'use client';

import { FC, useEffect, useState } from 'react';

import { useAuthenticationContext, UserGroups } from '@ravago/shared/authentication';
import { AutoSuggestObjectType, AutoSuggestResponseContent, facetOrObjectTypeLabelTranslationMapping } from '@ravago/shared/page-data/models/elastic';
import { DynamicPageUrl, RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { SearchItem } from '@ravago/shared/radiance/components/multifacet/components/SearchItem/SearchItem';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { CorrelationService } from '../../../../services/tracking/correlation-token.service';
import StringUtils from '../../../../utils/string/string.utils';
import { TrackingUtils } from '../../../../utils/tracking/tracking.utils';
import { ConsumerWrapper } from '../../../content/consumer-wrapper/consumer-wrapper';
import { DynamicPageLink } from '../../../content/dynamic-page-link/dynamic-page-link';
import { MockLink } from '../../../content/mock-link/mock-link';

interface Props {
   suggestedPages: AutoSuggestResponseContent[];
   itemCount: number;
   language: string;
   title?: string;
   highlightTerm?: string;
   baseRoutes?: {
      product: string;
      brand: string;
      caseStudy: string;
   };
   globals: RendererGlobals;
}

export const AutoSuggestPages: FC<Props> = ({ suggestedPages, itemCount, title, highlightTerm, language, baseRoutes, globals }) => {
   const translate = useTranslations();
   const { isAuthenticated, user } = useAuthenticationContext();

   const [visualSuggestedPages, setVisualSuggestedPages] = useState<AutoSuggestResponseContent[]>([]);

   const searchItemMapping: Record<
      AutoSuggestObjectType,
      {
         icon: string;
         color: 0 | 1 | 2;
         baseRoute?: DynamicPageUrl;
      }
   > = {
      'Case Study': {
         icon: 'book',
         color: 2,
         baseRoute: { url: baseRoutes?.caseStudy }
      },
      Brand: {
         icon: 'industry',
         color: 1,
         baseRoute: { url: baseRoutes?.brand }
      },
      Product: {
         icon: 'shopping-cart',
         color: 0,
         baseRoute: { url: baseRoutes?.product }
      }
   };

   useEffect(() => {
      const pages = suggestedPages.filter((item) => searchItemMapping[item.type as AutoSuggestObjectType].baseRoute);

      if (!isAuthenticated) {
         setVisualSuggestedPages(pages.filter((item) => item.type !== 'Case Study'));
      } else {
         setVisualSuggestedPages(pages);
      }
   }, [isAuthenticated, suggestedPages]);

   const visualizeLabel = (content: AutoSuggestResponseContent): string => {
      if (!highlightTerm) {
         return content.label;
      }
      return StringUtils.highlight(content.label, highlightTerm, '<b>', '</b>');
   };

   const visualizeSubLabel = (content: AutoSuggestResponseContent): string => {
      if (content.group && content.subgroup) {
         const groupWithHighlight = StringUtils.highlight(content.group, highlightTerm ?? '', '<b>', '</b>');
         const subgroupWithHighlight = StringUtils.highlight(content.subgroup, highlightTerm ?? '', '<b>', '</b>');
         return `${groupWithHighlight}/${subgroupWithHighlight}`;
      }

      if (content.functionalKeyRequirements) {
         let sliced = content.functionalKeyRequirements.slice(0, 48);
         const lastSpaceIndex = sliced.lastIndexOf(' ');

         if (lastSpaceIndex > 0) {
            sliced = sliced.slice(0, lastSpaceIndex);
         }

         return StringUtils.highlight(sliced, highlightTerm ?? '', '<b>', '</b>');
      }

      if (content.type) {
         return translate(facetOrObjectTypeLabelTranslationMapping.get(content.type) ?? content.type);
      }
      return '';
   };

   return (
      <Box gap="lg" width="100%">
         <Box direction="column" gap="lg" width="100%">
            <Typography variant="heading-4" color={title ? 'strong' : undefined}>
               {title ?? translate('auto-suggest.pages')}
            </Typography>
            <Box direction="column" gap="xs" width="100%">
               {visualSuggestedPages.slice(0, itemCount).map((item) => (
                  <ConsumerWrapper
                     key={item.id}
                     isConsumer={globals.isConsumer}
                     renderConsumer={(children) => (
                        <DynamicPageLink
                           id={`dynamic-link__auto-suggest__${item.slug}`}
                           slug={item.slug ?? ''}
                           baseRoute={searchItemMapping[item.type as AutoSuggestObjectType].baseRoute}
                           locale={globals.locale}
                           underline="none"
                        >
                           {children}
                        </DynamicPageLink>
                     )}
                     renderProducer={(children) => <MockLink>{children}</MockLink>}
                  >
                     <SearchItem
                        onClick={() =>
                           TrackingUtils.track('autosuggest_page_click', {
                              correlationId: CorrelationService.getCorrelationId('dpcid'),
                              pageId: item.id?.toString() ?? 'null',
                              pageType: item.type,
                              ...(searchItemMapping[item.type as AutoSuggestObjectType].baseRoute && {
                                 baseRoute: searchItemMapping[item.type as AutoSuggestObjectType].baseRoute?.url
                              }),
                              ...(item.slug && { slug: item.slug }),
                              language
                           })
                        }
                        trailingIcon="chevron-right"
                        facetIcon={searchItemMapping[item.type as AutoSuggestObjectType].icon}
                        facetColor={searchItemMapping[item.type as AutoSuggestObjectType].color}
                     >
                        <Box direction="column" items="start" gap="3xs">
                           <Typography variant="small" color="primary">
                              <span dangerouslySetInnerHTML={{ __html: visualizeLabel(item) }} />
                              {item.preferred === 1 && (user?.groups?.includes(UserGroups.ravagoSalesRepresentative) || !globals.isConsumer) && (
                                 <Icon icon="gem" color="inherit" size="small" />
                              )}
                              {item.quality === 'prime' && <Icon icon="leaf" color="inherit" size="small" />}
                           </Typography>
                           <Typography variant="small" color="subtle">
                              <span dangerouslySetInnerHTML={{ __html: visualizeSubLabel(item) }} />
                           </Typography>
                        </Box>
                     </SearchItem>
                  </ConsumerWrapper>
               ))}
               {visualSuggestedPages.length === 0 && <Typography>{translate('auto-suggest.no-pages')}</Typography>}
            </Box>
         </Box>
      </Box>
   );
};
