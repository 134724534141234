'use client';

import { FC, useEffect, useState } from 'react';

import { useAuthenticationContext } from '@ravago/shared/authentication';
import { ElementProps } from '@ravago/shared/page-data/models/base';
import { ContentfulCaseStudy } from '@ravago/shared/page-data/models/contentful';
import { CaseStudyListNode } from '@ravago/shared/page-data/models/elements';
import { DynamicPageUrl } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';

import { getFilterQueryForCaseStudies } from './case-study-list-functions';
import { CaseStudyList } from './components/case-study-list';

export const CaseStudyListElementServer: FC<ElementProps<CaseStudyListNode>> = ({ node, globals }) => {
   const { token, user, loading } = useAuthenticationContext();

   const [productOverview, setProductOverview] = useState<DynamicPageUrl | undefined>();
   const [caseStudiesLoading, setCaseStudiesLoading] = useState(false);
   const [caseStudiesTotal, setCaseStudiesTotal] = useState(0);
   const [caseStudies, setCaseStudies] = useState<ContentfulCaseStudy[]>([]);
   const [baseRoutes, setBaseRoutes] = useState({
      caseStudy: '',
      market: '',
      product: ''
   });

   const { query, showAllCaseStudiesButton } = node;
   const {
      locale,
      contentType,
      contentEntity,
      actions: { getCaseStudies, getPrivateToggles, getDynamicPageUrls, getDynamicPageParentUrl }
   } = globals;

   if (query.filterBy === 'dynamic' && contentEntity) {
      switch (contentType) {
         case 'product':
            query.productIds = [contentEntity.sys.id];
            break;
         case 'industry':
            query.industryIds = [contentEntity.sys.id];
            break;
         case 'brand':
            query.brandIds = [contentEntity.sys.id];
            break;
         default:
      }
   }

   useEffect(() => {
      const fetchData = async () => {
         if (!token || !user) return;

         const [toggles, caseStudyList, dynamicPageUrls, baseUrl] = await Promise.all([
            getPrivateToggles(token),
            getCaseStudies(locale, query, user.groups),
            getDynamicPageUrls(locale, ['caseStudy', 'industry', 'product']),
            getDynamicPageParentUrl(locale, 'product')
         ]);

         const caseStudyToggle = toggles.find((toggle) => toggle.name === 'case-studies');
         if (!caseStudyToggle || !caseStudyToggle.allowed) return;

         setCaseStudies(caseStudyList.caseStudies);
         setCaseStudiesTotal(caseStudyList.total);
         setProductOverview(baseUrl);

         setBaseRoutes({
            caseStudy: dynamicPageUrls.caseStudy || '',
            market: dynamicPageUrls.industry || '',
            product: dynamicPageUrls.product || ''
         });
      };

      const init = async () => {
         setCaseStudiesLoading(true);
         await fetchData();
         setCaseStudiesLoading(false);
      };

      init();
   }, [token, query, locale]);

   if (loading || caseStudiesLoading) {
      return (
         <Box direction="column" items="center">
            <Spinner />
         </Box>
      );
   }

   return (
      <CaseStudyList
         showAllCaseStudiesButton={showAllCaseStudiesButton && caseStudiesTotal > (node.query.limit || 4)}
         urlQuery={getFilterQueryForCaseStudies(query, globals)}
         productOverview={productOverview}
         isAuthenticated={!!token}
         caseStudies={caseStudies}
         acceptWebp={globals.acceptWebp}
         isConsumer
         assetBasePath={globals.assetBasePath}
         locale={locale}
         baseRoutes={baseRoutes}
      />
   );
};
