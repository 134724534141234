import { FC, ReactNode } from 'react';

interface Props {
   image: string;
   showOverlay?: boolean;
   inline?: boolean;
   children?: ReactNode;
}

export const Banner: FC<Props> = ({ image, showOverlay = true, inline = false, children }) => (
   <div data-testid="banner-container" style={{ backgroundImage: `url(${image})` }} className={`h-fit overflow-hidden bg-cover bg-center ${inline && 'rounded-component-data-display-banner-container-border-radius-inline'}`.replace(/\s+/g, ' ')}>
      <div data-testid="banner-overlay" className={`flex justify-center ${showOverlay && 'bg-[image:--component-data-display-banner-overlay-color-background-scrimmed]'}`.replace(/\s+/g, ' ')}>
         {inline ? (
            <div
               data-testid="banner-content--inline"
               className="p-component-data-display-banner-container-spacing-padding-inline-desktop phone:p-component-data-display-banner-container-spacing-padding-inline-mobile tab-land:p-component-data-display-banner-container-spacing-padding-inline-tablet tab-port:p-component-data-display-banner-container-spacing-padding-inline-tablet relative z-10 box-content size-full"
            >
               {children}
            </div>
         ) : (
            <div data-testid="banner-content--full" className="py-component-data-display-banner-container-spacing-padding-y min-h-component-data-display-banner-container-sizing-min-height relative z-10 box-content w-full">
               <div data-testid="banner-content-container" className="container h-full">
                  {children}
               </div>
            </div>
         )}
      </div>
   </div>
);
