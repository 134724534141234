'use client';

import { FC } from 'react';

import { BrandsByProducerItem, ProducerListNode } from '@ravago/shared/page-data/models/elements';
import { DynamicPageUrl, RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { ProducerCard } from '@ravago/shared/radiance/components/card/components/ProducerCard/ProducerCard';
import { Grid } from '@ravago/shared/radiance/components/grid/components/Grid/Grid';
import { GridCol } from '@ravago/shared/radiance/components/grid/components/GridCol/GridCol';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { UrlUtils } from '../../../../utils/url/url.utils';
import { ConsumerWrapper } from '../../consumer-wrapper/consumer-wrapper';
import { DynamicPageLink } from '../../dynamic-page-link/dynamic-page-link';
import { MockLink } from '../../mock-link/mock-link';

interface Props {
   node: ProducerListNode;
   globals: RendererGlobals;
   productsOverviewRoute?: DynamicPageUrl;
   brandsByProducer?: BrandsByProducerItem[];
   urlQuery?: string;
}

/* c8 ignore start */
export const ProducerList: FC<Props> = ({ node, globals, productsOverviewRoute, brandsByProducer, urlQuery }) => {
   const translate = useTranslations();
   const { locale, isConsumer } = globals;

   return (
      <Grid useContainerQuery>
         {brandsByProducer?.map(({ producer }) => (
            <GridCol key={producer.sys.id} useContainerQuery spanDesktop={3} spanTablet={6} spanPhone={4}>
               <ProducerCard
                  href={UrlUtils.getRoute(locale, productsOverviewRoute?.url, `?tab=brands&producers=${producer.name}`, undefined)}
                  target="_self"
                  logo={producer.logo.url}
                  name={producer.name}
               />
            </GridCol>
         ))}
         {node.showAllBrandsButton && (
            <GridCol useContainerQuery spanDesktop={12} spanTablet={12} spanPhone={4}>
               <ConsumerWrapper
                  isConsumer={isConsumer}
                  renderConsumer={(children) => (
                     <DynamicPageLink
                        id={`dynamic-link__producer-list__brands__${urlQuery}`}
                        underline="none"
                        slug={`?tab=brands${urlQuery}`}
                        baseRoute={productsOverviewRoute}
                        locale={locale}
                     >
                        {children}
                     </DynamicPageLink>
                  )}
                  renderProducer={(children) => <MockLink underline="none">{children}</MockLink>}
               >
                  <Button variant="tertiary" trailingIcon="long-arrow-right" fullWidth>
                     {translate('related-brands-2.all-brands')}
                  </Button>
               </ConsumerWrapper>
            </GridCol>
         )}
      </Grid>
   );
};
/* c8 ignore end */
