import { FC, useMemo } from 'react';

import { ContentfulCaseStudy } from '@ravago/shared/page-data/models/contentful';
import { CaseStudyCard as CaseStudyCardModel } from '@ravago/shared/page-data/models/elements';
import { DynamicPageUrl } from '@ravago/shared/page-data/models/utils';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { CaseStudyCard } from '@ravago/shared/radiance/components/card/components/CaseStudyCard/CaseStudyCard';
import { Grid } from '@ravago/shared/radiance/components/grid/components/Grid/Grid';
import { GridCol } from '@ravago/shared/radiance/components/grid/components/GridCol/GridCol';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { CaseStudyUtils } from '../../../../utils/case-study/case-study.utils';
import { getLanguageCode } from '../../../../utils/locale/locale.utils';
import { UrlUtils } from '../../../../utils/url/url.utils';
import CaseStudiesNotFoundIllustration from '../../../illustrations/case-studies-not-found-illustration';
import SecureLoginIllustration from '../../../illustrations/secure-login-illustration';
import { EmptyState } from '../../../shared/empty-state/empty-state.component';
import { LoginButton } from '../../../shared/login-button/login-button';
import { ConsumerWrapper } from '../../consumer-wrapper/consumer-wrapper';
import { DynamicPageLink } from '../../dynamic-page-link/dynamic-page-link';
import { MockLink } from '../../mock-link/mock-link';

interface Props {
   isAuthenticated: boolean;
   showAllCaseStudiesButton: boolean;
   productOverview?: DynamicPageUrl;
   caseStudies: ContentfulCaseStudy[];
   isConsumer: boolean;
   acceptWebp?: boolean;
   assetBasePath: string;
   locale: string;
   urlQuery?: string;
   baseRoutes?: {
      caseStudy?: string;
      market?: string;
      product?: string;
   };
}

export const CaseStudyList: FC<Props> = ({
   isAuthenticated,
   showAllCaseStudiesButton,
   caseStudies,
   isConsumer,
   acceptWebp,
   assetBasePath,
   productOverview,
   urlQuery,
   locale,
   baseRoutes
}) => {
   const translate = useTranslations();
   const language = getLanguageCode(locale);

   const caseStudyCards = useMemo(
      () => caseStudies?.map((caseStudy) => CaseStudyUtils.transformToCaseStudyCard(caseStudy, assetBasePath, acceptWebp)),
      [caseStudies, assetBasePath, acceptWebp]
   );

   if (!isAuthenticated) {
      return (
         <Box direction="column" items="center">
            <EmptyState
               title={translate('case-studies-not-authenticated')}
               label={translate('case-studies-not-authenticated-label')}
               image={<SecureLoginIllustration />}
            />
            <LoginButton trailingIcon="arrow-right" variant="primary" buttonLabel={translate('case-studies-login')} language={language} />
         </Box>
      );
   }

   if (!caseStudies.length) {
      return (
         <Box direction="column" items="center">
            <EmptyState label={translate('case-studies-no-results')} image={<CaseStudiesNotFoundIllustration />} />
         </Box>
      );
   }

   return (
      <>
         <Grid useContainerQuery>
            {caseStudyCards?.map((caseStudy: CaseStudyCardModel) => (
               <GridCol key={caseStudy.id} useContainerQuery spanDesktop={3} spanTablet={6} spanPhone={4}>
                  <CaseStudyCard
                     href={UrlUtils.getRoute(locale, baseRoutes?.caseStudy, caseStudy.slug, undefined)}
                     target="_self"
                     noFollow={caseStudy.noFollow}
                     title={caseStudy.title}
                     excerpt={caseStudy.excerpt}
                     background={`url('${caseStudy.image}') center center / cover no-repeat`}
                     markets={caseStudy.markets}
                     products={caseStudy.products}
                     badge={
                        <>
                           {caseStudy.isConfidential && <Badge variant="warning">{translate('case-study-confidential')}</Badge>}
                           {!caseStudy.isConfidential && caseStudy.visibility === 'Internal' && (
                              <Badge variant="warning">{translate('case-study-internal-only')}</Badge>
                           )}
                        </>
                     }
                  />
               </GridCol>
            ))}
         </Grid>
         {showAllCaseStudiesButton && (
            <ConsumerWrapper
               isConsumer={isConsumer}
               renderConsumer={(children) => (
                  <DynamicPageLink
                     id={`dynamic-link__case-study-list__${urlQuery}`}
                     underline="none"
                     slug={`?tab=case-study${urlQuery}`}
                     baseRoute={productOverview}
                     locale={locale}
                  >
                     {children}
                  </DynamicPageLink>
               )}
               renderProducer={(children) => <MockLink underline="none">{children}</MockLink>}
            >
               <Button variant="tertiary" trailingIcon="long-arrow-right">
                  {translate('case-study-all-related')}
               </Button>
            </ConsumerWrapper>
         )}
      </>
   );
};
