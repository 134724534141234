import { FC } from 'react';

import { RelatedDocument } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BoxItem } from '@ravago/shared/radiance/components/box/components/BoxItem/BoxItem';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Dropdown, DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';
import { InputGroup } from '@ravago/shared/radiance/components/input-field/components/InputGroup/InputGroup';
import { Multiselect, MultiselectOption } from '@ravago/shared/radiance/components/input-field/components/Multiselect/Multiselect';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../../providers/translation-client-context-provider';

interface Props {
   search?: string;
   numberOfFilters?: string;
   onLanguageChange?: (language: MultiselectOption[]) => void;
   onCategoryChange?: (category?: DropdownOption) => void;
   onSearch?: (search: string) => void;
   onCategoryClear?: () => void;
   onLanguageClear?: () => void;
   onSearchClear?: () => void;
   onClearAll?: () => void;
   onSetFilterOpen?: (isFilterOpen: boolean) => void;
   category?: string;
   isFilterOpen: boolean;
   isFilterApplied: boolean;
   disableAllFilters: boolean;
   filteredOptions: { key: string; documents: RelatedDocument[] }[];
   availableLanguages: MultiselectOption[];
   categories: DropdownOption[];
}

export const RelatedDocumentHeader: FC<Props> = ({
   numberOfFilters,
   onClearAll,
   onLanguageChange,
   filteredOptions,
   category,
   onCategoryChange,
   onCategoryClear,
   onSearchClear,
   onSearch,
   onSetFilterOpen,
   disableAllFilters,
   onLanguageClear,
   isFilterApplied,
   availableLanguages,
   search,
   isFilterOpen,
   categories
}) => {
   const translate = useTranslations();

   const handleCategoryChange = (value?: DropdownOption) => {
      onCategoryChange?.(value);
   };
   const handleSearchChange = (value: string) => {
      onSearch?.(value);
   };
   const handleLanguageChange = (value: MultiselectOption[]) => {
      onLanguageChange?.(value);
   };
   const handleIsFilterOpenToggle = (value: boolean) => {
      onSetFilterOpen?.(value);
   };
   const handleCategoryClear = () => {
      onCategoryClear?.();
   };
   const handleLanguageClear = () => {
      onLanguageClear?.();
   };
   const handleSearchClear = () => {
      onSearchClear?.();
   };
   const handleClearAll = () => {
      onClearAll?.();
   };

   return (
      <Box direction="column" gap="xl">
         {!disableAllFilters && (
            <Box direction="column" gap="md">
               <Box items="center">
                  <BoxItem grow={1}>
                     <InputGroup
                        id="product-document-search"
                        actionIcon="search"
                        width="100%"
                        clearable
                        onClear={() => handleSearchClear()}
                        onChange={(value) => handleSearchChange(value)}
                        label={translate('product.search-documents')}
                        value={search}
                     />
                  </BoxItem>
                  <BreakpointSwitch excludeViews={['phone', 'tab-port']}>
                     <Button
                        variant="tertiary"
                        leadingIcon="sliders-h"
                        badge={numberOfFilters}
                        onClick={() => handleIsFilterOpenToggle(!isFilterOpen)}
                     >
                        {isFilterOpen ? translate('product.documents.hide-filters') : translate('product.documents.show-filters')}
                     </Button>
                  </BreakpointSwitch>
                  <BreakpointSwitch includeViews={['phone', 'tab-port']}>
                     <IconButton onClick={() => handleIsFilterOpenToggle(!isFilterOpen)} icon="sliders-h" badge={numberOfFilters} />
                  </BreakpointSwitch>
               </Box>
               {isFilterOpen && (
                  <Box wrap="wrap" items="center" gap="xs">
                     <BoxItem basis="160px">
                        <Dropdown
                           onChange={(value) => handleCategoryChange(value)}
                           id="product-filter-categories"
                           value={category ? { id: category, value: category } : undefined}
                           label={translate('product.filter.categories')}
                           clearable
                           emptyOption={false}
                           onClear={() => handleCategoryClear()}
                           options={categories}
                        />
                     </BoxItem>
                     <Multiselect
                        id="product-filter-language"
                        onChange={(value) => handleLanguageChange(value)}
                        options={availableLanguages}
                        onClear={() => handleLanguageClear()}
                        label={translate('product.filter.languages')}
                     />
                     {isFilterApplied && (
                        <Button variant="tertiary" onClick={() => handleClearAll()}>
                           {translate('related-documents.reset-all')}
                        </Button>
                     )}
                  </Box>
               )}
               <Typography variant="caption">
                  {translate('product.documents.found').replace(
                     '{{total}}',
                     filteredOptions
                        .map((keyValueMap) => keyValueMap.documents)
                        .flat()
                        .length.toString()
                  )}
               </Typography>
            </Box>
         )}
      </Box>
   );
};
