'use client';

import { FC, useMemo } from 'react';

import { OverviewPageProductItem } from '@ravago/shared/page-data/models/elastic';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { CardGrid } from '@ravago/shared/radiance/components/card/components/CardGrid/CardGrid';

import { useRoleBasedFeatureToggles } from '../../../../../../hooks/use-role-based-feature-toggles';
import { ProductUtils } from '../../../../../../utils/product/product.utils';
import { ProductCard } from '../../../../../shared/cards/product-card/product-card';

interface Props {
   baseRoutes?: {
      product: string;
      technology: string;
   };
   products: Array<OverviewPageProductItem>;
   hasPublicLabelAndSigningsPermission: boolean;
   hasSustainabilityLabels: boolean;
   globals: RendererGlobals;
}

export const ProductGrid: FC<Props> = ({ baseRoutes, products, hasPublicLabelAndSigningsPermission, hasSustainabilityLabels, globals }) => {
   const { locale, isConsumer, acceptWebp, assetBasePath } = globals;

   const { hasProductCompareFeaturePermission } = useRoleBasedFeatureToggles(globals);

   const productCards = useMemo(
      () => products?.map((product) => ProductUtils.transformToProductCard(assetBasePath, product, acceptWebp)),
      [assetBasePath, products, acceptWebp]
   );

   return (
      <CardGrid>
         {productCards?.map((product) => (
            <ProductCard
               key={product.id}
               product={product}
               locale={locale}
               isConsumer={isConsumer}
               hasPublicLabelAndSigningsPermission={hasPublicLabelAndSigningsPermission}
               hasSustainabilityLabels={hasSustainabilityLabels}
               hasProductCompareFeaturePermission={hasProductCompareFeaturePermission}
               acceptWebp={acceptWebp}
               assetBasePath={assetBasePath}
               baseRoute={baseRoutes?.product}
               useStandardCardBanner={false}
            />
         ))}
      </CardGrid>
   );
};
