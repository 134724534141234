'use client';

import { FC, useMemo } from 'react';

import { Badge } from '../../../badge/components/Badge/Badge';
import { Box } from '../../../box/components/Box/Box';
import { Link } from '../../../link/components/Link/Link';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   href: string;
   target: '_blank' | '_self';
   noFollow?: boolean;
   background: string;
   title: string;
   excerpt?: string;
   markets: string[];
   products: { name: string; pristine: boolean }[];
   badge?: React.ReactNode;
}

export const CaseStudyCard: FC<Props> = ({ href, target = '_self', noFollow, title, excerpt, background, markets, products, badge }) => {
   const visibleMarkets = useMemo(() => (markets || []).slice(0, 3), [markets]);
   const remainingMarkets = (markets || []).length - visibleMarkets.length;

   const visibleProducts = useMemo(() => (products || []).slice(0, 3), [products]);
   const remainingProducts = (products || []).length - visibleProducts.length;

   return (
      <Link href={href} target={target} noFollow={noFollow} underline="none">
         <Box direction="column" gap="md">
            <Box direction="column" justify="between" minHeight="225px" rounded="lg" spacing="md" background={background}>
               <Box justify="end">{badge}</Box>
            </Box>
            <Typography variant="body-highlight">{title}</Typography>
            {excerpt && (
               <Typography variant="body" maxLines="4">
                  {excerpt}
               </Typography>
            )}
            {visibleMarkets?.length > 0 && (
               <Box gap="xs" wrap="wrap">
                  {visibleMarkets.map((market) => (
                     <Badge key={market} size="regular" variant="neutral" weight="regular">
                        {market}
                     </Badge>
                  ))}

                  {remainingMarkets > 0 && (
                     <Badge size="regular" variant="neutral" weight="regular">
                        +{remainingMarkets}
                     </Badge>
                  )}
               </Box>
            )}
            {visibleProducts?.length > 0 && (
               <Box gap="xs" wrap="wrap">
                  {visibleProducts.map((product) => (
                     <Badge key={product.name} size="regular" variant="info" weight="regular" trailingIcon={product.pristine ? 'gem' : undefined}>
                        {product.name}
                     </Badge>
                  ))}

                  {remainingProducts > 0 && (
                     <Badge size="regular" variant="info" weight="regular">
                        +{remainingProducts}
                     </Badge>
                  )}
               </Box>
            )}
         </Box>
      </Link>
   );
};
