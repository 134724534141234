'use client';

import { FC } from 'react';

import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';

export const EmailUnsubscribeError: FC = () => {
   const translate = useTranslations();

   return (
      <Box direction="column" items="center">
         <Typography component="p" variant="body" data-testid="email-unsubscribe-error__message">
            {translate('preference-center.email-unsubscribe.error')}
         </Typography>
      </Box>
   );
};
