'use client';

import { FC } from 'react';

import { ContentfulMarket } from '@ravago/shared/page-data/models/contentful';
import { MarketKeyAreasNode } from '@ravago/shared/page-data/models/elements';
import { DynamicPageUrl, RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { List } from '@ravago/shared/radiance/components/list/components/List/List';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { AppUtils } from '../../../../utils/app/app.utils';
import { ConsumerWrapper } from '../../consumer-wrapper/consumer-wrapper';
import { DynamicPageLink } from '../../dynamic-page-link/dynamic-page-link';
import { MockLink } from '../../mock-link/mock-link';

interface Props {
   node: MarketKeyAreasNode;
   globals: RendererGlobals;
   market: ContentfulMarket;
   productsOverviewBaseRoute?: DynamicPageUrl;
}

/* c8 ignore start */
export const MarketKeyAreas: FC<Props> = ({ node, globals, market, productsOverviewBaseRoute }) => {
   const translate = useTranslations();
   const { locale, isConsumer } = globals;

   return (
      <Box direction="column" items="start">
         <List type="disc">
            {market.keyAreas?.map((keyArea) => (
               <Typography key={AppUtils.getKey(node.id ?? '', keyArea)} component="li">
                  {keyArea}
               </Typography>
            ))}
         </List>
         {!node.hideButton && (
            <ConsumerWrapper
               isConsumer={isConsumer}
               renderConsumer={(children) => (
                  <DynamicPageLink
                     id={`dynamic-link__market-key-areas__${market.name}`}
                     underline="none"
                     slug={`?markets=${encodeURIComponent(market.name)}`}
                     baseRoute={productsOverviewBaseRoute}
                     locale={locale}
                  >
                     {children}
                  </DynamicPageLink>
               )}
               renderProducer={(children) => <MockLink underline="none">{children}</MockLink>}
            >
               <Button trailingIcon="arrow-right">{translate('all-products', { type: market.name })}</Button>
            </ConsumerWrapper>
         )}
      </Box>
   );
};
/* c8 ignore end */
