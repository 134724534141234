'use client';

import { FC, useMemo } from 'react';

import { Badge } from '../../../badge/components/Badge/Badge';
import { Box } from '../../../box/components/Box/Box';
import { Link } from '../../../link/components/Link/Link';
import { Tile } from '../../../tile/components/Tile/Tile';

interface Props {
   href: string;
   target: '_blank' | '_self';
   noFollow?: boolean;
   image: string;
   name: string;
   showSegments?: boolean;
   segments?: string[];
   ratio?: '1:1' | '5:4';
}

export const MarketCard: FC<Props> = ({ href, target = '_self', noFollow, image, name, showSegments, segments, ratio = '5:4' }) => {
   const visibleSegments = useMemo(() => (segments || []).slice(0, 6), [segments]);
   const remainingSegments = (segments || []).length - visibleSegments.length;

   return (
      <Link href={href} target={target} noFollow={noFollow} underline="none">
         <Box direction="column" gap="md">
            <Tile image={image} ratio={ratio} trailingIcon="long-arrow-right">
               {name}
            </Tile>

            {showSegments && (
               <>
                  {visibleSegments?.length > 0 && (
                     <Box gap="xs" wrap="wrap">
                        {visibleSegments.map((segment) => (
                           <Badge key={segment} size="regular" variant="info" weight="regular">
                              {segment}
                           </Badge>
                        ))}

                        {remainingSegments > 0 && (
                           <Badge size="regular" variant="info" weight="regular">
                              +{remainingSegments}
                           </Badge>
                        )}
                     </Box>
                  )}

                  {!visibleSegments?.length && (
                     <Badge size="regular" variant="neutral" weight="regular">
                        -
                     </Badge>
                  )}
               </>
            )}
         </Box>
      </Link>
   );
};
