import React, { PropsWithChildren, useMemo, useState } from 'react';

import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Checkbox } from '@ravago/shared/radiance/components/checkbox/components/Checkbox/Checkbox';
import { Expand } from '@ravago/shared/radiance/components/expand/components/Expand/Expand';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { ListItem } from '@ravago/shared/radiance/components/list/components/ListItem/ListItem';
import { RangeSlider } from '@ravago/shared/radiance/components/slider/components/RangeSlider/RangeSlider';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { Link } from '../../../../../shared/link/link';
import { FacetGroupContent } from '../../../models/facet-group.model';

export interface CheckedChangeEvent {
   checked: boolean;
}

export interface RangeChangeEvent {
   min: number;
   max: number;
}

interface Props extends PropsWithChildren {
   label: string;
   highlightValue: string;
   facet: FacetGroupContent;
   checked: boolean;
   indeterminate: boolean;
   range?: { min: number; max: number };
   disabled: boolean;
   onCheckedChange: (event: CheckedChangeEvent) => void;
   onRangeChange?: (event: RangeChangeEvent) => void;
}

export const FilterItem: React.FC<Props> = ({
   label,
   highlightValue,
   facet,
   checked,
   indeterminate,
   range,
   disabled,
   onCheckedChange,
   onRangeChange,
   children
}) => {
   const [expanded, setExpanded] = useState(false);

   const onSliderChange = (state: { min: number; max: number }) => {
      onRangeChange?.({ min: state.min, max: state.max });
   };

   const onCheckboxChange = () => {
      if (checked) {
         setExpanded(false);
      } else {
         setExpanded(true);
      }
      onCheckedChange({ checked: indeterminate ? true : !checked });
   };

   const visualLabel = useMemo(() => {
      if (!highlightValue.length) return label;

      return label.replace(new RegExp(highlightValue.split('').join('[^a-zA-Z0-9]*'), 'gi'), (match) => `<b>${match}</b>`);
   }, [label, highlightValue]);

   const secondaryAction = () => {
      if (!children || highlightValue !== '') return undefined;

      return (
         // eslint-disable-next-line jsx-a11y/anchor-is-valid
         <Link
            href=""
            onClick={(e) => {
               e.stopPropagation();
               setExpanded((curr) => !curr);
            }}
         >
            <Icon icon={expanded ? 'chevron-up' : 'chevron-down'} />
         </Link>
      );
   };

   return (
      <>
         <ListItem disabled={disabled} variant="transparent" onClick={onCheckboxChange}>
            <Box items="center" justify="between">
               <Checkbox id={facet.name} checked={checked} indeterminate={indeterminate} gutter={false} disabled={disabled}>
                  <Typography variant="body" color={disabled ? 'weak' : 'strong'}>
                     <span style={{ marginRight: '10px' }} dangerouslySetInnerHTML={{ __html: visualLabel }} />
                     <Typography inline component="span" variant="caption" color="subtle">
                        ({facet.quantity})
                     </Typography>
                  </Typography>
               </Checkbox>

               {secondaryAction()}
            </Box>
         </ListItem>

         {!!children && <Expand expanded={expanded || highlightValue !== ''}>{children}</Expand>}

         {checked && facet.range && (
            <Box direction="column" width="100%" spacing={{ left: 'xs', right: 'xs' }}>
               <RangeSlider min={facet.range.min} max={facet.range.max} value={range} onChange={onSliderChange} />
            </Box>
         )}
      </>
   );
};
