import { useEffect } from 'react';

import { useAuthenticationContext } from '@ravago/shared/authentication';
import notification from '@ravago/shared/radiance/utils/notification/notification';

import { saveAs } from 'file-saver';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { CorrelationService } from '../../../../services/tracking/correlation-token.service';
import { useInvoiceDispatchContext } from '../context/invoice-context';
import { InvoiceAction } from '../state/invoice.actions';

export const useFetchDocument = (
   downloadInvoiceDocument: (downloadEndpoint: string, accessToken: string, correlationId: string) => Promise<string | undefined>,
   documentToDownload: { invoiceId: string; documentId: string } | null,
   language: string
) => {
   const dispatch = useInvoiceDispatchContext();
   const translate = useTranslations();
   const { token } = useAuthenticationContext();

   useEffect(() => {
      if (!token || !documentToDownload) {
         return;
      }

      const fetchDocument = async () => {
         try {
            const documentBlobResponse = await downloadInvoiceDocument(
               `/customer/invoices/${documentToDownload.invoiceId}/documents/${documentToDownload.documentId}`,
               token,
               CorrelationService.generateCorrelationId('dpcid')
            );

            if (documentBlobResponse) {
               saveAs(documentBlobResponse);
            } else {
               notification(translate('invoices.error.download-document-failed'), { duration: 5000 });
            }
         } catch (error) {
            notification(translate('invoices.error.download-document-failed'), { duration: 5000 });
         } finally {
            dispatch({ type: InvoiceAction.DOWNLOAD_DOCUMENT, payload: null });
         }
      };

      fetchDocument();
   }, [dispatch, downloadInvoiceDocument, documentToDownload, language, token, translate]);
};
