'use client';

import { FC } from 'react';

import { useAuthenticationContext } from '@ravago/shared/authentication';
import { RegistrationNumber } from '@ravago/shared/page-data/models/elements/registration-number.model';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Paper } from '@ravago/shared/radiance/components/paper/components/Paper/Paper';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useQuery } from '@tanstack/react-query';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import OrderNotFoundIllustration from '../../../illustrations/order-not-found-illustration';
import { EmptyState } from '../../../shared/empty-state/empty-state.component';

interface Props {
   getRegistrationNumbers: (accessToken: string) => Promise<Array<RegistrationNumber>>;
}

export const RegistrationNumberList: FC<Props> = ({ getRegistrationNumbers }) => {
   const { token } = useAuthenticationContext();

   const translate = useTranslations();
   const getRegistrationNumbersQuery = useQuery({
      queryKey: ['registrationNumbers', { token }],
      queryFn: () => (token ? getRegistrationNumbers(token) : Promise.resolve(undefined)),
      enabled: !!token
   });

   const registrationNumbers = getRegistrationNumbersQuery.data;
   if (!registrationNumbers) {
      return (
         <Box direction="row" justify="center" spacing={{ top: '3xl', bottom: '3xl' }}>
            <Spinner data-testid="list__spinner" />
         </Box>
      );
   }

   if (registrationNumbers?.length === 0) {
      return (
         <EmptyState
            data-testid="registration-number__no_registration-numbers_found"
            image={<OrderNotFoundIllustration />}
            title={translate('registration-numbers.no-registration-numbers-found')}
         />
      );
   }

   const registrationNumberList = registrationNumbers.map(({ registrationNumber, type }) => (
      <Paper data-testid="registration-number__list-item" shadow key={registrationNumber}>
         <Box direction="row">
            <Typography data-testid="registration-number" component="h3" variant="body-highlight">
               {registrationNumber}
            </Typography>
         </Box>

         <Box direction="column" gap="2xs">
            <Typography data-testid="registration-number__type" variant="body">
               {type}
            </Typography>
         </Box>
      </Paper>
   ));

   return (
      <Box direction="column">
         <Typography variant="heading-2" component="h2">
            {translate('registration-numbers.title')}
         </Typography>
         <Box direction="column">{registrationNumberList}</Box>
      </Box>
   );
};
