'use client';

import { FC } from 'react';

import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';

import { useTranslations } from '../../../../providers/translation-client-context-provider';

export const BrandPortfolioListContentButton: FC = () => {
   const translate = useTranslations();

   return (
      <Button variant="secondary" trailingIcon="arrow-right">
         {translate('producer-card-see-all-brands')}
      </Button>
   );
};
