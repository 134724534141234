'use client';

import { FC, useEffect, useState } from 'react';

import { useAuthenticationContext } from '@ravago/shared/authentication';
import { SystemPageType } from '@ravago/shared/page-data/enums/system-page-type';
import { CallOffOrderDetail, MappedOrders } from '@ravago/shared/page-data/models/elements';
import { Address } from '@ravago/shared/page-data/models/elements/address.model';
import { Customer } from '@ravago/shared/page-data/models/features';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Alert } from '@ravago/shared/radiance/components/alert/components/Alert/Alert';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { DateUtils } from '../../../../utils/date/date.utils';
import { OrderMapperUtils } from '../../../../utils/order/order-mapper.utils';
import OrderNotFoundIllustration from '../../../illustrations/order-not-found-illustration';
import { EmptyState } from '../../../shared/empty-state/empty-state.component';
import { CallOffProgressBar } from '../../../shared/order/call-off-progress-bar';
import { OrderDetailsOrderLines } from '../../../shared/order/order-details-order-lines';
import { OrderShipments } from '../../../shared/order/order-shipments';
import { SystemPageLink } from '../../system-page-link/system-page-link';

import { CallOffOrderDeliveryRequestPaper } from './call-off-order-delivery-request-paper';
import { CallOffOrderInfo } from './call-off-order-info';
import { CallOffOrderPageHeader } from './call-off-order-page-header';

export type Props = {
   globals: RendererGlobals;
   shipmentBaseUrl: string;
   orderDetailBaseUrl: string;
   orderOverviewUrl: string;
};
export const CallOffOrderDetails: FC<Props> = ({
   globals: {
      locale,
      acceptWebp,
      actions: { getCallOffOrderDetail, getCustomer, getProductByEntryId, getDynamicPageUrls, getOrderProductsByIds },
      routeParams,
      isConsumer
   },
   shipmentBaseUrl,
   orderDetailBaseUrl,
   orderOverviewUrl
}) => {
   const translate = useTranslations();
   const { token } = useAuthenticationContext();
   const [callOffOrderDetail, setCallOffOrderDetail] = useState<CallOffOrderDetail>();
   const [deliveryAddress, setDeliveryAddress] = useState<Address>();
   const [customer, setCustomer] = useState<Customer | undefined>();
   const [isLoading, setIsLoading] = useState(true);
   const [showNotFound, setShowNotFound] = useState(false);
   const [productsBaseRoute, setProductsBaseRoute] = useState('');
   const [consumingOrders, setConsumingOrders] = useState<MappedOrders>();

   useEffect(() => {
      const fetchCallOffOrderDetail = async (accessToken: string) => {
         try {
            if (routeParams?.orderId) {
               const [callOffOrder, customerResponse] = await Promise.all([
                  getCallOffOrderDetail(accessToken, routeParams.orderId),
                  getCustomer(accessToken)
               ]);
               if (customerResponse) {
                  setCustomer(customerResponse);
                  const defaultAddress = customerResponse.addresses.find((address) => address.isDefault && address.type === 'Legal Address');
                  if (defaultAddress) {
                     setDeliveryAddress(defaultAddress);
                  }
               }
               if (callOffOrder) {
                  if (callOffOrder.product.pkbId) {
                     const dynamicPageUrls = await getDynamicPageUrls(locale, ['product']);
                     setProductsBaseRoute(dynamicPageUrls.product || '');
                     callOffOrder.contentfulProduct = await getProductByEntryId(locale, `product-${callOffOrder.product.pkbId}`);
                     const uniqueProductIds = [...new Set(callOffOrder.orders.map((order) => `product-${order.product.pkbId}`).filter(Boolean))];
                     const contentfulProducts = await getOrderProductsByIds(locale, uniqueProductIds);
                     if (contentfulProducts) {
                        callOffOrder.orders = callOffOrder.orders.map((order) => {
                           const productDetail = contentfulProducts.find((product) => product.id === `product-${order.product.pkbId}`);
                           return { ...order, contentfulProduct: productDetail };
                        });
                     }
                  }
                  setCallOffOrderDetail(callOffOrder);
                  setConsumingOrders(OrderMapperUtils.getMappedOrders(callOffOrder.orders));
                  return;
               }
            }
            setShowNotFound(true);
         } catch (error) {
            setShowNotFound(true);
         } finally {
            setIsLoading(false);
         }
      };
      if (token) {
         fetchCallOffOrderDetail(token);
      }
   }, [token]);

   if (showNotFound) {
      return (
         <Box direction="column">
            <CallOffOrderPageHeader headerText={translate('my-orders.order-header')} id={routeParams?.orderId || ''} />
            <EmptyState
               label={translate('my-orders.no-orders-found')}
               image={<OrderNotFoundIllustration />}
               button={
                  <Box items="center">
                     <SystemPageLink baseRoute={orderOverviewUrl} locale={locale} type={SystemPageType.ORDER_OVERVIEW}>
                        <Button variant="primary" leadingIcon="arrow-left">
                           {translate('shipment.return-to-orders')}
                        </Button>
                     </SystemPageLink>
                  </Box>
               }
               title={translate('shipment.not-found-title')}
            />
            <Divider color="default" orientation="horizontal" />
         </Box>
      );
   }

   if (isLoading) {
      return (
         <Box direction="column" items="center">
            <Spinner />
         </Box>
      );
   }

   if (callOffOrderDetail && routeParams?.orderId) {
      return (
         <Box direction="column" gap="xl">
            {callOffOrderDetail.status === 'Call Off Overdue' && (
               <Alert variant="warning" headline={translate('my-orders.agreement-overdue')}>
                  <Typography component="span" variant="body">
                     {translate('my-orders.agreement-overdue-message')}
                  </Typography>
               </Alert>
            )}
            <CallOffOrderPageHeader
               headerText={translate('my-orders.call-off-agreement-header')}
               id={routeParams.orderId}
               date={DateUtils.transformCallOffOrderPeriod(callOffOrderDetail.callOffPeriod)}
            />
            <CallOffProgressBar callOffOrderDetail={callOffOrderDetail} showLabel showSupportingText />
            <BreakpointSwitch includeViews={['desktop', 'tab-land']}>
               <Box>
                  <Box width="50%">
                     <CallOffOrderInfo locale={locale} callOffOrderDetail={callOffOrderDetail} productsBaseRoute={productsBaseRoute} />
                  </Box>
                  <Box width="50%" height="100%">
                     <CallOffOrderDeliveryRequestPaper
                        callOffOrderDetail={callOffOrderDetail}
                        deliveryAddress={deliveryAddress}
                        customer={customer}
                     />
                  </Box>
               </Box>
            </BreakpointSwitch>
            <BreakpointSwitch includeViews={['phone', 'tab-port']}>
               <Box direction="column">
                  <CallOffOrderInfo locale={locale} callOffOrderDetail={callOffOrderDetail} productsBaseRoute={productsBaseRoute} />
                  <CallOffOrderDeliveryRequestPaper callOffOrderDetail={callOffOrderDetail} deliveryAddress={deliveryAddress} customer={customer} />
               </Box>
            </BreakpointSwitch>
            <Box direction="column" gap="md">
               <Typography variant="heading-2" component="h2" align="left" color="strong">
                  {translate('my-orders.agreement-consuming-orders-title')}
               </Typography>
               {consumingOrders &&
               consumingOrders.openOrderLines.length +
                  consumingOrders.closedOrderLines.length +
                  consumingOrders.transportArrangedShipments.length +
                  consumingOrders.inTransitShipments.length >
                  0 ? (
                  <>
                     <OrderShipments
                        isConsumer={isConsumer}
                        locale={locale}
                        acceptWebp={acceptWebp}
                        orderDetail={consumingOrders}
                        orderDetailBaseUrl={orderDetailBaseUrl}
                        shipmentBaseUrl={shipmentBaseUrl}
                        openedFrom="call_off_agreement"
                     />
                     <OrderDetailsOrderLines
                        isConsumer={isConsumer}
                        acceptWebp={acceptWebp}
                        locale={locale}
                        orderDetail={consumingOrders}
                        shipmentBaseUrl={shipmentBaseUrl}
                        orderDetailBaseUrl={orderDetailBaseUrl}
                        openedFrom="call_off_agreement"
                     />
                  </>
               ) : (
                  <Typography variant="body" component="b" align="center" color="strong">
                     {translate('my-orders.agreement-consuming-orders-empty-message')}
                  </Typography>
               )}
            </Box>
         </Box>
      );
   }

   return (
      <Box direction="column" items="center">
         <Spinner />
      </Box>
   );
};
