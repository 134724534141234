import { ContentfulCountry } from '@ravago/shared/page-data/models/contentful';
import { FacetResponse } from '@ravago/shared/page-data/models/elastic';

export interface FacetGroup {
   identifier: {
      key: string;
      label: string;
   };
   // eslint-disable-next-line no-use-before-define
   facets: FacetGroupContent[];
}

export interface FacetGroupContent {
   label: string;
   name: string;
   quantity: number;
   range?: { min: number; max: number };
   nestedFacets?: FacetGroup[];
}

const getTranslatableKey = (identifier: string, input: string, data: ContentfulCountry[]): string => {
   if (identifier === 'sellingCountries') {
      return data.find((country: ContentfulCountry) => country.isoCode === input)?.name ?? input;
   }

   return input;
};

export const toFacetGroup = (facetResponse: FacetResponse, language: string, countries: ContentfulCountry[]): FacetGroup => ({
   identifier: facetResponse.identifier,
   facets: facetResponse.facets.map((facetContent) => ({
      name: facetContent.name,
      label: getTranslatableKey(facetResponse.identifier.key, facetContent.name, countries),
      range: facetContent.range,
      quantity: facetContent.quantity,
      nestedFacets: facetContent.nestedFacets?.map((nestedFacetGroup) => toFacetGroup(nestedFacetGroup, language, countries))
   }))
});
