import { FC } from 'react';

import { Invoice } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Tooltip } from '@ravago/shared/radiance/components/tooltip/components/Tooltip/Tooltip';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../../../../../providers/translation-client-context-provider';
import { useInvoiceDispatchContext, useInvoiceStateContext } from '../../../../../context/invoice-context';
import { InvoiceAction } from '../../../../../state/invoice.actions';

type Props = {
   invoice: Invoice;
   locale: string;
};

const SHOW_TOOLTIP_LENGTH = 18;

export const ClosedInvoiceTableRow: FC<Props> = ({ invoice, locale }) => {
   const { documentToDownload } = useInvoiceStateContext();
   const dispatch = useInvoiceDispatchContext();
   const translate = useTranslations();

   const isDownloading = documentToDownload?.invoiceId === invoice.id;

   const handleDownload = async () => {
      if (invoice.documentId) dispatch({ type: InvoiceAction.DOWNLOAD_DOCUMENT, payload: { invoiceId: invoice.id, documentId: invoice.documentId } });
   };

   const showTooltipOnInvoiceId = invoice.id.length > SHOW_TOOLTIP_LENGTH;

   return (
      <TableRow>
         <TableCell>
            <Box direction="column" gap="3xs">
               {showTooltipOnInvoiceId && (
                  <Tooltip text={invoice.eInvoiceId || invoice.id} limitWidth={false}>
                     <span style={{ display: 'block', textOverflow: 'ellipsis', overflow: 'hidden' }}>{invoice.eInvoiceId || invoice.id}</span>
                  </Tooltip>
               )}
               {!showTooltipOnInvoiceId && (
                  <span style={{ display: 'block', textOverflow: 'ellipsis', overflow: 'hidden' }}>{invoice.eInvoiceId || invoice.id}</span>
               )}
               <Typography component="span" variant="small" color="subtle">
                  {translate(`invoices.invoice-type.${invoice.type}`)}
               </Typography>
            </Box>
         </TableCell>
         <TableCell>{invoice.createDate}</TableCell>
         <TableCell>{invoice.legalEntity}</TableCell>
         <TableCell>
            {invoice.billedSum.amount.toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} {invoice.billedSum.currency}
         </TableCell>
         <TableCell>{/* Placeholder */}</TableCell>
         <TableCell align="right" width="40px">
            {invoice.documentId && (
               <Box justify="end">
                  {isDownloading ? (
                     <Spinner size="small" />
                  ) : (
                     <IconButton icon="download" onClick={handleDownload} showGutters={false} variant="on-light" />
                  )}
               </Box>
            )}
         </TableCell>
      </TableRow>
   );
};
