import { FC, useState } from 'react';

import { CoaResponse, DocumentOrderLineData, OrderDocumentType } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import { useTranslations } from '../../../../providers/translation-client-context-provider';

interface CoaContentProps {
   data: CoaResponse;
   onDownload: (document: CoaResponse, orderLineData: DocumentOrderLineData, type: OrderDocumentType) => void;
   onRequestCoa: (coa: CoaResponse) => void;
   showDownloadBtn?: boolean;
}

export const CoaContent: FC<CoaContentProps> = ({ data, onDownload, showDownloadBtn = false, onRequestCoa }) => {
   const translate = useTranslations();
   const [isOrderExpanded, setIsOrderExpanded] = useState(false);
   const { phone, tabPort } = useBreakpoint();
   const filteredOrderLineData = isOrderExpanded ? data.orderLineData : data.orderLineData.slice(0, 1);

   return (
      <Box gap="sm" direction="column" width="100%">
         {filteredOrderLineData.map((orderLine, index) => (
            <Box direction={tabPort || phone ? 'column' : 'row'} gap="md" key={orderLine.orderNumber} width="100%">
               {index > 0 && (phone || tabPort) && <Divider />}
               <Box direction="column" gap="none" width={tabPort || phone ? '100%' : '30%'} wrap="wrap">
                  <Typography variant="body-highlight" component="span">
                     {orderLine.productDescription}
                  </Typography>
                  <Typography variant="small" component="span" color="subtle">
                     {`${translate('shipment.documents.order')} ${orderLine.orderNumber}`}
                  </Typography>
               </Box>
               <Box direction="column" gap="none" width={tabPort || phone ? '100%' : '20%'}>
                  <Typography variant="small" component="span" color="subtle">
                     {translate('shipment.documents.transport-order-number')}
                  </Typography>
                  <Typography variant="body" component="span">
                     {orderLine.transportOrderNr}
                  </Typography>
               </Box>
               <Box direction="column" gap="none" width={tabPort || phone ? '100%' : '20%'}>
                  <Typography variant="small" component="span" color="subtle">
                     {translate('shipment.documents.producer-lot-number')}
                  </Typography>
                  <Typography variant="body" component="span">
                     {data.producerLotNr}
                  </Typography>
               </Box>
               <Box gap="none" width={tabPort || phone ? '100%' : '30%'}>
                  <Box direction="column" gap="none" width={phone ? '50%' : '100%'}>
                     <Typography variant="small" component="span" color="subtle">
                        {translate('shipment.documents.quantity')}
                     </Typography>
                     <Typography variant="body" component="span">
                        {`${orderLine.batch.quantity.amount} ${orderLine.batch.quantity.unitOfMeasure}`}
                     </Typography>
                  </Box>
                  <Box items="start" justify="end" width={phone ? '50%' : '100%'}>
                     {index < 1 &&
                        (showDownloadBtn ? (
                           <IconButton
                              icon="download"
                              onClick={() => onDownload(data, data.orderLineData[0], 'COA')}
                              type="button"
                              variant="on-light"
                           />
                        ) : (
                           <Button variant="tertiary" onClick={() => onRequestCoa(data)}>
                              {translate('shipment.documents.request')}
                           </Button>
                        ))}
                  </Box>
               </Box>
            </Box>
         ))}
         <Box>
            {data.orderLineData.length > 1 && (
               <Button
                  variant="tertiary"
                  onClick={() => setIsOrderExpanded(!isOrderExpanded)}
                  trailingIcon={isOrderExpanded ? 'chevron-up' : 'chevron-down'}
               >
                  {isOrderExpanded
                     ? translate('shipment.documents.show-less')
                     : `${data.orderLineData.length - 1} ${translate('shipment.documents.show-more')} `}
               </Button>
            )}
         </Box>
         {phone && filteredOrderLineData.length > 1 && <Divider />}
      </Box>
   );
};
