import { FC, ReactNode } from 'react';

import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableHeader } from '@ravago/shared/radiance/components/table/components/TableHeader/TableHeader';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

export interface Properties {
   [key: string]: { label: string; value?: ReactNode; dataCy?: string };
}

interface Props {
   properties: Properties;
}

export const ItemInformationTable: FC<Props> = ({ properties }) => (
   <Table stroked="horizontal" tableLayout="fixed" rowDividers={false}>
      <TableBody>
         {Object.keys(properties).map((key) => (
            <TableRow key={properties[key].label}>
               <TableHeader>
                  <Typography color="primary" variant="body-highlight" component="p">
                     {properties[key].label}
                  </Typography>
               </TableHeader>
               <TableCell>
                  <span data-cy={properties[key].dataCy}>{properties[key].value ?? '-'}</span>
               </TableCell>
            </TableRow>
         ))}
      </TableBody>
   </Table>
);
