'use client';

import { FC, useEffect, useState } from 'react';

import { useAuthenticationContext } from '@ravago/shared/authentication';
import { FeatureToggle } from '@ravago/shared/page-data/models/config';
import { ContentfulProductEntry } from '@ravago/shared/page-data/models/contentful';
import { ProductActionsNode } from '@ravago/shared/page-data/models/elements';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';

import { Entry } from 'contentful';

import { useDialogContext } from '../../../../hooks/use-dialog-context';
import { useProductCompare } from '../../../../hooks/use-product-compare';
import { useRoleBasedFeatureToggles } from '../../../../hooks/use-role-based-feature-toggles';
import { ProductUtils } from '../../../../utils/product/product.utils';

import { ProductActionsList } from './product-actions-list';

export interface Props {
   node: ProductActionsNode;
   globals: RendererGlobals;
   publicFeatures: FeatureToggle[];
}

export const ProductActions: FC<Props> = ({ node, globals, publicFeatures }) => {
   const { user } = useAuthenticationContext();
   const { productsToCompareList, addProductToCompareList, removeProductFromCompareList } = useProductCompare();
   const [compare, setCompare] = useState(false);
   const { dispatch } = useDialogContext();
   const { hasProductCompareFeaturePermission, hasShareFeaturePermission, hasTeamdeskFeedbackFeaturePermission } = useRoleBasedFeatureToggles(
      globals,
      publicFeatures
   );

   useEffect(() => {
      if (!globals.contentEntity) return;

      setCompare(productsToCompareList.some((item) => item === (globals.contentEntity as Entry<ContentfulProductEntry>).sys.id));
   }, [globals, productsToCompareList]);

   const onCheckTds = () => {
      if (!node.tdsAnchor) return;

      window.dispatchEvent(new CustomEvent('onCheckTds'));

      document.getElementById(node.tdsAnchor)?.scrollIntoView({ behavior: 'smooth' });
   };

   const onShare = () => {
      if (dispatch) dispatch({ type: 'open_dialog', value: { dialogType: 'share-page' } });
   };

   const onFeedback = () => {
      if (!globals.feedbackUrl) return;
      let teamdeskFeedbackUrl = globals.feedbackUrl;
      if (user?.mail) {
         teamdeskFeedbackUrl += user.mail;
      }
      window.open(teamdeskFeedbackUrl, '_blank');
   };

   const onCompare = () => {
      if (!globals.contentEntity) return;

      const normalizedEntry = ProductUtils.normalizeEntry(globals.contentEntity as Entry<ContentfulProductEntry>);

      if (!compare) {
         addProductToCompareList(normalizedEntry.id);
      } else {
         removeProductFromCompareList(normalizedEntry.id);
      }
   };

   return (
      <ProductActionsList
         compare={compare}
         compareDisabled={productsToCompareList.length >= 5 && !compare}
         showCheckTds={!!node.tdsAnchor}
         showFeedback={!!globals.feedbackUrl && hasTeamdeskFeedbackFeaturePermission}
         showCompare={hasProductCompareFeaturePermission}
         showShare={hasShareFeaturePermission}
         onCheckTds={onCheckTds}
         onShare={onShare}
         onFeedback={onFeedback}
         onCompare={onCompare}
      />
   );
};
