import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../../providers/translation-client-context-provider';

export const InvoiceHeader = () => {
   const translate = useTranslations();

   return (
      <Typography component="h2" variant="heading-2" color="strong">
         {translate('invoices.title')}
      </Typography>
   );
};
