'use client';

import { FC } from 'react';

import { useAuthenticationContext } from '@ravago/shared/authentication';
import { Address } from '@ravago/shared/page-data/models/elements/address-list.model';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Paper } from '@ravago/shared/radiance/components/paper/components/Paper/Paper';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useQuery } from '@tanstack/react-query';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import OrderNotFoundIllustration from '../../../illustrations/order-not-found-illustration';
import { EmptyState } from '../../../shared/empty-state/empty-state.component';

interface Props {
   getAddresses: (accessToken: string) => Promise<Array<Address>>;
}

export const AddressList: FC<Props> = ({ getAddresses }) => {
   const { token } = useAuthenticationContext();
   const translate = useTranslations();

   const getAddressesQuery = useQuery({
      queryKey: ['addresses', { token }],
      queryFn: () => (token ? getAddresses(token) : Promise.resolve(undefined)),
      enabled: !!token
   });

   const addresses = getAddressesQuery.data;
   if (!addresses) {
      return (
         <Box direction="row" justify="center" spacing={{ top: '3xl', bottom: '3xl' }}>
            <Spinner data-testid="list__spinner" />
         </Box>
      );
   }

   if (addresses?.length === 0) {
      return (
         <EmptyState
            data-testid="address__no-addresses-found"
            image={<OrderNotFoundIllustration />}
            title={translate('addresses.no-addresses-found')}
         />
      );
   }

   const addressList = addresses.map(({ id, name, countryName, type, line1, line2, line3, line4, line5 }) => {
      const addressLines = [line1, line2, line3, line4, line5]
         .filter(Boolean)
         .map((line, index, arr) => (index !== arr.length - 1 ? `${line}, ` : line));

      return (
         <Paper data-testid="address__list-item" shadow key={id}>
            <Box direction="row">
               <Typography data-testid="address__name" component="h3" variant="body-highlight">
                  {name}
               </Typography>
               <Badge data-testid="address__type">{translate(`addresses.types.${type}`)}</Badge>
            </Box>

            <Box direction="column" gap="2xs">
               <Typography variant="body-highlight">{translate('addresses.address')}</Typography>
               <Typography data-testid="address__lines" variant="body">
                  {addressLines} - {countryName}
               </Typography>
            </Box>
         </Paper>
      );
   });

   return (
      <Box direction="column">
         <Typography variant="heading-2" component="h2">
            {translate('addresses.title')}
         </Typography>
         <Box direction="column">{addressList}</Box>
      </Box>
   );
};
