import { useMemo } from 'react';

import { DynamicProductBlockQuotation } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Link } from '@ravago/shared/radiance/components/link/components/Link/Link';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { DateUtils } from '../../../../utils/date/date.utils';
import { getLanguageCode } from '../../../../utils/locale/locale.utils';

import { QuoteFlyoutQuotationLine } from './quote-flyout-quotation-line';

interface Props {
   quotation: DynamicProductBlockQuotation;
   productId: string;
   locale: string;
}

export const QuoteFlyoutQuotation = ({ quotation, productId, locale }: Props) => {
   const translate = useTranslations();

   const sortedQuotationLines = useMemo(() => quotation.lines.sort((a, b) => a.quantity.amount - b.quantity.amount), [quotation]);

   const productReference = useMemo(() => {
      const references = new Set(quotation.lines.map((line) => line.customerProductReference).filter((cpref) => cpref));
      return references.size === 1 ? [...references][0] : undefined;
   }, [quotation]);

   return (
      <Box direction="column">
         <Divider />

         <Box justify="between">
            <Typography component="h4" variant="body-highlight">
               {quotation.name}
            </Typography>
            <Link href={`/my/${getLanguageCode(locale)}/my-prices/quote/${quotation.id}?expandedProductId=${productId}`}>
               {translate('quote-flyout.view-all-details')}
            </Link>
         </Box>

         <Box direction="column" gap="2xs">
            <Typography component="span" variant="body-highlight">
               {translate('quote-flyout.general-comments-for-quote-label')}
            </Typography>
            {quotation.description ? (
               <Typography component="span" variant="body">
                  {quotation.description}
               </Typography>
            ) : (
               <Typography color="weak">{translate('quote-flyout.no-general-comments-found')}</Typography>
            )}
         </Box>

         {sortedQuotationLines.map((line) => (
            <QuoteFlyoutQuotationLine key={line.id} quotationLine={line} />
         ))}

         <Box direction="column" gap="2xs">
            <Typography component="span" variant="body-highlight">
               {translate('quote-flyout.validity-label')}
            </Typography>
            <Typography component="span" variant="body">
               {DateUtils.localizeDate(quotation.effective.from)} - {DateUtils.localizeDate(quotation.effective.until)}
            </Typography>
         </Box>

         <Box direction="column" gap="2xs">
            <Typography variant="body-highlight">{translate('quote-flyout.product-reference-label')}</Typography>
            {productReference ? (
               <Typography component="span" variant="body">
                  {productReference}
               </Typography>
            ) : (
               <Typography color="weak">{translate('quote-flyout.no-product-reference-found')}</Typography>
            )}
         </Box>
      </Box>
   );
};
