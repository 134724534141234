'use client';

import { FC, useState } from 'react';

import { MappedOrders, OrderLineOpenedFrom } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Expand } from '@ravago/shared/radiance/components/expand/components/Expand/Expand';

import { useTranslations } from '../../../providers/translation-client-context-provider';
import OrderExpandHeader from '../../content/order-details/components/order-expand-header';
import { ShipmentTable } from '../shipment-table/shipment-table';

interface Props {
   locale: string;
   isConsumer: boolean;
   acceptWebp?: boolean;
   orderDetail: MappedOrders;
   shipmentBaseUrl: string;
   orderDetailBaseUrl?: string;
   callOffAgreementBaseUrl?: string;
   openedFrom: OrderLineOpenedFrom;
}

export const OrderShipments: FC<Props> = ({
   locale,
   isConsumer,
   acceptWebp,
   orderDetail,
   shipmentBaseUrl,
   orderDetailBaseUrl,
   callOffAgreementBaseUrl,
   openedFrom
}) => {
   const translate = useTranslations();
   const [isInTransitExpanded, setIsInTransitExpanded] = useState(true);
   const [isTransportArrangedExpanded, setIsTransportArrangedExpanded] = useState(true);

   return (
      <Box direction="column" gap="2xl" width="100%">
         {orderDetail.inTransitShipments.length > 0 && (
            <Expand
               expanded={isInTransitExpanded}
               header={
                  <OrderExpandHeader
                     title={translate('order.status.in-transit')}
                     icon="truck"
                     isExpanded={isInTransitExpanded}
                     amountOfOrders={orderDetail.inTransitShipments.length}
                  />
               }
               onExpandToggle={() => setIsInTransitExpanded(!isInTransitExpanded)}
            >
               <ShipmentTable
                  shipments={orderDetail.inTransitShipments}
                  locale={locale}
                  acceptWebp={acceptWebp}
                  isConsumer={isConsumer}
                  shipmentBaseUrl={shipmentBaseUrl}
                  orderDetailBaseUrl={orderDetailBaseUrl}
                  callOffAgreementBaseUrl={callOffAgreementBaseUrl}
                  openedFrom={openedFrom}
               />
            </Expand>
         )}

         {orderDetail.transportArrangedShipments.length > 0 && (
            <Expand
               expanded={isTransportArrangedExpanded}
               header={
                  <OrderExpandHeader
                     title={translate('order.status.transport-arranged')}
                     icon="truck-loading"
                     isExpanded={isTransportArrangedExpanded}
                     amountOfOrders={orderDetail.transportArrangedShipments.length}
                  />
               }
               onExpandToggle={() => setIsTransportArrangedExpanded(!isTransportArrangedExpanded)}
            >
               <ShipmentTable
                  shipments={orderDetail.transportArrangedShipments}
                  locale={locale}
                  isConsumer={isConsumer}
                  acceptWebp={acceptWebp}
                  shipmentBaseUrl={shipmentBaseUrl}
                  orderDetailBaseUrl={orderDetailBaseUrl}
                  callOffAgreementBaseUrl={callOffAgreementBaseUrl}
                  openedFrom={openedFrom}
               />
            </Expand>
         )}
      </Box>
   );
};
