import { FC } from 'react';

import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Pagination } from '@ravago/shared/radiance/components/pagination/components/Pagination/Pagination';
import { Paper } from '@ravago/shared/radiance/components/paper/components/Paper/Paper';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { Tooltip } from '@ravago/shared/radiance/components/tooltip/components/Tooltip/Tooltip';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../../../providers/translation-client-context-provider';
import { DateUtils } from '../../../../../../utils/date/date.utils';
import { EmptyState } from '../../../../../shared/empty-state/empty-state.component';
import { useInvoiceDispatchContext, useInvoiceStateContext } from '../../../context/invoice-context';
import { InvoiceAction } from '../../../state/invoice.actions';
import { ClosedInvoicesNotFoundIllustration } from '../../empty-states/closed-invoices-not-found-illustration';
import { FilterResultsNotFoundIllustration } from '../../empty-states/filter-results-not-found-illustration';

import { ClosedInvoiceTableDesktop } from './table/desktop/closed-invoice-table-desktop';
import { ClosedInvoiceTableMobile } from './table/mobile/closed-invoice-table-mobile';

export const ClosedInvoices: FC<{ locale: string }> = ({ locale }) => {
   const { areClosedInvoicesLoading, closedInvoices: invoices, closedInvoicesQueryParams } = useInvoiceStateContext();
   const dispatch = useInvoiceDispatchContext();
   const translate = useTranslations();

   const { page } = closedInvoicesQueryParams;
   const startYear = DateUtils.getStartOfYearFromCurrentInUtc(2);

   const handlePageChange = (newPage: number) => {
      dispatch({ type: InvoiceAction.SET_CLOSED_INVOICES_QUERY_PARAMS, payload: { page: newPage } });
   };

   if (areClosedInvoicesLoading) {
      return (
         <Box direction="column" items="center">
            <Spinner />
         </Box>
      );
   }

   if (!invoices && !areClosedInvoicesLoading) {
      if (closedInvoicesQueryParams.identifier) {
         return (
            <EmptyState
               image={<FilterResultsNotFoundIllustration />}
               title={translate('invoices.closed-invoices.empty-state.filter.title')}
               label={translate('invoices.closed-invoices.empty-state.filter.label')}
            />
         );
      }
      return <EmptyState image={<ClosedInvoicesNotFoundIllustration />} title={translate('invoices.closed-invoices.empty-state.label')} />;
   }

   if (!invoices) {
      return null;
   }

   return (
      <Box direction="column">
         <Box gap="xs">
            <Typography component="span" variant="body" color="subtle">
               {translate('invoices.closed-invoices.date-info', { startYear: DateUtils.localizeDate(startYear.toString()) })}
            </Typography>
            <Tooltip text={translate('invoices.closed-invoices.info-tooltip')}>
               <Icon icon="info-circle" />
            </Tooltip>
         </Box>
         <Paper>
            <Box direction="column">
               <BreakpointSwitch includeViews={['desktop']}>
                  <ClosedInvoiceTableDesktop invoices={invoices} page={page} locale={locale} />
               </BreakpointSwitch>
               <BreakpointSwitch excludeViews={['desktop']}>
                  <ClosedInvoiceTableMobile invoices={invoices} page={page} locale={locale} />
               </BreakpointSwitch>
               <Box justify="end" margin={{ top: 'sm' }}>
                  <Pagination total={invoices[page]?.totalPages || 0} current={page} onChange={handlePageChange} />
               </Box>
            </Box>
         </Paper>
      </Box>
   );
};
