import { FC } from 'react';

import { BlogPost } from '@ravago/shared/page-data/models/contentful';
import { BlogPreviewNode } from '@ravago/shared/page-data/models/elements';
import { AspectRatio } from '@ravago/shared/radiance/components/aspect-ratio/components/AspectRatio/AspectRatio';
import { Image } from '@ravago/shared/radiance/components/image/components/Image/Image';

import { AssetUtils } from '../../../utils/asset/asset.utils';

interface Props {
   blogPost: BlogPost;
   acceptWebp?: boolean;
   size: BlogPreviewNode['size'];
   zoomed?: boolean;
}

export const BlogPreviewImage: FC<Props> = ({ blogPost, acceptWebp, size, zoomed = false }) => (
   <AspectRatio desktop={size === 'normal' ? '3/2' : undefined} aspectRatio={size === 'normal' ? '21/9' : '16/9'} width="100%" useContainerQuery>
      <Image
         borderRadius="md"
         src={AssetUtils.optimizeImageIfRequired(blogPost.image, acceptWebp, 1440)}
         alt={blogPost.title ?? ''}
         title={blogPost.title ?? ''}
         width="100%"
         height="100%"
         objectFit="cover"
         zoomed={zoomed}
      />
   </AspectRatio>
);
