'use client';

import { FC, useMemo } from 'react';

import { ContentfulEntryIndustrySegment } from '@ravago/shared/page-data/models/contentful';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { ListItem } from '@ravago/shared/radiance/components/list/components/ListItem/ListItem';

import { ConsumerWrapper } from '../../consumer-wrapper/consumer-wrapper';
import { DynamicPageLink } from '../../dynamic-page-link/dynamic-page-link';
import { MockLink } from '../../mock-link/mock-link';

interface MarketItem {
   id: string;
   name: string;
   segments: string[];
   slug?: string;
   noFollow?: boolean;
}

interface Props {
   industrySegments: ContentfulEntryIndustrySegment[];
   baseRoute?: string;
   isConsumer: boolean;
   locale: string;
}

const MarketListItem = ({ market, baseRoute }: { market: MarketItem; baseRoute?: string }) => (
   <ListItem
      key={market.id}
      interactive={false}
      trailingIcon={baseRoute ? 'chevron-right' : ''}
      supportingText={market.segments.join(', ') ?? '-'}
      supportingTextVariant="small"
   >
      {market.name}
   </ListItem>
);

export const MarketItemList: FC<Props> = ({ industrySegments, baseRoute, isConsumer, locale }) => {
   const markets = useMemo(
      () =>
         industrySegments?.reduce(
            (
               acc: {
                  [market: string]: MarketItem;
               },
               segment
            ) => {
               const segmentName = segment.fields?.name;
               const id = segment.fields?.industry?.sys.id;
               const market = segment.fields?.industry?.fields?.name;
               const slug = segment.fields?.industry?.fields?.slug;
               const noFollow = segment.fields?.industry?.fields?.seoMetadata?.fields?.noFollow;

               if (!market || !segmentName || !id) return acc;
               if (!acc[market]) acc[market] = { id, name: market, segments: [], slug, noFollow };

               acc[market].segments.push(segmentName);

               return acc;
            },
            {}
         ) ?? {},
      [industrySegments]
   );

   return (
      <Box direction="column" gap="none">
         {Object.values(markets)
            .sort((a1, a2) => a1.name.localeCompare(a2.name))
            .map((market, index) => (
               <div data-cy="360market" key={market.id} style={{ display: 'contents' }}>
                  {index > 0 && <Divider />}
                  {market.slug ? (
                     <ConsumerWrapper
                        isConsumer={isConsumer}
                        renderConsumer={(children) => (
                           <DynamicPageLink
                              id={`dynamic-link__market-item-list__${market.slug}`}
                              slug={market.slug ?? ''}
                              baseRoute={{ url: baseRoute, noFollow: market.noFollow }}
                              locale={locale}
                              underline="none"
                           >
                              {children}
                           </DynamicPageLink>
                        )}
                        renderProducer={(children) => <MockLink>{children}</MockLink>}
                     >
                        <MarketListItem market={market} baseRoute={baseRoute} />
                     </ConsumerWrapper>
                  ) : (
                     <MarketListItem market={market} baseRoute={baseRoute} />
                  )}
               </div>
            ))}
      </Box>
   );
};
