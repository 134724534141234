import { NodeTypes } from '@ravago/shared/page-data/enums/node-types';
import { Node } from '@ravago/shared/page-data/models/base';

import classNames from 'classnames';

import styles from '../../components/containers/node-properties/node-properties.module.scss';

export class StyleUtils {
   static shouldFillParent(type?: NodeTypes): boolean {
      if (!type) return false;

      const singleElementTypes = [
         NodeTypes.TEXT,
         NodeTypes.IMAGE,
         NodeTypes.BADGE,
         NodeTypes.BUTTON,
         NodeTypes.REQUEST_MISSING_DOCUMENTS_BUTTON,
         NodeTypes.BUTTON_PRODUCT_PORTFOLIO,
         NodeTypes.ICON_BUTTON,
         NodeTypes.CASE_STUDY_WEBSITE_URL,
         NodeTypes.CASE_STUDY_WARNING_BADGE,
         NodeTypes.PRODUCT_RECOMMENDATION_BADGE,
         NodeTypes.PRODUCT_BADGES,
         NodeTypes.BRAND_INFORMATION,
         NodeTypes.CASE_STUDY_INFORMATION,
         NodeTypes.CASE_STUDY_FEATURES_LIST
      ];

      return !singleElementTypes.includes(type);
   }

   static getCssClassesForNode(node: Node): string {
      const { type, appearance } = node;

      const fullWidthNodes: NodeTypes[] = [
         NodeTypes.BLOG_HEADER,
         NodeTypes.BLOG_OVERVIEW,
         NodeTypes.CALL_OFF_ORDER_DETAILS,
         NodeTypes.MARKET_ITEM_LIST,
         NodeTypes.ORDER_DETAILS,
         NodeTypes.ORDER_OVERVIEW,
         NodeTypes.INVOICES,
         NodeTypes.PRODUCT_LISTER_BLOCK,
         NodeTypes.PRODUCT_PORTFOLIO,
         NodeTypes.QUICK_TDS_LINK,
         NodeTypes.TECHNOLOGY_ITEM_LIST,
         NodeTypes.TEXT_ACCORDION,
         NodeTypes.BRAND_LIST,
         NodeTypes.VAT_NUMBER_LIST,
         NodeTypes.CASE_STUDY_LIST,
         NodeTypes.FORM_EMBED,
         NodeTypes.IMAGE_GALLERY,
         NodeTypes.MARKET_LIST,
         NodeTypes.MARKET_SEGMENT_LIST,
         NodeTypes.MARKET_TILE_LIST,
         NodeTypes.PRODUCER_LIST,
         NodeTypes.PRODUCT_LIST,
         NodeTypes.RELATED_DOCUMENTS,
         NodeTypes.SHIPMENT_DETAILS,
         NodeTypes.TECHNOLOGY_TILE_LIST,
         NodeTypes.TILE,
         NodeTypes.DETAIL_HEADER,
         NodeTypes.DIVIDER,
         NodeTypes.EMAIL_UNSUBSCRIBE,
         NodeTypes.HOMEPAGE_SEARCH,
         NodeTypes.PRODUCT_TABLE,
         NodeTypes.REQUEST_FOR_QUOTATION,
         NodeTypes.REGISTRATION_NUMBER
      ];

      const fullHeightNodes: NodeTypes[] = [NodeTypes.COLUMN];

      const themeOverride = appearance?.themeOverride ? `theme-${appearance?.themeOverride?.replace('theme-', '')}` : '';

      return classNames(
         themeOverride,
         styles.contentLayer,
         fullWidthNodes.includes(type) && styles.wFull,
         fullHeightNodes.includes(type) && styles.hFull,
         'border' in node && node.border ? styles.border : '',
         type === NodeTypes.COLUMN && 'rounded' in node && node.rounded ? styles.borderRadiusMd : '',
         type === NodeTypes.BLOG_PREVIEW && 'size' in node && node.size === 'small' ? styles.blogPreviewSmall : '',
         type === NodeTypes.VIDEO && 'width' in node && node.width === 'fill' ? styles.w100 : '',
         type === NodeTypes.VIDEO && 'width' in node && node.width === 'large' ? styles.w75 : '',
         type === NodeTypes.VIDEO && 'width' in node && node.width === 'medium' ? styles.w50 : '',
         type === NodeTypes.VIDEO && 'width' in node && node.width === 'small' ? styles.w25 : ''
      );
   }
}
