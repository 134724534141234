export enum BackgroundColors {
  THEME = 'theme',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  SCRIMMED_DEFAULT = 'scrimmed-default',
  SCRIMMED_ALT = 'scrimmed-alt',
  SCRIMMED_THEME = 'scrimmed-theme',
  SCRIMMED_PRIMARY = 'scrimmed-primary',
  SYSTEM_DANGER = 'system-danger',
  SYSTEM_SUCCESS = 'system-success',
  SYSTEM_INFO = 'system-info',
  SYSTEM_WARNING = 'system-warning',
  SURFACE_1 = 'surface-1',
  SURFACE_2 = 'surface-2',
  SURFACE_3 = 'surface-3',
  SURFACE_4 = 'surface-4',
  SURFACE_DEFAULT = 'surface-default',
  SURFACE_INVERSE = 'surface-inverse',
  SURFACE_ALT = 'surface-alt'
}
