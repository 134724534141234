import { FC } from 'react';

import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';

import { useInvoiceStateContext } from '../../context/invoice-context';
import { Toggle } from '../../models/feature-permissions.model';

import { ClosedInvoices } from './closed-invoices/closed-invoices';
import { ClosedInvoiceFilters } from './closed-invoices/filters/closed-invoice-filters';
import { OpenInvoiceFilters } from './open-invoices/filters/open-invoice-filters';
import { OpenInvoices } from './open-invoices/open-invoices';

type Props = {
   showOpenInvoices: boolean;
   locale: string;
};

export const InvoiceOverview: FC<Props> = ({ showOpenInvoices, locale }) => {
   const { permissions } = useInvoiceStateContext();

   if (showOpenInvoices) {
      return (
         <Box direction="column">
            {permissions?.[Toggle.FILTERS] && <OpenInvoiceFilters />}
            <OpenInvoices locale={locale} />
         </Box>
      );
   }

   if (!permissions?.[Toggle.CLOSED_INVOICES]) {
      return null;
   }

   return (
      <Box direction="column">
         {permissions[Toggle.FILTERS] && <ClosedInvoiceFilters />}
         <ClosedInvoices locale={locale} />
      </Box>
   );
};
