'use client';

import { FC } from 'react';

import { ContentfulTechnologyEntry } from '@ravago/shared/page-data/models/contentful';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { ListItem } from '@ravago/shared/radiance/components/list/components/ListItem/ListItem';

import { ConsumerWrapper } from '../../consumer-wrapper/consumer-wrapper';
import { DynamicPageLink } from '../../dynamic-page-link/dynamic-page-link';
import { MockLink } from '../../mock-link/mock-link';

interface Props {
   technologies: Array<{ sys: { id: string }; fields?: ContentfulTechnologyEntry }>;
   baseRoute?: string;
   isConsumer: boolean;
   locale: string;
}

const TechnologyListItem = ({ technology, baseRoute }: { technology: ContentfulTechnologyEntry; baseRoute?: string }) => (
   <ListItem interactive={false} trailingIcon={baseRoute ? 'chevron-right' : ''} supportingTextVariant="small">
      {technology.name}
   </ListItem>
);

export const TechnologyItemList: FC<Props> = ({ technologies, baseRoute, isConsumer, locale }) => (
   <Box direction="column" gap="none">
      {technologies
         ?.sort((a1, a2) => (a1.fields?.name ?? '').localeCompare(a2.fields?.name ?? ''))
         .filter((technology) => technology.fields)
         .map((technology, index) => (
            <div data-cy="360technology" key={technology.sys.id} style={{ display: 'contents' }}>
               {index > 0 && <Divider />}
               {technology.fields?.slug ? (
                  <ConsumerWrapper
                     isConsumer={isConsumer}
                     renderConsumer={(children) => (
                        <DynamicPageLink
                           id={`dynamic-link__technology-item-list__${technology.fields?.slug}`}
                           slug={technology.fields?.slug ?? ''}
                           baseRoute={{ url: baseRoute, noFollow: technology.fields?.seoMetadata?.fields?.noFollow }}
                           locale={locale}
                           underline="none"
                        >
                           {children}
                        </DynamicPageLink>
                     )}
                     renderProducer={(children) => <MockLink>{children}</MockLink>}
                  >
                     <TechnologyListItem technology={technology.fields} baseRoute={baseRoute} />
                  </ConsumerWrapper>
               ) : (
                  <TechnologyListItem technology={technology.fields ?? {}} baseRoute={baseRoute} />
               )}
            </div>
         ))}
   </Box>
);
