'use client';

import { FC, ReactNode, useMemo } from 'react';

import { documentToHtmlString, Options } from '@contentful/rich-text-html-renderer';
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types';

import { Entry } from 'contentful';
import { renderToString } from 'react-dom/server';

import { useTranslations } from '../../../providers/translation-client-context-provider';
import { replacePlaceholder } from '../../../utils/placeholder/replace-placeholder';
import { Link } from '../link/link';

interface Props {
   value: string;
   data: Entry<unknown> | undefined;
   isConsumer: boolean;
   defaultValues?: Record<string, string>;
   allowDocument?: boolean;
   renderChildValue?: (value: string) => ReactNode;
   isHtml?: boolean;
}

const options: Partial<Options> = {
   renderNode: {
      [INLINES.HYPERLINK]: (node, next) => renderToString(<Link href={node.data.uri}>{next(node.content)}</Link>),
      [BLOCKS.PARAGRAPH]: (node, next) => {
         if (
            node.nodeType === 'paragraph' &&
            (node.content.length === 0 || (node.content[0]?.nodeType === 'text' && node.content[0]?.value === '')) &&
            // prevent hyperlink from becoming <br />
            !(node.content[1]?.nodeType === 'hyperlink')
         )
            return `<br/>`;
         return `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) =>
         renderToString(
            <img
               src={`https://${node.data.target.fields.file.url}`}
               height={node.data.target.fields.file.details.image.height}
               width={node.data.target.fields.file.details.image.width}
               alt={node.data.target.fields.description}
            />
         )
   }
};
const parseDocumentFn = (document: Document) => documentToHtmlString(document, options);

export const PlaceholderText: FC<Props> = ({ value, data, isConsumer, defaultValues, allowDocument = false, renderChildValue, isHtml = false }) => {
   const translate = useTranslations();

   const translatedValues: Record<string, string> = {};

   Object.entries(defaultValues || {}).forEach(([key, translatableKey]) => {
      translatedValues[key] = translate(translatableKey);
   });

   const replacedValue = useMemo(
      () => replacePlaceholder(value, data, isConsumer, { defaultValues: translatedValues, allowDocument, parseDocumentFn }),
      [value, data, translatedValues, allowDocument]
   );

   /**
    * When using a combination of dynamic placeholders and text styles the output becomes invalid HTML.
    * The browser tries to fix the invalid HTML, but this leads to unexpected behavior. eg: the dynamic placeholder not being styled.
    *
    * This is the output, which is invalid:
    * <span style="color: --var(my-css-var)"><p>My replaced dynamic placeholder content</p></span>
    *
    * <p> is invalid inside <span>
    *
    *  The code below removes any <p> tags inside <span> tags thus fixing the issue.
    */
   const validatedValue = replacedValue.replace(
      /<span([^>]*)>((?:.|\n)*?)<\/span>/g,
      (match, attributes, content) => `<span${attributes}>${content.replace(/<\/?p>/g, '')}</span>`
   );

   const returnValue = renderChildValue?.(validatedValue) ?? validatedValue;

   if (!isHtml) return returnValue;

   return <div dangerouslySetInnerHTML={{ __html: returnValue }} />;
};
