import { FC } from 'react';

import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import { useTranslations } from '../../../providers/translation-client-context-provider';

interface Props {
   vatCode: string | undefined;
   invoiceEntity: string | undefined;
   showVatCode: boolean;
}

export const InvoiceInformation: FC<Props> = ({ vatCode, invoiceEntity, showVatCode = false }) => {
   const translate = useTranslations();
   const { phone } = useBreakpoint();

   return (
      <>
         <Box>
            <Typography variant="heading-4" component="h4" color="strong">
               {translate('shipment.invoice-information')}
            </Typography>
         </Box>
         <Box gap={phone ? 'lg' : '2xl'} direction={phone ? 'column' : 'row'}>
            {showVatCode && (
               <Box direction="column" gap="sm">
                  <Typography variant="body" component="span" color="subtle">
                     {translate('shipment.vat-code')}
                  </Typography>
                  <Typography variant="body" component="span">
                     {vatCode || '-'}
                  </Typography>
               </Box>
            )}
            <Box direction="column" gap="sm">
               <Typography variant="body" component="span" color="subtle">
                  {translate('shipment.invoice-entity')}
               </Typography>
               <Typography variant="body" component="span">
                  {invoiceEntity || '-'}
               </Typography>
            </Box>
         </Box>
      </>
   );
};
