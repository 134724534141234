import { FC } from 'react';

import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';

import { MailInfo } from './email-unsubscribe-wrapper';

interface Props {
   list: MailInfo[];
}

export const EmailUnsubscribeOutputList: FC<Props> = ({ list }) => {
   const translate = useTranslations();
   const uniqueLabels = new Set<string>();

   return list
      .filter((item) => item.label && !uniqueLabels.has(item.label) && uniqueLabels.add(item.label))
      .map((item, index, arr) => {
         let separator = '';
         if (index === arr.length - 1 && arr.length > 1) {
            separator += ` ${translate('preference-center.email-unsubscribe.and')} `;
         } else if (index > 0) {
            separator += ', ';
         }
         return (
            <span key={item.label}>
               {separator}{' '}
               <Typography component="span" variant="body-highlight" data-testid="email-unsubscribe-item">
                  {item.label} ({item.description})
               </Typography>
            </span>
         );
      });
};
