'use client';

import { FC, useEffect, useMemo, useState } from 'react';

import { useAuthenticationContext, User, UserGroups } from '@ravago/shared/authentication';
import { UOM } from '@ravago/shared/page-data/models/config';
import { RelatedDocument } from '@ravago/shared/page-data/models/elements';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BoxItem } from '@ravago/shared/radiance/components/box/components/BoxItem/BoxItem';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Checkbox } from '@ravago/shared/radiance/components/checkbox/components/Checkbox/Checkbox';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { DownloadOption } from '@ravago/shared/radiance/components/input-field/components/Download/Download';
import { Tooltip } from '@ravago/shared/radiance/components/tooltip/components/Tooltip/Tooltip';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useDocumentShare } from '../../../../../hooks/use-document-share';
import { useTranslations } from '../../../../../providers/translation-client-context-provider';
import { getLanguageNameFromLanguageCode } from '../../../../../utils/locale/locale.utils';
import { Link } from '../../../../shared/link/link';
import { LoginButton } from '../../../../shared/login-button/login-button';
import { ShareAnchor } from '../../../../shared/share-anchor/share-anchor';
import { RelatedDocumentTdsDownload } from '../related-document-tds-download/related-document-tds-download';
import { RelatedDocumentUlProspectorLink } from '../related-document-tds-download/related-document-ul-prospector-link';

interface BodyProps {
   document: RelatedDocument;
   superCondensedView: boolean;
   sharedDocument: boolean;
   condensedView: boolean;
   hasPublicTdsDownloadPermission: boolean;
   onLanguageChange?: (lan: DownloadOption | undefined) => void;
   onUOMChange?: (uom: DownloadOption | undefined) => void;
   onDocumentDownload: () => void;
   availableLanguages: DownloadOption[];
   channelUOMs: Array<UOM>;
   detailPageName?: string;
   ulProspectorId: string | undefined;
   onShareChecked: (document: RelatedDocument) => void;
   publicShowTdsUom: boolean;
   isAuthenticated: boolean;
   hasSharePermission: boolean;
   selectedUOM?: DownloadOption;
   selectedLanguage: DownloadOption;
   showTdsUom: boolean;
   user?: User;
   language: string;
}

const DocumentListItemBody: FC<BodyProps> = ({
   document,
   hasPublicTdsDownloadPermission,
   superCondensedView,
   sharedDocument,
   condensedView,
   ulProspectorId,
   onDocumentDownload,
   onShareChecked,
   onLanguageChange,
   selectedLanguage,
   hasSharePermission,
   selectedUOM,
   onUOMChange,
   isAuthenticated,
   availableLanguages,
   detailPageName,
   language,
   user,
   channelUOMs,
   publicShowTdsUom,
   showTdsUom
}) => {
   const translate = useTranslations();
   const { shareDocumentList } = useDocumentShare();

   const handleLanguageChange = async (lan: DownloadOption | undefined) => {
      if (lan) onLanguageChange?.(lan);
   };
   const handleUOMChange = async (uom: DownloadOption | undefined) => {
      if (uom) onUOMChange?.(uom);
   };

   const handleDownload = async () => {
      onDocumentDownload?.();
   };

   const isSalesRep = useMemo(() => !!user?.groups?.includes(UserGroups.ravagoSalesRepresentative), [user?.groups]);

   const loginButton = <LoginButton fullwidth={superCondensedView} buttonLabel={translate('related-documents.login-button')} language={language} />;

   const internalUseOnlyBadge = (
      <Tooltip whiteSpace="nowrap" pointers text={translate('related-documents.internal-use-tooltip')}>
         <Badge variant="warning" trailingIcon="info-circle">
            <Typography variant="small" component="p">
               {translate('related-documents.internal-use')}
            </Typography>
         </Badge>
      </Tooltip>
   );

   const fullOrIconButton = superCondensedView ? (
      <Button fullWidth variant="secondary" onClick={() => handleDownload()} leadingIcon="download">
         {translate('product.documents.actions.download')}
      </Button>
   ) : (
      <IconButton variant="on-light" backdrop={false} onClick={() => handleDownload()} icon="download" />
   );

   const waitingOrDownload = !document.isAvailable ? (
      <Box direction="row" items="center" gap="xs">
         <Icon icon="clock" color="weak" />
         <Typography variant="caption">{translate('product.documents.waiting-verification')}</Typography>
      </Box>
   ) : (
      fullOrIconButton
   );

   const isDocumentStandardTds = document.isStandardTDS ? (
      <Typography component="p" variant="caption">
         {translate('related-documents.no-tds-available')}
      </Typography>
   ) : (
      waitingOrDownload
   );

   const downloadButtons =
      document.isStandardTDS && !ulProspectorId && isAuthenticated ? (
         <BreakpointSwitch excludeViews={['phone']}>
            <Badge variant="disabled">{translate('related-documents.not-available')}</Badge>
         </BreakpointSwitch>
      ) : (
         <Box direction="column" gap="xs" items={document.isStandardTDS && superCondensedView && isAuthenticated ? 'center' : 'stretch'}>
            <Box direction="row" gap="sm" justify="end">
               {!isAuthenticated ? loginButton : isDocumentStandardTds}
            </Box>
            {document.isStandardTDS && !!ulProspectorId && isSalesRep && (
               <RelatedDocumentUlProspectorLink language={language} ulProspectorId={ulProspectorId} />
            )}
         </Box>
      );

   const tdsDocument = (
      <>
         <BoxItem grow={4} basis="0">
            <Box direction={condensedView ? 'column' : 'row'} width="100%">
               <Box direction="row" width="100%" items="center" justify="between">
                  <Box direction="column" gap="2xs">
                     <Typography variant="caption">{translate('product.documents.sub-category')}</Typography>
                     <Typography variant="small">{document.type}</Typography>
                  </Box>
                  {!superCondensedView && (
                     <Box direction="column" gap="xs" items="start">
                        <Box gap="sm" justify="end">
                           {isAuthenticated || hasPublicTdsDownloadPermission ? (
                              <RelatedDocumentTdsDownload
                                 documentId={document.id}
                                 showTdsUom={showTdsUom || (!isAuthenticated && publicShowTdsUom)}
                                 onLanguageChange={(lan) => handleLanguageChange(lan)}
                                 onDownload={() => handleDownload()}
                                 onUOMChange={(uom) => handleUOMChange(uom)}
                                 languages={availableLanguages}
                                 ulProspectorId={ulProspectorId}
                                 selectedLanguage={selectedLanguage}
                                 selectedUOM={selectedUOM}
                                 channelUOMs={channelUOMs}
                                 isSalesRep={isSalesRep}
                                 language={language}
                              />
                           ) : (
                              loginButton
                           )}
                           {!sharedDocument &&
                              !document.isStandardTDS &&
                              !condensedView &&
                              !document.isInternal &&
                              isAuthenticated &&
                              hasSharePermission && <ShareAnchor documentInfo={{ detailPageName, name: document.description, id: document.id }} />}
                        </Box>
                        {ulProspectorId && isSalesRep && <RelatedDocumentUlProspectorLink language={language} ulProspectorId={ulProspectorId} />}
                     </Box>
                  )}
               </Box>
            </Box>
         </BoxItem>
         {superCondensedView && (
            <BoxItem grow={1}>
               {isAuthenticated || hasPublicTdsDownloadPermission ? (
                  <RelatedDocumentTdsDownload
                     documentId={document.id}
                     ulProspectorId={ulProspectorId}
                     showTdsUom={showTdsUom || (!isAuthenticated && publicShowTdsUom)}
                     onLanguageChange={(lan) => handleLanguageChange(lan)}
                     onDownload={() => handleDownload()}
                     onUOMChange={(uom) => handleUOMChange(uom)}
                     languages={availableLanguages}
                     selectedLanguage={selectedLanguage}
                     isSalesRep={isSalesRep}
                     selectedUOM={selectedUOM}
                     channelUOMs={channelUOMs}
                     language={language}
                  />
               ) : (
                  loginButton
               )}
            </BoxItem>
         )}
      </>
   );

   const regularDocument = (
      <BoxItem grow={4} basis="0">
         <Box direction={superCondensedView ? 'column' : 'row'} gap="sm" width="100%">
            <Box
               width="100%"
               gap="sm"
               direction={superCondensedView ? 'column' : 'row'}
               justify={superCondensedView ? 'start' : 'between'}
               items={superCondensedView ? 'stretch' : 'center'}
            >
               <Box direction="column" gap="2xs">
                  <Typography variant="caption">{translate('product.documents.sub-category')}</Typography>
                  <Typography variant="small">{document.type}</Typography>
               </Box>
               {(!document.isStandardTDS || (document.isStandardTDS && !superCondensedView)) && (
                  <Box width="340px" direction="row" gap="sm" justify={superCondensedView ? 'start' : 'end'}>
                     <Box width="100%" justify={document.isStandardTDS ? 'end' : 'between'} items="center">
                        {!document.isStandardTDS && (
                           <Box direction="column" gap="2xs">
                              <Typography variant="caption">{translate('product.documents.language')}</Typography>
                              <Typography variant="small">{document.language}</Typography>
                           </Box>
                        )}
                        {!superCondensedView && (document.isAvailable || !isAuthenticated) && downloadButtons}
                     </Box>
                     {isAuthenticated && !document.isAvailable && (
                        <Box direction="row" items="center" gap="xs">
                           <Icon icon="clock" color="weak" />
                           <Typography variant="caption">{translate('product.documents.waiting-verification')}</Typography>
                        </Box>
                     )}
                     {!sharedDocument &&
                        !document.isStandardTDS &&
                        !condensedView &&
                        document.isAvailable &&
                        !document.isInternal &&
                        isAuthenticated &&
                        hasSharePermission && <ShareAnchor documentInfo={{ detailPageName, name: document.description, id: document.id }} />}
                  </Box>
               )}
            </Box>
            {superCondensedView && downloadButtons}
         </Box>
      </BoxItem>
   );

   return (
      <Box direction={condensedView ? 'column' : 'row'} gap="sm" items={condensedView ? 'stretch' : 'center'}>
         {document.isStandardTDS && !ulProspectorId && superCondensedView && (
            <Badge variant="disabled">{translate('related-documents.not-available')}</Badge>
         )}
         {document.isInternal && condensedView && internalUseOnlyBadge}
         <BoxItem grow={3} basis={0}>
            <Box gap="none" justify="between" direction="row">
               {isAuthenticated && document.isAvailable && (
                  <Box gap="xs" items="center">
                     <Box direction="row" gap="2xs" items="center">
                        {!sharedDocument && hasSharePermission && !document.isStandardTDS && (
                           <Checkbox
                              checked={shareDocumentList.documents.map((doc) => doc.id).includes(document.id)}
                              onChange={() => onShareChecked(document)}
                              id={document.id}
                           >
                              {}
                           </Checkbox>
                        )}
                        {document.isStandardTDS ? (
                           <Link
                              disabled={!ulProspectorId}
                              href={`https://materials.ulprospector.com/en/profile/default?e=${ulProspectorId}`}
                              target="_blank"
                           >
                              <Typography color="inherit">{document.description}</Typography>
                           </Link>
                        ) : (
                           // eslint-disable-next-line spaced-comment, jsx-a11y/anchor-is-valid
                           <Link onClick={() => handleDownload()}>
                              <Typography color="inherit">{document.description}</Typography>
                           </Link>
                        )}
                     </Box>
                     {document.isInternal && !condensedView && internalUseOnlyBadge}
                  </Box>
               )}
               {((isAuthenticated && !document.isAvailable) || !isAuthenticated) && (
                  <Box gap="none" items="center">
                     <Typography color="primary">{document.description}</Typography>
                  </Box>
               )}
               {!sharedDocument && !document.isStandardTDS && condensedView && !document.isInternal && isAuthenticated && hasSharePermission && (
                  <ShareAnchor documentInfo={{ detailPageName, name: document.description, id: document.id }} />
               )}
            </Box>
         </BoxItem>
         {document.isBrandedTds ? tdsDocument : regularDocument}
      </Box>
   );
};

interface Props {
   document: RelatedDocument;
   locale: string;
   hasSharePermission: boolean;
   sharedDocument: boolean;
   hasPublicTdsDownloadPermission: boolean;
   onDocumentDownload: (document: RelatedDocument, selectedLanguageId?: string, selectedUOMId?: string) => void;
   onShareChecked: (document: RelatedDocument) => void;
   availableLanguages: DownloadOption[];
   channelUOMs: Array<UOM>;
   detailPageName?: string;
   ulProspectorId: string | undefined;
   language: string;
   publicShowTdsUom: boolean;
   showTdsUom: boolean;
}

const DocumentListItem: FC<Props> = ({
   document,
   hasPublicTdsDownloadPermission,
   hasSharePermission,
   sharedDocument,
   language,
   onDocumentDownload,
   availableLanguages,
   onShareChecked,
   ulProspectorId,
   detailPageName,
   locale,
   channelUOMs,
   publicShowTdsUom,
   showTdsUom
}) => {
   const [selectedLanguage, setSelectedLanguage] = useState<DownloadOption>({
      id: 'en',
      value: getLanguageNameFromLanguageCode('en', locale)
   });
   const [selectedUOM, setSelectedUOM] = useState<DownloadOption>();
   const { isAuthenticated, user } = useAuthenticationContext();

   const handleLanguageChange = async (lan: DownloadOption | undefined) => {
      if (lan) setSelectedLanguage(lan);
   };
   const handleUOMChange = async (uom: DownloadOption | undefined) => {
      if (uom) setSelectedUOM(uom);
   };

   useEffect(() => {
      const defaultUom = channelUOMs.find((uom) => uom.default);
      if (!selectedUOM) setSelectedUOM(defaultUom ?? channelUOMs[0]);
   }, [channelUOMs]);

   return (
      <Box direction="column">
         <BreakpointSwitch includeViews={['desktop']}>
            <DocumentListItemBody
               onShareChecked={onShareChecked}
               sharedDocument={sharedDocument}
               document={document}
               superCondensedView={false}
               ulProspectorId={ulProspectorId}
               condensedView={false}
               hasPublicTdsDownloadPermission={hasPublicTdsDownloadPermission}
               onLanguageChange={(lan) => handleLanguageChange(lan)}
               onUOMChange={(uom) => handleUOMChange(uom)}
               onDocumentDownload={() => onDocumentDownload(document, selectedLanguage.id, selectedUOM?.id)}
               availableLanguages={availableLanguages}
               channelUOMs={channelUOMs}
               detailPageName={detailPageName}
               publicShowTdsUom={publicShowTdsUom}
               showTdsUom={showTdsUom}
               isAuthenticated={isAuthenticated}
               hasSharePermission={hasSharePermission}
               selectedUOM={selectedUOM}
               selectedLanguage={selectedLanguage}
               language={language}
               user={user}
            />
         </BreakpointSwitch>
         <BreakpointSwitch includeViews={['tab-port', 'tab-land']}>
            <DocumentListItemBody
               document={document}
               onShareChecked={onShareChecked}
               superCondensedView={false}
               condensedView
               ulProspectorId={ulProspectorId}
               sharedDocument={sharedDocument}
               hasPublicTdsDownloadPermission={hasPublicTdsDownloadPermission}
               onLanguageChange={(lan) => handleLanguageChange(lan)}
               onUOMChange={(uom) => handleUOMChange(uom)}
               onDocumentDownload={() => onDocumentDownload(document, selectedLanguage.id, selectedUOM?.id)}
               availableLanguages={availableLanguages}
               channelUOMs={channelUOMs}
               detailPageName={detailPageName}
               publicShowTdsUom={publicShowTdsUom}
               showTdsUom={showTdsUom}
               isAuthenticated={isAuthenticated}
               hasSharePermission={hasSharePermission}
               selectedUOM={selectedUOM}
               selectedLanguage={selectedLanguage}
               language={language}
               user={user}
            />
         </BreakpointSwitch>
         <BreakpointSwitch includeViews={['phone']}>
            <DocumentListItemBody
               document={document}
               superCondensedView
               onShareChecked={onShareChecked}
               sharedDocument={sharedDocument}
               condensedView
               ulProspectorId={ulProspectorId}
               hasPublicTdsDownloadPermission={hasPublicTdsDownloadPermission}
               onLanguageChange={(lan) => handleLanguageChange(lan)}
               onUOMChange={(uom) => handleUOMChange(uom)}
               onDocumentDownload={() => onDocumentDownload(document, selectedLanguage.id, selectedUOM?.id)}
               availableLanguages={availableLanguages}
               channelUOMs={channelUOMs}
               detailPageName={detailPageName}
               publicShowTdsUom={publicShowTdsUom}
               showTdsUom={showTdsUom}
               isAuthenticated={isAuthenticated}
               hasSharePermission={hasSharePermission}
               selectedUOM={selectedUOM}
               selectedLanguage={selectedLanguage}
               language={language}
               user={user}
            />
         </BreakpointSwitch>
      </Box>
   );
};

export default DocumentListItem;
