'use client';

import { FC } from 'react';

interface Props {
   id: string;
   checked?: boolean;
   disabled?: boolean;
   required?: boolean;
   readOnly?: boolean;
   onChange?: (state: boolean) => void;
}

export const Switch: FC<Props> = ({ id, checked = false, disabled = false, required = false, readOnly = false, onChange }) => {
   const handleToggle = () => {
      onChange?.(!checked);
   };

   return (
      <div
         className={`gap-component-input-switch-touch-area-with-label-spacing-gap py-component-input-switch-touch-area-spacing-padding-y min-h-component-input-switch-touch-area-sizing-min-size min-w-component-input-switch-touch-area-sizing-min-size group flex cursor-pointer items-center

            `.replace(/\s+/g, ' ')}
      >
         <div
            data-testid="switch-track"
            onClick={handleToggle}
            role="presentation"
            className={`min-w-component-input-switch-track-sizing-min-width min-h-component-input-switch-track-sizing-min-height w-component-input-switch-track-sizing-width h-component-input-switch-track-sizing-height rounded-component-input-switch-track-border-radius border-component-input-switch-track-border-width-unchecked relative flex items-center transition-colors
                  ${disabled && !checked ? 'bg-component-input-switch-track-color-background-unchecked-disabled' : ''}
                  ${!disabled && checked ? 'bg-component-input-switch-track-color-background-checked-enabled' : ''}
                  ${disabled && checked ? 'bg-component-input-switch-track-color-background-checked-disabled' : ''}
                  ${!checked ? 'border-component-input-switch-track-color-border-unchecked' : 'border-transparent'}
                  ${disabled || readOnly ? 'pointer-events-none' : 'cursor-pointer'}
               `.replace(/\s+/g, ' ')}
         >
            <div
               className={`rounded-component-input-switch-state-layer-border-radius w-component-input-switch-thumb-container-sizing-size h-component-input-switch-thumb-container-sizing-size absolute flex items-center justify-center transition-all
                          ${checked ? 'bg-component-input-switch-thumb-color-fill-checked-enabled translate-x-full' : 'bg-component-input-switch-thumb-color-fill-unchecked-enabled'}
                         `}
            >
               <div
                  className={`rounded-component-input-switch-state-layer-border-radius size-[calc(var(--component-input-switch-thumb-container-sizing-size)+(2*var(--component-input-switch-state-layer-spacing-padding)))] absolute flex items-center justify-center transition-all
                            ${
                               checked
                                  ? 'hover:bg-component-input-switch-state-layer-color-background-checked-hover [&:has(:focus-visible)]:bg-component-input-switch-state-layer-color-background-checked-focused active:bg-component-input-switch-state-layer-color-background-checked-pressed'
                                  : 'hover:bg-component-input-switch-state-layer-color-background-unchecked-hover [&:has(:focus-visible)]:bg-component-input-switch-state-layer-color-background-unchecked-focused active:bg-component-input-switch-state-layer-color-background-unchecked-pressed'
                            }
                        `.replace(/\s+/g, ' ')}
               >
                  <input
                     data-testid="switch-input"
                     type="checkbox"
                     className={`after:size-[var(--component-data-display-icon-container-sizing-width-body)] after:typography-component-data-display-icon-glyph-typography-text-body-bold cursor-pointer appearance-none after:transition-all after:content-['\\f111']
                                ${disabled && checked ? 'after:text-component-input-switch-thumb-color-fill-checked-disabled' : ''}
                                ${disabled && !checked ? 'after:text-component-input-switch-thumb-color-fill-unchecked-disabled' : ''}
                                ${!disabled && checked ? 'after:text-component-input-switch-thumb-color-fill-checked-enabled' : ''}
                                ${!disabled && !checked ? 'after:text-component-input-switch-thumb-color-fill-unchecked-enabled' : ''}
                            `.replace(/\s+/g, ' ')}
                     id={id}
                     checked={checked}
                     disabled={disabled}
                     required={required}
                     readOnly={readOnly}
                     onChange={handleToggle}
                  />
               </div>
            </div>
         </div>
      </div>
   );
};
