'use client';

import { FC } from 'react';

import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';

import { useDialogContext } from '../../../hooks/use-dialog-context';

interface Props {
   pageName?: string;
   squareButton?: boolean;
   documentInfo?: { detailPageName?: string; name: string; id: string | undefined };
   variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'error';
   backdrop?: boolean;
}

export const ShareAnchor: FC<Props> = ({ pageName, squareButton, documentInfo, variant, backdrop }) => {
   const { dispatch } = useDialogContext();

   const onSharePageClick = () => {
      if (dispatch) {
         if (pageName) dispatch({ type: 'open_dialog', value: { dialogType: 'share-page' } });
         if (documentInfo)
            dispatch({
               type: 'open_dialog',
               value: {
                  dialogType: 'share-document',
                  shareAnchorInfo: { docName: documentInfo.name, docId: documentInfo.id ?? '', detailPageName: documentInfo.detailPageName ?? '' }
               }
            });
      }
   };

   if (squareButton) {
      return (
         <Button variant={variant ?? 'primary'} onClick={onSharePageClick}>
            <Icon icon="share-alt" />
         </Button>
      );
   }
   return <IconButton backdrop={backdrop ?? false} icon="share-alt" onClick={onSharePageClick} />;
};
