import { FC } from 'react';

import { Invoice } from '@ravago/shared/page-data/models/elements';
import { PaginatedResponse } from '@ravago/shared/page-data/models/elements/paginated-response.model';

import { ClosedInvoiceCard } from './closed-invoice-card';

type Props = {
   invoices: { [page: number]: PaginatedResponse<Invoice[]> };
   page: number;
   locale: string;
};

export const ClosedInvoiceTableMobile: FC<Props> = ({ invoices, page, locale }) =>
   // eslint-disable-next-line react/no-array-index-key
   invoices[page].content.map((invoice, index) => <ClosedInvoiceCard key={index} invoice={invoice} locale={locale} showDivider={index !== 0} />);
