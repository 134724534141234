'use client';

import { FC, useEffect, useState } from 'react';

import { User, UserGroups } from '@ravago/shared/authentication';
import { UserPermissions } from '@ravago/shared/page-data/models/config';
import { ProductDetail } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useCallOffAgreementContext } from '../../../../context/call-off-agreement-context';
import { useFlyoutContext } from '../../../../hooks/use-flyout-context';
import { useRequestForQuotationContext } from '../../../../hooks/use-request-for-quotation-context';
import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { getLanguageCode } from '../../../../utils/locale/locale.utils';
import { TrackingUtils } from '../../../../utils/tracking/tracking.utils';
import { UserUtils } from '../../../../utils/user/user.utils';
import { Link } from '../../../shared/link/link';
import { LoginButton } from '../../../shared/login-button/login-button';

interface Props {
   locale: string;
   product: ProductDetail | undefined;
   userPermissions: UserPermissions;
   user: User | undefined;
   isFlyoutOpen?: boolean;
   isDisabled?: boolean;
}

export const RequestForQuotationButton: FC<Props> = ({ locale, product, userPermissions, isFlyoutOpen, isDisabled, user }) => {
   const translate = useTranslations();
   const language = getLanguageCode(locale);

   const { dispatch: flyoutDispatch } = useFlyoutContext();
   const { state: requestForQuotationState, dispatch: requestForQuotationDispatch, isCallOffValid, isRfqValid } = useRequestForQuotationContext();
   const { state: callOffAgreementState, dispatch: callOffAgreementDispatch } = useCallOffAgreementContext();

   const [requestButtonLabel, setRequestButtonLabel] = useState('request-for-quotation.start-quote-request');

   const onStartQuoteClick = () => {
      if (product) {
         if (flyoutDispatch) {
            flyoutDispatch({
               type: 'open_flyout',
               value: { flyoutType: 'request-for-quotation', title: product?.description }
            });
            setRequestButtonLabel('request-for-quotation.continue-quote-request');
         }

         TrackingUtils.track('rfq_click_start_quote_request', {
            productName: product.description,
            productId: product.id,
            userLoggedIn: JSON.stringify(!!user),
            userType: UserUtils.getUserType(user)
         });
      }
   };

   const continueCallOffOrderClick = () => {
      const currentCallOffOrderId = requestForQuotationState.color.callOffOrderId;
      const currentCallOffOrderQuantity = requestForQuotationState.color.quantity;
      if (!currentCallOffOrderId || !currentCallOffOrderQuantity) return;

      if (callOffAgreementState.callOffId !== currentCallOffOrderId) {
         callOffAgreementDispatch?.({ type: 'clear_call_off', value: {} });
      }
      callOffAgreementDispatch?.({
         type: 'set_call_off_id',
         value: { callOffId: currentCallOffOrderId }
      });

      callOffAgreementDispatch?.({
         type: 'set_opened_from',
         value: { openedFrom: 'dynamic-product-block' }
      });

      callOffAgreementDispatch?.({
         type: 'set_call_off_quantity',
         value: { quantity: currentCallOffOrderQuantity }
      });

      flyoutDispatch?.({
         type: 'open_flyout',
         value: { flyoutType: 'call-off-agreement' }
      });
   };

   const newQuoteFromCallOffClick = () => {
      requestForQuotationDispatch?.({
         type: 'new_quote_from_call_off'
      });
   };

   const newQuoteFromQuotedClick = () => {
      requestForQuotationDispatch?.({
         type: 'new_quote_from_quoted_color'
      });
   };

   useEffect(() => {
      if (!requestForQuotationState.color) {
         setRequestButtonLabel('request-for-quotation.start-quote-request');
      }
   }, [requestForQuotationState.color]);

   useEffect(() => {
      if (isFlyoutOpen && !!user) {
         setRequestButtonLabel('request-for-quotation.continue-quote-request');
      }
   }, [isFlyoutOpen, user]);

   if (!user && !userPermissions.hasAnonymousPermission) {
      return (
         <LoginButton
            data-testid="rfq-button__login"
            trailingIcon="long-arrow-right"
            fullwidth
            buttonLabel={locale ? translate('request-for-quotation.login-to-request') : 'Login to request a quote'}
            language={language}
         />
      );
   }

   if (user?.groups?.includes(UserGroups.digitalPlatformPendingVerification) && !userPermissions.hasPendingPermission) {
      return (
         <Button data-testid="rfq-button__pending" fullWidth trailingIcon="clock" variant="secondary" disabled>
            {locale ? translate('request-for-quotation.waiting-verification') : 'Waiting for account verification'}
         </Button>
      );
   }

   if (user?.groups?.includes(UserGroups.digitalPlatformGuest) && !userPermissions.hasGuestPermission) {
      return (
         <Link underline="none" href={`/${locale}/contact`}>
            <Button data-testid="rfq-button__guest" fullWidth trailingIcon="long-arrow-right" variant="secondary">
               {locale ? translate('request-for-quotation.contact-us') : 'Contact us for information'}
            </Button>
         </Link>
      );
   }

   if (requestForQuotationState.color.callOffOrderId) {
      return (
         <Box direction="column" gap="md">
            <Button
               data-testid="rfq-button__continue"
               fullWidth
               trailingIcon="long-arrow-right"
               disabled={!isCallOffValid}
               onClick={continueCallOffOrderClick}
            >
               {translate('request-for-quotation.continue')}
            </Button>
            <Typography align="center">{translate('request-for-quotation.or')}</Typography>
            <Button data-testid="rfq-button__start" fullWidth trailingIcon="plus" variant="secondary" onClick={newQuoteFromCallOffClick}>
               {translate('request-for-quotation.new-quote')}
            </Button>
         </Box>
      );
   }

   if (requestForQuotationState.color.isQuotedColor) {
      return (
         <Box direction="column" gap="md">
            <Typography align="center">{translate('request-for-quotation.or')}</Typography>
            <Button data-testid="rfq-button__start" fullWidth trailingIcon="plus" variant="secondary" onClick={newQuoteFromQuotedClick}>
               {translate('request-for-quotation.new-quote')}
            </Button>
         </Box>
      );
   }

   return (
      <Button
         data-testid="rfq-button__start"
         fullWidth
         trailingIcon="long-arrow-right"
         disabled={!product || !isRfqValid || isDisabled}
         variant="primary"
         onClick={onStartQuoteClick}
         id="start-request-quote-btn"
      >
         {locale ? translate(requestButtonLabel) : 'Start quote'}
      </Button>
   );
};
