'use client';

import { FC } from 'react';

import { ElementProps } from '@ravago/shared/page-data/models/base';
import { VideoNode } from '@ravago/shared/page-data/models/elements';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { Video } from '@ravago/shared/radiance/components/video/components/Video/Video';

export const VideoElement: FC<ElementProps<VideoNode>> = ({ node, globals }) => {
   const videoElement = (
      <>
         {node.provider === 'contentful' && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
               {node.videoId ? (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <video
                     autoPlay={globals.isConsumer && node.autoplay}
                     muted={node.autoplay}
                     controls={node.controls}
                     loop={node.loop}
                     preload="metadata"
                  >
                     <source src={`${node.videoId.url}#t=0.1&dl=0`} type="video/mp4" />
                     Your browser does not support the video tag.
                  </video>
               ) : (
                  <Typography>Could not load video</Typography>
               )}
            </>
         )}

         {node.provider !== 'contentful' && (
            <Video
               provider={node.provider}
               videoId={node.videoId}
               title={node.title}
               loading="eager"
               autoplay={globals.isConsumer && node.autoplay}
               controls={node.controls}
               loop={node.loop}
            />
         )}
      </>
   );

   return globals?.isConsumer ? videoElement : <div style={{ pointerEvents: 'none' }}>{videoElement}</div>;
};
