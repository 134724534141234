'use client';

import { useEffect, useState } from 'react';

import { useAuthenticationContext, UserGroups } from '@ravago/shared/authentication';
import { Features, FeatureToggle } from '@ravago/shared/page-data/models/config';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';

export const useRoleBasedFeatureToggles = (globals: RendererGlobals, publicFeaturesServerSide?: FeatureToggle[]) => {
   const { token, user } = useAuthenticationContext();
   const {
      isPublic,
      actions: { getPublicToggles, getPrivateToggles }
   } = globals;

   const [privateFeatures, setPrivateFeatures] = useState<FeatureToggle[]>([]);
   const [publicFeatures, setPublicFeatures] = useState<FeatureToggle[]>(publicFeaturesServerSide ?? []);
   const [featurePermissions, setFeaturePermissions] = useState({
      hasTeamdeskFeedbackFeaturePermission: false,
      hasProductCompareFeaturePermission: false,
      hasShareFeaturePermission: false
   });

   useEffect(() => {
      if (isPublic || publicFeaturesServerSide) return;

      getPublicToggles().then((data) => {
         setPublicFeatures(data);
      });
   }, [isPublic]);

   useEffect(() => {
      if (!token || isPublic) return;

      getPrivateToggles(token).then((data) => {
         setPrivateFeatures(data);
      });
   }, [token, isPublic]);

   useEffect(() => {
      if (isPublic) return;

      const publicFeaturesList = publicFeatures.reduce((acc, feature) => {
         acc[feature.name] = feature.allowed;
         return acc;
      }, {} as Features);

      if (!token) {
         // anonymous
         setFeaturePermissions({
            hasTeamdeskFeedbackFeaturePermission: false,
            hasProductCompareFeaturePermission: publicFeaturesList['public-widget-product-compare'],
            hasShareFeaturePermission: publicFeaturesList['public-widget-share-page']
         });
         return;
      }

      const privateFeaturesList = privateFeatures.reduce((acc, feature) => {
         acc[feature.name] = feature.allowed;
         return acc;
      }, {} as Features);

      if (user?.groups.includes(UserGroups.ravagoSalesRepresentative)) {
         // seller
         setFeaturePermissions({
            hasTeamdeskFeedbackFeaturePermission: privateFeaturesList['teamdesk-feedback'],
            hasProductCompareFeaturePermission: privateFeaturesList['product-compare'],
            hasShareFeaturePermission: privateFeaturesList['share-page']
         });
      } else if (
         user?.groups.includes(UserGroups.digitalPlatformGuest) ||
         user?.groups.includes(UserGroups.digitalPlatformPendingVerification) ||
         user?.groups.includes(UserGroups.customerPortal)
      ) {
         // customer, peding or guest
         setFeaturePermissions({
            hasTeamdeskFeedbackFeaturePermission: false,
            hasProductCompareFeaturePermission: privateFeaturesList['customer-widget-product-compare'],
            hasShareFeaturePermission: privateFeaturesList['customer-widget-share-page']
         });
      }
   }, [user, publicFeatures, privateFeatures, isPublic, token]);

   return featurePermissions;
};
