'use client';

import { FC, useMemo } from 'react';

import { BrandsWithProductsItem } from '@ravago/shared/page-data/models/elements';
import { DynamicPageUrl, RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { BrandCard } from '@ravago/shared/radiance/components/card/components/BrandCard/BrandCard';
import { Grid } from '@ravago/shared/radiance/components/grid/components/Grid/Grid';
import { GridCol } from '@ravago/shared/radiance/components/grid/components/GridCol/GridCol';

import { useConfigContext } from '../../../../hooks/use-config-context';
import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { BrandUtils } from '../../../../utils/brand/brand.utils';
import { UrlUtils } from '../../../../utils/url/url.utils';
import { ConsumerWrapper } from '../../consumer-wrapper/consumer-wrapper';
import { DynamicPageLink } from '../../dynamic-page-link/dynamic-page-link';
import { MockLink } from '../../mock-link/mock-link';

interface Props {
   showAllBrandsButton: boolean;
   globals: RendererGlobals;
   baseRoute?: string;
   productsOverviewRoute?: DynamicPageUrl;
   brandsWithProducts: BrandsWithProductsItem[];
   urlQuery?: string;
}

export const BrandList: FC<Props> = ({ globals, showAllBrandsButton, baseRoute, productsOverviewRoute, brandsWithProducts, urlQuery }) => {
   const translate = useTranslations();
   const configContext = useConfigContext();

   const { locale, isConsumer, acceptWebp } = globals;

   const brandCards = useMemo(
      () => brandsWithProducts?.map(({ brand, products }) => BrandUtils.transformToBrandCard(brand, products, acceptWebp)),
      [brandsWithProducts, acceptWebp]
   );

   return (
      <>
         <Grid useContainerQuery>
            {brandCards?.map((brand) => (
               <GridCol key={brand.id} useContainerQuery spanDesktop={3} spanTablet={6} spanPhone={4}>
                  <BrandCard
                     href={UrlUtils.getRoute(locale, baseRoute, brand.slug, undefined, configContext?.useLegacyDigitalPlatformRoutes)}
                     target="_self"
                     noFollow={brand.noFollow}
                     name={brand.name}
                     producer={brand.producer.name}
                     logo={brand.producer.logo}
                     excerpt={brand.excerpt}
                     polymerTypes={brand.polymerTypes}
                     productCount={translate('products-total', { total: brand.numberOfProducts })}
                     fillHeight
                  />
               </GridCol>
            ))}
         </Grid>

         {showAllBrandsButton && (
            <ConsumerWrapper
               isConsumer={isConsumer}
               renderConsumer={(children) => (
                  <DynamicPageLink
                     id={`dynamic-link__brand-list__${urlQuery}`}
                     underline="none"
                     slug={`?tab=brands${urlQuery}`}
                     baseRoute={productsOverviewRoute}
                     locale={locale}
                  >
                     {children}
                  </DynamicPageLink>
               )}
               renderProducer={(children) => <MockLink underline="none">{children}</MockLink>}
            >
               <Button variant="tertiary" trailingIcon="long-arrow-right">
                  {translate('related-brands-2.all-brands')}
               </Button>
            </ConsumerWrapper>
         )}
      </>
   );
};
