import { FC } from 'react';

import { CallOffOrderDetail } from '@ravago/shared/page-data/models/elements';
import { Avatar } from '@ravago/shared/radiance/components/avatar/components/Avatar/Avatar';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { OrderPriceInfoRow } from '../../../shared/order/order-price-info-row';
import { DynamicPageLink } from '../../dynamic-page-link/dynamic-page-link';

interface Props {
   locale: string;
   callOffOrderDetail: CallOffOrderDetail;
   productsBaseRoute: string;
}

export const CallOffOrderInfo: FC<Props> = ({ locale, callOffOrderDetail, productsBaseRoute }) => {
   const translate = useTranslations();

   return (
      <Box direction="column">
         <Box items="center">
            {callOffOrderDetail.contentfulProduct && (
               <Avatar size="large" shape="square" src={callOffOrderDetail.contentfulProduct?.brand?.producer?.logo?.url} />
            )}
            <Box direction="column" gap="none" spacing={{ left: callOffOrderDetail.contentfulProduct ? 'none' : 'md' }}>
               {callOffOrderDetail.contentfulProduct && productsBaseRoute ? (
                  <DynamicPageLink
                     id={`dynamic-link__call-off-order__${callOffOrderDetail.contentfulProduct.slug}`}
                     locale={locale}
                     baseRoute={{
                        url: productsBaseRoute,
                        noFollow: callOffOrderDetail.contentfulProduct.seoMetadata?.noFollow
                     }}
                     slug={callOffOrderDetail.contentfulProduct.slug}
                  >
                     <Typography component="span" variant="body-highlight" color="primary">
                        {callOffOrderDetail.product.description}
                     </Typography>
                  </DynamicPageLink>
               ) : (
                  <Typography component="span" variant="body-highlight" color="strong">
                     {callOffOrderDetail.product.description}
                  </Typography>
               )}
               {callOffOrderDetail.customerProductReferences.length > 0 && (
                  <Typography component="span" variant="body" color="subtle">
                     {callOffOrderDetail.customerProductReferences.join(' • ')}
                  </Typography>
               )}
            </Box>
         </Box>
         <Table tableLayout="fixed" stroked="horizontal" rowDividers={false} hover={false}>
            <TableBody>
               <TableRow>
                  <TableCell>
                     <Typography component="b" variant="body-highlight" color="primary">
                        {translate('my-orders.order-reference')}
                     </Typography>
                  </TableCell>
                  <TableCell>{callOffOrderDetail.customerOrderReferences.join(' • ') || '-'}</TableCell>
               </TableRow>
               <TableRow>
                  <TableCell>
                     <Typography component="b" variant="body-highlight" color="primary">
                        {translate('my-orders.total-quantity')}
                     </Typography>
                  </TableCell>
                  <TableCell>
                     {callOffOrderDetail.totalQuantity.amount} {callOffOrderDetail.totalQuantity.unitOfMeasure}
                  </TableCell>
               </TableRow>
               <TableRow>
                  <TableCell>
                     <Typography component="b" variant="body-highlight" color="primary">
                        {translate('my-orders.packaging')}
                     </Typography>
                  </TableCell>
                  <TableCell>{callOffOrderDetail.packagings.join(' • ')}</TableCell>
               </TableRow>
               <OrderPriceInfoRow unitPrice={callOffOrderDetail.unitPrice} isUnitPriceFixed={callOffOrderDetail.isUnitPriceFixed} />
               <TableRow>
                  <TableCell>
                     <Typography component="b" variant="body-highlight" color="primary">
                        {translate('my-orders.comment')}
                     </Typography>
                  </TableCell>
                  <TableCell>{callOffOrderDetail.orderConfirmationTexts.join(' • ') || '-'}</TableCell>
               </TableRow>
            </TableBody>
         </Table>
      </Box>
   );
};
