import { FeatureToggle } from '@ravago/shared/page-data/models/config';
import { Invoice, InvoiceForCurrencyForLegalEntity, InvoiceQueryParams } from '@ravago/shared/page-data/models/elements';
import { PaginatedResponse } from '@ravago/shared/page-data/models/elements/paginated-response.model';

import { BillingType } from '../models/billing-type.model';
import { OpenInvoiceFilters } from '../models/filters.model';

// eslint-disable-next-line no-shadow
export enum InvoiceAction {
   DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT',
   OPEN_INVOICES_NOT_FOUND = 'OPEN_INVOICES_NOT_FOUND',
   CLOSED_INVOICES_NOT_FOUND = 'CLOSED_INVOICES_NOT_FOUND',
   SET_BILLING_TYPE = 'SET_BILLING_TYPE',
   SET_CLOSED_INVOICES = 'SET_CLOSED_INVOICES',
   SET_OPEN_INVOICES = 'SET_OPEN_INVOICES',
   SET_PERMISSIONS = 'SET_PERMISSIONS',
   SET_CLOSED_INVOICES_QUERY_PARAMS = 'SET_CLOSED_INVOICES_QUERY_PARAMS',
   SET_OPEN_INVOICE_FILTERS = 'SET_OPEN_INVOICE_FILTERS',
   SET_FILTERED_OPEN_INVOICES = 'SET_FILTERED_OPEN_INVOICES',
   SET_DISABLE_OVERDUE_FILTER = 'SET_DISABLE_OVERDUE_FILTER'
}

export type DownloadDocumentAction = { type: InvoiceAction.DOWNLOAD_DOCUMENT; payload: { invoiceId: string; documentId: string } | null };

export type SetBillingTypeAction = { type: InvoiceAction.SET_BILLING_TYPE; payload: BillingType };

export type SetClosedInvoicesAction = { type: InvoiceAction.SET_CLOSED_INVOICES; payload: PaginatedResponse<Invoice[]> };

export type SetClosedInvoicesQueryParamsAction = {
   type: InvoiceAction.SET_CLOSED_INVOICES_QUERY_PARAMS;
   payload: InvoiceQueryParams;
};

export type SetOpenInvoicesAction = { type: InvoiceAction.SET_OPEN_INVOICES; payload: InvoiceForCurrencyForLegalEntity[] };

export type SetPermissionsAction = { type: InvoiceAction.SET_PERMISSIONS; payload: FeatureToggle[] };

export type OpenInvoicesNotFountAction = { type: InvoiceAction.OPEN_INVOICES_NOT_FOUND };

export type ClosedInvoicesNotFountAction = { type: InvoiceAction.CLOSED_INVOICES_NOT_FOUND };

export type SetOpenInvoiceFiltersAction = { type: InvoiceAction.SET_OPEN_INVOICE_FILTERS; payload: OpenInvoiceFilters };

export type SetFilteredOpenInvoicesAction = { type: InvoiceAction.SET_FILTERED_OPEN_INVOICES; payload: InvoiceForCurrencyForLegalEntity[] | null };

export type SetDisableOverdueFilterAction = {
   type: InvoiceAction.SET_DISABLE_OVERDUE_FILTER;
   payload: boolean;
};

export type Action =
   | DownloadDocumentAction
   | SetBillingTypeAction
   | SetClosedInvoicesAction
   | SetClosedInvoicesQueryParamsAction
   | SetOpenInvoicesAction
   | SetPermissionsAction
   | OpenInvoicesNotFountAction
   | ClosedInvoicesNotFountAction
   | SetOpenInvoiceFiltersAction
   | SetFilteredOpenInvoicesAction
   | SetDisableOverdueFilterAction;
