'use client';

import { FC, useMemo } from 'react';

import { ContentfulPaginatedResponse, ContentfulProduct } from '@ravago/shared/page-data/models/contentful';
import { AutoSuggestResponseContent } from '@ravago/shared/page-data/models/elastic';
import { ProductListerBlockNode } from '@ravago/shared/page-data/models/elements';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Paper } from '@ravago/shared/radiance/components/paper/components/Paper/Paper';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { getLanguageCode } from '../../../../utils/locale/locale.utils';
import { AutoSuggestPages } from '../../../shared/auto-suggest/components/auto-suggest-pages';

interface Props {
   node: ProductListerBlockNode;
   globals: RendererGlobals;
   baseRoute?: string;
   products: ContentfulPaginatedResponse<ContentfulProduct[]>;
}

/* c8 ignore start */
export const ProductListerBlock: FC<Props> = ({ node, globals, baseRoute, products }) => {
   const translate = useTranslations();
   const language = getLanguageCode(globals.locale);
   const { desktop } = useBreakpoint();

   const visualizeProducts: AutoSuggestResponseContent[] = useMemo(() => {
      if (!products) return [];
      return products.items.map((product) => ({
         id: parseInt(product.id.replace('product-', ''), 10),
         label: product.name,
         group: product.polymerGroup,
         subgroup: product.polymerSubGroup,
         slug: product.slug,
         subLabel: product.polymerGroup,
         preferred: product.isPreferred ? 1 : undefined,
         type: 'Product'
      }));
   }, [products]);

   const onCheckProductsList = () => {
      window.dispatchEvent(new CustomEvent('onCheckProductsList'));

      document.getElementById(node.productListAnchor)?.scrollIntoView({ behavior: 'smooth' });
   };

   const autoSuggestPages = (
      <>
         <AutoSuggestPages
            title={node.productListTitle}
            suggestedPages={visualizeProducts}
            itemCount={5}
            language={language}
            globals={globals}
            baseRoutes={baseRoute ? { product: baseRoute, caseStudy: '', brand: '' } : undefined}
         />
         {visualizeProducts.length > 5 && (
            <Button disabled={!node.productListAnchor} onClick={onCheckProductsList} badge={visualizeProducts.length.toString()}>
               {translate('product-lister-block-view-more')}
            </Button>
         )}
      </>
   );

   return desktop ? (
      <Paper shadow border={false}>
         <Box spacing={{ top: 'xl', right: 'lg', bottom: 'xl', left: 'lg' }} direction="column">
            {autoSuggestPages}
         </Box>
      </Paper>
   ) : (
      <Box direction="column">{autoSuggestPages}</Box>
   );
};
/* c8 ignore end */
