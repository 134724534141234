'use client';

import { FC, useState } from 'react';

import { ElementProps } from '@ravago/shared/page-data/models/base';
import { TextAccordionNode } from '@ravago/shared/page-data/models/elements';
import { Accordion } from '@ravago/shared/radiance/components/accordion/components/Accordion/Accordion';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { defaultValues } from '../../../utils/placeholder/replace-placeholder';
import { PlaceholderText } from '../../shared/placeholder-text/placeholder-text';

export const TextAccordionElement: FC<ElementProps<TextAccordionNode>> = ({ node, globals }) => {
   const [open, setOpen] = useState<boolean>(node.defaultState);

   const handleClick = () => {
      setOpen(!open);
   };

   return (
      <Accordion
         open={open}
         header={
            <Box direction="row" justify="between" items="center" onClick={handleClick}>
               <Typography variant="body-highlight">
                  <PlaceholderText
                     value={node.title}
                     data={globals?.contentEntity}
                     isConsumer={!!globals?.isConsumer}
                     defaultValues={defaultValues}
                  />
               </Typography>
               <Icon icon={open ? 'chevron-down' : 'chevron-up'} />
            </Box>
         }
         body={
            <Typography container>
               <PlaceholderText
                  value={node.body ?? ''}
                  data={globals?.contentEntity}
                  isConsumer={!!globals?.isConsumer}
                  defaultValues={defaultValues}
                  allowDocument
                  renderChildValue={(value) => (
                     // eslint-disable-next-line react/no-danger
                     <div style={{ display: 'contents' }} dangerouslySetInnerHTML={{ __html: value.replace(/<p><\/p>/g, '') }} />
                  )}
               />
            </Typography>
         }
      />
   );
};
