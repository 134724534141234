import { FC } from 'react';

import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';

import { EmailUnsubscribeOutputList } from './email-unsubscribe-output-list';
import { MailInfo } from './email-unsubscribe-wrapper';

interface Props {
   emailAddress: string;
   impactedMails: MailInfo[];
   isSuccess: boolean;
}

export const EmailAlreadyUnsubscribed: FC<Props> = ({ impactedMails, emailAddress, isSuccess }) => {
   const translate = useTranslations();

   return (
      <Box direction="column" gap="md" items="center">
         <Typography component="h2" variant="heading-2">
            <Typography data-testid="email-unsubscribed-title" component="span" variant="heading-2">
               {isSuccess ? (
                  <>
                     <Icon icon="check" size="heading-2" />
                     {translate('preference-center.email-unsubscribe.title-unsubscribed-successfully')}
                  </>
               ) : (
                  translate('preference-center.email-unsubscribe.title-already-unsubscribed')
               )}
            </Typography>
         </Typography>
         <Typography component="p" variant="body" data-testid="email-already-unsubscribed-email">
            {translate('preference-center.email-unsubscribe.unsubscribed-email', { email: emailAddress })}
         </Typography>
         <Typography component="p" variant="body">
            <EmailUnsubscribeOutputList list={impactedMails} />
         </Typography>
      </Box>
   );
};
