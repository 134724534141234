'use client';

import { FC, Fragment, useState } from 'react';

import { SystemPageType } from '@ravago/shared/page-data/enums/system-page-type';
import { AspectRatio } from '@ravago/shared/radiance/components/aspect-ratio/components/AspectRatio/AspectRatio';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Image } from '@ravago/shared/radiance/components/image/components/Image/Image';
import { List } from '@ravago/shared/radiance/components/list/components/List/List';
import { ListItem } from '@ravago/shared/radiance/components/list/components/ListItem/ListItem';
import { Menu } from '@ravago/shared/radiance/components/menu/components/Menu/Menu';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableHead } from '@ravago/shared/radiance/components/table/components/TableHead/TableHead';
import { TableHeader } from '@ravago/shared/radiance/components/table/components/TableHeader/TableHeader';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import { ShipmentOrder } from 'libs/shared/page-data/src/lib/models/elements/order.model';

import { useOrderLineContext } from '../../../../context/order-line-context';
import { useFlyoutContext } from '../../../../hooks/use-flyout-context';
import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { AssetUtils } from '../../../../utils/asset/asset.utils';
import { getLanguageCode } from '../../../../utils/locale/locale.utils';
import { Link } from '../../../shared/link/link';
import { MockLink } from '../../mock-link/mock-link';
import { SystemPageLink } from '../../system-page-link/system-page-link';

interface Props {
   locale: string;
   isConsumer: boolean;
   acceptWebp?: boolean;
   assetBasePath: string;
   orders: ShipmentOrder[];
   orderDetailBaseUrl: string;
   callOffAgreementBaseUrl: string;
   isPreview?: boolean;
}

export const ShipmentProducts: FC<Props> = ({
   locale,
   orders,
   isConsumer,
   acceptWebp,
   assetBasePath,
   orderDetailBaseUrl,
   callOffAgreementBaseUrl,
   isPreview
}) => {
   const translate = useTranslations();
   const language = getLanguageCode(locale);
   const { dispatch: flyoutDispatch } = useFlyoutContext();
   const { dispatch: orderLineDispatch } = useOrderLineContext();
   const { desktop, tabLand } = useBreakpoint();

   const [menuOpenIndex, setMenuOpenIndex] = useState<number | undefined>();

   const onMenuToggle = (index: number) => setMenuOpenIndex((curr) => (curr !== undefined ? undefined : index));

   const onMenuClose = () => setMenuOpenIndex(undefined);

   const onOrderLineButtonClick = async (orderLine: ShipmentOrder) => {
      setMenuOpenIndex(undefined);

      if (isConsumer) {
         if (orderLineDispatch) {
            const orderNumber = orderLine.orderNumber.split('-');

            orderLineDispatch({
               type: 'set_order',
               value: {
                  orderId: +orderNumber[0],
                  orderNumber: +orderNumber[1],
                  contentfulProduct: orderLine.contentfulProduct,
                  openedFrom: 'shipment_detail'
               }
            });
         }
         if (flyoutDispatch) {
            flyoutDispatch({
               type: 'open_flyout',
               value: { flyoutType: 'order-line', title: 'Order line' }
            });
         }
      }
   };

   return (
      <>
         <Box>
            <Typography variant="heading-4" component="h4" color="strong">
               {translate('shipment.products.products')}
            </Typography>
         </Box>
         {(desktop || tabLand) && !isPreview ? (
            <Table rounded border hover={false}>
               <TableHead>
                  <TableRow>
                     <TableHeader> {translate('shipment.products.product')}</TableHeader>
                     <TableHeader> {translate('shipment.products.order-line-number')}</TableHeader>
                     <TableHeader> {translate('shipment.products.quantity')}</TableHeader>
                     <TableHeader> {translate('shipment.products.packaging')}</TableHeader>
                     <TableHeader>{}</TableHeader>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {orders.map((order, index) => (
                     <TableRow key={order.orderNumber} onClick={() => {}}>
                        <TableCell onClick={() => onOrderLineButtonClick(order)}>
                           <Box>
                              <AspectRatio aspectRatio="1:1">
                                 <Image
                                    src={
                                       order.contentfulProduct?.brand?.producer?.logo?.url || `${assetBasePath}/images/placeholders/default-logo.png`
                                    }
                                    alt={order.product.description}
                                    title={order.product.description}
                                    height="52px"
                                    width="52px"
                                    objectFit="contain"
                                 />
                              </AspectRatio>
                              <Box direction="column" gap="none" justify="center">
                                 <Typography component="span" variant="body-highlight">
                                    {order.product.description}
                                 </Typography>
                                 {order.customerOrderReference && (
                                    <Typography component="span" variant="small" color="subtle">
                                       {translate('shipment.order-reference')} {order.customerOrderReference}
                                    </Typography>
                                 )}
                              </Box>
                           </Box>
                        </TableCell>
                        <TableCell onClick={() => onOrderLineButtonClick(order)}>{order.orderNumber}</TableCell>
                        <TableCell onClick={() => onOrderLineButtonClick(order)}>
                           {order.quantity.amount} {order.quantity.unitOfMeasure}
                        </TableCell>
                        <TableCell onClick={() => onOrderLineButtonClick(order)}>{order.packaging}</TableCell>
                        <TableCell width="60px">
                           <Menu
                              open={menuOpenIndex === index}
                              closeOnOutsideClick
                              align="right"
                              onMenuClose={() => onMenuClose()}
                              content={
                                 <List>
                                    <ListItem onClick={() => onOrderLineButtonClick(order)}>{translate('shipment.table.show-more')}</ListItem>
                                    {isConsumer ? (
                                       <>
                                          <SystemPageLink
                                             baseRoute={orderDetailBaseUrl}
                                             type={SystemPageType.ORDER_DETAIL}
                                             locale={locale}
                                             slug={order.orderNumber.split('-')[0]}
                                          >
                                             <ListItem>{translate('shipment.table.order-details')}</ListItem>
                                          </SystemPageLink>
                                          {callOffAgreementBaseUrl && order.agreementNumber !== undefined && (
                                             <SystemPageLink
                                                baseRoute={callOffAgreementBaseUrl}
                                                type={SystemPageType.CALL_OFF_ORDER_DETAIL}
                                                locale={locale}
                                                slug={order.agreementNumber}
                                             >
                                                <ListItem>{translate('shipment.table.call-off-agreement')}</ListItem>
                                             </SystemPageLink>
                                          )}
                                          <Link href={`/my/${language}/my-prices`} prefetch={false}>
                                             <ListItem>{translate('shipment.products.new-quote')}</ListItem>
                                          </Link>
                                       </>
                                    ) : (
                                       <>
                                          <MockLink>
                                             <ListItem>{translate('shipment.table.order-details')}</ListItem>
                                          </MockLink>
                                          <MockLink>
                                             <ListItem>{translate('shipment.products.new-quote')}</ListItem>
                                          </MockLink>
                                       </>
                                    )}
                                 </List>
                              }
                           >
                              <Box content="end" items="end">
                                 <IconButton icon="ellipsis-vertical" type="button" variant="on-light" onClick={() => onMenuToggle(index)} />
                              </Box>
                           </Menu>
                        </TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         ) : (
            <Table rowDividers={false} rounded border tableLayout="auto">
               <TableBody>
                  {orders.map((order, index) => (
                     <Fragment key={order.orderNumber}>
                        <TableRow>
                           <TableCell colSpan={2} onClick={() => onOrderLineButtonClick(order)}>
                              <Box spacing={{ top: 'md' }}>
                                 <AspectRatio aspectRatio="1:1">
                                    <Image
                                       src={
                                          order.contentfulProduct?.brand?.producer?.logo?.url
                                             ? AssetUtils.optimizeImageIfRequired(order.contentfulProduct.brand.producer.logo.url, acceptWebp)
                                             : `${assetBasePath}/images/placeholders/default-logo.png`
                                       }
                                       alt={order.product.description}
                                       title={order.product.description}
                                       height="52px"
                                       width="52px"
                                       objectFit="contain"
                                    />
                                 </AspectRatio>
                                 <Box direction="column" gap="none">
                                    {order.customerOrderReference && (
                                       <Typography component="span" variant="body" color="subtle">
                                          {order.customerOrderReference}
                                       </Typography>
                                    )}
                                    <Typography component="span" variant="body-highlight">
                                       {order.product.description}
                                    </Typography>
                                 </Box>
                              </Box>
                           </TableCell>
                           <TableCell width="60px">
                              <Menu
                                 open={menuOpenIndex === index}
                                 closeOnOutsideClick
                                 align="right"
                                 onMenuClose={() => onMenuClose()}
                                 content={
                                    <List>
                                       <ListItem onClick={() => onOrderLineButtonClick(order)}>{translate('shipment.table.show-more')}</ListItem>
                                       {isConsumer ? (
                                          <>
                                             <SystemPageLink
                                                baseRoute={orderDetailBaseUrl}
                                                type={SystemPageType.ORDER_DETAIL}
                                                locale={locale}
                                                slug={order.orderNumber.split('-')[0]}
                                             >
                                                <ListItem>{translate('shipment.table.order-details')}</ListItem>
                                             </SystemPageLink>
                                             {callOffAgreementBaseUrl && order.agreementNumber !== undefined && (
                                                <SystemPageLink
                                                   baseRoute={callOffAgreementBaseUrl}
                                                   type={SystemPageType.CALL_OFF_ORDER_DETAIL}
                                                   locale={locale}
                                                   slug={order.agreementNumber}
                                                >
                                                   <ListItem>{translate('shipment.table.call-off-agreement')}</ListItem>
                                                </SystemPageLink>
                                             )}
                                             <Link href={`/my/${language}/my-prices`} prefetch={false}>
                                                <ListItem>{translate('shipment.products.new-quote')}</ListItem>
                                             </Link>
                                          </>
                                       ) : (
                                          <>
                                             <MockLink>
                                                <ListItem>{translate('shipment.table.order-details')}</ListItem>
                                             </MockLink>
                                             <MockLink>
                                                <ListItem>{translate('shipment.products.new-quote')}</ListItem>
                                             </MockLink>
                                          </>
                                       )}
                                    </List>
                                 }
                              >
                                 <Box content="end" items="end">
                                    <IconButton icon="ellipsis-vertical" type="button" variant="on-light" onClick={() => onMenuToggle(index)} />
                                 </Box>
                              </Menu>
                           </TableCell>
                        </TableRow>
                        <TableRow onClick={() => onOrderLineButtonClick(order)}>
                           <TableCell width="120px">
                              <Typography variant="body" color="subtle" component="span">
                                 {translate('shipment.products.order-line-number')}
                              </Typography>
                           </TableCell>
                           <TableCell>{order.customerProductReference}</TableCell>
                        </TableRow>
                        <TableRow onClick={() => onOrderLineButtonClick(order)}>
                           <TableCell width="120px">
                              <Typography variant="body" color="subtle" component="span">
                                 {translate('shipment.products.quantity')}
                              </Typography>
                           </TableCell>
                           <TableCell>
                              {order.quantity.amount} {order.quantity.unitOfMeasure}
                           </TableCell>
                        </TableRow>
                        <TableRow divider onClick={() => onOrderLineButtonClick(order)}>
                           <TableCell width="120px">
                              <Typography variant="body" color="subtle" component="span">
                                 {translate('shipment.products.packaging')}
                              </Typography>
                           </TableCell>
                           <TableCell>{order.packaging}</TableCell>
                        </TableRow>
                     </Fragment>
                  ))}
               </TableBody>
            </Table>
         )}
      </>
   );
};
