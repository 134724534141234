import { FC, useMemo } from 'react';

import { TransactionType } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../../../../../providers/translation-client-context-provider';
import { calculateOverDueDays, isOverDue } from '../../../../../utils/invoice-date.utils';

type Props = {
   dueDate?: string;
   invoiceType: TransactionType;
};

export const InvoiceDueDate: FC<Props> = ({ dueDate, invoiceType }) => {
   const translate = useTranslations();

   const isCheque = invoiceType === TransactionType.CHEQUE;
   const isInvoiceOverDue = useMemo(() => isOverDue(dueDate), [dueDate]);
   const overDueDays = useMemo(() => calculateOverDueDays(dueDate), [dueDate]);

   if (isInvoiceOverDue) {
      return (
         <Typography variant="body-highlight" color="error">
            {dueDate} - {translate(`invoices.open-invoices.table.past-due${overDueDays === 1 ? 'singular' : ''}`, { dueDays: overDueDays })}
         </Typography>
      );
   }

   return (
      <Box direction="column" gap="3xs">
         <Typography>{dueDate}</Typography>
         {isCheque && <Typography variant="caption">{translate('invoices.open-invoices.table.date-of-cashing')}</Typography>}
      </Box>
   );
};
