'use client';

import { FC, HTMLAttributeAnchorTarget } from 'react';

import useId from '@ravago/shared/radiance/hooks/useId/useId';

import classNames from 'classnames';

import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

export interface TabProps {
   id?: string;
   label: string;
   leadingIcon?: string;
   trailingIcon?: string;
   href?: string;
   target?: HTMLAttributeAnchorTarget;
   active?: boolean;
   height?: string;
   width?: string;
   onClick?: () => void;
}

export const Tab: FC<TabProps> = ({ id, active = false, height, width, onClick, label, leadingIcon, trailingIcon, href, target }) => {
   const elementId = useId(id, 'tab');

   return (
      <button
         data-testid={`tab-${label}${active ? '--active' : ''}`}
         id={elementId}
         onClick={onClick}
         key={label}
         type="button"
         style={{ height, width }}
         className={classNames(
            `hover:bg-component-navigation-tab-state-layer-color-background-active-hover focus-visible:bg-component-navigation-tab-state-layer-color-background-active-focused active:bg-component-navigation-tab-state-layer-color-background-active-pressed disabled:hover:bg-component-navigation-tab-state-layer-color-background-inactive-hover disabled:focus-visible:bg-component-navigation-tab-state-layer-color-background-inactive-focused disabled:active:bg-component-navigation-tab-state-layer-color-background-inactive-pressed z-10 appearance-none bg-transparent transition-colors`,
            active ? 'border-b-component-navigation-tab-container-border-width-active border-b-component-navigation-tab-container-color-border-active' : 'border-b-component-navigation-tab-container-border-width-inactive border-b-component-navigation-tab-container-color-border-inactive'
         )}
      >
         <a
            href={href}
            target={target}
            style={{ height, width }}
            className="min-h-component-navigation-tab-container-sizing-min-height px-component-navigation-tab-container-spacing-padding-x py-component-navigation-tab-container-spacing-padding-y gap-component-navigation-tab-container-spacing-gap box-border flex h-full items-center justify-center"
         >
            {leadingIcon && (
               <div data-testid={`tab-${label}-leading-icon`} className="text-component-navigation-tab-icon-color-fill">
                  <Icon icon={leadingIcon} />
               </div>
            )}
            <div className="text-component-navigation-tab-label-color-text">
               <Typography color="inherit" whiteSpace="nowrap">
                  {label}
               </Typography>
            </div>
            {trailingIcon && (
               <div data-testid={`tab-${label}-trailing-icon`} className="text-component-navigation-tab-icon-color-fill">
                  <Icon icon={trailingIcon} />
               </div>
            )}
         </a>
      </button>
   );
};
