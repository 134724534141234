import { FC, MouseEvent, useState } from 'react';

import { useBodyOverflowHidden } from '../../../../hooks/useBodyOverflowHidden/useBodyOverflowHidden';
import { useKeyboardShortcut } from '../../../../hooks/useKeyboardShortcut/useKeyboardShortcut';
import { IconButton } from '../../../button/components/IconButton/IconButton';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Item {
   src: string;
   alt: string;
   title: string;
}

interface Props {
   items: Item[];
   initialSelectedItemIndex: number;
   onClose: () => void;
}

export const Lightbox: FC<Props> = ({ items, initialSelectedItemIndex, onClose }) => {
   const [selectedItemIndex, setSelectedItemIndex] = useState(initialSelectedItemIndex);

   const showNextItem = () => {
      setSelectedItemIndex((curr) => {
         let nextIndex = curr + 1;
         if (nextIndex >= items.length) nextIndex = 0;
         return nextIndex;
      });
   };

   const showPreviousItem = () => {
      setSelectedItemIndex((curr) => {
         let nextIndex = curr - 1;
         if (nextIndex < 0) nextIndex = items.length - 1;
         return nextIndex;
      });
   };

   useKeyboardShortcut('Escape', () => onClose());
   useKeyboardShortcut('ArrowRight', () => showNextItem());
   useKeyboardShortcut('ArrowLeft', () => showPreviousItem());

   useBodyOverflowHidden();

   const handleNextItem = (e?: MouseEvent<HTMLDivElement>) => {
      e?.stopPropagation();
      showNextItem();
   };

   const handlePreviousItem = (e?: MouseEvent<HTMLDivElement>) => {
      e?.stopPropagation();
      showPreviousItem();
   };

   const selectedItem = items[selectedItemIndex];
   const hasSingleItem = items.length <= 1;

   return (
      <div data-testid="lightbox-backdrop" role="presentation" className="bg-component-surfaces-overlay-container-color-background-scrimmed-alternate fixed inset-0 z-[9999] flex items-center justify-center empty:hidden" onClick={onClose}>
         <div className="gap-theme-spacing-xl phone:p-theme-spacing-2xl tab-port:p-theme-spacing-3xl tab-land:p-theme-spacing-3xl p-theme-spacing-6xl flex h-screen w-screen flex-col">
            <div className="flex justify-end">
               <IconButton icon="times" variant="on-dark" backdrop={false} onClick={onClose} />
            </div>

            <div className="flex grow flex-row items-center">
               <span data-testid="lightbox-previous">
                  <IconButton icon="chevron-left" variant="on-dark" backdrop={false} disabled={hasSingleItem} onClick={handlePreviousItem} />
               </span>

               <div className="relative size-full flex-1">
                  <img data-testid={`lightbox-selected-item-${selectedItemIndex}`} src={selectedItem.src} alt={selectedItem.alt} title={selectedItem.title} className="absolute inset-y-0 block size-full object-scale-down" />
               </div>

               <span data-testid="lightbox-next">
                  <IconButton icon="chevron-right" variant="on-dark" backdrop={false} disabled={hasSingleItem} onClick={handleNextItem} />
               </span>
            </div>

            <Typography color="on-dark-strong" align="center" variant="small">
               {selectedItemIndex + 1} / {items.length}
            </Typography>
         </div>
      </div>
   );
};
