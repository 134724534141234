import { FC } from 'react';

import { DisplayOrder, OrderLineOpenedFrom } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Pagination } from '@ravago/shared/radiance/components/pagination/components/Pagination/Pagination';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../providers/translation-client-context-provider';
import { DateUtils } from '../../../utils/date/date.utils';
import { OrderLineItem } from '../order/order-line-item';

import { OrderLineTableMenu } from './order-line-table-menu';

interface MobileOrderLinesTableProps {
   orderLines: DisplayOrder[];
   language: string;
   locale: string;
   startIndex: number;
   endIndex: number;
   tableViewItems: number;
   page: number;
   acceptWebp?: boolean;
   shipmentBaseUrl: string;
   orderDetailBaseUrl?: string;
   callOffAgreementBaseUrl?: string;
   openedFrom: OrderLineOpenedFrom;
   onOrderLineButtonClick: (orderLine: DisplayOrder) => void;
   onPageChange: (newPage: number) => void;
}

export const MobileOrderLinesTable: FC<MobileOrderLinesTableProps> = ({
   orderLines,
   language,
   locale,
   onOrderLineButtonClick,
   startIndex,
   endIndex,
   shipmentBaseUrl,
   orderDetailBaseUrl,
   callOffAgreementBaseUrl,
   openedFrom,
   tableViewItems,
   onPageChange,
   acceptWebp,
   page
}) => {
   const translate = useTranslations();

   return (
      <>
         <Table rowDividers={false} rounded border tableLayout="auto">
            {orderLines
               .filter((_, index) => index >= startIndex && index < endIndex)
               .map((orderLine) => (
                  <TableBody key={orderLine.orderNumber}>
                     <TableRow>
                        <TableCell colSpan={2}>
                           <Box justify="between">
                              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                              <div onClick={() => onOrderLineButtonClick(orderLine)}>
                                 <OrderLineItem order={orderLine} language={language} openedFrom={openedFrom} acceptWebp={acceptWebp} />
                              </div>
                              <Box justify="end">
                                 <OrderLineTableMenu
                                    orderLine={orderLine}
                                    locale={locale}
                                    language={language}
                                    shipmentBaseUrl={shipmentBaseUrl}
                                    orderDetailBaseUrl={orderDetailBaseUrl}
                                    callOffAgreementBaseUrl={callOffAgreementBaseUrl}
                                    onOrderLineButtonClick={onOrderLineButtonClick}
                                 />
                              </Box>
                           </Box>
                        </TableCell>
                     </TableRow>
                     <TableRow onClick={() => onOrderLineButtonClick(orderLine)}>
                        <TableCell>
                           <Typography variant="body" color="subtle" component="span">
                              {translate('shipment.products.order-line-number')}
                           </Typography>
                        </TableCell>
                        <TableCell>{orderLine.orderNumber}</TableCell>
                     </TableRow>
                     <TableRow onClick={() => onOrderLineButtonClick(orderLine)}>
                        <TableCell>
                           <Typography variant="body" color="subtle" component="span">
                              {translate('shipment.products.quantity')}
                           </Typography>
                        </TableCell>
                        <TableCell>
                           {orderLine.quantity.amount} {orderLine.quantity.unitOfMeasure}
                        </TableCell>
                     </TableRow>
                     <TableRow divider onClick={() => onOrderLineButtonClick(orderLine)}>
                        <TableCell width="200px">
                           {orderLine.isCallOff ? (
                              <Typography variant="body" color="subtle" component="span">
                                 {translate(orderLine.status === 'Closed' ? 'my-orders.delivered' : 'my-orders.call-off-period')}
                              </Typography>
                           ) : (
                              <Typography variant="body" color="subtle" component="span">
                                 {translate(orderLine.status === 'Closed' ? 'my-orders.delivered' : 'my-orders.estimated-delivery')}
                              </Typography>
                           )}
                        </TableCell>
                        <TableCell>
                           <Typography component="span" variant="body-highlight">
                              {orderLine.deliveryDate ? DateUtils.transformOrderDeliveryDate(orderLine.deliveryDate) : '-'}
                           </Typography>
                        </TableCell>
                     </TableRow>
                  </TableBody>
               ))}
         </Table>
         {orderLines.length > tableViewItems && (
            <Box justify="end">
               <Pagination current={page} onChange={onPageChange} total={Math.ceil(orderLines.length / tableViewItems)} />
            </Box>
         )}
      </>
   );
};
