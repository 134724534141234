'use client';

import { FC, useMemo } from 'react';

import { OverviewPageCaseStudyItem } from '@ravago/shared/page-data/models/elastic';
import { CaseStudyCard as CaseStudyCardModel } from '@ravago/shared/page-data/models/elements';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { CardGrid } from '@ravago/shared/radiance/components/card/components/CardGrid/CardGrid';
import { CaseStudyCard } from '@ravago/shared/radiance/components/card/components/CaseStudyCard/CaseStudyCard';

import { useTranslations } from '../../../../../../providers/translation-client-context-provider';
import { CaseStudyUtils } from '../../../../../../utils/case-study/case-study.utils';
import { UrlUtils } from '../../../../../../utils/url/url.utils';

interface Props {
   baseRoutes?: {
      caseStudy?: string;
      market?: string;
      product?: string;
   };
   caseStudies: Array<OverviewPageCaseStudyItem>;
   globals: RendererGlobals;
}

export const CaseStudyGrid: FC<Props> = ({ caseStudies, baseRoutes, globals }) => {
   const translate = useTranslations();

   const { assetBasePath, acceptWebp, locale } = globals;

   const caseStudyCards = useMemo(
      () => caseStudies?.map((caseStudy) => CaseStudyUtils.transformToCaseStudyCard(caseStudy, assetBasePath, acceptWebp)),
      [caseStudies, assetBasePath, acceptWebp]
   );

   return (
      <CardGrid>
         {caseStudyCards?.map((caseStudy: CaseStudyCardModel) => (
            <CaseStudyCard
               key={caseStudy.id}
               href={UrlUtils.getRoute(locale, baseRoutes?.caseStudy, caseStudy.slug, undefined)}
               target="_self"
               noFollow={caseStudy.noFollow}
               title={caseStudy.title}
               excerpt={caseStudy.excerpt}
               background={`url('${caseStudy.image}') center center / cover no-repeat`}
               markets={caseStudy.markets}
               products={caseStudy.products}
               badge={
                  <>
                     {caseStudy.isConfidential && <Badge variant="warning">{translate('case-study-confidential')}</Badge>}
                     {!caseStudy.isConfidential && caseStudy.visibility === 'Internal' && (
                        <Badge variant="warning">{translate('case-study-internal-only')}</Badge>
                     )}
                  </>
               }
            />
         ))}
      </CardGrid>
   );
};
