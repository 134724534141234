'use client';

import { FC, useEffect, useState } from 'react';

import { InvoiceForCurrencyForLegalEntity } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../../../providers/translation-client-context-provider';
import { EmptyState } from '../../../../../shared/empty-state/empty-state.component';
import { useInvoiceStateContext } from '../../../context/invoice-context';
import { OpenInvoicesNotFoundIllustration } from '../../empty-states/open-invoices-not-found-illustration';

import { InvoiceCollapsible } from './invoice-collapsible';

const getCollapsibleId = (legalEntityInvoices: InvoiceForCurrencyForLegalEntity[] | null) =>
   legalEntityInvoices?.length === 1 && legalEntityInvoices[0].currencyTransactions.length === 1
      ? `${legalEntityInvoices[0].legalEntity}.${legalEntityInvoices[0].currencyTransactions[0].currency}`
      : null;

export const OpenInvoices: FC<{ locale: string }> = ({ locale }) => {
   const { areOpenInvoicesLoading, filteredOpenInvoices: openInvoices } = useInvoiceStateContext();
   const translate = useTranslations();

   const [expandedId, setExpandedId] = useState(getCollapsibleId(openInvoices));

   useEffect(() => {
      setExpandedId(getCollapsibleId(openInvoices));
   }, [openInvoices]);

   if (areOpenInvoicesLoading) {
      return (
         <Box direction="column" items="center">
            <Spinner />
         </Box>
      );
   }

   if (!openInvoices?.length) {
      return (
         <EmptyState
            label={translate('invoices.open-invoices.empty-state.label')}
            image={<OpenInvoicesNotFoundIllustration />}
            title={translate('invoices.open-invoices.empty-state.title')}
         />
      );
   }

   return openInvoices?.map((openInvoice) => (
      <Box key={openInvoice.legalEntity} direction="column">
         <Typography variant="body-highlight">{openInvoice.legalEntity}</Typography>
         {openInvoice.currencyTransactions.map(({ currency, transactions, totalAmount, totalOverDue, numberOfInvoices }, i) => {
            if (!transactions.length && numberOfInvoices === 0) {
               return null;
            }

            const collapsibleId = `${openInvoice.legalEntity}.${openInvoice.currencyTransactions[i].currency}`;

            return (
               <InvoiceCollapsible
                  collapsibleId={collapsibleId}
                  currency={currency}
                  invoices={transactions}
                  isExpanded={expandedId === collapsibleId}
                  key={currency}
                  locale={locale}
                  onExpandToggle={setExpandedId}
                  totalAmount={totalAmount ?? 0}
                  totalOverDue={totalOverDue ?? 0}
               />
            );
         })}
      </Box>
   ));
};
