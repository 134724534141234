import { CurrencyTransaction, Transaction, InvoiceForCurrencyForLegalEntity } from '@ravago/shared/page-data/models/elements';

import { firstBy } from 'thenby';

const sortInvoicesByDueDate = (a: Transaction, b: Transaction) => {
   if (!a.dueDate) {
      return 1;
   }

   if (!b.dueDate) {
      return -1;
   }

   return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
};

export const processInvoices = (
   invoices: Transaction[],
   // eslint-disable-next-line no-shadow
   ...functions: ((invoices: Transaction[]) => Transaction[])[]
): Transaction[] => functions.reduce((processedInvoices, fn) => fn(processedInvoices), invoices);

const processCurrencyInvoices = (
   currencyInvoices: CurrencyTransaction[],
   ...functions: ((invoices: Transaction[]) => Transaction[])[]
): CurrencyTransaction[] =>
   currencyInvoices.map((currencyInvoice) => ({
      ...currencyInvoice,
      transactions: processInvoices(currencyInvoice.transactions, ...functions)
   }));

const processLegalEntityInvoices = (
   legalEntityInvoices: InvoiceForCurrencyForLegalEntity[],
   ...functions: ((invoices: Transaction[]) => Transaction[])[]
): InvoiceForCurrencyForLegalEntity[] =>
   legalEntityInvoices.map((legalEntityInvoice) => ({
      ...legalEntityInvoice,
      currencyTransactions: processCurrencyInvoices(legalEntityInvoice.currencyTransactions, ...functions)
   }));

export const sortInvoices = (legalEntityInvoices: InvoiceForCurrencyForLegalEntity[]) =>
   processLegalEntityInvoices(legalEntityInvoices, (invoices) => invoices.sort(firstBy(sortInvoicesByDueDate).thenBy('id', 'asc')));
