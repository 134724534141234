import { Invoice, InvoiceForCurrencyForLegalEntity, InvoiceQueryParams } from '@ravago/shared/page-data/models/elements';
import { PaginatedResponse } from '@ravago/shared/page-data/models/elements/paginated-response.model';

import { DateUtils } from '../../../../utils/date/date.utils';
import { FeaturePermissionsUtils } from '../../../../utils/feature-permissions/feature-permissions.utils';
import { BillingType } from '../models/billing-type.model';
import { FeaturePermissions, Toggle } from '../models/feature-permissions.model';
import { initialOpenInvoiceFilters, OpenInvoiceFilters } from '../models/filters.model';

import { Action, InvoiceAction } from './invoice.actions';

export interface InvoiceState {
   areOpenInvoicesLoading: boolean;
   areClosedInvoicesLoading: boolean;
   closedInvoices: { [page: number]: PaginatedResponse<Invoice[]> } | null;
   closedInvoicesQueryParams: InvoiceQueryParams;
   documentToDownload: { invoiceId: string; documentId: string } | null;
   permissions: FeaturePermissions | null;
   isDownloadingDocument: boolean;
   openInvoices: InvoiceForCurrencyForLegalEntity[] | null;
   openInvoiceFilters: OpenInvoiceFilters;
   filteredOpenInvoices: InvoiceForCurrencyForLegalEntity[] | null;
   selectedBillingType: BillingType;
   areOpenInvoicesFiltered: boolean;
   isOverdueFilterDisabled: boolean;
}

export const initialInvoiceState: InvoiceState = {
   areOpenInvoicesLoading: true,
   areClosedInvoicesLoading: false,
   closedInvoices: null,
   closedInvoicesQueryParams: {
      page: 1,
      isOpen: false,
      size: 20,
      dateFrom: DateUtils.getStartOfYearFromCurrentInUtc(2).toString()
   },
   documentToDownload: null,
   permissions: null,
   isDownloadingDocument: true,
   openInvoices: null,
   openInvoiceFilters: initialOpenInvoiceFilters,
   filteredOpenInvoices: null,
   selectedBillingType: BillingType.OPEN,
   areOpenInvoicesFiltered: false,
   isOverdueFilterDisabled: false
};

export const InvoiceReducer = (state: InvoiceState, action: Action): InvoiceState => {
   switch (action.type) {
      case InvoiceAction.CLOSED_INVOICES_NOT_FOUND:
         return { ...state, closedInvoices: null, areClosedInvoicesLoading: false };
      case InvoiceAction.DOWNLOAD_DOCUMENT:
         return { ...state, documentToDownload: action.payload };
      case InvoiceAction.OPEN_INVOICES_NOT_FOUND:
         return { ...state, openInvoices: null, areOpenInvoicesLoading: false };
      case InvoiceAction.SET_BILLING_TYPE:
         return {
            ...state,
            selectedBillingType: action.payload,
            areOpenInvoicesLoading: !state.openInvoices && action.payload === BillingType.OPEN,
            areClosedInvoicesLoading: !state.closedInvoices && action.payload === BillingType.CLOSED,
            openInvoiceFilters: initialOpenInvoiceFilters
         };
      case InvoiceAction.SET_CLOSED_INVOICES:
         return {
            ...state,
            closedInvoices: {
               ...state.closedInvoices,
               [action.payload.number]: action.payload
            },
            areClosedInvoicesLoading: false
         };

      case InvoiceAction.SET_CLOSED_INVOICES_QUERY_PARAMS:
         return {
            ...state,
            closedInvoicesQueryParams: { ...state.closedInvoicesQueryParams, ...action.payload },
            areClosedInvoicesLoading: !state.closedInvoices?.[action.payload.page]
         };
      case InvoiceAction.SET_OPEN_INVOICES:
         return { ...state, openInvoices: action.payload ?? null, areOpenInvoicesLoading: false };
      case InvoiceAction.SET_PERMISSIONS:
         return {
            ...state,
            permissions: {
               [Toggle.MY_INVOICES]: FeaturePermissionsUtils.hasPermission(action.payload, Toggle.MY_INVOICES),
               [Toggle.CLOSED_INVOICES]: FeaturePermissionsUtils.hasPermission(action.payload, Toggle.CLOSED_INVOICES),
               [Toggle.FILTERS]: FeaturePermissionsUtils.hasPermission(action.payload, Toggle.FILTERS)
            }
         };
      case InvoiceAction.SET_OPEN_INVOICE_FILTERS:
         return { ...state, openInvoiceFilters: action.payload, areOpenInvoicesFiltered: true };
      case InvoiceAction.SET_FILTERED_OPEN_INVOICES:
         return { ...state, filteredOpenInvoices: action.payload ?? null };
      case InvoiceAction.SET_DISABLE_OVERDUE_FILTER:
         return {
            ...state,
            isOverdueFilterDisabled: action.payload
         };
      default:
         return state;
   }
};
