'use client';

import { FC } from 'react';

import { useAuthenticationContext } from '@ravago/shared/authentication';
import { VatNumber } from '@ravago/shared/page-data/models/elements/vat-number.model';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Paper } from '@ravago/shared/radiance/components/paper/components/Paper/Paper';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useQuery } from '@tanstack/react-query';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import OrderNotFoundIllustration from '../../../illustrations/order-not-found-illustration';
import { EmptyState } from '../../../shared/empty-state/empty-state.component';

interface Props {
   getVatNumbers: (accessToken: string) => Promise<Array<VatNumber>>;
}

export const VatNumberList: FC<Props> = ({ getVatNumbers }) => {
   const { token } = useAuthenticationContext();

   const translate = useTranslations();
   const getVatNumbersQuery = useQuery({
      queryKey: ['vatNumbers', { token }],
      queryFn: () => (token ? getVatNumbers(token) : Promise.resolve(undefined)),
      enabled: !!token
   });

   const vatNumbers = getVatNumbersQuery.data;
   if (!vatNumbers) {
      return (
         <Box direction="row" justify="center" spacing={{ top: '3xl', bottom: '3xl' }}>
            <Spinner data-testid="list__spinner" />
         </Box>
      );
   }

   if (vatNumbers?.length === 0) {
      return (
         <EmptyState
            data-testid="vat-number__no_vat-numbers_found"
            image={<OrderNotFoundIllustration />}
            title={translate('vat-numbers.no-vat-numbers-found')}
         />
      );
   }

   const vatNumberList = vatNumbers.map(({ vatNumber, countryName }) => (
      <Paper data-testid="vat-number__list-item" shadow key={vatNumber}>
         <Box direction="row">
            <Typography data-testid="vat-number" component="h3" variant="body-highlight">
               {vatNumber}
            </Typography>
         </Box>

         <Box direction="column" gap="2xs">
            <Typography data-testid="vat-number__country-name" variant="body">
               {countryName}
            </Typography>
         </Box>
      </Paper>
   ));

   return (
      <Box direction="column">
         <Typography variant="heading-2" component="h2">
            {translate('vat-numbers.title')}
         </Typography>
         <Box direction="column">{vatNumberList}</Box>
      </Box>
   );
};
