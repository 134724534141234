'use client';

import { FC, useMemo } from 'react';

import { useAuthenticationContext, UserGroups } from '@ravago/shared/authentication';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Link } from '@ravago/shared/radiance/components/link/components/Link/Link';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { BusinessContextUtils } from '../../../../utils/business-context/business-context.utils';
import { getCountryCode, getLanguageCode } from '../../../../utils/locale/locale.utils';
import GuestNotAllowedInvoicesIllustration from '../../../illustrations/guest-not-allowed-invoice-illustration';
import PendingNotAllowedIllustration from '../../../illustrations/pending-not-allowed-illustration';
import { EmptyState } from '../../../shared/empty-state/empty-state.component';
import { useInvoiceDispatchContext, useInvoiceStateContext } from '../context/invoice-context';
import { useFetchClosedInvoices } from '../hooks/fetch-closed-invoices.hook';
import { useFetchDocument } from '../hooks/fetch-document.hook';
import { useFetchOpenInvoices } from '../hooks/fetch-open-invoices.hook';
import { useFetchPrivateToggles } from '../hooks/fetch-private-toggles.hook';
import { useFilterOpenInvoices } from '../hooks/filter-open-invoices.hook';
import { useRedirectOnUnauthenticated } from '../hooks/redirect-on-unauthenticated.hook';
import { BillingType } from '../models/billing-type.model';
import { Toggle } from '../models/feature-permissions.model';
import { InvoiceProviders } from '../providers/invoice-providers';
import { InvoiceAction } from '../state/invoice.actions';

import { InvoiceChipList } from './invoice-chip-list/invoice-chip-list';
import { InvoiceHeader } from './invoice-header/invoice-header';
import { InvoiceOverview } from './invoice-overview/invoice-overview';

type Props = {
   globals: RendererGlobals;
};

export const Invoices: FC<Props> = ({ globals }) => {
   const { actions, locale, businessContext } = globals;
   const { downloadInvoiceDocument, getInvoices, getOpenInvoices, getPrivateToggles } = actions;

   const translate = useTranslations();
   const { closedInvoicesQueryParams, documentToDownload, permissions, selectedBillingType, openInvoiceFilters } = useInvoiceStateContext();
   const { user } = useAuthenticationContext();
   const countryCode = getCountryCode(locale);
   const dispatch = useInvoiceDispatchContext();

   const language = useMemo(() => getLanguageCode(locale), [locale]);
   const isOpenBillingType = useMemo(() => {
      if (selectedBillingType === BillingType.OPEN) {
         dispatch({ type: InvoiceAction.SET_CLOSED_INVOICES_QUERY_PARAMS, payload: { page: 1, identifier: '' } });
         return true;
      }
      dispatch({ type: InvoiceAction.SET_OPEN_INVOICE_FILTERS, payload: { ...openInvoiceFilters, dueDate: '', documentTypes: [], overdue: false } });
      return false;
   }, [selectedBillingType]);

   useFetchOpenInvoices(getOpenInvoices, permissions, isOpenBillingType);
   useFetchClosedInvoices(getInvoices, permissions, isOpenBillingType, closedInvoicesQueryParams);
   useFetchDocument(downloadInvoiceDocument, documentToDownload, language);
   useFetchPrivateToggles(getPrivateToggles);
   useFilterOpenInvoices(isOpenBillingType);
   useRedirectOnUnauthenticated(permissions);

   if (!permissions?.[Toggle.MY_INVOICES]) {
      return (
         <Box direction="column" items="center">
            <Spinner />
         </Box>
      );
   }

   if (user?.groups?.includes(UserGroups.digitalPlatformPendingVerification)) {
      return (
         <>
            <InvoiceHeader />
            <EmptyState
               label={translate('invoices.user-pending-state.label')}
               image={<PendingNotAllowedIllustration />}
               title={translate('invoices.user-pending-state.title')}
            />
         </>
      );
   }

   if (user?.groups?.includes(UserGroups.digitalPlatformGuest)) {
      return (
         <>
            <InvoiceHeader />
            <EmptyState
               title={translate('invoices.user-guest-state.title')}
               content={
                  <Box>
                     <Typography component="span" variant="body">
                        {translate('invoices.user-guest-state.label1')}{' '}
                        <Link underline="none" href={`mailto:${BusinessContextUtils.getContactEmail(businessContext, countryCode)}`}>
                           <Typography color="tertiary">{BusinessContextUtils.getContactEmail(businessContext, countryCode)}</Typography>
                        </Link>
                        {translate('invoices.user-guest-state.label2')}
                     </Typography>
                  </Box>
               }
               image={<GuestNotAllowedInvoicesIllustration />}
            />
         </>
      );
   }

   return (
      <>
         <InvoiceHeader />
         <InvoiceChipList isOpenSelected={isOpenBillingType} />
         <InvoiceOverview showOpenInvoices={isOpenBillingType} locale={locale} />
      </>
   );
};

export const InvoicesWithContext = ({ globals }: Props) => (
   <InvoiceProviders>
      <Invoices globals={globals} />
   </InvoiceProviders>
);
