'use client';

import { FC, useEffect, useMemo, useState } from 'react';

import { ContentfulCollectionName } from '@ravago/shared/page-data/models/contentful';
import { Breadcrumbs as RadianceBreadcrumbs } from '@ravago/shared/radiance/components/breadcrumbs/components/Breadcrumbs/Breadcrumbs';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';

import { useTranslations } from '../../../providers/translation-client-context-provider';
import { Link } from '../link/link';

export type BreadcrumbsPath = { route: string; name: string }[];

interface Props {
   color: 'primary' | 'light';
   locale: string;
   path: BreadcrumbsPath | undefined;
   currentPathName: string;
   isConsumer: boolean;
   contentType?: ContentfulCollectionName;
}

export const Breadcrumbs: FC<Props> = ({ color, locale, path, currentPathName, isConsumer, contentType }) => {
   const translate = useTranslations();
   const [links, setLinks] = useState<{ label: string; href: string }[]>([]);

   useEffect(() => {
      setLinks(
         (path ?? []).map((part, index) => {
            const contentTypesWithFilters: ContentfulCollectionName[] = ['product', 'brand', 'caseStudy'];

            let optionalQueryParams = '';

            if (path?.length === index + 1 && contentType && contentTypesWithFilters.includes(contentType)) {
               optionalQueryParams = sessionStorage.getItem('pdp-filters') || '';
            }

            const searchParams = new URLSearchParams(optionalQueryParams);
            if (!searchParams.has('tab')) {
               if (contentType === 'brand') searchParams.set('tab', 'brands');
               if (contentType === 'caseStudy') searchParams.set('tab', 'case-study');
            }

            return { label: part.name, href: `/${locale}/${part.route}?${searchParams.toString()}` };
         })
      );
   }, [path, locale]);

   const backButton = useMemo(() => {
      if (links.length === 0) return `/${locale}`;
      return links[links.length - 1].href;
   }, [links, locale]);

   return (
      <>
         <BreakpointSwitch useContainerQuery includeViews={['desktop']}>
            <RadianceBreadcrumbs theme={color === 'primary' ? 'light' : 'dark'}>
               <Link href={`/${locale}`} color={color} inactive={!isConsumer}>
                  <Icon icon="house" color={color === 'primary' ? 'strong' : 'inherit'} size="body-highlight" />
               </Link>
               {links.length > 0
                  ? links.map((link) => (
                       <Link href={link.href} key={link.href} color={color === 'primary' ? 'dark' : 'light'} inactive={!isConsumer} underline="none">
                          {link.label}
                       </Link>
                    ))
                  : null}
               {!isConsumer && (
                  <Link href="/" color={color} inactive>
                     ...
                  </Link>
               )}
               <Link href="/" color={color} inactive>
                  {currentPathName}
               </Link>
            </RadianceBreadcrumbs>
         </BreakpointSwitch>
         <BreakpointSwitch useContainerQuery excludeViews={['desktop']}>
            <Link href={backButton} color={color === 'primary' ? 'dark' : 'light'} variant="body-highlight" underline="none" inactive={!isConsumer}>
               <Icon icon="chevron-left" />
               {links.length === 0 ? translate('page-consumer.breadcrumb-home') : links[links.length - 1]?.label || translate('back')}
            </Link>
         </BreakpointSwitch>
      </>
   );
};
