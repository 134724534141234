import { FC } from 'react';

import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { ToggleChip } from '@ravago/shared/radiance/components/toggle-chip/components/ToggleChip/ToggleChip';

import { useTranslations } from '../../../../providers/translation-client-context-provider';

interface Props {
   compare: boolean;
   showCheckTds: boolean;
   showFeedback: boolean;
   showCompare: boolean;
   showShare: boolean;
   compareDisabled: boolean;
   onCheckTds: () => void;
   onShare: () => void;
   onFeedback: () => void;
   onCompare: () => void;
}

export const ProductActionsList: FC<Props> = ({
   compare,
   showCheckTds,
   showFeedback,
   showCompare,
   showShare,
   onCheckTds,
   onShare,
   onFeedback,
   onCompare,
   compareDisabled
}) => {
   const translate = useTranslations();
   return (
      <Box gap="sm" wrap="wrap">
         {showCheckTds && (
            <div data-cy="chip-download-tds">
               <ToggleChip label={translate('product-actions.tds')} leadingIcon="download" onClick={onCheckTds} />
            </div>
         )}
         {showShare && (
            <div data-cy="chip-share-page">
               <ToggleChip label={translate('product-actions.share')} leadingIcon="share-nodes" onClick={onShare} />
            </div>
         )}
         {showFeedback && (
            <div data-cy="chip-provide-feedback">
               <ToggleChip label={translate('product-actions.feedback')} leadingIcon="message" onClick={onFeedback} />
            </div>
         )}
         {showCompare && (
            <div data-cy="chip-compare">
               <ToggleChip
                  disabled={compareDisabled}
                  label={translate(compare ? 'product-actions.compare.remove' : 'product-actions.compare.add')}
                  toggled={compare}
                  leadingIcon={compare ? 'check' : 'plus'}
                  onClick={onCompare}
               />
            </div>
         )}
      </Box>
   );
};
