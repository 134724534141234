import { InvoiceForCurrencyForLegalEntity, TransactionType } from '@ravago/shared/page-data/models/elements';
import { MultiselectOption } from '@ravago/shared/radiance/components/input-field/components/Multiselect/Multiselect';

import { OpenInvoiceFilters } from '../models/filters.model';

import { isOverDue } from './invoice-date.utils';

export class InvoiceFilterUtils {
   static getUniqueInvoiceTypes = (invoices: InvoiceForCurrencyForLegalEntity[]): TransactionType[] => {
      const allTypes = invoices.flatMap((invoice) =>
         invoice.currencyTransactions.flatMap((currencyInvoice) => currencyInvoice.transactions.map((invoiceItem) => invoiceItem.type))
      );
      return Array.from(new Set(allTypes));
   };

   static getDocumentTypeOptions = (invoices: InvoiceForCurrencyForLegalEntity[]): MultiselectOption[] => {
      const invoiceTypes = InvoiceFilterUtils.getUniqueInvoiceTypes(invoices);
      return invoiceTypes.map((type) => ({
         checked: false,
         id: type,
         label: InvoiceFilterUtils.mapDocumentTypeToLabel(type),
         disabled: false
      }));
   };

   static mapDocumentTypeToLabel = (type: string): string => {
      switch (type) {
         case TransactionType.INVOICE:
            return 'invoices.invoice-type.INVOICE';
         case TransactionType.CREDIT_NOTE:
            return 'invoices.invoice-type.CREDIT_NOTE';
         case TransactionType.DEBIT_NOTE:
            return 'invoices.invoice-type.DEBIT_NOTE';
         case TransactionType.PAYMENT:
            return 'invoices.invoice-type.PAYMENT';
         case TransactionType.CHEQUE:
            return 'invoices.invoice-type.CHEQUE';
         case TransactionType.UNMATCHED_PAYMENT:
            return 'invoices.invoice-type.UNMATCHED_PAYMENT';
         default:
            return type;
      }
   };

   static filterCurrencyInvoiceLinesById(
      invoices: InvoiceForCurrencyForLegalEntity[] | null,
      id?: string
   ): InvoiceForCurrencyForLegalEntity[] | null {
      if (!invoices) {
         return null;
      }

      if (!id) {
         return invoices;
      }

      return invoices.map((invoice) => ({
         ...invoice,
         currencyTransactions: invoice.currencyTransactions.map((currencyInvoice) => ({
            ...currencyInvoice,
            transactions: currencyInvoice.transactions.filter(
               (invoiceLine) => invoiceLine.eInvoiceId?.includes(id) || invoiceLine.id.toString().includes(id)
            )
         }))
      }));
   }

   static filterOpenInvoices(
      invoices: InvoiceForCurrencyForLegalEntity[] | null,
      filters: OpenInvoiceFilters | null,
      excludeDocumentTypes = false
   ): InvoiceForCurrencyForLegalEntity[] | null {
      if (!invoices) {
         return null;
      }

      return invoices.map((invoice) => ({
         ...invoice,
         currencyTransactions: invoice.currencyTransactions.map((currencyInvoice) => ({
            ...currencyInvoice,
            transactions: currencyInvoice.transactions.filter((invoiceItem) => {
               const onlyOverdue = filters?.overdue ? isOverDue(invoiceItem.dueDate) && invoiceItem.amount > 0 : true;
               const dueDateMatch = filters?.dueDate ? invoiceItem.dueDate && invoiceItem.dueDate <= filters.dueDate && invoiceItem.amount > 0 : true;
               const documentTypeMatch = InvoiceFilterUtils.getDocumentTypeMatch(invoiceItem.type, filters, excludeDocumentTypes);
               return onlyOverdue && dueDateMatch && documentTypeMatch;
            })
         }))
      }));
   }

   static getDocumentTypeMatch(invoiceType: string, filters: OpenInvoiceFilters | null, excludeDocumentTypes: boolean): boolean {
      if (excludeDocumentTypes || !filters?.documentTypes) {
         return true;
      }
      const { documentTypes } = filters;
      const noTypesChecked = documentTypes.every((type) => !type.checked);
      const typeChecked = documentTypes.some((type) => type.checked && type.id === invoiceType);

      return noTypesChecked || typeChecked;
   }
}
