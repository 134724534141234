import { FC } from 'react';

import { BillOfLadingResponse } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Paper } from '@ravago/shared/radiance/components/paper/components/Paper/Paper';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import { useTranslations } from '../../../../providers/translation-client-context-provider';

interface Props {
   billsOfLading: BillOfLadingResponse[];
   onRequestBol: (bol: BillOfLadingResponse) => void;
}

export const BillOfLadingDocuments: FC<Props> = ({ billsOfLading, onRequestBol }: Props) => {
   const translate = useTranslations();
   const { phone, tabPort } = useBreakpoint();

   return (
      <Box direction="column" gap="sm">
         {billsOfLading.length > 0 ? (
            <>
               {billsOfLading.map((bolDoc, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Paper direction={phone || tabPort ? 'column' : 'row'} key={index} width="100%">
                     <Box justify="between" items="start" width="100%" gap="md" direction={phone || tabPort ? 'column' : 'row'}>
                        <Box items="start" gap="none" width={phone ? '100%' : '30%'} wrap="wrap">
                           <Typography variant="body-highlight" component="span">
                              {translate('shipment.documents.bill-of-lading')}
                           </Typography>
                        </Box>
                        <Box gap="none" width={tabPort || phone ? '100%' : '70%'}>
                           <Box direction="column" gap="none" width={phone ? '50%' : '100%'}>
                              <Typography variant="small" component="span" color="subtle">
                                 {translate('shipment.documents.transport-order-number')}
                              </Typography>
                              <Typography variant="body" component="span">
                                 {bolDoc.transportOrderNr}
                              </Typography>
                           </Box>
                           <Box items="start" justify="end" gap="none" width={phone ? '50%' : '100%'}>
                              <Button variant="tertiary" onClick={() => onRequestBol(bolDoc)}>
                                 {translate('shipment.documents.request')}
                              </Button>
                           </Box>
                        </Box>
                     </Box>
                  </Paper>
               ))}
            </>
         ) : (
            <Typography variant="body" component="span">
               {translate('shipment.documents.no-documents-available')}
            </Typography>
         )}
      </Box>
   );
};
