'use client';

import { FC, useMemo, useState } from 'react';

import { ElementProps } from '@ravago/shared/page-data/models/base';
import { ContentfulCaseStudy } from '@ravago/shared/page-data/models/contentful';
import { ImageGalleryNode } from '@ravago/shared/page-data/models/elements';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BoxItem } from '@ravago/shared/radiance/components/box/components/BoxItem/BoxItem';
import { Grid } from '@ravago/shared/radiance/components/grid/components/Grid/Grid';
import { GridCol } from '@ravago/shared/radiance/components/grid/components/GridCol/GridCol';
import { Image } from '@ravago/shared/radiance/components/image/components/Image/Image';
import { LightboxGallery } from '@ravago/shared/radiance/components/lightbox/components/LightboxGallery/LightboxGallery';
import { Pagination } from '@ravago/shared/radiance/components/pagination/components/Pagination/Pagination';

import { Entry } from 'contentful';

import { AssetUtils } from '../../../utils/asset/asset.utils';
import { DynamicNodePlaceholder } from '../../placeholders/dynamic-node-placeholder/dynamic-node-placeholder';

const getImages = (globals: RendererGlobals): { id: string; src: string; alt: string; title: string }[] => {
   if (globals.contentType !== 'caseStudy' || !globals.contentEntity) {
      return [];
   }

   const entity = globals.contentEntity as Entry<ContentfulCaseStudy>;

   if (!entity.fields?.images) return [];

   return (
      entity.fields?.images.map((image) => ({
         id: image.sys.id,
         src: AssetUtils.optimizeImageIfRequired(image.fields.file.url, globals.acceptWebp),
         alt: image.fields.title,
         title: image.fields.title
      })) || []
   );
};

/* c8 ignore start */
export const ImageGalleryElement: FC<ElementProps<ImageGalleryNode>> = ({ globals, node }) => {
   const [page, setPage] = useState<number>(1);
   const maxNumberOfImages = node.maxAmountOfImages ?? 4;

   const images = useMemo(() => getImages(globals), [globals]);
   const totalPages = Math.ceil(images.length / maxNumberOfImages);

   const slicedImage = useMemo(
      () => images.slice((page - 1) * maxNumberOfImages, Number(maxNumberOfImages) + Number((page - 1) * maxNumberOfImages)),
      [images, maxNumberOfImages, page]
   );

   if (globals.contentType !== 'caseStudy') {
      if (globals.isConsumer) return null;
      return <DynamicNodePlaceholder contentType={globals?.contentType} supportedContentTypes={['caseStudy']} />;
   }

   return (
      <>
         {!!slicedImage.length && (
            <LightboxGallery
               renderGallery={({ onClick }) => (
                  <Grid useContainerQuery>
                     {slicedImage.map((item, index) => (
                        <GridCol key={item.id} spanDesktop={3 * (4 / maxNumberOfImages)} spanTablet={maxNumberOfImages === 1 ? 12 : 6} spanPhone={4}>
                           <Box height="100%" items="center">
                              <button type="button" onClick={globals?.isConsumer ? () => onClick(index) : undefined}>
                                 <Image
                                    data-testid={`image-gallery__image__${item.id}`}
                                    src={item.src}
                                    alt={item.alt}
                                    title={item.title}
                                    objectFit="scale-down"
                                 />
                              </button>
                           </Box>
                        </GridCol>
                     ))}
                  </Grid>
               )}
               items={images}
            />
         )}
         {!!totalPages && totalPages !== 1 && (
            <Box justify="end">
               <BoxItem>
                  {totalPages > 0 && <Pagination data-testid="image-gallery__pagination" total={totalPages} current={page} onChange={setPage} />}
               </BoxItem>
            </Box>
         )}
      </>
   );
};
/* c8 ignore stop */
