'use client';

import { FC, useEffect, useReducer, useState } from 'react';

import { useAuthenticationContext } from '@ravago/shared/authentication';
import { FeatureToggle } from '@ravago/shared/page-data/models/config';
import { OrderDocumentResponse, ShipmentDetail } from '@ravago/shared/page-data/models/elements';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { BusinessContextUtils } from '../../../../utils/business-context/business-context.utils';
import { DateUtils } from '../../../../utils/date/date.utils';
import { FeaturePermissionsUtils } from '../../../../utils/feature-permissions/feature-permissions.utils';
import { getLanguageCode } from '../../../../utils/locale/locale.utils';
import OrderNotFoundIllustration from '../../../illustrations/order-not-found-illustration';
import { EmptyState } from '../../../shared/empty-state/empty-state.component';
import { Link } from '../../../shared/link/link';
import { OrderDocuments } from '../../../shared/order-documents/order-documents';
import { OrderPageHeader } from '../../../shared/order-page-header/order-page-header';
import { DeliveryInformation } from '../../../shared/order/delivery-information';
import { InvoiceInformation } from '../../../shared/order/invoice-information';

import { ShipmentProducts } from './shipment-products';

export type Props = {
   globals: RendererGlobals;
   orderDetailBaseUrl: string;
   callOffAgreementBaseUrl: string;
};

interface State {
   shipment?: ShipmentDetail;
   shipmentId: string | null;
   documents: OrderDocumentResponse | undefined;
   showNotFound: boolean;
   isLoading: boolean;
   permissions: FeatureToggle[];
   showVatCode: boolean;
}

type Action =
   | { type: 'SET_SHIPMENT'; payload: ShipmentDetail }
   | { type: 'SET_SHIPMENT_ID'; payload: string | null }
   | { type: 'SET_DOCUMENTS'; payload: OrderDocumentResponse }
   | { type: 'SHOW_NOT_FOUND' }
   | { type: 'SET_PERMISSIONS'; payload: FeatureToggle[] };

const initialState: State = {
   shipment: undefined,
   shipmentId: null,
   documents: undefined,
   showNotFound: false,
   isLoading: true,
   permissions: [],
   showVatCode: false
};

function reducer(state: State, action: Action): State {
   switch (action.type) {
      case 'SET_SHIPMENT':
         return { ...state, shipment: action.payload, isLoading: false };
      case 'SET_SHIPMENT_ID':
         return { ...state, shipmentId: action.payload };
      case 'SET_DOCUMENTS':
         return { ...state, documents: action.payload };
      case 'SHOW_NOT_FOUND':
         return { ...state, showNotFound: true, isLoading: false };
      case 'SET_PERMISSIONS':
         return { ...state, permissions: action.payload, showVatCode: FeaturePermissionsUtils.hasPermission(action.payload, 'vat-info') };
      default:
         return state;
   }
}

export const ShipmentDetails: FC<Props> = ({
   globals: {
      locale,
      acceptWebp,
      businessContext,
      routeParams,
      isConsumer,
      assetBasePath,
      actions: {
         getShipmentDetail,
         requestBol,
         requestCoa,
         requestDeliveryNote,
         downloadDocument,
         getPrivateToggles,
         getOrderProductsByIds,
         getShipmentDocuments
      }
   },
   orderDetailBaseUrl,
   callOffAgreementBaseUrl
}) => {
   const translate = useTranslations();
   const language = getLanguageCode(locale);
   const [{ shipment, shipmentId, isLoading, showNotFound, showVatCode, documents }, dispatch] = useReducer(reducer, initialState);
   const { token } = useAuthenticationContext();
   const [hasDocuments, setHasDocuments] = useState(false);

   const checkDocuments = (orderDocuments: OrderDocumentResponse) => {
      const { deliveryNotes, billsOfLading, invoices, coa } = orderDocuments;
      return (
         (invoices && invoices.length > 0) ||
         (coa && coa.length > 0) ||
         (deliveryNotes && deliveryNotes.length > 0) ||
         (billsOfLading && billsOfLading.length > 0)
      );
   };

   useEffect(() => {
      const fetchShipmentDetails = async (accessToken: string, id: string) => {
         try {
            const shipmentPromise = getShipmentDetail(accessToken, id);
            const documentsPromise = getShipmentDocuments(accessToken, id);
            const togglesPromise = getPrivateToggles(accessToken);
            const [shipmentData, permissions, shipmentDocuments] = await Promise.all([shipmentPromise, togglesPromise, documentsPromise]);
            if (shipmentData) {
               dispatch({ type: 'SET_PERMISSIONS', payload: permissions });
               const uniqueProductIds = [...new Set(shipmentData.orders.map((order) => `product-${order.product.pkbId}`).filter(Boolean))];
               const contentfulProducts = await getOrderProductsByIds(locale, uniqueProductIds);
               if (contentfulProducts) {
                  shipmentData.orders = shipmentData.orders.map((order) => {
                     const productDetail = contentfulProducts.find((product) => product.id === `product-${order.product.pkbId}`);
                     return { ...order, contentfulProduct: productDetail };
                  });
               }
               dispatch({
                  type: 'SET_SHIPMENT',
                  payload: {
                     ...shipmentData
                  }
               });
               if (shipmentDocuments) {
                  dispatch({ type: 'SET_DOCUMENTS', payload: shipmentDocuments });
                  setHasDocuments(shipmentDocuments ? checkDocuments(shipmentDocuments) : false);
               }
            } else {
               dispatch({ type: 'SHOW_NOT_FOUND' });
            }
         } catch {
            dispatch({ type: 'SHOW_NOT_FOUND' });
         }
      };

      const id = routeParams?.shipmentId || null;
      dispatch({ type: 'SET_SHIPMENT_ID', payload: id });

      if (token && id) {
         fetchShipmentDetails(token, id);
      } else if (!id) {
         dispatch({ type: 'SHOW_NOT_FOUND' });
      }
   }, [token, getShipmentDetail, locale, routeParams]);

   if (isLoading) {
      return (
         <Box direction="column" items="center">
            <Spinner />
         </Box>
      );
   }

   if (showNotFound) {
      return (
         <Box direction="column">
            <OrderPageHeader headerText={translate('shipment.header')} id={shipmentId} status={undefined} date={undefined} />
            <EmptyState
               label={translate('shipment.not-found-label')}
               image={<OrderNotFoundIllustration />}
               button={
                  <Link href={`/my/${language}/my-orders`} prefetch={false}>
                     <Button variant="primary" leadingIcon="arrow-left">
                        {translate('shipment.return-to-orders')}
                     </Button>
                  </Link>
               }
               title={translate('shipment.not-found-title')}
            />
            <Divider color="default" orientation="horizontal" />
         </Box>
      );
   }

   if (shipmentId && shipment) {
      return (
         <Box direction="column">
            <OrderPageHeader
               headerText={translate('shipment.header')}
               id={shipmentId}
               status={shipment.status}
               date={DateUtils.transformOrderDeliveryDate(shipment.deliveryDate)}
            />
            <ShipmentProducts
               orders={shipment.orders}
               locale={locale}
               isConsumer={isConsumer}
               acceptWebp={acceptWebp}
               assetBasePath={assetBasePath}
               orderDetailBaseUrl={orderDetailBaseUrl}
               callOffAgreementBaseUrl={callOffAgreementBaseUrl}
            />
            <Divider color="default" orientation="horizontal" />
            <DeliveryInformation
               destinationAddress={shipment.destinationAddress}
               deliveryTermAddress={shipment.deliveryTermAddress}
               deliveryInstructions={shipment.deliveryInstructions}
               deliveryTerm={shipment.deliveryTerm}
            />
            <Divider color="default" orientation="horizontal" />
            <InvoiceInformation vatCode={shipment.vatCode} invoiceEntity={shipment.ole} showVatCode={showVatCode} />
            <Divider color="default" orientation="horizontal" />
            {hasDocuments && (
               <OrderDocuments
                  isUSBusinessContext={BusinessContextUtils.isUSBusinessContext(businessContext)}
                  documents={documents}
                  shipmentOrder={shipment.orders}
                  requestCoa={requestCoa}
                  requestBol={requestBol}
                  requestDeliveryNote={requestDeliveryNote}
                  downloadDocument={downloadDocument}
               />
            )}
         </Box>
      );
   }

   return (
      <Box direction="column" items="center">
         <Spinner />
      </Box>
   );
};
