import { FC } from 'react';

import { DisplayOrder, OrderLineOpenedFrom } from '@ravago/shared/page-data/models/elements';
import { AspectRatio } from '@ravago/shared/radiance/components/aspect-ratio/components/AspectRatio/AspectRatio';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Image } from '@ravago/shared/radiance/components/image/components/Image/Image';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../providers/translation-client-context-provider';
import { AssetUtils } from '../../../utils/asset/asset.utils';

interface Props {
   order: DisplayOrder;
   language: string;
   openedFrom: OrderLineOpenedFrom;
   acceptWebp?: boolean;
}

export const OrderLineItem: FC<Props> = ({ order, language, openedFrom, acceptWebp }) => {
   const translate = useTranslations();

   return (
      <Box spacing={{ left: '2xs' }} gap="md">
         {openedFrom !== 'order_overview_order' && openedFrom !== 'order_overview_shipment' && (
            <Box>
               <AspectRatio aspectRatio="1:1">
                  <Image
                     src={
                        order.contentfulProduct?.brand?.producer?.logo?.url
                           ? AssetUtils.optimizeImageIfRequired(order.contentfulProduct.brand.producer.logo.url, acceptWebp)
                           : '/images/placeholders/default-logo.png'
                     }
                     alt={order.product.description}
                     title={order.product.description}
                     height="52px"
                     width="52px"
                     objectFit="contain"
                  />
               </AspectRatio>
            </Box>
         )}
         <Box direction="column" gap="none" justify="center" items="start" wrap="wrap">
            <Typography component="span" variant="body-highlight">
               {order.product.description}
            </Typography>
            <Typography component="span" variant="small" color="subtle">
               {translate('shipment.table.order-reference')} {order.customerOrderReference || '-'}
            </Typography>
         </Box>
      </Box>
   );
};
