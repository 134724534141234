import { ProductBadge } from '@ravago/shared/page-data/models/elements';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';

import { useTranslations } from '../../../../../providers/translation-client-context-provider';

interface Props {
   badgeInfo: ProductBadge;
   hasSustainabilityLabels: boolean;
}

export const ProductCardBadge = ({ badgeInfo, hasSustainabilityLabels }: Props) => {
   const translate = useTranslations();

   if (hasSustainabilityLabels) {
      if (!badgeInfo.sustainability) return null;

      return (
         <Badge leadingIcon={badgeInfo.sustainability.icon ?? 'leaf'} variant="success">
            {badgeInfo.sustainability.label ?? translate('card-product-card-recycled')}
         </Badge>
      );
   }

   if (badgeInfo.quality !== 'Prime') {
      return (
         <Badge leadingIcon="leaf" variant="success">
            {translate('card-product-card-recycled')}
         </Badge>
      );
   }

   return null;
};
