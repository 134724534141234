'use client';

import { FC, useEffect, useState } from 'react';

import { useAuthenticationContext } from '@ravago/shared/authentication';
import {
   BillOfLadingResponse,
   CoaResponse,
   DeliveryNoteResponse,
   DocumentOrderLineData,
   InvoiceResponse,
   MappedOrderHeader,
   OrderDocumentResponse,
   OrderDocumentType,
   ShipmentOrder
} from '@ravago/shared/page-data/models/elements';
import { CorrelationService, TrackingUtils } from '@ravago/shared/page-elements-radiance-consumer';
import { Accordion } from '@ravago/shared/radiance/components/accordion/components/Accordion/Accordion';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Dropdown, DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';
import notification from '@ravago/shared/radiance/utils/notification/notification';

import saveAs from 'file-saver';

import { useTranslations } from '../../../providers/translation-client-context-provider';
import TooManyDocuments from '../../illustrations/too-many-documents';
import { EmptyState } from '../empty-state/empty-state.component';

import { BillOfLadingDocuments } from './bol/bill-of-lading-documents';
import { CoaDocuments } from './coa/coa-documents';
import DeliveryDocuments from './delivery-notes/delivery-documents';
import { InvoiceAndCreditNotes } from './invoice/invoice-credit-note';

interface Props {
   documents: OrderDocumentResponse | undefined;
   orders?: MappedOrderHeader;
   shipmentOrder?: ShipmentOrder[];
   isUSBusinessContext: boolean;
   requestCoa: (token: string, orderNumber: string, producerLotNr: string) => Promise<boolean>;
   requestDeliveryNote: (token: string, orderNumber: string, transportOrderLineNr: number, bundledTransportOrderId?: number) => Promise<boolean>;
   requestBol: (token: string, orderNumber: string, transportOrderLineNr: number) => Promise<boolean>;
   downloadDocument: (downloadEndpoint: string, accessToken: string, correlationId: string) => Promise<string | undefined>;
}

interface AccordionHeaderProps {
   title: string;
   isOpen: boolean;
   toggleAccordion: (isOpen: boolean) => void;
}

const AccordionHeader: FC<AccordionHeaderProps> = ({ title, isOpen, toggleAccordion }) => (
   <Box direction="row" justify="between" items="center">
      <Typography variant="body-highlight" component="b" color="strong">
         {title}
      </Typography>
      <IconButton icon={isOpen ? 'chevron-up' : 'chevron-down'} onClick={() => toggleAccordion(!isOpen)} type="button" variant="on-light" />
   </Box>
);

export const OrderDocuments: FC<Props> = ({
   orders,
   shipmentOrder,
   documents,
   requestCoa,
   requestDeliveryNote,
   requestBol,
   downloadDocument,
   isUSBusinessContext
}) => {
   const translate = useTranslations();
   const { token } = useAuthenticationContext();
   const { desktop } = useBreakpoint();
   const [isInvoiceOpen, setIsInvoiceOpen] = useState(true);
   const [isCoaOpen, setIsCoaOpen] = useState(true);
   const [isDeliveryOpen, setIsDeliveryOpen] = useState(true);
   const [isBolOpen, setIsBolOpen] = useState(true);
   const [filter, setFilter] = useState<DropdownOption>({
      id: '0',
      value: translate('shipment.documents.all-products')
   });
   const [coaDocs, setCoaDocs] = useState<Array<CoaResponse>>(documents?.coa || []);
   const [bolDocs, setBolDocs] = useState<Array<BillOfLadingResponse>>(documents?.billsOfLading || []);
   const [invoiceDocs, setInvoiceDocs] = useState<Array<InvoiceResponse>>(documents?.invoices || []);
   const [deliveryNotesDocs, setDeliveryNotesDocs] = useState<Array<DeliveryNoteResponse>>(documents?.deliveryNotes || []);

   const handleDropdownOptions = (dataOrders: MappedOrderHeader | undefined, dataShipment: ShipmentOrder[] | undefined) => {
      let data: any[] = [];
      if (dataShipment) {
         data = dataShipment;
      }

      if (dataOrders) {
         const inTransitOrderLines = dataOrders.mappedOrders.inTransitShipments.flatMap((shipment) => shipment.orderLines);
         const transportArrangedOrderLines = dataOrders.mappedOrders.transportArrangedShipments.flatMap((shipment) => shipment.orderLines);

         data = [
            ...inTransitOrderLines,
            ...transportArrangedOrderLines,
            ...dataOrders.mappedOrders.openOrderLines,
            ...dataOrders.mappedOrders.closedOrderLines
         ];
      }

      return data
         .map((order, index) => ({
            id: (index + 1).toString(),
            value: order.product.description,
            supportingText: order.orderNumber
         }))
         .concat({
            id: '0',
            value: translate('shipment.documents.all-products'),
            supportingText: ''
         })
         .sort((a, b) => Number(a.id) - Number(b.id));
   };

   useEffect(() => {
      if (documents) {
         if (filter.id !== '0') {
            setCoaDocs(documents.coa.filter((coa) => coa.orderLineData.filter((order) => order.orderNumber === filter.supportingText).length > 0));
            setBolDocs(documents.billsOfLading.filter((bol) => bol.transportOrderNr === filter.supportingText));
            setInvoiceDocs(
               documents.invoices.filter((invoice) => invoice.orderLineData.filter((order) => order.orderNumber === filter.supportingText).length > 0)
            );
            setDeliveryNotesDocs(
               documents.deliveryNotes.filter(
                  (deliveryNotes) => deliveryNotes.orderLineData.filter((order) => order.orderNumber === filter.supportingText).length > 0
               )
            );
         } else {
            setCoaDocs(documents.coa);
            setBolDocs(documents.billsOfLading);
            setInvoiceDocs(documents.invoices);
            setDeliveryNotesDocs(documents.deliveryNotes);
         }
      }
   }, [filter, documents]);

   const trackDocumentRequest = (type: OrderDocumentType, orderLine: DocumentOrderLineData) => {
      TrackingUtils.track('order_document_request', {
         documentType: type,
         orderNumber: orderLine.orderNumber,
         productName: orderLine.productDescription
      });
   };

   const handleDocumentRequest = async <T extends BillOfLadingResponse | CoaResponse | DeliveryNoteResponse>(
      type: OrderDocumentType,
      document: T
   ) => {
      if (!token) {
         notification(translate('shipment.documents.request-error'), { duration: 5000 });
         return;
      }
      let result;
      // eslint-disable-next-line default-case
      switch (type) {
         case 'COA':
            result = await requestCoa(token, (document as CoaResponse).orderLineData[0].orderNumber, (document as CoaResponse).producerLotNr);
            trackDocumentRequest(type, (document as CoaResponse).orderLineData[0]);
            break;
         case 'Delivery Note':
            result = await requestDeliveryNote(
               token,
               (document as DeliveryNoteResponse).orderLineData[0].orderNumber,
               Number((document as DeliveryNoteResponse).orderLineData[0].transportOrderNr.split('-')[2]),
               (document as DeliveryNoteResponse).bundledTransportOrderId
            );
            trackDocumentRequest(type, (document as DeliveryNoteResponse).orderLineData[0]);
            break;
         case 'Bill of Lading':
            result = await requestBol(
               token,
               (document as BillOfLadingResponse).transportOrderNr,
               (document as BillOfLadingResponse).transportOrderLineNr
            );
            TrackingUtils.track('order_document_request', {
               documentType: type,
               transportOrderNumber: (document as BillOfLadingResponse).transportOrderNr,
               transportOrderLineNumber: (document as BillOfLadingResponse).transportOrderLineNr.toString()
            });
            break;
      }
      if (result) {
         notification(translate('shipment.documents.request-success'), { duration: 5000 });
      } else {
         notification(translate('shipment.documents.request-error'), { duration: 5000 });
      }
   };

   const handleDownload = async (
      document: InvoiceResponse | CoaResponse | DeliveryNoteResponse,
      orderLine: DocumentOrderLineData,
      type: OrderDocumentType
   ) => {
      if (!token) return;
      const blob = await downloadDocument(
         `customer/orders/${orderLine.orderNumber}/documents/${document.id}`,
         token,
         CorrelationService.generateCorrelationId('dpcid')
      );
      if (blob) {
         saveAs(blob, document.extension ? `${document.name}.${document.extension}` : document.name);
         TrackingUtils.track('order_document_download', {
            documentType: type,
            orderNumber: orderLine.orderNumber,
            productName: orderLine.productDescription
         });
      } else {
         notification(translate('shipment.documents.download-error'), { duration: 5000 });
      }
   };

   return (
      <Box direction="column">
         <Box direction="row" justify="between">
            <Typography variant="heading-4" component="h4" color="strong">
               {translate('shipment.documents.title')}
            </Typography>
         </Box>
         <Box direction="row" justify="start" items="center">
            <Dropdown
               id="document-filter"
               label=""
               width={400}
               emptyOption={false}
               options={handleDropdownOptions(orders, shipmentOrder)}
               value={filter}
               onChange={(filterSelected) => filterSelected && setFilter(filterSelected)}
            />
            {desktop && (
               <Button
                  type="button"
                  variant="secondary"
                  disabled={filter.id === '0'}
                  onClick={() =>
                     setFilter({
                        id: '0',
                        value: translate('shipment.documents.all-products')
                     })
                  }
               >
                  {translate('shipment.documents.reset-filter')}
               </Button>
            )}
         </Box>
         <Box direction="column">
            {filter.id === '0' && coaDocs.length + bolDocs.length + invoiceDocs.length + deliveryNotesDocs.length > 10 ? (
               <EmptyState
                  label={translate('shipment.too-many-documents-text')}
                  image={<TooManyDocuments />}
                  title={translate('shipment.too-many-documents-title')}
               />
            ) : (
               <>
                  <Accordion
                     open={isInvoiceOpen}
                     body={<InvoiceAndCreditNotes invoices={invoiceDocs} onDownload={handleDownload} />}
                     header={
                        <AccordionHeader
                           title={translate('shipment.documents.copy-of-invoices-and-credit-notes')}
                           isOpen={isInvoiceOpen}
                           toggleAccordion={setIsInvoiceOpen}
                        />
                     }
                  />
                  {coaDocs.length > 0 && (
                     <Accordion
                        open={isCoaOpen}
                        body={
                           <CoaDocuments coaDocs={coaDocs} onRequestCoa={(coa) => handleDocumentRequest('COA', coa)} onDownload={handleDownload} />
                        }
                        header={
                           <AccordionHeader
                              title={translate('shipment.documents.certificates-of-analysis')}
                              isOpen={isCoaOpen}
                              toggleAccordion={setIsCoaOpen}
                           />
                        }
                     />
                  )}
                  {isUSBusinessContext ? (
                     <Accordion
                        open={isBolOpen}
                        body={<BillOfLadingDocuments billsOfLading={bolDocs} onRequestBol={(bol) => handleDocumentRequest('Bill of Lading', bol)} />}
                        header={
                           <AccordionHeader
                              title={translate('shipment.documents.bills-of-lading')}
                              isOpen={isBolOpen}
                              toggleAccordion={setIsBolOpen}
                           />
                        }
                     />
                  ) : (
                     <Accordion
                        open={isDeliveryOpen}
                        body={
                           <DeliveryDocuments
                              deliveryNoteDocs={deliveryNotesDocs}
                              onRequestDeliveryNote={(deliveryNote) => handleDocumentRequest('Delivery Note', deliveryNote)}
                              onDownload={handleDownload}
                           />
                        }
                        header={
                           <AccordionHeader
                              title={translate('shipment.documents.delivery-notes')}
                              isOpen={isDeliveryOpen}
                              toggleAccordion={setIsDeliveryOpen}
                           />
                        }
                     />
                  )}
               </>
            )}
         </Box>
      </Box>
   );
};
