import { MultiselectOption } from '@ravago/shared/radiance/components/input-field/components/Multiselect/Multiselect';

export interface OpenInvoiceFilters {
   eInvoiceId: string;
   dueDate: string;
   documentTypes: MultiselectOption[];
   overdue: boolean;
}

export const initialOpenInvoiceFilters: OpenInvoiceFilters = {
   eInvoiceId: '',
   dueDate: '',
   documentTypes: [],
   overdue: false
};
