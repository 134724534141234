import { UnleashData } from '@ravago/shared/page-data/models/base';
import { FeatureToggle } from '@ravago/shared/page-data/models/config';

import { InMemoryStorageProvider, UnleashClient } from 'unleash-proxy-client';

import { cache } from '../cache/cache.utils';

export abstract class FeaturePermissionsUtils {
   static parseOverrides(toggleOverridesString: string) {
      const result = new Map<string, boolean>();
      const toggleOverrides = toggleOverridesString.split(',').map((item) => item.trim());

      toggleOverrides.forEach((toggle) => {
         const [key, value] = toggle.split('=').map((item) => item.trim());
         result.set(key, value === 'true');
      });

      return result;
   }

   static hasPermission(permissions: FeatureToggle[], featurePermission: string, defaultValue = false) {
      return permissions.find((permission) => permission.name === featurePermission)?.allowed ?? defaultValue;
   }

   static getPrivatePermissions = cache(async (url: string, accessToken: string) =>
      fetch(url, {
         headers: {
            'Content-Type': 'application/json',
            'User-Agent': 'Ravago-bot-eui9K6Lq-dp',
            'X-ravago-authenticationToken': accessToken
         },
         cache: 'no-cache'
      })
         .then((response) => response.json())
         .then((permissions: Array<FeatureToggle>) => {
            if ((permissions && !Array.isArray(permissions)) || !permissions) {
               return [];
            }
            return permissions;
         })
   );

   static async getPublicPermissionsServerSide(unleashData: UnleashData, businessContextId: string) {
      const client = new UnleashClient({
         url: unleashData.url,
         clientKey: unleashData.clientKey,
         appName: unleashData.appName,
         disableMetrics: true,
         storageProvider: new InMemoryStorageProvider()
      });
      return this.getFeaturesFromUnleash(client, businessContextId);
   }

   private static async getFeaturesFromUnleash(client: UnleashClient, businessContextId: string): Promise<Array<FeatureToggle>> {
      client.setContextField('businessContextId', businessContextId);
      await client.start();

      const toggles: Array<FeatureToggle> = client
         .getAllToggles()
         .filter((toggle) => toggle.name.startsWith('public'))
         .map((toggle) => ({ name: toggle.name, allowed: toggle.enabled }));
      client.stop();
      return toggles;
   }
}
