'use client';

import { FC, useEffect, useMemo, useReducer, useState } from 'react';

import { useAuthenticationContext, UserGroups } from '@ravago/shared/authentication';
import { DisplayOrder, MappedOrders } from '@ravago/shared/page-data/models/elements';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { MultiselectOption } from '@ravago/shared/radiance/components/input-field/components/Multiselect/Multiselect';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { TabProps } from '@ravago/shared/radiance/components/tabs/components/Tab/Tab';
import { Tabs } from '@ravago/shared/radiance/components/tabs/components/Tabs/Tabs';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { BusinessContextUtils } from '../../../../utils/business-context/business-context.utils';
import { getCountryCode, getLanguageCode } from '../../../../utils/locale/locale.utils';
import { OrderFilterUtils } from '../../../../utils/order/order-filters.utils';
import { OrderMapperUtils } from '../../../../utils/order/order-mapper.utils';
import GuestNotAllowedIllustration from '../../../illustrations/guest-not-allowed-illustration';
import OrderNotFoundIllustration from '../../../illustrations/order-not-found-illustration';
import PendingNotAllowedIllustration from '../../../illustrations/pending-not-allowed-illustration';
import { EmptyState } from '../../../shared/empty-state/empty-state.component';
import { Link } from '../../../shared/link/link';
import { OrderLinesTable } from '../../../shared/order-lines-table/order-lines-table';
import { OrderShipments } from '../../../shared/order/order-shipments';

import { OrderOverviewSearchFilter } from './order-overview-search-filter';

export type Props = {
   globals: RendererGlobals;
   shipmentBaseUrl: string;
   callOffAgreementBaseUrl: string;
   orderDetailBaseUrl: string;
};

interface State {
   openOrders?: MappedOrders;
   closedOrders?: DisplayOrder[];
   showNotFound: boolean;
   isOpenLoading: boolean;
   isClosedLoading: boolean;
   hasCallOffs: boolean;
}

type Action =
   | { type: 'SET_OPEN_ORDERS'; payload: MappedOrders }
   | { type: 'SHOW_NOT_FOUND' }
   | { type: 'SET_HAS_CALL_OFFS' }
   | { type: 'SET_CLOSED_ORDERS'; payload: DisplayOrder[] };

const initialState: State = {
   showNotFound: false,
   isOpenLoading: true,
   isClosedLoading: true,
   openOrders: undefined,
   closedOrders: undefined,
   hasCallOffs: false
};

function reducer(state: State, action: Action): State {
   switch (action.type) {
      case 'SET_OPEN_ORDERS':
         return { ...state, openOrders: action.payload, isOpenLoading: false };
      case 'SET_CLOSED_ORDERS':
         return { ...state, closedOrders: action.payload, isClosedLoading: false };
      case 'SHOW_NOT_FOUND':
         return { ...state, showNotFound: true, isOpenLoading: false, isClosedLoading: false };
      case 'SET_HAS_CALL_OFFS':
         return { ...state, hasCallOffs: true };
      default:
         return state;
   }
}

export const OrderOverview: FC<Props> = ({
   globals: {
      locale,
      acceptWebp,
      isConsumer,
      businessContext,
      actions: { getOrders, getCallOffs }
   },
   shipmentBaseUrl,
   callOffAgreementBaseUrl,
   orderDetailBaseUrl
}) => {
   const translate = useTranslations();
   const language = getLanguageCode(locale);
   const countryCode = getCountryCode(locale);
   const [{ isOpenLoading, isClosedLoading, showNotFound, openOrders, closedOrders, hasCallOffs }, dispatch] = useReducer(reducer, initialState);
   const { token, user } = useAuthenticationContext();
   const [activeTab, setActiveTab] = useState(0);
   const [isFiltered, setIsFiltered] = useState(false);
   const [filteredOrders, setFilteredOrders] = useState<MappedOrders | undefined>();
   const [addressesOptions, setAddressesOptions] = useState<MultiselectOption[]>([]);

   const tabs = useMemo(() => {
      const baseTabs: Array<TabProps> = [
         {
            label: `${
               openOrders !== undefined
                  ? `${translate('my-orders.tab.open')} (${
                       isFiltered && filteredOrders ? filteredOrders.openOrderLines.length : openOrders.openOrderLines.length
                    })`
                  : `${translate('my-orders.tab.open')} (...)`
            }`,
            id: 'open-orders-tab'
         },
         {
            label: `${
               closedOrders !== undefined
                  ? `${translate('my-orders.tab.closed')} (${
                       isFiltered && filteredOrders?.closedOrderLines ? filteredOrders.closedOrderLines.length : closedOrders.length
                    })`
                  : `${translate('my-orders.tab.closed')} (...)`
            }`,
            id: 'closed-orders-tab'
         }
      ];

      return baseTabs;
   }, [openOrders, closedOrders, language, filteredOrders]);

   useEffect(() => {
      const fetchOpenOrders = async (accessToken: string) => {
         try {
            const [openOrdersResponse, callOffsResponse] = await Promise.all([getOrders(accessToken, true), getCallOffs(accessToken)]);
            let openOrdersList: DisplayOrder[] = [];
            if (openOrdersResponse) {
               openOrdersList = [...openOrdersList, ...openOrdersResponse];
            }
            if (callOffsResponse && callOffsResponse.length > 0) {
               dispatch({ type: 'SET_HAS_CALL_OFFS' });
               openOrdersList = [...openOrdersList, ...callOffsResponse];
            }
            const mappedOpenOrders = OrderMapperUtils.getMappedOrders(openOrdersList);

            dispatch({ type: 'SET_OPEN_ORDERS', payload: mappedOpenOrders });
         } catch (error) {
            dispatch({ type: 'SHOW_NOT_FOUND' });
         }
      };

      const fetchClosedOrders = async (accessToken: string) => {
         try {
            const closedOrdersResponse = await getOrders(accessToken, false);
            let closedOrdersList: DisplayOrder[] = [];

            if (closedOrdersResponse) {
               closedOrdersList = [...closedOrdersList, ...closedOrdersResponse];
            }

            const mappedClosedOrders = OrderMapperUtils.getMappedOrders(closedOrdersList);
            dispatch({ type: 'SET_CLOSED_ORDERS', payload: mappedClosedOrders.closedOrderLines });
         } catch (error) {
            dispatch({ type: 'SHOW_NOT_FOUND' });
         }
      };

      if (token) {
         fetchOpenOrders(token);
         fetchClosedOrders(token);
      }
   }, [token]);

   useEffect(() => {
      if (openOrders && closedOrders && !isClosedLoading && !isOpenLoading) {
         setAddressesOptions([...addressesOptions, ...OrderFilterUtils.getAddressesOptions([...openOrders.openOrderLines, ...closedOrders])]);
      }
   }, [isClosedLoading, isOpenLoading]);

   const onSearchOrFilter = (mappedOrders: MappedOrders | undefined, isFilter: boolean) => {
      if (mappedOrders) {
         setFilteredOrders(mappedOrders);
      } else {
         setFilteredOrders(undefined);
      }

      setIsFiltered(isFilter);
   };

   if (showNotFound) {
      return (
         <Box direction="column">
            <Typography variant="heading-1" component="h1" align="left" color="strong">
               {translate('my-orders.order-overview-header')}
            </Typography>
            <EmptyState
               label={translate('my-orders.no-orders-found')}
               image={<OrderNotFoundIllustration />}
               title={translate('shipment.not-found-title')}
            />
            <Divider color="default" orientation="horizontal" />
         </Box>
      );
   }

   if (user?.groups?.includes(UserGroups.digitalPlatformGuest)) {
      return (
         <Box direction="column">
            <Typography variant="heading-1" component="h1" align="left" color="strong">
               {translate('my-orders.order-overview-header')}
            </Typography>
            <EmptyState
               content={
                  <Box>
                     <Typography component="span" variant="body">
                        {translate('my-orders.guest1')}{' '}
                        <Link underline="none" href={`mailto:${BusinessContextUtils.getContactEmail(businessContext, countryCode)}`}>
                           <Typography color="tertiary">{BusinessContextUtils.getContactEmail(businessContext, countryCode)}</Typography>
                        </Link>
                        {translate('my-orders.guest2')}
                     </Typography>
                  </Box>
               }
               image={<GuestNotAllowedIllustration />}
            />
         </Box>
      );
   }

   if (user?.groups?.includes(UserGroups.digitalPlatformPendingVerification)) {
      return (
         <Box direction="column">
            <Typography variant="heading-1" component="h1" align="left" color="strong">
               {translate('my-orders.order-overview-header')}
            </Typography>
            <EmptyState label={translate('my-orders.pending')} image={<PendingNotAllowedIllustration />} aspectRatioWidth="65%" />
         </Box>
      );
   }

   return (
      <Box direction="column">
         <Box direction="column">
            <Typography variant="heading-1" component="h1" align="left" color="strong">
               {translate('my-orders.order-overview-header')}
            </Typography>

            <OrderOverviewSearchFilter
               onSearchOrFilter={onSearchOrFilter}
               openOrders={openOrders}
               closedOrders={closedOrders}
               addressesOptions={addressesOptions}
               hasCallOffs={hasCallOffs}
               disabled={isOpenLoading || isClosedLoading}
            />

            {openOrders && (
               <OrderShipments
                  isConsumer={isConsumer}
                  locale={locale}
                  acceptWebp={acceptWebp}
                  orderDetail={isFiltered && filteredOrders ? filteredOrders : openOrders}
                  shipmentBaseUrl={shipmentBaseUrl}
                  callOffAgreementBaseUrl={callOffAgreementBaseUrl}
                  orderDetailBaseUrl={orderDetailBaseUrl}
                  openedFrom="order_overview_shipment"
               />
            )}

            {isFiltered && filteredOrders?.transportArrangedShipments.length === 0 && filteredOrders?.inTransitShipments.length === 0 && (
               <Typography component="p" variant="body" align="center">
                  {translate('my-orders.order-overview-empty-transport-orders')}
               </Typography>
            )}

            <Box direction="column" spacing={{ top: 'md' }}>
               <Tabs tabs={tabs} activeTab={activeTab} onTabChange={setActiveTab} />
            </Box>
         </Box>

         {activeTab === 0 && (
            <>
               {isOpenLoading && (
                  <Box direction="column" items="center">
                     <Spinner />
                  </Box>
               )}
               {!isOpenLoading &&
                  openOrders &&
                  ((!isFiltered && openOrders.openOrderLines.length > 0) ||
                     (isFiltered && filteredOrders && filteredOrders.openOrderLines.length > 0)) && (
                     <OrderLinesTable
                        orderLines={isFiltered && filteredOrders ? filteredOrders.openOrderLines : openOrders.openOrderLines}
                        isConsumer={isConsumer}
                        acceptWebp={acceptWebp}
                        locale={locale}
                        shipmentBaseUrl={shipmentBaseUrl}
                        orderDetailBaseUrl={orderDetailBaseUrl}
                        callOffAgreementBaseUrl={callOffAgreementBaseUrl}
                        itemsPerPage={10}
                        openedFrom="order_overview_order"
                     />
                  )}
               {!isOpenLoading &&
                  (!openOrders || openOrders.openOrderLines.length === 0 || (filteredOrders?.openOrderLines.length === 0 && isFiltered)) && (
                     <EmptyState
                        label={translate('my-orders.no-open-orders-intro')}
                        image={<OrderNotFoundIllustration />}
                        title={translate('my-orders.no-open-orders')}
                     />
                  )}
            </>
         )}

         {activeTab === 1 && (
            <>
               {isClosedLoading && (
                  <Box direction="column" items="center">
                     <Spinner />
                  </Box>
               )}
               {!isClosedLoading && closedOrders && closedOrders.length > 0 && (
                  <OrderLinesTable
                     orderLines={isFiltered && filteredOrders ? filteredOrders.closedOrderLines : closedOrders}
                     isConsumer={isConsumer}
                     acceptWebp={acceptWebp}
                     locale={locale}
                     shipmentBaseUrl={shipmentBaseUrl}
                     orderDetailBaseUrl={orderDetailBaseUrl}
                     itemsPerPage={10}
                     callOffAgreementBaseUrl={callOffAgreementBaseUrl}
                     openedFrom="order_overview_order"
                  />
               )}
               {!isClosedLoading && (!closedOrders || closedOrders.length === 0 || (filteredOrders?.closedOrderLines.length === 0 && isFiltered)) && (
                  <EmptyState
                     label={translate('my-orders.no-open-orders-intro')}
                     image={<OrderNotFoundIllustration />}
                     title={translate('my-orders.no-closed-orders')}
                  />
               )}
            </>
         )}
      </Box>
   );
};
