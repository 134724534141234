import { FC } from 'react';

import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';

export interface BadgeItem {
   id: string;
   label: string;
   leadingIcon?: string;
   trailingIcon?: string;
   variant?: 'primary' | 'secondary' | 'disabled' | 'success' | 'error' | 'warning' | 'info' | 'neutral';
}

interface Props {
   badges: BadgeItem[];
   weight?: 'regular' | 'bold';
   size?: 'regular' | 'extra-large';
}

export const BadgeList: FC<Props> = ({ badges, weight = 'bold', size = 'regular' }) => (
   <Box wrap="wrap" gap="xs">
      {badges.map((badge) => (
         <Badge key={badge.id} weight={weight} size={size} variant={badge.variant} leadingIcon={badge.leadingIcon} trailingIcon={badge.trailingIcon}>
            {badge.label}
         </Badge>
      ))}
   </Box>
);
