'use client';

import { FC, ReactNode } from 'react';

import { ElementProps, Node } from '@ravago/shared/page-data/models/base';
import { ContentfulCaseStudyEntry } from '@ravago/shared/page-data/models/contentful';

import { Entry } from 'contentful';

import { useTranslations } from '../../../providers/translation-client-context-provider';
import { ItemInformationTable } from '../../shared/item-information-table/item-information-table';

export const CaseStudyInformationElement: FC<ElementProps<Node>> = ({ globals }) => {
   const translate = useTranslations();
   const caseStudy = globals.contentEntity as Entry<ContentfulCaseStudyEntry>;

   if (!caseStudy) return null;

   const properties: { [key: string]: { label: string; value?: ReactNode; dataCy?: string } } = {
      type: {
         label: translate('case-study-detail-info-table-type'),
         value: caseStudy?.fields?.type,
         dataCy: 'productionCountry'
      },
      productionCountry: {
         label: translate('case-study-detail-header-country'),
         value: caseStudy?.fields?.productionCountry?.fields?.name,
         dataCy: 'productionCountry'
      }
   };

   return <ItemInformationTable properties={properties} />;
};
