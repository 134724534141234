'use client';

import { ChangeEventHandler, FC, useEffect, useMemo, useRef, useState, KeyboardEvent, MouseEvent, Fragment, ComponentProps } from 'react';

import { groupBy } from 'lodash';

import { Badge } from '../../../badge/components/Badge/Badge';
import { Icon } from '../../../icon/components/Icon/Icon';
import { List } from '../../../list/components/List/List';
import { ListItem } from '../../../list/components/ListItem/ListItem';
import { Menu } from '../../../menu/components/Menu/Menu';
import { Typography } from '../../../typography/components/Typography/Typography';

import { ColorPickerListItem } from './ColorPickerListItem';

export interface ColorPickerOptionBadge {
   label: string;
   variant: ComponentProps<typeof Badge>['variant'];
}

export interface ColorPickerOption {
   id: string;
   label: string;
   code?: string;
   badges?: Array<ColorPickerOptionBadge>;
}

interface Props {
   id: string;
   disabled?: boolean;
   error?: boolean;
   errorMessage?: string;
   label?: string;
   leadingIcon?: boolean;
   options: Array<ColorPickerOption>;
   readOnly?: boolean;
   required?: boolean;
   supportText?: string;
   noResultsText?: string;
   noResultsSupportText?: string;
   trailingIcon?: string;
   value?: ColorPickerOption;
   width?: string | number;
   onChange?: (value: ColorPickerOption | undefined) => void;
   onSearch?: (value: string) => void;
   groupByFn?: (option: ColorPickerOption) => string;
}

export const ColorPicker: FC<Props> = ({ id, disabled = false, error = false, errorMessage, label, leadingIcon = true, options, readOnly = false, required = false, supportText, noResultsText, noResultsSupportText, trailingIcon, value, width, onChange, onSearch, groupByFn }) => {
   const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
   const [menuOpen, setMenuOpen] = useState(false);
   const [focused, setFocused] = useState(false);
   const [focusedIndex, setFocusedIndex] = useState(0);

   const visualOptions = useMemo(() => {
      if (searchValue) return options.filter((o) => o.label.toLowerCase().includes(searchValue.toLowerCase()));
      return options;
   }, [searchValue, options]);

   const groupedOptions = useMemo(() => {
      if (groupByFn) return groupBy(visualOptions, groupByFn);
      return visualOptions;
   }, [visualOptions, groupByFn]);

   const inputRef = useRef<HTMLInputElement>(null);

   const inputValue = searchValue ?? value?.label ?? '';

   const getIndex = (option: ColorPickerOption) => visualOptions.findIndex((visualOption) => visualOption.id === option.id);

   const handleClick = (event: MouseEvent<HTMLDivElement>) => {
      if (event.target === inputRef.current) {
         if (!inputValue) {
            setMenuOpen((curr) => !curr);
         } else if (!menuOpen) {
            setMenuOpen(true);
         }
      } else {
         event.preventDefault();
         inputRef.current?.focus();

         setMenuOpen((curr) => !curr);
      }
   };

   const handleChange = (option: ColorPickerOption) => {
      if (value !== option) onChange?.(option);
      setSearchValue(undefined);
      setMenuOpen(false);
   };

   const handleOptionClick = (event: MouseEvent<HTMLElement>, option: ColorPickerOption) => {
      event.preventDefault();
      handleChange(option);
   };

   const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      if (event.target.value === '' && value) {
         onChange?.(undefined);
      }
      onSearch?.(event.target.value);
      setSearchValue(event.target.value);
      setMenuOpen(true);
   };

   const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.code === 'Space' && !inputValue) {
         event.stopPropagation();
         event.preventDefault();
         setMenuOpen(true);
      } else if (event.key === 'ArrowUp' && focusedIndex !== 0) {
         event.preventDefault();
         setFocusedIndex(focusedIndex - 1);
      } else if (event.key === 'ArrowDown' && focusedIndex !== visualOptions.length - 1) {
         event.preventDefault();
         setFocusedIndex(focusedIndex + 1);
      } else if (event.key === 'Enter') {
         handleChange(visualOptions[focusedIndex]);
      }
   };

   const handleBlur = () => {
      setSearchValue(undefined);
      setFocused(false);
      setMenuOpen(false);
   };

   const handleFocus = () => {
      setFocused(true);
   };

   useEffect(() => {
      const index = options.findIndex((option) => option.id === value?.id);
      if (index) {
         setFocusedIndex(index);
      } else setFocusedIndex(0);
   }, [searchValue, focused, menuOpen, value]);

   return (
      <div data-testid={`color-picker${error ? '--error' : ''}${readOnly ? '--read-only' : ''}${menuOpen ? '--open' : ''}`} style={{ width }} className={`gap-component-input-color-picker-container-spacing-outer-gap flex w-full flex-col ${disabled || readOnly ? 'pointer-events-none' : ''}`}>
         <Menu
            content={
               <div id={`${id}-menu`} data-testid="color-picker-menu" className="overflow-y-auto">
                  <List role="listbox">
                     {!visualOptions ||
                        (visualOptions?.length === 0 && (
                           <ListItem disabled role="option">
                              {noResultsText && (
                                 <div data-testid="color-picker-noResultsText">
                                    <Typography whiteSpace="nowrap" variant="small" color="subtle">
                                       {noResultsText}
                                    </Typography>
                                 </div>
                              )}
                              {noResultsSupportText && (
                                 <div data-testid="color-picker-noResultsSupportText">
                                    <Typography whiteSpace="nowrap" variant="small" color="subtle">
                                       {noResultsSupportText}
                                    </Typography>
                                 </div>
                              )}
                           </ListItem>
                        ))}

                     {Array.isArray(groupedOptions)
                        ? groupedOptions.map((option, index) => <ColorPickerListItem key={option.id} option={option} focused={focusedIndex === getIndex(option)} searchValue={searchValue} onClick={(e) => handleOptionClick(e, option)} />)
                        : Object.entries(groupedOptions).map(([group, optionsFromGroup], index, arr) => (
                             <Fragment key={group}>
                                <ListItem interactive={false} variant="alternate">
                                   <Typography variant="body">{group}</Typography>
                                </ListItem>
                                {optionsFromGroup.map((option) => (
                                   <ColorPickerListItem key={option.id} option={option} focused={focusedIndex === getIndex(option)} searchValue={searchValue} onClick={(e) => handleOptionClick(e, option)} />
                                ))}
                             </Fragment>
                          ))}
                  </List>
               </div>
            }
            open={menuOpen}
            maxHeight
            width="fill"
            closeOnOutsideClick={false}
         >
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/interactive-supports-focus */}
            <div className="group relative w-full cursor-text" role="combobox" aria-controls={`${id}-menu`} aria-expanded={menuOpen} onMouseDown={handleClick}>
               {label && (
                  <label
                     data-testid="color-picker-label"
                     htmlFor={id}
                     style={{
                        maxWidth:
                           value || menuOpen || focused
                              ? 'calc(100% -  2 * var(--component-input-color-picker-container-spacing-padding-x))'
                              : 'calc(100% - var(--component-data-display-icon-container-sizing-width-body) - var(--component-input-color-picker-container-spacing-gap) - 2 * var(--component-input-color-picker-container-spacing-padding-x))'
                     }}
                     className={`absolute left-0 top-0 flex items-center transition-all cursor-pointer
                           ${!error && !disabled && (value || menuOpen || focused) ? 'text-component-input-color-picker-label-color-text-neutral-enabled-as-label' : ''}
                           ${!error && !disabled ? 'group-focus-within:text-component-input-color-picker-label-color-text-neutral-focused' : ''}
                           ${!error && !disabled && (menuOpen || focused) ? 'text-component-input-color-picker-label-color-text-neutral-focused' : ''}
                           ${!error && !disabled && !value && !menuOpen && !focused ? 'text-component-input-color-picker-label-color-text-neutral-enabled-as-placeholder' : ''}
                           ${disabled ? 'text-component-input-color-picker-label-color-text-neutral-disabled' : ''}
                           ${error && !disabled ? 'text-component-input-color-picker-label-color-text-error-enabled group-hover:!text-component-input-color-picker-label-color-text-error-hover' : ''}
                           ${
                              value || menuOpen || focused
                                 ? 'bg-component-input-color-picker-container-color-background px-component-input-color-picker-label-spacing-padding-x h-[var(--component-input-color-picker-container-border-width-enabled)] group-focus-within:h-[var(--component-input-color-picker-container-border-width-focused)]'
                                 : 'h-full'
                           }
                           ${menuOpen || focused ? 'h-[var(--component-input-color-picker-container-border-width-focused)]' : ''}
                           ${
                              !value && leadingIcon && !focused
                                 ? 'ml-[calc(var(--component-input-color-picker-container-spacing-padding-x)+var(--component-input-color-picker-container-spacing-gap)+(3*var(--brand-font-size-new-desktop-md)+2*var(--component-input-color-picker-orbs-spacing-gap)))]'
                                 : 'ml-component-input-color-picker-container-spacing-padding-x'
                           }
                      `.replace(/\s+/g, ' ')}
                  >
                     <Typography variant={value || menuOpen || focused ? 'caption' : 'body'} color="inherit" maxLines="1">
                        {label}
                        {required && '*'}
                     </Typography>
                  </label>
               )}

               <div
                  data-testid="color-picker-container"
                  className={`h-component-input-color-picker-container-sizing-height px-component-input-color-picker-container-spacing-padding-x py-component-input-color-picker-container-spacing-padding-y gap-component-input-color-picker-container-spacing-gap bg-component-input-color-picker-container-color-background rounded-component-input-color-picker-container-border-radius border-component-input-color-picker-container-border-width-enabled focus-within:!border-component-input-color-picker-container-border-width-focused box-border flex items-start justify-between transition-all
                           ${
                              error
                                 ? '!border-component-input-color-picker-container-color-border-error-enabled hover:!border-component-input-color-picker-container-color-border-error-hover focus-within:!border-component-input-color-picker-container-color-border-error-enabled'
                                 : '!border-component-input-color-picker-container-color-border-neutral-enabled hover:!border-component-input-color-picker-container-color-border-neutral-hover focus-within:!border-component-input-color-picker-container-color-border-neutral-focused'
                           }
                           ${menuOpen ? '!border-component-input-color-picker-container-border-width-focused' : ''}
                           ${menuOpen && error ? '!border-component-input-color-picker-container-color-border-error-enabled' : ''}
                           ${menuOpen && !error ? '!border-component-input-color-picker-container-color-border-neutral-enabled' : ''}
                  `.replace(/\s+/g, ' ')}
               >
                  {leadingIcon && (
                     <div data-testid="color-picker-leading-icon" className="m-auto flex h-full cursor-pointer items-center justify-center">
                        {value ? (
                           <div style={{ color: value.code || 'var(--component-input-color-picker-icon-end-color-text-on-surface-enabled)' }}>
                              <Icon size={value.code ? 'body-highlight' : 'body'} icon={value.code ? 'circle' : 'circle-dashed'} />
                           </div>
                        ) : (
                           <div className="flex items-center justify-center">
                              <div className="border-component-input-color-picker-orbs-default bg-component-input-color-picker-orbs-orb-1-color-background-fill border-component-input-color-picker-orbs-orb-1-color-border-fill size-[var(--brand-font-size-new-desktop-md)] rounded-full" />
                              <div className="border-component-input-color-picker-orbs-default bg-component-input-color-picker-orbs-orb-2-color-background-fill border-component-input-color-picker-orbs-orb-2-color-border-fill ml-component-input-color-picker-orbs-spacing-gap size-[var(--brand-font-size-new-desktop-md)] rounded-full" />
                              <div className="border-component-input-color-picker-orbs-default bg-component-input-color-picker-orbs-orb-3-color-background-fill border-component-input-color-picker-orbs-orb-3-color-border-fill ml-component-input-color-picker-orbs-spacing-gap size-[var(--brand-font-size-new-desktop-md)] rounded-full" />
                           </div>
                        )}
                     </div>
                  )}
                  <div className="flex h-full flex-1 items-center">
                     <input
                        id={id}
                        data-testid="color-picker-input"
                        autoComplete="off"
                        className="text-component-input-color-picker-input-color-text-on-surface-enabled disabled:text-component-input-color-picker-input-color-text-on-surface-disabled typography-component-typography-body-regular-md w-full bg-transparent outline-none"
                        disabled={disabled}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyPress}
                        onChange={handleInputChange}
                        onFocus={handleFocus}
                        name={id}
                        ref={inputRef}
                        required={required}
                        value={inputValue}
                     />
                  </div>
                  {value?.badges?.map((badge) => (
                     <Badge key={badge.label} variant={badge.variant}>
                        {badge.label}
                     </Badge>
                  ))}
                  {trailingIcon && (
                     <div
                        data-testid="color-picker-trailing-icon"
                        className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center ${
                           disabled ? 'text-component-input-color-picker-icon-end-color-text-on-surface-disabled' : 'text-component-input-color-picker-icon-end-color-text-on-surface-enabled'
                        }`.replace(/\s+/g, ' ')}
                     >
                        <Icon icon={trailingIcon} />
                     </div>
                  )}
                  <div
                     data-testid="color-picker-chevron"
                     className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center transition-all cursor-pointer ${
                        disabled ? 'text-component-input-color-picker-icon-end-color-text-on-surface-disabled' : 'text-component-input-color-picker-icon-end-color-text-on-surface-enabled'
                     } ${menuOpen ? 'rotate-180' : ''}`.replace(/\s+/g, ' ')}
                  >
                     <Icon icon="chevron-down" />
                  </div>
               </div>
            </div>
         </Menu>
         {supportText && (
            <div
               data-testid="color-picker-support-text"
               className={`pl-component-input-color-picker-supporting-text-spacing-padding-left gap-component-input-color-picker-supporting-text-spacing-gap flex items-center

                        ${!disabled ? 'text-component-input-color-picker-supporting-text-color-text-neutral-enabled' : ''}
                        ${disabled ? 'text-component-input-color-picker-supporting-text-color-text-neutral-disabled' : ''}
                        `.replace(/\s+/g, ' ')}
            >
               {supportText && (
                  <Typography variant="caption" color="inherit">
                     {supportText}
                  </Typography>
               )}
            </div>
         )}
         {error && errorMessage && (
            <div data-testid="color-picker-error-message" className="pl-component-input-color-picker-supporting-text-spacing-padding-left gap-component-input-color-picker-supporting-text-spacing-gap text-component-input-color-picker-supporting-text-color-text-error flex items-center">
               <Icon icon="exclamation-circle" size="caption" />
               <Typography variant="caption" color="inherit">
                  {errorMessage}
               </Typography>
            </div>
         )}
      </div>
   );
};
