'use client';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { IconButton } from '../../../button/components/IconButton/IconButton';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   total: number;
   current?: number;
   truncate?: boolean;
   onChange?: (page: number) => void;
}

export const Pagination: FC<Props> = ({ total, current = 1, truncate = true, onChange }) => {
   const [currentPage, setCurrentPage] = useState(current);

   /* istanbul ignore next */
   const createPanes = useCallback(
      (panes: 5 | 7): Array<number | string> => {
         // 1 - 2 - 3 - 4 <-- total = 4
         if (total <= panes) {
            return Array.from(Array(total), (_, page) => page + 1);
         }

         // 3 - 4 - 5 - 6 - 7 - 8 - 9 <-- total = 20
         if (!truncate) {
            let startingPage = currentPage - Math.floor(panes / 2);
            if (startingPage < 1) startingPage = 1;
            if (startingPage + panes > total) startingPage = total - panes + 1;

            return Array.from(Array(panes), (_, page) => startingPage + page);
         }

         const startCollapseOffset = Math.ceil(panes / 2);
         const endCollapseOffset = total - Math.ceil((panes - 2) / 2);

         // 1 - 2 - 3 - 4 - 5 - ... - 10
         if (currentPage <= startCollapseOffset && currentPage < endCollapseOffset) {
            return [...Array.from(Array(panes - 2), (_, page) => page + 1), '...', total];
         }

         // 1 - ... - 6 - 7 - 8 - 9 - 10
         if (currentPage >= endCollapseOffset && currentPage > startCollapseOffset) {
            return [1, '...', ...Array.from(Array(panes - 2), (_, page) => page + total - panes + 3)];
         }

         // 1 - ... - 4 - 5 - 6 - ... - 10
         const numberOfMiddlePages = panes - 4;
         const startingPage = currentPage - Math.floor(numberOfMiddlePages / 2);

         return [1, '...', ...Array.from(Array(numberOfMiddlePages), (_, i) => startingPage + i), '...', total];
      },
      [currentPage, total, truncate]
   );

   /* istanbul ignore next */
   const handlePageChange = (page: number) => {
      let newPage = page;
      if (newPage < 1) newPage = 1;
      else if (newPage > total) newPage = total;

      if (currentPage === newPage) return;

      setCurrentPage(newPage);
      onChange?.(newPage);
   };

   useEffect(() => setCurrentPage(current), [current]);

   const phoneArray = useMemo<Array<string | number>>(() => createPanes(5), [createPanes]);
   const paneArray = useMemo<Array<string | number>>(() => createPanes(7), [createPanes]);

   return (
      <div data-testid="pagination" className="flex items-center">
         <div data-testid="pagination-previous" onClick={() => handlePageChange(currentPage - 1)} role="presentation" className={currentPage <= 1 ? 'pointer-events-none' : ''}>
            <IconButton icon="chevron-left" disabled={currentPage <= 1} />
         </div>
         <div className="tab-port:hidden tab-land:hidden desktop:hidden contents">
            {phoneArray.map((pane, index) =>
               pane === '...' ? (
                  <div
                     key={`pagination-pane--truncate--${index === 1 ? 'start' : 'end'}`}
                     data-testid={`pagination-pane--mobile--truncate--${index === 1 ? 'start' : 'end'}`}
                     className="text-component-navigation-pagination-item-label-color-text-inactive w-component-navigation-pagination-item-touch-area-sizing-size min-h-component-navigation-pagination-item-touch-area-sizing-min-size min-w-component-navigation-pagination-item-touch-area-sizing-min-size flex items-center justify-center"
                  >
                     <Typography variant="body-highlight" color="inherit">
                        ...
                     </Typography>
                  </div>
               ) : (
                  <div
                     key={`pagination-pane--mobile--${pane}`}
                     data-testid={`pagination-pane--mobile--${pane}`}
                     onClick={() => handlePageChange(pane as number)}
                     role="presentation"
                     className="min-h-component-navigation-pagination-item-touch-area-sizing-min-size min-w-component-navigation-pagination-item-touch-area-sizing-min-size p-component-navigation-pagination-item-touch-area-spacing-padding group flex cursor-pointer items-center justify-center"
                  >
                     <button type="button" className={`rounded-component-navigation-pagination-item-container-border-radius  appearance-none overflow-hidden ${currentPage === (pane as number) ? 'bg-component-navigation-pagination-item-container-color-background-active' : ''}`.replace(/\s+/g, ' ')}>
                        <div
                           className={` flex items-center justify-center p-component-navigation-pagination-item-state-layer-spacing-padding aspect-square box-content w-[var(--brand-line-height-md)]
                                    ${
                                       currentPage === (pane as number)
                                          ? 'text-component-navigation-pagination-item-label-color-text-active group-hover:bg-component-navigation-pagination-item-state-layer-color-background-active-hover group-[has(:focus-visible)]:bg-component-navigation-pagination-item-state-layer-color-background-active-focused group-active:bg-component-navigation-pagination-item-state-layer-color-background-active-pressed'
                                          : 'text-component-navigation-pagination-item-label-color-text-inactive group-hover:bg-component-navigation-pagination-item-state-layer-color-background-inactive-hover group-[has(:focus-visible)]:bg-component-navigation-pagination-item-state-layer-color-background-inactive-focused group-active:bg-component-navigation-pagination-item-state-layer-color-background-inactive-pressed'
                                    }
                                 `.replace(/\s+/g, ' ')}
                        >
                           <Typography variant={currentPage === (pane as number) ? 'body-highlight' : 'body'} color="inherit">
                              {pane}
                           </Typography>
                        </div>
                     </button>
                  </div>
               )
            )}
         </div>
         <div className="phone:hidden contents">
            {paneArray.map((pane, index) =>
               pane === '...' ? (
                  <div
                     key={`pagination-pane--truncate--${index === 1 ? 'start' : 'end'}`}
                     data-testid={`pagination-pane--truncate--${index === 1 ? 'start' : 'end'}`}
                     className="text-component-navigation-pagination-item-label-color-text-inactive min-h-component-navigation-pagination-item-touch-area-sizing-min-size min-w-component-navigation-pagination-item-touch-area-sizing-min-size box-content flex aspect-square w-[var(--brand-line-height-md)] items-center justify-center"
                  >
                     <Typography variant="body-highlight" color="inherit">
                        ...
                     </Typography>
                  </div>
               ) : (
                  <div
                     key={`pagination-pane--${pane}`}
                     data-testid={`pagination-pane--${pane}`}
                     onClick={() => handlePageChange(pane as number)}
                     role="presentation"
                     className="min-h-component-navigation-pagination-item-touch-area-sizing-min-size min-w-component-navigation-pagination-item-touch-area-sizing-min-size p-component-navigation-pagination-item-touch-area-spacing-padding group flex cursor-pointer items-center justify-center"
                  >
                     <button
                        type="button"
                        className={`rounded-component-navigation-pagination-item-container-border-radius h-component-navigation-pagination-item-container-sizing-size w-component-navigation-pagination-item-container-sizing-size min-h-component-navigation-pagination-item-container-sizing-min-size min-w-component-navigation-pagination-item-container-sizing-min-size flex appearance-none items-center justify-center overflow-hidden ${
                           currentPage === (pane as number) ? 'bg-component-navigation-pagination-item-container-color-background-active' : ''
                        }`.replace(/\s+/g, ' ')}
                     >
                        <div
                           className={`flex items-center justify-center p-component-navigation-pagination-item-state-layer-spacing-padding aspect-square box-content w-[var(--brand-line-height-md)]
                                    ${
                                       currentPage === (pane as number)
                                          ? 'text-component-navigation-pagination-item-label-color-text-active group-hover:bg-component-navigation-pagination-item-state-layer-color-background-active-hover group-[has(:focus-visible)]:bg-component-navigation-pagination-item-state-layer-color-background-active-focused group-active:bg-component-navigation-pagination-item-state-layer-color-background-active-pressed'
                                          : 'text-component-navigation-pagination-item-label-color-text-inactive group-hover:bg-component-navigation-pagination-item-state-layer-color-background-inactive-hover group-[has(:focus-visible)]:bg-component-navigation-pagination-item-state-layer-color-background-inactive-focused group-active:bg-component-navigation-pagination-item-state-layer-color-background-inactive-pressed'
                                    }
                                 `.replace(/\s+/g, ' ')}
                        >
                           <Typography variant={currentPage === (pane as number) ? 'body-highlight' : 'body'} color="inherit">
                              {pane}
                           </Typography>
                        </div>
                     </button>
                  </div>
               )
            )}
         </div>
         <div data-testid="pagination-next" onClick={() => handlePageChange(currentPage + 1)} role="presentation" className={currentPage >= total ? 'pointer-events-none' : ''}>
            <IconButton icon="chevron-right" disabled={currentPage >= total} />
         </div>
      </div>
   );
};
