'use client';

import { FC } from 'react';

import { AspectRatio } from '@ravago/shared/radiance/components/aspect-ratio/components/AspectRatio/AspectRatio';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../../../providers/translation-client-context-provider';

interface Props {
   searchTerm: string;
   language: string;
}

export const ProductPortfolioEmpty: FC<Props> = ({ searchTerm = '', language }) => {
   const translate = useTranslations();

   return (
      <Box direction="column" items="center">
         <AspectRatio desktop="32/9" tablet="21/9" phone="21/9" width="100%">
            <svg style={{ width: '100%', height: '100%' }}>
               <use href="/illustrations/empty-state.svg#symbol" />
            </svg>
         </AspectRatio>
         <Typography variant="heading-1" align="center" color="strong">
            {translate('products-no-results-title')}
         </Typography>
         <Typography color="subtle">
            <p
               dangerouslySetInnerHTML={{
                  __html: translate(searchTerm === '' ? 'products-no-results' : 'products-no-search-results', { searchTerm })
               }}
            />
         </Typography>
      </Box>
   );
};
