'use client';

import { FC, useEffect, useState } from 'react';

import { useAuthenticationContext } from '@ravago/shared/authentication';
import { AutoSuggestResponse, FacetFilterWithTerms } from '@ravago/shared/page-data/models/elastic';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { InputGroup } from '@ravago/shared/radiance/components/input-field/components/InputGroup/InputGroup';

import { useSearchParams } from 'next/navigation';

import { useTranslations } from '../../../../../../providers/translation-client-context-provider';
import { FeaturePermissionsUtils } from '../../../../../../utils/feature-permissions/feature-permissions.utils';
import { AutoSuggest } from '../../../../../shared/auto-suggest/auto-suggest';

interface Props {
   facetFilters: Array<FacetFilterWithTerms>;
   language: string;
   globals: RendererGlobals;
   autoSuggestResult?: AutoSuggestResponse;
   loading?: boolean;
   autoSuggestExpanded?: boolean;
   numberOfFilters?: number;
   baseRoutes?: {
      product: string;
      brand: string;
      caseStudy: string;
   };
   onFilterFlyoutOpen?: () => void;
   onFiltersChange?: (filters: Array<FacetFilterWithTerms>) => void;
   onSearchChange?: (value: string) => void;
   onSearch?: (value: string) => void;
   onAutoSuggestMenuChange?: (value: boolean) => void;
}

export const ProductPortfolioSearch: FC<Props> = ({
   facetFilters,
   language,
   globals,
   autoSuggestResult,
   loading = false,
   autoSuggestExpanded,
   numberOfFilters = 0,
   baseRoutes,
   onFilterFlyoutOpen,
   onFiltersChange,
   onSearchChange,
   onSearch,
   onAutoSuggestMenuChange
}) => {
   const translate = useTranslations();
   const searchParams = useSearchParams();
   const initialSearch = searchParams.get('search') ?? '';

   const { token } = useAuthenticationContext();

   const [hasPermission, setHasPermission] = useState<boolean>(true);

   const handleSearchChange = (value: string) => {
      onSearchChange?.(value);
   };

   const handleSearch = (value: string) => {
      onSearch?.(value);
   };

   const handleClear = () => {
      onSearchChange?.('');
      onSearch?.('');
   };

   const handleApplyFilters = (filters: Array<FacetFilterWithTerms>) => {
      filters.forEach((filter) => {
         const existingFacet = facetFilters.find((facetFilter) => facetFilter.facet === filter.facet);

         if (existingFacet) {
            existingFacet.terms.push(...filter.terms.filter((term) => !existingFacet.terms.includes(term)));
         } else {
            facetFilters.push(filter);
         }

         onFiltersChange?.(facetFilters);
      });
   };

   useEffect(() => {
      if (!token) return;

      const checkPermission = async () => {
         const permissions = await globals.actions.getPrivateToggles(token);
         setHasPermission(FeaturePermissionsUtils.hasPermission(permissions, 'auto-suggest'));
      };

      checkPermission();
   }, [token]);

   return (
      <Box gap="sm">
         {hasPermission ? (
            <AutoSuggest
               id="product-portfolio-search"
               language={language}
               loading={loading}
               result={autoSuggestResult}
               baseRoutes={baseRoutes}
               globals={globals}
               searchTerm={initialSearch}
               onChange={handleSearchChange}
               onSearch={handleSearch}
               onClear={handleClear}
               expanded={autoSuggestExpanded}
               onAutoSuggestMenuChange={onAutoSuggestMenuChange}
               onFacetsClick={handleApplyFilters}
            />
         ) : (
            <InputGroup
               id="product-portfolio-search"
               actionIcon="search"
               autocomplete="off"
               clearable
               label={translate('products-search-placeholder')}
               onChange={handleSearchChange}
               onEnter={handleSearch}
               onActionClick={handleSearch}
               onClear={handleClear}
               value={initialSearch}
               width="100%"
            />
         )}

         <BreakpointSwitch excludeViews={['desktop']}>
            <IconButton
               icon="sliders-h"
               variant="on-light"
               badge={numberOfFilters !== 0 ? numberOfFilters.toString() : undefined}
               onClick={onFilterFlyoutOpen}
            />
         </BreakpointSwitch>
      </Box>
   );
};
