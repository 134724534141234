'use client';

import React, { useRef } from 'react';

import { OverviewPageItem, OverviewPageProductItem } from '@ravago/shared/page-data/models/elastic';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Pagination } from '@ravago/shared/radiance/components/pagination/components/Pagination/Pagination';

import { ProductTable } from '../overview/product-table';

interface Props {
   baseRoutes?: {
      brand: string;
      caseStudy: string;
      product: string;
      market: string;
      technology: string;
   };
   globals: RendererGlobals;
   onPageChange: (newPage: number) => void;
   page: number;
   hasPublicLabelAndSigningsPermission: boolean;
   hasSustainabilityLabels: boolean;
   content: Array<OverviewPageItem>;
   totalPages?: number;
}

export const ProductPortfolioTable: React.FC<Props> = ({
   baseRoutes,
   content,
   globals,
   onPageChange,
   page,
   hasPublicLabelAndSigningsPermission,
   hasSustainabilityLabels,
   totalPages = 1
}) => {
   const containerRef = useRef<HTMLDivElement>(null);

   const handlePageChange = (newPage: number) => {
      onPageChange(newPage);

      if (!containerRef.current) return;

      window.scrollTo({ top: containerRef.current.offsetTop, behavior: 'smooth' });
   };

   return (
      <div ref={containerRef}>
         <Box direction="column">
            {content[0].qualifier === 'product' && (
               <ProductTable
                  data-testid="product-table-view"
                  globals={globals}
                  baseRoutes={baseRoutes}
                  hasPublicLabelAndSigningsPermission={hasPublicLabelAndSigningsPermission}
                  hasSustainabilityLabels={hasSustainabilityLabels}
                  products={content as Array<OverviewPageProductItem>}
               />
            )}
            <Box justify="end">
               <Pagination data-testid="product-table-pagination" current={page} onChange={handlePageChange} total={totalPages} />
            </Box>
         </Box>
      </div>
   );
};
