import { useEffect, useMemo } from 'react';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { useInvoiceDispatchContext, useInvoiceStateContext } from '../context/invoice-context';
import { InvoiceAction } from '../state/invoice.actions';
import { InvoiceFilterUtils } from '../utils/invoice-filter.utils';

export const useFilterOpenInvoices = (isOpen: boolean) => {
   const translate = useTranslations();
   const { openInvoiceFilters, openInvoices } = useInvoiceStateContext();
   const dispatch = useInvoiceDispatchContext();

   const updatedTypes = useMemo(() => {
      if (!openInvoices || !isOpen) return openInvoiceFilters.documentTypes;

      const types = InvoiceFilterUtils.getDocumentTypeOptions(openInvoices)
         .map((type) => ({
            ...type,
            label: translate(type.label)
         }))
         .sort((a, b) => a.label.localeCompare(b.label));

      const filteredInvoices = InvoiceFilterUtils.filterOpenInvoices(
         InvoiceFilterUtils.filterCurrencyInvoiceLinesById(openInvoices, openInvoiceFilters?.eInvoiceId),
         openInvoiceFilters,
         true
      );

      const allInvoiceItems =
         filteredInvoices?.flatMap((invoice) => invoice.currencyTransactions.flatMap((currencyInvoice) => currencyInvoice.transactions)) || [];

      const typeCounts = allInvoiceItems.reduce((acc, invoiceItem) => {
         acc[invoiceItem.type] = (acc[invoiceItem.type] || 0) + 1;
         return acc;
      }, {} as Record<string, number>);

      return types.map((type) => {
         const isChecked = openInvoiceFilters?.documentTypes?.find((dt) => dt.id === type.id)?.checked || false;
         const count = typeCounts[type.id] || 0;
         const isTypePresent = count > 0;

         return {
            ...type,
            checked: isChecked,
            disabled: !isTypePresent,
            trailingText: `(${count})`
         };
      });
   }, [isOpen, openInvoices, openInvoiceFilters.documentTypes, translate]);

   useEffect(() => {
      if (!isOpen || !openInvoices) return;

      const filteredInvoices = InvoiceFilterUtils.filterOpenInvoices(
         InvoiceFilterUtils.filterCurrencyInvoiceLinesById(openInvoices, openInvoiceFilters?.eInvoiceId),
         openInvoiceFilters,
         false
      );
      dispatch({ type: InvoiceAction.SET_FILTERED_OPEN_INVOICES, payload: filteredInvoices });
   }, [dispatch, isOpen, openInvoices, openInvoiceFilters]);

   useEffect(() => {
      if (!isOpen || !openInvoices) return;

      if (JSON.stringify(openInvoiceFilters.documentTypes) !== JSON.stringify(updatedTypes)) {
         dispatch({ type: InvoiceAction.SET_OPEN_INVOICE_FILTERS, payload: { ...openInvoiceFilters, documentTypes: updatedTypes } });
      }
   }, [dispatch, isOpen, openInvoices, openInvoiceFilters.documentTypes, updatedTypes]);
};
