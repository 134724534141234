import { FacetFilterTerm, FacetFilterWithTerms } from '@ravago/shared/page-data/models/elastic';

export const queryToFilter = (params: string): Array<FacetFilterWithTerms> => {
   const decodedFilter = decodeURIComponent(params);
   const filterObject: FacetFilterWithTerms[] = JSON.parse(decodedFilter);
   return filterObject;
};

export const filterToQuery = (filters: FacetFilterWithTerms[]): string => {
   const serializedFilter = JSON.stringify(filters);
   return encodeURIComponent(serializedFilter);
};

const propMap: Record<string, string> = {
   countries: 'sellingCountries',
   polymerGroups: 'group.raw',
   polymerTypes: 'subgroup.raw',
   specialFeatures: 'features.raw',
   fillers: 'fillers.name.raw',
   technologies: 'processingMethods.name.raw',
   brands: 'brand.raw',
   producers: 'producer.name.raw',
   qualities: 'quality.raw',
   markets: 'industries.name.raw'
};

const transformProperty = (parts: string[]): FacetFilterTerm[] => parts.map((part) => ({ term: part }));

const transformNestedProperty = (nestedFacetName: string, parts: string[]): FacetFilterTerm[] =>
   parts.reduce((acc: FacetFilterTerm[], part) => {
      if (!part.includes('---')) {
         acc.push({ term: part });
         return acc;
      }

      const [mainTerm, nestedTerm] = part.split('---');

      const existingTerm = acc.find((item) => item.term === mainTerm);
      const nestedFacet = existingTerm && existingTerm.nestedFacets ? existingTerm.nestedFacets[0] : undefined;

      if (nestedFacet) {
         nestedFacet.terms.push({ term: nestedTerm });
      } else {
         acc.push({
            term: mainTerm,
            nestedFacets: [{ facet: nestedFacetName, terms: [{ term: nestedTerm }] }]
         });
      }
      return acc;
   }, []);

const transformPropertyWithRange = (parts: string[]): FacetFilterTerm[] =>
   parts.reduce((acc: FacetFilterTerm[], part) => {
      if (!part.includes('---')) {
         return acc;
      }

      const [mainTerm, rangeTerm] = part.split('---');
      const [min, max] = rangeTerm.split('-');

      acc.push({ term: mainTerm, range: { min: Number(min), max: Number(max) } });

      return acc;
   }, []);

const getTerms = (property: string, parts: string[]) => {
   if (property === 'markets') return transformNestedProperty('industries.segments.name.raw', parts);
   if (property === 'fillers') return transformPropertyWithRange(parts);
   return transformProperty(parts);
};

const createFilter = (property: string, value: string | string[] | undefined): FacetFilterWithTerms | undefined => {
   if (!(property in propMap) || !value || Array.isArray(value)) return undefined;

   const parts = value.split(';');
   const terms = getTerms(property, parts);

   return {
      facet: propMap[property],
      terms
   };
};

export const legacyQueryToFilter = (params: Record<string, string | string[] | undefined>): FacetFilterWithTerms[] => {
   const facetFilters: FacetFilterWithTerms[] = [];

   Object.entries(params).forEach(([key, value]) => {
      const facetFilter = createFilter(key, value);
      if (facetFilter) facetFilters.push(facetFilter);
   });

   return facetFilters;
};
