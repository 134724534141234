import { NodeSpacingSides, NodeSpacingSizes } from '../../models/base/appearance.model';

export class Compatibility {
  static getMarginPadding(spacing?: NodeSpacingSides): NodeSpacingSides {
    const sizes: Record<number, NodeSpacingSizes> = {
      0: 'none',
      2: '3xs',
      4: '2xs',
      8: 'xs',
      12: 'sm',
      16: 'md',
      24: 'lg',
      32: 'xl',
      64: '2xl',
      72: '3xl',
      160: '13xl'
    };

    const values = Object.keys(sizes);
    const compatibleSpacing: NodeSpacingSides = {
      top: spacing?.top || 'none',
      right: spacing?.right || 'none',
      bottom: spacing?.bottom || 'none',
      left: spacing?.left || 'none'
    };

    const findClosestSize = (numericSize: number): number =>
      parseInt(
        values.reduce((prev, curr) => (Math.abs(parseInt(curr, 10) - numericSize) < Math.abs(parseInt(prev, 10) - numericSize) ? curr : prev)),
        10
      );

    if (typeof compatibleSpacing?.top === 'number') compatibleSpacing.top = sizes[findClosestSize(compatibleSpacing.top)];
    if (typeof compatibleSpacing?.right === 'number') compatibleSpacing.right = sizes[findClosestSize(compatibleSpacing.right)];
    if (typeof compatibleSpacing?.bottom === 'number') compatibleSpacing.bottom = sizes[findClosestSize(compatibleSpacing.bottom)];
    if (typeof compatibleSpacing?.left === 'number') compatibleSpacing.left = sizes[findClosestSize(compatibleSpacing.left)];

    return compatibleSpacing;
  }
}
