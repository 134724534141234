import { useEffect } from 'react';

import { useRouter } from 'next/navigation';

import { FeaturePermissions, Toggle } from '../models/feature-permissions.model';

export const useRedirectOnUnauthenticated = (permissions: FeaturePermissions | null) => {
   const router = useRouter();

   useEffect(() => {
      if (permissions?.[Toggle.MY_INVOICES] !== false) {
         return;
      }

      router.push('/');
   }, [permissions, router]);
};
