'use client';

import { FC, useEffect, useState } from 'react';

import { BlogPost } from '@ravago/shared/page-data/models/contentful';
import { BlogPreviewNode } from '@ravago/shared/page-data/models/elements';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { Grid } from '@ravago/shared/radiance/components/grid/components/Grid/Grid';
import { GridCol } from '@ravago/shared/radiance/components/grid/components/GridCol/GridCol';

import { ConsumerWrapper } from '../../content/consumer-wrapper/consumer-wrapper';
import { DynamicPageLink } from '../../content/dynamic-page-link/dynamic-page-link';
import { MockLink } from '../../content/mock-link/mock-link';

import { BlogPreviewImage } from './blog-preview-image';
import { BlogPreviewInfo } from './blog-preview-info';

interface Props {
   baseRoute?: string;
   blogPost: BlogPost;
   globals?: RendererGlobals;
   imagePosition?: BlogPreviewNode['imagePosition'];
   size?: BlogPreviewNode['size'];
}

export const BlogPreview: FC<Props> = ({ blogPost, size = 'normal', imagePosition, globals, baseRoute }) => {
   const [hasHover, setHasHover] = useState(false);

   useEffect(() => setHasHover(false), [size]);

   const image = <BlogPreviewImage blogPost={blogPost} size={size} zoomed={hasHover} acceptWebp={globals?.acceptWebp} />;
   const info = <BlogPreviewInfo size={size} blogPost={blogPost} globals={globals} baseRoute={baseRoute} />;

   if (size === 'normal') {
      return (
         <Grid useContainerQuery>
            <GridCol spanDesktop={6} spanTablet={12} spanPhone={4} useContainerQuery>
               <BreakpointSwitch includeViews={['desktop']} useContainerQuery>
                  {imagePosition === 'left' ? image : info}
               </BreakpointSwitch>
               <BreakpointSwitch excludeViews={['desktop']} useContainerQuery>
                  {image}
               </BreakpointSwitch>
            </GridCol>
            <GridCol spanDesktop={6} spanTablet={12} spanPhone={4} useContainerQuery>
               <BreakpointSwitch includeViews={['desktop']} useContainerQuery>
                  {imagePosition === 'left' ? info : image}
               </BreakpointSwitch>
               <BreakpointSwitch excludeViews={['desktop']} useContainerQuery>
                  {info}
               </BreakpointSwitch>
            </GridCol>
         </Grid>
      );
   }

   return (
      <ConsumerWrapper
         isConsumer={globals?.isConsumer !== undefined ? globals.isConsumer : false}
         renderConsumer={(children) => (
            <DynamicPageLink
               id={`dynamic-link__blog-post__preview__${blogPost.slug}`}
               underline="none"
               slug={blogPost.slug}
               baseRoute={{ url: baseRoute, noFollow: blogPost.seoMetadata?.noFollow }}
               locale={globals?.locale || 'en-US'}
            >
               {children}
            </DynamicPageLink>
         )}
         renderProducer={(children) => <MockLink underline="none">{children}</MockLink>}
      >
         <div
            onMouseOver={() => setHasHover(true)}
            onMouseOut={() => setHasHover(false)}
            onFocus={() => setHasHover(true)}
            onBlur={() => setHasHover(false)}
         >
            <Box direction="column" gap="sm">
               {image}
               {info}
            </Box>
         </div>
      </ConsumerWrapper>
   );
};
