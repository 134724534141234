import { useMemo, useState } from 'react';

import { DynamicProductBlockQuotationLine, QuotedColor } from '@ravago/shared/page-data/models/elements';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Radio } from '@ravago/shared/radiance/components/radio/components/Radio/Radio';
import { RadioGroup } from '@ravago/shared/radiance/components/radio/components/RadioGroup/RadioGroup';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useShoppingCartContext } from '../../../context/shopping-cart-context';
import { useTranslations } from '../../../providers/translation-client-context-provider';

import { DynamicProductBlockComment } from './dynamic-product-block-comment';
import { DynamicProductBlockQuoteDetails } from './dynamic-product-block-quote-details';

interface Props {
   quotedColor: QuotedColor;
   token: string;
   onCommentClicked: () => void;
}

const getDefaultLine = (quotationLines: DynamicProductBlockQuotationLine[]) => {
   const bestPriceLines = quotationLines.filter((line) => line.bestPrice);
   return bestPriceLines.length > 0 ? bestPriceLines[0] : quotationLines[0];
};

export const DynamicProductBlockQuotedColorFields = ({ quotedColor, token, onCommentClicked }: Props) => {
   const translate = useTranslations();

   const { state, addToCart } = useShoppingCartContext();
   const quotationLines = useMemo(
      () => quotedColor.quotations.flatMap((quotation) => quotation.lines).sort((a, b) => a.quantity.amount - b.quantity.amount),
      [quotedColor]
   );
   const [selectedLine, setSelectedLine] = useState<DynamicProductBlockQuotationLine>(getDefaultLine(quotationLines));

   const bestLineId = useMemo(() => {
      const hasMultipleLines = quotationLines.length > 1;
      if (!hasMultipleLines) return undefined;

      const firstLinePrice = quotationLines[0].unitPrice.price.amount;
      const allSamePrice = quotationLines.every((line) => line.unitPrice.price.amount === firstLinePrice);
      if (allSamePrice) return undefined;

      return quotationLines.find((line) => line.bestPrice)?.id;
   }, [quotationLines]);

   const comments = useMemo(() => {
      const mainComments = quotedColor.quotations.filter((quotation) => !!quotation.description).map((quotation) => quotation.description as string);
      const lineComments = quotationLines.filter((quotationLine) => !!quotationLine.remark).map((quotationLine) => quotationLine.remark as string);

      const allComments = [...mainComments, ...lineComments];

      return {
         mainComments,
         lineComments,
         allComments
      };
   }, [quotedColor, quotationLines]);

   const totalPrice = useMemo(() => {
      if (!selectedLine) return 0;

      const updatedLine = quotationLines.find((line) => line.id === selectedLine.id);
      return (updatedLine?.unitPrice.price.amount ?? 0) * (updatedLine?.quantity.amount ?? 0);
   }, [selectedLine, quotationLines]);

   const showDetails = comments.allComments.length === 0;
   const showGeneralComment = comments.allComments.length > 1 || quotedColor.quotations.length > 1;
   const showMainComment = comments.allComments.length === 1 && comments.mainComments.length === 1 && quotedColor.quotations.length === 1;
   const showLineComment = comments.allComments.length === 1 && comments.lineComments.length === 1;

   const salesRepName =
      quotedColor.quotations.length === 1 && quotedColor.quotations[0].salesRep
         ? `${quotedColor.quotations[0].salesRep.firstName} ${quotedColor.quotations[0].salesRep.lastName}`
         : undefined;

   const handleAddToCartClick = () => {
      if (!selectedLine) return;
      addToCart(selectedLine.id, selectedLine.orderableProduct, selectedLine.quantity, token);
   };

   return (
      <Box items="center" justify="between">
         <Box direction="column" width="100%" gap="sm">
            {showDetails && <DynamicProductBlockQuoteDetails onClick={onCommentClicked} />}
            {showMainComment && <DynamicProductBlockComment comment={comments.mainComments[0]} onClick={onCommentClicked} />}
            {showGeneralComment && (
               <DynamicProductBlockComment
                  comment={translate(
                     salesRepName ? 'dynamic-product-block.sales-rep-message.named' : 'dynamic-product-block.sales-rep-message.unknown',
                     { name: salesRepName }
                  )}
                  onClick={onCommentClicked}
               />
            )}
            <RadioGroup value={selectedLine} onChange={setSelectedLine}>
               {quotationLines.map((line) => (
                  <Box key={line.id} direction="column" gap="xs">
                     <Box direction="row" justify="between" items="center">
                        <Radio value={line}>
                           {line.quantity.amount} {line.quantity.unitOfMeasure}
                        </Radio>
                        <Box gap="xs">
                           {bestLineId === line.id && <Badge variant="success">{translate('dynamic-product-block.best-price')}</Badge>}
                           {!!line.remark && showGeneralComment && <Badge trailingIcon="comment" variant="neutral" />}
                           <Typography variant="body">
                              {Intl.NumberFormat(undefined, {
                                 currency: line.unitPrice.price.currency,
                                 style: 'currency'
                              }).format(line.unitPrice.price.amount)}
                              /{line.unitPrice.unitOfMeasure}
                           </Typography>
                        </Box>
                     </Box>
                     {!!line.remark && showLineComment && <DynamicProductBlockComment comment={line.remark} onClick={onCommentClicked} />}
                  </Box>
               ))}
            </RadioGroup>
            <Divider />
            <Box justify="between">
               <Typography>{translate('dynamic-product-block.total-price')}</Typography>
               <Typography variant="body-highlight">
                  {totalPrice
                     ? Intl.NumberFormat(undefined, {
                          currency: selectedLine?.unitPrice.price.currency,
                          style: 'currency'
                       }).format(totalPrice)
                     : '-'}
               </Typography>
            </Box>
            <Button onClick={handleAddToCartClick} disabled={!!state.type}>
               {translate('dynamic-product-block.add-to-cart')}
            </Button>
         </Box>
      </Box>
   );
};
