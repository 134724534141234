/**
 * DANGER: Renaming any of these keys causes breaking changes in Contentful.
 * If absolutely necessary, you can rename a key but you will also need to provide a migration step.
 */
export enum NodeTypes {
  BADGE = 'Badge',
  BLOG_HEADER = 'Blog header',
  BLOG_OVERVIEW = 'Blog overview',
  BLOG_PREVIEW = 'Blog preview',
  BRAND_HEADER = 'Brand header',
  BRAND_INFORMATION = 'Brand information',
  PRODUCER_LIST = 'Producer list',
  BRAND_LIST = 'Brand list',
  BRAND_PORTFOLIO_LIST = 'Brand portfolio list',
  BREADCRUMBS = 'Breadcrumbs',
  BUTTON = 'Button',
  BUTTON_PRODUCT_PORTFOLIO = 'Button product portfolio',
  CALL_OFF_ORDER_DETAILS = 'Call off order details',
  CASE_STUDY_FEATURES_LIST = 'Case study features list',
  CASE_STUDY_INFORMATION = 'Case study information',
  CASE_STUDY_LIST = 'Case study list',
  CASE_STUDY_WARNING_BADGE = 'Case study warning badge',
  CASE_STUDY_WEBSITE_URL = 'Case study website URL',
  COLUMN = 'Column',
  DETAIL_HEADER = 'Detail header',
  DIVIDER = 'Divider',
  DYNAMIC_DATA_CONTENT = 'Dynamic data content',
  FORM_EMBED = 'Form embed',
  GLOBAL_SECTION = 'Global section',
  HOMEPAGE_SEARCH = 'Homepage search',
  ICON_BUTTON = 'Icon Button',
  IMAGE = 'Image',
  IMAGE_CAROUSEL = 'Image Carousel',
  IMAGE_GALLERY = 'Case study image gallery',
  IMAGE_GRID = 'Image Grid',
  INVOICES = 'Invoices',
  MARKET_ITEM_LIST = 'Market item list view',
  MARKET_KEY_AREAS = 'Market key areas',
  MARKET_LIST = 'Market list',
  MARKET_SEGMENT_LIST = 'Market segment list',
  MARKET_TILE_LIST = 'Market tile list',
  ORDER_DETAILS = 'Order details',
  ORDER_OVERVIEW = 'Order overview',
  PRODUCT_ACTIONS = 'Product actions',
  PRODUCT_CARD = 'Product card',
  PRODUCT_TABLE = 'Product table',
  PRODUCT_FEATURES_LIST = 'Product features list',
  PRODUCT_HEADER = 'Product header',
  PRODUCT_INFORMATION = 'Product information',
  PRODUCT_LIST = 'Product list',
  PRODUCT_LISTER_BLOCK = 'Product lister block',
  PRODUCT_PORTFOLIO = 'Product portfolio',
  PRODUCT_RECOMMENDATION_BADGE = 'Product recommendation badge',
  PRODUCT_BADGES = 'Product badges',
  PRODUCT_SPECIFICATIONS = 'Product specifications',
  PRODUCER_HEADER = 'Producer header',
  QUICK_TDS_LINK = 'Quick TDS link',
  RELATED_DOCUMENTS = 'Related documents',
  REQUEST_MISSING_DOCUMENTS_BUTTON = 'Request missing documents button',
  REQUEST_FOR_QUOTATION = 'Request For Quotation',
  ROW = 'Row',
  SECTION = 'Section',
  SHIPMENT_DETAILS = 'Shipment details',
  TECHNOLOGY_ITEM_LIST = 'Technology item list view',
  TECHNOLOGY_TILE_LIST = 'Technology tile list',
  TEXT = 'Text',
  TEXT_ACCORDION = 'Text accordion',
  TILE = 'Tile',
  VIDEO = 'Video',
  EMAIL_UNSUBSCRIBE = 'Email unsubscribe',
  VAT_NUMBER_LIST = 'VAT number list',
  ADDRESS_LIST = 'Address list',
  REGISTRATION_NUMBER = 'Registration number'
}
