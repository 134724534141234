import get from 'lodash/get';

export const isPlaceholderValid = (template: string, data: object | undefined): boolean => {
   if (!data) return false;

   const match = template.match(/\[%s:(.*)]/);
   if (!match) return false;

   const keys = match[1].trim().split('|');

   for (let i = 0; i < keys.length; i += 1) {
      if (get(data, keys[i]) !== undefined) {
         return true;
      }
   }

   return false;
};
