import { FC } from 'react';

import { BlogPost } from '@ravago/shared/page-data/models/contentful';
import { BlogPreviewNode } from '@ravago/shared/page-data/models/elements';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../providers/translation-client-context-provider';
import { ConsumerWrapper } from '../../content/consumer-wrapper/consumer-wrapper';
import { DynamicPageLink } from '../../content/dynamic-page-link/dynamic-page-link';
import { MockLink } from '../../content/mock-link/mock-link';
import { ContentfulDocument } from '../contentful-document/contentful-document';

interface Props {
   baseRoute?: string;
   size: BlogPreviewNode['size'];
   blogPost: BlogPost;
   globals?: RendererGlobals;
}

export const BlogPreviewInfo: FC<Props> = ({ blogPost, size, globals, baseRoute }) => {
   const translate = useTranslations();

   return (
      <Box direction="column" gap="sm" spacing={{ bottom: size === 'normal' ? 'none' : 'md' }}>
         <Typography variant="small" color="strong">
            {blogPost.category ?? ''}
         </Typography>
         <Typography variant={size === 'normal' ? 'heading-2' : 'heading-4'} color="strong">
            {blogPost.title ?? 'Not Found'}
         </Typography>
         <Typography color="subtle" maxLines={size === 'normal' ? '5' : '3'}>
            <ContentfulDocument document={blogPost.excerpt} color="primary" />
         </Typography>
         {baseRoute && size === 'normal' && (
            <ConsumerWrapper
               isConsumer={globals?.isConsumer !== undefined ? globals.isConsumer : false}
               renderConsumer={(children) => (
                  <DynamicPageLink
                     id={`dynamic-link__blog-post-info__${blogPost.slug}`}
                     underline="none"
                     slug={blogPost.slug}
                     baseRoute={{ url: baseRoute, noFollow: blogPost.seoMetadata?.noFollow }}
                     locale={globals?.locale || 'en-US'}
                  >
                     {children}
                  </DynamicPageLink>
               )}
               renderProducer={(children) => <MockLink underline="none">{children}</MockLink>}
            >
               {size === 'normal' ? (
                  <Button variant="secondary">{translate('read-more')}</Button>
               ) : (
                  <Typography variant="body-highlight" color="inherit">
                     {translate('read-more')}
                  </Typography>
               )}
            </ConsumerWrapper>
         )}
      </Box>
   );
};
