import { ClientConfig } from '@ravago/shared/page-data/models/config';
import { ServerConfig, TeamDesk } from '@ravago/shared/page-data/models/config/server-config.model';

class ConfigService {
   /**
    * Helper function to get all config.
    * This function should only be used on the server side only as it contains secrets that should not be exposed to the client.
    */
   static getServerConfig(): ServerConfig {
      if (typeof window !== 'undefined') throw new Error('You can not use server config on client side!');

      const featureFlags = {
         shouldIndex: process.env.FEATURE_FLAG_SHOULD_INDEX === 'true',
         useLokaliseTranslations: process.env.FEATURE_USE_LOKALISE_TRANSLATIONS === 'true'
      };

      if (!process.env.CMS_ENVIRONMENT) throw new Error('CMS_ENVIRONMENT is not set');
      if (!process.env.CONTENTFUL_SPACE_ID) throw new Error('CONTENTFUL_SPACE_ID is not set');
      if (!process.env.CONTENTFUL_ACCESS_TOKEN) throw new Error('CONTENTFUL_ACCESS_TOKEN is not set');
      if (!process.env.CONTENTFUL_PREVIEW_TOKEN) throw new Error('CONTENTFUL_PREVIEW_TOKEN is not set');
      if (!process.env.CONTENTFUL_PREVIEW) throw new Error('CONTENTFUL_PREVIEW is not set');
      if (!process.env.CONTENTFUL_CMA_TOKEN) throw new Error('CONTENTFUL_CMA_TOKEN is not set');
      if (!process.env.IS_PUBLIC) {
         if (!process.env.BUSINESS_CONTEXT_ID) throw new Error('BUSINESS_CONTEXT_ID is not set');
         if (!process.env.PUBLIC_TOGGLE_UNLEASH_DATA_URL) throw new Error('PUBLIC_TOGGLE_UNLEASH_DATA_URL is not set');
         if (!process.env.PUBLIC_TOGGLE_UNLEASH_DATA_CLIENT_KEY) throw new Error('PUBLIC_TOGGLE_UNLEASH_DATA_CLIENT_KEY is not set');
         if (!process.env.PUBLIC_TOGGLE_UNLEASH_DATA_APP_NAME) throw new Error('PUBLIC_TOGGLE_UNLEASH_DATA_APP_NAME is not set');
         if (!process.env.TOGGLES_BASE_URL) throw new Error('TOGGLES_BASE_URL is not set');
         if (!process.env.API_BASE_URL) throw new Error('API_BASE_URL is not set');
         if (!process.env.API_CONNECT_CLIENT_ID) throw new Error('API_CONNECT_CLIENT_ID is not set');
         if (!process.env.API_CONNECT_CLIENT_SECRET) throw new Error('API_CONNECT_CLIENT_SECRET is not set');
         if (!process.env.API_CONNECT_URL) throw new Error('API_CONNECT_URL is not set');
         if (!process.env.API_CONNECT_PROSPECTOR_TOKEN) throw new Error('API_CONNECT_PROSPECTOR_TOKEN is not set');
         if (!process.env.SEARCH_API_BASE_URL) throw new Error('SEARCH_API_BASE_URL is not set');
      }

      if (featureFlags.useLokaliseTranslations) {
         if (!process.env.LOKALISE_API_KEY) throw new Error('LOKALISE_API_KEY is not set');
         if (!process.env.LOKALISE_PROJECT_ID) throw new Error('LOKALISE_PROJECT_ID is not set');
      }

      return {
         ...ConfigService.getClientConfigToExpose(),
         teamDesk: this.getTeamDeskConfig(),
         featureFlags,
         ulProspectorUrl: process.env.UL_PROSPECTOR_URL,
         businessContextId: process.env.BUSINESS_CONTEXT_ID ?? '',
         publicToggleUnleashData: {
            url: process.env.PUBLIC_TOGGLE_UNLEASH_DATA_URL ?? '',
            clientKey: process.env.PUBLIC_TOGGLE_UNLEASH_DATA_CLIENT_KEY ?? '',
            appName: process.env.PUBLIC_TOGGLE_UNLEASH_DATA_APP_NAME ?? ''
         },
         apiBaseUrl: process.env.API_BASE_URL ?? '',
         apiBaseUrlV2: process.env.API_BASE_URL_V2 ?? '',
         dpUserApiBaseUrl: process.env.DP_USER_API_BASE_URL ?? '',
         preferenceCenterApiBaseUrl: process.env.PREFERENCE_CENTER_API_BASE_URL ?? '',
         apiToken: process.env.API_TOKEN ?? '',
         togglesBaseUrl: process.env.TOGGLES_BASE_URL ?? '',
         searchApiBaseUrl: process.env.SEARCH_API_BASE_URL ?? '',
         chemicalSearchApiBaseUrl: process.env.CHEMICAL_SEARCH_API_BASE_URL ?? '',
         apiConnect: {
            clientId: process.env.API_CONNECT_CLIENT_ID ?? '',
            clientSecret: process.env.API_CONNECT_CLIENT_SECRET ?? '',
            apiConnectUrl: process.env.API_CONNECT_URL ?? '',
            ulProspectorApiToken: process.env.API_CONNECT_PROSPECTOR_TOKEN ?? ''
         },
         contentful: {
            webhookToken: process.env.CONTENTFUL_WEBHOOK_TOKEN,
            cmaToken: process.env.CONTENTFUL_CMA_TOKEN,
            spaceId: process.env.CONTENTFUL_SPACE_ID,
            accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
            previewToken: process.env.CONTENTFUL_PREVIEW_TOKEN,
            preview: process.env.CONTENTFUL_PREVIEW === 'true'
         },
         cmsEnvironment: process.env.CMS_ENVIRONMENT,
         lokalise: {
            apiKey: process.env.LOKALISE_API_KEY ?? '',
            projectId: process.env.LOKALISE_PROJECT_ID ?? ''
         }
      };
   }

   static getClientConfigToExpose(): ClientConfig {
      if (typeof window !== 'undefined') throw new Error('You can not use server config on client side!');

      if (!process.env.DEFAULT_LOCALE) throw new Error('DEFAULT_LOCALE is not set');
      if (!process.env.DEFAULT_THEME) throw new Error('DEFAULT_THEME is not set');
      if (!process.env.HOST_NAME) throw new Error('HOST_NAME is not set');
      if (!process.env.IS_PRODUCTION) throw new Error('IS_PRODUCTION is not set');

      if (!process.env.IS_PUBLIC) {
         if (!process.env.GOOGLE_RECAPTCHA_SITE_KEY) throw new Error('GOOGLE_RECAPTCHA_SITE_KEY is not set');
         if (!process.env.API_BASE_URL_V2) throw new Error('API_BASE_URL_V2 is not set');
         if (!process.env.TECHNICAL_SUPPORT_BASE_URL) throw new Error('TECHNICAL_SUPPORT_BASE_URL is not set');
      }

      return {
         adminPortalUrl: process.env.ADMIN_PORTAL_URL,
         academySSO: process.env.ACADEMY_SSO,
         defaultLocale: process.env.DEFAULT_LOCALE,
         defaultTheme: process.env.DEFAULT_THEME,
         linkLegacyDigitalPlatform: process.env.LINK_LEGACY_DIGITAL_PLATFORM === 'true',
         useLegacyDigitalPlatformRoutes: process.env.USE_LEGACY_DIGITAL_PLATFORM_ROUTES === 'true',
         hostName: process.env.HOST_NAME,
         googleReCaptcha: {
            siteKey: process.env.GOOGLE_RECAPTCHA_SITE_KEY ?? ''
         },
         useNextAuth: process.env.USE_NEXT_AUTH === 'true',
         okta:
            process.env.OKTA_CLIENT_ID && process.env.OKTA_REDIRECT_URI && process.env.OKTA_ISSUER
               ? {
                    clientId: process.env.OKTA_CLIENT_ID,
                    redirectUri: process.env.OKTA_REDIRECT_URI,
                    issuer: process.env.OKTA_ISSUER
                 }
               : undefined,
         technicalSupport: {
            baseUrl: process.env.TECHNICAL_SUPPORT_BASE_URL ?? ''
         },
         isProduction: process.env.IS_PRODUCTION === 'true',
         isPublic: process.env.IS_PUBLIC === 'true'
      };
   }

   static getTeamDeskConfig(): TeamDesk {
      return !process.env.IS_PRODUCTION
         ? {
              application: {
                 id: 'fid_26086299',
                 value: process.env.TEAMDESK_APPLICATION
              },
              brand: 'fid_26086313',
              caseStudy: 'fid_26086322',
              missingValueCheck: 'fid_26086315',
              missingValue: 'fid_26086316',
              contentType: {
                 id: 'fid_26086290',
                 value: [
                    {
                       id: 124,
                       value: 'Product'
                    },
                    {
                       id: 125,
                       value: 'Case Study'
                    },
                    {
                       id: 126,
                       value: 'Brand'
                    },
                    {
                       id: 127,
                       value: 'Polymer Group'
                    },
                    {
                       id: 128,
                       value: 'Industry'
                    },
                    {
                       id: 129,
                       value: 'Process Method'
                    }
                 ]
              },
              context: {
                 id: 'fid_27007718',
                 value: process.env.TEAMDESK_BUSINESS_CONTEXT
              },
              database: 79298,
              email: 'fid_26086294',
              feedbackType: {
                 id: 'fid_26086289'
              },
              industry: 'fid_26086348',
              polymerGroup: 'fid_26086320',
              processMethod: 'fid_26086350',
              product: 'fid_27045847',
              table: 829316
           }
         : {
              application: {
                 id: 'fid_28329193',
                 value: process.env.TEAMDESK_APPLICATION
              },
              brand: 'fid_28329203',
              missingValueCheck: 'fid_28329205',
              missingValue: 'fid_28329206',
              caseStudy: 'fid_28329210',
              contentType: {
                 id: 'fid_28329184',
                 value: [
                    {
                       id: 124,
                       value: 'Product'
                    },
                    {
                       id: 125,
                       value: 'Case Study'
                    },
                    {
                       id: 126,
                       value: 'Brand'
                    },
                    {
                       id: 127,
                       value: 'Polymer Group'
                    },
                    {
                       id: 128,
                       value: 'Industry'
                    },
                    {
                       id: 129,
                       value: 'Process Method'
                    }
                 ]
              },
              context: {
                 id: 'fid_28329181',
                 value: process.env.TEAMDESK_BUSINESS_CONTEXT
              },
              database: 81396,
              email: 'fid_28329188',
              feedbackType: {
                 id: 'fid_28329183'
              },
              industry: 'fid_28329223',
              polymerGroup: 'fid_28329208',
              processMethod: 'fid_28329225',
              product: 'fid_28329255',
              table: 878126
           };
   }
}

export { ConfigService };
