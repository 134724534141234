import { useEffect } from 'react';

import { useAuthenticationContext } from '@ravago/shared/authentication';
import { FeatureToggle } from '@ravago/shared/page-data/models/config';

import { useRouter } from 'next/navigation';

import { useInvoiceDispatchContext } from '../context/invoice-context';
import { InvoiceAction } from '../state/invoice.actions';

export const useFetchPrivateToggles = (getPrivateToggles: (accessToken: string) => Promise<FeatureToggle[]>) => {
   const dispatch = useInvoiceDispatchContext();
   const router = useRouter();
   const { token } = useAuthenticationContext();

   useEffect(() => {
      if (!token) {
         return;
      }

      const fetchPrivateToggles = async (accessToken: string) => {
         try {
            const privateTogglesResponse = await getPrivateToggles(accessToken);

            dispatch({ type: InvoiceAction.SET_PERMISSIONS, payload: privateTogglesResponse });
         } catch {
            router.push('/');
         }
      };

      fetchPrivateToggles(token);
   }, [token, getPrivateToggles, router, dispatch]);
};
