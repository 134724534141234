import {
   BrandList,
   ContentfulBrand,
   ContentfulBrandEntry,
   ContentfulCollectionName,
   ContentfulProduct
} from '@ravago/shared/page-data/models/contentful';
import { OverviewPageBrandItem } from '@ravago/shared/page-data/models/elastic';
import { BrandCard } from '@ravago/shared/page-data/models/elements';
import { BaseQuery } from '@ravago/shared/page-data/models/utils';

import { ContentfulClientApi, Entry } from 'contentful';
import uniq from 'lodash/uniq';

import { AssetUtils } from '../asset/asset.utils';
import { cache } from '../cache/cache.utils';
import { ProductUtils } from '../product/product.utils';
import { QueryBuilder } from '../query-builder/query-builder';

export class BrandUtils {
   static getBrands = cache(async (contentfulClientApi: ContentfulClientApi, language: string, query: BaseQuery): Promise<BrandList> => {
      const { limit, producerIds, brandIds } = query;

      const contentType: ContentfulCollectionName = 'brand';

      const queryOptions = new QueryBuilder()
         .contentType(contentType)
         .limit(limit ?? 4)
         .include(3)
         .locale(language)
         .setArrayOptional('fields.producer.sys.id[in]', producerIds);

      if (query.brandIds?.length) {
         queryOptions.setArrayOptional('sys.id[in]', brandIds);
      } else {
         const brandIdsFromLinkedProducts = await BrandUtils.getBrandIdsFromProduct(contentfulClientApi, language, query);
         queryOptions.setArrayOptional('sys.id[in]', brandIdsFromLinkedProducts);
      }

      const brands = await contentfulClientApi.getEntries<ContentfulBrandEntry>(queryOptions.build());
      const normalizedBrands = brands.items.map(this.normalizeEntry);

      return { brands: normalizedBrands, total: brands.total };
   });

   static getBrandIdsFromProduct = cache(async (contentfulClientApi: ContentfulClientApi, language: string, query: BaseQuery): Promise<string[]> => {
      const products = await ProductUtils.getProductCollection(contentfulClientApi, language, {
         limit: 1000,
         include: 1,
         orderBy: 'sys.createdAt',
         filterBy: 'all',
         industryIds: query.industryIds,
         processIds: query.processIds,
         producerIds: query.producerIds
      });

      const brandIds = products.items.map((product) => product.brand.sys.id);
      return uniq(brandIds);
   });

   static getNamesOfEntries = cache(
      async (contentfulClientApi: ContentfulClientApi, contentType: ContentfulCollectionName, ids: string[], locale: string): Promise<string[]> => {
         const entries = await contentfulClientApi.getEntries<{ name: string }>({
            locale,
            content_type: contentType,
            'fields.sys.id[in]': ids,
            select: 'fields.name'
         });

         return entries.items.map((entry) => entry.fields.name);
      }
   );

   static transformToBrandCard(
      toTransform: ContentfulBrand | OverviewPageBrandItem,
      products?: Array<ContentfulProduct>,
      acceptWebp?: boolean
   ): BrandCard {
      const brandCard: BrandCard = {
         id: '',
         slug: toTransform.slug,
         name: toTransform.name,
         excerpt: toTransform.excerpt,
         polymerTypes: [],
         numberOfProducts: 0,
         producer: {
            name: '',
            logo: ''
         }
      };

      if ('qualifier' in toTransform) {
         brandCard.id = toTransform.slug;
         brandCard.producer = {
            name: toTransform.producer?.name ?? '',
            logo: toTransform.producer?.logoUrl ? AssetUtils.optimizeImageIfRequired(toTransform.producer?.logoUrl, acceptWebp) : ''
         };
         brandCard.polymerTypes = toTransform.polymerTypes ?? [];
         brandCard.numberOfProducts = toTransform.numberOfItems ?? 0;
      } else {
         brandCard.id = toTransform.sys.id;
         brandCard.producer = {
            name: toTransform.producer.name,
            logo: AssetUtils.optimizeImageIfRequired(toTransform.producer.logo.url, acceptWebp)
         };
         brandCard.polymerTypes =
            products
               ?.filter((product) => product.polymerSubGroup)
               ?.map((product) => product.polymerSubGroup ?? '')
               .filter((polymerType, index, self) => polymerType && self.indexOf(polymerType) === index) ?? [];
         brandCard.numberOfProducts = products?.length ?? 0;
         brandCard.noFollow = toTransform.seoMetadata?.noFollow;
      }

      return brandCard;
   }

   private static normalizeEntry(entry: Entry<ContentfulBrandEntry>): ContentfulBrand {
      return {
         sys: { id: entry.sys.id },
         name: entry.fields.name ?? '',
         slug: entry.fields.slug ?? '',
         excerpt: entry.fields.excerpt ?? '',
         producer: {
            sys: { id: entry.fields?.producer?.sys.id ?? '' },
            seoMetadata: { noFollow: entry.fields?.producer?.fields?.seoMetadata?.fields?.noFollow },
            slug: entry.fields.producer?.fields?.slug ?? '',
            name: entry.fields?.producer?.fields?.name ?? '',
            logo: { url: entry.fields?.producer?.fields?.logo?.fields?.file?.url ?? '' },
            storefront: {
               route: entry.fields?.producer?.fields?.storefront?.fields?.route ?? '',
               seoMetadata: { noFollow: entry.fields?.producer?.fields?.storefront?.fields?.seoMetadata?.fields?.noFollow }
            }
         },
         countries:
            entry.fields.countries?.map((country) => ({
               name: country.fields?.name ?? '',
               isoCode: country.fields?.isoCode ?? ''
            })) ?? [],
         seoMetadata: { noFollow: entry.fields.seoMetadata?.fields?.noFollow }
      };
   }
}
