'use client';

import { FC, useEffect, useState } from 'react';

import { Tab, TabProps } from '../Tab/Tab';

interface Props {
   tabs: Array<TabProps>;
   activeTab?: number;
   showContainer?: boolean;
   stretch?: boolean;
   height?: string;
   visualizeChanges?: boolean;
   onTabChange?: (index: number) => void;
   onTabClick?: (index: number) => void;
}

export const Tabs: FC<Props> = ({ tabs, activeTab = 0, showContainer = true, stretch = false, height, visualizeChanges = true, onTabChange, onTabClick }) => {
   const [currentTab, setCurrentTab] = useState(activeTab);

   const handleTabClick = (index: number) => {
      if (index !== currentTab && visualizeChanges) {
         setCurrentTab(index);
         onTabChange?.(index);
      }

      onTabClick?.(index);
   };

   useEffect(() => setCurrentTab(activeTab), [activeTab]);

   return (
      <div data-testid={`tabs${stretch ? '--stretched' : ''}`} className="scroll relative flex overflow-x-auto overflow-y-hidden" style={{ height }}>
         {tabs.map((tab, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tab key={`${tab.href}-${index}`} width={stretch ? '100%' : undefined} id={tab.id} label={tab.label} leadingIcon={tab.leadingIcon} trailingIcon={tab.trailingIcon} href={tab.href} target={tab.target} active={currentTab === index} onClick={() => handleTabClick(index)} />
         ))}
         {showContainer && <div data-testid="tabs-container" className="border-component-navigation-tabs-container-color-border border-b-component-navigation-tabs-container-border-width absolute inset-0 box-border" />}
      </div>
   );
};
