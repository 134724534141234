import { FC, PropsWithChildren, ReactNode } from 'react';

interface Props extends PropsWithChildren {
   isConsumer: boolean;
   renderConsumer: (children?: ReactNode) => ReactNode;
   renderProducer: (children?: ReactNode) => ReactNode;
}

export const ConsumerWrapper: FC<Props> = ({ isConsumer, renderConsumer, renderProducer, children }) =>
   isConsumer ? renderConsumer(children) : renderProducer(children);
