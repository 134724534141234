'use client';

import { FC, useEffect, useReducer, useState } from 'react';

import { useAuthenticationContext } from '@ravago/shared/authentication';
import { SystemPageType } from '@ravago/shared/page-data/enums/system-page-type';
import { FeatureToggle } from '@ravago/shared/page-data/models/config';
import { MappedOrderHeader, OrderDocumentResponse } from '@ravago/shared/page-data/models/elements';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { BusinessContextUtils } from '../../../../utils/business-context/business-context.utils';
import { FeaturePermissionsUtils } from '../../../../utils/feature-permissions/feature-permissions.utils';
import { OrderMapperUtils } from '../../../../utils/order/order-mapper.utils';
import OrderNotFoundIllustration from '../../../illustrations/order-not-found-illustration';
import { EmptyState } from '../../../shared/empty-state/empty-state.component';
import { OrderDocuments } from '../../../shared/order-documents/order-documents';
import { OrderPageHeader } from '../../../shared/order-page-header/order-page-header';
import { DeliveryInformation } from '../../../shared/order/delivery-information';
import { InvoiceInformation } from '../../../shared/order/invoice-information';
import { OrderDetailsOrderLines } from '../../../shared/order/order-details-order-lines';
import { OrderShipments } from '../../../shared/order/order-shipments';
import { SystemPageLink } from '../../system-page-link/system-page-link';

export type Props = {
   globals: RendererGlobals;
   shipmentBaseUrl: string;
   callOffAgreementBaseUrl: string;
   orderOverviewUrl: string;
};

interface State {
   order?: MappedOrderHeader;
   documents?: OrderDocumentResponse;
   showNotFound: boolean;
   isLoading: boolean;
   permissions: FeatureToggle[];
   showVatCode: boolean;
}

type Action =
   | { type: 'SET_ORDER'; payload: MappedOrderHeader }
   | { type: 'SET_ORDER_DOCUMENTS'; payload: OrderDocumentResponse | undefined }
   | { type: 'SHOW_NOT_FOUND' }
   | { type: 'SET_PERMISSIONS'; payload: FeatureToggle[] };

const initialState: State = {
   showNotFound: false,
   isLoading: true,
   order: undefined,
   documents: undefined,
   permissions: [],
   showVatCode: false
};

function reducer(state: State, action: Action): State {
   switch (action.type) {
      case 'SET_ORDER':
         return { ...state, order: action.payload, isLoading: false };
      case 'SET_ORDER_DOCUMENTS':
         return { ...state, documents: action.payload, isLoading: false };
      case 'SHOW_NOT_FOUND':
         return { ...state, showNotFound: true, isLoading: false };
      case 'SET_PERMISSIONS':
         return { ...state, permissions: action.payload, showVatCode: FeaturePermissionsUtils.hasPermission(action.payload, 'vat-info') };
      default:
         return state;
   }
}

export const OrderDetails: FC<Props> = ({
   globals: {
      locale,
      acceptWebp,
      isConsumer,
      businessContext,
      routeParams,
      actions: {
         getOrderHeader,
         getOrderProductsByIds,
         getPrivateToggles,
         getOrderDetailsDocuments,
         requestBol,
         requestCoa,
         requestDeliveryNote,
         downloadDocument
      }
   },
   shipmentBaseUrl,
   callOffAgreementBaseUrl,
   orderOverviewUrl
}) => {
   const translate = useTranslations();
   const [{ isLoading, showNotFound, order, showVatCode, documents }, dispatch] = useReducer(reducer, initialState);
   const { token } = useAuthenticationContext();
   const [hasDocuments, setHasDocuments] = useState(false);

   const checkDocuments = (orderDocuments: OrderDocumentResponse) => {
      const { deliveryNotes, billsOfLading, invoices, coa } = orderDocuments;
      return (
         (invoices && invoices.length > 0) ||
         (coa && coa.length > 0) ||
         (deliveryNotes && deliveryNotes.length > 0) ||
         (billsOfLading && billsOfLading.length > 0)
      );
   };

   useEffect(() => {
      const fetchOrderDetails = async (accessToken: string) => {
         try {
            if (routeParams?.orderId) {
               const [orderDetails, orderDetailsDocuments, permissions] = await Promise.all([
                  getOrderHeader(accessToken, Number(routeParams.orderId)),
                  getOrderDetailsDocuments(accessToken, Number(routeParams.orderId)),
                  getPrivateToggles(accessToken)
               ]);

               if (orderDetails) {
                  dispatch({ type: 'SET_PERMISSIONS', payload: permissions });
                  setHasDocuments(orderDetailsDocuments ? checkDocuments(orderDetailsDocuments) : false);

                  const uniqueProductIds = [
                     ...new Set(orderDetails.orderLines.map((orderLine) => `product-${orderLine.product.pkbId}`).filter(Boolean))
                  ];
                  const contentfulProducts = await getOrderProductsByIds(locale, uniqueProductIds);
                  if (contentfulProducts) {
                     orderDetails.orderLines = orderDetails.orderLines.map((orderLine) => {
                        const productDetail = contentfulProducts.find((product) => product.id === `product-${orderLine.product.pkbId}`);
                        return { ...orderLine, contentfulProduct: productDetail };
                     });
                  }
                  const orderHeader = OrderMapperUtils.getMappedOrderHeader(orderDetails);
                  dispatch({ type: 'SET_ORDER', payload: orderHeader });
                  dispatch({ type: 'SET_ORDER_DOCUMENTS', payload: orderDetailsDocuments });
                  return;
               }
            }
            dispatch({ type: 'SHOW_NOT_FOUND' });
         } catch (error) {
            dispatch({ type: 'SHOW_NOT_FOUND' });
         }
      };
      if (token) {
         fetchOrderDetails(token);
      }
   }, [token]);

   if (isLoading) {
      return (
         <Box direction="column" items="center">
            <Spinner />
         </Box>
      );
   }

   if (showNotFound) {
      return (
         <Box direction="column">
            <OrderPageHeader headerText={translate('my-orders.order-header')} id={routeParams?.orderId || ''} status={undefined} date={undefined} />
            <EmptyState
               label={translate('my-orders.no-orders-found')}
               image={<OrderNotFoundIllustration />}
               button={
                  <Box items="center">
                     <SystemPageLink baseRoute={orderOverviewUrl} locale={locale} type={SystemPageType.ORDER_OVERVIEW}>
                        <Button variant="primary" leadingIcon="arrow-left">
                           {translate('shipment.return-to-orders')}
                        </Button>
                     </SystemPageLink>
                  </Box>
               }
               title={translate('shipment.not-found-title')}
            />
            <Divider color="default" orientation="horizontal" />
         </Box>
      );
   }

   if (order) {
      return (
         <Box direction="column">
            <OrderPageHeader headerText={translate('my-orders.order-header')} id={order.id.toString()} status={order.status} date={undefined} />
            <OrderShipments
               isConsumer={isConsumer}
               acceptWebp={acceptWebp}
               locale={locale}
               orderDetail={order.mappedOrders}
               shipmentBaseUrl={shipmentBaseUrl}
               callOffAgreementBaseUrl={callOffAgreementBaseUrl}
               openedFrom="order_detail"
            />
            <OrderDetailsOrderLines
               isConsumer={isConsumer}
               acceptWebp={acceptWebp}
               locale={locale}
               orderDetail={order.mappedOrders}
               shipmentBaseUrl={shipmentBaseUrl}
               callOffAgreementBaseUrl={callOffAgreementBaseUrl}
               openedFrom="order_detail"
            />
            {order.invoiceInfo && (
               <>
                  <Divider />
                  <InvoiceInformation vatCode={order.invoiceInfo.vatCode} invoiceEntity={order.invoiceInfo.ole} showVatCode={showVatCode} />
               </>
            )}
            {order.deliveryInfo && (
               <>
                  <Divider />
                  <DeliveryInformation
                     destinationAddress={order.deliveryInfo.destinationAddress}
                     deliveryTermAddress={order.deliveryInfo.deliveryTermAddress}
                     deliveryInstructions={order.deliveryInfo.deliveryInstructions}
                     deliveryTerm={order.deliveryInfo.deliveryTerm}
                  />
               </>
            )}
            {hasDocuments && (
               <>
                  <Divider />
                  <OrderDocuments
                     isUSBusinessContext={BusinessContextUtils.isUSBusinessContext(businessContext)}
                     documents={documents}
                     orders={order}
                     requestCoa={requestCoa}
                     requestBol={requestBol}
                     requestDeliveryNote={requestDeliveryNote}
                     downloadDocument={downloadDocument}
                  />
               </>
            )}
         </Box>
      );
   }

   return (
      <Box direction="column" items="center">
         <Spinner />
      </Box>
   );
};
