'use client';

import { FC } from 'react';

import { ContentfulMarket, ContentfulTechnology } from '@ravago/shared/page-data/models/contentful';
import { DetailHeaderNode } from '@ravago/shared/page-data/models/elements';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Banner } from '@ravago/shared/radiance/components/banner/components/Banner/Banner';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import { AssetUtils } from '../../../../utils/asset/asset.utils';
import { ContentfulDocument } from '../../../shared/contentful-document/contentful-document';

import { ReadMoreContainer } from './read-more-container';

interface Props {
   node: DetailHeaderNode;
   globals: RendererGlobals;
   item?: ContentfulMarket | ContentfulTechnology | null;
}

/* c8 ignore start */
export const DetailHeader: FC<Props> = ({ node, globals, item }) => {
   const { acceptWebp } = globals;

   const { desktop } = useBreakpoint();

   const useDescriptionAsFallback = node.useDescriptionAsFallback === true;

   let description = item?.shortDescription;
   let expandable = false;
   if (!description && useDescriptionAsFallback) {
      description = item?.description;
      expandable = true;
   }

   return (
      <Banner image={item?.image?.url ? AssetUtils.optimizeImageIfRequired(item.image.url, acceptWebp, 1440) : ''} showOverlay>
         <Box direction="column" items="start" spacing={{ top: desktop ? 'lg' : 'xl', bottom: desktop ? 'lg' : 'xl' }}>
            <Typography color="inverse-strong" variant="heading-1" component="h1">
               {item?.name}
            </Typography>
            {description && (
               <ReadMoreContainer expandable={expandable}>
                  <ContentfulDocument document={description} color="inverse" />
               </ReadMoreContainer>
            )}
         </Box>
      </Banner>
   );
};
/* c8 ignore end */
