import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { InputGroup } from '@ravago/shared/radiance/components/input-field/components/InputGroup/InputGroup';

import { useTranslations } from '../../../../../../../providers/translation-client-context-provider';
import { useInvoiceDispatchContext } from '../../../../context/invoice-context';
import { InvoiceAction } from '../../../../state/invoice.actions';

export const ClosedInvoiceFilters = () => {
   const translate = useTranslations();
   const dispatch = useInvoiceDispatchContext();

   const handleInvoiceSearch = (inputValue: string) => {
      dispatch({ type: InvoiceAction.SET_CLOSED_INVOICES_QUERY_PARAMS, payload: { page: 1, identifier: inputValue } });
   };

   const handleClearInvoiceSearch = () => {
      dispatch({ type: InvoiceAction.SET_CLOSED_INVOICES_QUERY_PARAMS, payload: { page: 1, identifier: '' } });
   };

   return (
      <Box direction="column" width="100%">
         <InputGroup
            actionIcon="search"
            autocomplete="on"
            clearable
            id="closed-invoices-search"
            label={translate('invoices.filters.search-invoice')}
            onActionClick={handleInvoiceSearch}
            onEnter={handleInvoiceSearch}
            onClear={handleClearInvoiceSearch}
            maxLength={255}
         />
      </Box>
   );
};
