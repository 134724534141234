import { FC, Fragment, useMemo, useState } from 'react';

import { FacetFilterWithTerms, FacetFilterTerm, FacetFilterTracking } from '@ravago/shared/page-data/models/elastic';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { List } from '@ravago/shared/radiance/components/list/components/List/List';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { firstBy } from 'thenby';

import { useTranslations } from '../../../../../../providers/translation-client-context-provider';
import { FacetGroup, FacetGroupContent } from '../../../models/facet-group.model';

import { FilterGroupItem } from './filter-group-item';
import { RangeChangeEvent } from './filter-item';

const FACET_SORT_ORDER = (facetFilter: FacetFilterWithTerms | undefined) =>
   firstBy((facetGroupContent: FacetGroupContent) => facetGroupContent.quantity > 0, 'desc')
      .thenBy((facetGroupContent: FacetGroupContent) => {
         if (!facetFilter) return false;
         return facetFilter.terms.some((t) => t.term === facetGroupContent.name);
      }, 'desc')
      .thenBy('label', 'asc');

const doesFacetContainSearchValue = (searchValue: string, facetContent: FacetGroupContent): boolean =>
   facetContent.label.toLowerCase().includes(searchValue.toLowerCase()) ||
   (facetContent.nestedFacets?.some((nestedFacet) =>
      nestedFacet.facets.some((nestedFacetContent) => doesFacetContainSearchValue(searchValue, nestedFacetContent))
   ) ??
      false);

interface Props {
   language: string;
   searchValue: string;
   isChild?: boolean;
   isParentActive?: boolean;
   facetFilter?: FacetFilterWithTerms;
   facetGroup: FacetGroup;
   onFilterChange: (value: FacetFilterWithTerms) => void;
   onFilterRemove: () => void;
   onFilterClicked: (facetFilter: FacetFilterTracking) => void;
}

export const BaseFilterGroup: FC<Props> = ({
   language,
   searchValue,
   isChild,
   isParentActive,
   facetFilter,
   facetGroup,
   onFilterChange,
   onFilterRemove,
   onFilterClicked
}) => {
   const translate = useTranslations();
   const canExpand = !isChild && searchValue === '' && facetGroup.facets.length > 10;
   const [expanded, setExpanded] = useState(false);

   const visibleFacets = useMemo(() => {
      const filteredFacets = facetGroup.facets
         .filter((facetContent) => doesFacetContainSearchValue(searchValue, facetContent))
         .sort(FACET_SORT_ORDER(facetFilter));
      if (canExpand && !expanded) {
         return filteredFacets.slice(0, 5);
      }
      return filteredFacets;
   }, [expanded, canExpand, searchValue, facetGroup, facetFilter]);

   const onCheckedChange = (
      term: string,
      checked: boolean,
      range?: {
         min: number;
         max: number;
      }
   ) => {
      if (isParentActive) {
         const allTerms: Array<FacetFilterTerm> = facetGroup.facets.filter((f) => f.name !== term).map((f) => ({ term: f.name }));
         onFilterChange({ facet: facetGroup.identifier.key, terms: allTerms });
      }

      if (checked) {
         onFilterClicked({ facet: facetGroup.identifier.key, term, range });
         const newValue: FacetFilterTerm = { term };
         if (range) {
            newValue.range = range;
         }

         if (!facetFilter) {
            onFilterChange({ facet: facetGroup.identifier.key, terms: [newValue] });
         } else {
            const e = {
               ...facetFilter,
               terms: [...facetFilter.terms.filter((t) => t.term !== term), newValue]
            };
            onFilterChange(e);
         }
      } else {
         if (!facetFilter) return;
         const e = { ...facetFilter };
         e.terms = e.terms.filter((filterTerm) => filterTerm.term !== term);

         if (e.terms.length === 0) {
            onFilterRemove();
         } else {
            onFilterChange(e);
         }
      }
   };

   const onRangeChange = (term: string, event: RangeChangeEvent) => {
      if (!facetFilter) return;

      const e = { ...facetFilter };
      const found = e.terms.find((filterTerm) => filterTerm.term === term);

      if (!found) return;
      onFilterClicked({ facet: facetGroup.identifier.key, term, range: event });

      found.range = {
         min: event.min,
         max: event.max
      };

      onFilterChange(e);
   };

   const onChildFilterChange = (term: string, value: FacetFilterWithTerms) => {
      if (!facetFilter) {
         const e: FacetFilterWithTerms = { facet: facetGroup.identifier.key, terms: [{ term, nestedFacets: [value] }] };
         onFilterChange(e);
      } else {
         const e = { ...facetFilter };
         const facetFilterTerm = e.terms.find((t) => t.term === term);
         if (!facetFilterTerm) {
            e.terms.push({ term, nestedFacets: [value] });
         } else {
            facetFilterTerm.nestedFacets = [value];
         }
         onFilterChange(e);
      }
   };

   const onChildFilterRemove = (term: string) => {
      if (!facetFilter) return;
      const e = { ...facetFilter };
      e.terms = e.terms.filter((t) => t.term !== term);

      if (e.terms.length > 0) {
         onFilterChange(e);
      } else {
         onFilterRemove();
      }
   };

   const expandButton = () => (
      <div role="presentation" style={{ cursor: 'pointer' }} onClick={() => setExpanded((curr) => !curr)}>
         <Box items="center" spacing={{ top: 'sm', bottom: 'sm', left: 'sm' }}>
            <Icon color="primary" icon={expanded ? 'chevron-up' : 'chevron-down'} />
            <Typography variant="body" color="primary">
               {expanded ? translate('products-overview-filters-show-less') : translate('products-overview-filters-show-more')}
            </Typography>
         </Box>
      </div>
   );

   return (
      <>
         <List>
            {visibleFacets.map((facet, index) => {
               const term = facetFilter?.terms.find((t) => t.term === facet.name);

               return (
                  <Fragment key={facet.name}>
                     <FilterGroupItem
                        searchValue={searchValue}
                        facet={facet}
                        term={term}
                        isParentActive={isParentActive}
                        onCheckedChange={(checked) => onCheckedChange(facet.name, checked, facet.range)}
                        onRangeChange={(event) => onRangeChange(facet.name, event)}
                        renderChild={(parentActive) =>
                           facet.nestedFacets?.map((nestedFacet) => {
                              const nestedFacetFilter = term?.nestedFacets?.find((n) => n.facet === nestedFacet.identifier.key);

                              return (
                                 <Box margin={{ left: 'md' }} key={`${nestedFacet.identifier.key}-${facet.name}`}>
                                    <BaseFilterGroup
                                       language={language}
                                       searchValue={searchValue}
                                       isChild
                                       isParentActive={parentActive}
                                       facetFilter={nestedFacetFilter}
                                       facetGroup={nestedFacet}
                                       onFilterChange={(e) => onChildFilterChange(facet.name, e)}
                                       onFilterRemove={() => onChildFilterRemove(facet.name)}
                                       onFilterClicked={onFilterClicked}
                                    />
                                 </Box>
                              );
                           })
                        }
                     />

                     {index === 4 && canExpand && expanded && expandButton()}
                  </Fragment>
               );
            })}
         </List>

         {canExpand && expandButton()}
      </>
   );
};
