import { FC, useState } from 'react';

import { SystemPageType } from '@ravago/shared/page-data/enums/system-page-type';
import { DisplayOrder, OrderLineOpenedFrom, ShipmentWithOrderLines } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Chip } from '@ravago/shared/radiance/components/chip/components/Chip/Chip';
import { List } from '@ravago/shared/radiance/components/list/components/List/List';
import { ListItem } from '@ravago/shared/radiance/components/list/components/ListItem/ListItem';
import { Menu } from '@ravago/shared/radiance/components/menu/components/Menu/Menu';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableHead } from '@ravago/shared/radiance/components/table/components/TableHead/TableHead';
import { TableHeader } from '@ravago/shared/radiance/components/table/components/TableHeader/TableHeader';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useOrderLineContext } from '../../../context/order-line-context';
import { useFlyoutContext } from '../../../hooks/use-flyout-context';
import { useTranslations } from '../../../providers/translation-client-context-provider';
import { DateUtils } from '../../../utils/date/date.utils';
import { getLanguageCode } from '../../../utils/locale/locale.utils';
import { SystemPageLink } from '../../content/system-page-link/system-page-link';
import { Link } from '../link/link';
import { OrderLineItem } from '../order/order-line-item';

interface ShipmentTableProps {
   shipments: ShipmentWithOrderLines[];
   isConsumer: boolean;
   locale: string;
   acceptWebp?: boolean;
   shipmentBaseUrl: string;
   orderDetailBaseUrl?: string;
   callOffAgreementBaseUrl?: string;
   openedFrom: OrderLineOpenedFrom;
}

const OrderLineMenu: FC<{
   orderLine: DisplayOrder;
   shipmentId: number;
   locale: string;
   language: string;
   shipmentBaseUrl: string;
   orderDetailBaseUrl?: string;
   callOffAgreementBaseUrl?: string;
   onOrderLineButtonClick: (orderLine: DisplayOrder, shipmentId: number) => void;
}> = ({ orderLine, shipmentId, locale, language, shipmentBaseUrl, orderDetailBaseUrl, callOffAgreementBaseUrl, onOrderLineButtonClick }) => {
   const translate = useTranslations();
   const [menuOpen, setMenuOpen] = useState(false);

   const handleOrderLineButtonClick = () => {
      setMenuOpen(false);
      onOrderLineButtonClick(orderLine, shipmentId);
   };

   return (
      <Menu
         open={menuOpen}
         align="right"
         closeOnOutsideClick
         onMenuClose={() => setMenuOpen(false)}
         content={
            <List>
               <ListItem onClick={handleOrderLineButtonClick}>{translate('shipment.table.show-more')}</ListItem>
               {orderDetailBaseUrl && (
                  <SystemPageLink
                     baseRoute={orderDetailBaseUrl}
                     locale={locale}
                     type={SystemPageType.ORDER_DETAIL}
                     slug={orderLine.orderNumber.slice(0, orderLine.orderNumber.indexOf('-'))}
                  >
                     <ListItem>{translate('shipment.table.order-details')}</ListItem>
                  </SystemPageLink>
               )}
               {callOffAgreementBaseUrl && orderLine.agreementNumber !== undefined && (
                  <SystemPageLink
                     baseRoute={callOffAgreementBaseUrl}
                     locale={locale}
                     type={SystemPageType.CALL_OFF_ORDER_DETAIL}
                     slug={orderLine.agreementNumber}
                  >
                     <ListItem>{translate('shipment.table.call-off-agreement')}</ListItem>
                  </SystemPageLink>
               )}
               <SystemPageLink baseRoute={shipmentBaseUrl} locale={locale} type={SystemPageType.SHIPMENT} slug={shipmentId.toString()}>
                  <ListItem>{translate('shipment.table.shipment-details')}</ListItem>
               </SystemPageLink>
               <Link href={`/my/${language}/my-prices`} prefetch={false}>
                  <ListItem>{translate('shipment.table.new-quote')}</ListItem>
               </Link>
            </List>
         }
      >
         <Box content="end" items="end">
            <IconButton icon="ellipsis-vertical" onClick={() => setMenuOpen((curr) => !curr)} type="button" variant="on-light" />
         </Box>
      </Menu>
   );
};

const DesktopTable: FC<{
   shipment: ShipmentWithOrderLines;
   language: string;
   acceptWebp?: boolean;
   locale: string;
   openedFrom: OrderLineOpenedFrom;
   shipmentBaseUrl: string;
   orderDetailBaseUrl?: string;
   callOffAgreementBaseUrl?: string;
   onOrderLineButtonClick: (orderLine: DisplayOrder, shipmentId: number) => void;
}> = ({
   shipment,
   language,
   acceptWebp,
   locale,
   shipmentBaseUrl,
   openedFrom,
   orderDetailBaseUrl,
   callOffAgreementBaseUrl,
   onOrderLineButtonClick
}) => {
   const translate = useTranslations();

   return (
      <Box direction="column" width="100%">
         <Table rowDividers rounded border hover>
            <TableHead>
               <TableRow>
                  <TableHeader>
                     <SystemPageLink baseRoute={shipmentBaseUrl} locale={locale} type={SystemPageType.SHIPMENT} slug={shipment.shipmentId.toString()}>
                        <Typography variant="small-highlight" component="span" underline color="primary">
                           {translate('shipment.shipment')} {shipment.shipmentId}
                        </Typography>
                     </SystemPageLink>
                  </TableHeader>
                  <TableHeader>{translate('my-orders.table.order-line-number')}</TableHeader>
                  <TableHeader>{translate('my-orders.table.quantity')}</TableHeader>
                  <TableHeader colSpan={2}>
                     <Box justify="end">
                        <Chip
                           type={
                              <Typography variant="small" component="span" color="strong">
                                 {translate('shipment.delivery-date')}
                              </Typography>
                           }
                           label={
                              <Typography variant="small-highlight" component="span" align="left" color="strong">
                                 {DateUtils.transformOrderDeliveryDate(shipment.orderLines[0].deliveryDate)}
                              </Typography>
                           }
                        />
                     </Box>
                  </TableHeader>
               </TableRow>
            </TableHead>
            <TableBody>
               {shipment.orderLines.map((orderLine) => (
                  <TableRow key={orderLine.orderNumber} onClick={() => {}}>
                     <TableCell onClick={() => onOrderLineButtonClick(orderLine, shipment.shipmentId)} width="360px">
                        <OrderLineItem order={orderLine} language={language} openedFrom={openedFrom} acceptWebp={acceptWebp} />
                     </TableCell>
                     <TableCell onClick={() => onOrderLineButtonClick(orderLine, shipment.shipmentId)} width="200px">
                        {translate('my-orders.order-header')} {orderLine.orderNumber}
                     </TableCell>
                     <TableCell onClick={() => onOrderLineButtonClick(orderLine, shipment.shipmentId)} width="180px">
                        {orderLine.quantity.amount} {orderLine.quantity.unitOfMeasure}
                     </TableCell>
                     <TableCell onClick={() => onOrderLineButtonClick(orderLine, shipment.shipmentId)} width="370px">
                        {}
                     </TableCell>
                     <TableCell width="60px">
                        <OrderLineMenu
                           orderLine={orderLine}
                           shipmentId={shipment.shipmentId}
                           locale={locale}
                           language={language}
                           shipmentBaseUrl={shipmentBaseUrl}
                           orderDetailBaseUrl={orderDetailBaseUrl}
                           callOffAgreementBaseUrl={callOffAgreementBaseUrl}
                           onOrderLineButtonClick={onOrderLineButtonClick}
                        />
                     </TableCell>
                  </TableRow>
               ))}
            </TableBody>
         </Table>
      </Box>
   );
};

const MobileTable: FC<{
   shipment: ShipmentWithOrderLines;
   language: string;
   locale: string;
   acceptWebp?: boolean;
   shipmentBaseUrl: string;
   openedFrom: OrderLineOpenedFrom;
   orderDetailBaseUrl?: string;
   callOffAgreementBaseUrl?: string;
   onOrderLineButtonClick: (orderLine: DisplayOrder, shipmentId: number) => void;
}> = ({
   shipment,
   language,
   locale,
   acceptWebp,
   openedFrom,
   shipmentBaseUrl,
   orderDetailBaseUrl,
   callOffAgreementBaseUrl,
   onOrderLineButtonClick
}) => {
   const translate = useTranslations();

   return (
      <Table rowDividers={false} rounded border tableLayout="auto">
         <TableHead>
            <TableRow>
               <TableHeader colSpan={2}>
                  <Box direction="column" items="end" wrap="wrap" gap="xs">
                     <Chip
                        type={
                           <Typography variant="small" component="span" color="strong">
                              {translate('shipment.delivery-date')}
                           </Typography>
                        }
                        label={
                           <Typography variant="small-highlight" component="span" align="left" color="strong">
                              {DateUtils.transformOrderDeliveryDate(shipment.orderLines[0].deliveryDate)}
                           </Typography>
                        }
                     />
                     <SystemPageLink baseRoute={shipmentBaseUrl} locale={locale} type={SystemPageType.SHIPMENT} slug={shipment.shipmentId.toString()}>
                        <Typography variant="small-highlight" component="span" underline color="primary">
                           {translate('shipment.shipment')} {shipment.shipmentId}
                        </Typography>
                     </SystemPageLink>
                  </Box>
               </TableHeader>
            </TableRow>
         </TableHead>
         {shipment.orderLines.map((orderLine) => (
            <TableBody key={orderLine.orderNumber}>
               <TableRow>
                  <TableCell colSpan={2}>
                     <Box justify="between">
                        <OrderLineItem order={orderLine} language={language} openedFrom={openedFrom} acceptWebp={acceptWebp} />
                        <Box justify="end">
                           <OrderLineMenu
                              orderLine={orderLine}
                              shipmentId={shipment.shipmentId}
                              locale={locale}
                              language={language}
                              shipmentBaseUrl={shipmentBaseUrl}
                              orderDetailBaseUrl={orderDetailBaseUrl}
                              callOffAgreementBaseUrl={callOffAgreementBaseUrl}
                              onOrderLineButtonClick={onOrderLineButtonClick}
                           />
                        </Box>
                     </Box>
                  </TableCell>
               </TableRow>
               <TableRow onClick={() => onOrderLineButtonClick(orderLine, shipment.shipmentId)}>
                  <TableCell width="200px">
                     <Typography variant="body" color="subtle" component="span">
                        {translate('shipment.products.order-line-number')}
                     </Typography>
                  </TableCell>
                  <TableCell>{orderLine.orderNumber}</TableCell>
               </TableRow>
               <TableRow divider onClick={() => onOrderLineButtonClick(orderLine, shipment.shipmentId)}>
                  <TableCell>
                     <Typography variant="body" color="subtle" component="span">
                        {translate('shipment.products.quantity')}
                     </Typography>
                  </TableCell>
                  <TableCell>
                     {orderLine.quantity.amount} {orderLine.quantity.unitOfMeasure}
                  </TableCell>
               </TableRow>
            </TableBody>
         ))}
      </Table>
   );
};

export const ShipmentTable: FC<ShipmentTableProps> = ({
   shipments,
   locale,
   isConsumer,
   acceptWebp,
   shipmentBaseUrl,
   orderDetailBaseUrl,
   callOffAgreementBaseUrl,
   openedFrom
}) => {
   const language = getLanguageCode(locale);
   const { dispatch: flyoutDispatch } = useFlyoutContext();
   const { dispatch: orderLineDispatch } = useOrderLineContext();

   const onOrderLineButtonClick = async (orderLine: DisplayOrder, shipmentId: number) => {
      if (isConsumer && orderLineDispatch) {
         const [orderId, orderNumber] = orderLine.orderNumber.split('-').map(Number);

         orderLineDispatch({
            type: 'set_order',
            value: {
               orderId,
               orderNumber,
               contentfulProduct: orderLine.contentfulProduct,
               openedFrom,
               shipmentId
            }
         });

         flyoutDispatch?.({
            type: 'open_flyout',
            value: { flyoutType: 'order-line', title: 'Order line' }
         });
      }
   };

   return (
      <Box margin={{ top: 'md' }} direction="column" gap="sm" width="100%">
         {shipments.map((shipment) => (
            <BreakpointSwitch key={shipment.shipmentId} includeViews={['desktop']}>
               <DesktopTable
                  shipment={shipment}
                  language={language}
                  locale={locale}
                  acceptWebp={acceptWebp}
                  onOrderLineButtonClick={onOrderLineButtonClick}
                  openedFrom={openedFrom}
                  shipmentBaseUrl={shipmentBaseUrl}
                  callOffAgreementBaseUrl={callOffAgreementBaseUrl}
                  orderDetailBaseUrl={orderDetailBaseUrl}
               />
            </BreakpointSwitch>
         ))}
         {shipments.map((shipment) => (
            <BreakpointSwitch key={shipment.shipmentId} includeViews={['phone', 'tab-port', 'tab-land']}>
               <MobileTable
                  shipment={shipment}
                  language={language}
                  locale={locale}
                  acceptWebp={acceptWebp}
                  openedFrom={openedFrom}
                  onOrderLineButtonClick={onOrderLineButtonClick}
                  shipmentBaseUrl={shipmentBaseUrl}
                  callOffAgreementBaseUrl={callOffAgreementBaseUrl}
                  orderDetailBaseUrl={orderDetailBaseUrl}
               />
            </BreakpointSwitch>
         ))}
      </Box>
   );
};
