'use client';

import { createContext, Dispatch, useContext } from 'react';

import { Action } from '../state/invoice.actions';
import { initialInvoiceState, InvoiceState } from '../state/invoice.reducer';

export const InvoiceStateContext = createContext<InvoiceState>(initialInvoiceState);

export const InvoiceDispatchContext = createContext<Dispatch<Action>>(() => {});

export const useInvoiceStateContext = () => {
   const context = useContext(InvoiceStateContext);

   if (!context) {
      throw new Error('useInvoiceStateContext must be used within an InvoiceStateContextProvider');
   }

   return context;
};

export const useInvoiceDispatchContext = () => {
   const context = useContext(InvoiceDispatchContext);

   if (!context) {
      throw new Error('useInvoiceDispatchContext must be used within an InvoiceDispatchContextProvider');
   }

   return context;
};
