'use client';

import { FC, useEffect } from 'react';

import { FacetFilterTracking, FacetFilterWithTerms, facetLabelTranslationMapping } from '@ravago/shared/page-data/models/elastic';
import { BottomSheet } from '@ravago/shared/radiance/components/bottom-sheet/components/BottomSheet/BottomSheet';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Expand } from '@ravago/shared/radiance/components/expand/components/Expand/Expand';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import isNil from 'lodash/isNil';

import { useTranslations } from '../../../../../../providers/translation-client-context-provider';
import { Link } from '../../../../../shared/link/link';
import { FacetGroup } from '../../../models/facet-group.model';
import { getActiveFacetLabel } from '../../../utils/filter-label-utils';
import { FilterGroup } from '../filters/filter-group';

interface Props {
   facetFilters: Array<FacetFilterWithTerms>;
   facets: Array<FacetGroup>;
   canResetFilters: boolean;
   filterFlyoutOpen?: boolean;
   language: string;
   locked?: boolean;
   expandedGroups: string[];
   onFilterAdjust: (value: Array<FacetFilterWithTerms>) => void;
   onFilterClicked: (facetFilter: FacetFilterTracking) => void;
   onFilterReset: () => void;
   onExpandChange: (key: string, expanded: boolean) => void;
   onCloseAll: () => void;
   onFilterFlyoutClose: () => void;
}

export const ProductPortfolioFilters: FC<Props> = ({
   facetFilters,
   facets,
   canResetFilters,
   filterFlyoutOpen = false,
   language,
   locked = false,
   expandedGroups,
   onFilterAdjust,
   onFilterReset,
   onFilterClicked,
   onExpandChange,
   onCloseAll,
   onFilterFlyoutClose
}) => {
   const translate = useTranslations();
   const { phone } = useBreakpoint();

   const removeFilterGroup = (key: string) => {
      const newFacets = facetFilters.filter((facet) => facet.facet !== key);
      onFilterAdjust(newFacets);
   };

   const onFilterChange = (value: FacetFilterWithTerms) => {
      // Remove range key when no min/max values are set
      value.terms.forEach((term) => {
         if (term.range && (isNil(term.range.min) || isNil(term.range.max))) {
            delete term.range;
         }
      });

      const newValue = [...facetFilters];
      const index = newValue.findIndex((facetFilter) => facetFilter.facet === value.facet);

      if (index === -1) {
         newValue.push(value);
      } else {
         newValue[index] = value;
      }

      onFilterAdjust(newValue);
   };

   const handleReset = () => {
      onCloseAll();
      onFilterReset();
   };

   useEffect(() => {
      if (filterFlyoutOpen) onCloseAll();
   }, [filterFlyoutOpen]);

   const filterGroups = facets.map((facet) => (
      <FilterGroup
         key={facet.identifier.key}
         language={language}
         facetFilter={facetFilters.find((filter) => filter.facet === facet.identifier.key)}
         facetGroup={facet}
         onFilterChange={onFilterChange}
         onFilterRemove={() => removeFilterGroup(facet.identifier.key)}
         onFilterClicked={onFilterClicked}
         expanded={expandedGroups.includes(facet.identifier.key)}
         onExpandToggle={(expanded) => onExpandChange(facet.identifier.key, expanded)}
      />
   ));

   return (
      <div style={{ pointerEvents: locked ? 'none' : 'unset', display: 'contents' }}>
         <BreakpointSwitch includeViews={['desktop']}>
            <Box direction="column" gap="lg">
               <Box justify="between" items="baseline">
                  <Typography color="strong" variant="headline">
                     {translate('products-overview-filters-header')}
                  </Typography>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  {canResetFilters && (
                     // eslint-disable-next-line jsx-a11y/anchor-is-valid
                     <Link onClick={handleReset}>{translate('products-overview-filters-reset')}</Link>
                  )}
               </Box>
               <Expand expanded={Object.keys(facetFilters).length > 0}>
                  <Box direction="column" gap="sm" spacing={{ bottom: 'md' }}>
                     <Typography variant="body-highlight" color="strong">
                        {translate('products-overview-filters-applied')}
                     </Typography>

                     {facetFilters.map((facetFilter) => {
                        const facetGroup = facets.find((f) => f.identifier.key === facetFilter.facet);
                        if (!facetGroup || facetGroup.facets.length === 0) return null;
                        return (
                           <Box key={facetFilter.facet} justify="between" items="baseline">
                              <Typography variant="body" color="strong" maxLines="1">
                                 {`${translate(facetLabelTranslationMapping.get(facetFilter.facet) ?? facetFilter.facet)}: `}
                                 <b>{getActiveFacetLabel(facetFilter, facetGroup)}</b>
                              </Typography>
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <Link onClick={() => removeFilterGroup(facetFilter.facet)}>
                                 <Icon icon="times" />
                              </Link>
                           </Box>
                        );
                     })}
                  </Box>
               </Expand>
            </Box>
            <Box direction="column" gap="md">
               {filterGroups}
            </Box>
         </BreakpointSwitch>
         <BreakpointSwitch excludeViews={['desktop']}>
            <Box direction="column" gap="md" spacing={{ top: 'lg', right: 'md', bottom: 'md', left: 'md' }}>
               <Box justify="between" items="baseline">
                  <Typography color="strong" variant="heading-4">
                     {translate('products-overview-filters-header')}
                  </Typography>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link onClick={onFilterFlyoutClose}>
                     <Icon icon="times" />
                  </Link>
               </Box>
               <Divider />
            </Box>
            <Box direction="column" gap="none" height="100%" overflowY="auto" spacing={{ left: 'md', right: 'md', bottom: 'lg' }}>
               {filterGroups}
            </Box>
            <BottomSheet
               position="relative"
               header={
                  <Box direction={phone ? 'column' : 'row'} gap="xs">
                     <Button fullWidth onClick={onFilterFlyoutClose}>
                        {translate('filter.show-results')}
                     </Button>

                     {canResetFilters && (
                        <Button fullWidth variant="secondary" onClick={handleReset}>
                           {translate('products-overview-filters-reset')}
                        </Button>
                     )}
                  </Box>
               }
            />
         </BreakpointSwitch>
      </div>
   );
};
