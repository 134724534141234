import { FC, useEffect, useRef } from 'react';

import { InputField } from '@ravago/shared/radiance/components/input-field/components/InputField/InputField';

import { useTranslations } from '../../../../providers/translation-client-context-provider';

const UPDATE_FILTERS_TIMEOUT_MS = 500;

interface Props {
   disabled: boolean;
   searchString: string;
   onUpdateSearchFilter: (searchString: string) => void;
}

export const ProductCustomizableTableSearchFilter: FC<Props> = ({ disabled, searchString, onUpdateSearchFilter }) => {
   const translate = useTranslations();

   const updateSearchFilterTimeout = useRef<NodeJS.Timeout>();

   useEffect(
      function clearTimeoutWhenPassedInSearchStringChanges() {
         if (updateSearchFilterTimeout.current) {
            clearTimeout(updateSearchFilterTimeout.current);
         }
      },
      [searchString]
   );

   const handleInputFieldChange = (value: string) => {
      if (updateSearchFilterTimeout.current) {
         clearTimeout(updateSearchFilterTimeout.current);
      }
      updateSearchFilterTimeout.current = setTimeout(() => {
         delete updateSearchFilterTimeout.current;
         onUpdateSearchFilter(value);
      }, UPDATE_FILTERS_TIMEOUT_MS);
   };

   const handleInputFieldClear = () => {
      if (updateSearchFilterTimeout.current) {
         clearTimeout(updateSearchFilterTimeout.current);
      }
      onUpdateSearchFilter('');
   };

   return (
      <InputField
         label={translate('product-customizable-table-search-filter.search')}
         value={searchString}
         disabled={disabled}
         clearable
         id="table-search-filter__search-input"
         onChange={handleInputFieldChange}
         onClear={handleInputFieldClear}
         data-testid="table-search-filter__search-input"
      />
   );
};
