import { FC, useState } from 'react';

import { SystemPageType } from '@ravago/shared/page-data/enums/system-page-type';
import { DisplayOrder } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { List } from '@ravago/shared/radiance/components/list/components/List/List';
import { ListItem } from '@ravago/shared/radiance/components/list/components/ListItem/ListItem';
import { Menu } from '@ravago/shared/radiance/components/menu/components/Menu/Menu';

import { useTranslations } from '../../../providers/translation-client-context-provider';
import { SystemPageLink } from '../../content/system-page-link/system-page-link';
import { Link } from '../link/link';

export const OrderLineTableMenu: FC<{
   orderLine: DisplayOrder;
   locale: string;
   language: string;
   shipmentBaseUrl: string;
   orderDetailBaseUrl?: string;
   callOffAgreementBaseUrl?: string;
   onOrderLineButtonClick: (orderLine: DisplayOrder) => void;
}> = ({ orderLine, locale, language, shipmentBaseUrl, orderDetailBaseUrl, callOffAgreementBaseUrl, onOrderLineButtonClick }) => {
   const translate = useTranslations();
   const [menuOpen, setMenuOpen] = useState(false);

   const handleOrderLineButtonClick = () => {
      setMenuOpen(false);
      onOrderLineButtonClick(orderLine);
   };

   return (
      <Menu
         open={menuOpen}
         align="right"
         closeOnOutsideClick
         onMenuClose={() => setMenuOpen(false)}
         content={
            <List>
               <ListItem onClick={handleOrderLineButtonClick}>
                  {orderLine.isCallOff ? translate('shipment.table.request-delivery') : translate('shipment.table.show-more')}
               </ListItem>
               {orderDetailBaseUrl && !orderLine.isCallOff && (
                  <SystemPageLink
                     baseRoute={orderDetailBaseUrl}
                     locale={locale}
                     type={SystemPageType.ORDER_DETAIL}
                     slug={orderLine.orderNumber.slice(0, orderLine.orderNumber.indexOf('-'))}
                  >
                     <ListItem>{translate('shipment.table.order-details')}</ListItem>
                  </SystemPageLink>
               )}
               {callOffAgreementBaseUrl && (orderLine.agreementNumber !== undefined || orderLine.isCallOff) && (
                  <SystemPageLink
                     baseRoute={callOffAgreementBaseUrl}
                     locale={locale}
                     type={SystemPageType.CALL_OFF_ORDER_DETAIL}
                     slug={orderLine.isCallOff ? orderLine.orderNumber : orderLine.agreementNumber}
                  >
                     <ListItem>{translate('shipment.table.call-off-agreement')}</ListItem>
                  </SystemPageLink>
               )}
               {orderLine.shipmentIds &&
                  orderLine.shipmentIds.length > 0 &&
                  orderLine.shipmentIds.map((shipmentId) => (
                     <SystemPageLink
                        key={shipmentId}
                        baseRoute={shipmentBaseUrl}
                        locale={locale}
                        type={SystemPageType.SHIPMENT}
                        slug={shipmentId.toString()}
                     >
                        <ListItem>{`${translate('shipment.table.shipment-details')} ${shipmentId}`}</ListItem>
                     </SystemPageLink>
                  ))}
               <Link href={`/my/${language}/my-prices`} prefetch={false} underline="none">
                  <ListItem>{translate('shipment.table.new-quote')}</ListItem>
               </Link>
            </List>
         }
      >
         <Box content="end" items="end">
            <IconButton icon="ellipsis-vertical" onClick={() => setMenuOpen((curr) => !curr)} type="button" variant="on-light" />
         </Box>
      </Menu>
   );
};
