import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../providers/translation-client-context-provider';

interface Props {
   onClick: () => void;
}

export const DynamicProductBlockQuoteDetails = ({ onClick }: Props) => {
   const translate = useTranslations();

   return (
      <Box background="#F2F4F4" spacing="md" borderRadius="sm" justify="between" onClick={onClick}>
         <Typography variant="body" color="primary">
            {translate('dynamic-product-block.view-quote-details')}
         </Typography>
         <Icon icon="file-alt" color="primary" />
      </Box>
   );
};
