import { FC } from 'react';

import { UOM } from '@ravago/shared/page-data/models/config';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Download, DownloadOption } from '@ravago/shared/radiance/components/input-field/components/Download/Download';
import { Dropdown } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';

import { useTranslations } from '../../../../../providers/translation-client-context-provider';

import { RelatedDocumentUlProspectorLink } from './related-document-ul-prospector-link';

interface Props {
   onDownload?: () => void;
   onLanguageChange?: (language: DownloadOption) => void;
   onUOMChange?: (uom: DownloadOption) => void;
   showTdsUom?: boolean;
   languages: DownloadOption[];
   selectedLanguage: DownloadOption;
   selectedUOM?: DownloadOption;
   documentId: string;
   language: string;
   ulProspectorId: string | undefined;
   isSalesRep: boolean;
   channelUOMs: Array<UOM>;
}

export const RelatedDocumentTdsDownload: FC<Props> = ({
   onUOMChange,
   onLanguageChange,
   language,
   documentId,
   selectedLanguage,
   selectedUOM,
   showTdsUom = false,
   onDownload,
   isSalesRep,
   channelUOMs,
   ulProspectorId,
   languages
}) => {
   const translate = useTranslations();
   const handleLanguageChange = async (lan: DownloadOption | undefined) => {
      if (lan) onLanguageChange?.(lan);
   };

   const handleUOMChange = async (uom: DownloadOption | undefined) => {
      if (uom) onUOMChange?.(uom);
   };

   const handleDownload = () => {
      onDownload?.();
   };

   const download = () => (
      <Download
         onChange={(value) => handleLanguageChange(value)}
         onDownload={() => handleDownload()}
         value={selectedLanguage}
         id={`${documentId}-download`}
         options={languages}
      />
   );

   const selectorsAndDownload = () => (
      <>
         <Dropdown
            options={languages}
            value={selectedLanguage}
            emptyOption={false}
            label={translate('products-technical-data-sheet')}
            onChange={(lan) => handleLanguageChange(lan)}
            id={`${documentId}-language`}
         />
         <Dropdown
            options={channelUOMs}
            value={selectedUOM}
            emptyOption={false}
            label={translate('product.documents.uom')}
            onChange={(uom) => handleUOMChange(uom)}
            id={`${documentId}-uom`}
         />
         <IconButton icon="download" variant="on-primary" backdrop onClick={() => handleDownload()} />
      </>
   );

   return (
      <Box direction="column" gap="xs" items="start">
         <BreakpointSwitch excludeViews={['phone']}>
            <Box direction="row" gap="xs" items="start">
               {showTdsUom ? selectorsAndDownload() : download()}
            </Box>
         </BreakpointSwitch>
         <BreakpointSwitch includeViews={['phone']}>
            <Box direction="column" gap="md" width="100%" items="end">
               {showTdsUom ? selectorsAndDownload() : download()}
            </Box>
            {ulProspectorId && isSalesRep && <RelatedDocumentUlProspectorLink language={language} ulProspectorId={ulProspectorId} />}
         </BreakpointSwitch>
      </Box>
   );
};
