import { useEffect } from 'react';

export const useKeyboardShortcut = (key: string, onKeyPressed: () => void) => {
   useEffect(() => {
      const keyDownHandler = (e: globalThis.KeyboardEvent) => {
         if (e.key === key) {
            e.preventDefault();
            onKeyPressed();
         }
      };

      document.addEventListener('keydown', keyDownHandler);

      return () => {
         document.removeEventListener('keydown', keyDownHandler);
      };
   }, [key, onKeyPressed]);
};
