import { FC, PropsWithChildren, useReducer } from 'react';

import { InvoiceDispatchContext, InvoiceStateContext } from '../context/invoice-context';
import { initialInvoiceState, InvoiceReducer } from '../state/invoice.reducer';

export const InvoiceProviders: FC<PropsWithChildren> = ({ children }) => {
   const [state, dispatch] = useReducer(InvoiceReducer, initialInvoiceState);

   return (
      <InvoiceStateContext.Provider value={state}>
         <InvoiceDispatchContext.Provider value={dispatch}>{children}</InvoiceDispatchContext.Provider>
      </InvoiceStateContext.Provider>
   );
};
