'use client';

import { FC } from 'react';

import { Status } from '@ravago/shared/page-data/models/elements';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Chip } from '@ravago/shared/radiance/components/chip/components/Chip/Chip';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import { useTranslations } from '../../../providers/translation-client-context-provider';
import { OrderUtils } from '../../../utils/order/order.utils';

interface Props {
   headerText: string;
   id: string | null;
   status?: Status;
   date: string | undefined;
}

export const OrderPageHeader: FC<Props> = ({ headerText, id, status, date }) => {
   const translate = useTranslations();
   const { phone } = useBreakpoint();

   return (
      <Box direction={phone ? 'column' : 'row'} justify="between">
         <Box items="center">
            {id && (
               <Typography variant="heading-1" component="h1" align="left" color="strong">
                  {headerText} {id}
               </Typography>
            )}
            {status && (
               <Badge size="regular" variant={status !== 'Invoiced' ? 'info' : 'warning'} weight="bold">
                  {translate(OrderUtils.getStatusTranslation(status))}
               </Badge>
            )}
         </Box>
         {date && (
            <Box height="36px">
               <Chip
                  type={
                     <Typography variant="small" component="span" color="strong">
                        {translate('my-orders.estimated-delivery')}
                     </Typography>
                  }
                  label={
                     <Typography variant="small-highlight" component="span" align="left" color="strong">
                        {date}
                     </Typography>
                  }
               />
            </Box>
         )}
      </Box>
   );
};
