'use client';

import { FC, useCallback, useMemo } from 'react';

import { getColorCode } from '@ravago/shared/page-data/const/color-to-hex-mapper';
import { CallOffOrderColor, Color, QuotedColor } from '@ravago/shared/page-data/models/elements';
import { ColorPicker, ColorPickerOption } from '@ravago/shared/radiance/components/input-field/components/ColorPicker/ColorPicker';
import { Dropdown, DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';
import { InputField } from '@ravago/shared/radiance/components/input-field/components/InputField/InputField';

import { useTranslations } from '../../../providers/translation-client-context-provider';

export interface ColorPickerValue {
   colorId: number | undefined;
   colorName: string | undefined;
   callOffOrderId: string | undefined;
   isQuotedColor: boolean;
   producerColorCode: string | undefined;
   desiredColor: string | undefined;
}

interface Props {
   id: string;
   colors: Array<Color>;
   callOffOrderColors: Array<CallOffOrderColor>;
   quotedColors: Array<QuotedColor>;
   value: ColorPickerValue;
   onChange?: (value: ColorPickerValue) => void;
}

export const ExtendedColorPicker: FC<Props> = ({ id, colors, callOffOrderColors, quotedColors, value, onChange }) => {
   const translate = useTranslations();

   const colorOptions = useMemo(() => {
      const callOffOrderColorIds = callOffOrderColors.map((color) => color.color.id);
      const quotedColorIds = quotedColors.map((color) => color.color.id);
      const fromColors = colors
         .filter((color) => (!value.callOffOrderId && value.colorId === color.id) || !callOffOrderColorIds.includes(color.id))
         .filter((color) => (!value.isQuotedColor && value.colorId === color.id) || !quotedColorIds.includes(color.id))
         .map((color) => ({
            id: color.id.toString(),
            label: translate(`colors.${color.name.toLowerCase().replace(' ', '-')}`),
            code: getColorCode(color.name.toLowerCase())
         }));

      const fromCallOffs = callOffOrderColors.map((color) => {
         const isOverdue = color.recommendedCallOff.status === 'Call Off Overdue';
         const formattedPrice = Intl.NumberFormat(undefined, {
            currency: color.recommendedCallOff.unitPrice.price.currency,
            style: 'currency'
         }).format(color.recommendedCallOff.unitPrice.price.amount);
         const priceLabel = `${formattedPrice}/${color.recommendedCallOff.unitPrice.unitOfMeasure}`;
         const badges = [{ label: priceLabel, variant: 'info' }];
         if (isOverdue) {
            badges.unshift({ label: translate('extended-color-picker.overdue'), variant: 'error' });
         }

         let label = translate(`colors.${color.color.name.toLowerCase().replace(' ', '-')}`);
         if (color.color.producerColorCode) label = `${label} ${color.color.producerColorCode}`;

         return {
            id: color.recommendedCallOff.id,
            label,
            code: getColorCode(color.color.name.toLowerCase()),
            badges
         };
      });

      const fromQuotedColors = quotedColors.map((quotedColor) => {
         const lowestPriceLine = quotedColor.quotations
            .flatMap((quotation) => quotation.lines)
            .reduce((lowest, line) => (line.unitPrice.price.amount < lowest.unitPrice.price.amount ? line : lowest));

         const formattedPrice = Intl.NumberFormat(undefined, {
            currency: lowestPriceLine.unitPrice.price.currency,
            style: 'currency'
         }).format(lowestPriceLine.unitPrice.price.amount);
         const priceLabel = `${translate('extended-color-picker.from')} ${formattedPrice}/${lowestPriceLine.unitPrice.unitOfMeasure}`;
         const badges = [{ label: priceLabel, variant: 'info' }];

         let label = translate(`colors.${quotedColor.color.name.toLowerCase().replace(' ', '-')}`);
         if (quotedColor.color.producerColorCode) label = `${label} ${quotedColor.color.producerColorCode}`;

         return {
            id: `${quotedColor.color.id.toString()}-${quotedColor.color.producerColorCode}-quoted`,
            label,
            code: getColorCode(quotedColor.color.name.toLowerCase()),
            badges
         };
      });

      return [...fromCallOffs, ...fromQuotedColors, ...fromColors];
   }, [colors, callOffOrderColors, quotedColors, translate]);

   const selectedColor = useMemo(() => {
      if (value.callOffOrderId) return colorOptions.find((option) => option.id === value.callOffOrderId);
      if (value.isQuotedColor) return colorOptions.find((option) => option.id === `${value.colorId}-${value.producerColorCode}-quoted`);
      if (value.colorId) return colorOptions.find((option) => option.id === value.colorId?.toString());
      return undefined;
   }, [value, colorOptions]);

   const colorCodeOptions: DropdownOption[] = useMemo(() => {
      if (!selectedColor || selectedColor.id === '-1') return [];

      const selectedColorCodes = colors.find((col) => col.id.toString() === selectedColor.id)?.producerColorCodes;
      if (!selectedColorCodes) return [];

      const selectedColorCodesOptions = selectedColorCodes.map((producerColorCode) => ({
         id: producerColorCode.colorCode,
         value: producerColorCode.colorCode
      }));

      return [...selectedColorCodesOptions, { id: 'Other', value: 'Other' }];
   }, [selectedColor, colors]);

   const selectedColorCode = useMemo(() => {
      if (value.producerColorCode) return colorCodeOptions.find((option) => option.id === value.producerColorCode);
      return undefined;
   }, [value, colorCodeOptions]);

   const desiredColor = value.desiredColor ?? '';

   const disabled = colorOptions.length === 1;

   const showColorCode = value.colorId && value.colorId !== -1 && !value.callOffOrderId && !value.isQuotedColor;
   const showOtherField = value.colorId === -1 || value.producerColorCode === 'Other';

   const callOffIds = callOffOrderColors.map((color) => color.recommendedCallOff.id);
   const quotedColorIds = quotedColors.map((color) => `${color.color.id}-${color.color.producerColorCode}-quoted`);
   const colorIds = colors.map((color) => color.id.toString());

   const groupByFn = useCallback(
      (option: ColorPickerOption) => {
         if (callOffIds.includes(option.id)) return translate('extended-color-picker.call-off-colors');
         if (quotedColorIds.includes(option.id)) return 'extended-color-picker.order-from-quote';
         if (colorIds.includes(option.id)) return translate('extended-color-picker.request-new-quote');
         return '';
      },
      [callOffIds, quotedColorIds, colorIds, translate]
   );

   const handleColorChange = (option: ColorPickerOption | undefined) => {
      if (!option) {
         onChange?.({
            colorId: undefined,
            colorName: undefined,
            callOffOrderId: undefined,
            isQuotedColor: false,
            producerColorCode: undefined,
            desiredColor: undefined
         });
         return;
      }

      if (callOffIds.includes(option.id)) {
         const callOffOrderColor = callOffOrderColors.find((color) => color.recommendedCallOff.id === option.id);
         if (!callOffOrderColor) return;
         onChange?.({
            colorId: callOffOrderColor.color.id,
            colorName: translate(`colors.${callOffOrderColor.color.name.toLowerCase().replace(' ', '-')}`),
            callOffOrderId: callOffOrderColor.recommendedCallOff.id,
            isQuotedColor: false,
            producerColorCode: callOffOrderColor.color.producerColorCode,
            desiredColor: undefined
         });
      }

      if (option.id.includes('-quoted')) {
         const quotedColor = quotedColors.find((color) => `${color.color.id.toString()}-${color.color.producerColorCode}-quoted` === option.id);
         if (!quotedColor) return;
         onChange?.({
            colorId: quotedColor.color.id,
            colorName: translate(`colors.${quotedColor.color.name.toLowerCase().replace(' ', '-')}`),
            callOffOrderId: undefined,
            isQuotedColor: true,
            producerColorCode: quotedColor.color.producerColorCode,
            desiredColor: undefined
         });
      }

      if (colorIds.includes(option.id)) {
         const foundColor = colors.find((color) => color.id.toString() === option.id);
         if (!foundColor) return;
         onChange?.({
            colorId: foundColor.id,
            colorName: translate(`colors.${foundColor.name.toLowerCase().replace(' ', '-')}`),
            callOffOrderId: undefined,
            isQuotedColor: false,
            producerColorCode: undefined,
            desiredColor: undefined
         });
      }
   };

   const handleColorCodeChange = (option: DropdownOption | undefined, colorPreferences = '') => {
      onChange?.({ ...value, producerColorCode: option?.value, desiredColor: colorPreferences });
   };

   const handleDesiredColorChange = (newDesiredColor: string) => {
      onChange?.({ ...value, desiredColor: newDesiredColor });
   };

   return (
      <>
         <ColorPicker
            id={`${id}-color-picker`}
            disabled={disabled}
            label={translate('extended-color-picker.label')}
            options={colorOptions}
            value={selectedColor}
            onChange={handleColorChange}
            groupByFn={callOffOrderColors.length > 0 || quotedColors.length ? groupByFn : undefined}
         />
         {showColorCode && (
            <Dropdown
               id={`${id}-color-code-dropdown`}
               label={translate('extended-color-picker.color-code-label')}
               value={selectedColorCode}
               onChange={handleColorCodeChange}
               options={colorCodeOptions}
               emptyOption={false}
            />
         )}
         {showOtherField && (
            <InputField
               id={`${id}-other-input`}
               label={translate('extended-color-picker.desired-color-label')}
               value={desiredColor}
               maxLength={50}
               onChange={handleDesiredColorChange}
               supportText={translate('extended-color-picker.desired-color-support-text')}
               trailingIcon="droplet"
            />
         )}
      </>
   );
};
