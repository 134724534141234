import { FC, ReactNode, useMemo } from 'react';

import { FacetFilterTerm } from '@ravago/shared/page-data/models/elastic';

import { useTranslations } from '../../../../../../providers/translation-client-context-provider';
import { FacetGroupContent } from '../../../models/facet-group.model';

import { FilterItem, RangeChangeEvent } from './filter-item';

interface Props {
   searchValue: string;
   isParentActive?: boolean;
   facet: FacetGroupContent;
   term?: FacetFilterTerm;
   onCheckedChange: (newValue: boolean) => void;
   onRangeChange: (event: RangeChangeEvent) => void;
   renderChild: (isParentActive: boolean) => ReactNode | undefined;
}

export const FilterGroupItem: FC<Props> = ({ searchValue, isParentActive, facet, term, onCheckedChange, onRangeChange, renderChild }) => {
   const translate = useTranslations();

   const areChildrenActive = useMemo(() => {
      if (!term || !term.nestedFacets || !facet.nestedFacets) return false;
      const termSum = term.nestedFacets.map((nestedFacet) => nestedFacet.terms.length).reduce((acc, curr) => acc + curr, 0);
      const facetSum = facet.nestedFacets.map((nestedFacet) => nestedFacet.facets.length).reduce((acc, curr) => acc + curr, 0);
      return termSum === facetSum;
   }, [facet, term]);

   const active = isParentActive || (!!term && (!term.nestedFacets || term.nestedFacets.length === 0)) || areChildrenActive;
   const indeterminate = !areChildrenActive && !!term?.nestedFacets && term?.nestedFacets.length > 0;
   const range =
      term && typeof term?.range?.min === 'number' && typeof term?.range?.max === 'number' ? { min: term.range.min, max: term.range.max } : undefined;

   return (
      <FilterItem
         key={facet.name}
         label={translate(facet.label)}
         highlightValue={searchValue}
         facet={facet}
         checked={active}
         indeterminate={indeterminate}
         range={range}
         disabled={facet.quantity === 0}
         onCheckedChange={() => onCheckedChange(!active)}
         onRangeChange={onRangeChange}
      >
         {renderChild(active)}
      </FilterItem>
   );
};
