import { ApiConnect } from '@ravago/shared/page-data/models/config';
import {
   ContentfulCollectionName,
   ContentfulOrderProduct,
   ContentfulPaginatedResponse,
   ContentfulProduct,
   ContentfulProductEntry
} from '@ravago/shared/page-data/models/contentful';
import { OverviewPageProductItem } from '@ravago/shared/page-data/models/elastic';
import { CallOffOrderColor, QuotedColor, ProductCard, ProductDetail } from '@ravago/shared/page-data/models/elements';
import { BaseQuery } from '@ravago/shared/page-data/models/utils';

import { ContentfulClientApi, Entry, EntryCollection } from 'contentful';
import uniq from 'lodash/uniq';

import { ConfigService } from '../../services/config/config.service';
import { AssetUtils } from '../asset/asset.utils';
import { cache } from '../cache/cache.utils';
import { MarketSegmentUtils } from '../market-segment/market-segment.utils';
import { QueryBuilder } from '../query-builder/query-builder';

/* c8 ignore start */
export class ProductUtils {
   static getProductCollection = cache(
      async (
         contentfulClientApi: ContentfulClientApi,
         locale: string,
         query: BaseQuery
      ): Promise<ContentfulPaginatedResponse<ContentfulProduct[]>> => {
         const contentType: ContentfulCollectionName = 'product';
         const { limit, page, search, orderBy, filterBy, include, productIds, producerIds, brandIds, processIds, industryIds, segmentIds } = query;

         const queryOptions = new QueryBuilder()
            .contentType(contentType)
            .limit(limit || 4)
            .skip((limit || 4) * (page && page > 0 ? page - 1 : 0))
            .search(search)
            .order(orderBy)
            .include(include || 10)
            .locale(locale)
            .setArrayOptional('sys.id[in]', productIds)
            .setArrayOptional('fields.brand.sys.id[in]', brandIds)
            .setOptional('fields.processes.sys.id', processIds?.[0]);

         if (producerIds?.length) {
            const [producerId] = producerIds;

            queryOptions.set('fields.brand.sys.contentType.sys.id', 'brand');
            queryOptions.set('fields.brand.fields.producer.sys.id', producerId);
         }

         if (industryIds?.length) {
            const marketSegments = await MarketSegmentUtils.getMarketSegmentsByQuery(contentfulClientApi, locale, {
               limit: 1000,
               filterBy,
               orderBy,
               industryIds
            });
            const marketSegmentIds: Array<string> = marketSegments?.map((marketSegment) => marketSegment.sys.id) || [];
            queryOptions.set('fields.industrySegments.sys.id[in]', marketSegmentIds.join(','));
         }

         if (segmentIds?.length) {
            const [segmentId] = segmentIds;

            queryOptions.set('fields.industrySegments.sys.id', segmentId);
         }

         const entries: EntryCollection<ContentfulProductEntry> = await contentfulClientApi.getEntries(queryOptions.build());
         const products = this.normalizeEntryCollection(entries).slice(0, limit || 4);
         return { items: products, total: entries.total };
      }
   );

   static getProductByEntryId = cache(
      async (contentfulClientApi: ContentfulClientApi, locale: string, entryId?: string): Promise<ContentfulProduct | undefined> => {
         if (!entryId) return undefined;

         const entry = await contentfulClientApi.getEntry<ContentfulProductEntry>(entryId, { locale, include: 10 });

         if (!entry) return undefined;

         return this.normalizeEntry(entry);
      }
   );

   static getOrderProductsByIds = cache(
      async (contentfulClientApi: ContentfulClientApi, locale: string, entryIds: string[]): Promise<ContentfulOrderProduct[] | undefined> => {
         if (!entryIds || entryIds.length === 0) return undefined;

         const entry = await contentfulClientApi.getEntries<ContentfulProductEntry>({
            content_type: 'product',
            locale,
            include: 2,
            'sys.id[in]': entryIds.join(','),
            select: 'sys.id,fields.slug,fields.brand'
         });

         if (!entry) return undefined;

         return this.normalizeEntryCollectionToContentfulOrderProduct(entry);
      }
   );

   static async getProductsByCountry(
      contentfulClientApi: ContentfulClientApi,
      locale: string,
      countryIsoCode: string,
      limit = 1000
   ): Promise<ContentfulProduct[]> {
      const entries = await contentfulClientApi.getEntries<ContentfulProductEntry>({
         content_type: 'product',
         locale,
         limit,
         include: 10,
         'fields.brand.fields.countries.fields.isoCode': countryIsoCode
      });

      return this.normalizeEntryCollection(entries);
   }

   private static normalizeEntryCollection({ items }: EntryCollection<ContentfulProductEntry>): ContentfulProduct[] {
      return items.map(this.normalizeEntry);
   }

   private static normalizeEntryCollectionToContentfulOrderProduct({ items }: EntryCollection<ContentfulProductEntry>): ContentfulOrderProduct[] {
      return items.map(this.normalizeEntryContentfulOrderProduct);
   }

   static normalizeEntry(entry: Entry<ContentfulProductEntry>): ContentfulProduct {
      return {
         id: entry.sys.id,
         name: entry.fields.name ?? '',
         slug: entry.fields.slug ?? '',
         grade: entry.fields.grade ?? '',
         ranking: entry.fields.ranking ?? 0,
         image: entry.fields.image ? { url: entry.fields.image.fields?.file?.url ?? '' } : undefined,
         brand: {
            sys: { id: entry.fields.brand?.sys.id ?? '' },
            seoMetadata: { noFollow: entry.fields.brand?.fields?.seoMetadata?.fields?.noFollow },
            slug: entry.fields.brand?.fields?.slug ?? '',
            name: entry.fields.brand?.fields?.name ?? '',
            excerpt: '',
            producer: {
               sys: { id: entry.fields.brand?.fields?.producer?.sys.id ?? '' },
               seoMetadata: { noFollow: entry.fields.brand?.fields?.producer?.fields?.seoMetadata?.fields?.noFollow },
               name: entry.fields.brand?.fields?.producer?.fields?.name ?? '',
               logo: { url: entry.fields.brand?.fields?.producer?.fields?.logo?.fields?.file?.url ?? '' },
               slug: entry.fields.brand?.fields?.producer?.fields?.slug ?? '',
               storefront: {
                  route: entry.fields.brand?.fields?.producer?.fields?.storefront?.fields?.route ?? '',
                  seoMetadata: { noFollow: entry.fields.brand?.fields?.producer?.fields?.storefront?.fields?.seoMetadata?.fields?.noFollow }
               }
            },
            countries:
               entry.fields.brand?.fields?.countries?.map((country) => ({
                  name: country.fields?.name ?? '',
                  isoCode: country.fields?.isoCode ?? ''
               })) ?? []
         },
         polymerGroup: entry.fields.polymerSubgroup?.fields?.group?.fields?.name ?? '',
         polymerSubGroup: entry.fields.polymerSubgroup?.fields?.name ?? '',
         segments:
            entry.fields.industrySegments?.map((industrySegment) => ({
               sys: { id: industrySegment.sys.id },
               image: AssetUtils.mapToImage(industrySegment.fields?.image),
               name: industrySegment.fields?.name ?? '',
               description: industrySegment.fields?.description ?? '',
               applications: industrySegment.fields?.applications ?? [],
               rank: industrySegment.fields?.rank ?? 0,
               industry: {
                  name: industrySegment.fields?.industry?.fields?.name ?? '',
                  slug: industrySegment.fields?.industry?.fields?.slug ?? '',
                  id: industrySegment.fields?.industry?.sys.id ?? '',
                  keyAreas: industrySegment.fields?.industry?.fields?.keyAreas ?? [],
                  callToActionType: industrySegment.fields?.industry?.fields?.callToActionType ?? 'Off',
                  image: {
                     url: industrySegment.fields?.industry?.fields?.image?.fields?.file?.url ?? '',
                     title: industrySegment.fields?.industry?.fields?.image?.fields?.title ?? ''
                  },
                  seoMetadata: {
                     noFollow: industrySegment.fields?.industry?.fields?.seoMetadata?.fields?.noFollow
                  }
               }
            })) ?? [],
         fillers:
            entry.fields.fillers?.map((filler) => ({
               filler: { id: filler.sys.id, name: filler.fields?.filler.fields?.name ?? '' },
               percentage: filler.fields?.percentage ?? 0
            })) ?? [],
         processes:
            entry.fields.processes?.map((process) => ({
               id: process.sys.id,
               name: process.fields?.name ?? '',
               seoMetadata: {
                  noFollow: process.fields?.seoMetadata?.fields?.noFollow
               },
               slug: process.fields?.slug ?? ''
            })) ?? [],
         features: entry.fields.features?.map((featureEntry) => ({ id: featureEntry.sys.id, name: featureEntry.fields?.name || '' })) ?? [],
         specifications:
            entry.fields.specifications?.map((specification) => ({
               name: specification.fields?.name ?? '',
               type: specification.fields?.type ?? '',
               variation: specification.fields?.variation ?? ''
            })) ?? [],
         form: entry.fields.form?.fields?.name ?? '',
         isPreferred: entry.fields.isPreferred,
         quality: { id: entry.fields.quality.sys.id, name: entry.fields.quality.fields.name },
         ulPortfolio: entry.fields.ulPortfolio ?? false,
         ulProductId: entry.fields.ulProductId || undefined,
         modifiedAt: new Date(entry.fields.modifiedAt),
         sustainabilitySubGroup: entry.fields.sustainability?.fields
            ? {
                 id: entry.fields.sustainability.sys.id,
                 name: entry.fields.sustainability.fields.name,
                 sustainabilityGroup: entry.fields.sustainability.fields?.sustainabilityGroup?.fields
                    ? {
                         id: entry.fields.sustainability.fields.sustainabilityGroup.sys.id,
                         name: entry.fields.sustainability.fields.sustainabilityGroup.fields?.name ?? ''
                      }
                    : undefined,
                 labelIconPair: entry.fields.sustainability.fields?.labelIconPair?.fields
              }
            : undefined,
         seoMetadata: { noFollow: entry.fields.seoMetadata?.fields?.noFollow }
      };
   }

   static normalizeEntryContentfulOrderProduct(entry: Entry<ContentfulProductEntry>): ContentfulOrderProduct {
      return {
         id: entry.sys.id,
         slug: entry.fields.slug ?? '',
         brand: {
            sys: { id: entry.fields.brand?.sys.id ?? '' },
            slug: entry.fields.brand?.fields?.slug ?? '',
            name: '',
            excerpt: '',
            producer: {
               sys: { id: entry.fields.brand?.fields?.producer?.sys.id ?? '' },
               name: entry.fields.brand?.fields?.producer?.fields?.name ?? '',
               logo: { url: entry.fields.brand?.fields?.producer?.fields?.logo?.fields?.file?.url ?? '' },
               slug: entry.fields.brand?.fields?.producer?.fields?.slug ?? ''
            },
            countries: []
         }
      };
   }

   static async fetchProductById(productId: string, accessToken?: string): Promise<ProductDetail | undefined> {
      const { apiBaseUrl, apiToken } = ConfigService.getServerConfig();
      const url = `${apiBaseUrl}/products/${productId}`;
      return fetch(url, {
         method: 'GET',
         headers: accessToken
            ? {
                 'Content-Type': 'application/json',
                 'User-Agent': 'Ravago-bot-eui9K6Lq-dp',
                 'X-ravago-authenticationToken': accessToken,
                 'x-api-key': apiToken
              }
            : { 'Content-Type': 'application/json', 'x-api-key': apiToken },
         cache: 'no-cache'
      })
         .then((response) => response.json())
         .then((data) => {
            if (data.error) {
               return undefined;
            }
            return data;
         })
         .catch(() => undefined);
   }

   static async getProductCallOffOrderColorsById(productId: string, accessToken: string): Promise<CallOffOrderColor[]> {
      const { apiBaseUrlV2 } = ConfigService.getServerConfig();

      const url = `${apiBaseUrlV2}/customer/products/${productId}/call-off-order-colors`;
      return fetch(url, {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json',
            'User-Agent': 'Ravago-bot-eui9K6Lq-dp',
            'X-ravago-authenticationToken': accessToken
         },
         cache: 'no-cache'
      })
         .then((response) => {
            if (!response.ok) return [];
            return response.json() as unknown as CallOffOrderColor[];
         })
         .catch(() => []);
   }

   static async getProductQuotedColorsById(productId: string, accessToken: string): Promise<QuotedColor[]> {
      const { apiBaseUrlV2 } = ConfigService.getServerConfig();

      const url = `${apiBaseUrlV2}/customer/products/${productId}/quoted-colors`;
      return fetch(url, {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json',
            'User-Agent': 'Ravago-bot-eui9K6Lq-dp',
            'X-ravago-authenticationToken': accessToken
         },
         cache: 'no-cache'
      })
         .then((response) => {
            if (!response.ok) return [];
            return response.json() as unknown as QuotedColor[];
         })
         .catch(() => []);
   }

   static async downloadProductComparison(productsUlIds: string[], apiConnect: ApiConnect) {
      let docType = 'application/pdf';
      const headers = new Headers();
      headers.append('X-Api-Token', apiConnect.ulProspectorApiToken);
      headers.append('X-IBM-Client-Id', apiConnect.clientId);
      headers.append('X-IBM-Client-Secret', apiConnect.clientSecret);

      let url = `${apiConnect.apiConnectUrl}/datasheet?language=EN&unitSystem=0&`;

      uniq(productsUlIds).forEach((id) => {
         if (id) {
            if (url.includes('entryIds=')) {
               url = `${url}~${id}`;
            } else {
               url = `${url}entryIds=${id}`;
            }
         }
      });

      return fetch(url, {
         method: 'GET',
         headers
      })
         .then((response) => {
            if (!response.ok) {
               throw new Error('Failed to fetch datasheet');
            }
            return response.blob();
         })
         .then((blob) => {
            docType = blob.type;
            return blob.arrayBuffer();
         })
         .then((buffer) => {
            const bufferedDoc = Buffer.from(buffer).toString('base64');
            return `data:${docType};base64,${bufferedDoc}`;
         });
   }

   static transformToProductCard(assetBasePath: string, toTransform: ContentfulProduct | OverviewPageProductItem, acceptWebp?: boolean): ProductCard {
      const productCard: ProductCard = {
         id: `${toTransform.id}`,
         slug: toTransform.slug,
         name: toTransform.name,
         producer: '',
         polymerGroup: toTransform.polymerGroup ?? '',
         fillers: [],
         technologies: toTransform.processes?.map(({ name }) => name) ?? [],
         features: [],
         quality: '',
         isPreferred: toTransform.isPreferred,
         ...this.getProductCardImage(toTransform, assetBasePath, acceptWebp)
      };

      if ('qualifier' in toTransform) {
         productCard.producer = toTransform.producer;
         productCard.fillers =
            toTransform.fillers?.map(({ name, percentage }) => `${typeof percentage !== undefined ? `${percentage}% ` : ''}${name}`) ?? [];
         productCard.features = toTransform.features ?? [];
         productCard.quality = toTransform.quality ?? '';
         productCard.compareId = `product-${toTransform.id}`;
         if (
            toTransform.sustainabilityGroup &&
            toTransform.sustainabilityGroup.subSustainability &&
            toTransform.sustainabilityGroup.subSustainability.icon
         ) {
            productCard.sustainability = {
               label: toTransform.sustainabilityGroup.subSustainability.label,
               icon: toTransform.sustainabilityGroup.subSustainability.icon
            };
         }
      } else {
         productCard.producer = toTransform?.brand?.producer?.name ?? '';
         productCard.fillers = toTransform.fillers?.map(({ filler, percentage }) => `${percentage ? `${percentage} ` : ''}${filler.name}`) ?? [];
         productCard.features = toTransform.features?.map((feature) => feature.name) ?? [];
         productCard.quality = toTransform.quality?.name ?? '';
         productCard.compareId = toTransform.id;
         productCard.noFollow = toTransform.seoMetadata?.noFollow;
         productCard.sustainability = toTransform.sustainabilitySubGroup
            ? {
                 label: toTransform.sustainabilitySubGroup?.labelIconPair?.label,
                 icon: toTransform.sustainabilitySubGroup?.labelIconPair?.icon
              }
            : undefined;
      }

      return productCard;
   }

   private static getProductImageFromOverviewProduct(product: OverviewPageProductItem) {
      if (product.imageUrl) return product.imageUrl;
      if (product.segments?.length) return product.segments[0].imageURL;
      if (!product.industries) return undefined;

      for (let i = 0; i < product.industries.length; i += 1) {
         const industry = product.industries[i];
         for (let j = 0; j < industry.segments.length; j += 1) {
            const segment = industry.segments[j];
            if (segment.imageURL) return segment.imageURL;
         }
      }

      return undefined;
   }

   private static getProductCardImage(
      product: ContentfulProduct | OverviewPageProductItem,
      assetBasePath: string,
      acceptWebp?: boolean
   ): {
      image: string;
      backgroundColor?: 'primary' | 'secondary';
   } {
      if ('qualifier' in product) {
         const imageUrl = this.getProductImageFromOverviewProduct(product);
         if (imageUrl) return { image: AssetUtils.optimizeImageIfRequired(imageUrl, acceptWebp, 400) };

         return { image: `${assetBasePath}/images/placeholders/default-banner-small.${acceptWebp ? 'webp' : 'png'}`, backgroundColor: 'primary' };
      }

      if (product.image?.url) return { image: product.image.url };

      if (product.segments.length && product.segments[0].image?.url)
         return { image: AssetUtils.optimizeImageIfRequired(product.segments[0].image?.url, acceptWebp, 400) };

      return { image: `${assetBasePath}/images/placeholders/default-banner-small.${acceptWebp ? 'webp' : 'png'}`, backgroundColor: 'primary' };
   }
}

/* c8 ignore end */
