import { FC, useState } from 'react';

import { DisplayOrder, OrderLineOpenedFrom } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';

import { useCallOffAgreementContext } from '../../../context/call-off-agreement-context';
import { useOrderLineContext } from '../../../context/order-line-context';
import { useFlyoutContext } from '../../../hooks/use-flyout-context';
import { getLanguageCode } from '../../../utils/locale/locale.utils';

import { DesktopOrderLinesTable } from './desktop-order-lines-table';
import { MobileOrderLinesTable } from './mobile-order-lines-table';

interface OrderLinesTableProps {
   orderLines: DisplayOrder[];
   isConsumer: boolean;
   locale: string;
   shipmentBaseUrl: string;
   openedFrom: OrderLineOpenedFrom;
   acceptWebp?: boolean;
   orderDetailBaseUrl?: string;
   callOffAgreementBaseUrl?: string;
   itemsPerPage?: number;
}

export const OrderLinesTable: FC<OrderLinesTableProps> = ({
   orderLines,
   locale,
   itemsPerPage = 5,
   isConsumer,
   acceptWebp,
   shipmentBaseUrl,
   orderDetailBaseUrl,
   callOffAgreementBaseUrl,
   openedFrom
}) => {
   const language = getLanguageCode(locale);
   const { dispatch: flyoutDispatch } = useFlyoutContext();
   const { dispatch: orderLineDispatch } = useOrderLineContext();
   const { dispatch: callOffAgreementDispatch } = useCallOffAgreementContext();

   const [page, setPage] = useState(1);

   const onPageChange = (newPage: number) => {
      setPage(newPage);
   };

   const totalTableItems = itemsPerPage;
   const startIndex = (page - 1) * totalTableItems;
   const endIndex = startIndex + totalTableItems;

   const onOrderLineButtonClick = (orderLine: DisplayOrder) => {
      if (isConsumer && orderLineDispatch && callOffAgreementDispatch) {
         if (orderLine.isCallOff) {
            callOffAgreementDispatch({
               type: 'set_call_off_id',
               value: { callOffId: orderLine.orderNumber }
            });
            flyoutDispatch?.({
               type: 'open_flyout',
               value: { flyoutType: 'call-off-agreement', title: 'Call off agreement' }
            });
            return;
         }
         const [orderId, orderNumber] = orderLine.orderNumber.split('-').map(Number);

         orderLineDispatch({
            type: 'set_order',
            value: {
               orderId,
               orderNumber,
               contentfulProduct: orderLine.contentfulProduct,
               openedFrom,
               shipmentId: orderLine.shipmentIds && orderLine.shipmentIds.length > 0 ? orderLine.shipmentIds[0] : undefined
            }
         });

         flyoutDispatch?.({
            type: 'open_flyout',
            value: { flyoutType: 'order-line', title: 'Order line' }
         });
      }
   };

   return (
      <Box margin={{ top: 'md' }} direction="column" gap="sm" width="100%">
         <BreakpointSwitch includeViews={['desktop']}>
            <DesktopOrderLinesTable
               orderLines={orderLines}
               acceptWebp={acceptWebp}
               language={language}
               locale={locale}
               onOrderLineButtonClick={onOrderLineButtonClick}
               startIndex={startIndex}
               endIndex={endIndex}
               tableViewItems={totalTableItems}
               shipmentBaseUrl={shipmentBaseUrl}
               openedFrom={openedFrom}
               orderDetailBaseUrl={orderDetailBaseUrl}
               callOffAgreementBaseUrl={callOffAgreementBaseUrl}
               onPageChange={onPageChange}
               page={page}
            />
         </BreakpointSwitch>
         <BreakpointSwitch includeViews={['phone', 'tab-port', 'tab-land']}>
            <MobileOrderLinesTable
               orderLines={orderLines}
               language={language}
               locale={locale}
               onOrderLineButtonClick={onOrderLineButtonClick}
               startIndex={startIndex}
               endIndex={endIndex}
               acceptWebp={acceptWebp}
               openedFrom={openedFrom}
               tableViewItems={totalTableItems}
               shipmentBaseUrl={shipmentBaseUrl}
               orderDetailBaseUrl={orderDetailBaseUrl}
               callOffAgreementBaseUrl={callOffAgreementBaseUrl}
               onPageChange={onPageChange}
               page={page}
            />
         </BreakpointSwitch>
      </Box>
   );
};
