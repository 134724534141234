import { SVGProps } from 'react';

export const OpenInvoicesNotFoundIllustration = (props: SVGProps<SVGSVGElement>) => (
   <svg viewBox="0 0 286 341" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: '100%', height: '100%' }} {...props}>
      <g clipPath="url(#clip0_2007_48364)">
         <path
            d="M110.77 279.88C105.08 274.008 96.8328 270.64 88.1446 270.64L87.8095 270.641C82.0868 270.643 76.4662 269.125 71.5226 266.241L71.0082 265.941L71.4225 265.514C73.6656 263.192 75.5386 260.539 76.9759 257.647C75.9551 258.6 74.7376 259.316 73.4096 259.745C72.0816 260.174 70.6755 260.306 69.2909 260.131C67.829 259.957 66.4256 259.454 65.1859 258.659C63.9462 257.865 62.9025 256.8 62.1329 255.544C59.0963 250.615 55.4766 243.62 55.4766 238.292C55.4813 233.788 56.4239 229.335 58.2442 225.215C60.0645 221.096 62.7226 217.402 66.0491 214.367C68.1551 212.467 69.8372 210.145 70.9859 207.551C72.1345 204.957 72.7238 202.15 72.7154 199.313L72.7137 198.909C72.7131 195.174 73.1694 191.453 74.0722 187.828L74.1639 187.462L74.5402 187.476C76.9673 187.558 79.3956 187.355 81.7751 186.869C79.5431 186.595 77.3597 186.014 75.2867 185.143L74.8818 184.979L75.0192 184.564C84.4913 178.696 105.872 181.735 118.283 153.327C128.346 130.292 163.852 173.775 163.852 198.909C163.852 201.474 161.315 204.863 163.214 206.558C183.743 224.882 169.578 236.649 165.112 248.489C164.053 251.298 166.35 254.382 166.35 257.422C166.35 257.937 166.337 258.47 166.311 259.004L166.272 259.825L165.587 259.369C164.233 258.482 162.978 257.452 161.843 256.297C163.02 259.007 163.575 261.948 163.467 264.901C163.358 267.855 162.589 270.746 161.216 273.363C159.065 277.426 156.983 280.334 155.03 282.007C148.772 287.365 140.705 290.129 132.478 289.733C124.251 289.338 116.486 285.813 110.77 279.88Z"
            fill="#E6E6E6"
         />
         <path
            d="M145.141 341C145.281 341 145.416 340.949 145.522 340.858C145.627 340.766 145.696 340.639 145.716 340.5C145.741 340.327 148.16 322.866 145.987 299.661C143.98 278.23 137.535 247.363 118.228 222.011C118.181 221.951 118.124 221.899 118.058 221.861C117.992 221.822 117.919 221.797 117.843 221.787C117.767 221.777 117.69 221.782 117.616 221.801C117.542 221.821 117.473 221.854 117.412 221.901C117.351 221.947 117.3 222.005 117.262 222.071C117.224 222.137 117.199 222.21 117.188 222.286C117.178 222.362 117.183 222.439 117.203 222.513C117.222 222.587 117.256 222.656 117.303 222.717C136.443 247.849 142.836 278.49 144.829 299.77C146.99 322.84 144.589 340.164 144.565 340.336C144.553 340.419 144.559 340.503 144.583 340.583C144.606 340.663 144.647 340.737 144.701 340.8C144.756 340.863 144.824 340.913 144.899 340.948C144.975 340.982 145.058 341 145.141 341Z"
            fill="#3F3D56"
         />
         <path
            d="M129.385 257.77C129.508 257.771 129.628 257.732 129.727 257.66C129.827 257.587 129.901 257.486 129.939 257.369C129.977 257.252 129.977 257.126 129.939 257.009C129.901 256.892 129.827 256.79 129.727 256.718C124.226 252.992 118.283 249.966 112.035 247.707C102.274 244.132 87.43 240.89 72.1488 245.756C72.0759 245.779 72.0082 245.816 71.9497 245.866C71.8912 245.915 71.8429 245.975 71.8077 246.043C71.7725 246.111 71.751 246.185 71.7445 246.261C71.738 246.338 71.7466 246.414 71.7698 246.487C71.793 246.56 71.8303 246.628 71.8797 246.686C71.929 246.745 71.9894 246.793 72.0574 246.828C72.1253 246.863 72.1995 246.884 72.2757 246.891C72.3519 246.897 72.4286 246.888 72.5014 246.865C87.4695 242.099 102.044 245.287 111.635 248.8C117.783 251.019 123.63 253.996 129.044 257.659C129.143 257.732 129.262 257.771 129.385 257.77Z"
            fill="#3F3D56"
         />
         <path
            d="M155.998 191.454C156.007 191.332 155.977 191.209 155.912 191.105C155.848 191.001 155.751 190.919 155.638 190.873C155.524 190.827 155.398 190.818 155.279 190.847C155.16 190.876 155.053 190.943 154.974 191.037C150.863 196.258 147.418 201.971 144.717 208.043C140.449 217.527 136.149 232.105 139.899 247.705C139.917 247.779 139.949 247.849 139.994 247.911C140.039 247.973 140.096 248.026 140.161 248.066C140.226 248.105 140.298 248.132 140.374 248.144C140.449 248.156 140.526 248.153 140.601 248.135C140.675 248.117 140.745 248.085 140.807 248.04C140.869 247.995 140.921 247.938 140.961 247.873C141.001 247.808 141.027 247.735 141.039 247.66C141.051 247.584 141.048 247.507 141.03 247.433C137.357 232.153 141.584 217.839 145.777 208.521C148.433 202.546 151.821 196.925 155.863 191.787C155.942 191.693 155.99 191.577 155.998 191.454Z"
            fill="#3F3D56"
         />
         <path
            d="M268.156 340.384H221.867L221.668 340.023C221.076 338.949 220.505 337.811 219.971 336.642C215.203 326.429 213.187 314.748 211.409 304.443L210.07 296.675C209.908 295.734 210.03 294.766 210.421 293.895C210.812 293.024 211.453 292.29 212.264 291.786C213.074 291.282 214.016 291.031 214.97 291.066C215.923 291.101 216.845 291.42 217.616 291.982C224.651 297.104 231.688 302.22 238.729 307.332C241.394 309.272 244.439 311.518 247.354 313.945C247.636 312.579 247.93 311.208 248.224 309.856C248.427 308.922 248.904 308.071 249.593 307.41C250.283 306.749 251.153 306.309 252.094 306.146C253.035 305.984 254.003 306.105 254.874 306.496C255.746 306.887 256.48 307.529 256.985 308.34L262.401 317.046C266.351 323.403 269.837 329.668 269.127 336.425C269.13 336.507 269.124 336.59 269.108 336.67C268.966 337.78 268.703 338.871 268.322 339.923L268.156 340.384Z"
            fill="#F0F0F0"
         />
         <path
            d="M246.59 158.312L237.657 125.23C237.274 123.83 236.357 122.636 235.102 121.907C233.847 121.177 232.356 120.97 230.951 121.331C230.224 121.518 229.542 121.85 228.948 122.309C228.353 122.768 227.858 123.343 227.493 123.999C227.128 124.655 226.901 125.379 226.824 126.126C226.748 126.874 226.824 127.629 227.049 128.345L237.382 162.425L242.788 183.397C242.001 183.999 241.417 184.828 241.118 185.773C240.818 186.717 240.816 187.732 241.112 188.677C241.409 189.623 241.989 190.455 242.774 191.059C243.559 191.663 244.511 192.011 245.501 192.055C246.49 192.1 247.47 191.838 248.305 191.306C249.141 190.774 249.793 189.997 250.172 189.082C250.552 188.166 250.64 187.156 250.426 186.188C250.211 185.221 249.704 184.343 248.973 183.673L246.59 158.312Z"
            fill="#A0616A"
         />
         <path
            d="M171.417 151.188L198.56 130.288C199.708 129.399 200.46 128.094 200.654 126.655C200.848 125.217 200.47 123.759 199.6 122.597C199.149 121.996 198.582 121.493 197.931 121.119C197.28 120.745 196.56 120.507 195.814 120.42C195.068 120.333 194.313 120.399 193.594 120.614C192.874 120.829 192.206 121.188 191.63 121.67L164.1 144.245L146.794 157.256C145.937 156.758 144.948 156.536 143.961 156.62C142.974 156.703 142.037 157.089 141.276 157.724C140.516 158.36 139.97 159.214 139.711 160.171C139.453 161.128 139.495 162.141 139.833 163.073C140.17 164.005 140.786 164.81 141.597 165.38C142.407 165.949 143.374 166.255 144.364 166.256C145.355 166.257 146.322 165.953 147.134 165.385C147.946 164.816 148.563 164.012 148.902 163.081L171.417 151.188Z"
            fill="#A0616A"
         />
         <path d="M193.54 155.992L192.343 182.727L229.911 174.558C226.713 163.815 224.539 161.983 226.942 154.507L193.54 155.992Z" fill="#A0616A" />
         <path
            d="M216.639 106.98H204.121L198.746 116.183L196.184 116.042C194.932 115.973 193.678 116.16 192.5 116.592C191.322 117.024 190.244 117.692 189.333 118.555C188.421 119.417 187.695 120.457 187.199 121.61C186.703 122.763 186.447 124.005 186.447 125.261L189.603 130.155C189.603 130.155 183.959 139.996 192.09 144.838C192.09 144.838 190.64 153.96 192.09 153.862C193.54 153.764 196.88 151.908 193.54 153.764C190.2 155.621 190.449 155.87 190.881 156.302L191.313 156.735L226.435 162.635C226.435 162.635 227.462 155.823 227.573 154.608C227.684 153.393 226.942 156.363 227.684 153.393C228.426 150.422 229.54 151.536 228.426 150.422C227.313 149.308 235.64 135.508 232.219 130.155L237.704 124.802C237.704 119.667 229.461 115.504 224.329 115.504H223.672L216.639 106.98Z"
            fill="#E6E6E6"
         />
         <path d="M186.479 330.077H181.413L179.002 310.525L186.48 310.526L186.479 330.077Z" fill="#A0616A" />
         <path
            d="M171.187 337.557C171.19 338.124 171.416 338.668 171.817 339.07C172.218 339.472 172.761 339.7 173.329 339.703H182.866L183.162 339.091L184.511 336.305L185.033 339.091L185.149 339.703H188.746L188.695 339.086L187.736 327.577L186.481 327.5L181.059 327.179L179.727 327.097V330.534C178.716 331.609 170.785 335.676 171.187 337.557Z"
            fill="#2F2E41"
         />
         <path d="M261.776 316.8L257.515 319.543L244.915 304.404L251.204 300.357L261.776 316.8Z" fill="#A0616A" />
         <path
            d="M252.96 331.368C253.269 331.844 253.754 332.179 254.308 332.3C254.863 332.421 255.443 332.318 255.922 332.014L263.943 326.851L263.86 326.176L263.489 323.104L265.434 325.163L265.863 325.616L268.888 323.668L268.511 323.177L261.481 314.018L260.384 314.632L255.65 317.297L254.486 317.949L256.344 320.839C256.076 322.291 251.604 330.004 252.96 331.368Z"
            fill="#2F2E41"
         />
         <path
            d="M285.411 340.741L128.528 340.894C128.372 340.894 128.222 340.831 128.111 340.72C128.001 340.609 127.939 340.458 127.939 340.302C127.939 340.145 128.001 339.995 128.111 339.884C128.222 339.773 128.372 339.71 128.528 339.709L285.411 339.556C285.567 339.557 285.717 339.62 285.828 339.731C285.938 339.842 286 339.992 286 340.149C286 340.305 285.938 340.456 285.828 340.567C285.717 340.678 285.567 340.741 285.411 340.741Z"
            fill="#CACACA"
         />
         <path
            d="M229.322 171.538C229.322 171.538 205.308 184.246 192.607 175.576C192.607 175.576 189.829 189.039 188.715 190.153C187.602 191.267 185.828 191.752 187.086 192.995C188.344 194.238 189.044 195.916 186.653 198.047C184.262 200.179 181.877 199.502 184.183 201.697C186.488 203.892 177.21 257.732 177.21 257.732C177.21 257.732 178.695 307.802 177.21 309.316C175.726 310.83 173.741 311.17 175.661 313.042C177.581 314.915 177.581 313.462 177.581 316.045V318.628L178.46 321.195H190.51C190.51 321.195 193.418 317.815 190.51 314.88C187.602 311.944 191.395 311.141 192.282 308.387C193.169 305.632 194.653 308.603 193.169 305.632C191.684 302.662 190.971 303.227 190.957 301.273C190.942 299.32 212.839 222.829 212.839 222.829L215.491 265.166L247.391 311.531L256.781 304.485C256.781 304.485 249.945 294.857 251.543 293.533C253.142 292.209 246.832 290.549 248.822 289.056C250.811 287.563 246.021 286.058 247.827 284.079C249.632 282.1 245.339 277.111 242.852 277.111C240.365 277.111 236.874 268.795 236.874 268.795L242.085 203.099L229.322 171.538Z"
            fill="#2F2E41"
         />
         <path
            d="M203.87 79.9565C203.7 80.1797 202.346 80.0595 200.718 79.8216C197.499 79.3513 197.06 79.9102 194.653 82.1003C191.167 85.2729 193.149 104.624 191.436 108.816L190.883 109.224C191.98 109.015 193.059 108.713 194.106 108.322C194.697 106.455 195.29 104.582 195.887 102.716C196.451 104.168 196.805 105.692 196.94 107.243C197.768 106.917 198.592 106.117 202.055 105.045C203.799 103.862 216.413 102.672 218.035 102.151C222.15 88.1412 215.518 82.4755 203.87 79.9565Z"
            fill="#2F2E41"
         />
         <path
            d="M208.129 101.56C214.786 101.56 220.183 96.1608 220.183 89.5001C220.183 82.8393 214.786 77.4397 208.129 77.4397C201.471 77.4397 196.074 82.8393 196.074 89.5001C196.074 96.1608 201.471 101.56 208.129 101.56Z"
            fill="#A0616A"
         />
         <path
            d="M215.718 78.8936C215.039 78.7933 214.385 78.5639 213.792 78.2178C213.498 78.0401 213.259 77.7833 213.104 77.4766C212.949 77.17 212.883 76.8258 212.914 76.4835C213.063 75.6266 214.031 75.115 214.899 75.1671C215.763 75.2917 216.584 75.624 217.291 76.1356C217.213 75.3075 216.86 74.5295 216.287 73.9266C215.714 73.3238 214.955 72.931 214.133 72.8114C215.274 72.7499 216.46 72.6956 217.516 73.1348C218.571 73.5739 219.45 74.6613 219.26 75.7888C221.034 75.5601 222.784 76.7969 223.516 78.4297C224.249 80.0625 224.104 81.98 223.518 83.6713C223.461 83.9303 223.348 84.1738 223.188 84.3854C223.028 84.597 222.824 84.7718 222.591 84.8978C221.994 85.1245 221.351 84.7443 220.834 84.3696C218.131 82.4118 215.672 80.139 213.507 77.599"
            fill="#2F2E41"
         />
         <path
            d="M220.974 102.322C220.463 102.563 220.729 103.458 220.332 103.859C220.16 104.029 219.952 104.159 219.723 104.238C219.663 103.491 219.49 102.757 219.207 102.062C219.155 101.936 219.07 101.795 218.936 101.806C218.781 101.825 218.74 102.029 218.736 102.181C218.705 102.936 218.537 103.68 218.242 104.375C218.239 104.383 218.235 104.394 218.231 104.401C218.116 104.398 217.997 104.394 217.886 104.39C217.025 104.357 215.117 102.144 214.694 102.894C216.186 100.247 218.005 96.266 218.283 93.2398C218.38 92.6062 218.333 91.959 218.146 91.346C218.048 91.0411 217.865 90.7705 217.619 90.5665C217.372 90.3625 217.072 90.2335 216.754 90.195C216.597 90.1878 216.441 90.2154 216.296 90.2758C216.151 90.3362 216.022 90.4278 215.916 90.5442C215.811 90.6605 215.733 90.7988 215.687 90.949C215.641 91.0991 215.629 91.2576 215.652 91.4129L215.437 91.3832C213.44 89.7568 215.162 86.9311 212.839 85.8135C212.052 85.4347 207.235 86.7009 206.385 86.4781C206.367 85.5824 206.184 84.6976 205.847 83.8678C205.795 83.7415 205.709 83.6004 205.576 83.6116C205.42 83.6301 205.379 83.8344 205.375 83.9866C205.344 84.7418 205.177 85.4852 204.882 86.1811C203.704 86.014 202.506 86.077 201.352 86.3667C200.335 86.6341 199.37 87.0945 198.35 87.3396C197.845 87.4943 197.315 87.5444 196.79 87.4869C196.265 87.4295 195.758 87.2656 195.299 87.0054C194.004 86.1365 193.777 84.2131 194.423 82.7947C194.891 81.8845 195.522 81.0687 196.286 80.3886C196.869 79.8241 197.474 79.282 198.079 78.7399C198.621 78.2535 199.163 77.7671 199.704 77.2806C200.386 76.6383 201.113 76.0452 201.879 75.5058C203.102 74.7038 204.476 74.1603 205.916 73.9087C207.357 73.6571 208.833 73.7028 210.256 74.0428C211.963 74.4512 214.245 73.8757 215.808 74.674C216.624 75.0936 218.432 76.7014 219.148 77.2732C220.981 78.7325 219.741 77.5434 221.746 78.3871C226.527 80.399 224.413 100.702 220.974 102.322Z"
            fill="#2F2E41"
         />
         <path
            d="M87.5041 190.35C128.791 190.35 162.261 156.863 162.261 115.556C162.261 74.2485 128.791 40.7622 87.5041 40.7622C46.2171 40.7622 12.7473 74.2485 12.7473 115.556C12.7473 156.863 46.2171 190.35 87.5041 190.35Z"
            fill="white"
         />
         <path
            d="M87.5042 191.096C45.8718 191.096 12.0012 157.209 12.0012 115.556C12.0012 73.9027 45.8718 40.0154 87.5042 40.0154C129.137 40.0154 163.007 73.9027 163.007 115.556C163.007 157.209 129.137 191.096 87.5042 191.096ZM87.5042 41.5085C46.6947 41.5085 13.4936 74.726 13.4936 115.556C13.4936 156.385 46.6947 189.603 87.5042 189.603C128.314 189.603 161.515 156.385 161.515 115.556C161.515 74.726 128.314 41.5085 87.5042 41.5085Z"
            fill="var(--brand-color-primary-dark)"
         />
         <path
            d="M77.3058 154.482C76.0148 154.482 74.7416 154.182 73.5871 153.604C72.4325 153.026 71.4282 152.187 70.6539 151.154L50.2591 123.947C49.604 123.073 49.1273 122.078 48.8563 121.02C48.5853 119.962 48.5253 118.86 48.6798 117.779C48.8343 116.697 49.2002 115.657 49.7566 114.716C50.313 113.776 51.049 112.955 51.9226 112.3C52.7962 111.644 53.7904 111.167 54.8482 110.896C55.906 110.625 57.0069 110.565 58.0879 110.72C59.169 110.874 60.209 111.24 61.1486 111.797C62.0882 112.353 62.909 113.09 63.5642 113.964L76.9072 131.762L111.177 80.3327C112.401 78.4982 114.303 77.2251 116.465 76.7932C118.627 76.3613 120.872 76.8059 122.706 78.0294C124.54 79.2529 125.814 81.1551 126.247 83.3179C126.68 85.4806 126.237 87.7268 125.015 89.5628L84.2256 150.777C83.4878 151.885 82.4953 152.798 81.3316 153.443C80.1679 154.087 78.8667 154.443 77.5372 154.48C77.4601 154.481 77.3829 154.482 77.3058 154.482Z"
            fill="var(--brand-color-primary-dark)"
         />
         <path
            d="M164.526 45.4353C164.734 44.0053 163.237 42.6037 161.182 42.3046C159.127 42.0055 157.293 42.9223 157.085 44.3522C156.877 45.7822 158.374 47.1839 160.429 47.4829C162.483 47.782 164.318 46.8652 164.526 45.4353Z"
            fill="#E6E6E6"
         />
         <path
            d="M188.987 43.0471C189.195 41.6172 187.698 40.2155 185.643 39.9165C183.589 39.6174 181.755 40.5341 181.547 41.9641C181.339 43.394 182.836 44.7957 184.89 45.0947C186.945 45.3938 188.779 44.4771 188.987 43.0471Z"
            fill="#3F3D56"
         />
         <path
            d="M157.827 25.6221C158.035 24.1921 156.538 22.7905 154.483 22.4914C152.429 22.1923 150.595 23.109 150.387 24.539C150.179 25.969 151.676 27.3706 153.73 27.6697C155.785 27.9688 157.619 27.052 157.827 25.6221Z"
            fill="#FF6584"
         />
         <path
            d="M147.198 17.506C147.208 16.1932 146.476 15.1234 145.563 15.1165C144.651 15.1096 143.903 16.1682 143.893 17.481C143.883 18.7937 144.615 19.8635 145.527 19.8704C146.44 19.8773 147.188 18.8187 147.198 17.506Z"
            fill="#E6E6E6"
         />
         <path
            d="M143.593 2.38951C143.603 1.07675 142.871 0.00695501 141.959 4.94456e-05C141.046 -0.00685612 140.298 1.05175 140.288 2.3645C140.278 3.67725 141.01 4.74705 141.923 4.75396C142.836 4.76086 143.583 3.70226 143.593 2.38951Z"
            fill="#3F3D56"
         />
         <path
            d="M171.624 28.9935C171.634 27.6808 170.902 26.611 169.989 26.6041C169.076 26.5971 168.328 27.6558 168.318 28.9685C168.308 30.2813 169.04 31.3511 169.953 31.358C170.866 31.3649 171.614 30.3063 171.624 28.9935Z"
            fill="#E6E6E6"
         />
         <path
            d="M159.803 34.8986C159.813 33.5858 159.081 32.516 158.168 32.5091C157.255 32.5022 156.507 33.5608 156.497 34.8736C156.488 36.1863 157.219 37.2561 158.132 37.263C159.045 37.2699 159.793 36.2113 159.803 34.8986Z"
            fill="#FF6584"
         />
         <path
            d="M176.258 45.8124C176.268 44.4996 175.536 43.4298 174.623 43.4229C173.71 43.416 172.962 44.4746 172.953 45.7874C172.943 47.1001 173.675 48.1699 174.587 48.1768C175.5 48.1837 176.248 47.1251 176.258 45.8124Z"
            fill="#3F3D56"
         />
         <path
            d="M24.2376 222.624C24.4455 221.194 22.9486 219.792 20.894 219.493C18.8395 219.194 17.0054 220.111 16.7974 221.541C16.5895 222.971 18.0864 224.372 20.141 224.671C22.1955 224.97 24.0296 224.054 24.2376 222.624Z"
            fill="#E6E6E6"
         />
         <path
            d="M48.6992 220.236C48.9072 218.806 47.4102 217.404 45.3557 217.105C43.3012 216.806 41.467 217.723 41.2591 219.153C41.0511 220.582 42.5481 221.984 44.6026 222.283C46.6572 222.582 48.4913 221.666 48.6992 220.236Z"
            fill="#3F3D56"
         />
         <path
            d="M17.5393 202.81C17.7473 201.38 16.2503 199.979 14.1958 199.68C12.1413 199.381 10.3071 200.297 10.0992 201.727C9.89123 203.157 11.3882 204.559 13.4427 204.858C15.4973 205.157 17.3314 204.24 17.5393 202.81Z"
            fill="#FF6584"
         />
         <path
            d="M6.91007 194.694C6.92 193.382 6.1881 192.312 5.27533 192.305C4.36256 192.298 3.61456 193.357 3.60464 194.669C3.59471 195.982 4.32663 197.052 5.2394 197.059C6.15217 197.066 6.90015 196.007 6.91007 194.694Z"
            fill="#E6E6E6"
         />
         <path
            d="M3.30557 179.578C3.31549 178.265 2.58359 177.195 1.67082 177.189C0.758051 177.182 0.0100647 178.24 0.00014288 179.553C-0.00977898 180.866 0.722121 181.936 1.63489 181.942C2.54766 181.949 3.29564 180.891 3.30557 179.578Z"
            fill="#3F3D56"
         />
         <path
            d="M31.3358 206.182C31.3458 204.869 30.6138 203.8 29.7011 203.793C28.7883 203.786 28.0403 204.844 28.0304 206.157C28.0205 207.47 28.7524 208.54 29.6651 208.547C30.5779 208.554 31.3259 207.495 31.3358 206.182Z"
            fill="#E6E6E6"
         />
         <path
            d="M19.5149 212.087C19.5249 210.774 18.793 209.704 17.8802 209.698C16.9674 209.691 16.2194 210.749 16.2095 212.062C16.1996 213.375 16.9315 214.445 17.8443 214.451C18.757 214.458 19.505 213.4 19.5149 212.087Z"
            fill="#FF6584"
         />
         <path
            d="M35.97 223.001C35.9799 221.688 35.248 220.618 34.3352 220.611C33.4225 220.604 32.6745 221.663 32.6646 222.976C32.6546 224.289 33.3865 225.358 34.2993 225.365C35.2121 225.372 35.9601 224.314 35.97 223.001Z"
            fill="#3F3D56"
         />
      </g>
      <defs>
         <clipPath id="clip0_2007_48364">
            <rect width="286" height="341" fill="white" />
         </clipPath>
      </defs>
   </svg>
);
