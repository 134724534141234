import { DisplayOrder, OrderFilters, ShipmentWithOrderLines } from '@ravago/shared/page-data/models/elements';
import { MultiselectOption } from '@ravago/shared/radiance/components/input-field/components/Multiselect/Multiselect';

import { firstBy } from 'thenby';

export class OrderFilterUtils {
   static getAddressesOptions = (orders: DisplayOrder[]): MultiselectOption[] =>
      orders
         .filter((order) => order.deliveryTermAddress?.id)
         .map((order) => ({
            checked: false,
            id: order.deliveryTermAddress?.id.toString() || '',
            label: order.deliveryTermAddress?.name || '',
            disabled: false
         }))
         .filter((address, index, self) => index === self.findIndex((a) => a.id === address.id));

   static resetAddressMultiSelect = (options: MultiselectOption[]) => {
      const newOptions = [...options];
      newOptions.forEach((option) => {
         option.checked = false;
      });

      return newOptions;
   };

   static onSearch = (query: string | undefined, orders: DisplayOrder[]): DisplayOrder[] => {
      let filteredOrders = [...orders];
      if (query) {
         const word = query.toLowerCase();
         filteredOrders = orders.filter(
            (item) =>
               item.product.description.toLowerCase().includes(word) ||
               item.orderNumber.toLowerCase().includes(word) ||
               item.customerProductReference?.toLowerCase().includes(word) ||
               item.customerOrderReference?.toLowerCase().includes(word) ||
               item.shipmentIds?.some((shipmentId) => String(shipmentId).toLowerCase().includes(word))
         );
      }

      return filteredOrders;
   };

   static onFilter = (filters: OrderFilters, orders: DisplayOrder[], addressesOptions: MultiselectOption[]): DisplayOrder[] =>
      orders
         .filter((order) => OrderFilterUtils.onFilterCalloff(filters.callOff, order))
         .filter((order) => OrderFilterUtils.onFilterDeliveryDateFrom(filters.deliveryDateFrom, order))
         .filter((order) => OrderFilterUtils.onFilterDeliveryDateUntil(filters.deliveryDateTo, order))
         .filter((order) => OrderFilterUtils.onFilterDeliveryAddress(filters.deliveryAddress, order, addressesOptions));

   static onFilterCalloff = (isFiltered: boolean, order: DisplayOrder) => {
      if (isFiltered) {
         return order.isCallOff;
      }
      return true;
   };

   static onFilterDeliveryDateFrom = (date: string, order: DisplayOrder) => {
      if (date !== '') {
         const fromDateSelected = new Date(date).getTime();

         const fromDateOrder = new Date(order.deliveryDate.from).getTime();

         return fromDateOrder >= fromDateSelected;
      }
      return true;
   };

   static onFilterDeliveryDateUntil = (date: string, order: DisplayOrder) => {
      if (date !== '') {
         const untilDateSelected = new Date(date).getTime();

         const untilDateOrder = new Date(order.deliveryDate.until ?? order.deliveryDate.from).getTime();

         return untilDateOrder <= untilDateSelected;
      }
      return true;
   };

   static onFilterDeliveryAddress = (isFiltered: boolean, order: DisplayOrder, addressesOptions: MultiselectOption[]) => {
      if (isFiltered) {
         const deliveryAddressFiltered = addressesOptions.filter(
            (address: MultiselectOption) => address.checked && address.id === order.deliveryTermAddress?.id.toString()
         );

         if (deliveryAddressFiltered.length > 0) {
            return order;
         }
         return false;
      }
      return true;
   };

   static onFilterShipmentOrders = (
      shipmentOrders: ShipmentWithOrderLines[],
      query: string | undefined,
      filters: OrderFilters,
      addressesOptions: MultiselectOption[]
   ): ShipmentWithOrderLines[] => {
      const filteredShipmentOrderLines: ShipmentWithOrderLines[] = [];

      for (let i = 0; i < shipmentOrders.length; i += 1) {
         const searchData = OrderFilterUtils.onSearch(query, shipmentOrders[i].orderLines);
         const filteredData = OrderFilterUtils.onFilter(filters, searchData, addressesOptions);

         if (filteredData.length > 0) {
            filteredShipmentOrderLines.push({
               shipmentId: shipmentOrders[i].shipmentId,
               orderLines: filteredData
            });
         }
      }

      return filteredShipmentOrderLines;
   };

   static sortAddressesOptions = (options: MultiselectOption[]): MultiselectOption[] =>
      options.sort(firstBy('disabled', 'asc').thenBy((o) => o.label.toUpperCase()));
}
