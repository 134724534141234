'use client';

import { FC, useEffect, useMemo, useState } from 'react';

import { useAuthenticationContext, UserGroups } from '@ravago/shared/authentication';
import { UserPermissions } from '@ravago/shared/page-data/models/config';
import { CallOffOrderColor, ProductDetail, QuotedColor } from '@ravago/shared/page-data/models/elements';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Alert } from '@ravago/shared/radiance/components/alert/components/Alert/Alert';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Link } from '@ravago/shared/radiance/components/link/components/Link/Link';
import { Paper } from '@ravago/shared/radiance/components/paper/components/Paper/Paper';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { Sublevel } from '@ravago/shared/radiance/components/sublevel/components/Sublevel/Sublevel';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import { usePathname, useSearchParams } from 'next/navigation';

import { useBottomContainerContext } from '../../../../context/bottom-container-context';
import { useFlyoutContext } from '../../../../hooks/use-flyout-context';
import { useRequestForQuotationContext } from '../../../../hooks/use-request-for-quotation-context';
import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { RequestForQuotationState } from '../../../../reducers/request-for-quotation-reducer';
import { DynamicProductBlockUtils } from '../../../../utils/dynamic-product-block/dynamic-product-block.utils';
import { DynamicProductBlockCallOffFields } from '../../../shared/dynamic-product-block/dynamic-product-block-call-off-fields';
import { DynamicProductBlockQuotedColorFields } from '../../../shared/dynamic-product-block/dynamic-product-block-quoted-color-fields';
import { ColorPickerValue, ExtendedColorPicker } from '../../../shared/extended-color-picker/extended-color-picker';

import { QuoteFlyout } from './quote-flyout';
import RequestForQuotationBottomSheet from './request-for-quotation-bottom-sheet';
import { RequestForQuotationButton } from './request-for-quotation-button';

type Props = {
   dynamicPage: { type: string; id: string; extraId?: string; isTailCatalog?: boolean };
   globals: RendererGlobals;
   productDetail: ProductDetail | undefined;
   callOffOrderColors: CallOffOrderColor[];
   quotedColors: QuotedColor[];
   token?: string;
   user?: any;
   defaultColor: RequestForQuotationState['color'];
   userPermissions: UserPermissions;
};

export const RequestForQuotation: FC<Props> = ({
   dynamicPage,
   globals,
   callOffOrderColors,
   quotedColors,
   productDetail,
   userPermissions,
   user,
   defaultColor,
   token
}) => {
   const translate = useTranslations();
   const {
      locale,
      actions: { getCallOffOrderDetail }
   } = globals;
   const { state: requestForQuotationState, dispatch: requestForQuotationDispatch } = useRequestForQuotationContext();
   const { login } = useAuthenticationContext();

   const { desktop } = useBreakpoint();
   const { dispatch: bottomSheetDispatch } = useBottomContainerContext();
   const { dispatch: flyoutDispatch, state: flyoutState } = useFlyoutContext();
   const [flyoutOpen, setFlyoutOpen] = useState(false);

   const searchParams = useSearchParams();
   const pathname = usePathname();
   const hasPermission = DynamicProductBlockUtils.hasPermission(user, userPermissions);
   const [isLoaded, setIsLoaded] = useState(false);

   const selectedCallOffOrderColor = useMemo(
      () => callOffOrderColors?.find((color) => color.recommendedCallOff.id === requestForQuotationState.color.callOffOrderId),
      [callOffOrderColors, requestForQuotationState.color.callOffOrderId]
   );

   const selectedQuotedColor = useMemo(
      () =>
         quotedColors?.find(
            (color) =>
               requestForQuotationState.color.isQuotedColor &&
               color.color.id === requestForQuotationState.color.colorId &&
               color.color.producerColorCode === requestForQuotationState.color.producerColorCode
         ),
      [quotedColors, requestForQuotationState.color]
   );

   useEffect(() => {
      requestForQuotationDispatch?.({
         type: 'set_data',
         value: {
            defaultColor,
            product: productDetail,
            callOffOrderColors,
            quotedColors,
            userPermissions
         }
      });
      setIsLoaded(true);
   }, []);

   const handleLoginClick = () => {
      if (typeof window !== 'undefined') {
         localStorage.setItem('navigationPendingScrollPosition', window.scrollY.toString());
      }

      const fullUrl = `${pathname}?${searchParams.toString()}`;
      login(fullUrl);
   };

   const handleColorChange = (value: ColorPickerValue) => {
      if (requestForQuotationDispatch) {
         requestForQuotationDispatch({ type: 'set_color', value: { color: { ...value, quantity: undefined } } });
      }
   };

   const productBlockTitle = useMemo(() => {
      if (
         (requestForQuotationState.color.isQuotedColor || requestForQuotationState.color.callOffOrderId) &&
         (quotedColors.length > 0 || callOffOrderColors.length > 0)
      ) {
         return 'request-for-quotation.order-now';
      }
      return 'request-for-quotation.request-quote';
   }, [userPermissions, quotedColors, callOffOrderColors, requestForQuotationState.color]);

   const handleCommentClicked = () => {
      setFlyoutOpen(true);
   };

   useEffect(() => {
      if (searchParams.get('flyout') === 'open' && flyoutState.idOpenDialog !== 'request-for-quotation') {
         flyoutDispatch?.({
            type: 'open_flyout',
            value: { flyoutType: 'request-for-quotation', title: productDetail?.description }
         });
      }
   }, [userPermissions, searchParams, productDetail, callOffOrderColors]);

   useEffect(() => {
      if (!desktop) {
         const expandBottomSheet = () => {
            bottomSheetDispatch?.({
               type: 'expand_bottom_sheet'
            });
         };

         bottomSheetDispatch?.({
            type: 'show_bottom_sheet',
            bottomSheet: {
               header: (
                  <Box items="center" justify="between">
                     <Typography variant="headline">{translate('request-for-quotation.request-quote')}</Typography>
                     <IconButton icon="chevron-up" onClick={() => expandBottomSheet()} />
                  </Box>
               ),
               children: (
                  <RequestForQuotationBottomSheet
                     locale={locale}
                     hasPermission={hasPermission}
                     token={token}
                     user={user}
                     onCommentClicked={handleCommentClicked}
                     getCallOffOrderDetail={getCallOffOrderDetail}
                  />
               ),
               options: {
                  closeOnOutsideClick: true
               }
            }
         });
      } else {
         bottomSheetDispatch?.({ type: 'hide_bottom_sheet' });
      }
   }, [desktop, bottomSheetDispatch]);

   return (
      <>
         <BreakpointSwitch includeViews={['desktop']} useContainerQuery>
            <Paper border={false} shadow>
               <Box direction="column" gap="lg" spacing={{ x: 'lg', y: 'xl' }}>
                  {isLoaded ? (
                     <>
                        <Box justify="between" direction="row">
                           <Typography component="h4" variant="heading-4" color="strong">
                              {translate(productBlockTitle)}
                           </Typography>
                           <Badge leadingIcon="bolt" variant="success">
                              {translate('request-for-quotation.fast-response')}
                           </Badge>
                        </Box>
                        {dynamicPage.isTailCatalog ? (
                           <Box direction="column" gap="lg">
                              <Alert
                                 variant="error"
                                 headline={
                                    locale
                                       ? translate('request-for-quotation.tail-catalog-product')
                                       : 'Please note that this product is currently unavailable for guaranteed delivery, and therefore, we are unable to provide quotes at this time.'
                                 }
                              />
                              <Button
                                 data-testid="rfq-button__start"
                                 fullWidth
                                 trailingIcon="long-arrow-right"
                                 disabled
                                 variant="primary"
                                 id="start-request-quote-btn"
                              >
                                 {locale ? translate('request-for-quotation.start-quote-request') : 'Start quote'}
                              </Button>
                           </Box>
                        ) : (
                           <Box direction="column">
                              {!user && !userPermissions.hasAnonymousPermission && (
                                 <Typography component="b" variant="body">
                                    {translate('request-for-quotation.anonymous-user')}
                                 </Typography>
                              )}
                              {user?.groups?.includes(UserGroups.digitalPlatformPendingVerification) && !userPermissions.hasPendingPermission && (
                                 <Typography component="b" variant="body">
                                    {translate('request-for-quotation.pending-user')}
                                 </Typography>
                              )}
                              {user?.groups?.includes(UserGroups.digitalPlatformGuest) && !userPermissions.hasGuestPermission && (
                                 <Typography component="b" variant="body">
                                    {translate('request-for-quotation.guest-user')}
                                 </Typography>
                              )}
                              {userPermissions.hasAnonymousPermission && (
                                 <Box>
                                    <Sublevel>
                                       <Box direction="column" gap="xs">
                                          <Typography component="span" variant="body">
                                             {translate('request-for-quotation.login-message')}
                                          </Typography>
                                          <Box>
                                             {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                             <Link onClick={handleLoginClick} color="dark">
                                                {translate('request-for-quotation.login')}
                                             </Link>
                                          </Box>
                                       </Box>
                                    </Sublevel>
                                 </Box>
                              )}
                              {hasPermission && productDetail && productDetail?.colors && productDetail?.packagingGroups ? (
                                 <>
                                    <ExtendedColorPicker
                                       id="dynamic-product-block-color-picker"
                                       colors={productDetail.colors}
                                       callOffOrderColors={callOffOrderColors}
                                       quotedColors={quotedColors}
                                       value={requestForQuotationState.color}
                                       onChange={handleColorChange}
                                    />
                                    {selectedCallOffOrderColor && token && (
                                       <DynamicProductBlockCallOffFields
                                          id="dynamic-product-block-call-off-fields"
                                          callOffOrderColor={selectedCallOffOrderColor}
                                          getCallOffOrderDetail={getCallOffOrderDetail}
                                          token={token}
                                       />
                                    )}
                                    {selectedQuotedColor && token && (
                                       <DynamicProductBlockQuotedColorFields
                                          quotedColor={selectedQuotedColor}
                                          token={token}
                                          onCommentClicked={handleCommentClicked}
                                       />
                                    )}
                                 </>
                              ) : (
                                 <Alert
                                    variant="error"
                                    headline={
                                       locale
                                          ? translate('request-for-quotation.product-not-available')
                                          : 'It is not possible to request a quote for this product at the moment. Please try again later.'
                                    }
                                 />
                              )}
                           </Box>
                        )}
                        <Box direction="column">
                           <RequestForQuotationButton
                              locale={locale}
                              product={productDetail}
                              userPermissions={userPermissions}
                              user={user}
                              isFlyoutOpen={searchParams.get('flyout') === 'open'}
                              isDisabled={hasPermission && productDetail && !(productDetail?.colors && productDetail?.packagingGroups)}
                           />
                        </Box>
                     </>
                  ) : (
                     <Box direction="column" items="center">
                        <Spinner />
                     </Box>
                  )}
               </Box>
            </Paper>
         </BreakpointSwitch>

         {selectedQuotedColor && (
            <QuoteFlyout
               locale={locale}
               product={productDetail}
               color={requestForQuotationState.color}
               quotedColor={selectedQuotedColor}
               open={flyoutOpen}
               onFlyoutClose={() => setFlyoutOpen(false)}
            />
         )}
      </>
   );
};
