import { ContentfulCollectionName } from '@ravago/shared/page-data/models/contentful';
import { BaseQuery, RendererGlobals } from '@ravago/shared/page-data/models/utils';

export const supportedContentTypesForCaseStudyList: ContentfulCollectionName[] = ['industry', 'product', 'brand'];

export const getFilterQueryForCaseStudies = (query: BaseQuery, globals: RendererGlobals): string => {
   const { filterBy } = query;
   const { contentType, contentEntity } = globals;
   switch (filterBy) {
      case 'dynamic':
         if (!contentType || !supportedContentTypesForCaseStudyList.includes(contentType) || !(contentEntity as any)?.fields?.name) return '';
         if (contentType === 'product') return `&search=${encodeURIComponent((contentEntity as any)?.fields?.name)}`;
         if (contentType === 'industry') return `&markets=${encodeURIComponent((contentEntity as any)?.fields?.name)}`;
         if (contentType === 'brand') return `&brands=${encodeURIComponent((contentEntity as any)?.fields?.name)}`;
         return '';
      default:
         return '';
   }
};
