import { CurrencyTransaction, InvoiceForCurrencyForLegalEntity } from '@ravago/shared/page-data/models/elements';

import { calculateTotalPositiveDueAmount, calculateTotalOpenAmount } from './invoice-amount.utils';

export const addTotalsToCurrencyInvoice = (currencyInvoice: CurrencyTransaction) => ({
   ...currencyInvoice,
   totalAmount: calculateTotalOpenAmount(currencyInvoice.transactions),
   totalOverDue: calculateTotalPositiveDueAmount(currencyInvoice.transactions),
   numberOfInvoices: currencyInvoice.transactions.length
});

export const addInvoicesTotals = (invoices: InvoiceForCurrencyForLegalEntity[]): InvoiceForCurrencyForLegalEntity[] =>
   invoices.map((invoice) => ({
      ...invoice,
      currencyTransactions: invoice.currencyTransactions.map(addTotalsToCurrencyInvoice)
   }));
