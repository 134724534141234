'use client';

import { FC, PropsWithChildren, useMemo, useEffect } from 'react';

import { PageRendererContext } from '../../../context/page-renderer-context';

interface Props extends PropsWithChildren {
   isConsumer: boolean;
}

const Providers: FC<Props> = ({ isConsumer, children }) => {
   const value = useMemo(
      () => ({
         isConsumer
      }),
      [isConsumer]
   );

   useEffect(() => {
      if (isConsumer && typeof window !== 'undefined') {
         const scrollPosition = localStorage.getItem('navigationPendingScrollPosition');

         if (scrollPosition) {
            window.scrollTo({ top: parseInt(scrollPosition, 10), behavior: 'smooth' });
            localStorage.removeItem('navigationPendingScrollPosition');
         }
      }
   }, []);

   return <PageRendererContext.Provider value={value}>{children}</PageRendererContext.Provider>;
};

export default Providers;
