'use client';

import { FC, useMemo } from 'react';

import { Avatar } from '../../../avatar/components/Avatar/Avatar';
import { Badge } from '../../../badge/components/Badge/Badge';
import { Box } from '../../../box/components/Box/Box';
import { Link } from '../../../link/components/Link/Link';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   href: string;
   target: '_blank' | '_self';
   noFollow?: boolean;
   name: string;
   producer: string;
   logo: string;
   excerpt?: string;
   polymerTypes: string[];
   productCount: string;
   fillHeight?: boolean;
}

export const BrandCard: FC<Props> = ({ href, target = '_self', noFollow, name, producer, logo, excerpt, polymerTypes, productCount, fillHeight }) => {
   const visiblePolymerTypes = useMemo(() => (polymerTypes || []).slice(0, 5), [polymerTypes]);
   const remainingPolymerTypes = (polymerTypes || []).length - visiblePolymerTypes.length;

   return (
      <Link href={href} target={target} noFollow={noFollow} underline="none">
         <Box direction="column" gap="md" rounded="lg" spacing="lg" justify="between" height={fillHeight ? '100%' : 'auto'} shadow background="#fff">
            <Box direction="column" gap="md" height={fillHeight ? '100%' : 'auto'}>
               <Box justify="between" items="center">
                  <Box direction="column" gap="none">
                     <Typography variant="body-highlight">{name}</Typography>
                     <Typography variant="small" color="subtle">
                        {producer}
                     </Typography>
                  </Box>
                  <Avatar size="small" shape="square" shadow={false} src={logo} />
               </Box>
               {excerpt && (
                  <Typography variant="small" maxLines="3">
                     {excerpt}
                  </Typography>
               )}
               {visiblePolymerTypes?.length > 0 && (
                  <Box gap="xs" wrap="wrap">
                     {visiblePolymerTypes.map((polymerType) => (
                        <Badge key={polymerType} size="regular" variant="neutral" weight="regular">
                           {polymerType}
                        </Badge>
                     ))}

                     {remainingPolymerTypes > 0 && (
                        <Badge size="regular" variant="neutral" weight="regular">
                           +{remainingPolymerTypes}
                        </Badge>
                     )}
                  </Box>
               )}
            </Box>
            <Typography variant="small">{productCount}</Typography>
         </Box>
      </Link>
   );
};
