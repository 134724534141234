import { FC, useMemo } from 'react';

import { UOM } from '@ravago/shared/page-data/models/config';
import { RelatedDocument } from '@ravago/shared/page-data/models/elements';
import { Accordion } from '@ravago/shared/radiance/components/accordion/components/Accordion/Accordion';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Paper } from '@ravago/shared/radiance/components/paper/components/Paper/Paper';
import { Sublevel } from '@ravago/shared/radiance/components/sublevel/components/Sublevel/Sublevel';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { firstBy } from 'thenby';

import { useDocumentShare } from '../../../../../hooks/use-document-share';
import { useTranslations } from '../../../../../providers/translation-client-context-provider';
import { getLanguageCode, getLanguageNameFromLanguageCode } from '../../../../../utils/locale/locale.utils';
import { availableLanguageCodes } from '../../../quick-tds-link/components/quick-tds-link';
import DocumentListItem from '../related-document-list-item/document-list-item';

interface Props {
   documents: Array<RelatedDocument>;
   hasSharePermission: boolean;
   accordionTitle?: string;
   detailPageName?: string;
   ulProspectorId: string | undefined;
   publicShowTdsUom: boolean;
   showTdsUom: boolean;
   dismissable?: boolean;
   channelUOMs: Array<UOM>;
   onDocumentDownload: (document: RelatedDocument, selectedLanguageId?: string, selectedUOMId?: string) => void;
   onDismiss?: () => void;
   onToggle?: () => void;
   onShareChecked: (document: RelatedDocument) => void;
   accordionOpen?: boolean;
   locale: string;
   hasPublicTdsDownloadPermission: boolean;
   sharedWithYou?: boolean;
}

export const RelatedDocumentCategory: FC<Props> = ({
   documents,
   accordionOpen,
   dismissable,
   hasSharePermission,
   publicShowTdsUom,
   onDismiss,
   showTdsUom,
   ulProspectorId,
   accordionTitle,
   onDocumentDownload,
   onToggle,
   onShareChecked,
   hasPublicTdsDownloadPermission,
   detailPageName,
   locale,
   channelUOMs,
   sharedWithYou = false
}) => {
   const translate = useTranslations();
   const language = getLanguageCode(locale);
   const availableLanguages = availableLanguageCodes.map((lang) => ({
      id: lang,
      value: getLanguageNameFromLanguageCode(lang, locale)
   }));
   const { setDownloadList } = useDocumentShare();

   const handleToggle = () => {
      onToggle?.();
   };

   const handleDownload = (document: RelatedDocument, selectedLanguageId?: string, selectedUOMId?: string) => {
      onDocumentDownload?.(document, selectedLanguageId, selectedUOMId);
   };

   const handleDownloadAllSharedDocuments = () => {
      if (sharedWithYou) {
         setDownloadList(documents);
      }
   };

   const handleDismissSharedDocuments = () => {
      localStorage.removeItem('sharedDocuments');
      onDismiss?.();
   };

   const sortedDocuments = useMemo(
      () =>
         documents?.sort(
            firstBy((d: RelatedDocument) => !d.isBrandedTds, { ignoreCase: true })
               .thenBy((d: RelatedDocument) => !d.isStandardTDS, { ignoreCase: true })
               .thenBy((d: RelatedDocument) => d.type, { ignoreCase: true })
               .thenBy((d: RelatedDocument) => d.description, { ignoreCase: true })
               .thenBy((d: RelatedDocument) => d.rank, { ignoreCase: true })
         ),
      [documents]
   );

   if (dismissable || documents.length === 0) return null;

   const documentPapers = (sharedDocument: boolean) =>
      sortedDocuments?.map((document) => (
         <Paper key={document.id}>
            <DocumentListItem
               language={language}
               ulProspectorId={ulProspectorId}
               sharedDocument={sharedDocument}
               hasSharePermission={hasSharePermission}
               onShareChecked={(doc) => onShareChecked(doc)}
               channelUOMs={channelUOMs}
               detailPageName={detailPageName}
               publicShowTdsUom={publicShowTdsUom}
               showTdsUom={showTdsUom}
               onDocumentDownload={(doc, selectedLanguageId, selectedUOMId) => handleDownload(doc, selectedLanguageId, selectedUOMId)}
               locale={locale}
               availableLanguages={availableLanguages}
               document={document}
               hasPublicTdsDownloadPermission={hasPublicTdsDownloadPermission}
            />
         </Paper>
      ));

   return (
      <>
         {!sharedWithYou && (
            <Accordion
               key={accordionTitle}
               open={accordionOpen}
               header={
                  <Box justify="between" items="center">
                     <Typography variant="body-highlight" color="strong">
                        {accordionTitle}
                     </Typography>
                     <Icon icon={accordionOpen ? 'chevron-up' : 'chevron-down'} />
                  </Box>
               }
               body={documentPapers(sharedWithYou)}
               onHeaderClick={() => handleToggle()}
            />
         )}
         {sharedWithYou && (
            <Sublevel>
               <Box direction="column">
                  <Box direction="row" justify="between">
                     <Typography variant="heading-3" component="h3">
                        {translate('related-documents.shared-with-you')}
                     </Typography>
                     <Box direction="row">
                        <Button variant="secondary" onClick={handleDismissSharedDocuments}>
                           {translate('related-documents.dismiss')}
                        </Button>
                        <Button variant="primary" leadingIcon="download" onClick={handleDownloadAllSharedDocuments}>
                           {translate('related-documents.download-all')}
                        </Button>
                     </Box>
                  </Box>
                  <Box direction="column" gap="xs">
                     {documentPapers(sharedWithYou)}
                  </Box>
               </Box>
            </Sublevel>
         )}
      </>
   );
};
