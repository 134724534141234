'use client';

import { FC } from 'react';

import { ElementProps } from '@ravago/shared/page-data/models/base';
import { ContentfulBrandEntry } from '@ravago/shared/page-data/models/contentful';
import { BrandHeaderNode } from '@ravago/shared/page-data/models/elements';

import { Entry } from 'contentful';

import { AssetUtils } from '../../../utils/asset/asset.utils';
import { AvatarNameHeader } from '../../shared/avatar-name-header/avatar-name-header';

export const BrandHeaderElement: FC<ElementProps<BrandHeaderNode>> = ({ node, globals }) => {
   const brand = globals?.contentEntity as Entry<ContentfulBrandEntry>;

   return (
      <AvatarNameHeader
         theme={node.theme === 'primary' ? 'primary' : 'inverse-strong'}
         avatar={
            brand?.fields?.producer?.fields?.logo?.fields?.file?.url
               ? AssetUtils.optimizeImageIfRequired(brand.fields.producer.fields.logo.fields.file.url, globals.acceptWebp)
               : undefined
         }
         name={brand?.fields?.name}
      />
   );
};
