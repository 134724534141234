import { FC } from 'react';

import { Invoice } from '@ravago/shared/page-data/models/elements';
import { PaginatedResponse } from '@ravago/shared/page-data/models/elements/paginated-response.model';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableHead } from '@ravago/shared/radiance/components/table/components/TableHead/TableHead';
import { TableHeader } from '@ravago/shared/radiance/components/table/components/TableHeader/TableHeader';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';

import { useTranslations } from '../../../../../../../../providers/translation-client-context-provider';

import { ClosedInvoiceTableRow } from './closed-invoice-table-row';

type Props = {
   invoices: { [page: number]: PaginatedResponse<Invoice[]> };
   page: number;
   locale: string;
};

export const ClosedInvoiceTableDesktop: FC<Props> = ({ invoices, page, locale }) => {
   const translate = useTranslations();

   return (
      <Table>
         <TableHead>
            <TableRow>
               <TableHeader>{translate('invoices.closed-invoices.table.table-header.invoice')}</TableHeader>
               <TableHeader>{translate('invoices.closed-invoices.table.table-header.date')}</TableHeader>
               <TableHeader>{translate('invoices.closed-invoices.table.table-header.legal-entity')}</TableHeader>
               <TableHeader>{translate('invoices.closed-invoices.table.table-header.paid-amount')}</TableHeader>
               <TableHeader>{/* Placeholder */}</TableHeader>
               <TableHeader>{/* Placeholder */}</TableHeader>
            </TableRow>
         </TableHead>
         <TableBody>
            {invoices[page].content.map((invoice) => (
               <ClosedInvoiceTableRow key={invoice.id} invoice={invoice} locale={locale} />
            ))}
         </TableBody>
      </Table>
   );
};
