'use client';

import { FC, ReactElement, cloneElement, useEffect, useState } from 'react';

import { IconButton } from '../../../button/components/IconButton/IconButton';
import { DotsStepper } from '../../../stepper/components/DotsStepper/DotsStepper';

// TODO: Add swipeable prop

interface Props {
   showIndicators?: boolean;
   showControls?: boolean;
   currentSlide?: number;
   fullWidth?: boolean;
   onSlideChange?: (currentSlide: number) => void;
   variant?: 'default' | 'on-primary';
   children: Array<ReactElement>;
}

export const Carousel: FC<Props> = ({ showIndicators = false, showControls = true, currentSlide = 0, fullWidth = false, onSlideChange, variant, children }) => {
   const [visualSlide, setVisualSlide] = useState(currentSlide);

   useEffect(() => setVisualSlide(currentSlide), [currentSlide]);

   const handleSlideChange = (newSlide: number) => {
      let newIndex = newSlide;

      if (newSlide < 0) newIndex = children.length - 1;
      else if (newSlide > children.length - 1) newIndex = 0;

      setVisualSlide(newIndex);
      onSlideChange?.(newIndex);
   };

   return (
      <div data-testid="carousel" className={`gap-component-data-display-carousel-container-spacing-outer-gap flex flex-col items-center ${fullWidth ? 'w-full' : ''}`.replace(/\s+/g, ' ')}>
         <div className="relative w-full">
            <div className="w-full">
               {children.map((child, index) => {
                  const isActive = visualSlide === index;
                  return cloneElement(child, {
                     key: child.key || index,
                     className: `${child.props.className} ${isActive ? '' : 'hidden'}`
                  });
               })}
            </div>
            {showControls && (
               <div className="m-component-data-display-carousel-container-spacing-margin absolute inset-0 flex items-center justify-between">
                  <div data-testid="carousel-back" onClick={() => handleSlideChange(visualSlide - 1)} role="presentation">
                     <IconButton icon="long-arrow-left" backdrop />
                  </div>
                  <div data-testid="carousel-next" onClick={() => handleSlideChange(visualSlide + 1)} role="presentation">
                     <IconButton icon="long-arrow-right" backdrop />
                  </div>
               </div>
            )}
         </div>
         {showIndicators && (
            <div data-testid="carousel-indicators">
               <DotsStepper numberOfSteps={children.length} currentStep={visualSlide} onStepChange={handleSlideChange} variant={variant} />
            </div>
         )}
      </div>
   );
};
