import { SVGProps } from 'react';

const TooManyDocuments = (props: SVGProps<SVGSVGElement>) => (
   <svg width="332" height="100%" viewBox="0 0 332 308" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Too many documents</title>
      <g clipPath="url(#clip0_3404_153612)">
         <path d="M268.597 94.6374C266.731 94.6147 264.944 94.552 263.234 94.4492C265.008 94.7217 266.808 94.7849 268.597 94.6374Z" fill="#F2F2F2" />
         <path
            d="M267.475 127.628C261.386 114.463 258.719 100.273 260.587 84.7283C260.298 82.9199 261.31 81.1077 262.848 80.6773L290.416 73.0008C291.954 72.5745 293.436 73.6925 293.728 75.4999L300.616 118.4C300.905 120.208 299.894 122.021 298.356 122.451L270.788 130.128C269.249 130.554 267.767 129.436 267.475 127.628Z"
            fill="#F2F2F2"
         />
         <path
            d="M280.535 77.7488C280.932 74.6837 279.173 71.931 276.606 71.6004C274.039 71.2698 271.636 73.4866 271.239 76.5517C270.842 79.6168 272.6 82.3695 275.167 82.7001C277.734 83.0307 280.137 80.8139 280.535 77.7488Z"
            fill="#F2F2F2"
         />
         <path
            d="M225.995 63.5692C214.474 54.7557 205.504 43.4422 199.915 28.8169C198.818 27.3511 198.868 25.2764 200.029 24.1791L220.847 4.54423C222.01 3.45025 223.842 3.7492 224.942 5.21251L251.022 39.9648C252.12 41.4306 252.069 43.5054 250.908 44.6026L230.09 64.2375C228.927 65.3315 227.095 65.0325 225.995 63.5692Z"
            fill="#F2F2F2"
         />
         <path
            d="M214.315 13.3501C213.239 10.4528 210.4 8.83627 207.975 9.73954C205.549 10.6428 204.456 13.7238 205.532 16.6211C206.608 19.5185 209.447 21.1349 211.872 20.2317C214.298 19.3284 215.391 16.2474 214.315 13.3501Z"
            fill="#F2F2F2"
         />
         <path d="M277.556 203.734H0V204.635H277.556V203.734Z" fill="var(--brand-color-primary-dark)" />
         <path
            d="M112.899 97.5114C112.571 96.3692 117.149 106.838 116.06 106.964C111.1 107.531 100.11 110.087 96.6557 111.465C96.5002 111.447 96.3446 111.429 96.1936 111.406C95.823 111.366 95.4615 111.307 95.1047 111.24C87.0611 109.723 80.9757 103.138 80.811 95.1888C80.8019 95.0763 80.8019 94.9593 80.8019 94.8467C80.8008 94.2979 80.8298 93.7495 80.8888 93.2039C80.9645 92.4588 81.0945 91.7202 81.2777 90.9938L81.2594 90.9894C78.0566 87.9917 79.4064 82.4329 83.4877 80.0878C85.261 79.0715 87.2277 78.4353 89.2618 78.2199C89.8134 78.1602 90.3679 78.1302 90.9227 78.1299H96.1341C97.2125 77.8983 98.3056 77.7404 99.4056 77.6573H99.4147C109.906 76.8606 118.179 83.2341 117.405 90.9894C117.401 90.9984 117.392 91.0074 117.387 91.0164C117.163 91.3495 116.966 91.6645 116.792 91.9661C116.623 92.2497 116.481 92.5197 116.358 92.7763C115.401 94.7567 117.859 97.2488 116.229 97.7139C114.062 98.3326 121.931 118.666 113.35 118.666C117.524 119.849 113.802 100.662 112.899 97.5114Z"
            fill="#2F2E41"
         />
         <path d="M160.771 294.054L167.538 293.997L170.533 267.379L160.547 267.463L160.771 294.054Z" fill="#FFB6B6" />
         <path
            d="M182.448 304.776C182.454 305.575 182.141 306.345 181.579 306.915C181.017 307.485 180.25 307.81 179.448 307.817L165.995 307.929L163.64 303.171L162.775 307.954L157.699 308L158.981 290.943L160.747 290.826L167.95 290.331L170.274 290.176L170.315 295.002L181.122 302.307C181.527 302.581 181.859 302.949 182.09 303.379C182.321 303.809 182.443 304.288 182.448 304.776Z"
            fill="#2F2E41"
         />
         <path
            d="M169.351 280.701C166.659 280.377 159.867 279.801 159.867 279.801C159.867 279.801 156.661 273.131 157.18 271.056C157.69 268.972 158.964 268.548 157.781 267.459C154.899 256.846 155.36 239.607 155.36 239.607C155.36 239.607 139.852 232.121 124.15 223.457C108.8 214.981 93.2651 205.381 91.8606 200.542C90.9336 197.081 90.311 193.547 90 189.978C89.7652 187.638 89.6206 185.342 89.5303 183.285C89.3181 178.577 89.4039 175.098 89.4039 175.098L108.972 174.774L121.933 174.477H123.287V176.727L126.692 182.497C126.692 182.497 132.577 189.317 131.886 189.951C131.05 190.721 131.303 191.405 134.27 191.963C135.114 192.111 135.973 192.145 136.826 192.067C141.618 191.702 143.623 194.434 143.74 195.033C144.544 199.259 152.605 208.441 160.156 216.278H160.16C167.129 223.515 173.659 229.601 173.659 229.601L174.029 237.365L175.768 273.666C175.768 273.666 172.507 271.848 175.42 275.768C178.333 279.693 172.042 281.021 169.351 280.701Z"
            fill="#2F2E41"
         />
         <path d="M242.763 196.294L245.525 190.137L222.483 176.352L218.406 185.438L242.763 196.294Z" fill="#FFB6B6" />
         <path
            d="M261.562 181.11C262.293 181.437 262.865 182.04 263.152 182.787C263.438 183.534 263.416 184.364 263.089 185.094L257.596 197.334L252.275 197.49L256.279 200.263L254.209 204.883L239.18 196.627L239.807 194.977L242.352 188.242L243.177 186.071L247.597 188.042L258.758 181.285C259.176 181.032 259.65 180.884 260.138 180.854C260.626 180.823 261.115 180.911 261.562 181.11Z"
            fill="#2F2E41"
         />
         <path
            d="M230.91 181.393C230.894 181.428 230.875 181.46 230.859 181.489C229.6 183.792 227.534 189.121 227.405 189.449C227.402 189.455 227.4 189.462 227.399 189.468C227.399 189.468 220.582 189.118 218.84 187.872C217.095 186.617 219.736 188.602 215.711 185.977C213.629 184.612 206.501 182.482 200.115 180.741C194.174 179.122 188.881 177.849 188.881 177.849C188.881 177.849 184.202 182.093 177.423 187.959L177.42 187.962C171.123 193.398 163.009 200.234 155.146 206.355C138.311 211.29 120.543 215.566 116.101 213.164C112.978 211.39 110.017 209.349 107.25 207.063L111.824 195.114L123.92 182.488L136.653 177.331L141.634 175.312L148.609 172.491L186.372 157.199L228.192 171.636C228.192 171.636 225.279 173.982 230.019 172.745C234.696 171.527 232.225 178.924 230.91 181.393Z"
            fill="#2F2E41"
         />
         <path
            d="M98.9018 111.914H112.45L114.583 116.607L123.656 120.636C124.836 121.161 125.876 121.955 126.691 122.954C127.507 123.953 128.075 125.129 128.351 126.387C131.618 141.272 127.486 144.365 127.353 149.273C126.901 165.927 124.574 169.239 124.834 173.35C124.912 174.581 125.666 179.446 128.09 178.988C130.514 178.53 129.207 180.453 129.781 181.201C130.276 181.847 132.883 180.989 131.247 183.135C129.611 185.281 136.606 191.132 132.32 191.132C124.191 191.132 107.934 195.183 101.611 195.183C95.289 195.183 90.4032 197.052 87.6118 196.984C85.9105 196.942 86.4424 189.036 87.1224 182.188C87.2764 180.638 90.2636 180.678 87.5829 177.803C84.9022 174.929 88.0577 173.435 88.0634 172.678C88.0695 171.874 89.5831 169.85 87.6943 166.538C85.8054 163.226 87.5466 164.596 87.4646 163.557C86.8323 155.542 85.9263 145.324 85.9263 145.324L81.9326 134.121C80.9118 131.257 81.0467 128.111 82.309 125.344C83.5713 122.578 85.8623 120.408 88.6986 119.293L93.3833 117.452L98.9018 111.914Z"
            fill="var(--brand-color-primary-dark)"
         />
         <path
            d="M194.05 107.782C193.749 108.342 193.33 108.83 192.821 109.212C192.312 109.595 191.726 109.862 191.103 109.997C190.48 110.131 189.835 110.129 189.213 109.99C188.591 109.852 188.007 109.581 187.5 109.195L168.415 119.964L164.129 115.435L186.89 102.809C187.605 102.008 188.59 101.496 189.658 101.37C190.726 101.244 191.804 101.513 192.686 102.126C193.569 102.739 194.195 103.653 194.446 104.696C194.698 105.738 194.557 106.836 194.05 107.782Z"
            fill="#FFB6B6"
         />
         <path
            d="M108.119 133.2L107.828 136.668L145.823 136.181L183.643 114.99L178.114 106.508L144.872 120.438L121.246 120.916C117.922 120.983 114.74 122.276 112.315 124.544C109.891 126.813 108.396 129.897 108.119 133.2Z"
            fill="var(--brand-color-primary-dark)"
         />
         <path
            d="M103.534 109.439C110.348 109.439 115.873 103.933 115.873 97.1416C115.873 90.3497 110.348 84.8438 103.534 84.8438C96.7195 84.8438 91.1953 90.3497 91.1953 97.1416C91.1953 103.933 96.7195 109.439 103.534 109.439Z"
            fill="#FFB6B6"
         />
         <path
            d="M117.407 91.6598C117.201 91.7678 117 91.8669 116.794 91.9659C116.579 92.0694 116.364 92.1729 116.149 92.2674C112.017 94.1354 108.654 94.3424 106.613 91.6598C106.559 90.2483 106.241 88.8592 105.675 87.5639C105.775 89.0115 105.392 90.4514 104.586 91.6598H100.478C96.2454 94.4594 94.0721 94.752 97.0095 103.943C97.8285 106.504 101.085 121.407 99.8039 123.617C99.4333 123.577 95.463 111.307 95.1061 111.239C87.0625 109.722 80.9772 103.137 80.8125 95.1886C80.8216 94.7835 80.8491 94.3784 80.8903 93.9823C81.4202 93.1884 82.0025 92.4306 82.6335 91.7138C82.6303 89.5891 83.1205 87.4924 84.0656 85.5879C85.2613 83.175 87.1253 81.1539 89.4372 79.7635C90.8152 79.0976 92.2518 78.5597 93.7289 78.1567C93.7655 78.1477 93.7976 78.1387 93.8341 78.1296C94.2871 78.0081 94.7492 77.8956 95.2114 77.8011C96.166 77.6002 97.1315 77.4544 98.103 77.3645C98.1717 77.36 98.2403 77.3555 98.3043 77.3555C98.6923 77.3564 99.073 77.4605 99.407 77.657C99.4116 77.657 99.4116 77.6616 99.4162 77.6616C99.6549 77.8006 99.8633 77.9857 100.029 78.2062C100.322 78.577 100.48 79.0354 100.478 79.507H105.053C105.222 79.507 105.392 79.5115 105.561 79.5205C108.615 79.6245 111.52 80.8605 113.707 82.9867C115.895 85.1129 117.207 87.9761 117.388 91.0161C117.402 91.2322 117.407 91.4437 117.407 91.6598Z"
            fill="#2F2E41"
         />
         <path
            d="M86.7069 93.9069C91.1963 93.9069 94.8357 90.2796 94.8357 85.805C94.8357 81.3305 91.1963 77.7031 86.7069 77.7031C82.2175 77.7031 78.5781 81.3305 78.5781 85.805C78.5781 90.2796 82.2175 93.9069 86.7069 93.9069Z"
            fill="#2F2E41"
         />
         <path
            d="M96.6404 79.5044C96.7956 83.9762 93.001 87.6062 88.5116 87.6062C86.3557 87.6062 84.2881 86.7527 82.7637 85.2333C81.2392 83.7139 80.3828 81.6531 80.3828 79.5044C80.3828 75.0298 85.8198 74.9835 88.5116 71.4025C92.3195 66.3366 96.431 73.4737 96.6404 79.5044Z"
            fill="#2F2E41"
         />
         <path
            d="M107.269 206.245C106.007 187.578 109.702 170.019 119.964 153.927C120.222 152.869 120.891 151.955 121.824 151.388C122.757 150.82 123.878 150.644 124.94 150.899L164.583 160.455C165.646 160.712 166.562 161.379 167.131 162.309C167.701 163.239 167.877 164.356 167.621 165.415L154.927 217.733C154.668 218.792 153.999 219.705 153.067 220.273C152.134 220.84 151.013 221.016 149.95 220.761L110.307 211.205C109.245 210.948 108.329 210.281 107.759 209.352C107.19 208.422 107.013 207.305 107.269 206.245Z"
            fill="#F2F2F2"
         />
         <path
            opacity="0.2"
            d="M115.038 210.21C113.976 209.953 113.059 209.286 112.49 208.356C111.92 207.426 111.744 206.309 112 205.25C110.738 186.583 114.433 169.023 124.695 152.932C124.868 152.238 125.222 151.602 125.72 151.087L124.94 150.899C123.878 150.644 122.757 150.82 121.824 151.388C120.891 151.955 120.222 152.869 119.964 153.927C109.702 170.019 106.007 187.578 107.269 206.245C107.013 207.305 107.19 208.422 107.759 209.351C108.329 210.281 109.245 210.948 110.307 211.205L149.95 220.761C150.655 220.928 151.391 220.907 152.085 220.699C152.779 220.492 153.405 220.105 153.901 219.578L115.038 210.21Z"
            fill="black"
         />
         <path
            d="M150.934 188.187L124.351 181.857C123.928 181.756 123.563 181.491 123.336 181.122C123.109 180.753 123.038 180.309 123.139 179.887C123.24 179.466 123.504 179.102 123.875 178.875C124.245 178.648 124.69 178.577 125.113 178.677L151.696 185.007C152.118 185.109 152.482 185.374 152.709 185.743C152.935 186.111 153.006 186.555 152.905 186.975C152.804 187.396 152.54 187.76 152.171 187.987C151.801 188.214 151.357 188.286 150.934 188.187Z"
            fill="white"
         />
         <path
            d="M132.286 174.532L126.438 173.139C126.015 173.039 125.649 172.776 125.421 172.408C125.192 172.039 125.12 171.595 125.219 171.174C125.319 170.752 125.583 170.387 125.952 170.159C126.321 169.931 126.767 169.858 127.19 169.957L127.2 169.96L133.048 171.352C133.47 171.454 133.834 171.719 134.06 172.087C134.287 172.456 134.357 172.9 134.256 173.32C134.156 173.741 133.892 174.105 133.522 174.332C133.153 174.559 132.708 174.631 132.286 174.532Z"
            fill="white"
         />
         <path
            d="M147.583 202.162L121 195.832C120.578 195.731 120.214 195.466 119.987 195.097C119.761 194.728 119.69 194.285 119.791 193.864C119.892 193.443 120.156 193.079 120.525 192.852C120.895 192.625 121.34 192.554 121.762 192.653L148.346 198.983C148.555 199.032 148.754 199.122 148.929 199.247C149.104 199.372 149.252 199.531 149.366 199.714C149.479 199.896 149.556 200.099 149.59 200.311C149.625 200.523 149.618 200.74 149.568 200.949C149.519 201.158 149.428 201.356 149.302 201.53C149.176 201.704 149.017 201.852 148.834 201.965C148.65 202.078 148.446 202.154 148.234 202.188C148.021 202.222 147.803 202.214 147.593 202.165L147.583 202.162Z"
            fill="white"
         />
         <path
            d="M149.258 195.176L122.675 188.846C122.252 188.746 121.886 188.482 121.658 188.113C121.43 187.744 121.358 187.299 121.459 186.878C121.559 186.456 121.824 186.091 122.194 185.864C122.564 185.637 123.01 185.565 123.433 185.665L123.437 185.666L150.02 191.996C150.442 192.098 150.806 192.363 151.033 192.732C151.26 193.1 151.33 193.544 151.229 193.965C151.128 194.386 150.864 194.749 150.495 194.976C150.125 195.203 149.681 195.275 149.258 195.176Z"
            fill="white"
         />
         <path
            d="M143.638 162.345C147.415 162.345 150.476 159.294 150.476 155.53C150.476 151.766 147.415 148.715 143.638 148.715C139.862 148.715 136.801 151.766 136.801 155.53C136.801 159.294 139.862 162.345 143.638 162.345Z"
            fill="var(--brand-color-primary-dark)"
         />
         <path
            d="M109.161 210.149C108.604 209.841 108.119 209.417 107.742 208.905C107.365 208.393 107.104 207.805 106.978 207.183C106.851 206.56 106.861 205.918 107.008 205.3C107.155 204.682 107.435 204.103 107.828 203.603L97.2695 184.444L101.868 180.23L114.242 203.077C115.037 203.8 115.538 204.788 115.65 205.854C115.763 206.92 115.479 207.99 114.853 208.862C114.227 209.734 113.301 210.346 112.252 210.583C111.203 210.82 110.104 210.666 109.161 210.149Z"
            fill="#FFB6B6"
         />
         <path
            d="M96.8297 125.34L85.4734 124.248C84.9436 124.197 84.409 124.257 83.9038 124.424C83.3987 124.591 82.9342 124.862 82.5401 125.219C82.1461 125.575 81.8313 126.01 81.6158 126.495C81.4004 126.98 81.2891 127.505 81.2891 128.036V161.718L102.063 199.682L110.643 194.281L97.095 160.973L96.8297 125.34Z"
            fill="var(--brand-color-primary-dark)"
         />
         <path
            d="M186.483 122.61C189.116 104.085 196.37 87.665 209.745 74.0329C210.217 73.0503 211.061 72.2943 212.091 71.9309C213.122 71.5675 214.255 71.6264 215.242 72.0945L252.05 89.6046C253.036 90.075 253.794 90.9159 254.159 91.943C254.523 92.97 254.464 94.0993 253.994 95.083L230.732 143.66C230.26 144.642 229.416 145.398 228.386 145.762C227.356 146.125 226.223 146.066 225.236 145.598L188.427 128.088C187.442 127.618 186.683 126.777 186.318 125.75C185.954 124.723 186.013 123.593 186.483 122.61Z"
            fill="#F2F2F2"
         />
         <path
            opacity="0.2"
            d="M193.262 128.088C192.276 127.618 191.518 126.777 191.153 125.75C190.789 124.723 190.848 123.593 191.318 122.61C193.951 104.085 201.205 87.6649 214.58 74.0329C214.893 73.3892 215.371 72.8394 215.966 72.4391L215.242 72.0945C214.255 71.6264 213.122 71.5675 212.091 71.9309C211.061 72.2943 210.217 73.0502 209.745 74.0329C196.37 87.665 189.116 104.085 186.483 122.61C186.013 123.593 185.954 124.723 186.318 125.75C186.683 126.777 187.441 127.618 188.427 128.088L225.235 145.598C225.89 145.907 226.615 146.038 227.337 145.977C228.059 145.917 228.752 145.667 229.346 145.254L193.262 128.088Z"
            fill="black"
         />
         <path
            d="M232.945 113.926L208.246 102.26C207.853 102.074 207.55 101.741 207.404 101.332C207.258 100.924 207.28 100.475 207.467 100.083C207.653 99.6913 207.988 99.3893 208.397 99.2436C208.807 99.0978 209.258 99.1203 209.651 99.3059L234.349 110.972C234.741 111.158 235.042 111.492 235.187 111.899C235.332 112.307 235.309 112.755 235.124 113.146C234.938 113.537 234.604 113.838 234.196 113.984C233.787 114.131 233.337 114.109 232.945 113.926Z"
            fill="white"
         />
         <path
            d="M217.53 96.7277L212.096 94.1613C211.901 94.0701 211.726 93.9415 211.581 93.7828C211.435 93.6241 211.323 93.4385 211.25 93.2365C211.177 93.0344 211.144 92.82 211.154 92.6055C211.164 92.3909 211.216 92.1805 211.308 91.9861C211.4 91.7917 211.529 91.6172 211.688 91.4726C211.848 91.3281 212.034 91.2162 212.237 91.1435C212.44 91.0707 212.655 91.0385 212.87 91.0487C213.085 91.0589 213.296 91.1113 213.491 91.2028L213.501 91.2073L218.934 93.7738C219.326 93.9602 219.628 94.2938 219.773 94.7015C219.918 95.1092 219.896 95.5577 219.71 95.9487C219.524 96.3396 219.19 96.6412 218.781 96.7873C218.373 96.9333 217.923 96.9119 217.53 96.7277Z"
            fill="white"
         />
         <path
            d="M226.768 126.912L202.07 115.246C201.678 115.06 201.377 114.726 201.231 114.318C201.086 113.911 201.109 113.462 201.295 113.071C201.481 112.68 201.814 112.379 202.223 112.233C202.632 112.087 203.082 112.108 203.475 112.292L228.173 123.958C228.368 124.049 228.543 124.178 228.688 124.337C228.833 124.495 228.945 124.681 229.018 124.883C229.091 125.085 229.124 125.299 229.114 125.513C229.104 125.728 229.051 125.938 228.96 126.132C228.868 126.327 228.739 126.501 228.58 126.646C228.42 126.79 228.234 126.902 228.032 126.975C227.829 127.047 227.614 127.08 227.399 127.07C227.184 127.06 226.973 127.008 226.778 126.916L226.768 126.912Z"
            fill="white"
         />
         <path
            d="M229.857 120.419L205.158 108.753C204.765 108.568 204.462 108.234 204.315 107.826C204.168 107.418 204.19 106.969 204.376 106.577C204.562 106.185 204.896 105.883 205.306 105.736C205.715 105.59 206.166 105.612 206.559 105.797L206.563 105.799L231.261 117.465C231.653 117.651 231.954 117.985 232.099 118.392C232.245 118.8 232.222 119.248 232.036 119.639C231.85 120.03 231.516 120.332 231.108 120.478C230.699 120.624 230.249 120.603 229.857 120.419Z"
            fill="white"
         />
         <path
            d="M232.572 87.2863C236.348 87.2863 239.41 84.2351 239.41 80.4713C239.41 76.7074 236.348 73.6562 232.572 73.6562C228.796 73.6562 225.734 76.7074 225.734 80.4713C225.734 84.2351 228.796 87.2863 232.572 87.2863Z"
            fill="var(--brand-color-primary-dark)"
         />
         <path
            d="M146.234 80.224C140.488 65.9393 138.902 51.2532 142.825 36.0355C142.757 35.141 143.047 34.2552 143.632 33.5722C144.217 32.8893 145.048 32.465 145.944 32.3926L179.379 29.7266C180.275 29.6561 181.162 29.9434 181.844 30.5253C182.527 31.1072 182.949 31.9362 183.019 32.8305L186.428 77.019C186.496 77.9134 186.206 78.7993 185.621 79.4822C185.036 80.1652 184.205 80.5894 183.309 80.6619L149.874 83.3279C148.977 83.3983 148.091 83.1111 147.409 82.5292C146.726 81.9473 146.304 81.1183 146.234 80.224Z"
            fill="#F2F2F2"
         />
         <path
            opacity="0.2"
            d="M153.319 81.3386C152.423 81.409 151.536 81.1218 150.854 80.5399C150.172 79.958 149.749 79.1289 149.679 78.2347C143.934 63.9499 142.347 49.2639 146.27 34.0462C146.229 33.4587 146.343 32.8701 146.602 32.3401L145.944 32.3926C145.048 32.4651 144.217 32.8893 143.632 33.5722C143.047 34.2551 142.757 35.141 142.825 36.0354C138.902 51.2532 140.488 65.9392 146.234 80.224C146.304 81.1182 146.726 81.9473 147.409 82.5292C148.091 83.1111 148.977 83.3983 149.874 83.3279L183.309 80.6619C183.902 80.6126 184.473 80.4074 184.962 80.0673C185.452 79.7271 185.843 79.2641 186.096 78.725L153.319 81.3386Z"
            fill="black"
         />
         <path
            d="M175.771 54.9166L153.37 56.7652C153.014 56.7946 152.661 56.6815 152.388 56.4507C152.116 56.2199 151.947 55.8904 151.919 55.5346C151.891 55.1788 152.005 54.8259 152.237 54.5535C152.469 54.2811 152.799 54.1116 153.156 54.0821L175.556 52.2336C175.912 52.2055 176.264 52.3193 176.535 52.55C176.806 52.7808 176.975 53.1096 177.003 53.4646C177.031 53.8196 176.918 54.1717 176.687 54.4438C176.456 54.716 176.126 54.886 175.771 54.9166Z"
            fill="white"
         />
         <path
            d="M157.71 49.0065L152.782 49.4131C152.606 49.4284 152.428 49.4089 152.259 49.3555C152.09 49.3022 151.934 49.2161 151.798 49.1023C151.663 48.9884 151.552 48.849 151.471 48.692C151.39 48.535 151.34 48.3635 151.326 48.1872C151.311 48.011 151.331 47.8335 151.385 47.6649C151.439 47.4963 151.526 47.3399 151.641 47.2046C151.755 47.0694 151.895 46.9579 152.053 46.8766C152.211 46.7953 152.383 46.7458 152.559 46.7308L152.568 46.7301L157.496 46.3235C157.852 46.2951 158.204 46.4088 158.475 46.6395C158.747 46.8703 158.915 47.1992 158.943 47.5544C158.972 47.9095 158.858 48.2618 158.627 48.534C158.395 48.8062 158.066 48.9761 157.71 49.0065Z"
            fill="white"
         />
         <path
            d="M176.712 66.7138L154.312 68.5623C153.956 68.5907 153.604 68.477 153.332 68.2463C153.061 68.0155 152.893 67.6866 152.864 67.3314C152.836 66.9763 152.95 66.624 153.181 66.3518C153.412 66.0796 153.741 65.9097 154.097 65.8794L176.498 64.0308C176.674 64.0157 176.852 64.0354 177.021 64.0888C177.189 64.1423 177.346 64.2284 177.481 64.3422C177.616 64.4561 177.727 64.5954 177.808 64.7523C177.889 64.9093 177.938 65.0807 177.953 65.2568C177.968 65.4329 177.947 65.6103 177.893 65.7788C177.839 65.9473 177.753 66.1037 177.638 66.2389C177.524 66.3741 177.384 66.4856 177.227 66.567C177.069 66.6483 176.897 66.698 176.721 66.7131L176.712 66.7138Z"
            fill="white"
         />
         <path
            d="M176.241 60.8149L153.841 62.6635C153.484 62.6933 153.131 62.5806 152.858 62.3502C152.586 62.1197 152.417 61.7904 152.388 61.4346C152.359 61.0789 152.473 60.7258 152.704 60.4532C152.936 60.1805 153.266 60.0106 153.623 59.9807L153.626 59.9805L176.027 58.1319C176.383 58.1037 176.735 58.2175 177.006 58.4482C177.277 58.6789 177.445 59.0079 177.474 59.3629C177.502 59.7179 177.388 60.0701 177.157 60.3423C176.926 60.6144 176.597 60.7844 176.241 60.8149Z"
            fill="white"
         />
         <path
            d="M164.543 36.0875C167.234 34.5337 168.161 31.0998 166.612 28.4176C165.063 25.7353 161.626 24.8205 158.935 26.3742C156.244 27.928 155.318 31.3619 156.866 34.0441C158.415 36.7263 161.852 37.6412 164.543 36.0875Z"
            fill="var(--brand-color-primary-dark)"
         />
         <path
            d="M291.58 146.607C284.164 130.573 280.917 113.293 283.191 94.3614C282.84 92.1591 284.072 89.9521 285.945 89.4279L319.518 80.0791C321.392 79.56 323.197 80.9215 323.552 83.1227L331.941 135.368C332.292 137.571 331.061 139.778 329.188 140.302L295.614 149.65C293.74 150.17 291.936 148.808 291.58 146.607Z"
            fill="#F2F2F2"
         />
         <path
            opacity="0.2"
            d="M298.883 146.634C297.009 147.153 295.204 145.791 294.848 143.59C287.433 127.556 284.185 110.276 286.46 91.3446C286.352 90.6417 286.402 89.9238 286.606 89.2422L285.945 89.4261C284.072 89.9503 282.84 92.1573 283.191 94.3596C280.917 113.291 284.164 130.571 291.58 146.605C291.936 148.806 293.74 150.168 295.614 149.649L329.188 140.3C329.827 140.086 330.401 139.714 330.857 139.219C331.313 138.724 331.636 138.122 331.795 137.469L298.883 146.634Z"
            fill="black"
         />
         <path
            d="M318.677 110.887L296.191 117.225C295.446 117.434 294.726 116.893 294.585 116.017C294.504 115.615 294.569 115.196 294.768 114.837C294.968 114.477 295.289 114.2 295.674 114.054L318.16 107.716C318.906 107.509 319.624 108.051 319.765 108.927C319.844 109.329 319.779 109.746 319.58 110.105C319.381 110.464 319.061 110.741 318.677 110.887Z"
            fill="white"
         />
         <path
            d="M299.722 107.137L294.775 108.531C294.03 108.744 293.309 108.207 293.164 107.332C293.082 106.929 293.146 106.51 293.344 106.149C293.543 105.788 293.864 105.51 294.249 105.363L294.258 105.36L299.205 103.966C299.951 103.759 300.669 104.301 300.809 105.177C300.889 105.579 300.824 105.996 300.625 106.355C300.426 106.714 300.106 106.991 299.722 107.137Z"
            fill="white"
         />
         <path
            d="M320.948 124.827L298.462 131.165C297.716 131.372 296.998 130.83 296.857 129.953C296.778 129.552 296.843 129.134 297.042 128.776C297.241 128.417 297.561 128.14 297.945 127.994L320.431 121.656C321.176 121.443 321.897 121.98 322.042 122.855C322.124 123.258 322.06 123.677 321.862 124.038C321.663 124.399 321.342 124.677 320.956 124.824L320.948 124.827Z"
            fill="white"
         />
         <path
            d="M319.813 117.86L297.327 124.198C296.582 124.409 295.862 123.87 295.718 122.995C295.636 122.592 295.701 122.172 295.9 121.812C296.1 121.452 296.421 121.174 296.807 121.028L296.81 121.027L319.296 114.689C320.042 114.481 320.76 115.024 320.901 115.9C320.98 116.302 320.915 116.719 320.716 117.078C320.517 117.436 320.197 117.713 319.813 117.86Z"
            fill="white"
         />
         <path
            d="M307.482 85.8592C307.966 82.1264 305.824 78.774 302.698 78.3714C299.572 77.9688 296.645 80.6685 296.161 84.4013C295.677 88.1341 297.819 91.4865 300.946 91.8891C304.072 92.2917 306.998 89.592 307.482 85.8592Z"
            fill="var(--brand-color-primary-dark)"
         />
         <path
            d="M259.144 71.2179C247.909 61.7424 239.377 49.8599 234.427 34.7888C233.386 33.2524 233.538 31.1551 234.765 30.1006L256.781 11.2331C258.011 10.1821 259.85 10.573 260.893 12.107L285.611 48.536C286.652 50.0725 286.5 52.1697 285.273 53.2243L263.257 72.0917C262.027 73.1428 260.187 72.7519 259.144 71.2179Z"
            fill="#F2F2F2"
         />
         <path
            opacity="0.2"
            d="M264.659 68.665C263.429 69.716 261.59 69.3252 260.546 67.7911C249.311 58.3157 240.78 46.4332 235.829 31.3621C235.5 30.87 235.285 30.3113 235.198 29.726L234.765 30.0972C233.538 31.1518 233.386 33.249 234.427 34.7855C239.377 49.8566 247.909 61.739 259.144 71.2145C260.187 72.7485 262.027 73.1394 263.257 72.0883L285.273 53.221C285.679 52.8347 285.982 52.3522 286.151 51.8185C286.32 51.2847 286.352 50.7172 286.242 50.1689L264.659 68.665Z"
            fill="black"
         />
         <path
            d="M266.994 34.7533L252.275 47.4478C251.787 47.8677 251.055 47.7135 250.64 47.1032C250.437 46.828 250.339 46.4897 250.362 46.1485C250.386 45.8074 250.53 45.4853 250.769 45.2397L265.488 32.5451C265.977 32.1264 266.709 32.2824 267.124 32.8934C267.325 33.1683 267.423 33.5058 267.399 33.8461C267.376 34.1865 267.232 34.5078 266.994 34.7533Z"
            fill="white"
         />
         <path
            d="M251.383 38.5983L248.145 41.3911C247.658 41.8136 246.926 41.663 246.508 41.0545C246.304 40.7795 246.205 40.441 246.228 40.0991C246.251 39.7573 246.394 39.4343 246.633 39.1877L246.639 39.1829L249.877 36.3901C250.366 35.9714 251.098 36.1273 251.513 36.7384C251.714 37.0133 251.812 37.3508 251.788 37.6911C251.764 38.0315 251.621 38.3528 251.383 38.5983Z"
            fill="white"
         />
         <path
            d="M273.61 44.4592L258.891 57.1537C258.402 57.5725 257.67 57.4165 257.256 56.8054C257.054 56.5305 256.956 56.1931 256.98 55.8527C257.004 55.5124 257.147 55.1911 257.385 54.9456L272.104 42.251C272.59 41.8285 273.323 41.9791 273.741 42.5876C273.945 42.8626 274.044 43.2011 274.021 43.543C273.998 43.8848 273.855 44.2078 273.616 44.4544L273.61 44.4592Z"
            fill="white"
         />
         <path
            d="M270.305 39.6076L255.587 52.3021C255.099 52.7235 254.367 52.5712 253.95 51.962C253.747 51.6867 253.648 51.348 253.671 51.0063C253.695 50.6645 253.839 50.3419 254.078 50.0958L254.081 50.094L268.799 37.3995C269.288 36.9807 270.021 37.1367 270.435 37.7478C270.637 38.0227 270.734 38.3601 270.71 38.7005C270.687 39.0408 270.543 39.3621 270.305 39.6076Z"
            fill="white"
         />
         <path
            d="M249.742 19.8271C248.792 16.8429 245.997 15.07 243.499 15.8672C241.001 16.6644 239.745 19.7298 240.695 22.7139C241.644 25.6981 244.439 27.4709 246.938 26.6738C249.436 25.8766 250.691 22.8112 249.742 19.8271Z"
            fill="var(--brand-color-primary-dark)"
         />
      </g>
      <defs>
         <clipPath id="clip0_3404_153612">
            <rect width="332" height="308" fill="white" />
         </clipPath>
      </defs>
   </svg>
);
export default TooManyDocuments;
