'use client';

import { FC } from 'react';

import { useAuthenticationContext, UserGroups } from '@ravago/shared/authentication';
import { ElementProps } from '@ravago/shared/page-data/models/base';
import { ContentfulProductEntry } from '@ravago/shared/page-data/models/contentful';
import { ProductRecommendationBadgeNode } from '@ravago/shared/page-data/models/elements';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';

import { Entry } from 'contentful';

import { useTranslations } from '../../../providers/translation-client-context-provider';

export const ProductRecommendationBadgeElement: FC<ElementProps<ProductRecommendationBadgeNode>> = ({ node, globals }) => {
   const translate = useTranslations();
   const { user } = useAuthenticationContext();

   const product = globals?.contentEntity as Entry<ContentfulProductEntry> | undefined;

   const showBadge = () => {
      if (!globals.isConsumer) return true;

      if (!product || !user) return false;

      return product.fields.isPreferred && user.groups.includes(UserGroups.ravagoSalesRepresentative);
   };

   return (
      showBadge() && (
         <Badge leadingIcon="gem" variant={node.variant} size={node.size}>
            {translate('product.recommendation')}
         </Badge>
      )
   );
};
