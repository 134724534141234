import { useEffect, useState } from 'react';

import { CallOffOrderColor, CallOffOrderDetail, Quantity } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { InputField } from '@ravago/shared/radiance/components/input-field/components/InputField/InputField';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useRequestForQuotationContext } from '../../../hooks/use-request-for-quotation-context';
import { useTranslations } from '../../../providers/translation-client-context-provider';
import { CallOffProgressBar } from '../order/call-off-progress-bar';

interface Props {
   id: string;
   callOffOrderColor: CallOffOrderColor;
   getCallOffOrderDetail: (accessToken: string, callOffId: string) => Promise<CallOffOrderDetail | undefined>;
   token: string;
}

export const DynamicProductBlockCallOffFields = ({ id, callOffOrderColor, getCallOffOrderDetail, token }: Props) => {
   const translate = useTranslations();
   const { state, dispatch } = useRequestForQuotationContext();

   const [callOffOrderDetail, setCallOffOrderDetail] = useState<CallOffOrderDetail | undefined>();

   const { quantity } = state.color;
   const quantityValue = !quantity ? undefined : quantity.amount;

   const isQuantityInvalid = !!quantityValue && quantityValue > callOffOrderColor.recommendedCallOff.remainingQuantity.amount;

   const totalPriceAmount = quantityValue ? callOffOrderColor.recommendedCallOff.unitPrice.price.amount * quantityValue : 0;
   const totalPrice = Intl.NumberFormat(undefined, {
      currency: callOffOrderColor.recommendedCallOff.unitPrice.price.currency,
      style: 'currency'
   }).format(totalPriceAmount);

   useEffect(() => {
      const fetchCallOffOrderDetail = async (accessToken: string, callOffOrderId: string) => {
         const callOffOrder = await getCallOffOrderDetail(accessToken, callOffOrderId);
         if (callOffOrder) {
            setCallOffOrderDetail(callOffOrder);
         }
      };

      fetchCallOffOrderDetail(token, callOffOrderColor.recommendedCallOff.id);
   }, [token, callOffOrderColor]);

   const handleQuantityChange = (newQuantityValue: string) => {
      let newQuantity: Quantity | undefined;
      if (newQuantityValue !== '') {
         newQuantity = {
            amount: Math.floor(Number(newQuantityValue)),
            unitOfMeasure: callOffOrderColor.recommendedCallOff.unitPrice.unitOfMeasure
         };
      }

      dispatch?.({
         type: 'set_quantity',
         value: {
            quantity: newQuantity
         }
      });
   };

   return (
      <>
         <InputField
            id={`${id}-quantity-input`}
            label={translate('request-for-quotation.quantity-label')}
            suffix={callOffOrderColor.recommendedCallOff.unitPrice.unitOfMeasure}
            type="number"
            min={1}
            value={quantityValue?.toString()}
            onChange={handleQuantityChange}
            error={isQuantityInvalid}
            errorMessage={translate('request-for-quotation.invalid-quantity')}
         />
         {callOffOrderDetail && <CallOffProgressBar callOffOrderDetail={callOffOrderDetail} showSupportingText={false} showLabel={false} />}
         <Box justify="between">
            <Typography color="primary">
               {translate('request-for-quotation.quantity-delivered', {
                  quantity: `${callOffOrderColor.recommendedCallOff.consumedQuantity.amount} ${callOffOrderColor.recommendedCallOff.consumedQuantity.unitOfMeasure}`
               })}
            </Typography>
            <Typography color="subtle">
               {translate('request-for-quotation.quantity-remaining', {
                  quantity: `${callOffOrderColor.recommendedCallOff.remainingQuantity.amount} ${callOffOrderColor.recommendedCallOff.remainingQuantity.unitOfMeasure}`
               })}
            </Typography>
         </Box>
         <Divider />
         <Box justify="between">
            <Typography>{translate('request-for-quotation.total-price')}:</Typography>
            <Typography variant="body-highlight">{totalPrice}</Typography>
         </Box>
      </>
   );
};
