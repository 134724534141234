'use client';

import { FC, PropsWithChildren } from 'react';

import { Link } from '@ravago/shared/radiance/components/link/components/Link/Link';

interface Props extends PropsWithChildren {
   underline?: 'none' | 'hover' | 'always';
}

export const MockLink: FC<Props> = ({ underline, children }) => (
   // eslint-disable-next-line jsx-a11y/anchor-is-valid
   <Link underline={underline} href="" onClick={(e) => e.preventDefault()} data-testid="mock-link__link">
      {children}
   </Link>
);
