import { Price, UnitPrice } from '@ravago/shared/page-data/models/elements';

export abstract class CurrencyUtils {
   static localiseUnitPrice(unitPrice: UnitPrice, locales?: string | string[]) {
      const localPrice = CurrencyUtils.localisePrice(unitPrice.price, locales);
      return `${localPrice}/${unitPrice.unitOfMeasure}`;
   }

   static localisePrice(price: Price, locales?: string | string[]) {
      return Intl.NumberFormat(locales, {
         currency: price.currency,
         style: 'currency'
      }).format(price.amount);
   }
}
