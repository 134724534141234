import { useEffect } from 'react';

import { useAuthenticationContext } from '@ravago/shared/authentication';
import { InvoiceForCurrencyForLegalEntity } from '@ravago/shared/page-data/models/elements';

import { useInvoiceDispatchContext } from '../context/invoice-context';
import { FeaturePermissions, Toggle } from '../models/feature-permissions.model';
import { InvoiceAction } from '../state/invoice.actions';
import { sortInvoices } from '../utils/invoice-sort.utils';
import { addInvoicesTotals } from '../utils/totals.utils';

export const useFetchOpenInvoices = (
   getOpenInvoices: (token: string) => Promise<InvoiceForCurrencyForLegalEntity[] | undefined>,
   permissions: FeaturePermissions | null,
   isOpen: boolean
) => {
   const dispatch = useInvoiceDispatchContext();
   const { token } = useAuthenticationContext();

   useEffect(() => {
      if (!token || !permissions?.[Toggle.MY_INVOICES] || !isOpen) {
         return;
      }

      const fetchOpenInvoices = async () => {
         try {
            const invoicesResponse = await getOpenInvoices(token);

            if (invoicesResponse?.length) {
               dispatch({ type: InvoiceAction.SET_OPEN_INVOICES, payload: addInvoicesTotals(sortInvoices(invoicesResponse)) });
            } else {
               dispatch({ type: InvoiceAction.OPEN_INVOICES_NOT_FOUND });
            }
         } catch (error) {
            dispatch({ type: InvoiceAction.OPEN_INVOICES_NOT_FOUND });
         }
      };

      fetchOpenInvoices();
   }, [token, isOpen, getOpenInvoices, permissions, dispatch]);
};
