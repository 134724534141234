'use client';

import { FC, useState } from 'react';

import { ChemicalsSearchRequest, ChemicalsSearchResponse, FacetFilter } from '@ravago/shared/page-data/models/elastic';
import { ProductProperty, ProductPropertyName } from '@ravago/shared/page-data/models/elements';
import { FacetFilterV2Utils } from '@ravago/shared/page-data/utils/facet-filter';
import { Alert } from '@ravago/shared/radiance/components/alert/components/Alert/Alert';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BoxItem } from '@ravago/shared/radiance/components/box/components/BoxItem/BoxItem';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Pagination } from '@ravago/shared/radiance/components/pagination/components/Pagination/Pagination';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableHead } from '@ravago/shared/radiance/components/table/components/TableHead/TableHead';
import { TableHeader } from '@ravago/shared/radiance/components/table/components/TableHeader/TableHeader';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';

import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { EmptyState } from '../../../shared/empty-state/empty-state.component';
import { ProductPropertyConvertorUtils } from '../utils/product-property-convertor.utils';

import { ProductCustomizableTableFacetFilters } from './product-customizable-table-facet-filters';
import { ProductCustomizableTableSearchFilter } from './product-customizable-table-search-filter';

const PAGE_SIZE = 10;

interface Props {
   productProperties: Array<ProductProperty>;
   searchChemicals: (chemicalsSearchRequest: ChemicalsSearchRequest) => Promise<ChemicalsSearchResponse>;
}

export const ProductCustomizableTable: FC<Props> = ({ productProperties, searchChemicals }) => {
   const translate = useTranslations();
   const [pageIndex, setPageIndex] = useState(0);
   const [sortProperty, setSortProperty] = useState<ProductPropertyName>('NAME');
   const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');
   const [searchString, setSearchString] = useState('');
   const [distinctActiveFacetFilters, setDistinctActiveFacetFilters] = useState<Array<FacetFilter>>([]);

   const searchChemicalsQuery = useQuery({
      queryKey: ['product-customizable-table', { pageIndex, sortProperty, sortDirection, searchString, distinctActiveFacetFilters }],
      queryFn: () => {
         const query = searchString.trim();
         const facetFilters = distinctActiveFacetFilters.reduce<Array<FacetFilter>>(
            (acc, facetFilter) => FacetFilterV2Utils.merge(acc, [facetFilter]),
            []
         );
         return searchChemicals({
            page: pageIndex,
            size: PAGE_SIZE,
            sort: { property: sortProperty, direction: sortDirection },
            ...(query.length ? { query } : {}),
            ...(facetFilters.length ? { facetFilters } : {})
         });
      },
      placeholderData: keepPreviousData,
      refetchOnMount: false,
      refetchOnWindowFocus: false
   });

   const handleUpdateSearchFilter = (value: string) => {
      setSearchString(value);
      setPageIndex(0);
   };

   const handleUpdateFacetFilters = (facetFilters: Array<FacetFilter>) => {
      setDistinctActiveFacetFilters(facetFilters);
      setPageIndex(0);
   };

   const handleClearAllFilters = () => {
      setSearchString('');
      setDistinctActiveFacetFilters([]);
   };

   if (!productProperties.length) {
      return (
         <Alert headline={translate('product-customizable-table.misconfiguration.title')} variant="info" data-testid="table__warning">
            {translate('product-customizable-table.misconfiguration.description')}
         </Alert>
      );
   }

   const products = searchChemicalsQuery.data?.searchResult.content;

   const productsWithPropertyValues = products?.map((product) => ({
      id: product.id,
      propertyValues: productProperties.map(({ propertyName }) => ({
         propertyName,
         value: ProductPropertyConvertorUtils.convert(product, propertyName)
      }))
   }));

   const moreThanOnePage =
      searchChemicalsQuery.data && searchChemicalsQuery.data.searchResult.totalElements > searchChemicalsQuery.data.searchResult.numberOfElements;

   const handlePaginationChange = (pageNumber: number) => {
      setPageIndex(pageNumber - 1);
   };

   const handleTableHeaderClick = (propertyName: ProductPropertyName) => {
      if (sortProperty === propertyName) {
         setSortDirection((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'));
      } else {
         setSortProperty(propertyName);
         setSortDirection('ASC');
      }
      setPageIndex(0);
   };

   const renderTableBodyContent = () => {
      if (searchChemicalsQuery.isFetching) {
         const columnResizingMinimizer = productsWithPropertyValues?.map(({ id, propertyValues }) => (
            <tr key={id} style={{ visibility: 'collapse' }}>
               {propertyValues.map(({ propertyName, value }) => (
                  <TableCell key={propertyName}>{value}</TableCell>
               ))}
            </tr>
         ));
         return (
            <>
               <TableRow data-testid="table__row">
                  <TableCell colSpan={productProperties.length}>
                     <Box direction="row" justify="center" spacing={{ top: '3xl', bottom: '3xl' }}>
                        <Spinner data-testid="table__spinner" />
                     </Box>
                  </TableCell>
               </TableRow>
               {columnResizingMinimizer}
            </>
         );
      }

      if (products?.length === 0) {
         return (
            <TableRow>
               <TableCell colSpan={productProperties.length}>
                  <Box direction="column" gap="xl">
                     <EmptyState
                        image={
                           <svg style={{ width: '100%', height: '100%' }} data-testid="table__no-results--image">
                              <use href="/illustrations/empty-state.svg#symbol" />
                           </svg>
                        }
                        title={translate('product-customizable-table.no-results.title')}
                        label={translate('product-customizable-table.no-results.description')}
                        data-testid="table__no-results"
                     />
                     {(searchString || !!distinctActiveFacetFilters.length) && (
                        <Box direction="row" justify="center">
                           <Button variant="secondary" onClick={handleClearAllFilters} data-testid="table__clear-all-filters-button">
                              {translate('product-customizable-table.clear-all-filters')}
                           </Button>
                        </Box>
                     )}
                  </Box>
               </TableCell>
            </TableRow>
         );
      }

      return productsWithPropertyValues?.map(({ id, propertyValues }) => (
         <TableRow key={id} data-testid="table__row">
            {propertyValues.map(({ propertyName, value }) => (
               <TableCell key={propertyName} data-testid="table__cell">
                  {value}
               </TableCell>
            ))}
         </TableRow>
      ));
   };

   const renderPagination = () => {
      if (!moreThanOnePage || searchChemicalsQuery.isFetching) {
         return null;
      }
      return (
         <Box direction="row" justify="center">
            <Pagination
               total={searchChemicalsQuery.data.searchResult.totalPages}
               current={searchChemicalsQuery.data.searchResult.number + 1}
               onChange={handlePaginationChange}
               data-testid="table__pagination"
            />
         </Box>
      );
   };

   return (
      <Box direction="column" gap="sm">
         <ProductCustomizableTableSearchFilter
            disabled={searchChemicalsQuery.isLoading}
            searchString={searchString}
            onUpdateSearchFilter={handleUpdateSearchFilter}
            data-testid="table__search-filter"
         />
         <ProductCustomizableTableFacetFilters
            disabled={searchChemicalsQuery.isLoading}
            productProperties={productProperties}
            aggregatedFacets={searchChemicalsQuery.data?.aggregatedFacets ?? []}
            distinctActiveFacetFilters={distinctActiveFacetFilters}
            onUpdateFacetFilters={handleUpdateFacetFilters}
            data-testid="table__facet-filters"
         />
         <Table stroked={!productsWithPropertyValues ? 'none' : 'horizontal'} data-testid="table__table">
            <TableHead data-testid="table__head">
               <TableRow>
                  {productProperties.map(({ propertyName, label }) => (
                     <TableHeader key={propertyName} data-testid="table__header" onClick={() => handleTableHeaderClick(propertyName)}>
                        <Box direction="row" gap="3xs" justify="start" items="center" className="cursor-pointer">
                           <BoxItem>{label}</BoxItem>
                           {sortProperty === propertyName ? (
                              <Icon icon={sortDirection === 'ASC' ? 'sort-asc' : 'sort-desc'} data-testid="table__sort-direction-icon" />
                           ) : (
                              <div style={{ opacity: 0.15 }}>
                                 <Icon icon="sort" data-testid="table__sort-direction-icon" />
                              </div>
                           )}
                        </Box>
                     </TableHeader>
                  ))}
               </TableRow>
            </TableHead>
            <TableBody>{renderTableBodyContent()}</TableBody>
         </Table>
         {renderPagination()}
      </Box>
   );
};
