import { FC, ReactNode } from 'react';

import classnames from 'classnames';

import { Icon } from '../../../icon/components/Icon/Icon';

const facetColors = [
   'bg-component-data-display-facet-background-color-a text-component-data-display-facet-icon-color-a',
   'bg-component-data-display-facet-background-color-b text-component-data-display-facet-icon-color-b',
   'bg-component-data-display-facet-background-color-c text-component-data-display-facet-icon-color-c',
   'bg-component-data-display-facet-background-color-d text-component-data-display-facet-icon-color-d',
   'bg-component-data-display-facet-background-color-e text-component-data-display-facet-icon-color-e'
];

interface Props {
   facetIcon?: string;
   facetColor?: number;
   trailingIcon?: string;
   onClick?: () => void;
   children?: ReactNode;
}

export const SearchItem: FC<Props> = ({ children, facetIcon, facetColor = 0, trailingIcon, onClick }) => (
   <button
      data-testid={`search-item${onClick ? '--clickable' : ''}`}
      type="button"
      onClick={onClick}
      className={classnames(
         'min-h-component-data-display-facet-container-sizing-min-size-large rounded-component-data-display-search-item-border-radius gap-component-data-display-search-item-container-spacing-gap pr-component-data-display-search-item-container-spacing-padding-x-without-facet bg-component-data-display-search-item-container-color-background-default-enabled flex size-full items-stretch justify-between overflow-hidden',
         !facetIcon ? 'pl-component-data-display-search-item-container-spacing-padding-x-without-facet' : 'pl-component-data-display-search-item-container-spacing-padding-left-with-facet',
         onClick &&
            'after:rounded-r-component-data-display-search-item-border-radius hover:after:bg-component-data-display-search-item-state-layer-color-background-default-hover active:after:bg-component-data-display-search-item-state-layer-color-background-default-pressed focus-visible:after:bg-component-data-display-search-item-state-layer-color-background-default-focused after:left-component-data-display-facet-container-sizing-min-size-large relative cursor-pointer after:absolute after:inset-0 after:transition-all',
         onClick && facetIcon && 'after:left-component-data-display-search-item-facet-container-sizing-min-size'
      )}
   >
      {facetIcon && (
         <div data-testid="search-item-facet" className={classnames('min-w-component-data-display-facet-container-sizing-min-size-large flex items-center justify-center', facetColors[facetColor % Object.keys(facetColors).length])}>
            <Icon icon={facetIcon} color="inherit" size="body" />
         </div>
      )}
      <div className={classnames('gap-component-data-display-search-item-content-spacing-gap py-component-data-display-search-item-content-spacing-padding-y flex size-full flex-col')}>{children}</div>
      {trailingIcon && (
         <div data-testid="search-item-trailing-icon" className="text-component-data-display-search-item-icon-end-color-fill-enabled flex items-center">
            <Icon icon={trailingIcon} color="inherit" size="body" />
         </div>
      )}
   </button>
);
