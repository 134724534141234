import { FacetFilter, FacetFilterWithQuery, FacetFilterWithTerms } from '../../models/elastic';

export class FacetFilterV2Utils {
  static isFacetFilterWithTerms(facetFilter: FacetFilter): facetFilter is FacetFilterWithTerms {
    return 'terms' in facetFilter;
  }

  static isFacetFilterWithQuery(facetFilter: FacetFilter): facetFilter is FacetFilterWithQuery {
    return 'query' in facetFilter;
  }

  static merge(facetFilters1: Array<FacetFilter>, facetFilters2: Array<FacetFilter>): Array<FacetFilter> {
    if (facetFilters1.length === 0) {
      return facetFilters2;
    }
    if (facetFilters2.length === 0) {
      return facetFilters1;
    }

    return [
      ...facetFilters1.map((facetFilter1) => {
        const matchingFacetFilter2 = facetFilters2.find((facetFilter) => facetFilter.facet === facetFilter1.facet);
        if (matchingFacetFilter2) {
          if (FacetFilterV2Utils.isFacetFilterWithTerms(facetFilter1) && FacetFilterV2Utils.isFacetFilterWithTerms(matchingFacetFilter2)) {
            return FacetFilterV2Utils.mergeTermsOfSimilarFacetFiltersWithTerms(facetFilter1, matchingFacetFilter2);
          }
          if (FacetFilterV2Utils.isFacetFilterWithQuery(facetFilter1) && FacetFilterV2Utils.isFacetFilterWithQuery(matchingFacetFilter2)) {
            return FacetFilterV2Utils.mergeQueriesOfSimilarFacetFiltersWithQuery(facetFilter1, matchingFacetFilter2);
          }
        }
        return facetFilter1;
      }),
      ...facetFilters2.filter((facetFilter2) => !facetFilters1.some((facetFilter1) => facetFilter1.facet === facetFilter2.facet))
    ];
  }

  private static mergeTermsOfSimilarFacetFiltersWithTerms(
    facetFilter1: FacetFilterWithTerms,
    facetFilter2: FacetFilterWithTerms
  ): FacetFilterWithTerms {
    return {
      facet: facetFilter1.facet,
      terms: [
        ...facetFilter1.terms.map((term1) => {
          const matchingTerm2 = facetFilter2.terms.find((term2) => term2.term === term1.term);
          if (matchingTerm2) {
            return {
              term: term1.term,
              ...(matchingTerm2.nestedFacets && term1.nestedFacets
                ? { nestedFacets: FacetFilterV2Utils.merge(term1.nestedFacets, matchingTerm2.nestedFacets) as Array<FacetFilterWithTerms> }
                : {})
            };
          }
          return term1;
        }),
        ...facetFilter2.terms.filter((term) => !facetFilter1.terms.some((t) => t.term === term.term))
      ]
    };
  }

  private static mergeQueriesOfSimilarFacetFiltersWithQuery(
    facetFilter1: FacetFilterWithQuery,
    facetFilter2: FacetFilterWithQuery
  ): FacetFilterWithQuery {
    return {
      facet: facetFilter1.facet,
      query: [facetFilter1.query, facetFilter2.query].join(' ')
    };
  }
}
