'use client';

import { FC, useEffect, useRef, useState } from 'react';

import { Icon } from '../../../icon/components/Icon/Icon';
import { List } from '../../../list/components/List/List';
import { ListItem } from '../../../list/components/ListItem/ListItem';
import { Menu } from '../../../menu/components/Menu/Menu';
import { Typography } from '../../../typography/components/Typography/Typography';
import { InputFieldAction } from '../InputFieldAction/InputFieldAction';

export interface DownloadOption {
   id: string;
   value: string;
}

interface Props {
   id: string;
   clearable?: boolean;
   disabled?: boolean;
   label?: string;
   leadingIcon?: string;
   options: Array<DownloadOption>;
   readOnly?: boolean;
   required?: boolean;
   supportText?: string;
   trailingIcon?: string;
   value?: DownloadOption;
   width?: string | number;
   onClear?: () => void;
   onChange?: (value?: DownloadOption) => void;
   onDownload?: (value?: DownloadOption) => void;
}

export const Download: FC<Props> = ({ id, options, clearable = false, disabled = false, label, leadingIcon, readOnly = false, required = false, supportText, trailingIcon, value, width, onClear, onChange, onDownload }) => {
   const [selectedOption, setSelectedOption] = useState(value);
   const [menuOpen, setMenuOpen] = useState(false);

   const inputRef = useRef<HTMLInputElement>(null);

   /* istanbul ignore next */
   const handleChange = (option?: DownloadOption) => {
      setSelectedOption(option);
      onChange?.(option);
      setMenuOpen(false);
   };

   const handleClear = () => {
      setSelectedOption(undefined);
      setMenuOpen(true);
      onChange?.();
      onClear?.();
   };

   /* istanbul ignore next */
   const handleDownload = () => {
      onDownload?.(selectedOption);
   };

   useEffect(() => setSelectedOption(value), [value]);

   return (
      <div data-testid={`download${readOnly ? '--read-only' : ''}${menuOpen ? '--open' : ''}`} style={{ width }} className={`gap-component-input-download-container-spacing-outer-gap flex w-full flex-col ${disabled || readOnly ? 'pointer-events-none' : ''}`}>
         <div className="flex">
            <Menu
               content={
                  <div data-testid="download-menu" className="overflow-y-auto">
                     <List>
                        {options.map((option) => (
                           <ListItem key={option.id} onClick={/* istanbul ignore next */ () => handleChange(option)}>
                              <Typography whiteSpace="nowrap">{option.value}</Typography>
                           </ListItem>
                        ))}
                     </List>
                  </div>
               }
               open={menuOpen}
               maxHeight
               width="fill"
               onMenuClose={/* istanbul ignore next */ () => setMenuOpen(false)}
            >
               <div className="group relative w-full cursor-pointer">
                  {label && (
                     <label
                        data-testid="download-label"
                        htmlFor={id}
                        style={{
                           maxWidth:
                              selectedOption || menuOpen
                                 ? 'calc(100% -  2 * var(--component-input-download-container-spacing-padding-left))'
                                 : 'calc(100% - var(--component-input-download-container-spacing-padding-left) - 2*var(--component-input-download-container-spacing-gap) - var(--component-data-display-icon-container-sizing-width-body))'
                        }}
                        className={`absolute left-0 top-0 flex items-center transition-all cursor-pointer
                           ${!disabled && (selectedOption || menuOpen) ? 'text-component-input-download-label-color-text-neutral-enabled-as-label' : ''}
                           ${!disabled ? 'group-focus-within:text-component-input-download-label-color-text-neutral-focused' : ''}
                           ${!disabled && menuOpen ? 'text-component-input-download-label-color-text-neutral-focused' : ''}
                           ${!disabled && !selectedOption && !menuOpen ? 'text-component-input-download-label-color-text-neutral-enabled-as-placeholder' : ''}
                           ${disabled ? 'text-component-input-download-label-color-text-neutral-disabled' : ''}
                           ${!disabled ? 'text-component-input-download-label-color-text-error-enabled group-hover:!text-component-input-download-label-color-text-error-hover' : ''}
                           ${
                              selectedOption || menuOpen
                                 ? 'bg-component-input-download-container-color-background px-component-input-download-label-spacing-padding-x h-[var(--component-input-download-container-border-width-enabled)] group-focus-within:h-[var(--component-input-download-container-border-width-focused)]'
                                 : 'h-full'
                           }
                           ${menuOpen ? 'h-[var(--component-input-download-container-border-width-focused)]' : ''}
                           ${
                              !selectedOption && leadingIcon && !menuOpen
                                 ? 'phone:ml-[calc(var(--component-input-download-container-spacing-padding-left)+var(--component-input-download-container-spacing-gap)+var(--component-data-display-icon-container-sizing-width-mobile-body))] tab-port:ml-[calc(var(--component-input-download-container-spacing-padding-left)+var(--component-input-download-container-spacing-gap)+var(--component-data-display-icon-container-sizing-width-tablet-body))] tab-land:ml-[calc(var(--component-input-download-container-spacing-padding-left)+var(--component-input-download-container-spacing-gap)+var(--component-data-display-icon-container-sizing-width-tablet-body))] ml-[calc(var(--component-input-download-container-spacing-padding-left)+var(--component-input-download-container-spacing-gap)+var(--component-data-display-icon-container-sizing-width-desktop-body))]'
                                 : 'ml-component-input-download-container-spacing-padding-left'
                           }
                      `.replace(/\s+/g, ' ')}
                     >
                        <Typography variant={selectedOption || menuOpen ? 'caption' : 'body'} color="inherit" maxLines="1">
                           {label}
                           {required && '*'}
                        </Typography>
                     </label>
                  )}

                  <div
                     data-testid="download-container"
                     onClick={
                        /* istanbul ignore next */ () => {
                           inputRef.current?.focus();
                           setMenuOpen(!menuOpen);
                        }
                     }
                     role="presentation"
                     className={`!border-r-0 rounded-l-component-input-download-container-border-radius min-h-component-input-download-container-sizing-min-height gap-component-input-download-container-spacing-gap bg-component-input-download-container-color-background border-component-input-download-container-border-width-enabled box-border flex items-center justify-between overflow-hidden transition-all !border-component-input-dropdown-container-color-border-neutral-enabled hover:!border-component-input-dropdown-container-color-border-neutral-hover focus-within:!border-component-input-dropdown-container-color-border-neutral-focused
                              ${menuOpen ? '!border-component-input-download-container-border-width-focused' : ''}
                              ${menuOpen ? '!border-component-input-download-container-color-border-neutral-enabled' : ''}
                          `.replace(/\s+/g, ' ')}
                  >
                     <div className="pr-component-input-download-container-spacing-gap pl-component-input-download-container-spacing-padding-left gap-component-input-download-container-spacing-gap flex h-full flex-1 justify-between">
                        {leadingIcon && (
                           <div
                              data-testid="download-leading-icon"
                              className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center ${
                                 disabled ? 'text-component-input-download-icon-start-color-text-on-surface-disabled' : 'text-component-input-download-icon-start-color-text-on-surface-enabled'
                              }`.replace(/\s+/g, ' ')}
                           >
                              <Icon icon={leadingIcon} />
                           </div>
                        )}
                        <div className="flex h-full flex-1 items-center">
                           <input
                              data-testid="download-input"
                              className="text-component-input-download-input-color-text-on-surface-enabled disabled:text-component-input-download-input-color-text-on-surface-disabled typography-component-typography-body-regular-md w-full cursor-pointer bg-transparent outline-none"
                              disabled={disabled}
                              id={id}
                              name={id}
                              readOnly
                              ref={inputRef}
                              required={required}
                              value={selectedOption?.value ?? ''}
                           />
                        </div>
                        {clearable && selectedOption && (
                           <div
                              data-testid="download-clear"
                              onClick={handleClear}
                              role="presentation"
                              className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center cursor-pointer ${
                                 disabled ? 'text-component-input-download-icon-end-color-text-on-surface-disabled' : 'text-component-input-download-icon-end-color-text-on-surface-enabled'
                              }`.replace(/\s+/g, ' ')}
                           >
                              <Icon icon="times-circle" />
                           </div>
                        )}
                        {trailingIcon && (
                           <div
                              data-testid="download-trailing-icon"
                              className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center ${
                                 disabled ? 'text-component-input-download-icon-end-color-text-on-surface-disabled' : 'text-component-input-download-icon-end-color-text-on-surface-enabled'
                              }`.replace(/\s+/g, ' ')}
                           >
                              <Icon icon={trailingIcon} />
                           </div>
                        )}
                        <div
                           data-testid="download-chevron"
                           className={`w-component-data-display-icon-container-sizing-size-body m-auto flex aspect-square h-full items-center justify-center transition-all ${
                              disabled ? 'text-component-input-download-icon-end-color-text-on-surface-disabled' : 'text-component-input-download-icon-end-color-text-on-surface-enabled'
                           } ${menuOpen ? 'rotate-180' : ''}`.replace(/\s+/g, ' ')}
                        >
                           <Icon icon="chevron-down" />
                        </div>
                     </div>
                  </div>
               </div>
            </Menu>
            <div className="rounded-r-component-input-download-container-border-radius min-w-component-input-input-field-action-container-sizing-min-size flex h-full items-center justify-center overflow-hidden">
               <InputFieldAction icon="download" disabled={disabled} onClick={handleDownload} />
            </div>
         </div>

         {supportText && (
            <div
               data-testid="download-support-text"
               className={`pl-component-input-download-supporting-text-spacing-padding-left gap-component-input-download-supporting-text-spacing-gap flex items-center

                        ${!disabled ? 'text-component-input-download-supporting-text-color-text-neutral-enabled' : ''}
                        ${disabled ? 'text-component-input-download-supporting-text-color-text-neutral-disabled' : ''}
                        `.replace(/\s+/g, ' ')}
            >
               {supportText && (
                  <Typography variant="caption" color="inherit">
                     {supportText}
                  </Typography>
               )}
            </div>
         )}
      </div>
   );
};
