import { FC } from 'react';

import { Avatar } from '@ravago/shared/radiance/components/avatar/components/Avatar/Avatar';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

interface Props {
   theme: 'primary' | 'inverse-strong';
   avatar?: string;
   name?: string;
}

export const AvatarNameHeader: FC<Props> = ({ theme, avatar, name }) => {
   const { desktop } = useBreakpoint();

   if (!desktop) {
      return (
         <Box direction="column" gap="md" items="start">
            <Avatar size="large" shape="square" src={avatar} />
            <Box height="100%" direction="column" justify="center" gap="2xs">
               <Typography component="h1" variant="heading-2" color={theme}>
                  {name}
               </Typography>
            </Box>
         </Box>
      );
   }
   return (
      <Box direction="row" gap="xl" items="center">
         <Avatar size="extra-large" shape="square" src={avatar} />
         <Box height="100%" direction="column" justify="center" gap="2xs">
            <Typography component="h1" variant="heading-1" maxLines="2" color={theme}>
               {name}
            </Typography>
         </Box>
      </Box>
   );
};
