import { useEffect, useRef } from 'react';

import { useAuthenticationContext } from '@ravago/shared/authentication';
import { Invoice, InvoiceQueryParams } from '@ravago/shared/page-data/models/elements';
import { PaginatedResponse } from '@ravago/shared/page-data/models/elements/paginated-response.model';

import { useInvoiceDispatchContext, useInvoiceStateContext } from '../context/invoice-context';
import { FeaturePermissions, Toggle } from '../models/feature-permissions.model';
import { InvoiceAction } from '../state/invoice.actions';

export const useFetchClosedInvoices = (
   getInvoices: (token: string, query?: InvoiceQueryParams) => Promise<PaginatedResponse<Invoice[]> | undefined>,
   permissions: FeaturePermissions | null,
   isOpen: boolean,
   query: InvoiceQueryParams
) => {
   const { closedInvoices } = useInvoiceStateContext();
   const dispatch = useInvoiceDispatchContext();
   const { token } = useAuthenticationContext();

   const prevInvoiceNumberRef = useRef(query.identifier);

   useEffect(() => {
      const invoiceNumberChanged = prevInvoiceNumberRef.current !== query.identifier;
      const shouldFetch = token && permissions?.[Toggle.MY_INVOICES] && !isOpen && (!closedInvoices?.[query.page] || invoiceNumberChanged);

      if (!shouldFetch) {
         return;
      }

      const fetchClosedInvoices = async () => {
         try {
            const updatedQuery = { ...query, page: query.page - 1 };
            const invoicesResponse = await getInvoices(token, updatedQuery);

            if (invoicesResponse && !invoicesResponse.empty) {
               invoicesResponse.number += 1;
               dispatch({ type: InvoiceAction.SET_CLOSED_INVOICES, payload: invoicesResponse });
            } else {
               dispatch({ type: InvoiceAction.CLOSED_INVOICES_NOT_FOUND });
            }
         } catch (error) {
            dispatch({ type: InvoiceAction.CLOSED_INVOICES_NOT_FOUND });
         }
      };

      fetchClosedInvoices();

      prevInvoiceNumberRef.current = query.identifier;
   }, [token, permissions, isOpen, query.page, query.identifier, getInvoices, dispatch, closedInvoices]);
};
