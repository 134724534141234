import { FC } from 'react';

import { DisplayOrder, OrderLineOpenedFrom } from '@ravago/shared/page-data/models/elements';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Pagination } from '@ravago/shared/radiance/components/pagination/components/Pagination/Pagination';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableHead } from '@ravago/shared/radiance/components/table/components/TableHead/TableHead';
import { TableHeader } from '@ravago/shared/radiance/components/table/components/TableHeader/TableHeader';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../providers/translation-client-context-provider';
import { DateUtils } from '../../../utils/date/date.utils';
import { OrderUtils } from '../../../utils/order/order.utils';
import { OrderLineItem } from '../order/order-line-item';

import { OrderLineTableMenu } from './order-line-table-menu';

interface DesktopOrderLinesTableProps {
   orderLines: DisplayOrder[];
   language: string;
   locale: string;
   startIndex: number;
   endIndex: number;
   tableViewItems: number;
   page: number;
   shipmentBaseUrl: string;
   acceptWebp?: boolean;
   orderDetailBaseUrl?: string;
   callOffAgreementBaseUrl?: string;
   openedFrom: OrderLineOpenedFrom;
   onOrderLineButtonClick: (orderLine: DisplayOrder) => void;
   onPageChange: (newPage: number) => void;
}

export const DesktopOrderLinesTable: FC<DesktopOrderLinesTableProps> = ({
   orderLines,
   language,
   locale,
   acceptWebp,
   shipmentBaseUrl,
   orderDetailBaseUrl,
   callOffAgreementBaseUrl,
   openedFrom,
   onOrderLineButtonClick,
   startIndex,
   endIndex,
   tableViewItems,
   onPageChange,
   page
}) => {
   const translate = useTranslations();

   return (
      <Box direction="column" width="100%">
         <Table rowDividers rounded border hover>
            {openedFrom === 'order_overview_order' && (
               <TableHead>
                  <TableRow>
                     <TableHeader>{translate('my-orders.table.product')}</TableHeader>
                     <TableHeader>{translate('my-orders.table.order-line-number')}</TableHeader>
                     <TableHeader>{translate('my-orders.table.status')}</TableHeader>
                     <TableHeader>{translate('my-orders.table.quantity')}</TableHeader>
                     <TableHeader>{translate('my-orders.table.delivery')}</TableHeader>
                     <TableHeader>{}</TableHeader>
                  </TableRow>
               </TableHead>
            )}
            <TableBody>
               {orderLines
                  .filter((_, index) => index >= startIndex && index < endIndex)
                  .map((orderLine) => (
                     <TableRow key={orderLine.orderNumber} onClick={() => {}}>
                        <TableCell onClick={() => onOrderLineButtonClick(orderLine)} width="360px">
                           <OrderLineItem order={orderLine} language={language} openedFrom={openedFrom} acceptWebp={acceptWebp} />
                        </TableCell>
                        <TableCell onClick={() => onOrderLineButtonClick(orderLine)} width="200px">
                           {translate('my-orders.order-header')} {orderLine.orderNumber}
                        </TableCell>
                        <TableCell onClick={() => onOrderLineButtonClick(orderLine)} width="180px">
                           {orderLine.status === 'Invoiced' ? (
                              <Badge size="regular" variant="warning" weight="bold">
                                 {translate(OrderUtils.getStatusTranslation(orderLine.status))}
                              </Badge>
                           ) : (
                              <Badge size="regular" variant={orderLine.isCallOff ? 'primary' : 'info'} weight="bold">
                                 {translate(OrderUtils.getStatusTranslation(orderLine.status))}
                              </Badge>
                           )}
                        </TableCell>
                        <TableCell onClick={() => onOrderLineButtonClick(orderLine)} width="140px">
                           {orderLine.quantity.amount} {orderLine.quantity.unitOfMeasure}
                        </TableCell>
                        <TableCell onClick={() => onOrderLineButtonClick(orderLine)} width="250px">
                           <Box direction="column" gap="none">
                              {orderLine.isCallOff ? (
                                 <>
                                    <Typography variant="small" color="subtle" component="span">
                                       {translate(orderLine.status === 'Closed' ? 'my-orders.delivered' : 'my-orders.call-off-period')}
                                    </Typography>
                                    <Typography component="span" variant="body-highlight">
                                       {orderLine.deliveryDate ? DateUtils.transformCallOffOrderPeriod(orderLine.deliveryDate) : '-'}
                                    </Typography>
                                 </>
                              ) : (
                                 <>
                                    <Typography variant="small" color="subtle" component="span">
                                       {translate(orderLine.status === 'Closed' ? 'my-orders.delivered' : 'my-orders.estimated-delivery')}
                                    </Typography>
                                    <Typography component="span" variant="body-highlight">
                                       {orderLine.deliveryDate ? DateUtils.transformOrderDeliveryDate(orderLine.deliveryDate) : '-'}
                                    </Typography>
                                 </>
                              )}
                           </Box>
                        </TableCell>
                        <TableCell width="50px">
                           <Box justify="end">
                              <OrderLineTableMenu
                                 orderLine={orderLine}
                                 locale={locale}
                                 language={language}
                                 onOrderLineButtonClick={onOrderLineButtonClick}
                                 orderDetailBaseUrl={orderDetailBaseUrl}
                                 callOffAgreementBaseUrl={callOffAgreementBaseUrl}
                                 shipmentBaseUrl={shipmentBaseUrl}
                              />
                           </Box>
                        </TableCell>
                     </TableRow>
                  ))}
            </TableBody>
         </Table>
         {orderLines.length > tableViewItems && (
            <Box justify="end">
               <Pagination current={page} onChange={onPageChange} total={Math.ceil(orderLines.length / tableViewItems)} />
            </Box>
         )}
      </Box>
   );
};
