import { Icon } from '../Icon/Icon';

interface Props {
   colorCode: string | undefined;
}

export const ColorIcon = ({ colorCode }: Props) => (
   <div style={{ color: colorCode || 'var(--component-input-color-picker-icon-end-color-text-on-surface-enabled)' }}>
      <Icon size={colorCode ? 'body-highlight' : 'body'} icon={colorCode ? 'circle' : 'circle-dashed'} />
   </div>
);
