'use client';

import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import classnames from 'classnames';

import { generateElementId } from '../../../../utils/id/generate-element-id';

interface Props {
   useContainerQuery?: boolean;
   children: ReactNode[];
}

export const CardGrid: FC<Props> = ({ useContainerQuery, children }) => {
   const [visualCards, setVisualCards] = useState(children);

   const cardContainerRef = useRef<HTMLUListElement>(null);

   /* istanbul ignore next */
   const getCardsPerRow = () => {
      if (!cardContainerRef.current) return 0;

      const gapWidth = Number(window.getComputedStyle(cardContainerRef.current).gap.replace('px', ''));
      const cardWidth = cardContainerRef.current.children[0]?.clientWidth;
      const totalWidth = cardContainerRef.current.clientWidth;

      return Math.round((totalWidth + gapWidth) / (cardWidth + gapWidth)) || 1;
   };

   /* istanbul ignore next */
   const resetRowExpansion = useCallback(() => {
      const cardsPerRow = getCardsPerRow();

      const rows = visualCards.reduce((acc: Array<ReactNode[]>, card: ReactNode, index) => {
         const row = Math.floor(index / cardsPerRow);

         if (!acc[row]) acc[row] = [];
         acc[row].push(card);

         return acc;
      }, []);

      const newCards: ReactNode[] = rows.reduce((acc: ReactNode[], row: ReactNode[]) => acc.concat(row), []);

      setVisualCards(newCards);
   }, [visualCards]);

   useEffect(() => {
      window.addEventListener('resize', resetRowExpansion);
      return () => window.removeEventListener('resize', resetRowExpansion);
   }, [resetRowExpansion]);

   useEffect(() => setVisualCards(children), [children]);

   return (
      <ul
         data-testid={useContainerQuery ? 'card-grid--contained' : 'card-grid'}
         ref={cardContainerRef}
         className={classnames(
            `grid w-full list-none grid-cols-[repeat(auto-fill,minmax(var(--component-surfaces-card-container-size-min-width),1fr))]`,
            useContainerQuery && '@phone:gap-component-layout-grid-spacing-gap-mobile @tab-port:gap-component-layout-grid-spacing-gap-tablet @tab-land:gap-component-layout-grid-spacing-gap-tablet @desktop:gap-component-layout-grid-spacing-gap-desktop',
            !useContainerQuery && 'phone:gap-component-layout-grid-spacing-gap-mobile tab-port:gap-component-layout-grid-spacing-gap-tablet tab-land:gap-component-layout-grid-spacing-gap-tablet desktop:gap-component-layout-grid-spacing-gap-desktop'
         )}
      >
         {visualCards.map((card, index) => (
            <li className="list-none p-0" key={generateElementId(`card-grid-card-${index}`)}>
               {card}
            </li>
         ))}
      </ul>
   );
};
