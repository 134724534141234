import { FC, useEffect, useState } from 'react';

import { DisplayOrder, MappedOrders, OrderFilters, ShipmentWithOrderLines } from '@ravago/shared/page-data/models/elements';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BoxItem } from '@ravago/shared/radiance/components/box/components/BoxItem/BoxItem';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { DatePicker } from '@ravago/shared/radiance/components/input-field/components/DatePicker/DatePicker';
import { InputGroup } from '@ravago/shared/radiance/components/input-field/components/InputGroup/InputGroup';
import { Multiselect, MultiselectOption } from '@ravago/shared/radiance/components/input-field/components/Multiselect/Multiselect';
import { Switch } from '@ravago/shared/radiance/components/switch/components/Switch/Switch';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { OrderFilterUtils } from '../../../../utils/order/order-filters.utils';
import { TrackingUtils } from '../../../../utils/tracking/tracking.utils';

export type Props = {
   onSearchOrFilter: (value: MappedOrders | undefined, isFilter: boolean) => void;
   openOrders: MappedOrders | undefined;
   closedOrders: DisplayOrder[] | undefined;
   addressesOptions: MultiselectOption[];
   hasCallOffs: boolean;
   disabled: boolean;
};

export const OrderOverviewSearchFilter: FC<Props> = ({ onSearchOrFilter, openOrders, closedOrders, addressesOptions, hasCallOffs, disabled }) => {
   const translate = useTranslations();
   const [searchValue, setSearchValue] = useState('');
   const [filters, setFilters] = useState<OrderFilters>({
      deliveryDateFrom: '',
      deliveryDateTo: '',
      deliveryAddress: false,
      callOff: false
   });
   const [showFilters, setShowFilters] = useState(false);
   const [totalFilters, setTotalFilters] = useState(0);
   const [addresses, setAddresses] = useState<MultiselectOption[]>([]);
   const [selectedAddresses, setSelectedAddresses] = useState<MultiselectOption[]>([]);
   const { desktop } = useBreakpoint();

   const selectAddress = (options: MultiselectOption[]) => {
      setSelectedAddresses(options);
      const selectedAddressesFiltered = options.filter((option) => option.checked);
      setFilters({ ...filters, deliveryAddress: selectedAddressesFiltered.length > 0 });
   };

   const resetMultiselectAddressFilters = () => {
      const resetSelectedFilters = addressesOptions.map((address) => {
         address.checked = false;
         address.disabled = false;
         return address;
      });

      setSelectedAddresses([]);
      setAddresses(OrderFilterUtils.sortAddressesOptions(resetSelectedFilters));
   };

   const resetMultiselectAddress = () => {
      resetMultiselectAddressFilters();
      setFilters({ ...filters, deliveryAddress: false });
   };

   const resetFilters = () => {
      resetMultiselectAddressFilters();
      setFilters({
         deliveryDateFrom: '',
         deliveryDateTo: '',
         deliveryAddress: false,
         callOff: false
      });
   };

   const checkTotalFiltersBadged = (): number => {
      let total = 0;
      Object.entries(filters).forEach(([key, value]) => {
         if (value !== '' && value !== false) {
            total += 1;
         }
      });

      setTotalFilters(total);
      return total;
   };

   const updateAddresses = (
      isSearchValueChanged: boolean,
      searchOpenOrders: DisplayOrder[],
      searchCloseOrders: DisplayOrder[],
      amountOfFilters: number
   ) => {
      if (isSearchValueChanged || searchValue !== '' || amountOfFilters > 0) {
         let newAddressesOptions = OrderFilterUtils.getAddressesOptions([...searchOpenOrders, ...searchCloseOrders]);
         newAddressesOptions.forEach((newAddress) => {
            const currentSelectAddress = selectedAddresses.find((a) => a.id === newAddress.id);
            if (currentSelectAddress) {
               newAddress.checked = currentSelectAddress.checked;
            }
         });

         if (amountOfFilters > 0 && selectedAddresses.length > 0) {
            const newSelectedAddresses = selectedAddresses
               .map((addressSelected) => {
                  const y = newAddressesOptions.find((address) => address.id === addressSelected.id);
                  addressSelected.disabled = !y;
                  return addressSelected;
               })
               .filter((x) => !newAddressesOptions.includes(x) && x.checked)
               .concat(newAddressesOptions.filter((x) => !selectedAddresses.includes(x) && !x.checked));
            newAddressesOptions = [...newSelectedAddresses];
         }

         setAddresses(OrderFilterUtils.sortAddressesOptions(newAddressesOptions));
      } else if (amountOfFilters === 0 && searchValue === '') {
         setAddresses(OrderFilterUtils.sortAddressesOptions(addressesOptions));
      } else {
         setAddresses(OrderFilterUtils.sortAddressesOptions(selectedAddresses));
      }
   };

   const trackSearch = (value: string) => {
      TrackingUtils.track('click_orders_search_button', {
         searchTerm: value
      });
   };

   const handleFilterAndSearch = (query: string) => {
      const isSearchValueChanged = searchValue !== query;
      const amountOfFilters = checkTotalFiltersBadged();
      const isOpenOrdersNotEmpty = openOrders && openOrders.openOrderLines.length > 0;
      const isCloseOrdersNotEmpty = closedOrders && closedOrders.length > 0;

      let searchOpenOrders: DisplayOrder[] = [];
      let searchCloseOrders: DisplayOrder[] = [];
      let filteredOpenOrders: DisplayOrder[] = [];
      let filteredClosedOrders: DisplayOrder[] = [];
      let filteredInTransitShipmentsOrderLines: ShipmentWithOrderLines[] = [];
      let filteredTransportArrangedShipmentsOrderLines: ShipmentWithOrderLines[] = [];

      setSearchValue(query ?? '');

      if (isOpenOrdersNotEmpty) {
         searchOpenOrders = OrderFilterUtils.onSearch(query, openOrders.openOrderLines);

         filteredOpenOrders = OrderFilterUtils.onFilter(filters, searchOpenOrders, selectedAddresses);

         filteredInTransitShipmentsOrderLines = OrderFilterUtils.onFilterShipmentOrders(
            openOrders.inTransitShipments,
            query,
            filters,
            selectedAddresses
         );
         filteredTransportArrangedShipmentsOrderLines = OrderFilterUtils.onFilterShipmentOrders(
            openOrders.transportArrangedShipments,
            query,
            filters,
            selectedAddresses
         );
      }

      if (isCloseOrdersNotEmpty) {
         searchCloseOrders = OrderFilterUtils.onSearch(query, closedOrders);
         filteredClosedOrders = OrderFilterUtils.onFilter(filters, searchCloseOrders, selectedAddresses);
      }

      const newOrders = {
         closedOrderLines: filteredClosedOrders,
         openOrderLines: filteredOpenOrders,
         inTransitShipments: filteredInTransitShipmentsOrderLines,
         transportArrangedShipments: filteredTransportArrangedShipmentsOrderLines
      };
      if (query === '' && amountOfFilters === 0 && selectedAddresses.length === 0) {
         setAddresses(OrderFilterUtils.sortAddressesOptions(addressesOptions));
      } else {
         updateAddresses(isSearchValueChanged, searchOpenOrders, searchCloseOrders, amountOfFilters);
      }
      onSearchOrFilter?.(newOrders, amountOfFilters > 0 || query !== '');
   };

   const handleSearchButtonClick = (value: string) => {
      handleFilterAndSearch(value);
      trackSearch(value);
   };

   useEffect(() => {
      handleFilterAndSearch(searchValue);
   }, [filters, openOrders, closedOrders, addressesOptions]);

   return (
      <Box direction="column" items="center" margin={{ bottom: desktop ? 'md' : 'none' }}>
         <Box justify="between" width="100%">
            <InputGroup
               id="orders-search-input"
               actionIcon="search"
               autocomplete="off"
               clearable
               label={translate('my-orders.order-overview-search-placeholder')}
               onEnter={handleSearchButtonClick}
               onActionClick={handleSearchButtonClick}
               onClear={() => handleFilterAndSearch('')}
               value={searchValue}
               width="100%"
               disabled={disabled}
            />
            <Box gap="none">
               {desktop ? (
                  <Button
                     id="orders-toggle-filter-btn"
                     leadingIcon="sliders-h"
                     type="button"
                     variant="tertiary"
                     onClick={() => setShowFilters(!showFilters)}
                     disabled={disabled}
                  >
                     {showFilters ? translate('my-orders.order-overview-filter-hide') : translate('my-orders.order-overview-filter-show')}
                  </Button>
               ) : (
                  <IconButton
                     backdrop
                     icon="sliders-h"
                     onClick={() => setShowFilters(!showFilters)}
                     type="button"
                     variant="on-light"
                     disabled={disabled}
                  />
               )}

               <Box position="relative" left={-15} margin="none" spacing="none" gap="none">
                  {totalFilters > 0 && <Badge variant="primary">{totalFilters}</Badge>}
               </Box>
            </Box>
         </Box>
         {showFilters && (
            <Box justify="between" items="center" width="100%" wrap="wrap" gap="xs">
               <BoxItem grow={1} order={0}>
                  <DatePicker
                     id="order-overview-filter-date-picker-from"
                     label={translate('my-orders.order-overview-filter-date-from')}
                     value={filters.deliveryDateFrom}
                     onChange={(date) => setFilters({ ...filters, deliveryDateFrom: date })}
                  />
               </BoxItem>
               <BoxItem grow={1} order={0}>
                  <DatePicker
                     id="order-overview-filter-date-picker-until"
                     label={translate('my-orders.order-overview-filter-date-until')}
                     value={filters.deliveryDateTo}
                     onChange={(date) => setFilters({ ...filters, deliveryDateTo: date })}
                  />
               </BoxItem>
               <BoxItem grow={0} order={0}>
                  <Multiselect
                     clearText="Clear"
                     id="order-overview-filter-delivery-address"
                     label="Delivery address"
                     onChange={selectAddress}
                     onClear={resetMultiselectAddress}
                     options={addresses}
                  />
               </BoxItem>
               <BoxItem grow={1} order={0}>
                  {hasCallOffs && (
                     <Box justify="start" items="center" gap="sm">
                        <Switch
                           id="order-overview-filter-call-off"
                           checked={filters.callOff}
                           onChange={(value) => setFilters({ ...filters, callOff: value })}
                        />
                        <Typography variant="body" component="b" align="left">
                           {translate('my-orders.order-overview-filter-callOff')}
                        </Typography>
                     </Box>
                  )}
               </BoxItem>

               <Button type="button" variant="secondary" onClick={resetFilters} disabled={totalFilters === 0}>
                  {translate('my-orders.order-overview-filter-reset')}
               </Button>
            </Box>
         )}
      </Box>
   );
};
