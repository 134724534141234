'use client';

import React, { useMemo } from 'react';

import { FeatureToggle } from '@ravago/shared/page-data/models/config';
import { ProductRequestType, ViewType } from '@ravago/shared/page-data/models/elastic';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { TabProps } from '@ravago/shared/radiance/components/tabs/components/Tab/Tab';
import { Tabs } from '@ravago/shared/radiance/components/tabs/components/Tabs/Tabs';

import { useTranslations } from '../../../../../../providers/translation-client-context-provider';
import { FeaturePermissionsUtils } from '../../../../../../utils/feature-permissions/feature-permissions.utils';

interface Props {
   language: string;
   searchType: ProductRequestType;
   viewType: ViewType;
   totals: Record<ProductRequestType, number | undefined>;
   features: FeatureToggle[];
   onTabChange?: (tab: 'product' | 'brand' | 'caseStudy') => void;
   switchView?: (view: string) => void;
   hasListViewPermission?: boolean;
   locked?: boolean;
}

export const ProductPortfolioTabs: React.FC<Props> = ({
   language,
   switchView,
   searchType,
   viewType,
   totals,
   features,
   onTabChange,
   hasListViewPermission = false,
   locked = false
}) => {
   const translate = useTranslations();
   const tabs = useMemo(() => {
      const baseTabs: Array<TabProps> = [
         {
            label: `${translate('products-overview-product-tab')}${totals.product !== undefined ? ` (${totals.product})` : ''}`
         },
         {
            label: `${translate('products-overview-brand-tab')}${totals.brand !== undefined ? ` (${totals.brand})` : ''}`
         }
      ];

      if (FeaturePermissionsUtils.hasPermission(features, 'case-studies')) {
         baseTabs.push({
            label: `${translate('products-overview-case-study-tab')}${totals.caseStudy !== undefined ? ` (${totals.caseStudy})` : ''}`
         });
      }

      return baseTabs;
   }, [totals, features, language]);

   const getTabIndex = () => {
      switch (searchType) {
         case 'product':
            return 0;
         case 'brand':
            return 1;
         case 'caseStudy':
            return 2;
         default:
            return 0;
      }
   };

   const getTabByIndex = (index: number) => {
      switch (index) {
         case 0:
            return 'product';
         case 1:
            return 'brand';
         case 2:
            return 'caseStudy';
         default:
            return 'product';
      }
   };

   const listViewOptionAllowed = useMemo(() => hasListViewPermission && searchType === 'product', [hasListViewPermission, searchType]);

   return (
      <Box width="100%">
         <div style={{ pointerEvents: locked ? 'none' : 'unset', width: '100%' }}>
            <Tabs tabs={tabs} activeTab={getTabIndex()} onTabChange={(index) => onTabChange?.(getTabByIndex(index))} />
         </div>
         {listViewOptionAllowed && (
            <Box gap="2xs">
               <IconButton
                  icon="list"
                  backdrop={viewType === 'list'}
                  variant={viewType === 'list' ? 'on-primary' : 'on-light'}
                  onClick={() => (switchView ? switchView('list') : undefined)}
               />
               <IconButton
                  icon="grid-2"
                  backdrop={viewType === 'grid'}
                  variant={viewType === 'grid' ? 'on-primary' : 'on-light'}
                  onClick={() => (switchView ? switchView('grid') : undefined)}
               />
            </Box>
         )}
      </Box>
   );
};
