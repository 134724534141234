export interface InvoiceForCurrencyForLegalEntity {
  legalEntity: string;
  currencyTransactions: CurrencyTransaction[];
}

export interface CurrencyTransaction {
  currency: string;
  transactions: Transaction[];
  totalAmount?: number;
  totalOverDue?: number;
  numberOfInvoices?: number;
}

export interface Transaction {
  id: string;
  eInvoiceId?: string;
  type: TransactionType;
  issueDate: string;
  dueDate?: string;
  openAmount: number;
  documentId?: string;
  amount: number;
}

export enum TransactionType {
  CHEQUE = 'CHEQUE',
  CREDIT_NOTE = 'CREDIT_NOTE',
  DEBIT_NOTE = 'DEBIT_NOTE',
  INVOICE = 'INVOICE',
  PAYMENT = 'PAYMENT',
  UNMATCHED_PAYMENT = 'UNMATCHED_PAYMENT'
}
