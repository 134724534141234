'use client';

import { FC, useEffect, useMemo, useState } from 'react';

import { useAuthenticationContext, UserGroups } from '@ravago/shared/authentication';
import { FeatureToggle, UOM } from '@ravago/shared/page-data/models/config';
import { RelatedDocument } from '@ravago/shared/page-data/models/elements';
import { DynamicPageUrl, RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';
import { MultiselectOption } from '@ravago/shared/radiance/components/input-field/components/Multiselect/Multiselect';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import notification from '@ravago/shared/radiance/utils/notification/notification';

import { saveAs } from 'file-saver';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { firstBy } from 'thenby';

import { useDialogContext } from '../../../../hooks/use-dialog-context';
import { useDocumentShare } from '../../../../hooks/use-document-share';
import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { CorrelationService } from '../../../../services/tracking/correlation-token.service';
import { FeaturePermissionsUtils } from '../../../../utils/feature-permissions/feature-permissions.utils';
import { getLanguageCode, getLanguageNameFromLanguageCode } from '../../../../utils/locale/locale.utils';
import SanitizeDataUtils from '../../../../utils/sanitize-data/sanitize-data.utils';
import { TrackingUtils } from '../../../../utils/tracking/tracking.utils';
import NotFoundIllustration from '../../../illustrations/not-found-illustration';
import { EmptyState } from '../../../shared/empty-state/empty-state.component';
import { Link } from '../../../shared/link/link';
import { DynamicPageLink } from '../../dynamic-page-link/dynamic-page-link';

import { RelatedDocumentCategory } from './related-document-category/related-document-category';
import { RelatedDocumentHeader } from './related-document-header/related-document-header';

export const getStandardTdsDocument = (): RelatedDocument => ({
   id: 'STANDARD_TDS',
   name: 'Technical_Data_Sheet.pdf',
   category: 'Datasheets',
   typeId: '48',
   type: 'TDS',
   pkbId: 1,
   description: 'Technical Data Sheet',
   extension: 'pdf',
   language: 'en',
   rank: 1,
   isBrandedTds: false,
   isInternal: false,
   isAvailable: true,
   isStandardTDS: true
});

interface EmptyStateVars {
   noResult: string[];
   goTo: string;
   noSearchResult: string;
}

interface Props {
   dynamicPage: { type?: string; id?: string; extraId?: string; detailPageName?: string };
   contentTypeOverviewBaseRoute?: DynamicPageUrl;
   hasPublicTdsDownloadPermission: boolean;
   hasPublicShowTdsUomPermission: boolean;
   toRequestQuotation: { href?: string; target: string; title: string; disabled: boolean };
   channelUOMs: UOM[];
   ulProspectorId: number | undefined;
   documentAnchor?: string;
   globals: RendererGlobals;
}

export const RelatedDocumentList: FC<Props> = ({
   hasPublicTdsDownloadPermission,
   dynamicPage,
   contentTypeOverviewBaseRoute,
   toRequestQuotation,
   hasPublicShowTdsUomPermission,
   channelUOMs,
   globals,
   ulProspectorId,
   documentAnchor
}) => {
   const translate = useTranslations();
   const {
      locale,
      actions: { downloadDocument, downloadTdsDocument, downloadPublicTdsDocument, getPrivateToggles, getPrivateDocuments, getPublicDocuments }
   } = globals;
   const language = getLanguageCode(locale);
   const [search, setSearch] = useState<string>('');
   const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
   const [permissions, setPermissions] = useState<FeatureToggle[]>([]);
   const [permissionsHaveBeenSet, setPermissionsHaveBeenSet] = useState<boolean>(false);
   const [category, setCategory] = useState<string | undefined>(undefined);
   const [filterLanguages, setFilterLanguages] = useState<string[] | undefined>();
   const [accordionsOpen, setAccordionsOpen] = useState<number[]>([]);
   const [relatedDocuments, setRelatedDocuments] = useState<{ [id: string]: Array<RelatedDocument> } | undefined>();
   const [sharedDocuments, setSharedDocuments] = useState<Array<RelatedDocument> | undefined>();
   const [openTDS, setOpenTDS] = useState<boolean>(false);
   const { isAuthenticated, token, user } = useAuthenticationContext();
   const router = useRouter();
   const { shareDocumentList, setShareList, downloadDocumentList, setDownloadList } = useDocumentShare();
   const queryParams = useSearchParams();
   const pathname = usePathname();
   const { state, dispatch } = useDialogContext();

   const hasShowTdsUomPermission = FeaturePermissionsUtils.hasPermission(permissions, 'product-documents-tds-uom');
   const hasSharePermission = FeaturePermissionsUtils.hasPermission(permissions, 'share-page');

   const getEmptyState: EmptyStateVars = useMemo(() => {
      switch (dynamicPage.type) {
         case 'products':
            return {
               noSearchResult: 'product.documents.no-search-results-found',
               goTo: 'related-documents.go-to-products',
               noResult: ['product.documents.no-results-found-1', 'product.documents.no-results-found-2']
            };
         case 'technologies':
            return {
               noSearchResult: 'product.documents.no-search-results-found-technology',
               goTo: 'related-documents.go-to-technologies',
               noResult: ['product.documents.no-results-found-technology-1', 'product.documents.no-results-found-technology-2']
            };
         case 'brands':
            return {
               noSearchResult: 'product.documents.no-search-results-found-brand',
               goTo: 'related-documents.go-to-brands',
               noResult: ['product.documents.no-results-found-brand-1', 'product.documents.no-results-found-brand-2']
            };
         case 'markets':
            return {
               noSearchResult: 'product.documents.no-search-results-found-market',
               goTo: 'related-documents.go-to-markets',
               noResult: ['product.documents.no-results-found-market-1', 'product.documents.no-results-found-market-2']
            };
         default:
            return { noResult: [''], goTo: '', noSearchResult: '' };
      }
   }, [dynamicPage.type]);

   const tdsPopulatedOptions: { [p: string]: RelatedDocument[] } | undefined = useMemo(() => {
      if (
         dynamicPage.type !== 'products' ||
         !relatedDocuments ||
         (!ulProspectorId && (!relatedDocuments || Object.values(relatedDocuments).flat().length === 0)) ||
         relatedDocuments?.Datasheets?.some((document) => document.type?.toUpperCase() === 'TDS')
      ) {
         return relatedDocuments;
      }

      const newRelatedDocuments = { ...relatedDocuments };
      if (newRelatedDocuments.Datasheets) newRelatedDocuments.Datasheets = [...newRelatedDocuments.Datasheets, getStandardTdsDocument()];
      else newRelatedDocuments.Datasheets = [getStandardTdsDocument()];
      return newRelatedDocuments;
   }, [dynamicPage.type, relatedDocuments, ulProspectorId]);

   const visualizedOptions: { key: string; documents: RelatedDocument[] }[] = useMemo(
      () =>
         tdsPopulatedOptions
            ? Object.entries(tdsPopulatedOptions)
                 .map(([key, value]) => ({
                    key,
                    documents: value
                 }))
                 .sort((option) => (option.key === 'Datasheets' ? -1 : 0))
            : [],
      [tdsPopulatedOptions]
   );

   const getAvailableLanguages = useMemo(() => {
      const normalized = visualizedOptions
         .map((value) => value.documents)
         .flat()
         .filter((d) => d.language !== '')
         .map((d) => d.language.toLowerCase())
         .reduce((entities, value) => ({ ...entities, [value]: value }), {});
      return Object.keys(normalized);
   }, [visualizedOptions]);

   const getAvailableLanguagesOptions = useMemo(
      () =>
         getAvailableLanguages
            .map(
               (key): MultiselectOption => ({
                  id: key,
                  checked: filterLanguages?.includes(key) ?? false,
                  label: getLanguageNameFromLanguageCode(key, locale)
               })
            )
            .sort(firstBy((l) => l, { ignoreCase: true })),
      [filterLanguages, getAvailableLanguages]
   );

   const hasExtraInitialLanguage = useMemo(
      () => language.toLowerCase() !== 'en' && getAvailableLanguagesOptions.map((l) => l.id).includes(language.toLowerCase()),
      [getAvailableLanguagesOptions, language]
   );

   const isFilterApplied = useMemo(
      () => !!category || !filterLanguages || (filterLanguages && filterLanguages.length !== (hasExtraInitialLanguage ? 2 : 1)),
      [category, filterLanguages, hasExtraInitialLanguage]
   );

   const isFilterOrSearchApplied = useMemo(() => search !== '' || isFilterApplied, [search, isFilterApplied]);

   const filteredOptions = useMemo(() => {
      let filtered = visualizedOptions;
      filtered = filtered.map((value) => ({
         key: value.key,
         documents: value.documents.filter((document) => filterLanguages?.includes(document.language.toLowerCase()))
      }));
      if (category) filtered = filtered.filter((value) => value.key === category);
      if (search)
         filtered = filtered.map((value) => ({
            key: value.key,
            documents: value.documents.filter(
               (document) =>
                  document.name.toLowerCase().includes(search) ||
                  document.description.toLowerCase().includes(search) ||
                  document.type.toLowerCase().includes(search)
            )
         }));

      return filtered.filter((value) => value.documents.length > 0);
   }, [search, visualizedOptions, category, filterLanguages]);

   const getCategories = () => filteredOptions.map((value) => ({ id: value.key, value: value.key }));

   const getAmountOfDocumentCategories = () => filteredOptions.length;

   const handleSearchChange = (searchChange: string) => {
      setSearch(searchChange);
   };
   const handleSearchClear = () => {
      setSearch('');
   };

   const handleCategoryChange = (categoryChange: DropdownOption | undefined) => {
      setCategory(categoryChange?.value);
   };

   const handleCategoryClear = () => {
      setCategory(undefined);
   };

   const getNumberOfFilters = () => ((category ? 1 : 0) + (filterLanguages?.length ?? 0)).toString();

   const handleLanguageChange = (languageChange: MultiselectOption[]) => {
      const formattedLanguages = languageChange.filter((languageOption) => languageOption.checked).map((value) => value.id);
      setFilterLanguages(formattedLanguages.length > 0 ? formattedLanguages : undefined);
   };

   const handleLanguageClear = () => {
      setFilterLanguages(hasExtraInitialLanguage ? ['en', language.toLowerCase()] : ['en']);
   };

   const handleIsFilterOpenToggle = (open: boolean) => {
      setIsFilterOpen(open);
   };

   const handleIsDocumentLoading = (isDownloading: boolean, documentName?: string) => {
      if (dispatch) {
         dispatch({ type: isDownloading ? 'open_dialog' : 'close_dialog', value: { dialogType: 'document-is-downloading', title: documentName } });
      }
   };

   const handleAllDocumentsAreLoading = (isDownloading: boolean) => {
      if (dispatch) {
         dispatch({ type: isDownloading ? 'open_dialog' : 'close_dialog', value: { dialogType: 'multiple-documents-are-loading' } });
      }
   };

   const getLocalStorageWarningRemover = () => {
      const previousTimeStampString = localStorage.getItem('hideInteralDocumentWarning');
      const currentTimeStamp = new Date();
      if (!previousTimeStampString) return false;
      const maxTimeStamp = new Date(previousTimeStampString);
      maxTimeStamp.setDate(maxTimeStamp.getDate() + 15); // this number indicates 15 days, after that the warning will be shown again
      return currentTimeStamp < maxTimeStamp;
   };

   const trackTdsDownload = (authToken: string, document: { id: string; title: string }) => {
      Promise.all([globals.actions.getAllOrderedProducts(authToken), globals.actions.getAllQuotations(authToken)]).then(([products, quotations]) => {
         const productIds = products.content.filter((prod) => !!prod.baseProductId).map((product) => product.baseProductId!.toString());
         productIds.push(
            ...quotations.filter((quotation) => !!quotation.product.baseProductId).map((quotation) => quotation.product.baseProductId!.toString())
         );

         const trackingInfo = {
            product: {
               id: dynamicPage.id,
               name: dynamicPage.detailPageName
            },
            document,
            correlationId: CorrelationService.getCorrelationId('dpcid')
         };
         if (productIds.includes(dynamicPage.id ?? '')) {
            TrackingUtils.track('tds_download_previously_purchased', trackingInfo);
         } else {
            TrackingUtils.track('tds_download_new_lead', trackingInfo);
         }
      });
   };

   const handleDownload = async (
      document: RelatedDocument,
      selectedLanguageId: string | undefined,
      selectedUOMId: string | undefined,
      isWarningPossible = true,
      showIndiviualLoadingScreen = true
   ) => {
      if (token) {
         let downloadingName = document.name;
         if (document.isInternal && isWarningPossible && !getLocalStorageWarningRemover()) {
            if (dispatch) dispatch({ type: 'open_dialog', value: { dialogType: 'internal-document-warning', documentId: document.id } });
            return;
         }
         if (document.isInternal) {
            downloadingName = document.name.replace(new RegExp(`.${document.extension}$`), `_INTERNAL-USE-ONLY.${document.extension}`);
         }
         if (showIndiviualLoadingScreen) handleIsDocumentLoading(true, document.name);
         const blob = document.isBrandedTds
            ? await downloadTdsDocument(
                 `${dynamicPage.type}/${dynamicPage.id}/documents/${document.id}`,
                 selectedLanguageId ?? document.language,
                 selectedUOMId === 'imperial' ? 'IMPERIAL' : 'METRIC',
                 token,
                 CorrelationService.generateCorrelationId('dpcid')
              ).catch(() => {
                 if (showIndiviualLoadingScreen) handleIsDocumentLoading(false);
                 notification(translate('shipment.documents.download-error'));
                 return undefined;
              })
            : await downloadDocument(
                 `${dynamicPage.type}/${dynamicPage.id}/documents/${document.id}`,
                 token,
                 CorrelationService.generateCorrelationId('dpcid')
              ).catch(() => {
                 if (showIndiviualLoadingScreen) handleIsDocumentLoading(false);
                 notification(translate('shipment.documents.download-error'));
                 return undefined;
              });
         if (document.isBrandedTds || document.type.toUpperCase() === 'TDS') {
            trackTdsDownload(token, { id: document.id, title: document.description });
         }
         if (blob) saveAs(blob, downloadingName);
         if (showIndiviualLoadingScreen) handleIsDocumentLoading(false);
      } else if (document.isBrandedTds) {
         if (showIndiviualLoadingScreen) handleIsDocumentLoading(true, document.name);
         const blob = await downloadPublicTdsDocument(
            selectedLanguageId ?? document.language,
            document.id,
            CorrelationService.generateCorrelationId('dpcid'),
            selectedUOMId === 'imperial' ? 'IMPERIAL' : 'METRIC'
         ).catch(() => {
            if (showIndiviualLoadingScreen) handleIsDocumentLoading(false);
            notification(translate('shipment.documents.download-error'));
            return undefined;
         });
         if (blob) saveAs(blob, 'Technical-Data-Sheet.pdf');
         if (showIndiviualLoadingScreen) handleIsDocumentLoading(false);
      }
   };

   const handleAllDownload = async (documents: RelatedDocument[]) => {
      const results = [];
      const allDocuments = visualizedOptions.map((byCat) => byCat.documents).flat();
      for (let i = 0; i < documents.length; i += 1) {
         const document = allDocuments.find((doc) => doc.id === documents[i].id);
         if (document) results.push(handleDownload(document, undefined, undefined, false, false));
      }
      handleAllDocumentsAreLoading(true);
      await Promise.all(results);
      handleAllDocumentsAreLoading(false);
      setDownloadList([]);
   };

   const handleClearAll = () => {
      handleLanguageClear();
      handleCategoryClear();
      handleSearchClear();
   };

   const handleAccordionsOpen = (index: number | number[]) => {
      if (Array.isArray(index)) {
         setAccordionsOpen(index);
         return;
      }
      if (accordionsOpen.includes(index)) {
         setAccordionsOpen(accordionsOpen.filter((value) => value !== index));
         return;
      }
      setAccordionsOpen([...accordionsOpen, index]);
   };

   const handleShareChecked = (document: RelatedDocument) => {
      const index = shareDocumentList.documents.findIndex((doc) => doc.id === document.id && doc.type === document.type);
      if (index >= 0) {
         const newshareDocumentList = { ...shareDocumentList };
         newshareDocumentList.documents = shareDocumentList.documents.filter((_, i) => i !== index);
         setShareList(newshareDocumentList);
      } else {
         setShareList({
            documents: [...shareDocumentList.documents, document],
            detailPageName: dynamicPage.detailPageName ?? ''
         });
      }
   };

   const getSharedDocumentsFromLocalStorage = () => {
      const shared = localStorage.getItem('sharedDocuments');
      if (!shared) {
         setSharedDocuments(undefined);
         return;
      }

      const parsedShared = JSON.parse(shared);
      if (!relatedDocuments || !SanitizeDataUtils.isArrayOfString(parsedShared)) return;
      if (parsedShared[0] === `${dynamicPage.type}-${dynamicPage.id}`) {
         setSharedDocuments(
            Object.values(relatedDocuments)
               .flat()
               .filter((doc) => parsedShared.includes(doc.id) && !doc.isInternal)
         );
      }
   };

   const transformDocumentsByCategory = (documents: RelatedDocument[]): { [id: string]: Array<RelatedDocument> } =>
      documents.reduce(
         (entities: any, value: any) => ({
            ...entities,
            [value.category]: entities[value.category] ? entities[value.category].concat(value) : [value]
         }),
         {}
      );

   useEffect(() => {
      setFilterLanguages(hasExtraInitialLanguage ? ['en', language.toLowerCase()] : ['en']);
   }, [hasExtraInitialLanguage]);

   useEffect(() => {
      if (downloadDocumentList.length > 0) {
         handleAllDownload(downloadDocumentList);
      }
   }, [downloadDocumentList]);

   useEffect(() => {
      if (!token) return;
      getPrivateToggles(token).then((toggles) => {
         setPermissions(toggles);
         setPermissionsHaveBeenSet(true);
      });
   }, [token]);

   useEffect(() => {
      if (state.internal?.canBeDownloaded && state.documentId) {
         const document = visualizedOptions
            .map((byCat) => byCat.documents)
            .flat()
            .find((doc) => doc.id === state.documentId);
         if (document) handleDownload(document, undefined, undefined, false);
      }
   }, [state.documentId, state.internal?.canBeDownloaded]);

   useEffect(() => {
      const getItemsOnClient = async (): Promise<void> => {
         const fetchedRelatedDocuments: RelatedDocument[] = await getPublicDocuments(
            `${dynamicPage.type}/${dynamicPage.id}/documents`,
            dynamicPage.extraId
         );

         if (fetchedRelatedDocuments) {
            setRelatedDocuments(transformDocumentsByCategory(fetchedRelatedDocuments));
         }
      };

      const getItemsOnServer = async (): Promise<void> => {
         if (!permissionsHaveBeenSet) return;

         if (token) {
            const hasVerifiedUserAccessDownload = FeaturePermissionsUtils.hasPermission(permissions, 'product-documents-download-verified-user');
            const isUnverifiedUser =
               user?.groups?.includes(UserGroups.digitalPlatformGuest) || user?.groups?.includes(UserGroups.digitalPlatformPendingVerification);
            const hasInternalDocumentsPermission = FeaturePermissionsUtils.hasPermission(permissions, 'internal-documents');

            const fetchedRelatedDocuments: RelatedDocument[] = await getPrivateDocuments(
               `${dynamicPage.type}/${dynamicPage.id}/documents`,
               dynamicPage.extraId,
               hasInternalDocumentsPermission,
               hasVerifiedUserAccessDownload && !isUnverifiedUser,
               token
            );
            if (fetchedRelatedDocuments) {
               setRelatedDocuments(transformDocumentsByCategory(fetchedRelatedDocuments));
            }
         }
      };

      if (isAuthenticated) {
         getItemsOnServer();
      } else {
         getItemsOnClient();
      }
   }, [isAuthenticated, permissionsHaveBeenSet]);

   useEffect(() => {
      if (isFilterOrSearchApplied) handleAccordionsOpen([...Array(visualizedOptions.length).keys()]);
      else handleAccordionsOpen([]);
   }, [isFilterOrSearchApplied]);

   useEffect(() => {
      const documentIds = queryParams.get('documentIds');
      if (documentIds) {
         localStorage.setItem('sharedDocuments', JSON.stringify([`${dynamicPage.type}-${dynamicPage.id}`, ...documentIds.split(';')]));
         if (documentAnchor) router.push(`${pathname}#${documentAnchor}`);
         else router.push(pathname);
      }
   }, []);

   useEffect(() => {
      getSharedDocumentsFromLocalStorage();
   }, [relatedDocuments, queryParams]);

   useEffect(() => {
      if (!openTDS) return; // Nothing to do
      if (!relatedDocuments) return; // Documents are still loading, handle this when the relatedDocuments are loaded

      if (filteredOptions?.some((option) => option.key === 'Datasheets') && !accordionsOpen.includes(0)) {
         setAccordionsOpen((prev) => [...prev, 0]);
      }
      setOpenTDS(false);
   }, [filteredOptions, openTDS]);

   useEffect(() => {
      const handleCheckTdsClick = () => setOpenTDS(true);

      window.addEventListener('onCheckTds', handleCheckTdsClick);
      return () => {
         window.removeEventListener('onCheckTds', handleCheckTdsClick);
      };
   }, []);

   return (
      <Box direction="column" gap="lg">
         <RelatedDocumentHeader
            disableAllFilters={!isFilterOrSearchApplied && getAmountOfDocumentCategories() === 0}
            filteredOptions={filteredOptions}
            availableLanguages={getAvailableLanguagesOptions}
            isFilterOpen={isFilterOpen}
            isFilterApplied={isFilterApplied}
            search={search}
            category={category}
            categories={getCategories()}
            numberOfFilters={getNumberOfFilters()}
            onCategoryChange={(cat) => handleCategoryChange(cat)}
            onCategoryClear={() => handleCategoryClear()}
            onClearAll={() => handleClearAll()}
            onLanguageChange={(lang) => handleLanguageChange(lang)}
            onLanguageClear={() => handleLanguageClear()}
            onSearch={(searchChange) => handleSearchChange(searchChange)}
            onSetFilterOpen={(open) => handleIsFilterOpenToggle(open)}
            onSearchClear={() => handleSearchClear()}
         />
         {!relatedDocuments && (
            <Box direction="column" items="center">
               <Spinner />
            </Box>
         )}
         {sharedDocuments && (
            <RelatedDocumentCategory
               onDismiss={() => getSharedDocumentsFromLocalStorage()}
               channelUOMs={channelUOMs.map((uom) => ({ ...uom, value: translate(uom.value) }))}
               onDocumentDownload={(document, selectedLanguageId, selectedUOMId) => handleDownload(document, selectedLanguageId, selectedUOMId)}
               onShareChecked={(document) => handleShareChecked(document)}
               hasSharePermission={hasSharePermission}
               publicShowTdsUom={hasPublicShowTdsUomPermission}
               showTdsUom={hasShowTdsUomPermission}
               hasPublicTdsDownloadPermission={hasPublicTdsDownloadPermission}
               locale={locale}
               detailPageName={dynamicPage.detailPageName}
               documents={sharedDocuments}
               sharedWithYou
               ulProspectorId={ulProspectorId?.toString()}
            />
         )}
         {getAmountOfDocumentCategories() > 0 && relatedDocuments && (
            <Box direction="column">
               {filteredOptions.map((value, index) => (
                  <RelatedDocumentCategory
                     channelUOMs={channelUOMs.map((uom) => ({ ...uom, value: translate(uom.value) }))}
                     onDocumentDownload={(document, selectedLanguageId, selectedUOMId) => handleDownload(document, selectedLanguageId, selectedUOMId)}
                     onShareChecked={(document) => handleShareChecked(document)}
                     hasSharePermission={hasSharePermission}
                     ulProspectorId={ulProspectorId?.toString()}
                     publicShowTdsUom={hasPublicShowTdsUomPermission}
                     showTdsUom={hasShowTdsUomPermission}
                     hasPublicTdsDownloadPermission={hasPublicTdsDownloadPermission}
                     locale={locale}
                     key={value.key}
                     detailPageName={dynamicPage.detailPageName}
                     documents={value.documents}
                     accordionTitle={value.key}
                     onToggle={() => handleAccordionsOpen(index)}
                     accordionOpen={accordionsOpen.includes(index)}
                  />
               ))}
            </Box>
         )}
         {getAmountOfDocumentCategories() === 0 && relatedDocuments && (
            <Box direction="column" items="center">
               <EmptyState
                  label={isFilterOrSearchApplied ? translate(getEmptyState.noSearchResult) : getEmptyState.noResult.map((label) => translate(label))}
                  image={<NotFoundIllustration />}
                  title={translate('related-documents.empty-title')}
               />
               <BreakpointSwitch includeViews={['phone']}>
                  <Box direction="column" justify="center">
                     <DynamicPageLink
                        id="dynamic-link__document-list__product-overview__phone"
                        locale={locale}
                        baseRoute={contentTypeOverviewBaseRoute}
                     >
                        <Button variant="secondary">{translate(getEmptyState.goTo)}</Button>
                     </DynamicPageLink>
                     {user &&
                        !user?.groups?.includes(UserGroups.digitalPlatformGuest) &&
                        !user?.groups?.includes(UserGroups.digitalPlatformPendingVerification) &&
                        dynamicPage.type === 'products' && (
                           <Link
                              disabled={toRequestQuotation.disabled}
                              underline="none"
                              href={toRequestQuotation.href ?? ''}
                              target={toRequestQuotation.target}
                              title={toRequestQuotation.title}
                           >
                              <Button disabled={toRequestQuotation.disabled} variant="primary">
                                 {translate('related-documents.request')}
                              </Button>
                           </Link>
                        )}
                  </Box>
               </BreakpointSwitch>
               <BreakpointSwitch excludeViews={['phone']}>
                  <Box direction="row" justify="center">
                     {isFilterOrSearchApplied ? (
                        <Button onClick={() => handleClearAll()} variant="secondary">
                           {translate('related-documents.reset-search')}
                        </Button>
                     ) : (
                        <DynamicPageLink
                           id="dynamic-link__document-list__product-overview"
                           locale={locale}
                           underline="none"
                           baseRoute={contentTypeOverviewBaseRoute}
                        >
                           <Button variant="secondary">{translate(getEmptyState.goTo)}</Button>
                        </DynamicPageLink>
                     )}
                     {user &&
                        !user?.groups?.includes(UserGroups.digitalPlatformGuest) &&
                        !user?.groups?.includes(UserGroups.digitalPlatformPendingVerification) &&
                        dynamicPage.type === 'products' && (
                           <Link
                              disabled={toRequestQuotation.disabled}
                              underline="none"
                              href={toRequestQuotation.href ?? ''}
                              target={toRequestQuotation.target}
                              title={toRequestQuotation.title}
                           >
                              <Button disabled={toRequestQuotation.disabled} variant="primary">
                                 {translate('related-documents.request')}
                              </Button>
                           </Link>
                        )}
                  </Box>
               </BreakpointSwitch>
            </Box>
         )}
      </Box>
   );
};
