'use client';

import { PropsWithChildren, useEffect, useRef } from 'react';

import { createPortal } from 'react-dom';

interface Props extends PropsWithChildren {
   selector: string;
}

/* istanbul ignore next */
export const ClientPortal = ({ selector, children }: Props) => {
   const ref = useRef<Element | null>(null);

   useEffect(() => {
      ref.current = document.querySelector(selector);
   }, [selector]);

   return ref.current ? createPortal(children, ref.current) : null;
};
