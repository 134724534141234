'use client';

import { FC, useMemo } from 'react';

import { useAuthenticationContext, UserGroups } from '@ravago/shared/authentication';
import { ProductCard as ProductCardModel } from '@ravago/shared/page-data/models/elements';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { ProductCard as ProductCardRadiance } from '@ravago/shared/radiance/components/card/components/ProductCard/ProductCard';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';

import { useProductCompare } from '../../../../hooks/use-product-compare';
import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { UrlUtils } from '../../../../utils/url/url.utils';

import { ProductCardBadge } from './components/product-card-badge';

interface Props {
   product: ProductCardModel;
   locale: string;
   isConsumer: boolean;
   hasPublicLabelAndSigningsPermission?: boolean;
   hasSustainabilityLabels?: boolean;
   hasProductCompareFeaturePermission?: boolean;
   acceptWebp?: boolean;
   assetBasePath: string;
   baseRoute?: string;
   useStandardCardBanner?: boolean;
}

export const ProductCard: FC<Props> = ({
   product,
   locale,
   isConsumer,
   hasPublicLabelAndSigningsPermission = false,
   hasSustainabilityLabels = false,
   hasProductCompareFeaturePermission = false,
   acceptWebp,
   assetBasePath,
   baseRoute,
   useStandardCardBanner
}) => {
   const translate = useTranslations();
   const { user } = useAuthenticationContext();

   const { productsToCompareList, addProductToCompareList, removeProductFromCompareList } = useProductCompare();

   const isChecked = useMemo(() => productsToCompareList.some((productId) => productId === product.compareId), [product, productsToCompareList]);

   const handleProductCompare = () => {
      if (!product.compareId) return;

      const newChecked = !isChecked;

      if (newChecked) {
         addProductToCompareList(product.compareId);
      } else {
         removeProductFromCompareList(product.compareId);
      }
   };

   const background = useStandardCardBanner
      ? `${assetBasePath}/images/placeholders/default-banner-small.${acceptWebp ? 'webp' : 'png'}`
      : product.image;

   const card = (
      <ProductCardRadiance
         href={UrlUtils.getRoute(locale, baseRoute, product.slug, undefined)}
         target="_self"
         noFollow={product.noFollow}
         background={
            background
               ? `url('${background}') center center / cover no-repeat var(--component-surfaces-overlay-container-color-background-scrimmed-primary)`
               : ''
         }
         name={product.name}
         producer={product.producer}
         polymerType={product.polymerGroup}
         technologies={product.technologies}
         features={product.features}
         fillers={product.fillers}
         badgLeft={
            product.isPreferred &&
            hasPublicLabelAndSigningsPermission &&
            (user?.groups?.includes(UserGroups.ravagoSalesRepresentative) || !isConsumer) ? (
               <Badge variant="info">
                  <Icon icon="gem" />
               </Badge>
            ) : undefined
         }
         badgeRight={
            hasPublicLabelAndSigningsPermission && <ProductCardBadge badgeInfo={product} hasSustainabilityLabels={hasSustainabilityLabels} />
         }
         compareLabel={translate('product.compare-product')}
         toggleState={isChecked}
         fillHeight
         compareDisabled={!isChecked && productsToCompareList.length >= 5}
         onToggleCompare={hasProductCompareFeaturePermission ? handleProductCompare : undefined}
      />
   );

   return !isConsumer ? <span style={{ pointerEvents: 'none' }}>{card}</span> : card;
};
