import { FC } from 'react';

import { ContentfulCollectionName } from '@ravago/shared/page-data/models/contentful';
import { Alert } from '@ravago/shared/radiance/components/alert/components/Alert/Alert';

type DynamicNodePlaceholderProps = {
   contentType?: ContentfulCollectionName;
   supportedContentTypes: ContentfulCollectionName[];
};

export const DynamicNodePlaceholder: FC<DynamicNodePlaceholderProps> = ({ contentType, supportedContentTypes }) => {
   const isSupported = contentType && supportedContentTypes.includes(contentType);

   return (
      <div style={{ textAlign: 'center' }}>
         {!isSupported && (
            <Alert variant="warning">
               {contentType && (
                  <p style={{ color: 'inherit' }}>
                     This dynamic block is not supported for content type: <b>{contentType}.</b>
                  </p>
               )}
               {!contentType && (
                  <p style={{ color: 'inherit' }}>
                     Value <b>Dynamic</b> cannot be used on regular pages.
                  </p>
               )}
            </Alert>
         )}

         {isSupported && (
            <Alert variant="warning">
               <p style={{ color: 'inherit' }}>You are trying to render a dynamic block but no preview content was set.</p>
               <p style={{ color: 'inherit' }}>
                  Please select a <b>preview</b> option in the top right corner.
               </p>
            </Alert>
         )}
      </div>
   );
};
