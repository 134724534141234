'use client';

import { useEffect, useState } from 'react';

import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { DatePicker } from '@ravago/shared/radiance/components/input-field/components/DatePicker/DatePicker';
import { InputGroup } from '@ravago/shared/radiance/components/input-field/components/InputGroup/InputGroup';
import { Multiselect, MultiselectOption } from '@ravago/shared/radiance/components/input-field/components/Multiselect/Multiselect';
import { Switch } from '@ravago/shared/radiance/components/switch/components/Switch/Switch';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../../../../providers/translation-client-context-provider';
import { useInvoiceDispatchContext, useInvoiceStateContext } from '../../../../context/invoice-context';
import { InvoiceAction } from '../../../../state/invoice.actions';

export const OpenInvoiceFilters = () => {
   const translate = useTranslations();
   const { areOpenInvoicesLoading, openInvoiceFilters, filteredOpenInvoices, isOverdueFilterDisabled } = useInvoiceStateContext();
   const dispatch = useInvoiceDispatchContext();
   const [showFilters, setShowFilters] = useState(true);
   const [amountFilters, setAmountFilters] = useState(0);

   const handleClearFilters = () => {
      dispatch({ type: InvoiceAction.SET_OPEN_INVOICE_FILTERS, payload: { ...openInvoiceFilters, dueDate: '', documentTypes: [], overdue: false } });
   };

   const handleClearSearch = () => {
      dispatch({ type: InvoiceAction.SET_OPEN_INVOICE_FILTERS, payload: { ...openInvoiceFilters, eInvoiceId: '' } });
   };

   const handleInvoiceSearch = (inputValue: string) => {
      if (inputValue.trim() === '') {
         return handleClearSearch();
      }

      return dispatch({ type: InvoiceAction.SET_OPEN_INVOICE_FILTERS, payload: { ...openInvoiceFilters, eInvoiceId: inputValue } });
   };

   const handleDueDateFilter = (date: string) => {
      dispatch({ type: InvoiceAction.SET_OPEN_INVOICE_FILTERS, payload: { ...openInvoiceFilters, dueDate: date } });
   };

   const handleDocumentTypeFilter = (options: MultiselectOption[]) => {
      dispatch({ type: InvoiceAction.SET_OPEN_INVOICE_FILTERS, payload: { ...openInvoiceFilters, documentTypes: options } });
   };

   const resetDocumentTypeFilter = () => {
      const types = openInvoiceFilters.documentTypes.map((type) => {
         type.checked = false;
         return type;
      });
      dispatch({ type: InvoiceAction.SET_OPEN_INVOICE_FILTERS, payload: { ...openInvoiceFilters, documentTypes: types } });
   };

   const handleOverdueFilter = (checked: boolean) => {
      dispatch({ type: InvoiceAction.SET_OPEN_INVOICE_FILTERS, payload: { ...openInvoiceFilters, overdue: checked } });
   };

   useEffect(() => {
      let amount = 0;

      if (openInvoiceFilters.overdue) amount += 1;
      if (openInvoiceFilters.dueDate !== '') amount += 1;
      if (openInvoiceFilters.documentTypes.filter((document) => document.checked).length > 0) amount += 1;

      setAmountFilters(amount);
   }, [openInvoiceFilters.documentTypes, openInvoiceFilters.dueDate, openInvoiceFilters.overdue, filteredOpenInvoices]);

   const renderFilters = (isDesktop = true) => (
      <Box direction={isDesktop ? 'row' : 'column'} width="100%" gap={isDesktop ? 'lg' : 'md'}>
         <Box width={isDesktop ? '25%' : '100%'}>
            <DatePicker
               id="open-invoices-due-by-filter"
               label={translate('invoices.filters.invoices-due-by')}
               min={new Date().toISOString().split('T')[0]}
               value={openInvoiceFilters?.dueDate}
               onChange={handleDueDateFilter}
               disabled={areOpenInvoicesLoading}
            />
         </Box>
         <Multiselect
            clearText={translate('invoices.filters.clear')}
            id="open-invoices-document-type-filter"
            label={translate('invoices.filters.document-type')}
            options={openInvoiceFilters.documentTypes}
            onChange={handleDocumentTypeFilter}
            onClear={resetDocumentTypeFilter}
            sortOptionsOnOpen={false}
            disabled={areOpenInvoicesLoading}
         />
         <Box justify="start" items="center" gap="sm">
            <Switch
               id="open-invoices-overdue-filter"
               disabled={isOverdueFilterDisabled || areOpenInvoicesLoading}
               checked={openInvoiceFilters.overdue}
               onChange={handleOverdueFilter}
            />
            <Typography variant="body" component="b" align="left">
               {translate('invoices.filters.show-only-overdue')}
            </Typography>
         </Box>
         <Box>
            <Button type="button" variant="secondary" disabled={areOpenInvoicesLoading} onClick={handleClearFilters}>
               {translate('invoices.filters.reset-filters')}
            </Button>
         </Box>
      </Box>
   );

   return (
      <Box direction="column" width="100%">
         <Box>
            <InputGroup
               actionIcon="search"
               autocomplete="on"
               clearable
               disabled={areOpenInvoicesLoading}
               id="open-invoices-search"
               label={translate('invoices.filters.search-invoice')}
               maxLength={255}
               onActionClick={handleInvoiceSearch}
               onEnter={handleInvoiceSearch}
               onClear={handleClearSearch}
               value={openInvoiceFilters?.eInvoiceId ?? ''}
            />
            <BreakpointSwitch includeViews={['desktop']}>
               <Button leadingIcon="sliders" variant="tertiary" onClick={() => setShowFilters(!showFilters)}>
                  <Box gap="sm">
                     {showFilters ? translate('invoices.filters.hide-filters') : translate('invoices.filters.show-filters')}
                     <span>{!showFilters && amountFilters > 0 && <Badge variant="primary">{amountFilters}</Badge>}</span>
                  </Box>
               </Button>
            </BreakpointSwitch>
            <BreakpointSwitch excludeViews={['desktop']}>
               <Box gap="none">
                  <IconButton onClick={() => setShowFilters(!showFilters)} variant="on-light" backdrop icon="sliders" />
                  <Box position="relative" left={-15} margin="none" spacing="none" gap="none">
                     {!showFilters && amountFilters > 0 && <Badge variant="primary">{amountFilters}</Badge>}
                  </Box>
               </Box>
            </BreakpointSwitch>
         </Box>
         {showFilters && (
            <>
               <BreakpointSwitch includeViews={['desktop']}>{renderFilters()}</BreakpointSwitch>
               <BreakpointSwitch excludeViews={['desktop']}>{renderFilters(false)}</BreakpointSwitch>
            </>
         )}
      </Box>
   );
};
