'use client';

import React, { useMemo, useState } from 'react';

import { useAuthenticationContext, UserGroups } from '@ravago/shared/authentication';
import { OverviewPageProductItem } from '@ravago/shared/page-data/models/elastic';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Checkbox } from '@ravago/shared/radiance/components/checkbox/components/Checkbox/Checkbox';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { InputField } from '@ravago/shared/radiance/components/input-field/components/InputField/InputField';
import { List } from '@ravago/shared/radiance/components/list/components/List/List';
import { ListItem } from '@ravago/shared/radiance/components/list/components/ListItem/ListItem';
import { Menu } from '@ravago/shared/radiance/components/menu/components/Menu/Menu';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableHead } from '@ravago/shared/radiance/components/table/components/TableHead/TableHead';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { TableSubheader } from '@ravago/shared/radiance/components/table/components/TableSubheader/TableSubheader';
import { Tooltip } from '@ravago/shared/radiance/components/tooltip/components/Tooltip/Tooltip';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useProductCompare } from '../../../../../../hooks/use-product-compare';
import { useRoleBasedFeatureToggles } from '../../../../../../hooks/use-role-based-feature-toggles';
import { useTranslations } from '../../../../../../providers/translation-client-context-provider';
import { ProductCardBadge } from '../../../../../shared/cards/product-card/components/product-card-badge';
import { ConsumerWrapper } from '../../../../consumer-wrapper/consumer-wrapper';
import { DynamicPageLink } from '../../../../dynamic-page-link/dynamic-page-link';
import { MockLink } from '../../../../mock-link/mock-link';
import { TableInfo } from '../../../models/table-info.model';

interface Props {
   baseRoutes?: {
      product: string;
      technology: string;
   };
   products: Array<OverviewPageProductItem>;
   hasPublicLabelAndSigningsPermission: boolean;
   hasSustainabilityLabels: boolean;
   globals: RendererGlobals;
}

export const ProductTable: React.FC<Props> = ({ baseRoutes, products, hasPublicLabelAndSigningsPermission, hasSustainabilityLabels, globals }) => {
   const translate = useTranslations();
   const { user } = useAuthenticationContext();
   const { hasProductCompareFeaturePermission } = useRoleBasedFeatureToggles(globals);
   const { productsToCompareList, addProductToCompareList, removeProductFromCompareList } = useProductCompare();
   const [optionOpen, setOptionOpen] = useState(false);
   const [searchValue, setSearchValue] = useState('');

   const columns: TableInfo[] = [
      {
         key: 'polymer',
         isActive: true,
         width: '164px',
         title: translate('product.card.polymer-type'),
         value: (product: OverviewPageProductItem) => `${product?.polymerGroup} (${product?.polymerSubGroup})`
      },
      {
         key: 'features',
         isActive: true,
         width: '500px',
         title: translate('card-product-card-features'),
         value: (product: OverviewPageProductItem) => (product?.features?.length ? product?.features.join(' • ') : '-')
      },
      {
         key: 'technologies',
         isActive: true,
         width: '324px',
         title: translate('card-product-card-technologies'),
         value: (product: OverviewPageProductItem) =>
            product?.processes?.length
               ? product?.processes.map((technology, index) => (
                    <ConsumerWrapper
                       key={`${product.id}-${technology.slug}`}
                       isConsumer={globals.isConsumer}
                       renderConsumer={(children) => (
                          <DynamicPageLink
                             id={`table-body-technology-${technology.name}-link-${product.id}`}
                             locale={globals.locale}
                             slug={technology.slug}
                             baseRoute={{ url: baseRoutes?.technology, noFollow: false }}
                          >
                             {children}
                          </DynamicPageLink>
                       )}
                       renderProducer={(children) => <MockLink underline="none">{children}</MockLink>}
                    >
                       {technology.name}
                       {index < product.processes.length - 1 && ', '}
                    </ConsumerWrapper>
                 ))
               : '-'
      },
      {
         key: 'filler',
         isActive: true,
         width: '200px',
         title: translate('card-product-card-filler'),
         value: (product: OverviewPageProductItem) =>
            product?.fillers?.length
               ? product?.fillers.map((filler) => `${filler.percentage ?? '?'}% ${filler.name}`).join(', ')
               : translate('card-product-card-unfilled')
      }
   ];
   const [activeColumns, setActiveColumns] = useState(columns);
   const filteredColumns = useMemo(() => columns.filter((column) => column.key.includes(searchValue)), [searchValue]);

   const checked = (id: string) => productsToCompareList.some((productId) => productId === id);

   const handleProductCompare = (id: string) => {
      if (!id || !hasProductCompareFeaturePermission) return;

      const newChecked = !checked(id);

      if (newChecked) {
         addProductToCompareList(id);
      } else {
         removeProductFromCompareList(id);
      }
   };

   const openOption = () => {
      setOptionOpen(!optionOpen);
   };

   const optionClick = (column: TableInfo) => {
      const index = activeColumns.findIndex((c) => c.key === column.key);
      if (index !== -1) setActiveColumns(activeColumns.filter((c) => c.key !== column.key));
      else setActiveColumns([...activeColumns, column]);
   };

   return (
      <Box position="relative" overflowX="hidden" width="100%">
         <Box direction="row" gap="none" overflowX="scroll" width="100%">
            <Box direction="column" position="sticky" left="0" minWidth="359px" width="100%">
               <Table rowDividers hover>
                  <TableHead>
                     <TableRow>
                        {hasProductCompareFeaturePermission && <TableSubheader width="76px">{undefined}</TableSubheader>}
                        <TableSubheader>
                           <Box id="tabel-header-product" height="44px" items="center">
                              <Typography variant="body-highlight">{translate('my-orders.table.product')}</Typography>
                           </Box>
                        </TableSubheader>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {products.map((product) => (
                        <TableRow key={product.id}>
                           {hasProductCompareFeaturePermission && (
                              <TableCell>
                                 <Checkbox
                                    data-testid={`checkbox-${product.id}`}
                                    id={`table-body-checkbox-${product.id}`}
                                    disabled={productsToCompareList.length >= 5 && !checked(`product-${product.id}`)}
                                    checked={checked(`product-${product.id}`)}
                                    onChange={() => handleProductCompare(`product-${product.id}`)}
                                 />
                              </TableCell>
                           )}
                           <TableCell>
                              <Box gap="2xs" items="center" height="44px">
                                 <ConsumerWrapper
                                    isConsumer={globals.isConsumer}
                                    renderConsumer={(children) => (
                                       <DynamicPageLink
                                          id={`table-body-product-${product.id}`}
                                          baseRoute={{ url: baseRoutes?.product, noFollow: false }}
                                          slug={product.slug}
                                          locale={globals.locale}
                                       >
                                          {children}
                                       </DynamicPageLink>
                                    )}
                                    renderProducer={(children) => <MockLink>{children}</MockLink>}
                                 >
                                    <Typography color="inherit" variant="small">
                                       {product.name}
                                    </Typography>
                                 </ConsumerWrapper>
                                 {product.isPreferred &&
                                    hasPublicLabelAndSigningsPermission &&
                                    (user?.groups?.includes(UserGroups.ravagoSalesRepresentative) || !globals.isConsumer) && (
                                       <Tooltip text={translate('card-product-card-preferred')} pointers={false} align="end" direction="bottom">
                                          <Icon data-testid={`icon-${product.id}`} icon="gem" color="primary" />
                                       </Tooltip>
                                    )}
                                 {hasPublicLabelAndSigningsPermission && (
                                    <ProductCardBadge
                                       badgeInfo={{
                                          ...(product.sustainabilityGroup?.subSustainability?.icon && {
                                             sustainability: {
                                                icon: product.sustainabilityGroup.subSustainability.icon
                                             }
                                          }),
                                          quality: product.quality
                                       }}
                                       hasSustainabilityLabels={hasSustainabilityLabels}
                                    />
                                 )}
                              </Box>
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </Box>
            <Table
               rowDividers
               hover
               stickyOption={
                  <Menu
                     open={optionOpen}
                     onMenuClose={openOption}
                     closeOnOutsideClick
                     content={
                        <Box direction="column" gap="xs">
                           <Box direction="column" spacing={{ top: 'xs', left: 'xs', right: 'xs' }}>
                              <InputField
                                 id="table-options-column-search"
                                 data-testid="options-column-search"
                                 label="Search"
                                 trailingIcon="search"
                                 onChange={setSearchValue}
                                 value={searchValue}
                                 clearable
                              />
                           </Box>
                           <List>
                              {filteredColumns.map((column) => (
                                 <div key={`menu-${column.key}`}>
                                    <ListItem
                                       data-testid={`options-listitem-${column.key}`}
                                       id={`table-options-column-${column.key}`}
                                       onClick={() => optionClick(column)}
                                    >
                                       <Box items="center" gap="2xs">
                                          <Checkbox
                                             readOnly
                                             checked={activeColumns.some((col) => col.key === column.key)}
                                             id={`table-options-column-checkbox-${column.key}`}
                                          />
                                          <Typography>{column.title}</Typography>
                                       </Box>
                                    </ListItem>
                                 </div>
                              ))}
                           </List>
                        </Box>
                     }
                  >
                     <Box
                        data-testid="options-btn"
                        id="table-options-btn"
                        onClick={openOption}
                        height="60px"
                        width="60px"
                        items="center"
                        justify="center"
                        background="var(--component-data-display-table-cell-color-background-striped-default)"
                     >
                        <Icon color="strong" icon="sliders" />
                     </Box>
                  </Menu>
               }
            >
               <TableHead>
                  <TableRow>
                     {activeColumns.map((column) => (
                        <TableSubheader key={`column-header-${column.key}`}>
                           <Box id={`table-header-${column.key}`} data-testid={`table-header-${column.key}`} height="44px" items="center">
                              <Typography variant="body-highlight">{translate(column.title)}</Typography>
                           </Box>
                        </TableSubheader>
                     ))}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {products.map((product) => (
                     <TableRow key={product.id}>
                        {activeColumns.map((column) => (
                           <TableCell width={column.width} key={`cell-${column.key}`}>
                              <Box id={`tabel-body-${column.key}-${product.id}`} height="44px" items="center">
                                 <Typography maxLines="2" variant="small" data-testid={`text-${column.key}`}>
                                    {column.value(product)}
                                 </Typography>
                              </Box>
                           </TableCell>
                        ))}
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </Box>
      </Box>
   );
};
