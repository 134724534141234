'use client';

import { FC, PropsWithChildren, ReactElement } from 'react';

import { BackgroundColorTokenMap } from '@ravago/shared/page-data/const/background-color-token-map';
import { AccessLevel } from '@ravago/shared/page-data/enums/access-level';
import { BackgroundColors } from '@ravago/shared/page-data/enums/background-colors';
import { Conditionals } from '@ravago/shared/page-data/enums/conditionals';
import { MaxImageSizes } from '@ravago/shared/page-data/enums/max-image-sizes';
import { NodeTypes } from '@ravago/shared/page-data/enums/node-types';
import { Node } from '@ravago/shared/page-data/models/base';
import { ImageInterface, VideoInterface } from '@ravago/shared/page-data/models/elements';
import { SectionNode } from '@ravago/shared/page-data/models/layout';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Compatibility } from '@ravago/shared/page-data/utils/compatibility';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Breakpoint, BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';

import { getAlignment } from '../../../utils/alignment/get-alignment';
import { AssetUtils } from '../../../utils/asset/asset.utils';
import { isPlaceholderString } from '../../../utils/placeholder/is-placeholder-string';
import { isPlaceholderValid } from '../../../utils/placeholder/is-placeholder-valid';
import { replacePlaceholder } from '../../../utils/placeholder/replace-placeholder';
import { StyleUtils } from '../../../utils/style/style.utils';
import AuthGuard from '../../shared/auth-guard/auth-guard';

import styles from './node-properties.module.scss';

interface Props extends PropsWithChildren {
   node: Partial<Node>;
   globals: RendererGlobals;
   isConsumer: boolean;
   isSelected?: boolean;
   selectedNodeId?: string;
   renderCallback?: (element: ReactElement, priority: string) => ReactElement;
}

// This is a list of node types that should have a higher z-index than other elements.
const priorityElements = [NodeTypes.HOMEPAGE_SEARCH, NodeTypes.QUICK_TDS_LINK, NodeTypes.TILE];

const totalDeviceCount = 4;

/* c8 ignore start */
export const NodeProperties: FC<Props> = ({ node, globals, isConsumer, isSelected, selectedNodeId, renderCallback, children }) => {
   const { padding, margin } = node.appearance?.spacing || {};

   const { width, height } = node.appearance?.sizing || {};

   const getBackgroundColor = (): string => {
      if (!node?.appearance?.backgroundColor || BackgroundColorTokenMap[node.appearance.backgroundColor as BackgroundColors] === undefined) return '';

      return BackgroundColorTokenMap[node.appearance.backgroundColor as BackgroundColors];
   };

   const getBackground = (): string => {
      let backgroundColor = getBackgroundColor();

      if (!('backgroundImage' in node)) return backgroundColor;

      const image = node.backgroundImage as ImageInterface;

      if (!image) return backgroundColor;

      let src: string;

      if (isPlaceholderString(image.src)) {
         if (isPlaceholderValid(image.src, globals?.contentEntity)) {
            src = replacePlaceholder(image.src, globals?.contentEntity, isConsumer, { defaultValue: '' });
         } else {
            src = `${globals.assetBasePath}/images/placeholders/default-banner-large.${globals.acceptWebp ? 'webp' : 'png'}`;
            backgroundColor = 'var(--theme-color-background-primary)';
         }
      } else {
         src = image.src;
      }

      if (!AssetUtils.optimizeImageIfRequired(src, globals.acceptWebp, image.width, MaxImageSizes.BACKGROUND_IMAGE)) return '';

      return `url("${AssetUtils.optimizeImageIfRequired(
         src,
         globals.acceptWebp,
         image.width,
         MaxImageSizes.BACKGROUND_IMAGE
      )}") center center / cover no-repeat ${backgroundColor}`;
   };

   const hasVideoBackground = () => 'backgroundVideo' in node && (node.backgroundVideo as VideoInterface)?.url;

   const hasImageBackground = () => 'backgroundImage' in node && (node.backgroundImage as ImageInterface)?.src;

   const hasImageOverlay = () =>
      'overlay' in node && node.overlay !== 'none' && (hasVideoBackground() || hasImageBackground()) && node.type !== NodeTypes.IMAGE;

   const getImageOverlay = () => {
      if ('overlay' in node) {
         return node.overlay === 'primary' ? styles.colorOverlayPrimary : styles.colorOverlayDefault;
      }

      return null;
   };

   const getScreenVisibility = (): Breakpoint[] => {
      const { desktop, tablet, mobile } = node.settings?.visibility || {};

      const breakpoints: Breakpoint[] = [];

      if (desktop) breakpoints.push('desktop');

      if (tablet) breakpoints.push('tab-land', 'tab-port');

      if (mobile) breakpoints.push('phone');

      return breakpoints;
   };

   const getConditionalHideComponent = (): boolean => {
      const { activeConditional, attributes } = node.settings?.conditionals || {};

      if (!activeConditional) return false;

      if (activeConditional === Conditionals.NONE) return false;

      if (activeConditional === Conditionals.ATTRIBUTES) {
         if (!attributes || !attributes.slugs.length) return false;
         if (!attributes.slugs.includes(globals.contentEntity?.sys.id ?? '')) return true;
      }

      return false;
   };

   const getPriority = (): string => {
      if (!isConsumer) {
         if ((node.type === NodeTypes.SECTION || node.type === NodeTypes.COLUMN) && 'children' in node) {
            const hasSelectedChild = (nodes: any[]): any[] =>
               nodes.find((childNode) => {
                  if (childNode.id === selectedNodeId && childNode.type === NodeTypes.TEXT) {
                     return true;
                  }

                  if ('children' in childNode) {
                     return hasSelectedChild(childNode.children);
                  }

                  return false;
               });

            const hasSelected = hasSelectedChild(node.children as any[]);

            if (hasSelected) return '100';
         }

         if (node.type === NodeTypes.TEXT && isSelected) return '100';
      }

      return 'children' in node && priorityElements.some((element) => JSON.stringify(node.children).includes(element)) ? '2' : '1';
   };

   const renderElement = () => (
      <Box
         id={node.settings?.anchor}
         className={StyleUtils.getCssClassesForNode(node as Node)}
         tag={node.type === NodeTypes.SECTION ? 'section' : 'div'}
         direction="column"
         spacing={Compatibility.getMarginPadding(padding)}
         margin={Compatibility.getMarginPadding(margin)}
         maxWidth={width ? `${width}px` : undefined}
         minHeight={height ? `${height}px` : undefined}
         height={node.type === NodeTypes.COLUMN ? '100%' : undefined}
         justify={'verticalAlignment' in node ? getAlignment((node as SectionNode).verticalAlignment) : undefined}
         zIndex={getPriority()}
         background={getBackground()}
         attributes={{ ...(node.type && { 'node-type': node.type }) }}
      >
         {hasImageOverlay() && <div className={`${styles.colorOverlay} ${getImageOverlay()}`} />}

         {hasVideoBackground() && (
            <div className={styles.videoWrapper}>
               {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
               <video
                  className={styles.video}
                  autoPlay={(node as SectionNode).backgroundVideo?.autoplay}
                  loop={(node as SectionNode).backgroundVideo?.loop}
                  muted={(node as SectionNode).backgroundVideo?.muted}
               >
                  <source src={(node as SectionNode).backgroundVideo?.url} type="video/mp4" />
               </video>
            </div>
         )}

         {children}
      </Box>
   );

   const render = () => (renderCallback ? renderCallback(renderElement(), getPriority()) : renderElement());

   if (getConditionalHideComponent()) return null;
   return (
      <AuthGuard redirectUserToLogin={false} accessLevel={node.settings?.accessLevel || AccessLevel.PUBLIC} isConsumer={isConsumer}>
         {getScreenVisibility().length === totalDeviceCount ? (
            render()
         ) : (
            <BreakpointSwitch
               includeViews={getScreenVisibility()}
               useContainerQuery={(globals?.isConsumer && node.type !== NodeTypes.SECTION && node.type !== NodeTypes.ROW) || !globals?.isConsumer}
            >
               {render()}
            </BreakpointSwitch>
         )}
      </AuthGuard>
   );
};
/* c8 ignore stop */
