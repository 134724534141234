import React, { Fragment, ReactNode } from 'react';

import { Chemical } from '@ravago/shared/page-data/models/elastic';
import { ProductPropertyName } from '@ravago/shared/page-data/models/elements';

const SEPARATOR = ' • ';

export class ProductPropertyConvertorUtils {
   static convert(product: Chemical, propertyName: ProductPropertyName): ReactNode {
      switch (propertyName) {
         case 'NAME':
            return ProductPropertyConvertorUtils.convertName(product);
         case 'BRAND':
            return ProductPropertyConvertorUtils.convertBrand(product);
         case 'PRODUCERS':
            return ProductPropertyConvertorUtils.convertProducers(product);
         case 'FEATURES':
            return ProductPropertyConvertorUtils.convertFeatures(product);
         case 'FUNCTIONS':
            return ProductPropertyConvertorUtils.convertFunctions(product);
         case 'CHEMICAL_GROUP':
            return ProductPropertyConvertorUtils.convertChemicalGroup(product);
         case 'CHEMICAL_DESCRIPTION':
            return ProductPropertyConvertorUtils.convertChemicalDescription(product);
         case 'INGREDIENT_GROUP':
            return ProductPropertyConvertorUtils.convertIngredientGroup(product);
         case 'INGREDIENT_DESCRIPTION':
            return ProductPropertyConvertorUtils.convertIngredientDescription(product);
         case 'CAS_NUMBERS':
            return ProductPropertyConvertorUtils.convertCasNumbers(product);
         case 'COMMON_NAME':
            return ProductPropertyConvertorUtils.convertCommonName(product);
         case 'REGULATORY':
            return ProductPropertyConvertorUtils.convertRegulatory(product);
         case 'SUSTAINABILITIES':
            return ProductPropertyConvertorUtils.convertSustainabilities(product);
         case 'PHYSICAL_FORMS':
            return ProductPropertyConvertorUtils.convertPhysicalForms(product);
         case 'DESCRIPTION':
            return ProductPropertyConvertorUtils.convertDescription(product);
         case 'CI_NUMBERS':
            return ProductPropertyConvertorUtils.convertCiNumbers(product);
         case 'INCI_NUMBERS':
            return ProductPropertyConvertorUtils.convertInciNumbers(product);
         case 'E_NUMBERS':
            return ProductPropertyConvertorUtils.convertENumbers(product);
         case 'EC_NUMBERS':
            return ProductPropertyConvertorUtils.convertEcNumbers(product);
         case 'END_USES':
            return ProductPropertyConvertorUtils.convertEndUses(product);
         case 'END_USE_FORMS':
            return ProductPropertyConvertorUtils.convertEndUseForms(product);
         case 'REPRESENTATIONS':
            return ProductPropertyConvertorUtils.convertRepresentations(product);
         case 'COUNTER_PRODUCTS':
            return ProductPropertyConvertorUtils.convertCounterProducts(product);
         case 'COUNTER_BRANDS':
            return ProductPropertyConvertorUtils.convertCounterBrands(product);
         case 'QUALITY_GRADES':
            return ProductPropertyConvertorUtils.convertQualityGrades(product);
         case 'STATEMENTS':
            return ProductPropertyConvertorUtils.convertStatements(product);
         case 'MARKET_SEGMENTS':
            return ProductPropertyConvertorUtils.convertMarketSegments(product);
         case 'APPLICATIONS':
            return ProductPropertyConvertorUtils.convertApplications(product);
         case 'SUITABILITY_SEGMENTS':
            return ProductPropertyConvertorUtils.convertSuitabilitySegments(product);
         case 'GRADE':
            return ProductPropertyConvertorUtils.convertGrade(product);
         case 'SPECIES_GROUPS':
            return ProductPropertyConvertorUtils.convertSpeciesGroups(product);
         case 'FEMA':
            return ProductPropertyConvertorUtils.convertFema(product);
         case 'BOTANICAL_NAME':
            return ProductPropertyConvertorUtils.convertBotanicalName(product);
         case 'ORGANOLEPTIC_FAMILY':
            return ProductPropertyConvertorUtils.convertOrganolepticFamily(product);
         case 'SELLING_REGIONS':
            return ProductPropertyConvertorUtils.convertSellingRegions(product);
         case 'ERP_PRODUCTS':
            return ProductPropertyConvertorUtils.convertErpProducts(product);
         default:
            return undefined;
      }
   }

   private static arrayReducer<T>(propertyName?: keyof T): (acc: string, value: T) => string {
      return (acc: string, value: T) => {
         const applicableValue = (propertyName ? value[propertyName] : value) as string;
         const trimmedApplicableValue = applicableValue.trim();
         if (trimmedApplicableValue) {
            return `${acc}${acc.length ? SEPARATOR : ''}${trimmedApplicableValue}`;
         }
         return acc;
      };
   }

   private static convertName(product: Chemical): ReactNode {
      return product.name.trim();
   }

   private static convertBrand(product: Chemical): ReactNode {
      return product.brand?.name.trim();
   }

   private static convertProducers(product: Chemical): ReactNode {
      return product.producers.reduce(this.arrayReducer('name'), '') || undefined;
   }

   private static convertFeatures(product: Chemical): ReactNode {
      return product.features.reduce(this.arrayReducer(), '') || undefined;
   }

   private static convertFunctions(product: Chemical): ReactNode {
      return product.functions.reduce(this.arrayReducer('name'), '') || undefined;
   }

   private static convertChemicalGroup(product: Chemical): ReactNode {
      const chemicalGroupName = product.chemicalGroup?.name.trim();
      const chemicalGroupSubClassificationName = product.chemicalGroup?.subClassification?.name.trim();
      if (chemicalGroupSubClassificationName) {
         return `${chemicalGroupName} (${chemicalGroupSubClassificationName})`;
      }
      return chemicalGroupName;
   }

   private static convertChemicalDescription(product: Chemical): ReactNode {
      return product.chemicalDescription?.displayName.trim() || product.chemicalDescription?.name.trim();
   }

   private static convertIngredientGroup(product: Chemical): ReactNode {
      const ingredientGroupName = product.ingredientGroup?.name.trim();
      const ingredientGroupSubClassificationName = product.ingredientGroup?.subClassification?.name.trim();
      if (ingredientGroupSubClassificationName) {
         return `${ingredientGroupName} (${ingredientGroupSubClassificationName})`;
      }
      return ingredientGroupName;
   }

   private static convertIngredientDescription(product: Chemical): ReactNode {
      return product.ingredientDescription?.displayName.trim() || product.ingredientDescription?.name.trim();
   }

   private static convertCasNumbers(product: Chemical): ReactNode {
      return product.casNumbers.reduce(this.arrayReducer(), '') || undefined;
   }

   private static convertCommonName(product: Chemical): ReactNode {
      return product.commonName?.trim();
   }

   private static convertRegulatory(product: Chemical): ReactNode {
      return product.regulatory.reduce(this.arrayReducer(), '') || undefined;
   }

   private static convertSustainabilities(product: Chemical): ReactNode {
      return product.sustainabilities.reduce(this.arrayReducer(), '') || undefined;
   }

   private static convertPhysicalForms(product: Chemical): ReactNode {
      return product.physicalForms.reduce(this.arrayReducer(), '') || undefined;
   }

   private static convertDescription(product: Chemical): ReactNode {
      const trimmedDescription = product.description?.trim();
      if (!trimmedDescription) {
         return undefined;
      }
      return React.createElement('span', {
         dangerouslySetInnerHTML: { __html: trimmedDescription }
      });
   }

   private static convertCiNumbers(product: Chemical): ReactNode {
      return product.ciNumbers.reduce(this.arrayReducer(), '') || undefined;
   }

   private static convertInciNumbers(product: Chemical): ReactNode {
      return product.inciNumbers.reduce(this.arrayReducer(), '') || undefined;
   }

   private static convertENumbers(product: Chemical): ReactNode {
      return product.eNumbers.reduce(this.arrayReducer(), '') || undefined;
   }

   private static convertEcNumbers(product: Chemical): ReactNode {
      return product.ecNumbers.reduce(this.arrayReducer(), '') || undefined;
   }

   private static convertEndUses(product: Chemical): ReactNode {
      return product.endUses.reduce(this.arrayReducer(), '') || undefined;
   }

   private static convertEndUseForms(product: Chemical): ReactNode {
      return product.endUseForms.reduce(this.arrayReducer(), '') || undefined;
   }

   private static convertRepresentations(product: Chemical): ReactNode {
      return product.representations?.reduce(this.arrayReducer(), '') || undefined;
   }

   private static convertCounterProducts(product: Chemical): ReactNode {
      return product.counterProducts?.trim();
   }

   private static convertCounterBrands(product: Chemical): ReactNode {
      return product.counterBrands?.trim();
   }

   private static convertQualityGrades(product: Chemical): ReactNode {
      return product.qualityGrades.reduce(this.arrayReducer(), '') || undefined;
   }

   private static convertStatements(product: Chemical): ReactNode {
      return product.statements.reduce(this.arrayReducer(), '') || undefined;
   }

   private static convertMarketSegments(product: Chemical): ReactNode {
      return (
         product.marketSegments
            .map(({ name, subsegments }) => {
               const subsegmentNames = subsegments.map(({ name: subsegmentName }) => subsegmentName.trim()).filter(Boolean);
               const trimmedName = name.trim();
               if (!trimmedName) {
                  return null;
               }
               return subsegmentNames.length ? `${trimmedName} (${subsegmentNames.join(', ')})` : trimmedName;
            })
            .filter(Boolean)
            .join(SEPARATOR) || undefined
      );
   }

   private static convertApplications(product: Chemical): ReactNode {
      return (
         product.applications
            .map(({ name, endUses }) => {
               const endUseNames = endUses.map(({ name: endUseName }) => endUseName.trim()).filter(Boolean);
               const trimmedName = name.trim();
               if (!trimmedName) {
                  return null;
               }
               return endUseNames.length ? `${trimmedName} (${endUseNames.join(', ')})` : trimmedName;
            })
            .filter(Boolean)
            .join(SEPARATOR) || undefined
      );
   }

   private static convertSuitabilitySegments(product: Chemical): ReactNode {
      return (
         product.suitabilitySegments
            .map(({ name, subsegments }) => {
               const subsegmentNames = subsegments.map(({ name: subsegmentName }) => subsegmentName.trim()).filter(Boolean);
               const trimmedName = name.trim();
               if (!trimmedName) {
                  return null;
               }
               return subsegmentNames.length ? `${trimmedName} (${subsegmentNames.join(', ')})` : trimmedName;
            })
            .filter(Boolean)
            .join(SEPARATOR) || undefined
      );
   }

   private static convertGrade(product: Chemical): ReactNode {
      return product.grade?.trim();
   }

   private static convertSpeciesGroups(product: Chemical): ReactNode {
      return (
         product.speciesGroups
            .map(({ name, species }) => {
               const speciesNames = species.map(({ name: speciesName }) => speciesName.trim()).filter(Boolean);
               const trimmedName = name.trim();
               if (!trimmedName) {
                  return null;
               }
               return speciesNames.length ? `${trimmedName} (${speciesNames.join(', ')})` : trimmedName;
            })
            .filter(Boolean)
            .join(SEPARATOR) || undefined
      );
   }

   private static convertFema(product: Chemical): ReactNode {
      return product.fema?.trim();
   }

   private static convertBotanicalName(product: Chemical): ReactNode {
      return product.botanicalName?.trim();
   }

   private static convertOrganolepticFamily(product: Chemical): ReactNode {
      return product.organolepticFamily?.trim();
   }

   private static convertSellingRegions(product: Chemical): ReactNode {
      function recursivelyOutputSubSellingRegionsAsTitle(sellingRegion: Chemical['sellingRegions'][number]): string {
         const trimmedCode = sellingRegion.code.trim();
         if (!trimmedCode) {
            return '';
         }
         if (!sellingRegion.subSellingRegions?.length) {
            return trimmedCode;
         }
         return `${trimmedCode} (${sellingRegion.subSellingRegions
            .sort((a, b) => a.code.localeCompare(b.code))
            .map(recursivelyOutputSubSellingRegionsAsTitle)
            .join(', ')})`;
      }

      if (!product.sellingRegions.length) {
         return undefined;
      }

      return React.createElement(
         Fragment,
         {},
         product.sellingRegions.map((sellingRegion, index, arr) => [
            React.createElement(
               'span',
               {
                  role: 'sellingRegion',
                  title: sellingRegion.subSellingRegions
                     ?.sort((a, b) => a.code.localeCompare(b.code))
                     .map(recursivelyOutputSubSellingRegionsAsTitle)
                     .join(', ')
               },
               sellingRegion.code
            ),
            index < arr.length - 1 ? React.createElement('span', {}, SEPARATOR) : null
         ])
      );
   }

   private static convertErpProducts(product: Chemical): ReactNode {
      return product.erpProducts.map(({ id, source, name }) => `${name.trim()} (${source.trim()}: ${id.trim()})`).join(SEPARATOR) || undefined;
   }
}
