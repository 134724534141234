'use client';

import { FC, ReactNode, useState } from 'react';

import { createPortal } from 'react-dom';

import { Lightbox } from '../Lightbox/Lightbox';

interface RenderGalleryProps {
   onClick: (index: number) => void;
}

interface GalleryItem {
   src: string;
   alt: string;
   title: string;
}

interface Props {
   renderGallery: (props: RenderGalleryProps) => ReactNode;
   items: GalleryItem[];
}

export const LightboxGallery: FC<Props> = ({ renderGallery, items }) => {
   const [lightboxOpen, setLightboxOpen] = useState(false);
   const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

   const gallery = renderGallery({
      onClick: (index) => {
         setSelectedItemIndex(index);
         setLightboxOpen(true);
      }
   });

   const handleOnClose = () => {
      setLightboxOpen(false);
      setSelectedItemIndex(-1);
   };

   return (
      <div>
         {gallery}
         {lightboxOpen && createPortal(<Lightbox items={items} initialSelectedItemIndex={selectedItemIndex} onClose={handleOnClose} />, document.body)}
      </div>
   );
};
