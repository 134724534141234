import { DynamicProductBlockQuotationLine } from '@ravago/shared/page-data/models/elements';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { CurrencyUtils } from '../../../../utils/currency/currency.utils';

interface Props {
   quotationLine: DynamicProductBlockQuotationLine;
}

export const QuoteFlyoutQuotationLine = ({ quotationLine }: Props) => {
   const translate = useTranslations();

   return (
      <Box direction="column" gap="2xs">
         <Box justify="between">
            <Box gap="2xs" items="center">
               <Badge variant="info">
                  {quotationLine.quantity.amount} {quotationLine.quantity.unitOfMeasure}
               </Badge>
               <Badge variant="disabled" leadingIcon="box-alt">
                  {quotationLine.packaging}
               </Badge>
            </Box>
            <Typography>{CurrencyUtils.localiseUnitPrice(quotationLine.unitPrice)}</Typography>
         </Box>
         {quotationLine.remark ? (
            <Typography>{quotationLine.remark}</Typography>
         ) : (
            <Typography color="weak">{translate('quote-flyout.no-comments-found')}</Typography>
         )}
      </Box>
   );
};
