'use client';

import { FC, useEffect, useMemo, useState } from 'react';

import { useAuthenticationContext } from '@ravago/shared/authentication';
import { FeatureToggle } from '@ravago/shared/page-data/models/config';
import { ContentfulCountry } from '@ravago/shared/page-data/models/contentful';
import {
   AutoSuggestResponse,
   FacetFilterWithTerms,
   FacetFilterTracking,
   facetLabelTranslationMapping,
   OverviewPageItem,
   ProductRequestType,
   ProductsQueryResponse,
   ViewType
} from '@ravago/shared/page-data/models/elastic';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { ClientPortal } from '@ravago/shared/radiance/components/client-portal/components/ClientPortal/ClientPortal';
import { Flyout } from '@ravago/shared/radiance/components/flyout/components/Flyout/Flyout';
import { Grid } from '@ravago/shared/radiance/components/grid/components/Grid/Grid';
import { GridCol } from '@ravago/shared/radiance/components/grid/components/GridCol/GridCol';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';
import notification from '@ravago/shared/radiance/utils/notification/notification';

import { keepPreviousData, useQuery } from '@tanstack/react-query';

import isEqual from 'lodash/isEqual';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { CorrelationService } from '../../../../services/tracking/correlation-token.service';
import { getLanguageCode } from '../../../../utils/locale/locale.utils';
import { TrackingUtils } from '../../../../utils/tracking/tracking.utils';
import { AutoSuggestClientRequest, SearchClientRequest } from '../models/client-request.model';
import { FacetGroup, toFacetGroup } from '../models/facet-group.model';
import { filterToQuery } from '../utils/filter-query-utils';

import styles from './product-portfolio.module.scss';
import { ProductPortfolioEmpty } from './ui/portfolio/product-portfolio-empty';
import { ProductPortfolioFilters } from './ui/portfolio/product-portfolio-filters';
import { ProductPortfolioGrid } from './ui/portfolio/product-portfolio-grid';
import { ProductPortfolioSearch } from './ui/portfolio/product-portfolio-search';
import { ProductPortfolioTable } from './ui/portfolio/product-portfolio-table-view';
import { ProductPortfolioTabs } from './ui/portfolio/product-portfolio-tabs';

const tabMap = {
   product: 'products',
   brand: 'brands',
   caseStudy: 'case-study'
} as const;

const viewMap = {
   list: 20,
   grid: 18
} as const;

interface Props {
   baseRoutes?: {
      brand: string;
      caseStudy: string;
      product: string;
      market: string;
      technology: string;
   };
   relatedDocumentsAnchor?: string;
   globals: RendererGlobals;
   hasListViewPermission?: boolean;
   hasPublicLabelAndSigningsPermission?: boolean;
   hasSustainabilityLabels?: boolean;
   initialSearchType: ProductRequestType;
   initialView: ViewType;
   initialPage: number;
   initialSearch: string;
   initialFacetFilters: FacetFilterWithTerms[];
   initialTotals: Record<ProductRequestType, number | undefined>;
   baseFacetFilters: FacetFilterWithTerms[];
   countries: Array<ContentfulCountry>;
   getSearchResults: (request: SearchClientRequest) => Promise<ProductsQueryResponse<OverviewPageItem>>;
   getAutoSuggestResult: (request: AutoSuggestClientRequest) => Promise<AutoSuggestResponse>;
}

export const ProductPortfolio: FC<Props> = ({
   baseRoutes,
   relatedDocumentsAnchor,
   globals,
   hasListViewPermission = false,
   hasPublicLabelAndSigningsPermission = false,
   hasSustainabilityLabels = false,
   initialSearchType,
   initialPage,
   initialSearch,
   initialView,
   initialFacetFilters,
   initialTotals,
   baseFacetFilters,
   countries,
   getSearchResults,
   getAutoSuggestResult
}) => {
   const translate = useTranslations();
   const { token } = useAuthenticationContext();

   const { desktop, phone } = useBreakpoint();

   const language = getLanguageCode(globals.locale);

   const [features, setFeatures] = useState<FeatureToggle[]>([]);

   // Tracking state to know when to trigger tracking events
   const [trackedSearch, setTrackedSearch] = useState<string>('');
   const [trackFacetsAndSearchBeforeReset, setTrackFacetsAndSearchBeforeReset] = useState<
      { facets: FacetFilterWithTerms[]; search: string; page: number } | undefined
   >();
   const [trackFilterClick, setTrackFilterClick] = useState<FacetFilterTracking | undefined>(undefined);

   // Search & Filter State
   const [search, setSearch] = useState(initialSearch);
   const [autoSuggestSearch, setAutoSuggestSearch] = useState(initialSearch);
   const [searchType, setSearchType] = useState<ProductRequestType>(initialSearchType);
   const [viewType, setViewType] = useState<{ view: ViewType; size: number }>({ view: initialView, size: viewMap[initialView] });
   const [page, setPage] = useState(initialPage);
   const [facets, setFacets] = useState<FacetFilterWithTerms[]>(initialFacetFilters);
   const [totals, setTotals] = useState<Record<ProductRequestType, number | undefined>>(initialTotals);
   const [autoSuggestExpanded, setAutoSuggestExpanded] = useState(false);
   const [expandedGroups, setExpandedGroups] = useState<Array<string>>([]);

   const canResetFilters = !isEqual(baseFacetFilters, facets);

   const searchQuery = useQuery({
      queryKey: [
         'product-portfolio',
         {
            facets,
            search,
            page,
            tab: searchType,
            view: viewType.view,
            token
         }
      ],
      queryFn: () =>
         getSearchResults({
            facets,
            search,
            page,
            searchType,
            size: viewType.size,
            token,
            language,
            correlationId: CorrelationService.generateCorrelationId('dpcid')
         }),
      placeholderData: keepPreviousData,
      refetchOnMount: false,
      refetchOnWindowFocus: false
   });

   const trackSearch = (allTotals: Record<ProductRequestType, number | undefined>) => {
      TrackingUtils.track('product-portfolio-search', {
         correlationId: CorrelationService.getCorrelationId('dpcid'),
         searchQuery: search,
         totalAmountOfItems: JSON.stringify(allTotals),
         activeFacets: JSON.stringify(TrackingUtils.translateFacets([...facets], translate)),
         language
      });
      setTrackedSearch(search);
   };

   const trackFilterReset = () => {
      if (!trackFacetsAndSearchBeforeReset) return;
      TrackingUtils.track('click_reset_all_filters', {
         correlationId: CorrelationService.getCorrelationId('dpcid'),
         searchQueryBeforeReset: trackFacetsAndSearchBeforeReset.search,
         facetsBeforeReset: JSON.stringify(TrackingUtils.translateFacets([...trackFacetsAndSearchBeforeReset.facets], translate)),
         pageNumberBeforeReset: trackFacetsAndSearchBeforeReset.page.toString()
      });
      setTrackFacetsAndSearchBeforeReset(undefined);
   };

   const trackFacets = (allTotals: Record<ProductRequestType, number | undefined>) => {
      TrackingUtils.track('click_filter_facet', {
         correlationId: CorrelationService.getCorrelationId('dpcid'),
         selectedFilters: JSON.stringify(TrackingUtils.translateFacets([...facets], translate)),
         lastSelectedFilter: JSON.stringify({
            ...trackFilterClick,
            facet: trackFilterClick?.facet
               ? translate(facetLabelTranslationMapping.get(trackFilterClick.facet) ?? trackFilterClick.facet, undefined, true)
               : undefined
         }),
         totalAmountOfItems: JSON.stringify(allTotals)
      });
      setTrackFilterClick(undefined);
   };

   useEffect(() => {
      if (!searchQuery.data?.documentCounts) return;
      const allTotals = {
         product: searchQuery.data.documentCounts.products,
         brand: searchQuery.data.documentCounts.brands,
         caseStudy: searchQuery.data.documentCounts.caseStudies
      };
      setTotals(allTotals);
      // Only track the search when the app gives the user a result and when the search changes
      if (search !== trackedSearch) trackSearch({ ...allTotals });
      // Only track the facets when the app gives the user a result and when a facet is added
      if (trackFilterClick) trackFacets({ ...allTotals });
      // Track the reset of the filters
      if (trackFacetsAndSearchBeforeReset) trackFilterReset();
   }, [searchQuery.data]);

   const autoSuggestQuery = useQuery({
      queryKey: ['auto-suggest', { facets, search: autoSuggestSearch }],
      queryFn: async ({ signal }) => {
         await new Promise((resolve) => {
            setTimeout(resolve, 500);
         });
         if (signal.aborted) {
            return undefined;
         }
         return getAutoSuggestResult({
            facets,
            search: autoSuggestSearch,
            token,
            correlationId: CorrelationService.generateCorrelationId('dpcid'),
            language
         });
      },
      enabled: () => autoSuggestSearch.length > 2 && autoSuggestExpanded
   });

   // UI State
   const [filterFlyoutOpen, setFilterFlyoutOpen] = useState(false);

   const facetGroups: FacetGroup[] = useMemo(() => {
      if (!searchQuery.data) return [];
      return searchQuery.data.facets.map((facet) => toFacetGroup(facet, language, countries));
   }, [searchQuery.data, language, countries]);

   const filtersVisible = useMemo(() => facets.length > 0 || (searchQuery.data && searchQuery.data.facets.length > 0), [facets, searchQuery.data]);

   // eslint-disable-next-line no-shadow
   const updateQueryString = (searchType: ProductRequestType, page: number, search: string, facets: FacetFilterWithTerms[], view = 'grid') => {
      const params = new URLSearchParams();

      params.set('tab', tabMap[searchType]);
      params.set('page', page.toString());
      if (hasListViewPermission) params.set('view', view.toString());

      if (search) params.set('search', search);
      if (facets.length > 0) params.set('filter', filterToQuery(facets));

      window.history.pushState(null, '', `?${params.toString()}`);

      // Store the current filters in session storage to make sure breadcrumbs work as expected.
      sessionStorage.setItem('pdp-filters', `?${params.toString()}`);
   };

   useEffect(() => {
      if (searchQuery.error) {
         notification(translate('products-overview-error'));
      }
   }, [searchQuery.error, language]);

   useEffect(() => {
      if (desktop) setFilterFlyoutOpen(false);
   }, [desktop]);

   useEffect(() => {
      if (!token) return;
      globals.actions.getPrivateToggles(token).then((value) => setFeatures(value));
   }, [token]);

   const onSearchChange = (query: string) => {
      setAutoSuggestSearch(query);
   };

   const onSearch = (query: string) => {
      setSearch(query);
      setPage(1);
      updateQueryString(searchType, 1, query, facets);
   };

   const onTabChange = (newTab: ProductRequestType) => {
      setExpandedGroups([]);
      setSearchType(newTab);
      setPage(1);
      setViewType({ view: 'grid', size: viewMap.grid });

      updateQueryString(newTab, 1, search, facets, 'grid');
   };

   const onPageChange = (newPage: number) => {
      setPage(newPage);
      updateQueryString(searchType, newPage, search, facets);
   };

   const updateListView = (view: string) => {
      if (!hasListViewPermission) return;

      let newViewType: ViewType = 'grid';
      if (view === 'list') {
         newViewType = 'list';
      }
      setViewType({ view: newViewType, size: viewMap[newViewType] });
      updateQueryString(searchType, 1, search, facets, newViewType);
   };

   const facetChange = (newFacets: FacetFilterWithTerms[]) => {
      setFacets([...newFacets]);
      setPage(1);

      updateQueryString(searchType, 1, search, [...newFacets]);
   };

   const facetReset = () => {
      setTrackFacetsAndSearchBeforeReset({ facets: [...facets], search, page });
      setFacets([...baseFacetFilters]);
      setSearch('');
      setPage(1);

      updateQueryString(searchType, 1, '', baseFacetFilters);
   };

   const onExpand = (key: string, expanded: boolean) => {
      if (expanded) {
         setExpandedGroups([...expandedGroups, key]);
      } else {
         setExpandedGroups(expandedGroups.filter((group) => group !== key));
      }
   };

   const filters = searchQuery.data ? (
      <ProductPortfolioFilters
         facetFilters={facets}
         facets={facetGroups}
         canResetFilters={canResetFilters}
         filterFlyoutOpen={filterFlyoutOpen}
         language={language}
         locked={searchQuery.isPlaceholderData}
         expandedGroups={expandedGroups}
         onFilterAdjust={facetChange}
         onFilterReset={facetReset}
         onFilterClicked={(value) => setTrackFilterClick(value)}
         onExpandChange={onExpand}
         onCloseAll={() => setExpandedGroups([])}
         onFilterFlyoutClose={() => setFilterFlyoutOpen(false)}
      />
   ) : null;

   return (
      <>
         <Box gap="lg" direction="column">
            <Box gap="md" direction="column">
               <ProductPortfolioSearch
                  onAutoSuggestMenuChange={(value) => setAutoSuggestExpanded(value)}
                  autoSuggestExpanded={autoSuggestExpanded}
                  language={language}
                  numberOfFilters={facets.length}
                  autoSuggestResult={autoSuggestQuery.data}
                  loading={autoSuggestQuery.isFetching}
                  baseRoutes={baseRoutes}
                  globals={globals}
                  facetFilters={facets}
                  onFiltersChange={facetChange}
                  onFilterFlyoutOpen={() => setFilterFlyoutOpen(true)}
                  onSearchChange={onSearchChange}
                  onSearch={onSearch}
                  data-testid="product-portfolio__search"
               />
               <ProductPortfolioTabs
                  language={language}
                  onTabChange={onTabChange}
                  switchView={updateListView}
                  searchType={searchType}
                  viewType={viewType.view}
                  hasListViewPermission={hasListViewPermission && !phone}
                  totals={totals}
                  features={features}
                  locked={searchQuery.isPlaceholderData}
               />
            </Box>

            {searchQuery.data && (
               <Grid useContainerQuery>
                  {filtersVisible && (
                     <BreakpointSwitch includeViews={['desktop']}>
                        <GridCol spanDesktop={3} spanTablet={0} spanPhone={0}>
                           {filters}
                        </GridCol>
                     </BreakpointSwitch>
                  )}
                  <GridCol spanDesktop={filtersVisible ? 9 : 12} spanTablet={12} spanPhone={4} useContainerQuery>
                     {searchQuery.data.content.length === 0 ? (
                        <ProductPortfolioEmpty searchTerm={search} language={language} />
                     ) : (
                        <div
                           className={styles.productPortfolio__grid}
                           style={{
                              opacity: searchQuery.isPlaceholderData ? 0.5 : 1,
                              pointerEvents: searchQuery.isPlaceholderData ? 'none' : 'unset'
                           }}
                        >
                           {viewType.view === 'grid' || searchType !== 'product' || !hasListViewPermission || phone ? (
                              <ProductPortfolioGrid
                                 baseRoutes={baseRoutes}
                                 relatedDocumentsAnchor={relatedDocumentsAnchor}
                                 content={searchQuery.data.content}
                                 hasPublicLabelAndSigningsPermission={hasPublicLabelAndSigningsPermission}
                                 hasSustainabilityLabels={hasSustainabilityLabels}
                                 onPageChange={onPageChange}
                                 page={page}
                                 totalPages={searchQuery.data.totalPages}
                                 globals={globals}
                              />
                           ) : (
                              <ProductPortfolioTable
                                 baseRoutes={baseRoutes}
                                 content={searchQuery.data.content}
                                 hasPublicLabelAndSigningsPermission={hasPublicLabelAndSigningsPermission}
                                 hasSustainabilityLabels={hasSustainabilityLabels}
                                 onPageChange={onPageChange}
                                 page={page}
                                 totalPages={searchQuery.data.totalPages}
                                 globals={globals}
                              />
                           )}
                        </div>
                     )}
                  </GridCol>
               </Grid>
            )}
         </Box>

         {searchQuery.data && (
            <BreakpointSwitch excludeViews={['desktop']}>
               <ClientPortal selector="body">
                  <Flyout open={filterFlyoutOpen} onFlyoutClose={() => setFilterFlyoutOpen(false)} width={phone ? '320px' : '560px'} disableGutters>
                     <Box direction="column" gap="none" height="100%">
                        {filters}
                     </Box>
                  </Flyout>
               </ClientPortal>
            </BreakpointSwitch>
         )}
      </>
   );
};
