import { FC } from 'react';

import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { Chip } from '@ravago/shared/radiance/components/chip/components/Chip/Chip';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';

interface Props {
   headerText: string;
   id: string | null;
   date?: string;
}

export const CallOffOrderPageHeader: FC<Props> = ({ headerText, id, date }) => {
   const translate = useTranslations();

   return (
      <Box justify="between" width="100%" items="center">
         <BreakpointSwitch includeViews={['desktop', 'tab-land']}>
            {id && (
               <Typography variant="heading-1" component="h1" align="left" color="strong">
                  {headerText} {id}
               </Typography>
            )}
            {date && (
               <Box height="36px" items="center">
                  <Chip
                     type={
                        <Typography variant="small" component="span" color="strong">
                           {translate('my-orders.period')}
                        </Typography>
                     }
                     label={
                        <Typography variant="small-highlight" component="span" align="left" color="strong">
                           {date}
                        </Typography>
                     }
                  />
               </Box>
            )}
         </BreakpointSwitch>
         <BreakpointSwitch includeViews={['tab-port', 'phone']}>
            <Box direction="column" width="100%">
               {date && (
                  <Box justify="end" height="36px">
                     <Chip
                        type={
                           <Typography variant="small" component="span" color="strong">
                              {translate('my-orders.period')}
                           </Typography>
                        }
                        label={
                           <Typography variant="small-highlight" component="span" align="left" color="strong">
                              {date}
                           </Typography>
                        }
                     />
                  </Box>
               )}
               {id && (
                  <Typography variant="heading-1" component="h1" align="left" color="strong">
                     {headerText} {id}
                  </Typography>
               )}
            </Box>
         </BreakpointSwitch>
      </Box>
   );
};
