import { FC } from 'react';

import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface Props {
   title: string;
   icon: string;
   amountOfOrders?: number;
   isExpanded: boolean;
}

const OrderExpandHeader: FC<Props> = ({ title, icon, amountOfOrders = 0, isExpanded }) => (
   <Box justify="between">
      <Box items="center">
         <Box gap="sm">
            <Icon color="inherit" icon={icon} size="body" type="default" />
            <Typography variant="body-highlight" component="span" color="strong">
               {title}
            </Typography>
         </Box>
         {amountOfOrders > 0 && (
            <Badge size="regular" variant="primary">
               {amountOfOrders}
            </Badge>
         )}
      </Box>
      <Icon color="primary" icon={isExpanded ? 'chevron-up' : 'chevron-down'} size="body" type="default" />
   </Box>
);

export default OrderExpandHeader;
