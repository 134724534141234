import { FC } from 'react';

import { Transaction } from '@ravago/shared/page-data/models/elements';

import { OpenInvoiceCard } from './open-invoice-card';

type Props = {
   currency: string;
   invoices: Transaction[];
   locale: string;
};

export const OpenInvoiceTableMobile: FC<Props> = ({ currency, invoices, locale }) =>
   // eslint-disable-next-line react/no-array-index-key
   invoices.map((invoice, index) => <OpenInvoiceCard key={index} currency={currency} invoice={invoice} locale={locale} />);
