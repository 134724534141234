import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Transaction } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Expand } from '@ravago/shared/radiance/components/expand/components/Expand/Expand';
import { Pagination } from '@ravago/shared/radiance/components/pagination/components/Pagination/Pagination';
import { Paper } from '@ravago/shared/radiance/components/paper/components/Paper/Paper';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../../../providers/translation-client-context-provider';

import { InvoiceSummary } from './invoice-summary';
import { OpenInvoiceTableDesktop } from './table/desktop/open-invoice-table-desktop';
import { OpenInvoiceTableMobile } from './table/mobile/open-invoice-table-mobile';

type Props = {
   collapsibleId: string | null;
   currency: string;
   invoices: Transaction[];
   isExpanded: boolean;
   locale: string;
   onExpandToggle: (expandedId: string | null) => void;
   totalAmount: number;
   totalOverDue: number;
};

const INVOICES_PER_PAGE = 10;

export const InvoiceCollapsible: FC<Props> = ({
   collapsibleId,
   currency,
   locale,
   isExpanded,
   invoices,
   onExpandToggle,
   totalAmount,
   totalOverDue
}) => {
   const [currentPage, setCurrentPage] = useState(1);
   const translate = useTranslations();

   const getNumberOfTotalPages = useCallback(() => Math.ceil(invoices.length / INVOICES_PER_PAGE), [invoices]);
   const pagedInvoices = useMemo(
      () => invoices.slice((currentPage - 1) * INVOICES_PER_PAGE, currentPage * INVOICES_PER_PAGE),
      [currentPage, invoices]
   );

   const handleExpandToggle = (state: boolean) => {
      onExpandToggle(!state ? null : collapsibleId);
   };

   useEffect(() => {
      setCurrentPage(1);
   }, [getNumberOfTotalPages]);

   return (
      <Paper shadow>
         <Expand
            expanded={isExpanded}
            header={
               <InvoiceSummary
                  currency={currency}
                  isExpanded={isExpanded}
                  invoices={invoices}
                  totalAmount={totalAmount}
                  totalOverDue={totalOverDue}
                  locale={locale}
               />
            }
            onExpandToggle={handleExpandToggle}
         >
            {!invoices.length ? (
               <Box direction="column" gap="md" margin={{ top: 'sm' }}>
                  <Divider />
                  <Typography>{translate('invoices.empty-state.no-documents-found')}</Typography>
               </Box>
            ) : (
               <Box direction="column" margin={{ top: 'sm' }}>
                  <BreakpointSwitch includeViews={['desktop']}>
                     <OpenInvoiceTableDesktop currency={currency} invoices={pagedInvoices} locale={locale} />
                  </BreakpointSwitch>
                  <BreakpointSwitch excludeViews={['desktop']}>
                     <OpenInvoiceTableMobile currency={currency} invoices={pagedInvoices} locale={locale} />
                  </BreakpointSwitch>
                  <Box justify="end" margin={{ top: 'sm' }}>
                     <Pagination current={currentPage} onChange={setCurrentPage} total={getNumberOfTotalPages()} truncate />
                  </Box>
               </Box>
            )}
         </Expand>
      </Paper>
   );
};
