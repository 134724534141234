'use client';

import { FC, PropsWithChildren, useState } from 'react';

import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { Link } from '../../../shared/link/link';
import styles from '../detail-header.module.scss';

interface Props extends PropsWithChildren {
   expandable?: boolean;
}

export const ReadMoreContainer: FC<Props> = ({ children, expandable = true }) => {
   const translate = useTranslations();
   const [expanded, setExpanded] = useState(false);

   return (
      <Typography container variant="inherit" color="inverse-strong">
         <Box direction="column" gap="2xs">
            <div className={!expanded && expandable ? styles.maxLines : undefined}>{children}</div>

            {expandable && (
               <>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link color="light" onClick={() => setExpanded((curr) => !curr)}>
                     {translate(!expanded ? 'read-more' : 'read-less')}
                  </Link>
               </>
            )}
         </Box>
      </Typography>
   );
};
