'use client';

import { FC } from 'react';

import { ContentfulProduct } from '@ravago/shared/page-data/models/contentful';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableHeader } from '@ravago/shared/radiance/components/table/components/TableHeader/TableHeader';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../providers/translation-client-context-provider';

interface Props {
   product?: ContentfulProduct;
}

export const ProductSpecifications: FC<Props> = ({ product }) => {
   const translate = useTranslations();

   if (!product?.specifications?.length) {
      return <Typography component="p">{translate('product.spec.no-specifications')}</Typography>;
   }

   return (
      <Table stroked="horizontal" tableLayout="fixed">
         <TableBody>
            {product?.specifications?.map((specification) => (
               <TableRow key={specification.type}>
                  <TableHeader>{specification.type}</TableHeader>
                  <TableCell>{specification.name ?? '-'}</TableCell>
               </TableRow>
            ))}
         </TableBody>
      </Table>
   );
};
