'use client';

import { ReactNode, useEffect, useMemo } from 'react';

import { useAuthenticationContext, UserGroups } from '@ravago/shared/authentication';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';

import { usePathname } from 'next/navigation';

export default abstract class AuthGuardService {
   static isAuth = (component: ReactNode, redirectUser: boolean, language?: string, allowedRoles?: UserGroups[]): ReactNode | null => {
      const { loading, user, login } = useAuthenticationContext();
      const pathName = usePathname();
      const userHasAccess = useMemo(() => {
         if (!allowedRoles) return !!user;
         return user?.groups.some((group) => allowedRoles.includes(group));
      }, [allowedRoles, user]);

      useEffect(() => {
         if (!loading && !userHasAccess && redirectUser) {
            login(pathName);
         }
      }, [loading, userHasAccess, pathName, redirectUser, language]);

      if (!userHasAccess) {
         if (loading && redirectUser) {
            return (
               <Box spacing="lg" width="100%" direction="column" items="center">
                  <Spinner />
               </Box>
            );
         }
         return null;
      }

      return component;
   };
}
