'use client';

import { FC } from 'react';

import { ElementProps } from '@ravago/shared/page-data/models/base';
import { ContentfulCaseStudyEntry } from '@ravago/shared/page-data/models/contentful';
import { CaseStudyWebsiteUrlNode } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { Entry } from 'contentful';

import { Link } from '../../shared/link/link';
import { ConsumerWrapper } from '../consumer-wrapper/consumer-wrapper';
import { MockLink } from '../mock-link/mock-link';

export const CaseStudyWebsiteUrlElement: FC<ElementProps<CaseStudyWebsiteUrlNode>> = ({ node, globals }) => {
   const caseStudy = globals?.contentEntity as Entry<ContentfulCaseStudyEntry> | undefined;
   const websiteUrl = caseStudy?.fields?.link;

   return (
      <>
         {!websiteUrl && !globals.isConsumer && (
            <Typography data-testid="case-study-url__typography__not-found" color="error">
               Website URL not set
            </Typography>
         )}

         {websiteUrl &&
            (node.switchToButton ? (
               <ConsumerWrapper
                  isConsumer={globals.isConsumer}
                  renderConsumer={(children) => (
                     <Link underline="none" href={websiteUrl}>
                        {children}
                     </Link>
                  )}
                  renderProducer={(children) => <MockLink>{children}</MockLink>}
               >
                  <Button data-testid="case-study-url__button" leadingIcon={node.leadingIcon} variant={node.buttonVariant ?? 'secondary'}>
                     {node.text}
                  </Button>
               </ConsumerWrapper>
            ) : (
               <Box gap="2xs" items="center">
                  <ConsumerWrapper
                     isConsumer={globals.isConsumer}
                     renderConsumer={(children) => (
                        <Link underline="none" href={websiteUrl}>
                           {children}
                        </Link>
                     )}
                     renderProducer={(children) => <MockLink>{children}</MockLink>}
                  >
                     <Icon icon={node.leadingIcon} />
                  </ConsumerWrapper>
                  <ConsumerWrapper
                     isConsumer={globals.isConsumer}
                     renderConsumer={(children) => <Link href={websiteUrl}>{children}</Link>}
                     renderProducer={(children) => <MockLink>{children}</MockLink>}
                  >
                     <Typography data-testid="case-study-url__typography__link-found" color="primary">
                        {node.text}
                     </Typography>
                  </ConsumerWrapper>
               </Box>
            ))}
      </>
   );
};
