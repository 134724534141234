import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   label?: ReactNode;
   leadingIcon?: string;
   toggled?: boolean;
   disabled?: boolean;
   onClick?: () => void;
}

export const ToggleChip: FC<Props> = ({ label, leadingIcon, toggled = false, disabled = false, onClick }) => (
   <button
      type="button"
      data-testid={`toggle-chip${onClick ? '--clickable' : ''}${disabled ? '--disabled' : ''}${toggled ? '--toggled' : ''}`}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
         'rounded-component-data-display-action-container-border-radius',
         !toggled ? 'border-component-data-display-action-container-border-width-default bg-component-data-display-action-container-color-background-enabled' : 'border-component-data-display-action-container-border-width-toggled bg-component-data-display-action-container-color-background-toggled',
         'border-component-data-display-action-container-color-border-enabled',
         'focus-visible:border-component-data-display-action-container-color-border-focused',
         'group inline-flex overflow-hidden',
         onClick ? 'cursor-pointer' : 'cursor-default'
      )}
   >
      <div
         className={classNames(
            'px-component-data-display-action-container-spacing-padding-x-default',
            'py-component-data-display-action-container-spacing-padding-y',
            'min-h-component-data-display-action-container-sizing-min-height',
            'gap-component-data-display-action-container-spacing-gap',
            'flex h-full w-full items-center transition-all',
            !disabled &&
               onClick &&
               !toggled &&
               'group-hover:bg-component-data-display-action-state-layer-color-background-hover-default group-focus-visible:bg-component-data-display-action-state-layer-color-background-focused-default group-active:bg-component-data-display-action-state-layer-color-background-pressed-default',
            !disabled &&
               onClick &&
               toggled &&
               'group-hover:bg-component-data-display-action-state-layer-color-background-hover-toggled group-focus-visible:bg-component-data-display-action-state-layer-color-background-focused-toggled group-active:bg-component-data-display-action-state-layer-color-background-pressed-toggled',
            leadingIcon && 'pl-component-data-display-action-container-spacing-padding-x-icon'
         )}
      >
         {leadingIcon && (
            <div
               data-testid="toggle-chip--leading-icon"
               className={classNames(
                  disabled && 'text-component-data-display-action-icon-color-text-on-disabled-default',
                  !disabled && !toggled && 'text-component-data-display-action-icon-color-text-primary-default',
                  !disabled && toggled && 'text-component-data-display-action-icon-color-text-primary-toggled',
                  disabled && toggled && 'text-component-data-display-action-icon-color-text-on-disabled-toggled'
               )}
            >
               <Icon icon={leadingIcon} />
            </div>
         )}
         {label && (
            <span
               data-testid="toggle-chip--label"
               className={classNames(
                  disabled && 'text-component-data-display-action-label-color-text-on-disabled-default',
                  !disabled && !toggled && 'text-component-data-display-action-label-text-color-text-on-theme-default',
                  !disabled && toggled && 'text-component-data-display-action-label-color-text-on-theme-toggled',
                  disabled && toggled && 'text-component-data-display-action-label-color-text-on-disabled-toggled'
               )}
            >
               <Typography color="inherit" variant="small">
                  {label}
               </Typography>
            </span>
         )}
      </div>
   </button>
);
