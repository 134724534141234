import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Document } from '@contentful/rich-text-types';

import get from 'lodash/get';
import { marked } from 'marked';

import { TextUtils } from '../../components/content/text/utils/text.utils';

const isDocument = (object: object): object is Document => 'nodeType' in object;

interface Options {
   allowDocument: boolean;
   defaultValue?: string;
   defaultValues?: Record<string, string>;
   parseDocumentFn?: (document: Document) => string;
}

const defaultOptions: Options = {
   allowDocument: false
};

export const defaultValues = { 'fields.countries.fields.name': 'brand-countries-not-found' };

/**
 * Parses a string to extract key-value options within parentheses.
 *
 * The input string is expected to have the format: `mainString(option1=value1;option2=value2;...)`.
 * The function extracts the options part within the parentheses and returns an object with the parsed key-value pairs.
 *
 * @param {string} placeholder - The input string containing the options within parentheses.
 * @returns {Record<string, string>} An object containing the parsed key-value pairs.
 *
 * @example
 * const input = "fields.countries(delimiter=,;field=fields.name)";
 * const options = getPlaceholderParameters(input);
 * console.log(options); // { delimiter: ',', field: 'fields.name' }
 */
export const getPlaceholderParameters = (placeholder: string): Record<string, string> => {
   const result: Record<string, string> = {};
   const regex = /.*\((.*)\)/;
   const match = placeholder.match(regex);

   if (match) {
      const optionsString = match[1];
      const pairs = optionsString.split(';');

      pairs.forEach((pair) => {
         const [key, value] = pair.split('=');
         result[key] = value;
      });
   }

   return result;
};

/**
 * Replaces placeholders in a template string with corresponding values from the data object.
 * Supports multiple fallback paths in the format [%s:path1|path2|...].
 * Supports options when dealing with array fields: (delimiter=,)
 *
 * Full example: [%s:fields.countries(delimiter=,){fields.name}|fields.title]
 *
 * @param template - The template string containing placeholders.
 * @param data - The data object to retrieve values from.
 * @param options - Optional settings for placeholder replacement.
 * @returns The template string with placeholders replaced by data values.
 */
export const replacePlaceholder = (
   template: string,
   data: object | undefined,
   isConsumer = true,
   options: Partial<Options> = defaultOptions
): string => {
   if (!data) return template;

   const regex = /\[%s:([^%]+)]/g;

   const params = getPlaceholderParameters(template);

   let shouldRemoveOuterElement = false;

   let replacedText = template.replace(regex, (match, key: string) => {
      const trimmedKeys = key.trim().split('|');

      let value: any;
      let searchKey = '';

      for (let i = 0; i < trimmedKeys.length; i += 1) {
         /**
          * Remove any optional parameters from the key.
          */
         searchKey = trimmedKeys[i].replace(/(\(.*?\)|\{.*?})/g, '');

         value = get(data, searchKey);

         if (value !== undefined) {
            break;
         }
      }

      if (value === undefined || value === null) {
         const defaultValue = `${searchKey}${params.field ? `.${params.field}` : ''}`;

         if (isConsumer) return options.defaultValues?.[defaultValue] ?? options.defaultValue ?? '';

         return options.defaultValues?.[defaultValue] ?? options.defaultValue ?? match;
      }

      if (typeof value === 'function') {
         return match;
      }

      if (typeof value.toLocaleDateString === 'function') {
         return value.toLocaleDateString();
      }

      if (Array.isArray(value)) {
         if (!value.length) return '';

         const nestedFields = params.field ? value.map((item: unknown) => get(item, params.field)) : value;

         return nestedFields.join(`${params.delimiter ?? ','} `);
      }

      if (typeof value === 'object') {
         if (options.allowDocument && isDocument(value)) {
            shouldRemoveOuterElement = true;
            return TextUtils.addClasses(options.parseDocumentFn?.(value) ?? documentToHtmlString(value));
         }
         return match;
      }

      if (params.markdown) {
         shouldRemoveOuterElement = true;
         return TextUtils.addClasses(marked(value, { breaks: true }));
      }

      return value.toString();
   });

   if (shouldRemoveOuterElement) {
      replacedText = replacedText.replace(/^<[^>]+>/g, '').replace(/<\/[^>]+>$/g, '');
   }

   return replacedText;
};
