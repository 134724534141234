'use client';

import { FC, useMemo, useRef } from 'react';

import Format from '../../../../utils/format/format';
import { Badge } from '../../../badge/components/Badge/Badge';
import { Box } from '../../../box/components/Box/Box';
import { Checkbox, CheckboxState } from '../../../checkbox/components/Checkbox/Checkbox';
import { Link } from '../../../link/components/Link/Link';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   href: string;
   target: '_blank' | '_self';
   noFollow?: boolean;
   background: string;
   name: string;
   producer: string;
   polymerType: string;
   technologies: string[];
   features: string[];
   fillers?: string[];
   badgLeft?: React.ReactNode;
   badgeRight?: React.ReactNode;
   compareLabel?: string;
   toggleState?: boolean;
   fillHeight?: boolean;
   compareDisabled?: boolean;
   onToggleCompare?: (checked: CheckboxState) => void;
}

export const ProductCard: FC<Props> = ({ href, target = '_self', noFollow, background, name, producer, polymerType, technologies, features, fillers, badgLeft, badgeRight, compareLabel, toggleState, fillHeight, compareDisabled, onToggleCompare }) => {
   const visibleFeatures = useMemo(() => (features || []).slice(0, 3), [features]);
   const remainingFeatures = (features || []).length - visibleFeatures.length;

   const checkboxId = useRef(Format('product-card-compare', name));

   return (
      <Link href={href} target={target} noFollow={noFollow} underline="none">
         <Box direction="column" gap="none" rounded="lg" overflowY="hidden" height={fillHeight ? '100%' : 'auto'} shadow>
            <Box direction="column" justify="between" minHeight="150px" spacing="md" background={background} position="relative">
               <Box justify="between" position="relative" zIndex="1">
                  {badgLeft}
                  {badgeRight}
               </Box>
               <Box direction="column" gap="none" position="relative" zIndex="1">
                  <Typography variant="body-highlight" color="inverse-strong">
                     {name}
                  </Typography>
                  <Typography variant="small" color="inverse-strong">
                     {producer}
                  </Typography>
               </Box>
               <Box position="absolute" left="0" top="0" backgroundColor="gradient-fade-out-black" width="100%" height="100%" />
            </Box>
            <Box direction="column" justify="between" spacing="md" height={fillHeight ? '100%' : 'auto'}>
               <Box direction="column" gap="md">
                  <Typography variant="small">
                     {polymerType} {fillers?.length ? `| ${fillers.join(', ')}` : ''}
                  </Typography>
                  {technologies?.length > 0 && <Typography variant="small">{technologies.join(', ')}</Typography>}
                  {visibleFeatures?.length > 0 && (
                     <Box gap="xs" wrap="wrap">
                        {visibleFeatures.map((feature) => (
                           <Badge key={feature} size="regular" variant="neutral" weight="regular">
                              {feature}
                           </Badge>
                        ))}

                        {remainingFeatures > 0 && (
                           <Badge size="regular" variant="neutral" weight="regular">
                              +{remainingFeatures}
                           </Badge>
                        )}
                     </Box>
                  )}
               </Box>

               {onToggleCompare && (
                  <Checkbox id={checkboxId.current} gutter={false} checked={toggleState} disabled={compareDisabled} onChange={onToggleCompare}>
                     {compareLabel && <Typography variant="small">{compareLabel}</Typography>}
                  </Checkbox>
               )}
            </Box>
         </Box>
      </Link>
   );
};
