'use client';

import { FC, useState } from 'react';

import { AutoSuggestResponse, FacetFilterWithTerms } from '@ravago/shared/page-data/models/elastic';
import { DynamicPageUrl, RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { ClientPortal } from '@ravago/shared/radiance/components/client-portal/components/ClientPortal/ClientPortal';
import { InputGroup } from '@ravago/shared/radiance/components/input-field/components/InputGroup/InputGroup';
import { Menu } from '@ravago/shared/radiance/components/menu/components/Menu/Menu';
import { useBodyOverflowHidden } from '@ravago/shared/radiance/hooks/useBodyOverflowHidden/useBodyOverflowHidden';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import classNames from 'classnames';

import { useTranslations } from '../../../providers/translation-client-context-provider';
import { CorrelationService } from '../../../services/tracking/correlation-token.service';
import { TrackingUtils } from '../../../utils/tracking/tracking.utils';

import styles from './auto-suggest.module.scss';
import { AutoSuggestPanel } from './components/auto-suggest-panel';

interface Props {
   id: string;
   result?: AutoSuggestResponse;
   searchTerm?: string;
   language: string;
   loading?: boolean;
   baseRoutes?: {
      product: string;
      brand: string;
      caseStudy: string;
   };
   productOverviewRoute?: DynamicPageUrl;
   globals: RendererGlobals;
   readonly?: boolean;
   expanded?: boolean;
   facetType?: 'apply' | 'navigate';
   onChange?: (value: string) => void;
   onSearch?: (value: string) => void;
   onClear?: () => void;
   onAutoSuggestMenuChange?: (value: boolean) => void;
   onFacetsClick?: (facets: Array<FacetFilterWithTerms>) => void;
}

export const AutoSuggest: FC<Props> = ({
   id,
   result,
   language,
   loading = false,
   baseRoutes,
   productOverviewRoute,
   globals,
   searchTerm = '',
   readonly = false,
   expanded,
   facetType = 'apply',
   onChange,
   onSearch,
   onAutoSuggestMenuChange,
   onClear,
   onFacetsClick
}) => {
   const translate = useTranslations();
   const { phone } = useBreakpoint();

   const [search, setSearch] = useState(searchTerm);
   const trimmedSearch = search.trim();

   useBodyOverflowHidden(phone && expanded);

   const handleOpenOrCloseAutoSuggest = (value: boolean) => {
      onAutoSuggestMenuChange?.(value);
   };

   const handleFacetsClick = (facets: Array<FacetFilterWithTerms>) => {
      TrackingUtils.track('autosuggest_facet_click', {
         correlationId: CorrelationService.getCorrelationId('dpcid'),
         facets: JSON.stringify(TrackingUtils.translateFacets([...facets], translate)),
         language
      });
      handleOpenOrCloseAutoSuggest(false);
      setSearch('');
      onChange?.('');
      onSearch?.('');
      onFacetsClick?.(facets);
   };

   const handleChange = (value: string) => {
      setSearch(value);
      onChange?.(value.trim());
   };

   const handleSearch = () => {
      if (facetType === 'navigate') {
         return;
      }
      handleOpenOrCloseAutoSuggest(false);
      onSearch?.(search);
   };

   const handleClear = () => {
      setSearch('');
      onClear?.();
   };

   const handleMobileBack = () => {
      handleOpenOrCloseAutoSuggest(false);
      setSearch(searchTerm);
      onChange?.(searchTerm);
   };

   const showMenuContent = expanded && trimmedSearch.length > 2 && !phone;

   return (
      <>
         <div role="presentation" onClick={() => (phone ? handleOpenOrCloseAutoSuggest(true) : undefined)} style={{ display: 'contents' }}>
            <Menu
               content={
                  showMenuContent ? (
                     <AutoSuggestPanel
                        result={result}
                        searchTerm={search}
                        language={language}
                        loading={loading}
                        baseRoutes={baseRoutes}
                        productOverviewRoute={productOverviewRoute}
                        facetType={facetType}
                        globals={globals}
                        onFacetFiltersClick={handleFacetsClick}
                        data-testid="auto-suggest__auto-suggest-panel"
                     />
                  ) : undefined
               }
               width="fill"
               variant="alternate"
               onMenuClose={!phone ? () => handleOpenOrCloseAutoSuggest(false) : undefined}
               open={showMenuContent}
               data-testid="auto-suggest__menu"
            >
               <div style={{ width: '100%', pointerEvents: phone ? 'none' : 'auto' }}>
                  <Box direction="column" width="100%">
                     <InputGroup
                        id={id}
                        actionIcon="search"
                        autocomplete="off"
                        clearable
                        label={translate('products-search-placeholder')}
                        onChange={handleChange}
                        value={search}
                        width="100%"
                        readOnly={readonly}
                        onEnter={handleSearch}
                        onActionClick={handleSearch}
                        onClear={handleClear}
                        onFocus={() => handleOpenOrCloseAutoSuggest(true)}
                        data-testid="auto-suggest__input-group"
                     />
                  </Box>
               </div>
            </Menu>
         </div>
         <ClientPortal selector="body">
            {/* TODO: Replace with new surface component */}
            <div className={classNames(styles.autoSuggest__mobileContainer, phone && expanded && styles.autoSuggest__mobileContainer__expanded)}>
               <Box spacing="lg" direction="column" position="fixed" inset={0} width="100%" height="100%" overflowY="auto">
                  {phone && expanded && (
                     <Box gap="xs">
                        <IconButton backdrop icon="chevron-left" onClick={handleMobileBack} />
                        <InputGroup
                           id={`${id}--mobile`}
                           actionIcon="search"
                           autocomplete="off"
                           clearable
                           label={translate('products-search-placeholder')}
                           onChange={handleChange}
                           onEnter={handleSearch}
                           onActionClick={handleSearch}
                           onClear={handleClear}
                           value={search}
                           width="100%"
                           autoFocus
                        />
                     </Box>
                  )}
                  <BreakpointSwitch includeViews={['phone']}>
                     {trimmedSearch.length > 2 && result && (
                        <AutoSuggestPanel
                           result={result}
                           searchTerm={search}
                           language={language}
                           loading={loading}
                           baseRoutes={baseRoutes}
                           productOverviewRoute={productOverviewRoute}
                           facetType={facetType}
                           globals={globals}
                           onFacetFiltersClick={handleFacetsClick}
                           data-testid="auto-suggest__auto-suggest-panel"
                        />
                     )}
                  </BreakpointSwitch>
               </Box>
            </div>
         </ClientPortal>
      </>
   );
};
