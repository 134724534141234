import { UserGroups } from '@ravago/shared/authentication';
import {
   CaseStudyList,
   ContentfulCaseStudy,
   ContentfulCaseStudyEntry,
   ContentfulCaseStudyIndustry,
   ContentfulCollectionName
} from '@ravago/shared/page-data/models/contentful';
import { OverviewPageCaseStudyItem } from '@ravago/shared/page-data/models/elastic';
import { CaseStudyCard } from '@ravago/shared/page-data/models/elements';
import { BaseQuery } from '@ravago/shared/page-data/models/utils';

import { ContentfulClientApi, EntryCollection } from 'contentful';

import { AssetUtils } from '../asset/asset.utils';
import { cache } from '../cache/cache.utils';
import { MarketSegmentUtils } from '../market-segment/market-segment.utils';
import { ProductUtils } from '../product/product.utils';
import { QueryBuilder } from '../query-builder/query-builder';

/* c8 ignore start */
export class CaseStudyUtils {
   static getCaseStudies = cache(
      async (contentfulClientApi: ContentfulClientApi, locale: string, query: BaseQuery, userGroups: UserGroups[]): Promise<CaseStudyList> => {
         const contentType: ContentfulCollectionName = 'caseStudy';

         const { limit, orderBy, caseStudyIds, productIds, industryIds, filterBy, brandIds } = query;

         const [productId] = productIds || [];
         const [brandId] = brandIds || [];

         const userInternal = userGroups.includes(UserGroups.ravagoSalesRepresentative) || userGroups.includes(UserGroups.ravagoGlobalGroupAdmin);

         const queryOptions = new QueryBuilder()
            .contentType(contentType)
            .limit(limit || 4)
            .order(orderBy)
            .include(10)
            .locale(locale)
            .setArrayOptional('sys.id[in]', caseStudyIds)
            .setOptional('fields.products.sys.id', productId);

         if (!userInternal) {
            queryOptions.set('fields.visibility[all]', 'External');
         }

         if (brandId) {
            const products = await ProductUtils.getProductCollection(contentfulClientApi, locale, {
               limit: 1000,
               filterBy,
               orderBy,
               brandIds: [brandId]
            });

            queryOptions.setArrayOptional(
               'fields.products.sys.id[in]',
               products.items.map((prod) => prod.id)
            );
         }

         if (industryIds?.length) {
            const marketSegments = await MarketSegmentUtils.getMarketSegmentsByQuery(contentfulClientApi, locale, {
               limit: 1000,
               filterBy,
               orderBy,
               industryIds
            });
            const marketSegmentIds: Array<string> = marketSegments?.map((marketSegment) => marketSegment.sys.id) || [];

            queryOptions.setArrayOptional('fields.segments.sys.id[in]', marketSegmentIds);
         }

         const entries = await contentfulClientApi.getEntries<ContentfulCaseStudyEntry>(queryOptions.build());
         const response = this.normalizeEntryCollection(entries).slice(0, limit || 4);

         return { caseStudies: response, total: entries.total };
      }
   );

   static getAll = cache(async (contentfulClientApi: ContentfulClientApi, locale: string) => {
      const contentType: ContentfulCollectionName = 'caseStudy';

      const queryOptions = new QueryBuilder().contentType(contentType).limit(1000).include(10).locale(locale);

      const entries: EntryCollection<ContentfulCaseStudyEntry> = await contentfulClientApi.getEntries(queryOptions.build());

      while (entries.total > entries.items.length) {
         // eslint-disable-next-line no-await-in-loop
         const nextEntries: EntryCollection<ContentfulCaseStudyEntry> = await contentfulClientApi.getEntries(
            queryOptions.set('skip', entries.items.length).build()
         );

         entries.items.push(...nextEntries.items);
      }

      const response = this.normalizeEntryCollection(entries);

      return response;
   });

   static getMostCommonMarket = (caseStudy: ContentfulCaseStudy): ContentfulCaseStudyIndustry | undefined => {
      const industries = caseStudy.segments?.map((segment) => segment.industry).filter((item) => item);

      if (!industries.length) return undefined;

      return industries.sort((a, b) => industries.filter((v) => v === a).length - industries.filter((v) => v === b).length).pop();
   };

   private static normalizeEntryCollection({ items }: EntryCollection<ContentfulCaseStudyEntry>): ContentfulCaseStudy[] {
      return items.map((item) => ({
         sys: { id: item.sys.id },
         visibility: item.fields?.visibility ?? '',
         slug: item.fields?.slug ?? '',
         title: item.fields?.title ?? '',
         excerpt: item.fields?.excerpt ?? '',
         link: item.fields?.link ?? '',
         image: { url: item.fields.images?.[0]?.fields?.file?.url ?? '' },
         segments:
            item.fields?.segments?.map((segment) => ({
               sys: { id: segment.sys?.id ?? '' },
               name: segment.fields?.name ?? '',
               industry: {
                  sys: { id: segment.fields?.industry?.sys?.id ?? '' },
                  name: segment.fields?.industry?.fields?.name ?? '',
                  image: { url: segment.fields?.industry?.fields?.image?.fields?.file?.url ?? '' },
                  slug: segment.fields?.industry?.fields?.slug ?? ''
               }
            })) ?? [],
         processingMethod: {
            id: item.fields?.processingMethod?.sys?.id ?? '',
            name: item.fields?.processingMethod?.fields?.name ?? ''
         },
         products: item.fields?.products?.filter((p) => p.fields).map(ProductUtils.normalizeEntry) ?? [],
         features:
            item.fields?.features?.map((feature) => ({
               id: feature.sys?.id ?? '',
               name: feature.fields?.name ?? ''
            })) ?? [],
         images: [],
         isConfidential: item.fields?.confidential ?? false
      }));
   }

   private static getCaseStudyImageFromOverviewProduct(caseStudy: OverviewPageCaseStudyItem) {
      if (caseStudy.images?.length) return caseStudy.images[0];
      if (!caseStudy.industries) return undefined;

      for (let i = 0; i < caseStudy.industries.length; i += 1) {
         const industry = caseStudy.industries[i];
         if (industry.imageURL) {
            return industry.imageURL;
         }
         for (let j = 0; j < industry.segments.length; j += 1) {
            const segment = industry.segments[j];
            if (segment.imageURL) return segment.imageURL;
         }
      }

      return undefined;
   }

   private static getCaseStudyCardImage(
      caseStudy: ContentfulCaseStudy | OverviewPageCaseStudyItem,
      assetBasePath: string,
      acceptWebp?: boolean
   ): {
      image: string;
      backgroundColor?: 'primary' | 'secondary';
   } {
      if ('qualifier' in caseStudy) {
         const imageUrl = this.getCaseStudyImageFromOverviewProduct(caseStudy);
         if (imageUrl) return { image: AssetUtils.optimizeImageIfRequired(imageUrl, acceptWebp, 400) };
         return { image: `${assetBasePath}/images/placeholders/default-banner-small.${acceptWebp ? 'webp' : 'png'}`, backgroundColor: 'primary' };
      }

      if (caseStudy.image?.url) return { image: AssetUtils.optimizeImageIfRequired(caseStudy.image.url, acceptWebp, 400) };
      if (caseStudy.images?.[0]?.fields?.file?.url)
         return { image: AssetUtils.optimizeImageIfRequired(caseStudy.images[0].fields.file.url, acceptWebp, 400) };

      const industry = CaseStudyUtils.getMostCommonMarket(caseStudy);
      const fallbackBanner = `${assetBasePath}/images/placeholders/default-banner-small.${acceptWebp ? 'webp' : 'png'}`;

      if (!industry) return { image: fallbackBanner, backgroundColor: 'primary' };

      return { image: AssetUtils.optimizeImageIfRequired(industry.image.url, acceptWebp, 400) };
   }

   static transformToCaseStudyCard(
      toTransform: ContentfulCaseStudy | OverviewPageCaseStudyItem,
      assetBasePath: string,
      acceptWebp?: boolean
   ): CaseStudyCard {
      const caseStudyCard: CaseStudyCard = {
         id: '',
         slug: toTransform.slug,
         title: '',
         excerpt: toTransform.excerpt,
         markets: [],
         products: [],
         isConfidential: false,
         ...this.getCaseStudyCardImage(toTransform, assetBasePath, acceptWebp)
      };

      if ('qualifier' in toTransform) {
         caseStudyCard.id = toTransform.slug;
         caseStudyCard.title = toTransform.label;
         caseStudyCard.markets = toTransform.industries?.map(({ name }) => name);
         caseStudyCard.products = toTransform.products?.map((product) => ({ name: product.name, pristine: false })) ?? [];
         caseStudyCard.isConfidential = toTransform.confidential;
         caseStudyCard.visibility = toTransform.visibility;
      } else {
         caseStudyCard.id = toTransform.sys.id;
         caseStudyCard.title = toTransform.title;
         caseStudyCard.markets = toTransform.segments.map(({ industry }) => industry.name);
         caseStudyCard.products = toTransform.products.map((product) => ({
            name: product.name,
            pristine: false
         }));
         caseStudyCard.isConfidential = toTransform.isConfidential;
         caseStudyCard.visibility = toTransform.visibility;
         caseStudyCard.noFollow = toTransform.seoMetadata?.noFollow;
      }

      return caseStudyCard;
   }
}

/* c8 ignore end */
