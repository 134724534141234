import { differenceInDays, format, subYears } from 'date-fns';

export const isOverDue = (dueDate?: string): boolean => {
   if (!dueDate) {
      return false;
   }

   const startOfToday = new Date(new Date().setHours(0, 0, 0, 0));

   return startOfToday >= new Date(dueDate);
};
export const calculateOverDueDays = (dueDate?: string): number => {
   if (!dueDate || !isOverDue(dueDate)) {
      return 0;
   }

   return differenceInDays(new Date(), dueDate);
};

export const getStringDate = (numberOfYears: number): string => {
   const dateYearsAgo = subYears(new Date(), numberOfYears);

   return format(dateYearsAgo, 'yyyy-MM-dd');
};
