import { SVGProps } from 'react';

const GuestNotAllowedInvoicesIllustration = (props: SVGProps<SVGSVGElement>) => (
   <svg viewBox="0 0 436 321" xmlns="http://www.w3.org/2000/svg" style={{ width: '100%', height: '100%' }} {...props}>
      <path d="M405.044 321V284.549C405.044 284.549 419.229 310.391 405.044 321Z" fill="#F1F1F1" />
      <path d="M405.92 320.994L379.107 296.343C379.107 296.343 407.709 303.355 405.92 320.994Z" fill="#F1F1F1" />
      <path
         d="M290.086 224.716C290.026 224.049 290.113 223.376 290.341 222.746C290.569 222.116 290.932 221.544 291.405 221.07C291.877 220.596 292.448 220.231 293.076 220.001C293.705 219.772 294.376 219.683 295.043 219.742L299.876 210.455L306.228 212.02L299.218 225.039C299.046 226.154 298.472 227.166 297.604 227.885C296.736 228.604 295.635 228.979 294.509 228.94C293.383 228.9 292.311 228.448 291.496 227.669C290.681 226.891 290.179 225.84 290.086 224.716Z"
         fill="#FFB7B7"
      />
      <path d="M318.938 315.191L313.578 315.19L311.028 294.486L318.939 294.487L318.938 315.191Z" fill="#FFB7B7" />
      <path
         d="M320.305 320.394L303.022 320.393V320.174C303.023 318.387 303.731 316.674 304.993 315.411C306.254 314.147 307.965 313.438 309.749 313.438L320.306 313.438L320.305 320.394Z"
         fill="#2F2E41"
      />
      <path d="M362.048 311.181L356.877 312.593L348.977 293.29L356.609 291.206L362.048 311.181Z" fill="#FFB7B7" />
      <path
         d="M364.734 315.84L348.06 320.393L348.002 320.182C347.533 318.459 347.767 316.619 348.652 315.068C349.537 313.516 351.001 312.381 352.722 311.911L362.907 309.129L364.734 315.84Z"
         fill="#2F2E41"
      />
      <path
         d="M309.153 225.755L306.479 266.415L312.223 312.033L321.63 309.157L322.027 268.398L329.553 244.895L351.835 307.67L362.034 303.901L353.617 263.142L348.863 224.862L309.153 225.755Z"
         fill="#2F2E41"
      />
      <path
         d="M335.147 154.356L319.305 156.695L313.597 162.348L311.825 182.658L312.926 200.647L308.338 232.371C308.338 232.371 319.875 225.957 328.817 233.986C337.758 242.015 350.269 235.405 350.526 230.064L335.147 154.356Z"
         fill="#CBCBCB"
      />
      <path
         d="M327.102 179.828L327.102 179.805L334.972 154.115L335.074 154.108C335.634 154.072 348.829 153.293 351.78 159.817L351.794 159.849L350.898 186.546L352.132 228.357L327.729 233.651L327.551 233.689L327.102 179.828Z"
         fill="#2F2E41"
      />
      <path
         d="M318.381 178.052L319.824 156.273C309.557 156.913 309.711 164.203 309.723 164.521L309.611 197.097L307.554 231.947L315.032 231.386L318.381 178.052Z"
         fill="#2F2E41"
      />
      <path d="M295.228 217.679L304.344 196.492L310.706 184.791L314.028 205.898L302.494 223.223L295.228 217.679Z" fill="#2F2E41" />
      <path
         d="M332.902 209.884C333.041 209.228 333.322 208.612 333.726 208.077C334.129 207.543 334.644 207.103 335.236 206.789C335.827 206.476 336.48 206.296 337.148 206.262C337.816 206.229 338.483 206.342 339.103 206.595L346.457 199.149L352.066 202.521L341.533 212.89C341.04 213.904 340.192 214.703 339.151 215.133C338.11 215.564 336.947 215.597 335.884 215.226C334.82 214.855 333.928 214.107 333.379 213.122C332.829 212.138 332.659 210.985 332.902 209.884Z"
         fill="#FFB7B7"
      />
      <path
         d="M339.086 203.877L354.081 187.404L344.155 173.22L345.177 166.286L351.027 159.049L351.141 159.197C351.764 160.005 366.418 179.069 366.838 186.287C367.26 193.53 346.198 211.854 345.302 212.631L345.177 212.739L339.086 203.877Z"
         fill="#2F2E41"
      />
      <path
         d="M326.006 152.571C331.892 152.571 336.663 147.793 336.663 141.898C336.663 136.004 331.892 131.226 326.006 131.226C320.12 131.226 315.348 136.004 315.348 141.898C315.348 147.793 320.12 152.571 326.006 152.571Z"
         fill="#FFB7B7"
      />
      <path
         d="M336.029 133.366L336.546 132.325L333.946 131.031C333.946 131.031 331.078 126.358 325.888 127.671C320.699 128.984 318.364 129.77 318.364 129.77L315.771 131.077L317.072 132.374L314.737 133.16L316.297 133.936L314.482 134.981L315.459 140.336C315.459 140.336 317.082 136.274 320.202 137.826C323.321 139.379 329.028 137.024 329.028 137.024L333.985 146.632C333.985 146.632 335.008 143.264 336.832 144.162C336.832 144.162 341.49 136.733 336.029 133.366Z"
         fill="#2F2E41"
      />
      <path d="M215.438 193.791L53.3685 238.552L0 44.7603L162.069 0L215.438 193.791Z" fill="white" />
      <path
         d="M215.438 193.791L53.3685 238.552L0 44.7603L162.069 0L215.438 193.791ZM55.4392 234.899L211.79 191.718L159.998 3.65287L3.64767 46.8339L55.4392 234.899Z"
         fill="#F1F1F1"
      />
      <path
         d="M126.799 86.8152L86.1735 98.0353C85.6004 98.1928 84.9883 98.1163 84.4715 97.8225C83.9547 97.5287 83.5753 97.0416 83.4166 96.468L72.2127 55.7842C72.0553 55.2103 72.1317 54.5973 72.4251 54.0797C72.7185 53.5622 73.2049 53.1823 73.7776 53.0234L114.403 41.8033C114.976 41.6458 115.589 41.7223 116.105 42.0161C116.622 42.3099 117.002 42.797 117.16 43.3706L128.364 84.0544C128.522 84.6283 128.445 85.2413 128.152 85.7589C127.858 86.2764 127.372 86.6563 126.799 86.8152ZM74.016 53.889C73.6723 53.9843 73.3805 54.2123 73.2044 54.5228C73.0284 54.8334 72.9826 55.2012 73.077 55.5455L84.281 96.2293C84.3762 96.5735 84.6038 96.8657 84.9139 97.042C85.224 97.2183 85.5913 97.2642 85.9351 97.1697L126.561 85.9496C126.904 85.8543 127.196 85.6264 127.372 85.3158C127.548 85.0053 127.594 84.6375 127.5 84.2931L116.296 43.6093C116.201 43.2652 115.973 42.9729 115.663 42.7966C115.353 42.6204 114.985 42.5745 114.642 42.669L74.016 53.889Z"
         fill="#E5E5E5"
      />
      <path
         d="M116.501 95.0155L75.8753 106.236C75.3595 106.377 74.8086 106.309 74.3434 106.044C73.8783 105.78 73.5368 105.341 73.394 104.825L62.19 64.1412C62.0484 63.6247 62.1171 63.073 62.3812 62.6072C62.6452 62.1414 63.083 61.7995 63.5985 61.6565L104.224 50.4364C104.74 50.2946 105.291 50.3635 105.756 50.6279C106.221 50.8923 106.563 51.3307 106.705 51.847L117.909 92.5308C118.051 93.0473 117.982 93.599 117.718 94.0648C117.454 94.5306 117.016 94.8725 116.501 95.0155Z"
         fill="var(--brand-color-primary-dark)"
      />
      <path d="M163.564 100.569L50.1934 131.88L51.4016 136.267L164.772 104.956L163.564 100.569Z" fill="#F1F1F1" />
      <path d="M166.714 112.008L53.3435 143.319L54.5517 147.706L167.922 116.395L166.714 112.008Z" fill="#F1F1F1" />
      <path d="M169.864 123.448L56.4939 154.758L57.7021 159.146L171.072 127.835L169.864 123.448Z" fill="#F1F1F1" />
      <path d="M175.408 143.58L62.0381 174.891L63.2463 179.278L176.617 147.967L175.408 143.58Z" fill="#F1F1F1" />
      <path d="M178.559 155.019L65.1885 186.33L66.3967 190.717L179.767 159.407L178.559 155.019Z" fill="#F1F1F1" />
      <path d="M181.709 166.458L68.3386 197.769L69.5469 202.156L182.917 170.846L181.709 166.458Z" fill="#F1F1F1" />
      <path d="M267.317 237.904H99.1975V36.8784H267.317V237.904Z" fill="white" />
      <path d="M267.317 237.904H99.1975V36.8784H267.317V237.904ZM102.163 234.934H264.351V39.8486H102.163V234.934Z" fill="#E5E5E5" />
      <path d="M236.708 123.297H140.587V127.848H236.708V123.297Z" fill="var(--brand-color-primary-dark)" />
      <path d="M236.708 135.129H140.587V139.68H236.708V135.129Z" fill="var(--brand-color-primary-dark)" />
      <path d="M236.708 146.962H140.587V151.513H236.708V146.962Z" fill="var(--brand-color-primary-dark)" />
      <path
         d="M132.773 129.209C134.411 129.209 135.738 127.88 135.738 126.239C135.738 124.599 134.411 123.269 132.773 123.269C131.135 123.269 129.807 124.599 129.807 126.239C129.807 127.88 131.135 129.209 132.773 129.209Z"
         fill="var(--brand-color-primary-dark)"
      />
      <path d="M236.708 72.3096H140.587V76.8607H236.708V72.3096Z" fill="#E5E5E5" />
      <path d="M236.708 84.1421H140.587V88.6932H236.708V84.1421Z" fill="#E5E5E5" />
      <path d="M236.708 95.9751H140.587V100.526H236.708V95.9751Z" fill="#E5E5E5" />
      <path
         d="M132.773 77.7269C134.411 77.7269 135.738 76.3971 135.738 74.7568C135.738 73.1164 134.411 71.7866 132.773 71.7866C131.135 71.7866 129.807 73.1164 129.807 74.7568C129.807 76.3971 131.135 77.7269 132.773 77.7269Z"
         fill="#E5E5E5"
      />
      <path d="M236.708 174.779H140.587V179.33H236.708V174.779Z" fill="#E5E5E5" />
      <path d="M236.708 186.612H140.587V191.163H236.708V186.612Z" fill="#E5E5E5" />
      <path d="M236.708 198.445H140.587V202.996H236.708V198.445Z" fill="#E5E5E5" />
      <path
         d="M132.773 180.197C134.411 180.197 135.738 178.867 135.738 177.226C135.738 175.586 134.411 174.256 132.773 174.256C131.135 174.256 129.807 175.586 129.807 177.226C129.807 178.867 131.135 180.197 132.773 180.197Z"
         fill="#E5E5E5"
      />
      <path
         d="M259.468 255.214C278.446 255.214 293.83 239.808 293.83 220.803C293.83 201.798 278.446 186.391 259.468 186.391C240.49 186.391 225.105 201.798 225.105 220.803C225.105 239.808 240.49 255.214 259.468 255.214Z"
         fill="var(--brand-color-primary-dark)"
      />
      <path
         d="M268.988 218.608V212.023C268.989 210.771 268.744 209.53 268.266 208.373C267.788 207.215 267.087 206.163 266.202 205.277C265.318 204.391 264.269 203.688 263.113 203.209C261.957 202.729 260.719 202.482 259.468 202.482C258.217 202.482 256.979 202.729 255.823 203.209C254.668 203.688 253.618 204.391 252.734 205.277C251.85 206.163 251.148 207.215 250.67 208.373C250.192 209.53 249.947 210.771 249.948 212.023V218.608C248.675 218.665 247.474 219.212 246.593 220.133C245.713 221.055 245.221 222.282 245.22 223.557V239.117H273.716V223.557C273.715 222.282 273.224 221.055 272.343 220.133C271.463 219.212 270.261 218.665 268.988 218.608ZM259.468 205.846C261.103 205.848 262.671 206.5 263.828 207.658C264.984 208.816 265.634 210.386 265.636 212.023V218.596H253.3V212.023C253.302 210.386 253.952 208.816 255.109 207.658C256.265 206.5 257.833 205.848 259.468 205.846Z"
         fill="white"
      />
      <path
         d="M262.03 226.292C262.03 225.849 261.916 225.414 261.698 225.029C261.481 224.644 261.168 224.322 260.79 224.094C260.412 223.865 259.981 223.739 259.54 223.727C259.098 223.714 258.661 223.816 258.271 224.023C257.88 224.23 257.55 224.534 257.312 224.907C257.073 225.279 256.935 225.707 256.911 226.148C256.886 226.59 256.976 227.03 257.172 227.427C257.367 227.823 257.662 228.163 258.027 228.411V233.987H260.909V228.411C261.254 228.176 261.537 227.86 261.732 227.491C261.928 227.121 262.03 226.71 262.03 226.292Z"
         fill="var(--brand-color-primary-dark)"
      />
      <path
         d="M435.497 320.795H243.793C243.66 320.795 243.532 320.742 243.437 320.648C243.343 320.553 243.29 320.425 243.29 320.291C243.29 320.158 243.343 320.03 243.437 319.935C243.532 319.841 243.66 319.788 243.793 319.788H435.497C435.63 319.788 435.758 319.841 435.853 319.935C435.947 320.03 436 320.158 436 320.291C436 320.425 435.947 320.553 435.853 320.648C435.758 320.742 435.63 320.795 435.497 320.795Z"
         fill="#CBCBCB"
      />
   </svg>
);
export default GuestNotAllowedInvoicesIllustration;
