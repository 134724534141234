'use client';

import { FC } from 'react';

import { ContentfulMarketSegment } from '@ravago/shared/page-data/models/contentful';
import { DynamicPageUrl } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { List } from '@ravago/shared/radiance/components/list/components/List/List';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../providers/translation-client-context-provider';
import { ConsumerWrapper } from '../../content/consumer-wrapper/consumer-wrapper';
import { DynamicPageLink } from '../../content/dynamic-page-link/dynamic-page-link';
import { MockLink } from '../../content/mock-link/mock-link';

interface Props {
   isConsumer: boolean;
   hideButton: boolean;
   marketSegment: ContentfulMarketSegment;
   language: string;
   productsOverviewBaseRoute?: DynamicPageUrl;
}

export const MarketSegmentContent: FC<Props> = ({ isConsumer, hideButton, marketSegment, language, productsOverviewBaseRoute }) => {
   const translate = useTranslations();
   const marketsFilter = encodeURIComponent(`${marketSegment.marketName}---${marketSegment.name}`);

   return (
      <Box direction="column" items="baseline" gap="sm">
         <Typography color="primary" variant="heading-3" component="h3">
            {marketSegment.name}
         </Typography>

         {marketSegment.description}

         <Typography color="primary" variant="body-highlight" component="h4">
            {translate('segment-slider-applications')}
         </Typography>
         <List type="disc">
            {marketSegment.applications.map((application) => (
               <Typography component="li" key={application}>
                  {application}
               </Typography>
            ))}
         </List>
         {!hideButton && (
            <ConsumerWrapper
               key={marketSegment.sys.id}
               isConsumer={isConsumer}
               renderConsumer={(children) => (
                  <DynamicPageLink
                     id="dynamic-link__market-segment__product-overview"
                     underline="none"
                     slug={`?markets=${marketsFilter}`}
                     baseRoute={productsOverviewBaseRoute}
                     locale={language}
                  >
                     {children}
                  </DynamicPageLink>
               )}
               renderProducer={(children) => <MockLink underline="none">{children}</MockLink>}
            >
               <Button trailingIcon="long-arrow-right" variant="secondary">
                  {translate('segment-slider-button', { segment: marketSegment.name })}
               </Button>
            </ConsumerWrapper>
         )}
      </Box>
   );
};
