import { HorizontalAlignment, VerticalAlignment } from '@ravago/shared/page-data/models/utils';

export const getAlignment = (alignment: VerticalAlignment | HorizontalAlignment): 'start' | 'center' | 'end' => {
   switch (alignment) {
      case 'CENTER':
         return 'center';
      case 'RIGHT':
      case 'BOTTOM':
         return 'end';
      default:
         return 'start';
   }
};
