import { FC } from 'react';

interface Props {
   title: string;
   provider: 'youtube' | 'vimeo';
   videoId: string;
   loading?: 'lazy' | 'eager';
   autoplay?: boolean;
   controls?: boolean;
   loop?: boolean;
}

const getYoutubeEmbedSrc = (videoId: string) => `https://www.youtube.com/embed/${videoId}`;
const getVimeoEmbedSrc = (videoId: string) => `https://player.vimeo.com/video/${videoId}`;

export const Video: FC<Props> = ({ title, provider, videoId, loading, autoplay = false, controls = true, loop = false }) => {
   const embedSrc = provider === 'vimeo' ? getVimeoEmbedSrc(videoId) : getYoutubeEmbedSrc(videoId);
   const url = new URL(embedSrc);
   url.searchParams.set('autoplay', Number(autoplay).toString());
   url.searchParams.set('controls', Number(controls).toString());
   url.searchParams.set('loop', Number(loop).toString());

   if (autoplay) {
      if (provider === 'youtube') {
         url.searchParams.set('mute', '1');
      } else {
         url.searchParams.set('muted', '1');
      }
   }

   if (loop && provider === 'youtube') {
      url.searchParams.set('playlist', videoId);
   }

   return <iframe className="aspect-video w-full" data-testid="video" loading={loading} src={url.toString()} title={title} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />;
};
