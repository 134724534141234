'use client';

import { FC } from 'react';

import { ElementProps } from '@ravago/shared/page-data/models/base';
import { ContentfulProducerEntry } from '@ravago/shared/page-data/models/contentful';
import { ProducerHeaderNode } from '@ravago/shared/page-data/models/elements';

import { Entry } from 'contentful';

import { AssetUtils } from '../../../utils/asset/asset.utils';
import { AvatarNameHeader } from '../../shared/avatar-name-header/avatar-name-header';

export const ProducerHeaderElement: FC<ElementProps<ProducerHeaderNode>> = ({ node, globals }) => {
   const producer = globals?.contentEntity as Entry<ContentfulProducerEntry>;

   return (
      <AvatarNameHeader
         theme={node.theme === 'primary' ? 'primary' : 'inverse-strong'}
         avatar={
            producer?.fields?.logo?.fields?.file?.url
               ? AssetUtils.optimizeImageIfRequired(producer.fields.logo.fields.file.url, globals.acceptWebp)
               : undefined
         }
         name={producer?.fields?.name}
      />
   );
};
