import { FC, ReactNode } from 'react';

import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   theme?: 'light' | 'dark';
   separator?: string;
   children: ReactNode | ReactNode[];
}

export const Breadcrumbs: FC<Props> = ({ theme = 'dark', separator = 'chevron-right', children }) => (
   <div data-testid={`breadcrumbs--${theme}`} className="h-component-sizing-breadcrumbs-height gap-component-navigation-breadcrumbs-container-spacing-gap inline-flex items-center">
      {(Array.isArray(children) ? children : [children])?.map((child, index, arr) => {
         if (!child) return null;

         return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="gap-component-navigation-breadcrumbs-container-spacing-gap inline-flex items-center" key={index}>
               {child}
               {index < arr.length - 1 && (
                  <div className={theme === 'light' ? 'text-component-navigation-breadcrumbs-separator-color-text-default' : 'text-component-navigation-breadcrumbs-separator-color-text-on-scrim'}>
                     <Typography color="inherit" variant="small">
                        <Icon icon={separator} color="inherit" />
                     </Typography>
                  </div>
               )}
            </div>
         );
      })}
   </div>
);
