import { Transaction } from '@ravago/shared/page-data/models/elements';

import Decimal from 'decimal.js';

import { isOverDue } from './invoice-date.utils';

export const calculateTotalOpenAmount = (invoices: Transaction[]): number => {
   const totalOpenAmount = invoices.reduce((prev, next) => prev.plus(next.openAmount), new Decimal(0));
   return totalOpenAmount.toNumber();
};

export const calculateTotalPositiveDueAmount = (invoices: Transaction[]): number => {
   const totalDueAmount = invoices
      .filter((transaction) => transaction.openAmount > 0)
      .reduce((prev, next) => {
         const isInvoiceOverDue = isOverDue(next.dueDate);
         return isInvoiceOverDue ? prev.plus(next.openAmount) : prev;
      }, new Decimal(0));
   return totalDueAmount.toNumber();
};
