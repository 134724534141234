'use client';

import { FC, useState } from 'react';

import { ActionState } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';

import { EmailAlreadyUnsubscribed } from './email-already-unsubscribed';
import { EmailUnsubscribeOutputList } from './email-unsubscribe-output-list';

export interface MailInfo {
   label: string;
   description: string;
}

interface Props {
   isAlreadyUnsubscribed: boolean;
   onUnsubscribe: () => Promise<boolean>;
   impactedMails: MailInfo[];
   emailAddress: string;
}

export const EmailUnsubscribeWrapper: FC<Props> = ({ impactedMails, emailAddress, onUnsubscribe, isAlreadyUnsubscribed }) => {
   const translate = useTranslations();
   const [actionState, setActionState] = useState<ActionState>('idle');

   const unsubscribeFromTopics = () => {
      onUnsubscribe().then((response) => {
         if (response) {
            setActionState('success');
         } else {
            setActionState('failed');
         }
      });
   };

   if (isAlreadyUnsubscribed || actionState === 'success') {
      return (
         <EmailAlreadyUnsubscribed
            isSuccess={actionState === 'success'}
            emailAddress={emailAddress}
            impactedMails={impactedMails}
            data-testid="email-unsubscribe-already-unsubscribed"
         />
      );
   }

   return (
      <Box direction="column" gap="md" items="center">
         <Typography component="h2" variant="heading-2" data-testid="email-unsubscribe-title">
            {translate('preference-center.email-unsubscribe.title-unsubscribe')}
         </Typography>
         <Typography component="p" variant="body" data-testid="email-unsubscribe-email">
            {translate('preference-center.email-unsubscribe.unsubscribe-email', { email: emailAddress })}
         </Typography>
         <Typography component="p" variant="body">
            <EmailUnsubscribeOutputList list={impactedMails} data-testid="email-unsubscribe-output-list" />
         </Typography>
         <Typography component="p" variant="body" data-testid="email-unsubscribe-warning">
            {translate('preference-center.email-unsubscribe.unsubscribe-warning')}
         </Typography>
         {actionState === 'failed' ? (
            <Typography component="p" variant="body" data-testid="email-unsubscribe-failed">
               {translate('preference-center.email-unsubscribe.unsubscribed-failed')}
            </Typography>
         ) : null}
         <Button data-testid="email-unsubscribe-button" onClick={unsubscribeFromTopics}>
            {translate('preference-center.email-unsubscribe.unsubscribe')}
         </Button>
      </Box>
   );
};
