'use client';

import { FC, useEffect } from 'react';

import { ElementProps } from '@ravago/shared/page-data/models/base';
import { FormEmbedNode } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';

export const FormEmbedElement: FC<ElementProps<FormEmbedNode>> = ({ node, globals }) => {
   const formLoaderId = 'dynamics-form-loader';
   const formLoaderScript = node.formLoaderUrl || 'https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/eur/FormLoader/FormLoader.bundle.js';

   const isValid = node.formId && node.formApiUrl && node.cachedFormUrl;

   useEffect(() => {
      const formLoaderExists = document.getElementById(formLoaderId);

      if (!formLoaderExists && isValid) {
         const script = document.createElement('script');

         script.id = formLoaderId;
         script.src = formLoaderScript;
         script.async = true;

         document.body.appendChild(script);
      }
   }, [node.formId, node.formApiUrl, node.cachedFormUrl, node.readableEventId, node.formLoaderUrl, isValid, formLoaderScript]);

   return (
      <Box width="100%" direction="column" items="center">
         {!isValid && !globals.isConsumer && (
            <p>
               One or more required properties are missing. Please make sure a value was provided for: <b>Form ID</b>, <b>Form api url</b> and{' '}
               <b>Cached form url</b>
            </p>
         )}

         {isValid && (
            <div
               data-form-id={node.formId}
               data-form-api-url={node.formApiUrl}
               data-cached-form-url={node.cachedFormUrl}
               data-readable-event-id={node.readableEventId}
            />
         )}
      </Box>
   );
};
