'use client';

import React, { useMemo, useState } from 'react';

import { FacetFilterWithTerms, FacetFilterTracking, facetLabelTranslationMapping } from '@ravago/shared/page-data/models/elastic';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BreakpointSwitch } from '@ravago/shared/radiance/components/breakpoint-switch/components/BreakpointSwitch/BreakpointSwitch';
import { Expand } from '@ravago/shared/radiance/components/expand/components/Expand/Expand';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { InputField } from '@ravago/shared/radiance/components/input-field/components/InputField/InputField';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../../../providers/translation-client-context-provider';
import { FacetGroup } from '../../../models/facet-group.model';
import { getActiveFacetLabel } from '../../../utils/filter-label-utils';

import { BaseFilterGroup } from './base-filter-group';

interface Props {
   language: string;
   expanded: boolean;
   facetFilter?: FacetFilterWithTerms;
   facetGroup: FacetGroup;
   onFilterChange: (value: FacetFilterWithTerms) => void;
   onFilterRemove: () => void;
   onFilterClicked: (facetFilter: FacetFilterTracking) => void;
   onExpandToggle: (expanded: boolean) => void;
}

export const FilterGroup: React.FC<Props> = ({
   language,
   expanded,
   facetFilter,
   facetGroup,
   onFilterChange,
   onFilterClicked,
   onFilterRemove,
   onExpandToggle
}) => {
   const translate = useTranslations();
   const [searchValue, setSearchValue] = useState('');

   const activeFacetLabel = useMemo(() => getActiveFacetLabel(facetFilter, facetGroup), [facetFilter, facetGroup]);

   if (!facetGroup.facets.length) return null;

   return (
      <Box direction="column" gap="sm">
         <Expand
            expanded={expanded}
            onExpandToggle={onExpandToggle}
            header={
               <Box justify="between" items="baseline" spacing={{ top: 'sm', bottom: 'sm' }}>
                  <Box direction="column" gap="none">
                     <Typography variant="body" color="strong">
                        {translate(facetLabelTranslationMapping.get(facetGroup.identifier.key) ?? facetGroup.identifier.key)}
                     </Typography>
                     {activeFacetLabel && (
                        <BreakpointSwitch excludeViews={['desktop']}>
                           <Typography variant="small" color="subtle" maxLines="1">
                              {activeFacetLabel}
                           </Typography>
                        </BreakpointSwitch>
                     )}
                  </Box>

                  <Icon color="subtle" icon={expanded ? 'chevron-up' : 'chevron-down'} />
               </Box>
            }
         >
            <Box direction="column" spacing={{ top: 'xs', bottom: 'xs' }}>
               <InputField
                  id={`${facetGroup.identifier.key}-search`}
                  label="Search"
                  trailingIcon="search"
                  onChange={setSearchValue}
                  value={searchValue}
                  clearable
               />
            </Box>

            <BaseFilterGroup
               language={language}
               searchValue={searchValue}
               facetFilter={facetFilter}
               facetGroup={facetGroup}
               onFilterChange={onFilterChange}
               onFilterRemove={onFilterRemove}
               onFilterClicked={onFilterClicked}
            />
         </Expand>
      </Box>
   );
};
