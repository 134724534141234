import { MouseEvent } from 'react';

import { Badge } from '../../../badge/components/Badge/Badge';
import { Box } from '../../../box/components/Box/Box';
import { BoxItem } from '../../../box/components/BoxItem/BoxItem';
import { ColorIcon } from '../../../icon/components/ColorIcon/ColorIcon';
import { ListItem } from '../../../list/components/ListItem/ListItem';
import { Typography } from '../../../typography/components/Typography/Typography';

import { ColorPickerOption } from './ColorPicker';

interface Props {
   option: ColorPickerOption;
   focused: boolean;
   searchValue: string | undefined;
   onClick: (event: MouseEvent<HTMLElement>) => void;
}

export const ColorPickerListItem = ({ option, focused, searchValue, onClick }: Props) => (
   <ListItem role="option" focused={focused} ariaSelected={focused} key={option.id} onClick={onClick}>
      <Box>
         <BoxItem grow={1}>
            <Box items="center" gap="sm">
               <ColorIcon colorCode={option.code} />
               <Typography whiteSpace="nowrap">
                  <div dangerouslySetInnerHTML={{ __html: option.label.replace(new RegExp((searchValue ?? '').split('').join('[^a-zA-Z0-9]*'), 'gi'), (match) => `<b>${match}</b>`) }} />
               </Typography>
            </Box>
         </BoxItem>
         <Box gap="sm">
            {option.badges?.map((badge) => (
               <Badge key={badge.label} variant={badge.variant}>
                  {badge.label}
               </Badge>
            ))}
         </Box>
      </Box>
   </ListItem>
);
