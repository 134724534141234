import { FC } from 'react';

import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { ToggleChip } from '@ravago/shared/radiance/components/toggle-chip/components/ToggleChip/ToggleChip';

import { useTranslations } from '../../../../../providers/translation-client-context-provider';
import { useInvoiceDispatchContext, useInvoiceStateContext } from '../../context/invoice-context';
import { BillingType } from '../../models/billing-type.model';
import { Toggle } from '../../models/feature-permissions.model';
import { InvoiceAction } from '../../state/invoice.actions';

type Props = {
   isOpenSelected: boolean;
};

export const InvoiceChipList: FC<Props> = ({ isOpenSelected }) => {
   const translate = useTranslations();
   const dispatch = useInvoiceDispatchContext();
   const { permissions } = useInvoiceStateContext();

   const handleClick = (selectionType: BillingType) => {
      dispatch({ type: InvoiceAction.SET_BILLING_TYPE, payload: selectionType });
   };

   return (
      <Box>
         <ToggleChip label={translate('invoices.invoice-chip-list.open')} toggled={isOpenSelected} onClick={() => handleClick(BillingType.OPEN)} />
         {permissions?.[Toggle.CLOSED_INVOICES] && (
            <ToggleChip
               label={translate('invoices.invoice-chip-list.closed')}
               toggled={!isOpenSelected}
               onClick={() => handleClick(BillingType.CLOSED)}
            />
         )}
      </Box>
   );
};
