'use client';

import { FC, PropsWithChildren } from 'react';

import { UserGroups } from '@ravago/shared/authentication';
import { AccessLevel } from '@ravago/shared/page-data/enums/access-level';
import { ContentfulCaseStudy, ContentfulCollectionName } from '@ravago/shared/page-data/models/contentful';

import { Entry } from 'contentful';

import AuthGuardService from '../../../services/auth-guard/auth-guard.service';

// TODO: move this to server https://www.freecodecamp.org/news/secure-routes-in-next-js/
interface Props extends PropsWithChildren {
   accessLevel: AccessLevel;
   isConsumer: boolean;
   language?: string;
   contentEntity?: Entry<unknown>;
   contentType?: ContentfulCollectionName;
   redirectUserToLogin: boolean;
}

const AuthGuard: FC<Props> = ({ accessLevel, contentEntity, language, isConsumer, contentType, children, redirectUserToLogin }) => {
   if (!isConsumer) return children;

   switch (accessLevel) {
      case 'Authenticated Only': {
         let allowedRoles;
         if (contentType === 'caseStudy') {
            if ((contentEntity?.fields as ContentfulCaseStudy)?.visibility !== 'External') {
               allowedRoles = [UserGroups.ravagoGlobalGroupAdmin, UserGroups.ravagoSalesRepresentative, UserGroups.ravagoGlobalViewer];
            }
         }
         return AuthGuardService.isAuth(children, redirectUserToLogin, language, allowedRoles);
      }
      case 'Verified Only': {
         return AuthGuardService.isAuth(children, redirectUserToLogin, language, [
            UserGroups.customerPortal,
            UserGroups.ravagoGlobalGroupAdmin,
            UserGroups.ravagoSalesRepresentative,
            UserGroups.ravagoGlobalViewer
         ]);
      }
      case 'CSR Only': {
         return AuthGuardService.isAuth(children, redirectUserToLogin, language, [
            UserGroups.ravagoGlobalGroupAdmin,
            UserGroups.ravagoSalesRepresentative,
            UserGroups.ravagoGlobalViewer
         ]);
      }
      default:
         return children;
   }
};

export default AuthGuard;
