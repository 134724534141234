import { FC } from 'react';

import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import { useTranslations } from '../../../../../providers/translation-client-context-provider';
import { Link } from '../../../../shared/link/link';

interface Props {
   language: string;
   ulProspectorId: string | undefined;
}

export const RelatedDocumentUlProspectorLink: FC<Props> = ({ language, ulProspectorId }) => {
   const translate = useTranslations();
   const { phone } = useBreakpoint();

   return (
      <Box direction="row" gap="xs" width="100%" justify={phone ? 'center' : 'end'} items="center">
         <Link href={`https://materials.ulprospector.com/en/document?e=${ulProspectorId}`} target="_blank" underline="none">
            <Icon size="small" icon="external-link" />
         </Link>
         <Link href={`https://materials.ulprospector.com/en/document?e=${ulProspectorId}`} target="_blank">
            <Typography component="p" variant="small" color="inherit">
               {translate('related-documents.ul-prospector')}
            </Typography>
         </Link>
      </Box>
   );
};
