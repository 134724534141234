/* eslint-disable react/no-array-index-key */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useMemo } from 'react';

import { DeliveryNoteResponse, DocumentOrderLineData, OrderDocumentType } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Paper } from '@ravago/shared/radiance/components/paper/components/Paper/Paper';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';

import { DeliveryNoteContent } from './delivery-note-content';

interface Props {
   deliveryNoteDocs: Array<DeliveryNoteResponse>;
   onRequestDeliveryNote: (deliveryNote: DeliveryNoteResponse) => void;
   onDownload: (document: DeliveryNoteResponse, orderLineData: DocumentOrderLineData, type: OrderDocumentType) => void;
}

const DeliveryDocuments: FC<Props> = ({ deliveryNoteDocs, onDownload, onRequestDeliveryNote }) => {
   const translate = useTranslations();

   const [downloadableDocuments, requestableDocuments] = useMemo(
      () => [deliveryNoteDocs.filter((doc) => doc.id), deliveryNoteDocs.filter((doc) => !doc.id)],
      [deliveryNoteDocs]
   );

   const showDivider = downloadableDocuments.length > 0 && requestableDocuments.length > 0;

   const renderDocuments = (documents: Array<DeliveryNoteResponse>, showDownloadBtn: boolean) =>
      documents.map((deliveryNoteDoc) => (
         <Paper direction="row" key={deliveryNoteDoc.id} width="100%">
            <Box justify="between" items="start" width="100%" direction="row">
               <DeliveryNoteContent
                  data={deliveryNoteDoc}
                  onDownload={onDownload}
                  showDownloadBtn={showDownloadBtn}
                  onRequestDeliveryNote={onRequestDeliveryNote}
               />
            </Box>
         </Paper>
      ));

   return (
      <Box direction="column" gap="sm">
         {deliveryNoteDocs.length > 0 ? (
            <>
               {renderDocuments(downloadableDocuments, true)}
               {showDivider && <Divider />}
               {renderDocuments(requestableDocuments, false)}
            </>
         ) : (
            <Typography variant="body" component="span">
               {translate('shipment.documents.no-documents-available')}
            </Typography>
         )}
      </Box>
   );
};

export default DeliveryDocuments;
