import { FC, ReactNode } from 'react';

interface Props {
   order?: number;
   grow?: number;
   shrink?: number;
   basis?: string | number;
   children: ReactNode | Array<ReactNode>;
}

export const BoxItem: FC<Props> = ({ order = 0, grow = 0, shrink = 1, basis, children }) => (
   <div
      data-testid="box-item"
      style={{
         order,
         flexGrow: grow,
         flexShrink: shrink,
         flexBasis: basis
      }}
   >
      {children}
   </div>
);
