import { FC } from 'react';

import { Transaction } from '@ravago/shared/page-data/models/elements';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { TableCell } from '@ravago/shared/radiance/components/table/components/TableCell/TableCell';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';
import { Tooltip } from '@ravago/shared/radiance/components/tooltip/components/Tooltip/Tooltip';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../../../../../providers/translation-client-context-provider';
import { useInvoiceDispatchContext, useInvoiceStateContext } from '../../../../../context/invoice-context';
import { InvoiceAction } from '../../../../../state/invoice.actions';
import { InvoiceDueDate } from '../shared/invoice-due-date';

type Props = {
   invoice: Transaction;
   locale: string;
};

const SHOW_TOOLTIP_LENGTH = 18;

export const OpenInvoiceTableRow: FC<Props> = ({ invoice, locale }) => {
   const { documentToDownload } = useInvoiceStateContext();
   const dispatch = useInvoiceDispatchContext();
   const translate = useTranslations();

   const isPartiallyPaid = invoice.amount !== invoice.openAmount && invoice.openAmount > 0;
   const isDownloading = documentToDownload?.invoiceId === invoice.id;

   const handleDownload = async () => {
      if (invoice.documentId) dispatch({ type: InvoiceAction.DOWNLOAD_DOCUMENT, payload: { invoiceId: invoice.id, documentId: invoice.documentId } });
   };

   const showTooltipOnInvoiceId = invoice.eInvoiceId?.length || SHOW_TOOLTIP_LENGTH < 0;

   return (
      <TableRow>
         <TableCell width="200px">
            <Box direction="column" gap="3xs">
               {showTooltipOnInvoiceId && (
                  <Tooltip text={invoice.eInvoiceId || invoice.id} limitWidth={false}>
                     <span style={{ display: 'block', textOverflow: 'ellipsis', overflow: 'hidden' }}>{invoice.eInvoiceId || invoice.id}</span>
                  </Tooltip>
               )}
               {!showTooltipOnInvoiceId && (
                  <span style={{ display: 'block', textOverflow: 'ellipsis', overflow: 'hidden' }}>{invoice.eInvoiceId || invoice.id}</span>
               )}
               <Typography component="span" variant="small" color="subtle">
                  {translate(`invoices.invoice-type.${invoice.type}`)}
               </Typography>
            </Box>
         </TableCell>
         <TableCell width="130px">{invoice.issueDate}</TableCell>
         <TableCell width="230px">
            <InvoiceDueDate dueDate={invoice.dueDate} invoiceType={invoice.type} />
         </TableCell>
         <TableCell width="180px">
            <Box direction="column" gap="3xs" items="end">
               {invoice.openAmount?.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
               {isPartiallyPaid && (
                  <Typography variant="caption">
                     {translate('invoices.open-invoices.table.invoice-amount', { amount: invoice.amount.toFixed(2) })}
                  </Typography>
               )}
            </Box>
         </TableCell>
         <TableCell align="right" width="240px">
            <Box justify="end">{isPartiallyPaid && <Badge variant="warning">{translate('invoices.warning.partially-paid')}</Badge>}</Box>
         </TableCell>
         <TableCell align="right" width="40px">
            {invoice.documentId && (
               <Box justify="end">
                  {isDownloading ? (
                     <Spinner size="small" />
                  ) : (
                     <IconButton icon="download" onClick={handleDownload} showGutters={false} variant="on-light" />
                  )}
               </Box>
            )}
         </TableCell>
      </TableRow>
   );
};
