import { TextNode } from '@ravago/shared/page-data/models/elements';

export interface Link {
   href: string;
   target: '_blank' | '_self';
   title: string;
}

const HEADER_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5'];
const BODY_TAGS = ['p'];

const HEADER_CLASSES = 'typography-container-heading';
const BODY_CLASSES = 'typography-container';

const VARIANT_CLASSES: Record<string, string> = {
   h1: 'phone:typography-component-data-display-typography-mobile-h1 tab-port:typography-component-data-display-typography-tablet-h1 tab-land:typography-component-data-display-typography-tablet-h1 typography-component-data-display-typography-desktop-h1',
   h2: 'phone:typography-component-data-display-typography-mobile-h2 tab-port:typography-component-data-display-typography-tablet-h2 tab-land:typography-component-data-display-typography-tablet-h2 typography-component-data-display-typography-desktop-h2',
   h3: 'phone:typography-component-data-display-typography-mobile-h3 tab-port:typography-component-data-display-typography-tablet-h3 tab-land:typography-component-data-display-typography-tablet-h3 typography-component-data-display-typography-desktop-h3',
   h4: 'phone:typography-component-data-display-typography-mobile-h4 tab-port:typography-component-data-display-typography-tablet-h4 tab-land:typography-component-data-display-typography-tablet-h4 typography-component-data-display-typography-desktop-h4',
   p: 'phone:typography-component-data-display-typography-mobile-body-regular tab-port:typography-component-data-display-typography-tablet-body-regular tab-land:typography-component-data-display-typography-tablet-body-regular typography-component-data-display-typography-desktop-body-regular'
};

const COLOR_VARIABLES: Record<string, string> = {
   h1: 'component-data-display-typography-label-color-text-primary',
   h2: 'component-data-display-typography-label-color-text-primary',
   h3: 'component-data-display-typography-label-color-text-primary',
   h4: 'component-data-display-typography-label-color-text-primary',
   headline: 'component-data-display-typography-label-color-text-default-strong'
};

export class TextUtils {
   /**
    * Adds classes to the html string and replaces the tag with a new tag if provided
    *
    * @param value the html string to replace the tag in
    * @param tag the tag to replace
    * @param replaceTag the tag to replace the html tag with
    *
    * @returns the html string with the tag replaced
    */
   private static replaceHtml(value: string, tag: string, replaceTag?: string) {
      const isHeaderTag = HEADER_TAGS.includes(tag);
      const isNewTag = replaceTag !== tag;

      const variantClass = isNewTag ? VARIANT_CLASSES[tag] || '' : '';
      const colorVariable = isNewTag ? COLOR_VARIABLES[tag] || '' : '';

      const classString = `${isHeaderTag ? HEADER_CLASSES : BODY_CLASSES} ${variantClass}`;
      const styleString = colorVariable ? `style="color: var(--${colorVariable})"` : '';

      return value
         .replaceAll(`<${tag}>`, `<${replaceTag || tag} ${styleString} class="${classString}">`)
         .replaceAll(`<${tag} class="`, `<${replaceTag || tag} ${styleString} class="${classString}`);
   }

   /**
    * Adds classes to the html string and replaces the tag with a new tag if provided
    *
    * @param html the html string to add classes to
    * @param newTag the tag to replace the html tag with
    *
    * @returns the html string with classes added
    */
   static addClasses(html: string, newTag?: string) {
      return [...HEADER_TAGS, ...BODY_TAGS].reduce((acc, tag) => this.replaceHtml(acc, tag, newTag), html);
   }

   /**
    * Gets the text in html format to render for the TextNode
    *
    * @param node the TextNode to get the text from
    * @param isConsumer whether the text is for the consumer or not
    * @param link the link to add to the text
    *
    * @returns the text in html format
    */
   static getRenderText(node: TextNode, isConsumer: boolean, link?: Link): string {
      if (!node.html.trim().replace(/<[^>]*>?/gm, '').length && !isConsumer) return '<p>Lorem ipsum dolor sit...</p>';

      const htmlWithClasses = this.addClasses(node.html, node.component);

      if (!link?.href) return htmlWithClasses;

      return `<a href="${link.href}" target="${link.target}" title="${link.title}">${htmlWithClasses}</a>`;
   }
}
