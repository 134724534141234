'use client';

import { FC, useEffect, useState } from 'react';

import { useAuthenticationContext, UserGroups } from '@ravago/shared/authentication';
import { ContentfulProduct } from '@ravago/shared/page-data/models/contentful';
import { RequestMissingDocumentsButtonNode } from '@ravago/shared/page-data/models/elements';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';
import notification from '@ravago/shared/radiance/utils/notification/notification';

import { firstBy } from 'thenby';

import { useDialogContext } from '../../../../hooks/use-dialog-context';
import { useDialogFormContext } from '../../../../hooks/use-dialog-form-context';
import { useTranslations } from '../../../../providers/translation-client-context-provider';

interface Props {
   node: RequestMissingDocumentsButtonNode;
   url?: string;
   globals: RendererGlobals;
   productId: string;
   productName: string;
}

export const RequestMissingDocumentsButton: FC<Props> = ({ node, productName, productId, url, globals }) => {
   const translate = useTranslations();
   const {
      actions: { getDocumentCategories, sendMissingDocumentForm }
   } = globals;
   const [TDurl, setTDurl] = useState(url);
   const [categories, setCategories] = useState<DropdownOption[]>();
   const { user, token } = useAuthenticationContext();
   const { dispatch } = useDialogContext();
   const { customerFeedback } = useDialogFormContext();

   const verifiedUser =
      user && !(user.groups.includes(UserGroups.digitalPlatformGuest) || user.groups.includes(UserGroups.digitalPlatformPendingVerification));
   const isTailCatalogProduct = globals.contentType === 'product' && (globals.contentEntity?.fields as ContentfulProduct)?.isTailCatalog;

   const handleRequestMissingDocuments = () => {
      if (user && (user.groups.includes(UserGroups.ravagoSalesRepresentative) || user.groups.includes(UserGroups.ravagoGlobalGroupAdmin))) {
         window.open(TDurl, '_blank');
      } else if (verifiedUser) {
         if (dispatch) {
            dispatch({
               type: 'open_dialog',
               value: {
                  dialogType: 'request-documents-form',
                  title: productName,
                  requestDocumentFormCategories: categories
               }
            });
         }
      }
   };

   useEffect(() => {
      if (customerFeedback.category?.id && customerFeedback.feedback && token) {
         sendMissingDocumentForm(
            productId,
            token,
            customerFeedback.feedback ?? '',
            parseInt(customerFeedback.category.id, 10),
            false,
            isTailCatalogProduct
               ? `${(globals.contentEntity?.fields as ContentfulProduct)?.name} (UL id ${
                    (globals.contentEntity?.fields as ContentfulProduct)?.ulProductId
                 })`
               : undefined
         ).then((post) => {
            if (post) {
               notification(translate('request-missing-documents-form.sent').toString(), { duration: 5000 });
            }
         });
      }
   }, [customerFeedback, token]);

   useEffect(() => {
      if (user?.sub) setTDurl(`${url}${user.sub}`);
   }, [url, user]);

   useEffect(() => {
      if (token) {
         getDocumentCategories(productId, token, false).then((docuCategories) => {
            setCategories(
               docuCategories
                  .map((category) => ({ id: category.subcategoryId, value: `${category.categoryName} - ${category.subcategoryName}` }))
                  .sort(firstBy((category) => category.value))
            );
         });
      }
   }, [token]);

   return (
      <Button
         data-testid="missing-documents-button"
         trailingIcon={node.trailingIcon}
         leadingIcon={node.leadingIcon}
         disabled={globals.isConsumer && (!url || !categories || !verifiedUser)}
         variant={node.variant}
         badge={node.badge}
         onClick={() => (globals.isConsumer ? handleRequestMissingDocuments() : undefined)}
      >
         {node.label}
      </Button>
   );
};
