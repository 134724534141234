'use client';

import { FC } from 'react';

import { Box } from '../../../box/components/Box/Box';
import { Image } from '../../../image/components/Image/Image';
import { Link } from '../../../link/components/Link/Link';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   href: string;
   target: '_blank' | '_self';
   noFollow?: boolean;
   logo: string;
   name: string;
}

export const ProducerCard: FC<Props> = ({ href, target = '_self', noFollow, logo, name }) => (
   <Link href={href} target={target} noFollow={noFollow} underline="none">
      <Box direction="column" gap="md" spacing="lg" rounded="lg" shadow background="#fff">
         <Box justify="center">
            <Box width="250px" height="250px" items="center" justify="center">
               <Image src={logo} alt={name} title={name} />
            </Box>
         </Box>
         <Typography variant="body" color="subtle">
            {name}
         </Typography>
      </Box>
   </Link>
);
