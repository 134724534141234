import { FacetFilterWithTerms } from '@ravago/shared/page-data/models/elastic';

import { FacetGroup } from '../models/facet-group.model';

export const getActiveFacetLabel = (facetFilter: FacetFilterWithTerms | undefined, facetGroup: FacetGroup | undefined): string | undefined => {
   if (!facetFilter) return undefined;
   return facetFilter.terms
      .map((t) => {
         const facetGroupContent = facetGroup?.facets?.find((f) => f.name === t.term);

         if (!facetGroupContent) return undefined;

         let { label } = facetGroupContent;

         if (t?.range?.min && t?.range?.max) {
            label = `${label} ${t.range.min} - ${t.range.max}`;
         }

         if (t.nestedFacets) {
            const nestedLabels = t.nestedFacets
               .map((n) => {
                  const nestedFacetGroup = facetGroupContent.nestedFacets?.find((nestedFacet) => nestedFacet.identifier.key === n.facet);
                  return getActiveFacetLabel(n, nestedFacetGroup);
               })
               .filter((value) => !!value)
               .join(', ');
            label = `${label} (${nestedLabels})`;
         }

         return label;
      })
      .filter((label) => !!label)
      .join(', ');
};
