const removeDiacritics = (value: string): string => value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const highlight = (value: string, highlightedTerms: string, startMarker: string, endMarker: string): string => {
   const trimmedText = removeDiacritics(value.trim());
   const trimmedHighlightedTerms = highlightedTerms.trim();
   if (!trimmedText || !trimmedHighlightedTerms || (!startMarker && !endMarker)) {
      return value;
   }
   const escapedTerms = trimmedHighlightedTerms.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
   const sortedHighlightedTerms = removeDiacritics(escapedTerms)
      .split(' ')
      .sort((a, b) => b.length - a.length);
   const highlightRegex = new RegExp(`(${sortedHighlightedTerms.join('|')})`, 'gi');
   return removeDiacritics(value)
      .split(highlightRegex)
      .reduce<Array<{ index: number; diacriticslessPart: string; correspondingOriginalPart: string }>>((acc, diacriticslessPart) => {
         const previousItem = acc[acc.length - 1];
         const previousSplitIndex = !previousItem ? 0 : previousItem.index + previousItem.diacriticslessPart.length;
         const nextSplitIndex = previousSplitIndex + diacriticslessPart.length;
         const correspondingOriginalPart = value.substring(previousSplitIndex, nextSplitIndex);
         acc.push({ index: previousSplitIndex, diacriticslessPart, correspondingOriginalPart });
         return acc;
      }, [])
      .map(({ diacriticslessPart, correspondingOriginalPart }) =>
         highlightRegex.test(diacriticslessPart) ? startMarker + correspondingOriginalPart + endMarker : correspondingOriginalPart
      )
      .join('');
};

export default {
   removeDiacritics,
   highlight
};
