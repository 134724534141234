'use client';

import { FC, useState } from 'react';

import { MappedOrders, OrderLineOpenedFrom } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Expand } from '@ravago/shared/radiance/components/expand/components/Expand/Expand';

import OrderExpandHeader from '../../content/order-details/components/order-expand-header';
import { OrderLinesTable } from '../order-lines-table/order-lines-table';

interface Props {
   locale: string;
   isConsumer: boolean;
   acceptWebp?: boolean;
   orderDetail: MappedOrders;
   shipmentBaseUrl: string;
   openedFrom: OrderLineOpenedFrom;
   orderDetailBaseUrl?: string;
   callOffAgreementBaseUrl?: string;
}

export const OrderDetailsOrderLines: FC<Props> = ({
   locale,
   isConsumer,
   acceptWebp,
   orderDetail,
   shipmentBaseUrl,
   orderDetailBaseUrl,
   callOffAgreementBaseUrl,
   openedFrom
}) => {
   const [isOpenOrderLineExpanded, setIsOpenOrderLineExpanded] = useState(true);
   const [isCloseOrderLineExpanded, setIsCloseOrderLineExpanded] = useState(true);

   return (
      <Box direction="column" gap="lg" width="100%">
         {orderDetail.openOrderLines.length > 0 && (
            <Expand
               expanded={isOpenOrderLineExpanded}
               header={
                  <OrderExpandHeader
                     title="Open"
                     icon="clock"
                     isExpanded={isOpenOrderLineExpanded}
                     amountOfOrders={orderDetail.openOrderLines.length}
                  />
               }
               onExpandToggle={() => setIsOpenOrderLineExpanded(!isOpenOrderLineExpanded)}
            >
               <OrderLinesTable
                  acceptWebp={acceptWebp}
                  orderLines={orderDetail.openOrderLines}
                  locale={locale}
                  isConsumer={isConsumer}
                  shipmentBaseUrl={shipmentBaseUrl}
                  orderDetailBaseUrl={orderDetailBaseUrl}
                  callOffAgreementBaseUrl={callOffAgreementBaseUrl}
                  openedFrom={openedFrom}
               />
            </Expand>
         )}

         {orderDetail.closedOrderLines.length > 0 && (
            <Expand
               expanded={isCloseOrderLineExpanded}
               header={<OrderExpandHeader title="Closed" icon="check" isExpanded={isCloseOrderLineExpanded} />}
               onExpandToggle={() => setIsCloseOrderLineExpanded(!isCloseOrderLineExpanded)}
            >
               <OrderLinesTable
                  acceptWebp={acceptWebp}
                  orderLines={orderDetail.closedOrderLines}
                  locale={locale}
                  isConsumer={isConsumer}
                  shipmentBaseUrl={shipmentBaseUrl}
                  orderDetailBaseUrl={orderDetailBaseUrl}
                  callOffAgreementBaseUrl={callOffAgreementBaseUrl}
                  openedFrom={openedFrom}
               />
            </Expand>
         )}
      </Box>
   );
};
