'use client';

import { FC, useEffect, useMemo, useState } from 'react';

import { useAuthenticationContext, UserGroups } from '@ravago/shared/authentication';
import { FeatureToggle, UOM } from '@ravago/shared/page-data/models/config';
import { OrderedProduct, Quotation, RelatedDocument } from '@ravago/shared/page-data/models/elements';
import { CustomerDataPage } from '@ravago/shared/page-data/models/pages';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Dropdown, DropdownOption } from '@ravago/shared/radiance/components/input-field/components/Dropdown/Dropdown';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';
import notification from '@ravago/shared/radiance/utils/notification/notification';

import { saveAs } from 'file-saver';
import { firstBy } from 'thenby';

import { useDialogContext } from '../../../../hooks/use-dialog-context';
import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { CorrelationService } from '../../../../services/tracking/correlation-token.service';
import { FeaturePermissionsUtils } from '../../../../utils/feature-permissions/feature-permissions.utils';
import { getLanguageCode, getLanguageNameFromLanguageCode } from '../../../../utils/locale/locale.utils';
import { TrackingUtils } from '../../../../utils/tracking/tracking.utils';
import { Link } from '../../../shared/link/link';
import { ShareAnchor } from '../../../shared/share-anchor/share-anchor';

interface Props {
   hasPublicTdsDownloadPermission: boolean;
   hasPublicShowTdsUomPermission: boolean;
   documentAnchor: string | undefined;
   actions: {
      downloadPublicTdsDocument: (documentLanguage: string, ulProductId: string, correlationId: string, documentUOM?: string) => Promise<string>;
      downloadDocument: (downloadEndpoint: string, accessToken: string, correlationId: string) => Promise<string | undefined>;
      getAllOrderedProducts: (accessToken: string) => Promise<CustomerDataPage<OrderedProduct>>;
      getAllQuotations: (accessToken: string) => Promise<Quotation[]>;
      getPrivateDocuments: (
         endpoint: string,
         extraId: string | undefined,
         hasInternalDocumentsPermission: boolean,
         isVerifiedUser: boolean,
         accessToken: string
      ) => Promise<RelatedDocument[]>;
      getPrivateToggles: (accessToken: string) => Promise<FeatureToggle[]>;
   };
   productId?: string;
   productName?: string;
   locale: string;
   uomCollection: Array<UOM> | undefined;
   ulProspectorId?: number;
   ulPortfolio?: boolean;
}

// These languages are hardcoded and remain the same over all channels as these are based on ul Prospector data
export const availableLanguageCodes = ['en', 'de', 'es', 'fr', 'ja', 'pt', 'ru', 'zh'];

export const QuickTdsLink: FC<Props> = ({
   hasPublicTdsDownloadPermission,
   hasPublicShowTdsUomPermission,
   ulPortfolio,
   documentAnchor,
   productId,
   uomCollection,
   productName,
   actions,
   locale,
   ulProspectorId
}) => {
   const translate = useTranslations();
   const language = getLanguageCode(locale);
   const { getPrivateDocuments, downloadPublicTdsDocument, downloadDocument, getPrivateToggles, getAllOrderedProducts, getAllQuotations } = actions;

   const [TDSdocuments, setTDSDocuments] = useState<Array<RelatedDocument> | undefined>();
   const [permissions, setPermissions] = useState<FeatureToggle[]>([]);
   const [permissionsHaveBeenSet, setPermissionsHaveBeenSet] = useState<boolean>(false);
   const [documentLanguage, setDocumentLanguage] = useState<DropdownOption>({ id: 'en', value: 'English' });
   const [documentUom, setDocumentUom] = useState<DropdownOption>(() => {
      const uomOption = uomCollection?.find((uom) => uom.default);
      return uomOption
         ? { ...uomOption, value: translate(uomOption.value) }
         : {
              id: 'metric',
              value: translate('unit-of-measure.metric')
           };
   });
   const { dispatch } = useDialogContext();
   const { user, token, isAuthenticated } = useAuthenticationContext();
   const { phone } = useBreakpoint();

   const hasShowTdsUomPermission = FeaturePermissionsUtils.hasPermission(permissions, 'product-documents-tds-uom');
   const hasSharePermission = FeaturePermissionsUtils.hasPermission(permissions, 'share-page');

   const isSalesRep = useMemo(() => !!user?.groups?.includes(UserGroups.ravagoSalesRepresentative), [user?.groups]);

   const availableUomOptions: UOM[] = useMemo(
      () => uomCollection?.sort(firstBy((l) => l, { ignoreCase: true }))?.map((uom) => ({ ...uom, value: translate(uom.value) })) ?? [],
      [uomCollection, locale]
   );

   const availableLanguagesOptions: DropdownOption[] = useMemo(
      () =>
         availableLanguageCodes
            .map(
               (key): DropdownOption => ({
                  id: key,
                  value: getLanguageNameFromLanguageCode(key, locale)
               })
            )
            .sort(firstBy((l) => l, { ignoreCase: true })),
      [locale]
   );

   const trackQuickTdsDownload = (authToken: string, document: { id: string; title: string }) => {
      Promise.all([getAllOrderedProducts(authToken), getAllQuotations(authToken)]).then(([products, quotations]) => {
         const productIds = products.content.filter((prod) => !!prod.baseProductId).map((product) => product.baseProductId!.toString());
         productIds.push(
            ...quotations.filter((quotation) => !!quotation.product.baseProductId).map((quotation) => quotation.product.baseProductId!.toString())
         );

         const trackingInfo = {
            product: {
               id: productId,
               name: productName
            },
            document,
            correlationId: CorrelationService.getCorrelationId('dpcid')
         };
         if (productIds.includes(productId ?? '')) {
            TrackingUtils.track('tds_download_previously_purchased', trackingInfo);
         } else {
            TrackingUtils.track('tds_download_new_lead', trackingInfo);
         }
      });
   };

   const handleLanguageChange = (lan: DropdownOption | undefined) => {
      if (lan) setDocumentLanguage(lan);
   };

   const handleUOMChange = (uom: DropdownOption | undefined) => {
      if (uom) setDocumentUom(uom);
   };

   const downloadTdsFromUl = async () => {
      if (!ulProspectorId) return;

      if (dispatch) {
         dispatch({
            type: 'open_dialog',
            value: { dialogType: 'document-is-downloading', title: translate('seller-widget.tds') }
         });
         const blob = await downloadPublicTdsDocument(
            documentLanguage.id,
            ulProspectorId.toString(),
            CorrelationService.generateCorrelationId('dpcid'),
            documentUom.id
         ).catch(() => {
            dispatch({
               type: 'close_dialog',
               value: { dialogType: 'document-is-downloading', title: translate('seller-widget.tds') }
            });
            notification(translate('shipment.documents.download-error'));
            return undefined;
         });
         if (isAuthenticated && token) {
            trackQuickTdsDownload(token, { id: ulProspectorId.toString(), title: 'TDS' });
         }
         if (blob) {
            saveAs(blob, 'Technical-Data-Sheet.pdf');
            dispatch({
               type: 'close_dialog',
               value: { dialogType: 'document-is-downloading', title: translate('seller-widget.tds') }
            });
         }
      }
   };

   const downloadTdsFromDms = async () => {
      if (dispatch && TDSdocuments && TDSdocuments.length > 0 && token) {
         dispatch({
            type: 'open_dialog',
            value: { dialogType: 'document-is-downloading', title: translate('seller-widget.tds') }
         });
         const blob = await downloadDocument(
            `products/${productId}/documents/${TDSdocuments[0].id}`,
            token,
            CorrelationService.generateCorrelationId('dpcid')
         );
         trackQuickTdsDownload(token, { id: TDSdocuments[0].id, title: TDSdocuments[0].description });
         if (blob) {
            saveAs(blob, TDSdocuments[0].description);
         }
         dispatch({
            type: 'close_dialog',
            value: { dialogType: 'document-is-downloading', title: translate('seller-widget.tds') }
         });
      }
   };

   const scrollToDocuments = () => {
      if (!documentAnchor) return;
      document.getElementById(documentAnchor)?.scrollIntoView({ behavior: 'smooth' });
   };

   const setCorrectTdsDocuments = (documents: RelatedDocument[]) => {
      const tdsDocumentsCurrentLanguage = documents.filter((tdsDocuments) => tdsDocuments.language.toLowerCase() === language);
      const tdsDocumentsEnglish = documents.filter((tdsDocuments) => tdsDocuments.language.toLowerCase() === 'en');

      if (tdsDocumentsCurrentLanguage.length > 0) {
         setTDSDocuments(tdsDocumentsCurrentLanguage);
      } else if (tdsDocumentsEnglish.length > 0) {
         setTDSDocuments(tdsDocumentsEnglish);
      } else {
         setTDSDocuments([]);
      }
   };

   useEffect(() => {
      if (!token) return;
      getPrivateToggles(token).then((toggles) => {
         setPermissions(toggles);
         setPermissionsHaveBeenSet(true);
      });
   }, [token]);

   useEffect(() => {
      const getItemsOnServer = async (): Promise<void> => {
         if (!permissionsHaveBeenSet) return;

         if (token) {
            const hasVerifiedUserAccessDownload = FeaturePermissionsUtils.hasPermission(permissions, 'product-documents-download-verified-user');
            const isUnverifiedUser =
               user?.groups?.includes(UserGroups.digitalPlatformGuest) || user?.groups?.includes(UserGroups.digitalPlatformPendingVerification);
            const hasInternalDocumentsPermission = FeaturePermissionsUtils.hasPermission(permissions, 'internal-documents');

            const fetchedRelatedDocuments: RelatedDocument[] = await getPrivateDocuments(
               `products/${productId}/documents`,
               undefined,
               hasInternalDocumentsPermission,
               hasVerifiedUserAccessDownload && !isUnverifiedUser,
               token
            );
            if (fetchedRelatedDocuments) {
               setCorrectTdsDocuments(fetchedRelatedDocuments.filter((document) => document.type === 'TDS'));
            }
         }
      };

      if (isAuthenticated) {
         getItemsOnServer();
      } else {
         setTDSDocuments([]);
      }
   }, [isAuthenticated, permissionsHaveBeenSet]);

   const dmsQuickLink = () =>
      TDSdocuments ? (
         <>
            {TDSdocuments.length === 1 && isAuthenticated && (
               <Box>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link onClick={() => downloadTdsFromDms()} variant="body">
                     {translate('products-technical-data-sheet')}
                     <Icon icon="download" type="default" size="body" color="primary" />
                  </Link>
               </Box>
            )}
            {TDSdocuments.length !== 1 && !(isSalesRep && ulProspectorId) && (
               <Box direction="column" items="end" gap="none">
                  {TDSdocuments.length > 1 && (
                     <Typography color="weak" variant="body">
                        {translate('product.documents.dms-multiple-docs')}
                     </Typography>
                  )}
                  {TDSdocuments.length === 0 && (
                     <Typography color="weak" variant="body">
                        {translate('product.documents.technical-data-sheet-not-available')}
                     </Typography>
                  )}
                  {documentAnchor && documentAnchor !== '' && (
                     // eslint-disable-next-line jsx-a11y/anchor-is-valid
                     <Link color="primary" onClick={() => scrollToDocuments()} variant="body">
                        {translate('product.go-to-all-documents')}
                        <Icon icon="long-arrow-down" type="default" size="body" color="primary" />
                     </Link>
                  )}
               </Box>
            )}
         </>
      ) : (
         <Spinner />
      );

   if (!isAuthenticated && !hasPublicTdsDownloadPermission) {
      return null;
   }

   return (
      <Box items={phone ? 'start' : 'end'} direction="column" gap="xs">
         {!!ulPortfolio && !!ulProspectorId ? (
            <Box gap="sm" items="center" width="100%">
               <Dropdown
                  value={documentLanguage}
                  emptyOption={false}
                  label={translate('products-technical-data-sheet')}
                  onChange={(lan) => handleLanguageChange(lan)}
                  id={`${productId}-language`}
                  options={availableLanguagesOptions}
               />
               {hasShowTdsUomPermission ||
                  (!isAuthenticated && hasPublicShowTdsUomPermission && (
                     <Dropdown
                        value={documentUom}
                        emptyOption={false}
                        id={`${productId}-uom`}
                        options={availableUomOptions}
                        label={translate('product.documents.uom')}
                        onChange={(uom) => handleUOMChange(uom)}
                     />
                  ))}
               <Button variant="secondary" onClick={() => downloadTdsFromUl()}>
                  <Icon icon="download" />
               </Button>
               {hasSharePermission && isAuthenticated && (
                  <ShareAnchor
                     squareButton
                     backdrop
                     variant="primary"
                     pageName={translate('seller-widget.tds')}
                     documentInfo={{
                        detailPageName: productName,
                        name: translate('seller-widget.tds'),
                        id: ulProspectorId.toString()
                     }}
                  />
               )}
            </Box>
         ) : (
            dmsQuickLink()
         )}
         {isSalesRep && !!ulProspectorId && (
            <Box gap="sm" items="center">
               <Icon color="primary" icon="external-link" />
               <Typography component="p" variant="body">
                  <Link color="primary" href={`https://materials.ulprospector.com/en/document?e=${ulProspectorId}`} target="_blank">
                     {translate('product.ul-prospector-link')}
                  </Link>
               </Typography>
            </Box>
         )}
      </Box>
   );
};
