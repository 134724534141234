// eslint-disable-next-line no-shadow
export enum Toggle {
   'MY_INVOICES' = 'my-invoices',
   'CLOSED_INVOICES' = 'my-invoices-closed',
   'FILTERS' = 'my-invoices-filters'
}

export type FeaturePermissions = {
   [key in Toggle]: boolean;
};
