import { FC } from 'react';

import { CallOffOrderDetail } from '@ravago/shared/page-data/models/elements';
import { Address } from '@ravago/shared/page-data/models/elements/address.model';
import { Customer } from '@ravago/shared/page-data/models/features';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Paper } from '@ravago/shared/radiance/components/paper/components/Paper/Paper';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';

import { CallOffOrderDeliveryRequest } from './call-off-order-delivery-request';

interface Props {
   callOffOrderDetail: CallOffOrderDetail;
   deliveryAddress: Address | undefined;
   customer?: Customer;
}

export const CallOffOrderDeliveryRequestPaper: FC<Props> = ({ callOffOrderDetail, deliveryAddress, customer }) => {
   const translate = useTranslations();

   return (
      <Paper width="100%" border={false} shadow>
         <Box direction="column" gap="lg" spacing={{ y: 'sm' }}>
            <Typography component="h4" variant="heading-4" color="strong">
               {translate('my-orders.request-delivery')}
            </Typography>
            <CallOffOrderDeliveryRequest callOffOrderDetail={callOffOrderDetail} deliveryAddress={deliveryAddress} customer={customer} />
         </Box>
      </Paper>
   );
};
