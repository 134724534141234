import { FC, useMemo } from 'react';

import {
   CombinedFacetResponse,
   CombinedFacetResponseContent,
   FacetFilterTerm,
   FacetFilterWithTerms,
   facetLabelTranslationMapping
} from '@ravago/shared/page-data/models/elastic';
import { DynamicPageUrl } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { SearchItem } from '@ravago/shared/radiance/components/multifacet/components/SearchItem/SearchItem';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { AppUtils } from '../../../../utils/app/app.utils';
import StringUtils from '../../../../utils/string/string.utils';
import { filterToQuery } from '../../../content/product-portfolio/utils/filter-query-utils';

interface Props {
   suggestedFacets: Array<CombinedFacetResponse>;
   itemCount: number;
   highlightTerm: string;
   locale: string;
   onCombinedFacetClick?: (combinedFacets: Array<FacetFilterWithTerms>) => void;
   facetType: 'apply' | 'navigate';
   productOverviewRoute?: DynamicPageUrl;
}

export const AutoSuggestFilters: FC<Props> = ({
   suggestedFacets,
   itemCount,
   highlightTerm,
   locale,
   onCombinedFacetClick,
   facetType,
   productOverviewRoute
}) => {
   const translate = useTranslations();

   const toFilledInParentFacets = (facet: CombinedFacetResponseContent, existingFacets: Array<FacetFilterWithTerms>) => {
      const existingParent = existingFacets.find((f) => f.facet === facet.parent?.facetField);
      const existingTerm = existingParent?.terms.find((t) => t.term === facet.parent?.value);

      if (existingTerm) {
         existingTerm.nestedFacets?.push({
            facet: facet.facetField,
            terms: [{ term: facet.value }]
         });
      } else if (existingParent) {
         existingParent.terms.push({
            term: facet.parent?.value ?? '',
            nestedFacets: [
               {
                  facet: facet.facetField,
                  terms: [{ term: facet.value }]
               }
            ]
         });
      } else {
         existingFacets.push({
            facet: facet.parent?.facetField ?? '',
            terms: [
               {
                  term: facet.parent?.value ?? '',
                  nestedFacets: [
                     {
                        facet: facet.facetField,
                        terms: [{ term: facet.value }]
                     }
                  ]
               }
            ]
         });
      }

      return existingFacets;
   };

   const toFilledInFacets = (combinedFacet: Array<CombinedFacetResponseContent>) => {
      const facetFilters: Array<FacetFilterWithTerms> = [];

      combinedFacet.forEach((facet) => {
         if (facet.parent) {
            toFilledInParentFacets(facet, facetFilters);
            return;
         }

         const existingParent = facetFilters.find((f) => f.facet === facet.facetField);

         if (existingParent) {
            existingParent.terms.push({ term: facet.value });
         } else {
            facetFilters.push({
               facet: facet.facetField,
               terms: [{ term: facet.value }]
            });
         }
      });

      return facetFilters;
   };

   const visualizeLabel = (facetFilterTerm: FacetFilterTerm): string => {
      const termWithHighlight = StringUtils.highlight(facetFilterTerm.term, highlightTerm, '<b>', '</b>');
      if (facetFilterTerm.nestedFacets) {
         const parentTermWithHighlight = StringUtils.highlight(facetFilterTerm.nestedFacets[0].terms[0].term ?? '', highlightTerm, '<b>', '</b>');
         return `${parentTermWithHighlight} (${termWithHighlight})`;
      }
      return termWithHighlight;
   };

   const filledInFacets = useMemo(() => suggestedFacets.map((facet) => toFilledInFacets(facet.filters)), [suggestedFacets]);

   const filterTerms = (facet: FacetFilterWithTerms, index: number, isLast: boolean) => (
      <Box key={AppUtils.getKey(facet.facet, index)} direction="column" gap="3xs" justify="center" height="100%">
         {facet.terms.map((facetTerm, facetTermIndex) => {
            const isParent = facetTerm.nestedFacets;

            if (isParent)
               return (
                  <Box key={AppUtils.getKey(facetTerm.term, facetTermIndex)} gap="none">
                     <Typography variant="small-highlight" color="primary">
                        {translate(facetLabelTranslationMapping.get(facetTerm.nestedFacets![0].facet) ?? '')}
                     </Typography>
                     &nbsp;
                     <Typography variant="small" color="strong">
                        <span dangerouslySetInnerHTML={{ __html: visualizeLabel(facetTerm) }} />
                        {!isLast ? ',' : ''}
                     </Typography>
                  </Box>
               );

            return (
               <Box key={AppUtils.getKey(facetTerm.term, facetTermIndex)} gap="none">
                  <Typography variant="small-highlight" color="primary">
                     {translate(facetLabelTranslationMapping.get(facet.facet) ?? facet.facet)}
                  </Typography>
                  &nbsp;
                  <Typography variant="small" color="strong">
                     <span dangerouslySetInnerHTML={{ __html: visualizeLabel(facetTerm) }} />
                     {!isLast ? ',' : ''}
                  </Typography>
               </Box>
            );
         })}
      </Box>
   );

   const filterItem = (item: Array<FacetFilterWithTerms>) => (
      <SearchItem trailingIcon="plus" onClick={() => onCombinedFacetClick?.(item)}>
         <Box direction="column" gap="3xs" justify="center" height="100%">
            {item.map((facet, index) => filterTerms(facet, index, index === item.length - 1))}
         </Box>
      </SearchItem>
   );

   const getRoute = (combinedFacets: Array<FacetFilterWithTerms>) => {
      if (productOverviewRoute) {
         return `${locale}/${productOverviewRoute.url}?filter=${filterToQuery(combinedFacets)}`;
      }

      return '';
   };

   return (
      <Box gap="lg" width="100%">
         <Box direction="column" gap="lg" width="100%">
            <Typography variant="heading-4">{translate('auto-suggest.filters')}</Typography>
            <Box direction="column" gap="xs" width="100%">
               {filledInFacets.slice(0, itemCount).map((item, index) => (
                  <Box key={AppUtils.getKey(item.flatMap((f) => f.terms).join(''), index)} direction="column" items="start" gap="2xs">
                     {facetType === 'apply' ? (
                        filterItem(item)
                     ) : (
                        <a href={getRoute(item) ?? undefined} style={{ display: 'contents' }}>
                           {filterItem(item)}
                        </a>
                     )}
                  </Box>
               ))}
               {filledInFacets.length === 0 && <Typography>{translate('auto-suggest.no-filters')}</Typography>}
            </Box>
         </Box>
      </Box>
   );
};
