import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import dataAttributes from '../../../../utils/attributes/attributes';
import { Icon } from '../../../icon/components/Icon/Icon';
import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   image?: string;
   size?: string;
   ratio?: '1:1' | '5:4';
   trailingIcon?: string;
   attributes?: Record<string, string>;
   onClick?: () => void;
   children?: ReactNode;
}

export const Tile: FC<Props> = ({ image, size, ratio = '1:1', trailingIcon, attributes = {}, onClick, children }) => (
   <div
      data-testid="tile"
      className={classNames('rounded-component-data-display-tile-container-border-radius relative w-full overflow-hidden bg-cover bg-center', ratio === '1:1' && 'aspect-square', ratio === '5:4' && 'aspect-[5/4]', onClick ? 'cursor-pointer' : 'pointer-events-none')}
      onClick={onClick}
      role="presentation"
      style={image ? { backgroundImage: `url(${image})`, width: size } : {}}
      {...dataAttributes(attributes)}
   >
      <div className="absolute inset-0 size-full" />
      <div className="px-component-data-display-tile-caption-container-spacing-padding-x py-component-data-display-tile-caption-container-spacing-padding-y bg-component-data-display-tile-caption-container-color-background-gradient-scrimmed text-component-data-display-tile-caption-container-color-text-on-scrimmed absolute inset-0 flex items-end justify-between">
         <Typography variant="body-highlight" color="inherit">
            {children}
         </Typography>
         {trailingIcon && (
            <div data-testid="tile-trailing-icon">
               <Icon icon={trailingIcon} color="inherit" />
            </div>
         )}
      </div>
   </div>
);
