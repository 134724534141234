'use client';

import { FC, ReactNode, useCallback, useMemo, useState } from 'react';

import { Badge } from '../../../badge/components/Badge/Badge';
import { Box } from '../../../box/components/Box/Box';
import { Button } from '../../../button/components/Button/Button';
import { Checkbox } from '../../../checkbox/components/Checkbox/Checkbox';
import { Icon } from '../../../icon/components/Icon/Icon';
import { List } from '../../../list/components/List/List';
import { ListItem } from '../../../list/components/ListItem/ListItem';
import { Menu } from '../../../menu/components/Menu/Menu';
import { RangeSlider } from '../../../slider/components/RangeSlider/RangeSlider';
import { Typography } from '../../../typography/components/Typography/Typography';
import { InputGroup } from '../InputGroup/InputGroup';

export interface MultiselectOption {
   id: string;
   label: string;
   trailingText?: string;
   checked?: boolean;
   disabled?: boolean;
   children?: Array<Omit<MultiselectOption, 'children' | 'sliderData'>>;
   sliderData?: { min: number; max: number; sliderValues: { min: number; max: number } };
}

interface Props {
   id: string;
   clearText?: string;
   disabled?: boolean;
   label: string;
   leadingIcon?: string;
   options: Array<MultiselectOption>;
   readOnly?: boolean;
   searchLabel?: string;
   supportText?: string;
   onChange?: (options: Array<MultiselectOption>) => void;
   onClear?: () => void;
   emptyState?: ReactNode;
   sortOptionsOnOpen?: boolean;
}

export const Multiselect: FC<Props> = ({ id, clearText, disabled = false, readOnly = false, label, leadingIcon, options, searchLabel, supportText, onChange, onClear, emptyState, sortOptionsOnOpen = true }) => {
   const [searchValue, setSearchValue] = useState('');
   const [isOpen, setIsOpen] = useState(false);

   /* istanbul ignore next */
   const filteredOptions = useMemo(() => {
      if (!searchValue) return options;

      return options.filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase()) || option.children?.some((child) => child.label.toLowerCase().includes(searchValue.toLowerCase())));
   }, [searchValue, options]);

   /* istanbul ignore next */
   const numberOfCheckedOptions = useMemo(() => {
      const checkedItems = options.filter((option) => option.checked).length;
      const checkedChildren = options.flatMap((option) => option.children).filter((child) => child?.checked).length;

      return checkedItems + checkedChildren;
   }, [options]);

   /* istanbul ignore next */
   const handleToggleOption = (option: MultiselectOption) => {
      const newOptions = options.map((o) => {
         if (o.id === option.id)
            return {
               ...o,
               checked: !o.checked,
               children: o.children?.map((c) => ({ ...c, checked: !o.checked }))
            };

         return o;
      });

      onChange?.(newOptions);
   };

   /* istanbul ignore next */
   const handleToggleChild = (option: Omit<MultiselectOption, 'children' | 'sliderData'>) => {
      const newOptions = options.map((o) => {
         if (o.children) {
            const newChildren = o.children.map((c) => {
               if (c.id === option.id) return { ...c, checked: !c.checked };

               return c;
            });

            return { ...o, checked: newChildren.every((c) => c.checked), children: newChildren };
         }

         return o;
      });

      onChange?.(newOptions);
   };

   /* istanbul ignore next */
   const handleSliderChange = (option: MultiselectOption, sliderValues: { min: number; max: number }) => {
      const newOptions = options.map((o) => {
         if (o.id === option.id) return { ...o, sliderData: { ...o.sliderData, sliderValues } };

         return o;
      }) as Array<MultiselectOption>;

      onChange?.(newOptions);
   };

   /* istanbul ignore next */
   const sortOptions = useCallback(() => {
      options.sort((a, b) => {
         const isAOrItsChildrenChecked = a.checked || a.children?.some((child) => child.checked);
         const isBOrItsChildrenChecked = b.checked || b.children?.some((child) => child.checked);
         if (isAOrItsChildrenChecked !== isBOrItsChildrenChecked) {
            return isAOrItsChildrenChecked ? -1 : 1;
         }

         if (a.disabled !== b.disabled) {
            return !a.disabled ? -1 : 1;
         }

         return a.label.toUpperCase().localeCompare(b.label.toUpperCase());
      });
      options.forEach((option) => {
         if (!option.children) {
            return;
         }
         option.children.sort((a, b) => {
            if (a.checked !== b.checked) {
               return a.checked ? -1 : 1;
            }

            if (a.disabled !== b.disabled) {
               return !a.disabled ? -1 : 1;
            }
            return a.label.toUpperCase().localeCompare(b.label.toUpperCase());
         });
      });
   }, [options]);

   /* istanbul ignore next */
   const handleOpen = () => {
      if (sortOptionsOnOpen) sortOptions();
      setIsOpen(true);
   };

   /* istanbul ignore next */
   const handleClose = () => {
      setIsOpen(false);
      setSearchValue('');
   };

   /* istanbul ignore next */
   const handleToggle = () => {
      if (isOpen) {
         handleClose();
      } else {
         handleOpen();
      }
   };

   return (
      <div className={`gap-component-input-filter-container-spacing-outer-gap flex flex-col ${disabled || readOnly ? 'pointer-events-none' : ''}`}>
         <Menu
            open={isOpen}
            onMenuClose={handleClose}
            content={
               <div data-testid="multiselect-menu">
                  <Box direction="column" gap="2xs">
                     <div className="px-component-input-filter-dropdown-action-spacing-padding-x flex w-full">
                        <InputGroup id={`${id}-search`} actionIcon="search" label={searchLabel} value={searchValue} onChange={setSearchValue} autocomplete="off" />
                     </div>
                     <div className="max-h-component-navigation-menu-container-sizing-max-height-on-text-fields overflow-y-auto overflow-x-hidden">
                        {filteredOptions.length === 0 && /* istanbul ignore next */ emptyState}
                        {filteredOptions.length > 0 && (
                           <List>
                              {filteredOptions.map((option) => (
                                 <div key={option.id} data-testid="multiselect-list-item">
                                    <ListItem interactive trailingText={option.trailingText} disabled={option.disabled} onClick={/* istanbul ignore next */ () => handleToggleOption(option)}>
                                       <Checkbox readOnly gutter={false} id={`${id}-${option.id}`} disabled={option.disabled} checked={option.checked} indeterminate={!option.checked && option.children?.some((o) => o.checked)}>
                                          <Typography>{option.label}</Typography>
                                       </Checkbox>
                                    </ListItem>
                                    {option.children?.map((child) => (
                                       <div key={child.id} data-testid="multiselect-child-list-item">
                                          <ListItem interactive trailingText={child.trailingText} disabled={option.disabled || child.disabled} onClick={/* istanbul ignore next */ () => handleToggleChild(child)}>
                                             <Checkbox readOnly gutter={false} id={`${id}-${child.id}`} disabled={option.disabled || child.disabled} child checked={child.checked}>
                                                <Typography>{child.label}</Typography>
                                             </Checkbox>
                                          </ListItem>
                                       </div>
                                    ))}

                                    {option.sliderData && option.checked && (
                                       <div className="px-component-data-display-list-item-container-spacing-padding-x item-container-spacing-padding-right flex flex-col">
                                          <RangeSlider min={option.sliderData.min} max={option.sliderData.max} value={option.sliderData.sliderValues} onChange={/* istanbul ignore next */ (value) => handleSliderChange(option, value)} disabled={disabled || option.disabled} />
                                       </div>
                                    )}
                                 </div>
                              ))}
                           </List>
                        )}
                     </div>
                     {numberOfCheckedOptions > 0 && clearText && (
                        <div data-testid="multiselect-clear" className="px-component-input-filter-dropdown-action-spacing-padding-x flex w-full flex-col">
                           <Button variant="secondary" leadingIcon="times" onClick={onClear}>
                              {clearText}
                           </Button>
                        </div>
                     )}
                  </Box>
               </div>
            }
         >
            <button
               data-testid="multiselect-button"
               id={id}
               type="button"
               disabled={disabled}
               onClick={handleToggle}
               className={`border-component-input-filter-container-border-width-enabled rounded-component-input-filter-container-border-radius border-component-input-filter-container-color-border-neutral-enabled hover:border-component-input-filter-container-color-border-neutral-hover bg-component-input-filter-container-color-background min-h-component-input-filter-container-sizing-min-height h-component-input-filter-container-sizing-height px-component-input-filter-container-spacing-padding-x py-component-input-filter-container-spacing-padding-y gap-component-input-combobox-container-spacing-gap box-border flex items-center transition-all
                      ${isOpen ? 'border-component-input-filter-container-color-border-neutral-focused border-component-input-filter-container-border-width-focused' : ''}
                  `}
            >
               {leadingIcon && (
                  <div data-testid="multiselect-leading-icon" className={disabled ? 'text-component-input-filter-icon-start-color-text-on-surface-disabled' : 'text-component-input-filter-icon-start-color-text-on-surface-enabled'}>
                     <Icon icon={leadingIcon} />
                  </div>
               )}
               <div className={disabled ? 'text-component-input-filter-label-color-text-neutral-disabled' : 'text-component-input-filter-label-color-text-neutral-enabled'}>
                  <Typography color="inherit">{label}</Typography>
               </div>
               <div className={numberOfCheckedOptions === 0 ? /* istanbul ignore next */ 'opacity-0' : ''}>
                  <Badge variant={disabled ? 'disabled' : 'primary'}>{numberOfCheckedOptions}</Badge>
               </div>
               <div className={`${disabled ? 'text-component-input-filter-icon-end-color-text-on-surface-disabled' : 'text-component-input-filter-icon-end-color-text-on-surface-enabled'} ${isOpen ? 'rotate-180' : ''} transition-all`}>
                  <Icon icon="chevron-down" />
               </div>
            </button>
         </Menu>
         {supportText && (
            <div
               data-testid="multiselect-support-text"
               className={`pl-component-input-filter-supporting-text-spacing-padding-left gap-component-input-filter-supporting-text-spacing-gap flex ${
                  disabled ? 'text-component-input-filter-supporting-text-color-text-neutral-disabled' : 'text-component-input-filter-supporting-text-color-text-neutral-enabled'
               }`}
            >
               <Typography variant="caption" color="inherit">
                  {supportText}
               </Typography>
            </div>
         )}
      </div>
   );
};
