import { ContentfulCollectionName, ContentfulMarketSegment, ContentfulMarketSegmentEntry } from '@ravago/shared/page-data/models/contentful';
import { BaseQuery } from '@ravago/shared/page-data/models/utils';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { ContentfulClientApi, EntryCollection } from 'contentful';

import { AssetUtils } from '../asset/asset.utils';
import { cache } from '../cache/cache.utils';
import { QueryBuilder } from '../query-builder/query-builder';

/* c8 ignore start */
export class MarketSegmentUtils {
   static getMarketSegmentsByQuery = cache(
      async (contentfulClientApi: ContentfulClientApi, locale: string, query: BaseQuery): Promise<ContentfulMarketSegment[] | undefined> => {
         const [industryId] = query.industryIds || [];

         if (!industryId) return undefined;

         const contentType: ContentfulCollectionName = 'segment';

         const { limit, orderBy } = query;

         const queryOptions = new QueryBuilder()
            .contentType(contentType)
            .limit(limit || 4)
            .order(orderBy)
            .include(10)
            .locale(locale)
            .setOptional('fields.industry.sys.id', industryId)
            .build();

         const entries = await contentfulClientApi.getEntries<ContentfulMarketSegmentEntry>(queryOptions);

         const response = entries?.total > 0 ? this.normalizeEntryCollection(entries) : undefined;

         return response;
      }
   );

   private static normalizeEntryCollection({ items }: EntryCollection<ContentfulMarketSegmentEntry>): ContentfulMarketSegment[] {
      return items.map((item) => ({
         sys: { id: item.sys.id },
         name: item.fields?.name ?? '',
         description: item.fields?.description ? documentToReactComponents(item.fields.description) : '',
         applications: item.fields?.applications ?? [],
         image: AssetUtils.mapToImage(item.fields?.image),
         marketName: item.fields.industry?.fields?.name ?? ''
      }));
   }
}

/* c8 ignore end */
