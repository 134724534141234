'use client';

import { FC } from 'react';

import { ElementProps } from '@ravago/shared/page-data/models/base';
import { ContentfulProductEntry } from '@ravago/shared/page-data/models/contentful';
import { ProductHeaderNode } from '@ravago/shared/page-data/models/elements';

import { Entry } from 'contentful';

import { AssetUtils } from '../../../utils/asset/asset.utils';
import { AvatarNameHeader } from '../../shared/avatar-name-header/avatar-name-header';

export const ProductHeaderElement: FC<ElementProps<ProductHeaderNode>> = ({ node, globals }) => {
   const product = globals?.contentEntity as Entry<ContentfulProductEntry>;

   if (!product) return null;

   return (
      <AvatarNameHeader
         theme={node.theme === 'primary' ? 'primary' : 'inverse-strong'}
         avatar={
            product?.fields?.brand?.fields?.producer?.fields.logo?.fields.file?.url
               ? AssetUtils.optimizeImageIfRequired(product.fields.brand.fields.producer.fields.logo.fields.file.url, globals.acceptWebp)
               : undefined
         }
         name={product?.fields?.name}
      />
   );
};
