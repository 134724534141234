'use client';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { ContentfulBrandEntry, ContentfulPaginatedResponse, ContentfulProduct } from '@ravago/shared/page-data/models/contentful';
import { ProductListNode } from '@ravago/shared/page-data/models/elements';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BoxItem } from '@ravago/shared/radiance/components/box/components/BoxItem/BoxItem';
import { Grid } from '@ravago/shared/radiance/components/grid/components/Grid/Grid';
import { GridCol } from '@ravago/shared/radiance/components/grid/components/GridCol/GridCol';
import { InputGroup } from '@ravago/shared/radiance/components/input-field/components/InputGroup/InputGroup';
import { Pagination } from '@ravago/shared/radiance/components/pagination/components/Pagination/Pagination';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { Entry } from 'contentful';
import debounce from 'lodash/debounce';

import { useRoleBasedFeatureToggles } from '../../../../hooks/use-role-based-feature-toggles';
import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { ProductUtils } from '../../../../utils/product/product.utils';
import { ProductCard } from '../../../shared/cards/product-card/product-card';
import { EmptyState } from '../../../shared/empty-state/empty-state.component';

interface Props {
   node: ProductListNode;
   globals: RendererGlobals;
   baseRoute?: string;
   products: ContentfulPaginatedResponse<ContentfulProduct[]>;
   hasPublicLabelAndSigningsPermission: boolean;
   hasSustainabilityLabels: boolean;
   paginatedProducts?: (page: number, searchTerm: string) => Promise<ContentfulPaginatedResponse<ContentfulProduct[]>>;
}

/* c8 ignore start */
export const ProductList: FC<Props> = ({
   node,
   globals,
   baseRoute,
   products,
   hasPublicLabelAndSigningsPermission,
   hasSustainabilityLabels,
   paginatedProducts
}) => {
   const translate = useTranslations();
   const { hasProductCompareFeaturePermission } = useRoleBasedFeatureToggles(globals);
   const [productItems, setProductItems] = useState<ContentfulPaginatedResponse<ContentfulProduct[]>>(products);
   const [searchTerm, setSearchTerm] = useState(node.query?.search || '');
   const [currentPage, setCurrentPage] = useState(node.query?.page || 1);

   const { locale, isConsumer, contentEntity, contentType, assetBasePath, acceptWebp } = globals;

   const brand = contentEntity as Entry<ContentfulBrandEntry>;

   const {
      showPagination,
      showTotal,
      showSearch,
      query: { limit }
   } = node;

   const canShowPagination = (showPagination ?? false) && productItems.total > 0;

   const totalPages = canShowPagination ? Math.ceil(productItems.total / (limit || 4)) : 0;

   // eslint-disable-next-line react-hooks/exhaustive-deps
   const onSearch = useCallback(
      debounce((value: string) => {
         if (searchTerm === '' || value === '') setCurrentPage(1);
         setSearchTerm(value);
      }, 300),
      []
   );

   const productCards = useMemo(
      () => productItems?.items?.map((product) => ProductUtils.transformToProductCard(assetBasePath, product, acceptWebp)),
      [assetBasePath, productItems, acceptWebp]
   );

   useEffect(() => {
      if (paginatedProducts) {
         paginatedProducts(currentPage, searchTerm).then((response) => setProductItems(response));
      }
   }, [currentPage, searchTerm]);

   useEffect(() => {
      setProductItems(products);
   }, [products]);

   return (
      <>
         {showSearch && (
            <Box items="center">
               <BoxItem grow={1}>
                  <InputGroup
                     id="product-document-search"
                     actionIcon="search"
                     width="100%"
                     clearable
                     onChange={onSearch}
                     label={translate('brands-search-placeholder')}
                     value={searchTerm}
                  />
               </BoxItem>
            </Box>
         )}
         {showTotal && (
            <Typography variant="caption">
               {translate('brands-detail-total', { total: productItems?.total.toString() ?? '0', brand: brand?.fields?.name || '' })}
            </Typography>
         )}
         <Grid useContainerQuery>
            {productCards?.map((product) => (
               <GridCol key={product.id} useContainerQuery spanDesktop={3} spanTablet={6} spanPhone={4}>
                  <ProductCard
                     product={product}
                     locale={locale}
                     isConsumer={isConsumer}
                     hasPublicLabelAndSigningsPermission={hasPublicLabelAndSigningsPermission}
                     hasSustainabilityLabels={hasSustainabilityLabels}
                     hasProductCompareFeaturePermission={hasProductCompareFeaturePermission}
                     acceptWebp={acceptWebp}
                     assetBasePath={assetBasePath}
                     baseRoute={baseRoute}
                     useStandardCardBanner={contentType === 'caseStudy'}
                  />
               </GridCol>
            ))}
         </Grid>
         {showSearch && productItems.total === 0 && <EmptyState icon="search" label={translate('products-no-search-results', { searchTerm })} />}
         {canShowPagination && (
            <Box justify="end">
               <BoxItem>{totalPages > 0 && <Pagination total={totalPages} current={currentPage} onChange={setCurrentPage} />}</BoxItem>
            </Box>
         )}
      </>
   );
};
/* c8 ignore end */
