import { FC } from 'react';

import { Invoice } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../../../../../providers/translation-client-context-provider';
import { useInvoiceDispatchContext } from '../../../../../context/invoice-context';
import { InvoiceAction } from '../../../../../state/invoice.actions';

type Props = {
   invoice: Invoice;
   showDivider: boolean;
   locale: string;
};

export const ClosedInvoiceCard: FC<Props> = ({ invoice, showDivider, locale }) => {
   const dispatch = useInvoiceDispatchContext();
   const translate = useTranslations();

   const handleDownload = async () => {
      if (invoice.documentId) dispatch({ type: InvoiceAction.DOWNLOAD_DOCUMENT, payload: { invoiceId: invoice.id, documentId: invoice.documentId } });
   };

   return (
      <Box direction="column" gap="md">
         {showDivider && <Divider />}
         <Box justify="between" gap="none" items="center">
            <Typography variant="body-highlight" color="strong">
               {invoice.billedSum.amount.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {invoice.billedSum.currency}{' '}
            </Typography>
            <Box gap="none">
               <Box justify="end" width="100%">
                  <IconButton icon="download" onClick={handleDownload} variant="on-light" />
               </Box>
            </Box>
         </Box>
         <Box gap="none">
            <Box minWidth="50%">
               <Typography color="subtle">{translate('invoices.closed-invoices.table.table-header.type')}</Typography>
            </Box>
            <Box direction="column" gap="3xs" wordBreak="all">
               <Typography>{invoice.eInvoiceId || invoice.id}</Typography>
               <Typography color="subtle" variant="small">
                  {translate(`invoices.invoice-type.${invoice.type}`)}
               </Typography>
            </Box>
         </Box>
         <Box gap="none">
            <Box minWidth="50%">
               <Typography color="subtle">{translate('invoices.closed-invoices.table.table-header.date')}</Typography>
            </Box>
            <Box>
               <Typography>{invoice.createDate}</Typography>
            </Box>
         </Box>
         <Box gap="none">
            <Box minWidth="50%">
               <Typography color="subtle">{translate('invoices.closed-invoices.table.table-header.legal-entity')}</Typography>
            </Box>
            <Box>
               <Typography>{invoice.legalEntity}</Typography>
            </Box>
         </Box>
      </Box>
   );
};
