import { ProductDetail, QuotedColor } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Flyout } from '@ravago/shared/radiance/components/flyout/components/Flyout/Flyout';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import { createPortal } from 'react-dom';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { ColorPickerValue } from '../../../shared/extended-color-picker/extended-color-picker';
import { ProductFlyoutColorDetails } from '../../../shared/product-flyout/product-flyout-color-details';
import { ProductFlyoutProductTitle } from '../../../shared/product-flyout/product-flyout-product-title';

import { QuoteFlyoutQuotation } from './quote-flyout-quotation';

interface Props {
   locale: string;
   product?: ProductDetail;
   color: ColorPickerValue;
   quotedColor: QuotedColor;
   open: boolean;
   onFlyoutClose: () => void;
}

export const QuoteFlyout = ({ locale, product, color, quotedColor, open, onFlyoutClose }: Props) => {
   const translate = useTranslations();
   const { phone } = useBreakpoint();

   return (
      <>
         {createPortal(
            <Flyout position={phone ? 'bottom' : 'right'} width="100%" open={open} closeOnOutsideClick onFlyoutClose={onFlyoutClose}>
               <Box direction="column" overflowY="auto" height="100%" spacing="xs">
                  <Box justify="between" items="center">
                     <Typography component="h4" variant="heading-4" color="strong">
                        {translate('quote-flyout.title')}
                     </Typography>
                     <IconButton icon="chevron-right" onClick={onFlyoutClose} />
                  </Box>

                  <Box direction="column">
                     <ProductFlyoutProductTitle title={product?.description} translate={translate} />
                     <ProductFlyoutColorDetails value={color} />
                  </Box>

                  {quotedColor?.quotations.map((quotation) => (
                     <QuoteFlyoutQuotation key={quotation.id} quotation={quotation} productId={product?.id ?? ''} locale={locale} />
                  ))}
               </Box>
            </Flyout>,
            document.body
         )}
      </>
   );
};
