import { BackgroundColors } from '../../enums/background-colors/background-colors.enum';

export const BackgroundColorTokenMap: { [key in BackgroundColors]: string } = {
  [BackgroundColors.THEME]: 'var(--theme-color-background-theme)',
  [BackgroundColors.PRIMARY]: 'var(--theme-color-background-primary)',
  [BackgroundColors.SECONDARY]: 'var(--theme-color-background-secondary)',
  [BackgroundColors.TERTIARY]: 'var(--theme-color-background-tertiary)',
  [BackgroundColors.SCRIMMED_DEFAULT]: 'var(--theme-color-background-scrimmed-default)',
  [BackgroundColors.SCRIMMED_ALT]: 'var(--theme-color-background-scrimmed-alt)',
  [BackgroundColors.SCRIMMED_THEME]: 'var(--theme-color-background-scrimmed-theme)',
  [BackgroundColors.SCRIMMED_PRIMARY]: 'var(--theme-color-background-scrimmed-primary)',
  [BackgroundColors.SYSTEM_DANGER]: 'var(--theme-color-background-system-danger)',
  [BackgroundColors.SYSTEM_SUCCESS]: 'var(--theme-color-background-system-success)',
  [BackgroundColors.SYSTEM_INFO]: 'var(--theme-color-background-system-info)',
  [BackgroundColors.SYSTEM_WARNING]: 'var(--theme-color-background-system-warning)',
  [BackgroundColors.SURFACE_1]: 'var(--theme-color-surface-1)',
  [BackgroundColors.SURFACE_2]: 'var(--theme-color-surface-2)',
  [BackgroundColors.SURFACE_3]: 'var(--theme-color-surface-3)',
  [BackgroundColors.SURFACE_4]: 'var(--theme-color-surface-4)',
  [BackgroundColors.SURFACE_DEFAULT]: 'var(--theme-color-surface-default)',
  [BackgroundColors.SURFACE_INVERSE]: 'var(--theme-color-surface-inverse)',
  [BackgroundColors.SURFACE_ALT]: 'var(--theme-color-surface-alt)'
};
