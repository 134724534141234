import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

interface Props {
   comment: string;
   onClick: () => void;
}

export const DynamicProductBlockComment = ({ comment, onClick }: Props) => (
   <Box background="#F2F4F4" spacing="md" borderRadius="sm" gap="sm" justify="between" onClick={onClick}>
      <Typography variant="body" maxLines="2">
         {comment}
      </Typography>
      <Icon icon="arrow-right" color="subtle" />
   </Box>
);
