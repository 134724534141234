'use client';

import { FC } from 'react';

import { ElementProps } from '@ravago/shared/page-data/models/base';
import { ContentfulCaseStudyEntry } from '@ravago/shared/page-data/models/contentful';
import { CaseStudyWarningBadgeNode } from '@ravago/shared/page-data/models/elements';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { Entry } from 'contentful';

import { useTranslations } from '../../../providers/translation-client-context-provider';

export const CaseStudyWarningBadgeElement: FC<ElementProps<CaseStudyWarningBadgeNode>> = ({ node, globals }) => {
   const translate = useTranslations();
   const caseStudy = globals?.contentEntity as Entry<ContentfulCaseStudyEntry>;

   const isInternal = caseStudy?.fields?.visibility === 'Internal' ?? false;
   const confidential = caseStudy?.fields?.confidential ?? false;

   if (!isInternal && !confidential) {
      if (globals?.isConsumer) return null;
      return (
         <Typography component="p" variant="body" color="secondary">
            No badge will be displayed, Case study is not internal and not confidential.
         </Typography>
      );
   }

   return (
      <Badge variant={node.variant} size={node.size}>
         {confidential ? translate('case-study-confidential') : translate('case-study-internal-only')}
      </Badge>
   );
};
