import { useMemo } from 'react';

import { User } from '@ravago/shared/authentication';
import { CallOffOrderDetail } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';

import { useSearchParams } from 'next/navigation';

import { useRequestForQuotationContext } from '../../../../hooks/use-request-for-quotation-context';
import { DynamicProductBlockCallOffFields } from '../../../shared/dynamic-product-block/dynamic-product-block-call-off-fields';
import { DynamicProductBlockQuotedColorFields } from '../../../shared/dynamic-product-block/dynamic-product-block-quoted-color-fields';
import { ColorPickerValue, ExtendedColorPicker } from '../../../shared/extended-color-picker/extended-color-picker';

import { RequestForQuotationButton } from './request-for-quotation-button';

interface Props {
   locale: string;
   hasPermission: boolean;
   user: User | undefined;
   token?: string;
   onCommentClicked: () => void;
   getCallOffOrderDetail: (accessToken: string, callOffId: string) => Promise<CallOffOrderDetail | undefined>;
}

const RequestForQuotationBottomSheet = ({ locale, hasPermission, onCommentClicked, getCallOffOrderDetail, token, user }: Props) => {
   const { state: requestForQuotationState, dispatch: requestForQuotationDispatch } = useRequestForQuotationContext();
   const { product, callOffOrderColors, quotedColors, userPermissions } = requestForQuotationState;

   const searchParams = useSearchParams();

   const selectedCallOffOrderColor = useMemo(
      () => callOffOrderColors?.find((color) => color.recommendedCallOff.id === requestForQuotationState.color.callOffOrderId),
      [requestForQuotationState.color.callOffOrderId]
   );

   const selectedQuotedColor = useMemo(
      () =>
         quotedColors?.find(
            (color) =>
               requestForQuotationState.color.isQuotedColor &&
               color.color.id === requestForQuotationState.color.colorId &&
               color.color.producerColorCode === requestForQuotationState.color.producerColorCode
         ),
      [requestForQuotationState.color]
   );

   const handleColorChange = (value: ColorPickerValue) => {
      if (requestForQuotationDispatch) {
         requestForQuotationDispatch({ type: 'set_color', value: { color: { ...value, quantity: undefined } } });
      }
   };

   return (
      <Box direction="column">
         <ExtendedColorPicker
            id="rfq-bottom-sheet"
            colors={product?.colors ?? []}
            callOffOrderColors={callOffOrderColors ?? []}
            quotedColors={quotedColors ?? []}
            value={requestForQuotationState.color}
            onChange={handleColorChange}
         />
         {selectedCallOffOrderColor && token && (
            <DynamicProductBlockCallOffFields
               id="rfq"
               callOffOrderColor={selectedCallOffOrderColor}
               getCallOffOrderDetail={getCallOffOrderDetail}
               token={token}
            />
         )}
         {selectedQuotedColor && token && (
            <DynamicProductBlockQuotedColorFields quotedColor={selectedQuotedColor} token={token} onCommentClicked={onCommentClicked} />
         )}

         <RequestForQuotationButton
            locale={locale}
            product={product}
            userPermissions={
               userPermissions ?? {
                  hasGuestPermission: false,
                  hasPendingPermission: false,
                  hasAnonymousPermission: false,
                  hasVerifiedPermission: false
               }
            }
            user={user}
            isFlyoutOpen={searchParams.get('flyout') === 'open'}
            isDisabled={hasPermission && product && !(product?.colors && product?.packagingGroups)}
         />
      </Box>
   );
};

export default RequestForQuotationBottomSheet;
