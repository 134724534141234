import { FC, useMemo, useEffect } from 'react';

import { Transaction } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Icon } from '@ravago/shared/radiance/components/icon/components/Icon/Icon';
import { Tooltip } from '@ravago/shared/radiance/components/tooltip/components/Tooltip/Tooltip';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import { useTranslations } from '../../../../../../providers/translation-client-context-provider';
import { useInvoiceDispatchContext, useInvoiceStateContext } from '../../../context/invoice-context';
import { InvoiceAction } from '../../../state/invoice.actions';
import { calculateTotalPositiveDueAmount, calculateTotalOpenAmount } from '../../../utils/invoice-amount.utils';

type Props = {
   currency: string;
   invoices: Transaction[];
   isExpanded: boolean;
   totalAmount: number;
   totalOverDue: number;
   locale: string;
};

export const InvoiceSummary: FC<Props> = ({ currency, invoices, isExpanded, totalAmount, totalOverDue, locale }) => {
   const translate = useTranslations();
   const { desktop } = useBreakpoint();
   const { areOpenInvoicesFiltered } = useInvoiceStateContext();
   const dispatch = useInvoiceDispatchContext();

   const responsiveDirection = desktop ? 'row' : 'column';
   const responsiveGap = desktop ? '3xl' : 'md';

   const chevronIcon = isExpanded ? 'chevron-up' : 'chevron-down';
   const totalFilteredOpenAmount = useMemo(() => calculateTotalOpenAmount(invoices), [invoices]);
   const totalFilteredDueAmount = useMemo(() => calculateTotalPositiveDueAmount(invoices), [invoices]);

   useEffect(() => {
      dispatch({
         type: InvoiceAction.SET_DISABLE_OVERDUE_FILTER,
         payload: totalFilteredDueAmount === 0 || totalOverDue === 0
      });
   }, [totalFilteredDueAmount]);

   return (
      <Box justify="between" items="start" margin={{ top: 'md', bottom: 'md' }}>
         <Box justify="between" width="100%" direction={responsiveDirection}>
            <Box gap={responsiveGap} direction={responsiveDirection}>
               <Box direction="column" gap="none">
                  <Box gap="2xs">
                     <Typography variant="body-highlight" color="strong">
                        {areOpenInvoicesFiltered
                           ? translate('invoices.invoice-summary.open-amount')
                           : translate('invoices.invoice-summary.total-open-amount')}
                     </Typography>
                     <Tooltip align="start" pointers text={translate('invoices.open-invoices.billing-notification')}>
                        <Box>
                           <Icon icon="info-circle" />
                        </Box>
                     </Tooltip>
                  </Box>
                  {!areOpenInvoicesFiltered ? (
                     <Typography variant="heading-4" color="strong">
                        {totalAmount.toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} {currency}
                     </Typography>
                  ) : (
                     <>
                        <Typography variant="heading-4" color="strong">
                           {totalFilteredOpenAmount.toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} {currency}
                        </Typography>
                        {totalAmount !== 0 && (
                           <Typography variant="caption" color="subtle">
                              {translate('invoices.invoice-summary.total-amount')}:{' '}
                              {totalAmount.toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} {currency}
                           </Typography>
                        )}
                     </>
                  )}
               </Box>
               {totalOverDue !== 0 && (
                  <Box direction="column" gap="none">
                     <Typography variant="body-highlight" color="error">
                        {translate('invoices.invoice-summary.overdue-title')}
                     </Typography>
                     {!areOpenInvoicesFiltered ? (
                        <Typography variant="heading-4" color="error">
                           {totalOverDue.toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} {currency}
                        </Typography>
                     ) : (
                        <>
                           <Typography variant="heading-4" color="error">
                              {totalFilteredDueAmount.toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} {currency}
                           </Typography>
                           <Typography variant="caption" color="error">
                              {translate('invoices.invoice-summary.total-overdue')}:{' '}
                              {totalOverDue.toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} {currency}
                           </Typography>
                        </>
                     )}
                  </Box>
               )}
            </Box>
            <Box>
               <Typography color="subtle">
                  {translate(`invoices.invoice-summary.number-of-invoices${invoices.length === 1 ? '-singular' : ''}`, { number: invoices.length })}
               </Typography>
            </Box>
         </Box>
         <Box>
            <Icon color="primary" icon={chevronIcon} size="body" type="default" />
         </Box>
      </Box>
   );
};
