import { FC } from 'react';

import { Transaction } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Table } from '@ravago/shared/radiance/components/table/components/Table/Table';
import { TableBody } from '@ravago/shared/radiance/components/table/components/TableBody/TableBody';
import { TableHead } from '@ravago/shared/radiance/components/table/components/TableHead/TableHead';
import { TableHeader } from '@ravago/shared/radiance/components/table/components/TableHeader/TableHeader';
import { TableRow } from '@ravago/shared/radiance/components/table/components/TableRow/TableRow';

import { useTranslations } from '../../../../../../../../providers/translation-client-context-provider';

import { OpenInvoiceTableRow } from './open-invoice-table-row';

type Props = {
   currency: string;
   invoices: Transaction[];
   locale: string;
};

export const OpenInvoiceTableDesktop: FC<Props> = ({ currency, invoices, locale }) => {
   const translate = useTranslations();

   return (
      <Box direction="column">
         <Table>
            <TableHead>
               <TableRow>
                  <TableHeader>{translate('invoices.open-invoices.table.table-header.invoice')}</TableHeader>
                  <TableHeader>{translate('invoices.open-invoices.table.table-header.date')}</TableHeader>
                  <TableHeader>{translate('invoices.open-invoices.table.table-header.due-date')}</TableHeader>
                  <TableHeader align="right">{translate('invoices.open-invoices.table.table-header.open-amount', { currency })}</TableHeader>
                  <TableHeader>{/* Placeholder */}</TableHeader>
                  <TableHeader>{/* Placeholder */}</TableHeader>
               </TableRow>
            </TableHead>
            <TableBody>
               {invoices.map((invoice, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <OpenInvoiceTableRow key={index} invoice={invoice} locale={locale} />
               ))}
            </TableBody>
         </Table>
      </Box>
   );
};
