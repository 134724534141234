'use client';

import { FC, useMemo } from 'react';

import { OverviewPageBrandItem } from '@ravago/shared/page-data/models/elastic';
import { RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { BrandCard } from '@ravago/shared/radiance/components/card/components/BrandCard/BrandCard';
import { CardGrid } from '@ravago/shared/radiance/components/card/components/CardGrid/CardGrid';

import { useConfigContext } from '../../../../../../hooks/use-config-context';
import { useTranslations } from '../../../../../../providers/translation-client-context-provider';
import { BrandUtils } from '../../../../../../utils/brand/brand.utils';
import { UrlUtils } from '../../../../../../utils/url/url.utils';

interface Props {
   brands: Array<OverviewPageBrandItem>;
   baseRoute?: string;
   globals: RendererGlobals;
}

export const BrandGrid: FC<Props> = ({ brands, baseRoute, globals }) => {
   const translate = useTranslations();
   const { useLegacyDigitalPlatformRoutes } = useConfigContext();

   const { locale, acceptWebp } = globals;

   const brandCards = useMemo(() => brands?.map((brand) => BrandUtils.transformToBrandCard(brand, undefined, acceptWebp)), [brands, acceptWebp]);

   return (
      <CardGrid>
         {brandCards?.map((brand) => (
            <BrandCard
               key={brand.id}
               href={UrlUtils.getRoute(locale, baseRoute, brand.slug, undefined, useLegacyDigitalPlatformRoutes)}
               target="_self"
               noFollow={brand.noFollow}
               name={brand.name}
               producer={brand.producer.name}
               logo={brand.producer.logo}
               excerpt={brand.excerpt}
               polymerTypes={brand.polymerTypes}
               productCount={translate('products-total', { total: brand.numberOfProducts })}
               fillHeight
            />
         ))}
      </CardGrid>
   );
};
