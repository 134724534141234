/* eslint-disable react/no-array-index-key */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';

import { DocumentOrderLineData, InvoiceResponse, OrderDocumentType } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Paper } from '@ravago/shared/radiance/components/paper/components/Paper/Paper';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';

import { InvoiceContent } from './invoice-content';

interface Props {
   invoices: Array<InvoiceResponse>;
   onDownload: (document: InvoiceResponse, orderLineData: DocumentOrderLineData, type: OrderDocumentType) => void;
}

export const InvoiceAndCreditNotes: FC<Props> = ({ invoices, onDownload }) => {
   const translate = useTranslations();

   return (
      <Box direction="column" gap="sm">
         {invoices.length > 0 ? (
            invoices.map((invoice, index) => (
               <Paper direction="row" key={index} width="100%">
                  <Box justify="between" items="start" width="100%">
                     <InvoiceContent data={invoice} onDownload={onDownload} />
                  </Box>
               </Paper>
            ))
         ) : (
            <Typography variant="body" component="span">
               {translate('shipment.documents.no-documents-available')}
            </Typography>
         )}
      </Box>
   );
};
