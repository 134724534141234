import { SVGProps } from 'react';

const GuestNotAllowedIllustration = (props: SVGProps<SVGSVGElement>) => (
   <svg viewBox="0 0 656.81592 629.53711" xmlns="http://www.w3.org/2000/svg" style={{ width: '100%', height: '100%' }} {...props}>
      <path
         d="M287.092,135.23145a15.51744,15.51744,0,0,0-15.5,15.5V668.69824a15.51744,15.51744,0,0,0,15.5,15.5H681.96545a15.51752,15.51752,0,0,0,15.5-15.5V150.73145a15.51752,15.51752,0,0,0-15.5-15.5Z"
         transform="translate(-271.59204 -135.23145)"
         fill="#e6e6e6"
      />
      <path
         d="M670.10835,343.58647H298.94966a16.51867,16.51867,0,0,1-16.5-16.5V221.31255a16.51867,16.51867,0,0,1,16.5-16.5H670.10835a16.51835,16.51835,0,0,1,16.5,16.5V327.08647A16.51835,16.51835,0,0,1,670.10835,343.58647Z"
         transform="translate(-271.59204 -135.23145)"
         fill="#fff"
      />
      <path
         d="M670.10835,505.07573H298.94966a16.51868,16.51868,0,0,1-16.5-16.5V382.80132a16.51867,16.51867,0,0,1,16.5-16.5H670.10835a16.51835,16.51835,0,0,1,16.5,16.5V488.57573A16.51835,16.51835,0,0,1,670.10835,505.07573Z"
         transform="translate(-271.59204 -135.23145)"
         fill="#fff"
      />
      <path
         d="M298.94966,528.79106a15.51737,15.51737,0,0,0-15.5,15.5V650.0645a15.51737,15.51737,0,0,0,15.5,15.5H670.10835a15.51737,15.51737,0,0,0,15.5-15.5V544.29106a15.51737,15.51737,0,0,0-15.5-15.5Z"
         transform="translate(-271.59204 -135.23145)"
         fill="#fff"
      />
      <circle cx="403.78758" cy="20.95655" r="11.29294" fill="#fff" />
      <circle cx="48.34196" cy="300.45706" r="11.29294" fill="#e6e6e6" />
      <path
         d="M449.80286,445.22266h-88.085a9.53418,9.53418,0,1,1,0-19.06836h88.085a9.53418,9.53418,0,1,1,0,19.06836Z"
         transform="translate(-271.59204 -135.23145)"
         fill="#e6e6e6"
      />
      <circle cx="249.07415" cy="301.0217" r="11.29295" fill="#e6e6e6" />
      <path
         d="M650.535,445.7876h-88.085a9.53442,9.53442,0,0,1,0-19.06885h88.085a9.53442,9.53442,0,1,1,0,19.06885Z"
         transform="translate(-271.59204 -135.23145)"
         fill="#e6e6e6"
      />
      <polygon
         points="409.668 16.673 408.071 15.076 403.788 19.36 399.504 15.076 397.907 16.673 402.191 20.957 397.907 25.24 399.504 26.837 403.788 22.553 408.071 26.837 409.668 25.24 405.385 20.957 409.668 16.673"
         fill="#e6e6e6"
      />
      <circle cx="48.34196" cy="138.96788" r="11.29295" fill="#e6e6e6" />
      <path
         d="M651.3822,282.60449H382.60986a9.53443,9.53443,0,0,1,0-19.06885H651.3822a9.53443,9.53443,0,0,1,0,19.06885Z"
         transform="translate(-271.59204 -135.23145)"
         fill="#e6e6e6"
      />
      <path
         d="M604.51648,282.60449H365.10583a9.53442,9.53442,0,0,1,0-19.06885H604.51648a9.53443,9.53443,0,0,1,0,19.06885Z"
         transform="translate(-271.59204 -135.23145)"
         fill="var(--brand-color-primary-dark)"
      />
      <circle cx="48.34196" cy="461.94626" r="11.29294" fill="#e6e6e6" />
      <path
         d="M651.3822,605.58252H382.60986a9.53418,9.53418,0,1,1,0-19.06836H651.3822a9.53418,9.53418,0,0,1,0,19.06836Z"
         transform="translate(-271.59204 -135.23145)"
         fill="#e6e6e6"
      />
      <path
         d="M508.244,605.58252H365.10583a9.53418,9.53418,0,1,1,0-19.06836H508.244a9.53418,9.53418,0,0,1,0,19.06836Z"
         transform="translate(-271.59204 -135.23145)"
         fill="var(--brand-color-primary-dark)"
      />
      <path
         d="M377.24573,445.22266H361.7179a9.53418,9.53418,0,1,1,0-19.06836h15.52783a9.53418,9.53418,0,1,1,0,19.06836Z"
         transform="translate(-271.59204 -135.23145)"
         fill="var(--brand-color-primary-dark)"
      />
      <path
         d="M611.00989,445.7876H562.45007a9.53442,9.53442,0,0,1,0-19.06885h48.55982a9.53443,9.53443,0,0,1,0,19.06885Z"
         transform="translate(-271.59204 -135.23145)"
         fill="var(--brand-color-primary-dark)"
      />
      <path
         d="M849.37062,560.04821a10.05579,10.05579,0,0,0,8.66683-12.75311l29.27422-20.49388-17.26535-6.83674L844.894,540.474a10.11027,10.11027,0,0,0,4.47664,19.57425Z"
         transform="translate(-271.59204 -135.23145)"
         fill="#a0616a"
      />
      <path
         d="M863.01606,545.0823a4.509,4.509,0,0,1-1.82666-.39062,4.45275,4.45275,0,0,1-2.57813-3.168l-1.96533-9.127a4.50608,4.50608,0,0,1,1.42529-4.32519l17.36036-15.28613-17.137-35.90528a10.96937,10.96937,0,0,1,18.979-10.88086l21.94629,32.3711a21.13919,21.13919,0,0,1,3.19628,16.48633A22.05582,22.05582,0,0,1,891.779,529.094L865.268,544.47A4.47654,4.47654,0,0,1,863.01606,545.0823Z"
         transform="translate(-271.59204 -135.23145)"
         fill="#ccc"
      />
      <path
         d="M765.75158,525.56709A10.05571,10.05571,0,0,0,780.549,521.232l35.62579,2.78967-8.98163-16.25313-32.45387-.15733a10.11028,10.11028,0,0,0-8.98768,17.95587Z"
         transform="translate(-271.59204 -135.23145)"
         fill="#a0616a"
      />
      <circle cx="579.35586" cy="280.67141" r="24.56103" fill="#a0616a" />
      <polygon points="507.039 616.668 495.289 613.172 503.182 566.184 520.525 571.345 507.039 616.668" fill="#a0616a" />
      <path
         d="M778.23924,764.18208,740.3503,752.90867l.14258-.47924a15.386,15.386,0,0,1,19.135-10.36005l.00094.00028L782.77,748.95513Z"
         transform="translate(-271.59204 -135.23145)"
         fill="#2f2e41"
      />
      <polygon points="635.778 616.067 623.518 616.066 617.686 568.778 629.78 568.779 635.778 616.067" fill="#a0616a" />
      <path
         d="M910.49626,763.18208l-39.53052-.00146v-.5a15.38605,15.38605,0,0,1,15.38648-15.38623h.001l24.1438.001Z"
         transform="translate(-271.59204 -135.23145)"
         fill="#2f2e41"
      />
      <path
         d="M783.1518,737.99734H770.84223a4.50058,4.50058,0,0,1-4.42578-5.31348l17.241-93.86621c-.01661-68.38672,26.88964-107.99707,27.16137-108.38965l.32837-.47363,50.28711,4.10352,50.12305,197.54394a4.4997,4.4997,0,0,1-4.14331,5.60157l-13.83057.67089a4.47875,4.47875,0,0,1-4.39648-2.82324l-52.989-132.46777a1.433,1.433,0,0,0-1.427-.94238,1.47048,1.47048,0,0,0-1.38232,1.00683L787.40253,734.97488A4.50392,4.50392,0,0,1,783.1518,737.99734Z"
         transform="translate(-271.59204 -135.23145)"
         fill="#2f2e41"
      />
      <path
         d="M862.48749,538.02468l-.38427-.02832-53.54053-4.041,9.39136-59.72949A31.273,31.273,0,0,1,844.67573,448.011h0q.61705-.08642,1.24243-.16894A30.90084,30.90084,0,0,1,874.0041,459.0823a31.28953,31.28953,0,0,1,5.35449,30.169Z"
         transform="translate(-271.59204 -135.23145)"
         fill="#ccc"
      />
      <path
         d="M835.25193,522.42712a21.17424,21.17424,0,0,1-15.59565,4.53512l-30.98688-3.3355a4.5005,4.5005,0,0,1-3.614-6.33953l4.13236-9.07119a4.49564,4.49564,0,0,1,3.79389-2.62491l22.58542-1.514,7.41459-39.08157a10.98309,10.98309,0,0,1,10.77768-8.92477,10.8588,10.8588,0,0,1,8.16995,3.65027,11.32174,11.32174,0,0,1,2.77928,8.06034l-1.43618,38.74726a21.13727,21.13727,0,0,1-7.36447,15.35367Q835.58424,522.16189,835.25193,522.42712Z"
         transform="translate(-271.59204 -135.23145)"
         fill="#ccc"
      />
      <path
         d="M832.271,409.76879a32.002,32.002,0,0,1-9.5544,2.10249c-3.29273.04117-6.793-1.09946-8.59485-3.60726a8.9857,8.9857,0,0,1-.98891-7.43916,17.67051,17.67051,0,0,1,9.75236-11.45715,20.76073,20.76073,0,0,1,15.9175-.51234c1.848-5.61764,8.8082-8.675,15.25043-8.84795,13.1597-.35318,24.92633,8.61993,30.64391,19.40963s6.5529,23.123,6.90839,35.09692c.0867,2.92024.123,5.96522-1.26026,8.60151-2.05983,3.92569-6.74061,6.03983-11.1525,7.71631a209.36873,209.36873,0,0,1-35.66714,10.02595c5.83957-3.73249,7.6918-11.76636,3.993-17.31923-1.8097-2.71684-4.78122-4.97868-5.26206-8.12524-.57656-3.77291,2.64253-7.07235,3.83335-10.72421a10.89766,10.89766,0,0,0-3.10911-11.25,13.66644,13.66644,0,0,0-12.33769-2.92569"
         transform="translate(-271.59204 -135.23145)"
         fill="#2f2e41"
      />
      <path d="M927.408,764.76855h-207a1,1,0,0,1,0-2h207a1,1,0,0,1,0,2Z" transform="translate(-271.59204 -135.23145)" fill="#ccc" />
   </svg>
);
export default GuestNotAllowedIllustration;
