import { FC, useMemo } from 'react';

import { Transaction } from '@ravago/shared/page-data/models/elements';
import { Badge } from '@ravago/shared/radiance/components/badge/components/Badge/Badge';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { IconButton } from '@ravago/shared/radiance/components/button/components/IconButton/IconButton';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../../../../../providers/translation-client-context-provider';
import { useInvoiceDispatchContext } from '../../../../../context/invoice-context';
import { InvoiceAction } from '../../../../../state/invoice.actions';
import { isOverDue } from '../../../../../utils/invoice-date.utils';
import { InvoiceDueDate } from '../shared/invoice-due-date';

type Props = {
   currency: string;
   invoice: Transaction;
   locale: string;
};

export const OpenInvoiceCard: FC<Props> = ({ currency, invoice, locale }) => {
   const dispatch = useInvoiceDispatchContext();
   const translate = useTranslations();

   const isInvoiceOverDue = useMemo(() => isOverDue(invoice.dueDate), [invoice.dueDate]);

   const handleDownload = async () => {
      if (invoice.documentId) dispatch({ type: InvoiceAction.DOWNLOAD_DOCUMENT, payload: { invoiceId: invoice.id, documentId: invoice.documentId } });
   };

   return (
      <Box direction="column" gap="md">
         <Divider />
         <Box justify="between" gap="none">
            <Box minWidth="50%" items="center">
               <Typography variant="body-highlight" color="strong">
                  {invoice.openAmount.toLocaleString(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} {currency}
               </Typography>
            </Box>
            <Box minWidth="50%" gap="none" items="center">
               {isInvoiceOverDue && <Badge variant="error">{translate('invoices.error.overdue')}</Badge>}
               <Box justify="end" width="100%">
                  <IconButton icon="download" onClick={handleDownload} variant="on-light" />
               </Box>
            </Box>
         </Box>
         <Box gap="none">
            <Box minWidth="50%">
               <Typography color="subtle">{translate('invoices.open-invoices.table.table-header.invoice')}</Typography>
            </Box>
            <Box direction="column" gap="3xs" wordBreak="all">
               <Typography>{invoice.eInvoiceId || invoice.id}</Typography>
               <Typography variant="small" color="subtle">
                  {translate(`invoices.invoice-type.${invoice.type}`)}
               </Typography>
            </Box>
         </Box>
         <Box gap="none">
            <Box minWidth="50%">
               <Typography color="subtle">{translate('invoices.open-invoices.table.table-header.date')}</Typography>
            </Box>
            <Box>
               <Typography>{invoice.issueDate}</Typography>
            </Box>
         </Box>
         <Box gap="none">
            <Box minWidth="50%">
               <Typography color="subtle">{translate('invoices.open-invoices.table.table-header.due-date')}</Typography>
            </Box>
            <Box>
               <InvoiceDueDate dueDate={invoice.dueDate} invoiceType={invoice.type} />
            </Box>
         </Box>
      </Box>
   );
};
