'use client';

import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';

import { Typography } from '../../../typography/components/Typography/Typography';

interface Props {
   min?: number;
   max?: number;
   step?: number;
   value?: { min: number; max: number };
   disabled?: boolean;
   onChange?: (value: { min: number; max: number }) => void;
}

export const RangeSlider: FC<Props> = ({ min = 0, max = 100, step = 1, value = { min, max }, disabled = false, onChange }) => {
   const [visualValue, setVisualValue] = useState({ p1: value.min, p2: value.max });

   /* istanbul ignore next */
   const handleChange = (event: ChangeEvent<HTMLInputElement>, side: 'p1' | 'p2') => setVisualValue({ ...visualValue, [side]: event.target.valueAsNumber });

   /* istanbul ignore next */
   const handleMouseUp = useCallback(() => onChange?.(visualValue.p1 > visualValue.p2 ? { min: visualValue.p2, max: visualValue.p1 } : { min: visualValue.p1, max: visualValue.p2 }), [onChange, visualValue]);

   useEffect(() => setVisualValue({ p1: value.min, p2: value.max }), [value.min, value.max]);

   /* istanbul ignore next */
   useEffect(() => {
      let newValue = visualValue;

      if (visualValue.p1 < min) newValue = { ...newValue, p1: min };
      if (visualValue.p1 > max) newValue = { ...newValue, p1: max };
      if (visualValue.p2 < min) newValue = { ...newValue, p2: min };
      if (visualValue.p2 > max) newValue = { ...newValue, p2: max };

      if (newValue !== visualValue) {
         setVisualValue(newValue);
         onChange?.(newValue.p1 > newValue.p2 ? { min: newValue.p2, max: newValue.p1 } : { min: newValue.p1, max: newValue.p2 });
      }
   }, [value.min, value.max, min, max, visualValue]);

   return (
      <div className="gap-component-input-slider-handle-spacing-gap relative flex flex-col">
         <div className="min-h-component-input-slider-handle-container-sizing-min-size relative flex items-center">
            <input
               data-testid="range-slider-input-p1"
               type="range"
               min={min}
               max={max}
               step={step}
               value={visualValue.p2}
               disabled={disabled}
               onChange={/* istanbul ignore next */ (e) => handleChange(e, 'p2')}
               onMouseUp={handleMouseUp}
               onTouchEnd={handleMouseUp}
               style={{
                  background: `linear-gradient(to right,
                                        var(--component-input-slider-track-color-background-inactive) ${((Math.min(visualValue.p1, visualValue.p2) - min) / (max - min)) * 100}%,
                                        var(--${disabled ? 'component-input-slider-track-color-background-active-disabled' : 'component-input-slider-track-color-background-active-enabled'}) ${((Math.min(visualValue.p1, visualValue.p2) - min) / (max - min)) * 100}%,
                                        var(--${disabled ? 'component-input-slider-track-color-background-active-disabled' : 'component-input-slider-track-color-background-active-enabled'}) ${((Math.max(visualValue.p1, visualValue.p2) - min) / (max - min)) * 100}%,
                                        var(--component-input-slider-track-color-background-inactive) ${((Math.max(visualValue.p1, visualValue.p2) - min) / (max - min)) * 100}%)`.replace(/\s+/g, ' ')
               }}
               className={`h-component-input-slider-track-size-height rounded-component-input-slider-track-border-radius [&::-webkit-slider-thumb]:size-component-data-display-icon-container-sizing-width-body pointer-events-none absolute w-full appearance-none [&::-webkit-slider-thumb]:pointer-events-auto [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:rounded-full ${
                  disabled
                     ? '[&::-webkit-slider-thumb]:bg-component-input-slider-handle-color-fill-disabled [&::-moz-range-thumb]:bg-component-input-slider-handle-color-fill-disabled'
                     : '[&::-webkit-slider-thumb]:bg-component-input-slider-handle-color-fill-enabled [&::-moz-range-thumb]:bg-component-input-slider-handle-color-fill-enabled'
               } [&::-moz-range-thumb]:size-component-data-display-icon-container-sizing-width-body [&::-moz-range-thumb]:pointer-events-auto [&::-moz-range-thumb]:appearance-none [&::-moz-range-thumb]:rounded-full [&::-moz-range-thumb]:border-0`}
            />
            <input
               data-testid="range-slider-input-p2"
               type="range"
               min={min}
               max={max}
               step={step}
               value={visualValue.p1}
               disabled={disabled}
               onChange={/* istanbul ignore next */ (e) => handleChange(e, 'p1')}
               onMouseUp={handleMouseUp}
               onTouchEnd={handleMouseUp}
               className={`h-component-input-slider-track-size-height rounded-component-input-slider-track-border-radius [&::-webkit-slider-thumb]:size-component-data-display-icon-container-sizing-width-body pointer-events-none absolute w-full appearance-none bg-transparent [&::-webkit-slider-thumb]:pointer-events-auto [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:rounded-full ${
                  disabled
                     ? '[&::-webkit-slider-thumb]:bg-component-input-slider-handle-color-fill-disabled [&::-moz-range-thumb]:bg-component-input-slider-handle-color-fill-disabled'
                     : '[&::-webkit-slider-thumb]:bg-component-input-slider-handle-color-fill-enabled [&::-moz-range-thumb]:bg-component-input-slider-handle-color-fill-enabled'
               } [&::-moz-range-thumb]:size-component-data-display-icon-container-sizing-width-body [&::-moz-range-thumb]:pointer-events-auto [&::-moz-range-thumb]:appearance-none [&::-moz-range-thumb]:rounded-full [&::-moz-range-thumb]:border-0`}
            />
         </div>
         <div className="relative">
            <div className="flex w-[var(--component-data-display-icon-container-sizing-width-body)] justify-center" style={{ marginLeft: `calc(${(visualValue.p1 - min) / (max - min)} * (100% - var(--component-data-display-icon-container-sizing-width-body)))` }}>
               <div
                  className={`rounded-component-input-slider-text-container-border-radius bg-component-input-slider-text-container-color-background px-component-input-slider-text-container-spacing-padding-x w-fit ${
                     disabled ? 'text-component-input-slider-handle-color-text-disabled' : 'text-component-input-slider-handle-color-text-enabled'
                  }`}
               >
                  <Typography variant="small-highlight" color="inherit">
                     {visualValue.p1}
                  </Typography>
               </div>
            </div>
            <div className="absolute flex w-[var(--component-data-display-icon-container-sizing-width-body)] -translate-y-full justify-center" style={{ marginLeft: `calc(${(visualValue.p2 - min) / (max - min)} * (100% - var(--component-data-display-icon-container-sizing-width-body)))` }}>
               <div
                  className={`rounded-component-input-slider-text-container-border-radius bg-component-input-slider-text-container-color-background px-component-input-slider-text-container-spacing-padding-x w-fit ${
                     disabled ? 'text-component-input-slider-handle-color-text-disabled' : 'text-component-input-slider-handle-color-text-enabled'
                  }`}
               >
                  <Typography variant="small-highlight" color="inherit">
                     {visualValue.p2}
                  </Typography>
               </div>
            </div>
         </div>
      </div>
   );
};
