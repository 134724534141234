'use client';

import { FC, useState } from 'react';

import { useAuthenticationContext } from '@ravago/shared/authentication';
import { AutoSuggestResponse } from '@ravago/shared/page-data/models/elastic';
import { DynamicPageUrl, RendererGlobals } from '@ravago/shared/page-data/models/utils';

import { useQuery } from '@tanstack/react-query';

import { CorrelationService } from '../../../../services/tracking/correlation-token.service';
import { AutoSuggest } from '../../../shared/auto-suggest/auto-suggest';
import { AutoSuggestClientRequest } from '../../product-portfolio/models/client-request.model';

interface Props {
   baseRoutes?: {
      product: string;
      brand: string;
      caseStudy: string;
   };
   productOverviewRoute?: DynamicPageUrl;
   language: string;
   getAutoSuggestResult: ({ search, token }: Omit<AutoSuggestClientRequest, 'facets'>) => Promise<AutoSuggestResponse>;
   globals: RendererGlobals;
}

export const HomepageSearch: FC<Props> = ({ baseRoutes, productOverviewRoute, language, getAutoSuggestResult, globals }) => {
   const [search, setSearch] = useState('');
   const [autoSuggestExpanded, setAutoSuggestExpanded] = useState(false);

   const { token } = useAuthenticationContext();

   const autoSuggestQuery = useQuery({
      queryKey: ['auto-suggest', { search, token }],
      queryFn: () => getAutoSuggestResult({ search, token, correlationId: CorrelationService.generateCorrelationId('dpcid') }),
      enabled: () => search.length > 2
   });

   return (
      <AutoSuggest
         id="global-search"
         language={language}
         result={autoSuggestQuery.data}
         loading={autoSuggestQuery.isFetching}
         baseRoutes={baseRoutes}
         globals={globals}
         onChange={setSearch}
         productOverviewRoute={productOverviewRoute}
         expanded={autoSuggestExpanded}
         onAutoSuggestMenuChange={(value) => setAutoSuggestExpanded(value)}
         facetType="navigate"
      />
   );
};
