import { FC, ReactNode, useMemo, useRef } from 'react';

import { FacetContentResponse, FacetFilter, FacetFilterWithTerms, FacetResponse } from '@ravago/shared/page-data/models/elastic';
import { ProductProperty, ProductPropertyName } from '@ravago/shared/page-data/models/elements';
import { FacetFilterV2Utils } from '@ravago/shared/page-data/utils/facet-filter';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { BoxItem } from '@ravago/shared/radiance/components/box/components/BoxItem/BoxItem';
import { InputField } from '@ravago/shared/radiance/components/input-field/components/InputField/InputField';
import { Multiselect, MultiselectOption } from '@ravago/shared/radiance/components/input-field/components/Multiselect/Multiselect';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';

const MULTISELECT_PROPERTY_NAMES: Array<ProductPropertyName> = [
   'BRAND',
   'PRODUCERS',
   'FEATURES',
   'FUNCTIONS',
   'CHEMICAL_GROUP',
   'CHEMICAL_DESCRIPTION',
   'INGREDIENT_GROUP',
   'INGREDIENT_DESCRIPTION',
   'REGULATORY',
   'SUSTAINABILITIES',
   'PHYSICAL_FORMS',
   'END_USE_FORMS',
   'QUALITY_GRADES',
   'STATEMENTS',
   'MARKET_SEGMENTS',
   'APPLICATIONS',
   'SUITABILITY_SEGMENTS',
   'SPECIES_GROUPS',
   'ORGANOLEPTIC_FAMILY'
];

const INPUT_FIELD_PROPERTY_NAMES: Array<ProductPropertyName> = ['CAS_NUMBERS', 'EC_NUMBERS', 'INCI_NUMBERS', 'FEMA', 'E_NUMBERS', 'CI_NUMBERS'];

const PROPERTY_NAME_TO_FACET_IDENTIFIER_DICTIONARY: Record<ProductPropertyName, string> = {
   BRAND: 'brand.raw',
   PRODUCERS: 'producers.name.raw',
   FEATURES: 'features.name.raw',
   FUNCTIONS: 'functions.name.raw',
   CHEMICAL_GROUP: 'chemicalGroup.name.raw',
   CHEMICAL_DESCRIPTION: 'chemicalDescription.name.raw',
   INGREDIENT_GROUP: 'ingredientGroup.name.raw',
   INGREDIENT_DESCRIPTION: 'ingredientDescription.name.raw',
   CAS_NUMBERS: 'casNumbers.raw',
   REGULATORY: 'regulatory.name.raw',
   SUSTAINABILITIES: 'sustainabilities.name.raw',
   PHYSICAL_FORMS: 'physicalForms.raw',
   CI_NUMBERS: 'ciNumbers.raw',
   INCI_NUMBERS: 'inciNumbers.raw',
   E_NUMBERS: 'eNumbers.raw',
   EC_NUMBERS: 'ecNumbers.raw',
   END_USE_FORMS: 'endUseForms.raw',
   QUALITY_GRADES: 'qualityGrades.raw',
   STATEMENTS: 'statements.raw',
   MARKET_SEGMENTS: 'marketSegments.name.raw',
   APPLICATIONS: 'applications.name.raw',
   SUITABILITY_SEGMENTS: 'suitabilitySegments.name.raw',
   SPECIES_GROUPS: 'speciesGroups.name.raw',
   FEMA: 'fema.raw',
   ORGANOLEPTIC_FAMILY: 'organolepticFamily.raw'
};

function createCleanMultiselectOptions(facetIdentifier: string, facets: Array<FacetContentResponse>): Array<MultiselectOption> {
   return facets.map((facet) => {
      const facetFilterAsId: FacetFilterWithTerms = {
         facet: facetIdentifier,
         terms: [{ term: facet.name }]
      };
      const childOptions = facet.nestedFacets
         ?.map((nestedFacet) =>
            nestedFacet.facets.map((childFacet) => {
               const childFacetFilterAsId: FacetFilterWithTerms = {
                  facet: facetIdentifier,
                  terms: [{ term: facet.name, nestedFacets: [{ facet: nestedFacet.identifier.key, terms: [{ term: childFacet.name }] }] }]
               };
               return { id: JSON.stringify(childFacetFilterAsId), label: childFacet.alias ?? childFacet.name };
            })
         )
         .flat();
      return { id: JSON.stringify(facetFilterAsId), label: facet.alias ?? facet.name, ...(childOptions ? { children: childOptions } : {}) };
   });
}

function patchPropertiesOfMultiselectOptions(
   multiselectOptions: Array<MultiselectOption>,
   facetIdentifier: string,
   aggregatedFacets: Array<FacetResponse>,
   distinctActiveFacetFilters: Array<FacetFilter>
): void {
   const currentFacets = aggregatedFacets.find((aggregatedFacet) => aggregatedFacet.identifier.key === facetIdentifier)?.facets;
   currentFacets?.forEach((currentFacet) => {
      const facetFilterAsId: FacetFilterWithTerms = {
         facet: facetIdentifier,
         terms: [{ term: currentFacet.name }]
      };
      const matchingMultiselectOption = multiselectOptions.find((option) => option.id === JSON.stringify(facetFilterAsId));
      if (!matchingMultiselectOption) {
         return;
      }

      const optionTrailingText = currentFacet.quantity ? `(${currentFacet.quantity})` : '';
      const optionIsChecked = distinctActiveFacetFilters.some(
         (activeFacetFilter) => JSON.stringify(activeFacetFilter) === JSON.stringify(facetFilterAsId)
      );
      matchingMultiselectOption.trailingText = optionTrailingText;
      matchingMultiselectOption.checked = optionIsChecked;
      matchingMultiselectOption.disabled = !currentFacet.quantity && !optionIsChecked;

      currentFacet.nestedFacets?.forEach((nestedFacet) => {
         nestedFacet.facets.forEach((childFacet) => {
            const childFacetFilterAsId: FacetFilterWithTerms = {
               facet: facetIdentifier,
               terms: [{ term: currentFacet.name, nestedFacets: [{ facet: nestedFacet.identifier.key, terms: [{ term: childFacet.name }] }] }]
            };
            const matchingChildOption = matchingMultiselectOption.children?.find(
               (childOption) => childOption.id === JSON.stringify(childFacetFilterAsId)
            );
            if (!matchingChildOption) {
               return;
            }

            const childOptionTrailingText = childFacet.quantity ? `(${childFacet.quantity})` : '';
            const childOptionIsChecked =
               optionIsChecked ||
               distinctActiveFacetFilters.some((activeFacetFilter) => JSON.stringify(activeFacetFilter) === JSON.stringify(childFacetFilterAsId));
            matchingChildOption.trailingText = childOptionTrailingText;
            matchingChildOption.checked = childOptionIsChecked;
            matchingChildOption.disabled = !childFacet.quantity && !childOptionIsChecked;
         });
      });
   });
}

interface Props {
   disabled: boolean;
   productProperties: Array<ProductProperty>;
   aggregatedFacets: Array<FacetResponse>;
   distinctActiveFacetFilters: Array<FacetFilter>;
   onUpdateFacetFilters: (distinctFacetFilters: Array<FacetFilter>) => void;
}

export const ProductCustomizableTableFacetFilters: FC<Props> = ({
   disabled,
   productProperties,
   aggregatedFacets,
   distinctActiveFacetFilters,
   onUpdateFacetFilters
}) => {
   const translate = useTranslations();
   const optionsInTheOrderAsDisplayedInMultiselectsDictionary = useRef<Record<string, Array<MultiselectOption>>>({});

   const activeFacetFiltersDictionary = useMemo(
      () =>
         distinctActiveFacetFilters.reduce<Record<string, Array<FacetFilter>>>((acc, facetFilter) => {
            const facetIdentifier = facetFilter.facet;
            if (!acc[facetIdentifier]) {
               acc[facetIdentifier] = [];
            }
            acc[facetIdentifier].push(facetFilter);
            return acc;
         }, {}),
      [distinctActiveFacetFilters]
   );

   const handleMultiselectClear = (facetIdentifier: string, keepReferenceToOrderAsDisplayedInMultiselect = false) => {
      if (!keepReferenceToOrderAsDisplayedInMultiselect) {
         delete optionsInTheOrderAsDisplayedInMultiselectsDictionary.current[facetIdentifier];
      }

      const { [facetIdentifier]: _, ...remainingActiveFacetFilters } = activeFacetFiltersDictionary;
      onUpdateFacetFilters(Object.values(remainingActiveFacetFilters).flat());
   };

   const handleMultiselectChange = (facetIdentifier: string, allOptions: Array<MultiselectOption>) => {
      optionsInTheOrderAsDisplayedInMultiselectsDictionary.current[facetIdentifier] = allOptions;

      const checkedFacetFilters: Array<FacetFilterWithTerms> = allOptions
         .map((option) => {
            if (option.checked) {
               return JSON.parse(option.id) as FacetFilterWithTerms;
            }
            const checkedChildren = option.children?.filter((childOption) => childOption.checked);
            if (checkedChildren?.length) {
               return checkedChildren.map((childOption) => JSON.parse(childOption.id) as FacetFilterWithTerms);
            }
            return [];
         })
         .flat()
         .filter(Boolean);

      if (!checkedFacetFilters.length) {
         handleMultiselectClear(facetIdentifier, true);
         return;
      }

      onUpdateFacetFilters(
         Object.values({
            ...activeFacetFiltersDictionary,
            [facetIdentifier]: checkedFacetFilters
         }).flat()
      );
   };

   const handleInputFieldClear = (facetIdentifier: string) => {
      const { [facetIdentifier]: _, ...remainingActiveFacetFilters } = activeFacetFiltersDictionary;
      onUpdateFacetFilters(Object.values(remainingActiveFacetFilters).flat());
   };

   const handleInputFieldChange = (facetIdentifier: string, value: string) => {
      const trimmedValue = value.trim();
      if (!trimmedValue) {
         handleInputFieldClear(facetIdentifier);
      }

      onUpdateFacetFilters(
         Object.values({
            ...activeFacetFiltersDictionary,
            [facetIdentifier]: [{ facet: facetIdentifier, query: value }]
         }).flat()
      );
   };

   const renderMultiselectForFacet = ({ propertyName, label }: ProductProperty): ReactNode | undefined => {
      if (!MULTISELECT_PROPERTY_NAMES.includes(propertyName)) {
         return undefined;
      }

      const facetIdentifier = PROPERTY_NAME_TO_FACET_IDENTIFIER_DICTIONARY[propertyName];
      if (!facetIdentifier) {
         return undefined;
      }

      const multiselectOptions = optionsInTheOrderAsDisplayedInMultiselectsDictionary.current[facetIdentifier] ?? [];
      if (!multiselectOptions.length) {
         const correspondingFacets = aggregatedFacets.find((aggregatedFacet) => aggregatedFacet.identifier.key === facetIdentifier)?.facets ?? [];
         multiselectOptions.push(...createCleanMultiselectOptions(facetIdentifier, correspondingFacets));
      }
      patchPropertiesOfMultiselectOptions(multiselectOptions, facetIdentifier, aggregatedFacets, distinctActiveFacetFilters);

      return (
         <Multiselect
            id={`table-facet-filters__multiselect-${propertyName}`}
            label={label}
            disabled={disabled}
            emptyState={
               <Box spacing={{ top: 'md', bottom: 'md', left: 'xl', right: 'xl' }}>
                  <Typography whiteSpace="nowrap" data-testid="table-facet-filters__no-options">
                     {translate('product-customizable-table-facet-filters.no-available-options')}
                  </Typography>
               </Box>
            }
            options={multiselectOptions}
            clearText={translate('product-customizable-table-facet-filters.clear-all-options')}
            onChange={(allOptions) => handleMultiselectChange(facetIdentifier, allOptions)}
            onClear={() => handleMultiselectClear(facetIdentifier)}
            data-testid="table-facet-filters__multiselect"
         />
      );
   };

   const renderInputFieldForFacet = ({ propertyName, label }: ProductProperty): ReactNode | undefined => {
      if (!INPUT_FIELD_PROPERTY_NAMES.includes(propertyName)) {
         return undefined;
      }

      const facetIdentifier = PROPERTY_NAME_TO_FACET_IDENTIFIER_DICTIONARY[propertyName];
      if (!facetIdentifier) {
         return undefined;
      }

      const correspondingActiveFacetFilter = activeFacetFiltersDictionary[facetIdentifier]?.filter(FacetFilterV2Utils.isFacetFilterWithQuery)?.[0];
      const searchString = correspondingActiveFacetFilter?.query;

      return (
         <InputField
            label={label}
            value={searchString}
            disabled={disabled}
            clearable
            id={`table-facet-filters__input-field-${propertyName}`}
            onChange={(value) => handleInputFieldChange(facetIdentifier, value)}
            onClear={() => handleInputFieldClear(facetIdentifier)}
            data-testid="table-facet-filters__input-field"
         />
      );
   };

   return (
      <Box direction="row" gap="md" wrap="wrap" spacing={{ left: 'none', right: 'none' }}>
         {productProperties.map((productProperty) => {
            const filterElementForFacet = renderMultiselectForFacet(productProperty) ?? renderInputFieldForFacet(productProperty);
            return filterElementForFacet ? <BoxItem key={productProperty.propertyName}>{filterElementForFacet}</BoxItem> : undefined;
         })}
      </Box>
   );
};
