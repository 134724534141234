'use client';

import { FC, useState } from 'react';

import { AUTO_SUGGEST_OBJECT_TYPES, AutoSuggestObjectType, AutoSuggestResponse, FacetFilterWithTerms } from '@ravago/shared/page-data/models/elastic';
import { DynamicPageUrl, RendererGlobals } from '@ravago/shared/page-data/models/utils';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Button } from '@ravago/shared/radiance/components/button/components/Button/Button';
import { Spinner } from '@ravago/shared/radiance/components/spinner/components/Spinner/Spinner';
import { ToggleChip } from '@ravago/shared/radiance/components/toggle-chip/components/ToggleChip/ToggleChip';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';
import { useBreakpoint } from '@ravago/shared/radiance/hooks/useBreakpoint/useBreakpoint';

import { useTranslations } from '../../../../providers/translation-client-context-provider';
import { CorrelationService } from '../../../../services/tracking/correlation-token.service';
import { TrackingUtils } from '../../../../utils/tracking/tracking.utils';

import { AutoSuggestFilters } from './auto-suggest-filters';
import { AutoSuggestPages } from './auto-suggest-pages';

const ITEM_COUNT = 10;

interface Props {
   result?: AutoSuggestResponse;
   searchTerm?: string;
   language: string;
   loading?: boolean;
   baseRoutes?: {
      product: string;
      brand: string;
      caseStudy: string;
   };
   productOverviewRoute?: DynamicPageUrl;
   facetType?: 'apply' | 'navigate';
   globals: RendererGlobals;
   onFacetFiltersClick: (facetFilters: Array<FacetFilterWithTerms>) => void;
}

export const AutoSuggestPanel: FC<Props> = ({
   result,
   searchTerm = '',
   language,
   loading = false,
   baseRoutes,
   productOverviewRoute,
   globals,
   facetType = 'apply',
   onFacetFiltersClick
}) => {
   const translate = useTranslations();
   const { phone } = useBreakpoint();

   const [tab, setTab] = useState<'pages' | 'filters' | 'all'>('all');
   const [pagesItemCount, setPagesItemCount] = useState(ITEM_COUNT);
   const [filtersItemCount, setFiltersItemCount] = useState(ITEM_COUNT);

   const suggestedPages =
      result?.searchResult?.content?.filter((item) => AUTO_SUGGEST_OBJECT_TYPES.includes(item.type as AutoSuggestObjectType)) ?? [];
   const suggestedFilters = result?.suggestedFilters ?? [];

   const handleLoadMore = (loadMoreTab: 'all' | 'pages' | 'filters') => {
      if (loadMoreTab === 'all' || loadMoreTab === 'pages') {
         setPagesItemCount((prev) => prev + ITEM_COUNT);
      }
      if (loadMoreTab === 'all' || loadMoreTab === 'filters') {
         setFiltersItemCount((prev) => prev + ITEM_COUNT);
      }
   };

   const handleCombinedFacetClick = (facetFilters: Array<FacetFilterWithTerms>) => {
      TrackingUtils.track('autosuggest_facet_click', {
         correlationId: CorrelationService.getCorrelationId('dpcid'),
         facets: JSON.stringify([...facetFilters]),
         language
      });
      onFacetFiltersClick(facetFilters);
   };

   return (
      <Box maxHeight={phone ? undefined : '50vh'} overflowY="auto">
         <Box spacing={!phone ? 'xl' : 'none'} width="100%" height="fit-content">
            {loading ? (
               <Box justify="center" items="center" width="100%">
                  <Spinner data-testid="auto-suggest-panel__spinner" />
               </Box>
            ) : (
               <Box spacing={!phone ? 'sm' : 'none'} width="100%" direction="column">
                  {phone && (
                     <Box direction="row" gap="xs">
                        <ToggleChip
                           label={translate('auto-suggest.tab.all')}
                           toggled={tab === 'all'}
                           onClick={() => setTab('all')}
                           data-testid="auto-suggest-panel__all-tab"
                        />
                        <ToggleChip
                           label={translate('auto-suggest.tab.pages')}
                           toggled={tab === 'pages'}
                           onClick={() => setTab('pages')}
                           data-testid="auto-suggest-panel__pages-tab"
                        />
                        <ToggleChip
                           label={translate('auto-suggest.tab.filters')}
                           toggled={tab === 'filters'}
                           onClick={() => setTab('filters')}
                           data-testid="auto-suggest-panel__filters-tab"
                        />
                     </Box>
                  )}
                  <Box width="100%" direction={phone ? 'column' : 'row'}>
                     {suggestedPages.length + suggestedFilters.length > 0 ? (
                        <>
                           {(tab === 'all' || tab === 'pages') && (
                              <Box direction="column" width="100%">
                                 <AutoSuggestPages
                                    suggestedPages={suggestedPages}
                                    itemCount={pagesItemCount}
                                    language={language}
                                    globals={globals}
                                    highlightTerm={searchTerm}
                                    baseRoutes={baseRoutes}
                                    data-testid="auto-suggest-panel__suggested-pages"
                                 />
                                 {suggestedPages.length > pagesItemCount && (
                                    <Box justify="center">
                                       <Button
                                          variant="secondary"
                                          onClick={() => handleLoadMore('pages')}
                                          data-testid="auto-suggest-panel__more-pages-button"
                                       >
                                          {translate('auto-suggest.load-more')}
                                       </Button>
                                    </Box>
                                 )}
                              </Box>
                           )}
                           {(tab === 'all' || tab === 'filters') && (
                              <Box direction="column" width="100%">
                                 <AutoSuggestFilters
                                    suggestedFacets={suggestedFilters}
                                    itemCount={filtersItemCount}
                                    locale={globals.locale}
                                    highlightTerm={searchTerm}
                                    onCombinedFacetClick={handleCombinedFacetClick}
                                    facetType={facetType}
                                    productOverviewRoute={productOverviewRoute}
                                    data-testid="auto-suggest-panel__suggested-filters"
                                 />
                                 {suggestedFilters.length > filtersItemCount && (
                                    <Box justify="center">
                                       <Button
                                          variant="secondary"
                                          onClick={() => handleLoadMore('filters')}
                                          data-testid="auto-suggest-panel__more-filters-button"
                                       >
                                          {translate('auto-suggest.load-more')}
                                       </Button>
                                    </Box>
                                 )}
                              </Box>
                           )}
                        </>
                     ) : (
                        <Box direction="column" gap="lg">
                           <Typography variant="heading-4">{translate('auto-suggest.no-results')}</Typography>
                           <Typography>{translate('auto-suggest.no-results-description')}</Typography>
                        </Box>
                     )}
                  </Box>
               </Box>
            )}
         </Box>
      </Box>
   );
};
