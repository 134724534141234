'use client';

import { FC } from 'react';

import { ElementProps } from '@ravago/shared/page-data/models/base';
import { ContentfulCaseStudyEntry } from '@ravago/shared/page-data/models/contentful';
import { CaseStudyFeaturesListNode } from '@ravago/shared/page-data/models/elements';

import { Entry } from 'contentful';

import { BadgeList } from '../../shared/badge-list/badge-list';

export const CaseStudyFeaturesListElement: FC<ElementProps<CaseStudyFeaturesListNode>> = ({ node, globals }) => {
   const caseStudy = globals?.contentEntity as Entry<ContentfulCaseStudyEntry> | undefined;
   const features = caseStudy?.fields?.features?.map((feature) => feature?.fields?.name ?? '') ?? [];

   if (!features.length || !caseStudy) return null;

   return (
      <BadgeList
         size={node.size}
         weight="regular"
         badges={features.map((feature) => ({
            id: feature,
            label: feature,
            variant: node.variant ?? 'neutral'
         }))}
      />
   );
};
