/* eslint-disable react/no-array-index-key */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useMemo } from 'react';

import { CoaResponse, DocumentOrderLineData, OrderDocumentType } from '@ravago/shared/page-data/models/elements';
import { Box } from '@ravago/shared/radiance/components/box/components/Box/Box';
import { Divider } from '@ravago/shared/radiance/components/divider/components/Divider/Divider';
import { Paper } from '@ravago/shared/radiance/components/paper/components/Paper/Paper';
import { Typography } from '@ravago/shared/radiance/components/typography/components/Typography/Typography';

import { useTranslations } from '../../../../providers/translation-client-context-provider';

import { CoaContent } from './coa-content';

interface Props {
   coaDocs: Array<CoaResponse>;
   onRequestCoa: (coa: CoaResponse) => void;
   onDownload: (document: CoaResponse, orderLineData: DocumentOrderLineData, type: OrderDocumentType) => void;
}

export const CoaDocuments: FC<Props> = ({ coaDocs, onRequestCoa, onDownload }) => {
   const translate = useTranslations();

   const [downloadableDocuments, requestableDocuments] = useMemo(
      () => [coaDocs.filter((coaDoc) => coaDoc.isAvailable), coaDocs.filter((coaDoc) => !coaDoc.isAvailable)],
      [coaDocs]
   );

   const showDivider = downloadableDocuments.length > 0 && requestableDocuments.length > 0;

   const renderDocuments = (documents: CoaResponse[], showDownloadBtn: boolean) =>
      documents.map((document, index) => (
         <Paper direction="row" key={index} width="100%">
            <Box justify="between" items="start" spacing={{ top: '3xs' }} width="100%" direction="row">
               <CoaContent data={document} onDownload={onDownload} showDownloadBtn={showDownloadBtn} onRequestCoa={onRequestCoa} />
            </Box>
         </Paper>
      ));

   return (
      <Box direction="column" gap="sm">
         {coaDocs.length > 0 ? (
            <>
               {renderDocuments(downloadableDocuments, true)}
               {showDivider && <Divider />}
               {renderDocuments(requestableDocuments, false)}
            </>
         ) : (
            <Typography variant="body" component="span">
               {translate('shipment.documents.no-documents-available')}
            </Typography>
         )}
      </Box>
   );
};
